import { Button } from '@pwa/ui';
import { styled } from '@mui/material';
var StyledButton = styled(Button)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    justifyContent: 'space-between',
    padding: theme.spacing(2)
  };
  return styles;
});
export { StyledButton };