import { Box, styled } from '@mui/material';
var StyledInformationWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.typography.pxToRem(8),
    boxShadow: theme.boxShadows.independence,
    marginTop: theme.spacing(3),
    overflow: 'hidden',
    padding: theme.spacing(2)
  };
  return styles;
});
export { StyledInformationWrapper };