import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledContentWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(3)
  }, theme.breakpoints.up('desktop'), {
    marginBottom: theme.spacing(5),
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(4),
    width: theme.typography.pxToRem(452)
  });

  return styles;
});
var StyledBox = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    margin: theme.spacing(4, 0)
  };
  return styles;
});
var StyledBoxWrapper = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    backgroundColor: theme.palette.white.main,
    borderRadius: pxToRem(16),
    margin: theme.spacing(4, 0),
    padding: theme.spacing(2)
  };
  return styles;
});
var StyledTabsContent = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    marginTop: theme.spacing(3)
  };
  return styles;
});
export { StyledBox, StyledBoxWrapper, StyledContentWrapper, StyledTabsContent };