import { dateFormatter } from '@pwa/ui';
import { formatNumber } from '@helpers';
import { ConfirmationCode } from '@enums';
import { i18n } from '@localization';

var getHistoryOperationDetails = function getHistoryOperationDetails(_ref) {
  var provider = _ref.provider,
      fieldName = _ref.fieldName,
      fieldDetails = _ref.fieldDetails,
      status = _ref.status,
      type = _ref.type,
      transAmount = _ref.transAmount,
      transCurrency = _ref.transCurrency,
      paymentInstrument = _ref.paymentInstrument,
      date = _ref.date,
      code = _ref.code,
      city = _ref.city,
      country = _ref.country,
      accountAmount = _ref.accountAmount,
      accountCurrency = _ref.accountCurrency,
      accountDate = _ref.accountDate,
      feeAmount = _ref.feeAmount,
      feeCurr = _ref.feeCurr,
      paymentAmount = _ref.paymentAmount,
      paymentAmountCurr = _ref.paymentAmountCurr,
      bankName = _ref.bankName,
      detailsOperation = _ref.detailsOperation,
      exchangeRate = _ref.exchangeRate,
      feeCurrencyOtherBank = _ref.feeCurrencyOtherBank,
      feeOtherBank = _ref.feeOtherBank,
      recipientAmount = _ref.recipientAmount,
      recipientCurrency = _ref.recipientCurrency,
      senderAmount = _ref.senderAmount,
      senderCurrency = _ref.senderCurrency,
      ibankGroupId = _ref.ibankGroupId;
  var formattedTransValue = transAmount ? formatNumber(transAmount, 2, true) : '';
  var formattedAccountValue = accountAmount ? formatNumber(accountAmount, 2, true) : '';
  var formattedSenderAmount = senderAmount ? formatNumber(senderAmount, 2, true) : '';
  var formattedRecipientAmount = recipientAmount ? formatNumber(recipientAmount, 2, true) : '';
  var formattedFeeOtherBank = feeOtherBank ? formatNumber(feeOtherBank, 2, true) : '';
  var isCard = type === 'C';
  var isAccount = type === 'A';
  var feeAmountText = ibankGroupId === ConfirmationCode.InstantTransfer ? i18n.t('components.modals.historyOperation.commission') : i18n.t('components.modals.historyOperation.feeAmount');

  var paymentInstrumentText = function paymentInstrumentText() {
    if (ibankGroupId === ConfirmationCode.InstantTransfer) {
      return i18n.t('components.modals.historyOperation.debitAccount');
    }

    return isCard ? i18n.t('components.modals.historyOperation.card') : isAccount ? i18n.t('components.modals.historyOperation.account') : null;
  };

  var transAmountText = function transAmountText() {
    if (ibankGroupId === ConfirmationCode.InstantTransfer) {
      return i18n.t('components.modals.historyOperation.total');
    }

    return isCard ? i18n.t('components.modals.historyOperation.amount') : isAccount ? i18n.t('components.modals.historyOperation.amountAccount') : null;
  };

  var dateText = function dateText() {
    if (ibankGroupId === ConfirmationCode.InstantTransfer) {
      return i18n.t('components.modals.historyOperation.dateSMP');
    }

    return isCard ? i18n.t('components.modals.historyOperation.dateCard') : isAccount ? i18n.t('components.modals.historyOperation.dateAccount') : i18n.t('components.modals.historyOperation.dateAny');
  };

  return [{
    title: "".concat(i18n.t('components.modals.historyOperation.provider')),
    value: provider
  }, {
    title: fieldName,
    value: fieldDetails
  }, {
    title: "".concat(i18n.t('components.modals.historyOperation.status')),
    value: status
  }, {
    title: "".concat(i18n.t('components.modals.historyOperation.recipient')),
    value: fieldDetails && detailsOperation ? "".concat(detailsOperation, " \n ").concat(fieldDetails) : null
  }, {
    title: "".concat(i18n.t('components.modals.historyOperation.bankName')),
    value: bankName || null
  }, {
    title: paymentInstrumentText(),
    value: paymentInstrument
  }, {
    title: exchangeRate ? "".concat(i18n.t('components.modals.historyOperation.writeoffAmount')) : "".concat(i18n.t('components.modals.historyOperation.transferAmount')),
    value: senderAmount && senderCurrency ? "".concat(formattedSenderAmount, " ").concat(senderCurrency) : null
  }, {
    title: "".concat(i18n.t('components.modals.historyOperation.transferAmount')),
    value: exchangeRate && recipientAmount && recipientCurrency ? "".concat(formattedRecipientAmount, " ").concat(recipientCurrency) : null
  }, {
    title: senderCurrency && recipientCurrency ? "".concat(i18n.t('components.modals.historyOperation.exchangeRate'), " (").concat(senderCurrency, "/").concat(recipientCurrency, ")") : null,
    value: exchangeRate || null
  }, {
    title: "".concat(i18n.t('components.modals.historyOperation.paymentAmount')),
    value: paymentAmount && paymentAmountCurr ? "".concat(formatNumber(paymentAmount, 2, true), " ").concat(paymentAmountCurr) : null
  }, {
    title: feeAmountText,
    value: feeAmount && feeCurr ? "".concat(formatNumber(feeAmount, 2, true), " ").concat(feeCurr) : null
  }, {
    title: "".concat(i18n.t('components.modals.historyOperation.otherBankCommission')),
    value: feeOtherBank && feeCurrencyOtherBank ? "".concat(formattedFeeOtherBank, " ").concat(feeCurrencyOtherBank) : null
  }, {
    title: transAmountText(),
    value: transAmount && transCurrency ? "".concat(formattedTransValue, " ").concat(transCurrency) : null
  }, {
    title: isAccount ? "".concat(i18n.t('components.modals.historyOperation.amountAccount')) : null,
    value: accountAmount && accountCurrency ? "".concat(formattedAccountValue, " ").concat(accountCurrency) : null
  }, {
    title: dateText(),
    value: "".concat(dateFormatter(date, 'dd.MM.yyyy'), " ").concat(i18n.t('components.modals.historyOperation.time'), " ").concat(dateFormatter(date, 'HH:mm'))
  }, {
    title: "".concat(i18n.t('components.modals.historyOperation.dateAccountPayment')),
    value: accountDate ? "".concat(dateFormatter(date, 'dd.MM.yyyy')) : null
  }, {
    title: "".concat(i18n.t('components.modals.historyOperation.code')),
    value: code
  }, {
    title: "".concat(i18n.t('components.modals.historyOperation.place')),
    value: country && city ? "".concat(country, ", ").concat(city) : country || city || null
  }];
};

export { getHistoryOperationDetails };