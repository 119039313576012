import { LoaderService } from '@services';

/**
 * Loader interceptor, catch all requests and responses
 *
 * @author Ihar Kazlouski
 * @function LoaderInterceptor
 * @category Interceptors
 * @return {AxiosResponse | AxiosRequestConfig | AxiosError<ApiError> | Promise<AxiosError<ApiError>> | undefined} data.
 */
var LoaderInterceptor = function LoaderInterceptor(_ref) {
  var _error$config;

  var response = _ref.response,
      request = _ref.request,
      error = _ref.error;

  if (request && request.blocked) {
    LoaderService.incRequestCounter();
  }

  if (response && response.config.blocked) {
    LoaderService.decRequestCounter();
  }

  if (error && (_error$config = error.config) !== null && _error$config !== void 0 && _error$config.blocked) {
    LoaderService.decRequestCounter();
  }

  return request || response || error && Promise.reject(error);
};

export { LoaderInterceptor };