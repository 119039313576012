import { __assign as t, __makeTemplateObject as e } from "../../_virtual/_tslib.js";
import { jsx as r } from "react/jsx-runtime";
import n from "../../node_modules/svg-inline-react/lib/index.js";
import { styled as i } from "@mui/material";
import "../../themes/box-shadows/boxShadows.js";
import "../../themes/dark.theme.js";
import { pxToRem as o } from "../../themes/default-theme/default-theme.js";
import "../../themes/gradients/gradients.js";
import "../../themes/light.theme.js";
var s,
    h = i(function (e) {
  return r(n, t({
    raw: !0
  }, e));
})(s || (s = e(["\n  display: block;\n\n  .fill {\n    fill: currentColor;\n  }\n\n  .stroke {\n    stroke: currentColor;\n  }\n\n  width: ", ";\n  height: ", ";\n"], ["\n  display: block;\n\n  .fill {\n    fill: currentColor;\n  }\n\n  .stroke {\n    stroke: currentColor;\n  }\n\n  width: ", ";\n  height: ", ";\n"])), function (t) {
  return "string" == typeof t.width ? t.width : o(t.width || 20);
}, function (t) {
  return "string" == typeof t.height ? t.height : o(t.height || 20);
});
export { h as StyledIcon };