import { pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledButtonContainer = styled(Box)(function (_ref) {
  var theme = _ref.theme,
      isButtonHidden = _ref.isButtonHidden;
  var styles = {
    height: isButtonHidden ? pxToRem(1) : 'unset',
    marginTop: isButtonHidden ? 0 : pxToRem(24),
    overflow: isButtonHidden ? 'hidden' : 'unset',
    pointerEvents: isButtonHidden ? 'none' : 'unset',
    position: isButtonHidden ? 'absolute' : 'unset',
    visibility: isButtonHidden ? 'hidden' : 'visible'
  };
  return styles;
});
export { StyledButtonContainer };