import { __assign as r } from "../../../../_virtual/_tslib.js";
import { jsxs as i, jsx as o } from "react/jsx-runtime";
import { Box as t } from "@mui/material";
import { RemoteImage as a } from "../../../remote-image/remote-image.view.js";
import { Typography as e } from "../../../typography/typography.component.js";
import { StyledNumberBox as n, StyledBox as m } from "./cards-images-info.styles.js";
import { IMAGES_NUM_TO_SHOW as c } from "./card-images-info.enums.js";

var l = function (l) {
  var s = l.cardsImgs,
      p = l.color,
      d = l.variant,
      f = s.length - c;
  return i(t, r({
    alignItems: "center",
    display: "flex"
  }, {
    children: [f > 0 && o(n, r({
      $color: p,
      $variant: d
    }, {
      children: o(e, r({
        color: "nightWizard",
        fontWeight: 500,
        variant: "caption2"
      }, {
        children: "+".concat(f)
      }))
    })), o(t, r({
      display: "flex",
      ml: 1
    }, {
      children: s.slice(0, 3).map(function (i, t) {
        return o(m, r({
          $color: p,
          $variant: d
        }, {
          children: o(a, {
            borderRadius: 0,
            height: 20,
            src: i,
            width: 32
          })
        }), "".concat(i, "-").concat(t));
      })
    }))]
  }));
};

export { l as CardsImagesInfo };