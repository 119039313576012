import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledAccountWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    background: theme.colors.brightGray,
    marginTop: theme.spacing(-7),
    paddingBottom: theme.spacing(14.5),
    paddingTop: theme.spacing(7)
  }, theme.breakpoints.up('desktop'), {
    marginTop: theme.spacing(-10),
    paddingTop: theme.spacing(10)
  });

  return styles;
});
var StyledBox = styled(Box)(function (_ref2) {
  var _theme$colors;

  var theme = _ref2.theme;
  var styles = {
    alignItems: 'center',
    backgroundColor: theme.colors.aragonGreen,
    border: "".concat(pxToRem(3), " solid ").concat(theme === null || theme === void 0 ? void 0 : (_theme$colors = theme.colors) === null || _theme$colors === void 0 ? void 0 : _theme$colors.brightGray),
    borderRadius: '50%',
    display: 'flex',
    height: pxToRem(24),
    justifyContent: 'center',
    pointerEvents: 'none',
    position: 'absolute',
    right: pxToRem(-5),
    top: pxToRem(-5),
    width: pxToRem(24)
  };
  return styles;
});
var StyledCurrencyBox = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.typography.pxToRem(16),
    display: 'flex',
    height: theme.typography.pxToRem(60),
    justifyContent: 'center',
    position: 'relative',
    width: theme.typography.pxToRem(60)
  };
  return styles;
});
export { StyledAccountWrapper, StyledBox, StyledCurrencyBox };