import { __rest as e, __assign as o } from "../../../_virtual/_tslib.js";
import { jsxs as r, jsx as t } from "react/jsx-runtime";
import { Stack as i } from "@mui/material";
import { Icon as c } from "../../icon/icon.component.js";
import { Typography as a } from "../../typography/typography.component.js";
import { StyledFormControl as l, StyledInputLabel as n, StyledCheckboxLabel as s, StyledCheckboxBase as d, StyleFromHelperWrapper as h, StyledFormHelperText as m } from "./checkbox-field.styles.js";
import p from "../../../packages/pwa-ui/assets/images/checkbox-checked.image.svg.js";
import f from "../../../packages/pwa-ui/assets/images/checkbox-unchecked.image.svg.js";

var u = function (u) {
  var b = u.name,
      v = u.fullWidth,
      g = u.color,
      k = void 0 === g ? "white" : g,
      y = u.label,
      x = u.title,
      j = u.subtitle,
      w = u.bottomLabel,
      W = u.error;
  u.deviceSize;
  var F = u.focused;
  u.active, u.inputRef, u.value;
  var S = e(u, ["name", "fullWidth", "color", "label", "title", "subtitle", "bottomLabel", "error", "deviceSize", "focused", "active", "inputRef", "value"]),
      z = b && "".concat(b, "-checkboxfield"),
      I = !!W,
      L = {
    errorId: z && "".concat(z, "-error-text"),
    htmlFor: z,
    id: z
  };
  return r(l, o({
    error: I,
    focused: F,
    fullWidth: v
  }, {
    children: [y && t(n, o({
      $color: k,
      htmlFor: L.htmlFor,
      shrink: !0
    }, {
      children: y
    })), t(s, {
      control: t(d, o({
        "aria-describedby": z,
        checkedIcon: t(c, {
          height: 20,
          src: p,
          width: 20
        }),
        error: W || "",
        fullWidth: v,
        icon: t(c, {
          color: I ? "error" : "estateViolet",
          height: 20,
          src: f,
          width: 20
        }),
        id: L.id,
        inputProps: {
          id: L.id,
          type: "checkbox"
        },
        name: b
      }, S, {
        type: "checkbox"
      })),
      label: r(i, o({
        component: "span"
      }, {
        children: ["string" == typeof x ? t(a, o({
          component: "span",
          fontWeight: 500,
          variant: "body1"
        }, {
          children: x
        })) : "function" == typeof x ? x({
          error: W,
          focused: F
        }) : x, j && t(a, o({
          color: "abandonedSpaceship",
          variant: "caption1"
        }, {
          children: j
        }))]
      }))
    }), w && !I ? t(h, {
      children: t(m, o({
        $color: k
      }, {
        children: w
      }))
    }) : null]
  }));
};

export { u as CheckboxField };