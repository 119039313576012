import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1'
  }, theme.breakpoints.up('desktop'), {
    display: 'block'
  });

  return styles;
});
var StyledDetailsWrapper = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    backgroundColor: theme.palette.white.main,
    borderRadius: pxToRem(16),
    padding: theme.spacing(3)
  };
  return styles;
});
var StyledBox = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    margin: theme.spacing(0, 0, 1, 1)
  };
  return styles;
});
export { StyledBox, StyledDetailsWrapper, StyledWrapper };