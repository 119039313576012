import _defineProperty from "@babel/runtime/helpers/defineProperty";
// eslint-disable-next-line import/no-unresolved
import { Swiper } from 'swiper/react';
import { pxToRem } from '@pwa/ui';
import { alpha, Box, FormControl, styled } from '@mui/material';
var StyledWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    backgroundColor: theme.palette.white.main,
    borderRadius: pxToRem(12),
    overflow: 'hidden',
    padding: theme.spacing(3, 0),
    textAlign: 'center'
  };
  return styles;
});
var StyledSwiper = styled(Swiper)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    '& .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet': {
      margin: theme.spacing(1.5, 1.75, 1)
    },
    '& .swiper-pagination': {
      bottom: 'unset',
      fontSize: 0,
      lineHeight: pxToRem(8),
      marginTop: pxToRem(8),
      position: 'relative'
    },
    overflow: 'visible'
  };
  return styles;
});
var StyledBullet = styled(Box)(function (_ref3) {
  var $bgColor = _ref3.$bgColor;
  var styles = {
    '&.swiper-pagination-bullet-active': {
      border: "".concat(pxToRem(2), " solid white"),
      opacity: 1,
      outline: "".concat(pxToRem(4), " solid ").concat(alpha($bgColor || '#000', 0.4))
    },
    backgroundColor: "".concat($bgColor || ''),
    display: !$bgColor && 'none',
    height: pxToRem(32),
    opacity: 0.4,
    verticalAlign: 'middle',
    width: pxToRem(32)
  };
  return styles;
});
var StyledFormControl = styled(FormControl)(function (_ref4) {
  var $error = _ref4.$error,
      theme = _ref4.theme;
  var styles = {
    '& > div': {
      border: $error ? "".concat(pxToRem(1), " solid ").concat(theme.palette.error.main) : "".concat(pxToRem(1), " solid ").concat(theme.palette.white.main)
    },
    '& p': {
      color: $error ? theme.palette.error.main : theme.palette.nightWizard.main
    }
  };
  return styles;
});
var StyledImageWrapper = styled(Box)(function (_ref5) {
  var theme = _ref5.theme;

  var styles = _defineProperty({
    '& img': {
      height: '100%',
      objectFit: 'cover',
      width: '100%'
    },
    marginLeft: theme.spacing(9.75),
    marginRight: theme.spacing(9.75),
    maxWidth: pxToRem(300),
    position: 'relative'
  }, theme.breakpoints.up(456), {
    margin: 'auto'
  });

  return styles;
});
export { StyledBullet, StyledFormControl, StyledImageWrapper, StyledSwiper, StyledWrapper };