import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { pxToRem } from '@pwa/ui';
import { LinearProgress, linearProgressClasses, styled } from '@mui/material';
var StyledLinearProgress = styled(LinearProgress)(function (_ref) {
  var _styles;

  var theme = _ref.theme,
      $isFull = _ref.$isFull;
  var styles = (_styles = {}, _defineProperty(_styles, "&.".concat(linearProgressClasses.colorPrimary), {
    backgroundColor: theme.palette.alpineGoat.main,
    borderRadius: pxToRem(40)
  }), _defineProperty(_styles, "& .".concat(linearProgressClasses.bar), {
    backgroundColor: $isFull ? theme.palette.aragonGreen.main : theme.palette.primary.main,
    borderRadius: pxToRem(40)
  }), _defineProperty(_styles, "borderRadius", pxToRem(40)), _styles);
  return styles;
});
export { StyledLinearProgress };