import { pxToRem, Typography } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledWrapper = styled(Box)(function (_ref) {
  var _theme$colors;

  var theme = _ref.theme;
  var styles = {
    border: "".concat(pxToRem(1), " solid ").concat(theme === null || theme === void 0 ? void 0 : (_theme$colors = theme.colors) === null || _theme$colors === void 0 ? void 0 : _theme$colors.lavenderSyrup),
    borderRadius: pxToRem(12),
    padding: theme.spacing(1.5)
  };
  return styles;
});
var StyledBox = styled(Box)(function () {
  var styles = {
    flex: 'auto',
    maxWidth: "calc(100% - ".concat(pxToRem(36), ")")
  };
  return styles;
});
var StyledTitle = styled(Box)(function () {
  var styles = {
    flex: 'auto 1 1'
  };
  return styles;
});
var StyledText = styled(Box)(function () {
  var styles = {
    flex: 'auto 0 0',
    maxWidth: '50%'
  };
  return styles;
});
var StyledTypography = styled(Typography)(function () {
  var styles = {
    wordBreak: 'break-all'
  };
  return styles;
});
export { StyledBox, StyledText, StyledTitle, StyledTypography, StyledWrapper };