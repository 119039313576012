import { __rest as r, __assign as o } from "../../_virtual/_tslib.js";
import { jsx as i, jsxs as n } from "react/jsx-runtime";
import * as t from "react";
import { Grid as c, Typography as e } from "@mui/material";
import { Icon as m } from "../icon/icon.component.js";
import { StyledCardButton as a, StyledIconWrapper as l } from "./card-button.styles.js";
var s = t.forwardRef(function (t, s) {
  var p = t.icon,
      d = t.iconBg,
      f = t.iconBgCustom,
      u = t.iconColor,
      g = t.children,
      h = r(t, ["icon", "iconBg", "iconBgCustom", "iconColor", "children"]);
  return i(a, o({
    ref: s,
    size: "extraLarge"
  }, h, {
    children: n(c, o({
      alignItems: "center",
      container: !0,
      spacing: 1.5,
      wrap: "nowrap"
    }, {
      children: [p && i(c, o({
        item: !0
      }, {
        children: i(l, o({
          $iconBg: d,
          $iconBgCustom: f
        }, {
          children: i(m, {
            color: u,
            src: p
          })
        }))
      })), i(c, o({
        item: !0
      }, {
        children: "string" == typeof g ? i(e, {
          children: g
        }) : g
      }))]
    }))
  }));
});
s.displayName = "CardButton";

var p = function (r) {
  return i(s, o({}, r));
};

export { p as CardButton };