import { __rest as r, __assign as o } from "../../_virtual/_tslib.js";
import { jsx as i } from "react/jsx-runtime";
import { SvgIcon as m } from "@mui/material";
import { StyledIcon as t } from "./icon.styles.js";

var e = function (e) {
  var a = e.className,
      c = e.color,
      l = r(e, ["className", "color"]);
  return i(m, o({
    className: a,
    color: c,
    component: "i",
    inheritViewBox: !0
  }, {
    children: i(t, o({
      color: c
    }, l))
  }));
};

export { e as Icon };