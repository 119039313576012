import { Backdrop, Dialog, Paper, styled } from '@mui/material';
import { pxToRem } from '@themes';
var StyledDialog = styled(Dialog)(function (_ref) {
  var theme = _ref.theme;
  var styles = {};
  return styles;
});
var StyledPaper = styled(Paper)(function (_ref2) {
  var theme = _ref2.theme,
      width = _ref2.width;
  var styles = {
    borderRadius: pxToRem(12),
    margin: theme.spacing(2),
    width: width ? pxToRem(width) : undefined
  };
  return styles;
});
var StyledBackdrop = styled(Backdrop)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    backgroundColor: '#0E0E22B2'
  };
  return styles;
});
export { StyledBackdrop, StyledDialog, StyledPaper };