import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { Currency, NewCardId, PaymentsCardsEntryPoint } from '@enums';

/**
 * Gets the account cards slides from.
 *
 * @author Yury Kuntsou
 * @function getAccountCardsSlidesFrom
 * @category Interceptors
 * @param {AccountCard[]} cards - The cards to filter.
 * @param {P2PCardsSettings[]} settings - The settings to use for filtering.
 * @returns {AccountCard[]} The filtered cards.
 */
export var getAccountCardsSlidesFrom = function getAccountCardsSlidesFrom(cards, settings) {
  if (!cards) {
    return [];
  }

  if (!settings) {
    return cards;
  }

  var filteredCards = cards.filter(function (card) {
    return settings.some(function (setting) {
      return card.extraRsTech === setting.cardFrom;
    });
  });
  return filteredCards;
};
/**
 * Sorts an array of AccountCard objects by the currency BYN.
 *
 * @author Yury Kuntsou
 * @function getAccountCardsOtherSlidesFrom
 * @param {AccountCard[]} cards - The array of AccountCard objects to be sorted.
 * @return {AccountCard[]} - The sorted array of AccountCard objects.
 */

var sortUserCardByBYN = function sortUserCardByBYN(cards) {
  if (!cards) {
    return [];
  }

  return _toConsumableArray(cards).sort(function (a, b) {
    if (a.cardCurrency === Currency.BYN && b.cardCurrency !== Currency.BYN) {
      return -1;
    } else if (a.cardCurrency !== Currency.BYN && b.cardCurrency === Currency.BYN) {
      return 1;
    } else {
      return 0;
    }
  });
};
/**
 * Gets the account cards other slides from.
 *
 * @author Yury Kuntsou
 * @function getAccountCardsOtherSlidesFrom
 * @category Interceptors
 * @param {AccountCardsOther[]} cards - The cards to filter.
 * @param {P2PCardsSettings[]} settings - The settings to use for filtering.
 * @returns {AccountCardsOther[]} The filtered cards.
 */


export var getAccountCardsOtherSlidesFrom = function getAccountCardsOtherSlidesFrom(cards, settings) {
  if (!cards) {
    return [];
  }

  var filteredCards = [];

  var _loop = function _loop(index) {
    var element = cards.find(function (card) {
      return !card.onlyRecipient && card.cardId === settings[index].cardFrom;
    });

    if (element) {
      filteredCards.push(element);
    }
  };

  for (var index = 0; index < settings.length; index++) {
    _loop(index);
  }

  return filteredCards;
};
/**
 * Gets the account cards slides.
 *
 * @author Yury Kuntsou
 * @function getAccountCardsSlides
 * @category Interceptors
 * @param {AccountCard[]} cards - The cards to filter.
 * @param {P2PCardsSettings} setting - The setting to use for filtering.
 * @returns {AccountCard[]} The filtered cards.
 */

export var getAccountCardsSlides = function getAccountCardsSlides(cards, setting, entryPoint) {
  if (!cards) {
    return [];
  }

  if (!setting) {
    return cards;
  }

  var filteredCards = [];

  var _loop2 = function _loop2(index) {
    var element = cards.find(function (card) {
      return card.extraRsTech === setting.cardTo[index];
    });

    if (element) {
      filteredCards.push(element);
    }
  };

  for (var index = 0; index < setting.cardTo.length; index++) {
    _loop2(index);
  }

  if (entryPoint !== PaymentsCardsEntryPoint.HISTORY) {
    var cardFromIndex = cards.findIndex(function (card) {
      return card.extraRsTech === setting.cardFrom;
    });

    if (cardFromIndex !== -1) {
      var userCards = cards.filter(function (card) {
        return card.iban !== cards[cardFromIndex].iban;
      });
      var array = new Set([].concat(_toConsumableArray(userCards), filteredCards));
      return sortUserCardByBYN(_toConsumableArray(array));
    }
  }

  return sortUserCardByBYN(filteredCards);
};
/**
 * Get account cards other slides.
 *
 * @author Yury Kuntsou
 * @function getAccountCardsOtherSlides
 * @category Slider
 * @param {AccountCardsOther[]} cards - The array of cards to get the slides from.
 * @param {P2PCardsSettings} setting - The settings for the slider.
 * @returns {AccountCardsOther[]} The array of filtered cards.
 */

export var getAccountCardsOtherSlides = function getAccountCardsOtherSlides(cards, setting) {
  if (!cards) {
    return [];
  }

  if (!setting) {
    return cards;
  }

  var filteredCards = [];

  var _loop3 = function _loop3(index) {
    var element = cards.find(function (card) {
      return card.cardId === setting.cardTo[index];
    });

    if (element) {
      filteredCards.push(element);
    }
  };

  for (var index = 0; index < setting.cardTo.length; index++) {
    _loop3(index);
  }

  return filteredCards;
};
/**
 * Get payment cards slider settings.
 *
 * @author Yury Kuntsou
 * @function getPaymentCardsSliderSettings
 * @category Slider
 * @param {P2PCardsSettings[]} settings - The array of settings for the slider.
 * @param {AccountCard[]} userCards - The array of user's cards.
 * @param {AccountCardsOther[]} otherCards - The array of other cards.
 * @returns {PaymentCardsSliderSettings} The settings for the slider.
 */

export var getPaymentCardsSliderSettings = function getPaymentCardsSliderSettings(settings, userCards, otherCards, entryPoint) {
  return settings.reduce(function (acc, setting) {
    var filteredUserCards = getAccountCardsSlides(userCards, setting, entryPoint);
    var filteredOtherCards = getAccountCardsOtherSlides(otherCards, setting);
    return _objectSpread(_defineProperty({}, setting.cardFrom, {
      disableNewCard: !setting.cardTo.includes(NewCardId.NEW),
      otherCards: filteredOtherCards,
      userCards: filteredUserCards
    }), acc);
  }, {});
};