import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, styled } from '@mui/material';
var StyledContent = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    backgroundColor: theme.palette.white.main,
    borderTopLeftRadius: theme.typography.pxToRem(16),
    borderTopRightRadius: theme.typography.pxToRem(16),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingBottom: theme.spacing(8),
    paddingTop: theme.spacing(4)
  }, theme.breakpoints.up('desktop'), {
    backgroundColor: 'unset',
    borderRadius: 'unset'
  });

  return styles;
});
var StyledDesktopWrapper = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;

  var styles = _defineProperty({}, theme.breakpoints.up('desktop'), {
    maxWidth: theme.typography.pxToRem(452)
  });

  return styles;
});
export { StyledContent, StyledDesktopWrapper };