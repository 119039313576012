import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';
import { ConstantsUtil } from '@utils';
import { CLOSE_DRAWER, CLOSING_DRAWER_WITH_ANSWER, DRAWER_INIT, INIT_CLOSE_DRAWER, INIT_DRAWER_CLOSING_WITH_ANSWER, REMOVE_DRAWER, SET_DRAWER } from './drawers.action-types';
export var actions = {
  /**
   * Close drawer with answer.
   *
   * @author Mikhail Siutsou
   * @function closeWithAnswer
   * @category Actions
   * @subcategory Drawers
   * @param {DrawerCloseWithAnswerPayload} payload payload.
   */
  closeWithAnswer: function closeWithAnswer(payload) {
    return {
      payload: payload,
      type: CLOSING_DRAWER_WITH_ANSWER
    };
  },

  /**
   * Close drawer.
   *
   * @author Mikhail Siutsou
   * @function drawerClose
   * @category Actions
   * @subcategory Drawers
   * @param {DrawerClosePayload} payload payload.
   */
  drawerClose: function drawerClose(payload) {
    return {
      payload: payload,
      type: CLOSE_DRAWER
    };
  },

  /**
   * Init drawer opening.
   *
   * @author Mikhail Siutsou
   * @function drawerInit
   * @category Actions
   * @subcategory Drawers
   * @param {DrawerPayload} payload payload.
   */
  drawerInit: function drawerInit(payload) {
    var _ref;

    return _ref = {}, _defineProperty(_ref, ERROR_ACTION, "".concat(DRAWER_INIT, "/").concat(ConstantsUtil.actions.ASYNC_FAILED)), _defineProperty(_ref, WAIT_FOR_ACTION, "".concat(DRAWER_INIT, "/").concat(ConstantsUtil.actions.ASYNC_SUCCESS)), _defineProperty(_ref, "payload", payload), _defineProperty(_ref, "type", DRAWER_INIT), _ref;
  },

  /**
   * Init drawer closing.
   *
   * @author Mikhail Siutsou
   * @function drawerInitClosing
   * @category Actions
   * @subcategory Drawers
   * @param {DrawerClosePayload} payload payload.
   */
  drawerInitClosing: function drawerInitClosing(payload) {
    return {
      payload: payload,
      type: INIT_CLOSE_DRAWER
    };
  },

  /**
   * Init closing with answer.
   *
   * @author Mikhail Siutsou
   * @function drawerInitClosingWithAnswer
   * @category Actions
   * @subcategory Drawers
   * @param {DrawerCloseWithAnswerPayload} payload payload.
   */
  drawerInitClosingWithAnswer: function drawerInitClosingWithAnswer(payload) {
    return {
      payload: payload,
      type: INIT_DRAWER_CLOSING_WITH_ANSWER
    };
  },

  /**
   * Remove popup with answer.
   *
   * @author Yury Kuntsou
   * @function removeDrawer
   * @category Actions
   * @subcategory Drawers
   * @param {DrawerClosePayload} payload payload.
   */
  removeDrawer: function removeDrawer(payload) {
    return {
      payload: payload,
      type: REMOVE_DRAWER
    };
  },

  /**
   * Set necessary drawer.
   *
   * @author Mikhail Siutsou
   * @function setDrawer
   * @category Actions
   * @subcategory Drawers
   * @param {DrawerPayload} payload payload.
   */
  setDrawer: function setDrawer(payload) {
    return {
      payload: payload,
      type: SET_DRAWER
    };
  }
};