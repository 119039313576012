import { __read as e, __assign as n } from "../../../../../_virtual/_tslib.js";
import { jsx as t, jsxs as r } from "react/jsx-runtime";
import { useMemo as o, useState as a, useEffect as i } from "react";
import d from "../../../../../node_modules/date-fns/esm/endOfMonth/index.js";
import m from "../../../../../node_modules/date-fns/esm/format/index.js";
import s from "../../../../../node_modules/date-fns/esm/getDate/index.js";
import f from "../../../../../node_modules/date-fns/esm/getDaysInMonth/index.js";
import u from "../../../../../node_modules/date-fns/esm/getMonth/index.js";
import l from "../../../../../node_modules/date-fns/esm/getYear/index.js";
import c from "../../../../../node_modules/date-fns/esm/isEqual/index.js";
import h from "../../../../../node_modules/date-fns/esm/isSameMonth/index.js";
import D from "../../../../../node_modules/date-fns/esm/subMonths/index.js";
import { StyledCalendarWrapper as v, StyledCenterBox as p, StyledCalendarWheelWrapper as w } from "./calendar-wheel.styles.js";
import { WheelSlider as x } from "../wheel-slider/wheel-slider.component.js";

var g = function (g) {
  var j = g.minDate,
      M = g.maxDate,
      _ = g.onChange,
      y = g.value,
      b = g.locale,
      C = g.disableDays,
      O = g.disableMonths,
      Y = g.disableYears,
      A = o(function () {
    return j ? new Date(j) : D(new Date(), 1);
  }, [j]),
      F = o(function () {
    return M ? new Date(M) : new Date();
  }, [M]),
      q = o(function () {
    return y && new Date(y);
  }, [y]),
      E = e(a(q || A), 2),
      I = E[0],
      S = E[1],
      k = o(function () {
    return Array.from({
      length: l(F) - l(A) + 1
    }, function (e, n) {
      return l(A) + n;
    });
  }, [F, A]),
      z = o(function () {
    var e = l(I) === l(A) ? u(A) + 1 : 1,
        n = l(I) === l(F) ? u(F) + 1 : 12;
    return Array.from({
      length: n + 1 - e
    }, function (n, t) {
      return e + t;
    });
  }, [I.getFullYear(), A, F]),
      B = o(function () {
    var e = s(d(I)),
        n = h(I, A) ? s(A) : 1,
        t = h(I, F) ? s(F) : e;
    return Array.from({
      length: t + 1 - n
    }, function (e, t) {
      return n + t;
    });
  }, [I.getMonth(), I.getFullYear(), A, F]),
      G = function (e, n, t) {
    var r = l(A),
        o = l(F);

    if (e <= r) {
      var a = u(A),
          i = s(A),
          d = n < a ? a : n,
          m = f(new Date(r, d));
      return new Date(r, d, d === a ? t > m ? m : t < i ? i : t : t > m ? m : t);
    }

    if (e >= o) {
      var c = u(F),
          h = s(F),
          D = (d = n > c ? c : n, f(new Date(o, d)));
      return new Date(o, d, d === c ? t > h ? h : t : t > D ? D : t);
    }

    var v = f(new Date(e, n));
    return new Date(e, n, t > v ? v : t);
  };

  return i(function () {
    _ && !c(I, q || A) && _(I);
  }, [A, _, I, q]), t("div", {
    children: r(v, {
      children: [t(p, {}), t(w, n({
        hidden: C
      }, {
        children: t(x, {
          data: B,
          value: B.indexOf(s(I)),
          onChange: function (e) {
            var n = G(l(I), u(I), B[e]);
            c(I, n) || S(n);
          }
        })
      })), t(w, n({
        hidden: O
      }, {
        children: t(x, {
          data: z,
          formatData: function (e) {
            return m(new Date(0, +e - 1), "MMMM", {
              locale: b
            });
          },
          value: z.indexOf(u(I) + 1),
          onChange: function (e) {
            var n = G(l(I), z[e] - 1, s(I));
            c(I, n) || S(n);
          }
        })
      })), t(w, n({
        hidden: Y
      }, {
        children: t(x, {
          data: k,
          value: k.indexOf(l(I)),
          onChange: function (e) {
            var n = G(k[e], u(I), s(I));
            c(I, n) || S(n);
          }
        })
      }))]
    })
  });
};

export { g as CalendarWheel };