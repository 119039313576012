import { styled as a, Paper as e, Box as t } from "@mui/material";
var r = a(e)(function (a) {
  return {
    padding: a.theme.spacing(1.5, 2)
  };
}),
    i = a(t)(function (a) {
  return a.theme, {
    borderCollapse: "collapse",
    borderSpacing: 0,
    display: "table",
    tableLayout: a.$tableLayout,
    width: "100%"
  };
}),
    n = a(t)(function (a) {
  a.theme;
  return {
    display: "table-row"
  };
}),
    o = a(t)(function (a) {
  return {
    display: "table-cell",
    padding: a.theme.spacing(1),
    verticalAlign: "middle"
  };
}),
    l = a(t)(function (a) {
  var e = a.theme;
  return {
    backgroundColor: e.colors.whiteSolid,
    borderRadius: "".concat(e.typography.pxToRem(14), " 0px"),
    marginBottom: e.spacing(1),
    marginTop: e.spacing(1.5),
    padding: e.spacing(2)
  };
});
export { l as StyledFooterWrapper, r as StyledPaper, i as StyledTable, o as StyledTableCell, n as StyledTableRow };