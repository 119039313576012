import { __rest as r, __assign as t } from "../../_virtual/_tslib.js";
import { jsx as a } from "react/jsx-runtime";
import { StyledTypography as o } from "./typography.styles.js";

var i = function (i) {
  var n = i.color,
      m = i.contrast,
      c = i.variant,
      e = i.maxLine,
      s = r(i, ["color", "contrast", "variant", "maxLine"]),
      l = n && "inherit" !== n ? "".concat(n, m ? ".contrastText" : ".main") : n;
  return a(o, t({}, s, {
    $maxLine: e,
    color: l,
    variant: c
  }));
};

export { i as Typography };