import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledButtonWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(2.5)
  };
  return styles;
});
var StyledCheckboxWrapper = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    paddingLeft: theme.spacing(1)
  };
  return styles;
});
var StyledBox = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    marginTop: theme.spacing(2)
  };
  return styles;
});
var StyledBoxWrapper = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    backgroundColor: theme.palette.white.main,
    borderRadius: pxToRem(12),
    padding: theme.spacing(3, 0),
    textAlign: 'center'
  };
  return styles;
});
var StyledImageWrapper = styled(Box)(function (_ref5) {
  var theme = _ref5.theme;

  var styles = _defineProperty({
    '& img': {
      height: '100%',
      objectFit: 'cover',
      width: '100%'
    },
    marginLeft: theme.spacing(9.75),
    marginRight: theme.spacing(9.75),
    maxWidth: pxToRem(300),
    position: 'relative'
  }, theme.breakpoints.up(456), {
    margin: 'auto'
  });

  return styles;
});
export { StyledBox, StyledBoxWrapper, StyledButtonWrapper, StyledCheckboxWrapper, StyledImageWrapper };