import { styled as o, ButtonGroup as r, buttonGroupClasses as e, buttonClasses as t } from "@mui/material";
var a = o(r)(function (o) {
  var r,
      a,
      n = o.theme,
      c = o.$spacing,
      p = void 0 === c ? 0 : c,
      i = o.$isDark,
      d = {
    border: "none",
    borderRadius: n.typography.pxToRem(12)
  };
  return (r = {
    backgroundColor: i ? n.colors.ghostWhite : null === (a = null == n ? void 0 : n.colors) || void 0 === a ? void 0 : a.white,
    borderRadius: n.typography.pxToRem(16),
    padding: n.spacing(.5)
  })[".".concat(e.grouped, " + .").concat(e.grouped)] = {
    marginLeft: n.spacing(Number(p))
  }, r[".".concat(e.grouped, ":not(:last-of-type)")] = d, r[".".concat(e.grouped, ":not(:first-of-type)")] = d, r[".".concat(e.grouped, ".").concat(t.text, ":hover")] = {
    color: n.colors.abandonedSpaceship
  }, r;
});
export { a as StyledButtonGroup };