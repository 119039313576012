import { Currency as s, currencyISOCode as e } from "../../enums/entities/entities.enum.js";
import "../../enums/keyboard/keyboard.enum.js";
import "../../enums/theme/theme.enum.js";
import a from "../../packages/pwa-ui/assets/images/flags/flag-byn.image.svg.js";
import m from "../../packages/pwa-ui/assets/images/flags/flag-cny.images.svg.js";
import r from "../../packages/pwa-ui/assets/images/flags/flag-eur.image.svg.js";
import g from "../../packages/pwa-ui/assets/images/flags/flag-rub.image.svg.js";
import i from "../../packages/pwa-ui/assets/images/flags/flag-usd.image.svg.js";

var t = function (e) {
  switch (e) {
    case s.USD:
      return i;

    case s.EUR:
      return r;

    case s.RUB:
      return g;

    case s.CNY:
      return m;

    case s.BYN:
    default:
      return a;
  }
},
    u = function (s) {
  return e[s];
};

export { u as getCurrencyByISOCode, t as getCurrencyFlag };