import { __rest as o, __assign as r } from "../../_virtual/_tslib.js";
import { createElement as t } from "react";
import { jsx as i } from "react/jsx-runtime";
import { Icon as n } from "../icon/icon.component.js";
import { StyledBottomNavigation as a, StyledBottomNavigationAction as c } from "./bottom-navigation.styles.js";

var m = function (m) {
  var e = m.actions,
      s = o(m, ["actions"]);
  return i(a, r({}, s, {
    children: e.map(function (a) {
      var m = a.icon,
          e = o(a, ["icon"]);
      return t(c, r({}, e, {
        key: e.label,
        icon: i(n, {
          color: s.value === e.value ? "primary" : "secondary",
          src: m
        })
      }));
    })
  }));
};

export { m as BottomNavigation };