import { Button, pxToRem } from '@pwa/ui';
import { Box, Stack, styled } from '@mui/material';
var StyledButton = styled(Button)(function (_ref) {
  var _theme$palette;

  var theme = _ref.theme;
  var styles = {
    '&:not(:last-child)::after': {
      backgroundColor: theme === null || theme === void 0 ? void 0 : (_theme$palette = theme.palette) === null || _theme$palette === void 0 ? void 0 : _theme$palette.lavenderSyrup.main,
      bottom: 0,
      content: "''",
      display: 'block',
      height: pxToRem(1),
      left: pxToRem(68),
      position: 'absolute',
      right: pxToRem(16)
    },
    height: 'unset',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    position: 'relative'
  };
  return styles;
});
var StyledContent = styled(Stack)(function () {
  var styles = {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  };
  return styles;
});
var StyledIconWrapper = styled(Box)(function () {
  var styles = {
    borderRadius: pxToRem(10),
    fontSize: '0',
    lineHeight: '0'
  };
  return styles;
});
export { StyledButton, StyledContent, StyledIconWrapper };