import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { pxToRem } from '@pwa/ui';
import { Box, Stack, styled } from '@mui/material';
var StyledInformationWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(2)
  }, theme.breakpoints.up('desktop'), {
    maxWidth: theme.typography.pxToRem(452)
  });

  return styles;
});
var StyledInformation = styled(Stack)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    backgroundColor: '#FFFFFF',
    borderRadius: pxToRem(16),
    padding: theme.spacing(2)
  };
  return styles;
});
export { StyledInformation, StyledInformationWrapper };