import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';
import { ConstantsUtil } from '@utils';
import { FETCH_USER_FAILURE, FETCH_USER_INFO_FAILURE, FETCH_USER_INFO_REQUEST, FETCH_USER_INFO_SUCCESS, FETCH_USER_REQUEST, FETCH_USER_SUCCESS, LOGOUT_USER_FAILURE, LOGOUT_USER_REQUEST, LOGOUT_USER_SUCCESS } from './auth.action-types';
export var actions = {
  /**
   * Fetch user request failure action.
   *
   * @author Mikhail Siutsou
   * @function fetchUserFailure
   * @category Actions
   * @subcategory Auth
   * @param {FetchUserFailurePayload} payload payload.
   * @return {fetchUserFailure} fetch user request failure.
   */
  fetchUserFailure: function fetchUserFailure(payload) {
    return {
      payload: payload,
      type: FETCH_USER_FAILURE
    };
  },

  /**
   * Fetch user info request failure action.
   *
   * @author Christina Kashevar
   * @function fetchUserInfoFailure
   * @category Actions
   * @subcategory Auth
   * @param {FetchUserFailurePayload} payload payload.
   * @return {fetchUserFailure} fetch user request failure.
   */
  fetchUserInfoFailure: function fetchUserInfoFailure(payload) {
    return {
      payload: payload,
      type: FETCH_USER_INFO_FAILURE
    };
  },

  /**
   * Fetch user request action.
   *
   * @author Christina Kashevar
   * @function fetchUserInfoRequest
   * @category Actions
   * @subcategory Auth
   * @return {fetchUserInfoRequest} fetch user info request.
   */
  fetchUserInfoRequest: function fetchUserInfoRequest() {
    var _ref;

    return _ref = {}, _defineProperty(_ref, ERROR_ACTION, "".concat(FETCH_USER_INFO_REQUEST, "/").concat(ConstantsUtil.actions.ASYNC_FAILED)), _defineProperty(_ref, WAIT_FOR_ACTION, "".concat(FETCH_USER_INFO_REQUEST, "/").concat(ConstantsUtil.actions.ASYNC_SUCCESS)), _defineProperty(_ref, "type", FETCH_USER_INFO_REQUEST), _ref;
  },

  /**
   * Fetch user info request success action.
   *
   * @author Christina Kashevar
   * @function fetchUserInfoSuccess
   * @category Actions
   * @subcategory Auth
   * @param {FetchUserSuccessPayload} payload payload.
   * @return {fetchUserSuccess} fetch user success.
   */
  fetchUserInfoSuccess: function fetchUserInfoSuccess(payload) {
    return {
      payload: payload,
      type: FETCH_USER_INFO_SUCCESS
    };
  },

  /**
   * Fetch user request action.
   *
   * @author Mikhail Siutsou
   * @function fetchUserRequest
   * @category Actions
   * @subcategory Auth
   * @return {fetchUserRequest} fetch user request.
   */
  fetchUserRequest: function fetchUserRequest() {
    var _ref2;

    return _ref2 = {}, _defineProperty(_ref2, ERROR_ACTION, "".concat(FETCH_USER_REQUEST, "/").concat(ConstantsUtil.actions.ASYNC_FAILED)), _defineProperty(_ref2, WAIT_FOR_ACTION, "".concat(FETCH_USER_REQUEST, "/").concat(ConstantsUtil.actions.ASYNC_SUCCESS)), _defineProperty(_ref2, "type", FETCH_USER_REQUEST), _ref2;
  },

  /**
   * Fetch user request success action.
   *
   * @author Mikhail Siutsou
   * @function fetchUserSuccess
   * @category Actions
   * @subcategory Auth
   * @param {FetchUserSuccessPayload} payload payload.
   * @return {fetchUserSuccess} fetch user success.
   */
  fetchUserSuccess: function fetchUserSuccess(payload) {
    return {
      payload: payload,
      type: FETCH_USER_SUCCESS
    };
  },

  /**
   * Logout user request failure action.
   *
   * @author Mikhail Siutsou
   * @function logoutUserFailure
   * @category Actions
   * @subcategory Auth
   * @param {LogoutUserFailurePayload} payload payload.
   * @return {logoutUserFailure} login user request failure.
   */
  logoutUserFailure: function logoutUserFailure(payload) {
    return {
      payload: payload,
      type: LOGOUT_USER_FAILURE
    };
  },

  /**
   * Logout user request action.
   *
   * @author Mikhail Siutsou
   * @function logoutUserRequest
   * @category Actions
   * @subcategory Auth
   * @return {logoutUserRequest} logout user request.
   */
  logoutUserRequest: function logoutUserRequest() {
    var _ref3;

    return _ref3 = {}, _defineProperty(_ref3, ERROR_ACTION, "".concat(LOGOUT_USER_REQUEST, "/").concat(ConstantsUtil.actions.ASYNC_FAILED)), _defineProperty(_ref3, WAIT_FOR_ACTION, "".concat(LOGOUT_USER_REQUEST, "/").concat(ConstantsUtil.actions.ASYNC_SUCCESS)), _defineProperty(_ref3, "type", LOGOUT_USER_REQUEST), _ref3;
  },

  /**
   * Logout user request success action.
   *
   * @author Mikhail Siutsou
   * @function logoutUserSuccess
   * @category Actions
   * @subcategory Auth
   * @return {logoutUserSuccess} logout user success.
   */
  logoutUserSuccess: function logoutUserSuccess() {
    return {
      type: LOGOUT_USER_SUCCESS
    };
  }
};