import moment from 'moment';
import { dateFormatter } from '@pwa/ui';
import { i18n } from '@localization';
/**
 * Returns a text for the period when no operations have been found.
 *
 * @author Yury Kuntsou
 * @function getNotFoundHistoryText
 * @category Helpers
 * @param {string} [dateFrom] - The start date of the period.
 * @param {string} [dateTo] - The end date of the period.
 * @returns {(string | null)} The text for the period when no operations have been found.
 */

export var getNotFoundHistoryText = function getNotFoundHistoryText(dateFrom, dateTo) {
  if (!dateFrom && !dateTo) return null;
  var dateFromFormatted = dateFrom && dateFormatter(new Date(dateFrom), 'dd.MM.yyyy');
  var dateToFormatted = dateTo && dateFormatter(new Date(dateTo), 'dd.MM.yyyy');

  if (dateFrom && dateTo && moment(dateFrom).isSame(moment(dateTo), 'day')) {
    return i18n.t('components.historyOperations.notFoundHistory', {
      date: dateFromFormatted
    });
  }

  if (dateFrom && !dateTo) {
    return i18n.t('components.historyOperations.notFoundHistoryFrom', {
      dateFrom: dateFromFormatted
    });
  }

  if (!dateFrom && dateTo) {
    return i18n.t('components.historyOperations.notFoundHistoryTo', {
      dateTo: dateToFormatted
    });
  }

  return i18n.t('components.historyOperations.notFoundHistoryFromTo', {
    dateFrom: dateFromFormatted,
    dateTo: dateToFormatted
  });
};