import { __assign as r } from "../../../../_virtual/_tslib.js";
import { jsxs as t, jsx as o, Fragment as n } from "react/jsx-runtime";
import { Box as e } from "@mui/material";
import { CurrencyTypography as a } from "../../../currency-typography/currency-typography.component.js";
import { Typography as i } from "../../../typography/typography.component.js";

var c = function (c) {
  var p = c.secondCurr,
      m = c.secondAmount,
      y = c.thirdCurr,
      g = c.thirdAmount,
      u = c.isLoading;
  return t(e, r({
    mt: .25
  }, {
    children: [("string" == typeof m || "number" == typeof m) && o(a, {
      amount: m,
      color: u ? "bayFog" : "abandonedSpaceship",
      currency: p,
      fontWeightDecimal: 500,
      fontWeightInteger: 500,
      variantDecimal: "caption1",
      variantInteger: "caption1"
    }), ("string" == typeof g || "number" == typeof g) && t(n, {
      children: [o(i, r({
        color: "abandonedSpaceship",
        variant: "caption1"
      }, {
        children: " | "
      })), o(a, {
        amount: g,
        color: u ? "bayFog" : "abandonedSpaceship",
        currency: y,
        fontWeightDecimal: 500,
        fontWeightInteger: 500,
        variantDecimal: "caption1",
        variantInteger: "caption1"
      })]
    })]
  }));
};

export { c as AccountMoneyInfo };