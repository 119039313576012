import { pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledBox = styled(Box)(function (_ref) {
  var _theme$colors, _theme$colors2;

  var theme = _ref.theme;
  var styles = {
    backdropFilter: "blur(".concat(pxToRem(2), ")"),
    backgroundColor: theme === null || theme === void 0 ? void 0 : (_theme$colors = theme.colors) === null || _theme$colors === void 0 ? void 0 : _theme$colors.alpineGoat,
    border: "".concat(pxToRem(1), " solid ").concat(theme === null || theme === void 0 ? void 0 : (_theme$colors2 = theme.colors) === null || _theme$colors2 === void 0 ? void 0 : _theme$colors2.estateViolet),
    borderRadius: pxToRem(8),
    marginTop: theme.spacing(0.75),
    padding: theme.spacing(1, 2),
    textAlign: 'center'
  };
  return styles;
});
export { StyledBox };