export var getBankAtms = function getBankAtms() {
  return {
    atms: [{
      accessibilities: [],
      affiliationCode: '0272',
      affiliationName: 'ЗАО "Банк Дабрабыт"',
      availability: {
        access24Hours: true,
        description: '24 часа',
        isRestricted: null,
        nonStandardAvailabilities: [],
        sameAsOrganization: null,
        standardAvailability: {
          days: []
        }
      },
      bankId: 'MMBNBY22',
      baseCurrency: 'BYN',
      cards: [],
      contactDetails: null,
      currencies: [],
      currencyExchanges: [],
      currentStatus: 'On',
      description: null,
      guid: '0117de98-d639-41b4-b6c2-7622a81d03d9',
      location: {
        addressLine: 'ул. Коммунистическая 24',
        buildingNumber: '24',
        country: 'BY',
        countrySubDivision: 'Брестская',
        department: null,
        description: 'ОАО "Банк Дабрабыт"',
        geolocation: {
          latitude: 52.0895,
          longitude: 23.685694
        },
        postCode: null,
        streetName: 'ул. Коммунистическая',
        townName: 'Брест'
      },
      services: [{
        additionalData: null,
        description: null,
        serviceType: 'PINActivation'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'PINUnblock'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'LoanRepayment'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'CashWithdraw'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'PINChange'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'BillPayments'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'Transfer'
      }],
      terminalId: '27290405',
      type: 'ATM'
    }, {
      accessibilities: [],
      affiliationCode: '0226',
      affiliationName: 'ОАО "Банк БелВЭБ"',
      availability: {
        access24Hours: true,
        description: null,
        isRestricted: null,
        nonStandardAvailabilities: [],
        sameAsOrganization: null,
        standardAvailability: {
          days: []
        }
      },
      bankId: 'BELBBY2X',
      baseCurrency: 'BYN',
      cards: [],
      contactDetails: null,
      currencies: ['USD'],
      currencyExchanges: [],
      currentStatus: 'On',
      description: null,
      guid: '013a1a25-0020-4587-9fd6-39c03582b3f5',
      location: {
        addressLine: 'ул. Скрыганова, 6',
        buildingNumber: '6',
        country: 'BY',
        countrySubDivision: 'Минская',
        department: null,
        description: 'ОАО "Банк БелВЭБ"',
        geolocation: {
          latitude: 53.91182,
          longitude: 27.516891
        },
        postCode: null,
        streetName: 'ул. Скрыганова',
        townName: 'Минск'
      },
      services: [{
        additionalData: null,
        description: null,
        serviceType: 'PINChange'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'CashWithdraw'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'PINActivation'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'BillPayments'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'PINUnblock'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'LoanRepayment'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'Transfer'
      }],
      terminalId: '00190012',
      type: 'ATM'
    }, {
      accessibilities: [],
      affiliationCode: '0226',
      affiliationName: 'ОАО "Банк БелВЭБ"',
      availability: {
        access24Hours: true,
        description: null,
        isRestricted: null,
        nonStandardAvailabilities: [],
        sameAsOrganization: null,
        standardAvailability: {
          days: []
        }
      },
      bankId: 'BELBBY2X',
      baseCurrency: 'BYN',
      cards: [],
      contactDetails: null,
      currencies: [],
      currencyExchanges: [],
      currentStatus: 'On',
      description: null,
      guid: '0345f54b-eaba-4842-b892-9de826ca96aa',
      location: {
        addressLine: 'пр-т Московский, 60а',
        buildingNumber: '60а',
        country: 'BY',
        countrySubDivision: 'Витебская',
        department: null,
        description: 'ТЦ "Евроопт"',
        geolocation: {
          latitude: 55.17708,
          longitude: 30.240425
        },
        postCode: null,
        streetName: 'пр-т Московский',
        townName: 'Витебск'
      },
      services: [{
        additionalData: null,
        description: null,
        serviceType: 'PINActivation'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'BillPayments'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'PINUnblock'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'PINChange'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'CashWithdraw'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'LoanRepayment'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'Transfer'
      }],
      terminalId: '00192108',
      type: 'ATM'
    }, {
      accessibilities: [],
      affiliationCode: '0110',
      affiliationName: 'ЗАО "РРБ–Банк"',
      availability: {
        access24Hours: false,
        description: 'в режиме работы организации',
        isRestricted: null,
        nonStandardAvailabilities: [],
        sameAsOrganization: null,
        standardAvailability: {
          days: []
        }
      },
      bankId: 'REDJBY22',
      baseCurrency: 'BYN',
      cards: [],
      contactDetails: null,
      currencies: [],
      currencyExchanges: [],
      currentStatus: 'On',
      description: null,
      guid: '04372f12-b70d-4500-982b-a9ea1bf46d31',
      location: {
        addressLine: 'пр-т Независимости 95',
        buildingNumber: '95',
        country: 'BY',
        countrySubDivision: 'Минская',
        department: null,
        description: 'СООО "Mобильные TелеCистемы"',
        geolocation: {
          latitude: 53.92583,
          longitude: 27.616573
        },
        postCode: null,
        streetName: 'пр-т Независимости',
        townName: 'Минск'
      },
      services: [{
        additionalData: null,
        description: null,
        serviceType: 'BillPayments'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'Transfer'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'LoanRepayment'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'PINActivation'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'PINUnblock'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'PINChange'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'CashWithdraw'
      }],
      terminalId: '11090037',
      type: 'ATM'
    }, {
      accessibilities: [],
      affiliationCode: '0226',
      affiliationName: 'ОАО "Банк БелВЭБ"',
      availability: {
        access24Hours: false,
        description: null,
        isRestricted: null,
        nonStandardAvailabilities: [],
        sameAsOrganization: null,
        standardAvailability: {
          days: []
        }
      },
      bankId: 'BELBBY2X',
      baseCurrency: 'BYN',
      cards: [],
      contactDetails: null,
      currencies: ['USD'],
      currencyExchanges: [],
      currentStatus: 'On',
      description: null,
      guid: '05799144-b76c-418b-8222-0f43c95cb408',
      location: {
        addressLine: 'ул. Белуша, 56',
        buildingNumber: '56',
        country: 'BY',
        countrySubDivision: 'Гродненская',
        department: null,
        description: 'Гипермаркет "Материк"',
        geolocation: {
          latitude: 53.671295,
          longitude: 23.865833
        },
        postCode: null,
        streetName: 'ул. Белуша',
        townName: 'Гродно'
      },
      services: [{
        additionalData: null,
        description: null,
        serviceType: 'Transfer'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'CashWithdraw'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'LoanRepayment'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'BillPayments'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'PINActivation'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'PINUnblock'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'PINChange'
      }],
      terminalId: '00194109',
      type: 'ATM'
    }, {
      accessibilities: [],
      affiliationCode: '0226',
      affiliationName: 'ОАО "Банк БелВЭБ"',
      availability: {
        access24Hours: true,
        description: '24 часа',
        isRestricted: null,
        nonStandardAvailabilities: [],
        sameAsOrganization: null,
        standardAvailability: {
          days: []
        }
      },
      bankId: 'BELBBY2X',
      baseCurrency: 'BYN',
      cards: [],
      contactDetails: null,
      currencies: [],
      currencyExchanges: [],
      currentStatus: 'TempOff',
      description: null,
      guid: '05ac8e65-e0ce-4086-a65a-0bf92bc22933',
      location: {
        addressLine: 'ул. Семашко, 8',
        buildingNumber: '8',
        country: 'BY',
        countrySubDivision: '',
        department: null,
        description: 'МНПЦ хирургии, трансплантологии и гематологии',
        geolocation: {
          latitude: 53.85975,
          longitude: 27.495497
        },
        postCode: null,
        streetName: 'ул. Семашко,',
        townName: 'Минск'
      },
      services: [{
        additionalData: null,
        description: null,
        serviceType: 'CashWithdraw'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'CashIn'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'Transfer'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'LoanRepayment'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'PINActivation'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'PINUnblock'
      }, {
        additionalData: null,
        description: null,
        serviceType: 'PINChange'
      }],
      terminalId: '00190134',
      type: 'ATM'
    }],
    filters: {}
  };
};
export var getBankDepartments = function getBankDepartments() {
  return [{
    bankId: 'BELBBY2X',
    cfoCode: '86030',
    guid: '01b14c50-ea9d-4aee-b7be-0c397e6f6be4',
    informations: [{
      availability: {
        description: 'пн. – пт.: 9.00 – 18.30'
      }
    }],
    name: 'Сервисный офис №230',
    postalAddress: {
      addressLine: 'ул. Скорины 23',
      buildingNumber: '23',
      country: 'BY',
      countrySubDivision: null,
      department: null,
      description: null,
      geolocation: {
        latitude: 53.937345,
        longitude: 27.475096
      },
      postCode: null,
      streetName: 'ул. Скорины',
      townName: 'Полоцк'
    },
    services: [{
      currentStatus: 'Active',
      type: 'DirectDebit'
    }, {
      currentStatus: 'Active',
      type: 'Deposit'
    }, {
      currentStatus: 'Active',
      type: 'Card'
    }, {
      currentStatus: 'Active',
      type: 'Transfer'
    }, {
      currentStatus: 'Active',
      type: 'Loan'
    }, {
      currentStatus: 'Active',
      type: 'CurrencyExchange'
    }]
  }, {
    bankId: 'BELBBY2X',
    cfoCode: '57030',
    guid: '052850b5-dbdc-4235-86bc-7c8a41e68016',
    informations: [{
      availability: {
        description: 'пн. – пт.: 9.00 – 19.00'
      }
    }],
    name: 'Сервисный офис №700 (Premium Service)',
    postalAddress: {
      addressLine: 'ул. Заславская 10',
      buildingNumber: '10',
      country: 'BY',
      countrySubDivision: null,
      department: null,
      description: null,
      geolocation: {
        latitude: 53.910774,
        longitude: 27.539698
      },
      postCode: null,
      streetName: 'ул. Заславская',
      townName: 'Минск'
    },
    services: [{
      currentStatus: 'Active',
      type: 'DirectDebit'
    }, {
      currentStatus: 'Active',
      type: 'Card'
    }, {
      currentStatus: 'Active',
      type: 'Transfer'
    }, {
      currentStatus: 'Active',
      type: 'Loan'
    }]
  }, {
    bankId: 'BELBBY2X',
    cfoCode: '75030',
    guid: '20f3471c-fe6b-4f28-809f-9deafb6adf11',
    informations: [{
      availability: {
        description: 'пн. – пт.: 10.00 – 19.00'
      }
    }],
    name: 'Удаленное рабочее место сервисного офиса №725',
    postalAddress: {
      addressLine: 'ул. Притыцкого 93',
      buildingNumber: '93',
      country: 'BY',
      countrySubDivision: null,
      department: null,
      description: null,
      geolocation: {
        latitude: 53.90605,
        longitude: 27.44014
      },
      postCode: null,
      streetName: 'ул. Притыцкого',
      townName: 'Минск'
    },
    services: [{
      currentStatus: 'Active',
      type: 'DirectDebit'
    }, {
      currentStatus: 'Active',
      type: 'Card'
    }]
  }];
};