import { IconButton, pxToRem, Typography } from '@pwa/ui';
import { Box, Stack, styled } from '@mui/material';
var StyledIconButton = styled(IconButton)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    margin: theme.spacing(-0.625, -0.625, 0, 0)
  };
  return styles;
});
var StyledWrapper = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    backgroundColor: theme.colors.white,
    borderRadius: pxToRem(16),
    boxShadow: theme.boxShadows.independence
  };
  return styles;
});
var StyledContentWrapper = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    padding: theme.spacing(2)
  };
  return styles;
});
var StyledImageWrapper = styled(Box)(function () {
  var styles = {
    '& img': {
      height: 'auto',
      maxHeight: '100%',
      maxWidth: '100%',
      width: 'auto'
    },
    alignItems: 'center',
    borderRadius: pxToRem(12),
    display: 'flex',
    flexShrink: 0,
    height: pxToRem(44),
    overflow: 'hidden',
    width: pxToRem(44)
  };
  return styles;
});
var StyledButtonWrapper = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    padding: theme.spacing(0, 2, 2)
  };
  return styles;
});
var StyledFieldsWrapper = styled(Box)(function (_ref5) {
  var theme = _ref5.theme;
  var styles = {
    padding: theme.spacing(0, 2, 2)
  };
  return styles;
});
var StyledBox = styled(Stack)(function (_ref6) {
  var _theme$colors;

  var theme = _ref6.theme;
  var styles = {
    borderTop: "".concat(pxToRem(1), " solid ").concat(theme === null || theme === void 0 ? void 0 : (_theme$colors = theme.colors) === null || _theme$colors === void 0 ? void 0 : _theme$colors.lavenderSyrup),
    paddingTop: theme.spacing(2)
  };
  return styles;
});
var StyledTypography = styled(Typography)(function (_ref7) {
  var theme = _ref7.theme;
  var styles = {
    overflowWrap: 'anywhere'
  };
  return styles;
});
export { StyledBox, StyledButtonWrapper, StyledContentWrapper, StyledFieldsWrapper, StyledIconButton, StyledImageWrapper, StyledTypography, StyledWrapper };