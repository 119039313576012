import { __spreadArray as t, __read as n } from "../../../../_virtual/_tslib.js";
import { useRef as r, useState as e, useMemo as a } from "react";

function u() {}

function o() {
  return !0;
}

function i(t) {
  return !!(t || "").match(/\d/);
}

function c(t) {
  return null == t;
}

function f(t) {
  return "number" == typeof t && isNaN(t);
}

function l(t) {
  return t.replace(/[-[\]/{}()*+?.\\^$|]/g, "\\$&");
}

function s(t) {
  switch (t) {
    case "lakh":
      return /(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g;

    case "wan":
      return /(\d)(?=(\d{4})+(?!\d))/g;

    default:
      return /(\d)(?=(\d{3})+(?!\d))/g;
  }
}

function g(t, n, r) {
  var e = s(r),
      a = t.search(/[1-9]/);
  return a = -1 === a ? t.length : a, t.substring(0, a) + t.substring(a, t.length).replace(e, "$1" + n);
}

function d(e) {
  var a = r(e);
  a.current = e;
  var u = r(function () {
    for (var r = [], e = 0; e < arguments.length; e++) r[e] = arguments[e];

    return a.current.apply(a, t([], n(r), !1));
  });
  return u.current;
}

function v(t, n) {
  void 0 === n && (n = !0);
  var r = "-" === t[0],
      e = r && n,
      a = (t = t.replace("-", "")).split("."),
      u = a[0];
  return {
    addNegation: e,
    afterDecimal: a[1] || "",
    beforeDecimal: u,
    hasNegation: r
  };
}

function h(t) {
  if (!t) return t;
  var n = "-" === t[0];
  n && (t = t.substring(1, t.length));
  var r = t.split("."),
      e = r[0].replace(/^0+/, "") || "0",
      a = r[1] || "";
  return "".concat(n ? "-" : "").concat(e).concat(a ? ".".concat(a) : "");
}

function m(t, n, r) {
  for (var e = "", a = r ? "0" : "", u = 0; u <= n - 1; u++) e += t[u] || a;

  return e;
}

function p(t, n) {
  return Array(n + 1).join(t);
}

function b(t) {
  var r = t + "",
      e = "-" === r[0] ? "-" : "";
  e && (r = r.substring(1));
  var a = n(r.split(/[eE]/g), 2),
      u = a[0],
      o = a[1];
  if (!(o = Number(o))) return e + u;
  var i = 1 + o,
      c = (u = u.replace(".", "")).length;
  return i < 0 ? u = "0." + p("0", Math.abs(i)) + u : i >= c ? u += p("0", i - c) : u = (u.substring(0, i) || "0") + "." + u.substring(i), e + u;
}

function x(t, n, r) {
  if (-1 !== ["", "-"].indexOf(t)) return t;
  var e = (-1 !== t.indexOf(".") || r) && n,
      a = v(t),
      u = a.beforeDecimal,
      o = a.afterDecimal,
      i = a.hasNegation,
      c = parseFloat("0.".concat(o || "0")),
      f = (o.length <= n ? "0.".concat(o) : c.toFixed(n)).split("."),
      l = u.split("").reverse().reduce(function (t, n, r) {
    return t.length > r ? (Number(t[0]) + Number(n)).toString() + t.substring(1, t.length) : n + t;
  }, f[0]),
      s = m(f[1] || "", n, r),
      g = e ? "." : "";
  return "".concat(i ? "-" : "").concat(l).concat(g).concat(s);
}

function y(t, n) {
  if (t.value = t.value, null !== t) {
    if (t.createTextRange) {
      var r = t.createTextRange();
      return r.move("character", n), r.select(), !0;
    }

    return t.selectionStart || 0 === t.selectionStart ? (t.focus(), t.setSelectionRange(n, n), !0) : (t.focus(), !1);
  }
}

function N(t, n) {
  for (var r = 0, e = 0, a = t.length, u = n.length; t[r] === n[r] && r < a;) r++;

  for (; t[a - 1 - e] === n[u - 1 - e] && u - e > r && a - e > r;) e++;

  return {
    end: a - e,
    start: r
  };
}

function S(t, n) {
  for (var r = 0, e = 0, a = t.length, u = n.length; t[r] === n[r] && r < a;) r++;

  for (; t[a - 1 - e] === n[u - 1 - e] && u - e > r && a - e > r;) e++;

  return {
    from: {
      end: a - e,
      start: r
    },
    to: {
      end: u - e,
      start: r
    }
  };
}

function A(t, n, r) {
  return Math.min(Math.max(t, n), r);
}

function D(t) {
  return Math.max(t.selectionStart, t.selectionEnd);
}

function M() {
  return "undefined" != typeof navigator && !(navigator.platform && /iPhone|iPod/.test(navigator.platform));
}

function O(t) {
  return {
    from: {
      end: 0,
      start: 0
    },
    lastValue: "",
    to: {
      end: t.length,
      start: 0
    }
  };
}

function V(t, n) {
  return void 0 === t && (t = " "), "string" == typeof t ? t : t[n] || " ";
}

function w(t, n, r, e, a) {
  var u = a.findIndex(function (t) {
    return t;
  }),
      o = t.slice(0, u);
  n || r.startsWith(o) || (r = o + r, e += o.length);

  for (var c = r.length, f = t.length, l = {}, s = new Array(c), g = 0; g < c; g++) {
    s[g] = -1;

    for (var d = 0, v = f; d < v; d++) if (r[g] === t[d] && !0 !== l[d]) {
      s[g] = d, l[d] = !0;
      break;
    }
  }

  for (var h = e; h < c && (-1 === s[h] || !i(r[h]));) h++;

  var m = h === c || -1 === s[h] ? f : s[h];

  for (h = e - 1; h > 0 && -1 === s[h];) h--;

  var p = -1 === h || -1 === s[h] ? 0 : s[h] + 1;
  return p > m ? m : e - p < m - e ? p : m;
}

function R(t, n, r, e) {
  var a = t.length;

  if (n = A(n, 0, a), "left" === e) {
    for (; n >= 0 && !r[n];) n--;

    -1 === n && (n = r.indexOf(!0));
  } else {
    for (; n <= a && !r[n];) n++;

    n > a && (n = r.lastIndexOf(!0));
  }

  return -1 === n && (n = a), n;
}

function $(t) {
  for (var n = Array.from({
    length: t.length + 1
  }).map(function () {
    return !0;
  }), r = 0, e = n.length; r < e; r++) n[r] = Boolean(i(t[r]) || i(t[r - 1]));

  return n;
}

function j(t, o, i, l, s, g) {
  void 0 === g && (g = u);
  var v = r(),
      h = d(function (t) {
    var n, r;
    return c(t) || f(t) ? (r = "", n = "") : "number" == typeof t || i ? (r = "number" == typeof t ? b(t) : t, n = l(r)) : (r = s(t, void 0), n = t), {
      formattedValue: n,
      numAsString: r
    };
  }),
      m = n(e(function () {
    return h(o);
  }), 2),
      p = m[0],
      x = m[1];
  return a(function () {
    c(t) ? v.current = void 0 : (v.current = h(t), x(v.current));
  }, [t, h]), [p, function (t, n) {
    x({
      formattedValue: t.formattedValue,
      numAsString: t.value
    }), g(t, n);
  }];
}

export { M as addInputMode, g as applyThousandSeparator, $ as caretUnknownFormatBoundary, i as charIsNumber, A as clamp, l as escapeRegExp, S as findChangeRange, N as findChangedIndex, h as fixLeadingZero, D as geInputCaretPosition, R as getCaretPosInBoundary, w as getCaretPosition, O as getDefaultChangeMeta, V as getMaskAtIndex, s as getThousandsGroupRegex, f as isNanValue, c as isNil, m as limitToScale, u as noop, o as returnTrue, x as roundToPrecision, y as setCaretPosition, v as splitDecimal, b as toNumericString, j as useInternalValues, d as usePersistentCallback };