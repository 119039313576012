import { styled as t, InputLabel as e, inputBaseClasses as o, Box as i, FormHelperText as n } from "@mui/material";
import "../../../themes/box-shadows/boxShadows.js";
import "../../../themes/dark.theme.js";
import { pxToRem as r } from "../../../themes/default-theme/default-theme.js";
import "../../../themes/gradients/gradients.js";
import "../../../themes/light.theme.js";
import { TextField as a } from "../text-field/text-field.component.js";
var m = t(e)(function (t) {
  var e = t.theme,
      o = t.$color,
      i = {
    color: e.colors.abandonedSpaceship,
    display: "block",
    fontSize: r(16),
    fontWeight: 400,
    lineHeight: r(18),
    marginBottom: e.spacing(.5),
    maxWidth: "100%",
    paddingLeft: e.spacing(1),
    position: "relative",
    transform: "unset",
    transformOrigin: "unset"
  };
  return "bayFog" === o && (i.color = e.colors.white), i;
}),
    s = t(a)(function (t) {
  var e,
      i,
      n,
      a = t.theme;
  return (e = {})["& .".concat(o.input)] = ((i = {})['&[type="password"]'] = ((n = {})["&::placeholder"] = {
    color: a.palette.nightWizard.main
  }, n.color = a.palette.primary.main, n.fontSize = r(18), n), i.boxSizing = "border-box", i.padding = a.spacing(1), i.textAlign = "center", i), e;
}),
    l = t(i)(function () {
  return {
    fontSize: r(12),
    fontWeight: 500,
    left: 0,
    lineHeight: r(14),
    marginTop: r(12),
    position: "absolute",
    right: 0,
    top: "100%"
  };
}),
    p = t(n)(function (t) {
  var e = t.theme;
  return {
    color: e.palette.error.main,
    display: "block",
    margin: "unset",
    maxWidth: "100%",
    padding: e.spacing(0, 1),
    textAlign: "center"
  };
}),
    c = t(n)(function (t) {
  var e = t.theme,
      o = t.$color,
      i = {
    color: e.colors.abandonedSpaceship,
    display: "block",
    margin: "unset",
    maxWidth: "100%",
    padding: e.spacing(0, 1),
    textAlign: "center"
  };
  return "bayFog" === o && (i.color = e.colors.white), i;
}),
    d = t(i)(function () {
  return {
    fontSize: r(24),
    marginLeft: r(4)
  };
});
export { l as StyleFromHelperWrapper, p as StyledFormHelperErrorText, c as StyledFormHelperText, m as StyledInputLabel, d as StyledSeparate, s as StyledTextField };