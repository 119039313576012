var e = function (e) {
  window.isSecureContext && navigator.clipboard ? navigator.clipboard.writeText(e) : function (e) {
    var t = document.createElement("textarea");
    t.value = e, document.body.appendChild(t), t.setAttribute("style", "height: 0; width: 0; position: fixed; top: -999px"), t.focus(), t.select();

    try {
      document.execCommand("copy");
    } catch (e) {
      console.error("Unable to copy to clipboard", e);
    }

    t.blur(), document.body.removeChild(t);
  }(e);
};

export { e as copyToClipboard };