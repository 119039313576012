import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { IconButton, pxToRem } from '@pwa/ui';
import { Accordion as MuiAccordion, AccordionDetails as MuiAccordionDetails, AccordionSummary as MuiAccordionSummary, accordionSummaryClasses, Box, collapseClasses, styled } from '@mui/material';
var StyledAccordion = styled(MuiAccordion)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    '&:before': {
      display: 'none'
    },
    backgroundColor: 'white',
    border: 'none'
  }, "& .".concat(collapseClasses.root), {
    margin: theme.spacing(0, -2),
    padding: theme.spacing(0, 2)
  });

  return styles;
});
var StyledAccordionSummary = styled(MuiAccordionSummary)(function (_ref2) {
  var _styles2;

  var theme = _ref2.theme;
  var styles = (_styles2 = {
    minHeight: 'auto',
    padding: 0
  }, _defineProperty(_styles2, "& .".concat(accordionSummaryClasses.content), {
    margin: 0
  }), _defineProperty(_styles2, "& .".concat(accordionSummaryClasses.expandIconWrapper), {
    color: 'inherit'
  }), _styles2);
  return styles;
});
var StyledAccordionDetails = styled(MuiAccordionDetails)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    padding: theme.spacing(0),
    paddingTop: theme.spacing(1.75)
  };
  return styles;
});
var StyledTitleWrapper = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  };
  return styles;
});
var StyledDelimiter = styled(Box)(function (_ref5) {
  var theme = _ref5.theme;
  var styles = {
    borderRight: "1px solid ".concat(theme.palette.lavenderSyrup.main),
    height: pxToRem(18),
    marginRight: theme.spacing(2),
    width: pxToRem(1)
  };
  return styles;
});
var StyledIconButton = styled(IconButton)(function (_ref6) {
  var theme = _ref6.theme;
  var styles = {
    padding: 0
  };
  return styles;
});
export { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary, StyledDelimiter, StyledIconButton, StyledTitleWrapper };