import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject;

import { Box, Modal, styled } from '@mui/material';
var LoaderContainer = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  transform: translate3d(-50%, -50%, 0);\n"])));
var StyledModal = styled(Modal)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    zIndex: theme.zIndex.modal + 5
  };
  return styles;
});
export { LoaderContainer, StyledModal };