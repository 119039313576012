import * as yup from 'yup';
import { formatNumber } from '@helpers';
import { i18n } from '@localization';

var getPaymentsPhoneFormSchema = function getPaymentsPhoneFormSchema(minAmnts, maxAmnts) {
  return yup.object().shape({
    checkContract: yup.bool().equals([true], i18n.t('forms.validation.required')),
    transferAmntDt: yup.number().required(i18n.t('forms.validation.enterAmount')).test({
      message: i18n.t('forms.validation.enterAmount'),
      name: 'isRequired',
      test: function test(value, ctx) {
        return Number(value) !== 0;
      }
    }).test({
      message: i18n.t('forms.validation.maxTransferAccountAmount'),
      name: 'maxAmount',
      test: function test(value, ctx) {
        if (!value) return true;
        var allValues = ctx.parent;
        var account = allValues === null || allValues === void 0 ? void 0 : allValues.account;
        if (!account) return true;
        return Number(value) <= account.accountAmount;
      }
    }).test({
      name: 'minTransferAmnt',
      test: function test(value, ctx) {
        var _minAmnts$find;

        if (!value) return true;
        var allValues = ctx.parent;
        var currency = allValues === null || allValues === void 0 ? void 0 : allValues.account.accountCurrency;
        if (!currency) return true;
        var minValue = (_minAmnts$find = minAmnts.find(function (amnt) {
          return amnt.transferCurr === currency;
        })) === null || _minAmnts$find === void 0 ? void 0 : _minAmnts$find.minTransferAmnt;
        if (!minValue) return true;

        if (Number(value) < minValue) {
          return ctx.createError({
            message: i18n.t('forms.validation.minTransferAmount', {
              data: " ".concat(formatNumber(minValue, 2, true, ',', ' '), " ").concat(currency)
            })
          });
        }

        return true;
      }
    }).test({
      name: 'maxTransferAmnt',
      test: function test(value, ctx) {
        var _maxAmnts$find;

        if (!value) return true;
        var allValues = ctx.parent;
        var currency = allValues === null || allValues === void 0 ? void 0 : allValues.account.accountCurrency;
        if (!currency) return true;
        var maxValue = (_maxAmnts$find = maxAmnts.find(function (amnt) {
          return amnt.transferCurr === currency;
        })) === null || _maxAmnts$find === void 0 ? void 0 : _maxAmnts$find.maxTransferAmnt;
        if (!maxValue) return true;

        if (Number(value) > maxValue) {
          return ctx.createError({
            message: i18n.t('forms.validation.maxTransferAmount', {
              data: " ".concat(formatNumber(maxValue, 2, true, ',', ' '), " ").concat(currency)
            })
          });
        }

        return true;
      }
    }).nullable()
  });
};

export { getPaymentsPhoneFormSchema };