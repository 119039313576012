import { __rest as r, __assign as e } from "../../_virtual/_tslib.js";
import { jsxs as o, jsx as t } from "react/jsx-runtime";
import { Stack as i, Box as a } from "@mui/material";
import { getLastFourCardDigits as m } from "../../helpers/card-number/card-number.helper.js";
import "react";
import { Size as n } from "../../enums/entities/entities.enum.js";
import "../../enums/keyboard/keyboard.enum.js";
import "../../enums/theme/theme.enum.js";
import "../../node_modules/@babel/runtime/helpers/typeof.js";
import { Typography as l } from "../typography/typography.component.js";
import { StyledWrapper as c, StyledShadow as d, StyledBoxBackground as p, StyledBox as s, BannerLabel as h, StyledTypography as u, StyledSeparator as b } from "./bank-small-card.styles.js";

var f = function (f) {
  var g = f.cardNumber,
      x = f.cardName,
      y = f.cardExpiry,
      j = f.label,
      w = f.chipColor,
      v = f.backgroundImg,
      k = f.textColor,
      C = r(f, ["cardNumber", "cardName", "cardExpiry", "label", "chipColor", "backgroundImg", "textColor"]);
  return o(c, e({}, C, {
    children: [t(d, {}), t(p, {
      $backgroundImg: v
    }), o(s, {
      children: [j && t(h, {
        $color: w,
        size: n.Small,
        title: j
      }), o(i, e({
        alignItems: "flex-start",
        maxWidth: "100%",
        spacing: .5
      }, {
        children: [t(u, e({
          color: k || "white",
          flexGrow: 1,
          fontWeight: 500,
          maxWidth: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          variant: "body2",
          whiteSpace: "nowrap"
        }, {
          children: x
        })), o(i, e({
          alignItems: "center",
          direction: "row"
        }, {
          children: [t(l, e({
            color: k || "white",
            fontWeight: 400,
            variant: "caption1"
          }, {
            children: "**** ".concat(m(g))
          })), t(a, {
            children: t(b, {
              $backgroundColor: k
            })
          }), t(l, e({
            color: k || "white",
            fontWeight: 400,
            variant: "caption1"
          }, {
            children: y
          }))]
        }))]
      }))]
    })]
  }));
};

export { f as BankSmallCard };