import { getHexColor, pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledErrorWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(6.5, 4)
  };
  return styles;
});
var StyledIconWrapper = styled(Box)(function () {
  var styles = {
    display: 'block',
    margin: '0 auto',
    maxWidth: pxToRem(283),
    position: 'relative',
    transform: 'translate3d(0,0,0)',
    width: '100%'
  };
  return styles;
});
var StyledIconSpacer = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    height: 0,
    overflow: 'hidden',
    paddingTop: '97%',
    width: '100%'
  };
  return styles;
});
var StyledIconCircle = styled(Box)(function (_ref3) {
  var theme = _ref3.theme,
      $backgroundColor = _ref3.$backgroundColor;
  var color = getHexColor(theme.palette, $backgroundColor);
  var styles = {
    backgroundColor: color,
    borderRadius: '50%',
    height: 0,
    left: '50%',
    marginLeft: 'calc(-41%)',
    marginTop: 'calc(-41%)',
    paddingTop: '82%',
    position: 'absolute',
    top: '50%',
    width: '82%'
  };
  return styles;
});
var StyledIcon = styled('img')(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    display: 'block',
    height: '100%',
    inset: 0,
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%'
  };
  return styles;
});
export { StyledErrorWrapper, StyledIcon, StyledIconCircle, StyledIconSpacer, StyledIconWrapper };