import { styled as e, Box as t } from "@mui/material";
import "react";
import { getHexColor as o } from "../../helpers/colors/colors.helper.js";
import "../../enums/entities/entities.enum.js";
import "../../enums/keyboard/keyboard.enum.js";
import "../../enums/theme/theme.enum.js";
import "../../node_modules/@babel/runtime/helpers/typeof.js";
import { Button as r } from "../button/button.component.js";
var n = e(r)(function (e) {
  var t = e.theme;
  return {
    height: "unset",
    paddingBottom: t.spacing(1.75),
    paddingTop: t.spacing(1.75),
    textAlign: "left",
    textTransform: "none"
  };
}),
    m = e(t)(function (e) {
  var t = e.theme,
      r = e.$iconBg,
      n = e.$iconBgCustom;
  return {
    alignItems: "center",
    backgroundColor: r && o(t.palette, r) || n,
    borderRadius: t.typography.pxToRem(12),
    display: "flex",
    fontSize: 0,
    height: t.typography.pxToRem(44),
    justifyContent: "center",
    width: t.typography.pxToRem(44)
  };
});
export { n as StyledCardButton, m as StyledIconWrapper };