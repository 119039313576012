import { __rest as e, __assign as r } from "../../_virtual/_tslib.js";
import { jsx as t } from "react/jsx-runtime";
import { Box as o } from "@mui/material";
import { StyledFieldButton as n, StyledFieldLabel as a, StyledSwitch as l } from "./switch-button.styles.js";

var c = function (c) {
  var i = c.labelProps,
      m = c.children,
      h = c.checked,
      s = c.onChange,
      p = e(c, ["labelProps", "children", "checked", "onChange"]);
  return t(n, r({
    color: "white",
    component: o,
    variant: "contained"
  }, p, {
    children: t(a, r({
      checked: h,
      control: t(l, {}),
      label: m,
      labelPlacement: "start",
      onChange: s
    }, i))
  }));
};

export { c as SwitchButton };