import { colors as e } from "../colors/colors.js";
var a = {
  coolGrey: "linear-gradient(0deg, ".concat(e.mensNight, " 0%, ").concat(e.coolGrey, " 100%)"),
  deepAquamarine: "linear-gradient(0deg, ".concat(e.blueBobbin, " 0%, ").concat(e.deepAquamarine, " 100%)"),
  luxuryDark: "linear-gradient(0deg, ".concat(e.shark, " 0%, ").concat(e.oxfordBlue, " 100%)"),
  optimal: "linear-gradient(0deg, ".concat(e.rollingStone, " 0%, ").concat(e.regentGray, " 100%)"),
  oxfordBlue: "linear-gradient(282.19deg, ".concat(e.shark, " 3.67%, ").concat(e.oxfordBlue, " 93.86%)"),
  panPurple: "linear-gradient(0deg, ".concat(e.denim, " 0%, ").concat(e.panPurple, " 100%)"),
  premium: "linear-gradient(0deg, ".concat(e.laser, " 0%, ").concat(e.harvestGold, " 100%)"),
  simple: "linear-gradient(0deg, ".concat(e.schooner, " 0%, ").concat(e.cloudy, " 100%)")
};
export { a as gradients };