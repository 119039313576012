import { __assign as o } from "../../../_virtual/_tslib.js";
import { styled as t, InputAdornment as r, FormControl as n, InputBase as e, inputBaseClasses as i, InputLabel as a, Box as c, FormHelperText as d } from "@mui/material";
import "../../../themes/box-shadows/boxShadows.js";
import "../../../themes/dark.theme.js";
import { pxToRem as l } from "../../../themes/default-theme/default-theme.js";
import "../../../themes/gradients/gradients.js";
import "../../../themes/light.theme.js";
var s = t(r)(function (o) {
  var t = o.theme;
  return {
    marginLeft: t.spacing(1.875),
    marginRight: t.spacing(1)
  };
}),
    p = t(n)(function (o) {
  return {
    display: o.fullWidth && "flex",
    maxWidth: "100%"
  };
}),
    u = t(e)(function (o) {
  var t,
      r = o.theme,
      n = o.color,
      e = o.error,
      a = o.$forcedFocus,
      c = o.$hiddenToField,
      d = ((t = {})["& .MuiInputAdornment-root.MuiInputAdornment-positionEnd"] = {
    left: "calc(50% - ".concat(l(10), ")"),
    position: "absolute",
    top: l(20)
  }, t["& .MuiInputAdornment-root.MuiInputAdornment-positionStart"] = {
    fontSize: l(14),
    fontWeight: 400,
    left: 0,
    lineHeight: l(16),
    position: "absolute",
    top: l(20)
  }, t["& .MuiInputAdornment-root.MuiInputAdornment-positionStart > p"] = {
    color: e ? r.palette.error.main : r.colors.bayFog
  }, t.backgroundColor = r.colors.white, t.border = "".concat(l(1), " solid ").concat(r.colors.lavenderSyrup), t.borderRadius = l(12), t.color = r.colors.nightWizard, t.fontSize = l(16), t.fontWeight = 500, t.height = l(64), t.lineHeight = l(18), t.overflow = "hidden", t["& .".concat(i.input)] = {
    width: c ? void 0 : "calc(50% - ".concat(l(10), ")")
  }, t["&.".concat(i.error)] = {
    border: "".concat(l(1), " solid ").concat(r.palette.error.main)
  }, t["&.".concat(i.focused)] = {
    border: "".concat(l(1), " solid ").concat(r.palette.primary.main)
  }, t["& .".concat(i.inputAdornedStart)] = {
    padding: 0
  }, t.padding = r.spacing(3.75, 2, 1.25, 2), t.position = "relative", t.transition = r.transitions.create("border-color", {
    duration: 150
  }), t["&.".concat(i.focused, " ").concat(s, " > p")] = {
    color: r.colors.panPurple
  }, t);
  if (a && (d.border = "".concat(l(1), " solid ").concat(r.colors.panPurple)), "secondary" === n) d.backgroundColor = r.palette.secondary.dark, d.borderColor = r.palette.secondary.dark;
  return d;
}),
    m = t(e)(function (t) {
  var r,
      n = t.theme,
      e = t.color,
      a = t.error,
      c = t.$hiddenFromField,
      d = t.$forcedFocus,
      p = {
    bottom: !c && l(1),
    left: !c && "50%",
    position: !c && "absolute",
    transition: n.transitions.create(["border-color"]),
    width: !c && "calc(50% - ".concat(l(1), ")")
  },
      u = o(o({}, p), ((r = {})["& .MuiInputAdornment-root.MuiInputAdornment-positionStart"] = {
    fontSize: l(14),
    fontWeight: 400,
    left: 0,
    lineHeight: l(16),
    position: "absolute",
    top: l(20)
  }, r["& .MuiInputAdornment-root.MuiInputAdornment-positionStart > p"] = {
    color: a ? n.palette.error.main : n.colors.bayFog
  }, r.backgroundColor = n.colors.white, r.border = c && "".concat(l(1), " solid ").concat(n.colors.lavenderSyrup), r.borderRadius = l(12), r.color = n.colors.nightWizard, r.fontSize = l(16), r.fontWeight = 500, r.height = l(c ? 64 : 62), r.lineHeight = l(18), r["& .".concat(i.inputAdornedStart)] = {
    padding: 0
  }, r.overflow = "hidden", r.padding = n.spacing(3.75, 2, 1.25, 2), r["&.".concat(i.focused, " ").concat(s, " > p")] = {
    color: n.colors.panPurple
  }, r));
  if ("secondary" === e) u.backgroundColor = n.palette.secondary.dark, u.borderColor = n.palette.secondary.dark;
  return d && (u.border = "".concat(l(1), " solid ").concat(n.colors.panPurple)), u;
}),
    h = t(a)(function (o) {
  var t = o.theme;
  return {
    color: t.palette.secondary.main,
    display: "block",
    fontSize: l(14),
    lineHeight: l(24),
    marginBottom: t.spacing(.5),
    maxWidth: "100%",
    paddingLeft: t.spacing(2),
    position: "relative",
    transform: "unset",
    transformOrigin: "unset"
  };
}),
    g = t(r)(function (o) {
  var t = o.theme;
  return {
    borderRight: "".concat(l(1), " solid ").concat(t.colors.lavenderSyrup),
    height: l(20),
    width: 0
  };
}),
    f = t(c)(function () {
  return {
    fontSize: l(12),
    left: 0,
    lineHeight: l(14),
    marginTop: l(4),
    position: "absolute",
    right: 0,
    top: "100%"
  };
}),
    b = t(d)(function (o) {
  var t = o.theme;
  return {
    color: t.palette.error.main,
    display: "block",
    margin: "unset",
    maxWidth: "100%",
    padding: t.spacing(0, 1)
  };
}),
    y = t(d)(function (o) {
  var t = o.theme,
      r = o.$color,
      n = {
    color: t.colors.abandonedSpaceship,
    display: "block",
    margin: "unset",
    maxWidth: "100%",
    padding: t.spacing(0, 1)
  };
  return "bayFog" === r && (n.color = t.colors.white), n;
});
export { f as StyleFromHelperWrapper, m as StyledAbsoluteInputBase, p as StyledFormControl, b as StyledFormHelperErrorText, y as StyledFormHelperText, s as StyledInputAdornment, u as StyledInputBase, g as StyledInputDivider, h as StyledInputLabel };