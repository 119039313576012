import { __rest as l, __read as t, __assign as o } from "../../_virtual/_tslib.js";
import { jsxs as i, jsx as n } from "react/jsx-runtime";
import * as e from "react";
import { Button as u } from "../button/button.component.js";
import { ButtonGroup as a } from "../button-group/button-group.component.js";
import { StyledBox as r } from "./tab.styles.js";
import { TabPanel as v } from "./tab-panel.component.js";
var d = e.forwardRef(function (d, m) {
  var s,
      f = d.items,
      c = d.fullHeight,
      p = d.onTabChange,
      b = d.initValue,
      h = d.isDark,
      g = l(d, ["items", "fullHeight", "onTabChange", "initValue", "isDark"]),
      j = t(e.useState(b || (null === (s = null == f ? void 0 : f[0]) || void 0 === s ? void 0 : s.value)), 2),
      k = j[0],
      y = j[1],
      H = h ? "ghostWhite" : "secondary";
  return i(r, o({
    $fullHeight: c
  }, {
    children: [n(a, o({
      ref: m
    }, g, {
      $isDark: h,
      fullWidth: !0
    }, {
      children: null == f ? void 0 : f.map(function (l) {
        return n(u, o({
          color: k === (null == l ? void 0 : l.value) ? "primary" : H,
          disableElevation: !0,
          variant: k === (null == l ? void 0 : l.value) || h ? "contained" : "text",
          onClick: function () {
            null == p || p(null == l ? void 0 : l.value), y(null == l ? void 0 : l.value);
          }
        }, {
          children: null == l ? void 0 : l.title
        }), null == l ? void 0 : l.value);
      })
    })), null == f ? void 0 : f.map(function (l) {
      return n(v, o({
        fullHeight: c,
        tabValue: null == l ? void 0 : l.value,
        tabsValue: k
      }, {
        children: null == l ? void 0 : l.content
      }), null == l ? void 0 : l.value);
    })]
  }));
});
d.displayName = "Tabs";
export { d as Tabs };