import moment from 'moment';
import * as yup from 'yup';
import { i18n } from '@localization';

var getFilterHistorySchema = function getFilterHistorySchema() {
  return yup.object().shape({
    dateFrom: yup.string().required().test({
      message: i18n.t('forms.validation.dateFrom'),
      name: 'min',
      test: function test(value, ctx) {
        var fields = ctx.parent;

        if (moment(value) > moment(fields.dateTo)) {
          return false;
        }

        return true;
      }
    })
  });
};

export { getFilterHistorySchema };