import { BankDepartmentServices, BankServices } from '@enums';
import { i18n } from '@localization';
/**
 * Get service text function.
 *
 * @author Christina Kashevar
 * @function getBankServiceText
 * @category helpers
 * @return {string} service text.
 */

var getBankServiceText = function getBankServiceText(code) {
  switch (code) {
    case BankServices.BillPayments:
      return "".concat(i18n.t('pages.bankOnMap.billPayments'));

    case BankServices.CashIn:
      return "".concat(i18n.t('pages.bankOnMap.cashIn'));

    case BankServices.CashWithdraw:
      return "".concat(i18n.t('pages.bankOnMap.cashWithdraw'));

    case BankServices.PINActivation:
      return "".concat(i18n.t('pages.bankOnMap.pinActivation'));

    case BankServices.PINChange:
      return "".concat(i18n.t('pages.bankOnMap.pinChange'));

    case BankServices.PINUnblock:
      return "".concat(i18n.t('pages.bankOnMap.pinUnblock'));

    case BankServices.CurrencyExchange:
      return "".concat(i18n.t('pages.bankOnMap.currencyExchange'));

    case BankServices.LoanRepayment:
      return "".concat(i18n.t('pages.bankOnMap.loanRepayment'));

    default:
      return "".concat(i18n.t('pages.bankOnMap.transfer'));
  }
};
/**
 * Get service text function.
 *
 * @author Christina Kashevar
 * @function getBankServiceText
 * @category helpers
 * @return {string} service text.
 */


var getBankDepartmentServiceText = function getBankDepartmentServiceText(code) {
  switch (code) {
    case BankDepartmentServices.Card:
      return "".concat(i18n.t('pages.bankOnMap.cards'));

    case BankDepartmentServices.Loan:
      return "".concat(i18n.t('pages.bankOnMap.credit'));

    case BankDepartmentServices.CurrencyExchange:
      return "".concat(i18n.t('pages.bankOnMap.currencyExchange'));

    case BankDepartmentServices.Deposit:
      return "".concat(i18n.t('pages.bankOnMap.deposit'));

    case BankDepartmentServices.DepositBox:
      return "".concat(i18n.t('pages.bankOnMap.depositBox'));

    case BankDepartmentServices.DirectDebit:
      return "".concat(i18n.t('pages.bankOnMap.directDebit'));

    default:
      return "".concat(i18n.t('pages.bankOnMap.transfer'));
  }
};

export { getBankDepartmentServiceText, getBankServiceText };