import { __assign as e, __rest as a, __read as t } from "../../../../_virtual/_tslib.js";
import { jsx as r } from "react/jsx-runtime";
import { NumberFormatBase as n } from "react-number-format";
import { SourceType as o } from "./numeric-format.types.js";
import { useInternalValues as l, setCaretPosition as i, fixLeadingZero as u, roundToPrecision as s, splitDecimal as d, limitToScale as c, applyThousandSeparator as f, getDefaultChangeMeta as v, charIsNumber as m, escapeRegExp as p, noop as g, isNil as h, isNanValue as S, toNumericString as x } from "./numeric-format.utils.js";

function D(e, a) {
  var t = a.decimalScale,
      r = a.fixedDecimalScale,
      n = a.prefix,
      o = void 0 === n ? "" : n,
      l = a.suffix,
      i = void 0 === l ? "" : l,
      u = a.allowNegative,
      s = a.thousandsGroupStyle,
      v = void 0 === s ? "thousand" : s;
  if ("" === e || "-" === e) return e;
  var m = y(a),
      p = m.thousandSeparator,
      g = m.decimalSeparator,
      h = 0 !== t && -1 !== e.indexOf(".") || t && r,
      S = d(e, u),
      x = S.beforeDecimal,
      D = S.afterDecimal,
      w = S.addNegation;
  return void 0 !== t && (D = c(D, t, !!r)), p && (x = f(x, p, v)), o && (x = o + x), i && (D += i), w && (x = "-" + x), e = x + (h && g || "") + D;
}

function w(e, a, t) {
  void 0 === a && (a = v(e));
  var r = t.allowNegative,
      n = void 0 !== r && r,
      o = t.prefix,
      l = void 0 === o ? "" : o,
      i = t.suffix,
      u = void 0 === i ? "" : i,
      s = t.decimalScale,
      c = a.from,
      f = a.to,
      g = f.start,
      h = f.end,
      S = y(t),
      x = S.allowedDecimalSeparators,
      D = S.decimalSeparator,
      w = e[h] === D;
  if (e.includes(".") && (e = e.replace(".", D)), m(e) && (e === l || e === u) && "" === a.lastValue) return e;

  if (h - g == 1 && -1 !== x.indexOf(e[g])) {
    var b = 0 === s ? "" : D;
    e = e.substring(0, g) + b + e.substring(g + 1, e.length);
  }

  var N = function (e, a, t) {
    var r = !1,
        n = !1;
    l.startsWith("-") ? r = !1 : e.startsWith("--") ? (r = !1, n = !0) : u.startsWith("-") && e.length === u.length ? r = !1 : "-" === e[0] && (r = !0);
    var o = r ? 1 : 0;
    return n && (o = 2), o && (e = e.substring(o), a -= o, t -= o), {
      end: t,
      hasNegation: r,
      start: a,
      value: e
    };
  },
      V = N(e, g, h),
      B = V.hasNegation;

  e = V.value, g = V.start, h = V.end;
  var k = N(a.lastValue, c.start, c.end),
      C = k.start,
      W = k.end,
      j = k.value,
      E = e.substring(g, h);
  !(e.length && j.length && (C > j.length - u.length || W < l.length)) || E && u.startsWith(E) || (e = j);
  var F = 0;
  e.startsWith(l) ? F += l.length : g < l.length && (F = g), h -= F;
  var G = (e = e.substring(F)).length,
      I = e.length - u.length;
  e.endsWith(u) ? G = I : (h > I || h > e.length - u.length) && (G = h), e = e.substring(0, G), e = function (e, a) {
    void 0 === e && (e = "");
    var t = /(-)/.test(e),
        r = /(-)(.)*(-)/.test(e);
    return e = e.replace(/-/g, ""), t && !r && a && (e = "-" + e), e;
  }(B ? "-".concat(e) : e, n), e = (e.match(function (e, a) {
    return new RegExp("(^-)|[0-9]|".concat(p(e)), a ? "g" : void 0);
  }(D, !0)) || []).join("");
  var K = e.indexOf(D);
  e = e.replace(new RegExp(p(D), "g"), function (e, a) {
    return a === K ? "." : "";
  });
  var O = d(e, n),
      A = O.beforeDecimal,
      L = O.afterDecimal,
      R = O.addNegation;
  return f.end - f.start < c.end - c.start && "" === A && w && !parseFloat(L) && (e = R ? "-" : ""), e;
}

function y(e) {
  var a = e.decimalSeparator,
      t = void 0 === a ? "." : a,
      r = e.thousandSeparator,
      n = e.allowedDecimalSeparators;
  return !0 === r && (r = ","), n || (n = [t, "."]), {
    allowedDecimalSeparators: n,
    decimalSeparator: t,
    thousandSeparator: r
  };
}

function b(r) {
  r.decimalSeparator, r.allowedDecimalSeparators, r.thousandsGroupStyle, r.suffix, r.allowNegative;
  var n = r.allowLeadingZeros,
      d = r.onKeyDown,
      c = void 0 === d ? g : d,
      f = r.onBlur,
      v = void 0 === f ? g : f,
      m = r.thousandSeparator,
      p = r.decimalScale,
      b = r.fixedDecimalScale,
      N = r.prefix,
      V = void 0 === N ? "" : N,
      B = r.defaultValue,
      k = r.value,
      C = r.valueIsNumericString,
      W = r.onValueChange,
      j = a(r, ["decimalSeparator", "allowedDecimalSeparators", "thousandsGroupStyle", "suffix", "allowNegative", "allowLeadingZeros", "onKeyDown", "onBlur", "thousandSeparator", "decimalScale", "fixedDecimalScale", "prefix", "defaultValue", "value", "valueIsNumericString", "onValueChange"]);
  !function (e) {
    var a = y(e),
        t = a.thousandSeparator,
        r = a.decimalSeparator;
    if (t === r) throw new Error("\n        Decimal separator can't be same as thousand separator.\n        thousandSeparator: ".concat(t, ' (thousandSeparator = {true} is same as thousandSeparator = ",")\n        decimalSeparator: ').concat(r, " (default value for decimalSeparator is .)\n     "));
  }(r);

  var E = function (e) {
    return D(e, r);
  },
      F = function (e, a) {
    return w(e, a, r);
  },
      G = C;

  h(k) ? h(B) || (G = null != C ? C : "number" == typeof B) : G = null != C ? C : "number" == typeof k;

  var I = function (e) {
    return h(e) || S(e) ? e : ("number" == typeof e && (e = x(e)), G && "number" == typeof p ? s(e, p, Boolean(b)) : e);
  },
      K = t(l(I(k), I(B), Boolean(G), E, F, W), 2),
      O = K[0],
      A = O.numAsString,
      L = O.formattedValue,
      R = K[1];

  return e(e({}, j), {
    format: E,
    getCaretBoundary: function (e) {
      return function (e, a) {
        var t = a.prefix,
            r = void 0 === t ? "" : t,
            n = a.suffix,
            o = void 0 === n ? "" : n,
            l = Array.from({
          length: e.length + 1
        }).map(function () {
          return !0;
        }),
            i = "-" === e[0];
        l.fill(!1, 0, r.length + (i ? 1 : 0));
        var u = e.length;
        return l.fill(!1, u - o.length + 1, u + 1), l;
      }(e, r);
    },
    onBlur: function (e) {
      var a = A;

      if (a.match(/\d/g) || (a = ""), n || (a = u(a)), b && p && (a = s(a, p, b)), a !== A) {
        var t = D(a, r);
        R({
          floatValue: parseFloat(a),
          formattedValue: t,
          value: a
        }, {
          event: e,
          source: o.event
        });
      }

      v(e);
    },
    onKeyDown: function (e) {
      var a = e.target,
          t = e.key,
          n = a.selectionStart,
          o = a.selectionEnd,
          l = a.value,
          u = void 0 === l ? "" : l;

      if (n === o) {
        "Backspace" !== t && 229 !== (null == e ? void 0 : e.keyCode) || "-" !== u[0] || n !== V.length + 1 || i(a, 1);
        var s = y(r).decimalSeparator;
        ("Backspace" === t || 229 === (null == e ? void 0 : e.keyCode)) && u[n - 1] === s && p && b && (i(a, n - 1), e.preventDefault());
        var d = !0 === m ? "," : m;
        "Backspace" !== t && 229 !== (null == e ? void 0 : e.keyCode) || u[n - 1] !== d || i(a, n - 1), "Delete" === t && u[n] === d && i(a, n + 1), "Delete" === t && s && b && u[n] === s && (i(a, n + 1), e.preventDefault()), c(e);
      } else c(e);
    },
    onValueChange: R,
    removeFormatting: F,
    value: L,
    valueIsNumericString: !1
  });
}

function N(a) {
  var t = b(a);
  return r(n, e({}, t));
}

export { N as default };