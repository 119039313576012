import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";

/**
 * Get Account Index By Id.
 *
 * @author Yury Kuntsou
 * @function getAccountIndexById
 * @category helpers
 * @return {string} account index.
 */
var getAccountIndexById = function getAccountIndexById(id, list, isWithAllAccounts) {
  if (!id) {
    return undefined;
  }

  var index = list.findIndex(function (account) {
    return account.id === id;
  });
  return index !== -1 ? isWithAllAccounts ? index + 1 : index : undefined;
};
/**
 * Sorts an array of TransferAccountListItems by accountToAccountType
 * @author Yury Kuntsou
 * @function getSortingAccountsByType
 * @category helper
 * @param {TransferAccountListItem[]} accounts - An array of TransferAccountListItems to be sorted
 * @returns {TransferAccountListItem[]} Array of TransferAccountListItems sorted according to accountToAccountType
 */


var getSortingAccountsByType = function getSortingAccountsByType(accounts) {
  if (!accounts) return [];
  return _toConsumableArray(accounts).sort(function (a, b) {
    if ((typeof a.accountToAccountType === 'undefined' || a.accountToAccountType === null) && (typeof b.accountToAccountType === 'undefined' || b.accountToAccountType === null)) {
      return 0;
    }

    if (typeof a.accountToAccountType === 'undefined' || a.accountToAccountType === null) {
      return 1;
    }

    if (typeof b.accountToAccountType === 'undefined' || b.accountToAccountType === null) {
      return -1;
    }

    return a.accountToAccountType - b.accountToAccountType;
  });
};

export { getAccountIndexById, getSortingAccountsByType };