import { pxToRem } from '@pwa/ui';
import { Box, Stack, styled } from '@mui/material';
var StyledWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    backgroundColor: theme.palette.white.main,
    borderRadius: pxToRem(16)
  };
  return styles;
});
var StyledTop = styled(Stack)(function (_ref2) {
  var _theme$colors;

  var theme = _ref2.theme;
  var styles = {
    borderBottom: "".concat(pxToRem(1), " solid ").concat(theme === null || theme === void 0 ? void 0 : (_theme$colors = theme.colors) === null || _theme$colors === void 0 ? void 0 : _theme$colors.lavenderSyrup),
    padding: theme.spacing(1.5, 2)
  };
  return styles;
});
var StyledBox = styled(Box)(function () {
  var styles = {
    pointerEvents: 'none'
  };
  return styles;
});
var StyledBlock = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    padding: theme.spacing(2)
  };
  return styles;
});
var StyledInfo = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    alignItems: 'center',
    backgroundColor: theme.palette.ghostWhite.main,
    borderRadius: pxToRem(12),
    display: 'flex',
    height: pxToRem(56),
    padding: theme.spacing(1.5),
    whiteSpace: 'pre-wrap',
    width: '100%'
  };
  return styles;
});
export { StyledBlock, StyledBox, StyledInfo, StyledTop, StyledWrapper };