import { Button } from '@pwa/ui';
import { Box, styled } from '@mui/material';
import { pxToRem } from '@themes';
var StyledModalContainer = styled(Box)(function (_ref) {
  var theme = _ref.theme,
      $fullScreen = _ref.$fullScreen,
      $bgColor = _ref.$bgColor;
  var styles = {
    backgroundColor: $bgColor || theme.palette.ghostWhite.main,
    borderRadius: !$fullScreen && pxToRem(12),
    display: 'flex',
    flex: $fullScreen && 1,
    flexDirection: 'column',
    padding: !$fullScreen && theme.spacing(3)
  };
  return styles;
});
var StyledModalButton = styled(Button)(function () {
  var styles = {
    minWidth: pxToRem(168)
  };
  return styles;
});
export { StyledModalButton, StyledModalContainer };