import { pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledBox = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    backgroundColor: theme.palette.white.main,
    borderRadius: pxToRem(16),
    padding: theme.spacing(3, 2)
  };
  return styles;
});
var StyledBlock = styled(Box)(function (_ref2) {
  var _theme$colors;

  var theme = _ref2.theme;
  var styles = {
    borderTop: "".concat(pxToRem(1), " solid ").concat(theme === null || theme === void 0 ? void 0 : (_theme$colors = theme.colors) === null || _theme$colors === void 0 ? void 0 : _theme$colors.lavenderSyrup),
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2)
  };
  return styles;
});
export { StyledBlock, StyledBox };