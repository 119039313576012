import { Button, pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledBoxWrapper = styled(Button)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    alignItems: 'center',
    border: "1px solid ".concat(theme.palette.alpineGoat.main),
    borderRadius: pxToRem(16),
    color: theme.palette.white.main,
    display: 'flex',
    height: pxToRem(76),
    justifyContent: 'left',
    padding: theme.spacing(2),
    width: '100%'
  };
  return styles;
});
var StyledBoxIcon = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    alignItems: 'center',
    backgroundColor: theme.colors.panPurple,
    borderRadius: pxToRem(12),
    display: 'flex',
    height: pxToRem(44),
    justifyContent: 'center',
    marginRight: pxToRem(12),
    width: pxToRem(44)
  };
  return styles;
});
export { StyledBoxIcon, StyledBoxWrapper };