import { IconButton, pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledWrapperDrawer = styled(Box)(function (_ref) {
  var theme = _ref.theme,
      isMobile = _ref.isMobile;
  var styles = {
    marginLeft: isMobile ? 0 : theme.spacing(-2),
    paddingBottom: theme.spacing(3.25)
  };
  return styles;
});
var StyledIconButton = styled(IconButton)(function (_ref2) {
  var theme = _ref2.theme,
      isMobile = _ref2.isMobile;
  var styles = {
    '&:hover': {
      backgroundColor: 'rgba(151,153,177,0.04)'
    },
    backgroundColor: isMobile ? theme.colors.whiteSolid : 'unset',
    borderRadius: pxToRem(12),
    justifyContent: 'start',
    padding: theme.spacing(2, 2.25),
    width: '100%'
  };
  return styles;
});
export { StyledIconButton, StyledWrapperDrawer };