export var NotificationActions;

(function (NotificationActions) {
  NotificationActions["Close"] = "close";
  NotificationActions["Deeplink"] = "deeplink";
  NotificationActions["Url"] = "url";
})(NotificationActions || (NotificationActions = {}));

export var NotificationStatuses;

(function (NotificationStatuses) {
  NotificationStatuses["Interested"] = "T";
  NotificationStatuses["NotRead"] = "N";
  NotificationStatuses["Viewed"] = "V";
})(NotificationStatuses || (NotificationStatuses = {}));