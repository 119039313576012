import { styled as t, Box as e, Drawer as o } from "@mui/material";
import "../../../themes/box-shadows/boxShadows.js";
import "../../../themes/dark.theme.js";
import { pxToRem as r } from "../../../themes/default-theme/default-theme.js";
import "../../../themes/gradients/gradients.js";
import "../../../themes/light.theme.js";
var i = t(e)(function (t) {
  return t.theme, t.$filled, {
    "& .MuiFormControl-root": {
      position: "unset"
    },
    width: t.$fullWidth && "100%"
  };
}),
    n = t(e)(function (t) {
  var e = t.theme;
  return {
    display: "flex",
    flexDirection: "column",
    maxHeight: e.convertToDVH(100),
    paddingBottom: e.spacing(6)
  };
}),
    m = t(e)(function (t) {
  return {
    padding: t.theme.spacing(3, 2, 0),
    textAlign: "center"
  };
}),
    a = t(e)(function (t) {
  var e = t.theme;
  return {
    backgroundColor: e.colors.carteBlanche,
    borderRadius: r(2),
    height: r(3),
    margin: e.spacing(1, "auto", 0, "auto"),
    opacity: "50%",
    width: r(60)
  };
}),
    h = t(o)(function (t) {
  return {
    zIndex: t.theme.zIndex.modal + 1
  };
});
export { n as DrawerContainer, a as Puller, h as StyledDrawer, m as StyledTitle, i as StyledWrapper };