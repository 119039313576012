import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, Button, styled } from '@mui/material';
var StyledMoreWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(3)
  }, theme.breakpoints.up('desktop'), {
    display: 'block',
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(3)
  });

  return styles;
});
var StyledTabsContent = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    marginTop: theme.spacing(3)
  };
  return styles;
});
var StyledArrowWrapper = styled(Button)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    '& svg': {
      transition: theme.transitions.create(['color'])
    },
    minWidth: 'unset',
    padding: '0'
  };
  return styles;
});
export { StyledArrowWrapper, StyledMoreWrapper, StyledTabsContent };