import { CreditFormFieldType } from '@enums';

var getInitialValues = function getInitialValues(data) {
  if (!data) return;
  return data.reduce(function (acc, field) {
    var textId = field.textId,
        dfltValue = field.dfltValue,
        type = field.type;

    if (type === CreditFormFieldType.Checkbox) {
      acc[textId] = dfltValue === '1'; // checked

      return acc;
    }

    if (!dfltValue) {
      return acc;
    }

    acc[textId] = dfltValue;
    return acc;
  }, {});
};

export { getInitialValues };