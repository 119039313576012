import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, styled } from '@mui/material';
import { pxToRem } from '@themes';
var StyledExchangeBox = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var height = theme.convertToDVH(100);

  var styles = _defineProperty({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: "calc(".concat(height, " - ").concat(pxToRem(40 + 50 + 68 + 32 + 16 + 32), ")")
  }, theme.breakpoints.up('desktop'), {
    display: 'block',
    minHeight: 'unset'
  });

  return styles;
});
export { StyledExchangeBox };