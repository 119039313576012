export var getDepositCalculatorMock = function getDepositCalculatorMock() {
  return {
    dataAccountList: [{
      accountAmount: 9990.0,
      accountCurrency: 'BYN',
      accountName: 'Текущий счет BYN1',
      accountNumber: '3014022600009',
      accountPrefix: '3014',
      accountSuffix: '009',
      accountToAccountType: 0,
      bankCode: '226',
      bankDepartment: '99500',
      cards: [],
      defaultAccount: true,
      equivalents: [{
        equivalentAmount: 3468.75,
        equivalentCurrency: 'USD'
      }, {
        equivalentAmount: 3227.79,
        equivalentCurrency: 'EUR'
      }],
      iban: 'BY90BELB30140226000098H0V003',
      ibanReadable: 'BY90 BELB 3014 0226 0000 98H0 V003',
      id: 1,
      isOverDraft: true,
      rate: 0.01
    }, {
      accountAmount: 9000.0,
      accountCurrency: 'BYN',
      accountName: 'My account42',
      accountNumber: '3014098H0V005',
      accountPrefix: '3014',
      accountSuffix: '005',
      accountToAccountType: null,
      bankCode: '226',
      bankDepartment: '12040',
      cards: [],
      defaultAccount: false,
      equivalents: [{
        equivalentAmount: 3125.0,
        equivalentCurrency: 'USD'
      }, {
        equivalentAmount: 2907.92,
        equivalentCurrency: 'EUR'
      }],
      iban: 'BY69BELB3014098H0V0050226000',
      ibanReadable: 'BY69 BELB 3014 098H 0V00 5022 6000',
      id: 2,
      isOverDraft: false,
      rate: 0.0
    }, {
      accountAmount: 9000.0,
      accountCurrency: 'BYN',
      accountName: '1',
      accountNumber: '3014098H0V01N',
      accountPrefix: '3014',
      accountSuffix: '01N',
      accountToAccountType: null,
      bankCode: '226',
      bankDepartment: '12030',
      cards: [],
      defaultAccount: false,
      equivalents: [{
        equivalentAmount: 3125.0,
        equivalentCurrency: 'USD'
      }, {
        equivalentAmount: 2907.92,
        equivalentCurrency: 'EUR'
      }],
      iban: 'BY32BELB3014098H0V01N0226000',
      ibanReadable: 'BY32 BELB 3014 098H 0V01 N022 6000',
      id: 3,
      isOverDraft: false,
      rate: 0.0
    }, {
      accountAmount: 9000.0,
      accountCurrency: 'BYN',
      accountName: '42',
      accountNumber: '3014098H0V01O',
      accountPrefix: '3014',
      accountSuffix: '01O',
      accountToAccountType: null,
      bankCode: '226',
      bankDepartment: '12030',
      cards: [],
      defaultAccount: false,
      equivalents: [{
        equivalentAmount: 3125.0,
        equivalentCurrency: 'USD'
      }, {
        equivalentAmount: 2907.92,
        equivalentCurrency: 'EUR'
      }],
      iban: 'BY17BELB3014098H0V01O0226000',
      ibanReadable: 'BY17 BELB 3014 098H 0V01 O022 6000',
      id: 4,
      isOverDraft: false,
      rate: 0.0
    }, {
      accountAmount: 9000.0,
      accountCurrency: 'USD',
      accountName: 'USD42',
      accountNumber: '3014098H0V01P',
      accountPrefix: '3014',
      accountSuffix: '01P',
      accountToAccountType: null,
      bankCode: '226',
      bankDepartment: '12030',
      cards: [],
      defaultAccount: false,
      equivalents: [{
        equivalentAmount: 25560.0,
        equivalentCurrency: 'BYN'
      }, {
        equivalentAmount: 8333.33,
        equivalentCurrency: 'EUR'
      }],
      iban: 'BY02BELB3014098H0V01P0226000',
      ibanReadable: 'BY02 BELB 3014 098H 0V01 P022 6000',
      id: 5,
      isOverDraft: false,
      rate: 0.0
    }],
    dataDepositList: [{
      MaxAma: 999999999999999,
      MinAma: 1000.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВФФ',
      depositAmnt: 0.0,
      depositCurr: 'BYN',
      depositName: 'Универсальный безотзывный',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 1,
      interestAmnt: 0.0,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 8.6,
      reserve: '',
      sdboFlag: 'N',
      taxAmnt: 0.0,
      term: '35',
      termAbbr: 'D'
    }, {
      MaxAma: 999999999999999,
      MinAma: 50.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВИО',
      depositAmnt: 0.0,
      depositCurr: 'BYN',
      depositName: 'Безотзывный онлайн',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 2,
      interestAmnt: 0.0,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 4.2,
      reserve: '',
      sdboFlag: 'Y',
      taxAmnt: 0.0,
      term: '35',
      termAbbr: 'D'
    }, {
      MaxAma: 999999999999999,
      MinAma: 1000.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВФФ',
      depositAmnt: 0.0,
      depositCurr: 'BYN',
      depositName: 'Универсальный безотзывный',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 3,
      interestAmnt: 0.0,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 10.0,
      reserve: '',
      sdboFlag: 'N',
      taxAmnt: 0.0,
      term: '3',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 50.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'N',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВИО',
      depositAmnt: 0.0,
      depositCurr: 'BYN',
      depositName: 'Безотзывный онлайн',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 4,
      interestAmnt: 0.0,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 7.1,
      reserve: '',
      sdboFlag: 'Y',
      taxAmnt: 0.0,
      term: '3',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 1000.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВФФ',
      depositAmnt: 0.0,
      depositCurr: 'BYN',
      depositName: 'Универсальный безотзывный',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 5,
      interestAmnt: 0.0,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 11.5,
      reserve: '',
      sdboFlag: 'N',
      taxAmnt: 0.0,
      term: '7',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 50.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'N',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВИО',
      depositAmnt: 0.0,
      depositCurr: 'BYN',
      depositName: 'Безотзывный онлайн',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 6,
      interestAmnt: 0.0,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 8.5,
      reserve: '',
      sdboFlag: 'Y',
      taxAmnt: 0.0,
      term: '7',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 1000.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВФФ',
      depositAmnt: 0.0,
      depositCurr: 'BYN',
      depositName: 'Универсальный безотзывный',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 7,
      interestAmnt: 0.0,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 12.3,
      reserve: '',
      sdboFlag: 'N',
      taxAmnt: 0.0,
      term: '13',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 50.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'N',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВИО',
      depositAmnt: 0.0,
      depositCurr: 'BYN',
      depositName: 'Безотзывный онлайн',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 8,
      interestAmnt: 0.0,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 12.5,
      reserve: '',
      sdboFlag: 'Y',
      taxAmnt: 0.0,
      term: '13',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 1000.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВФФ',
      depositAmnt: 0.0,
      depositCurr: 'BYN',
      depositName: 'Универсальный безотзывный',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 9,
      interestAmnt: 0.0,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 14.2,
      reserve: '',
      sdboFlag: 'N',
      taxAmnt: 0.0,
      term: '24',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 50.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'N',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВИО',
      depositAmnt: 0.0,
      depositCurr: 'BYN',
      depositName: 'Безотзывный онлайн',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 10,
      interestAmnt: 0.0,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 16.0,
      reserve: '',
      sdboFlag: 'Y',
      taxAmnt: 0.0,
      term: '24',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 50.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВИК',
      depositAmnt: 0.0,
      depositCurr: 'BYN',
      depositName: 'Интернет-Вкл@д фикс',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 11,
      interestAmnt: 0.0,
      otzFlag: 'Y',
      otzPartiallyFlag: 'Y',
      pensionDepositFlag: '',
      rate: 6.8,
      reserve: '',
      sdboFlag: 'Y',
      taxAmnt: 0.0,
      term: '35',
      termAbbr: 'D'
    }, {
      MaxAma: 999999999999999,
      MinAma: 1000.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВФЗ',
      depositAmnt: 0.0,
      depositCurr: 'BYN',
      depositName: 'УНИВЕРСАЛЬНЫЙ',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 12,
      interestAmnt: 0.0,
      otzFlag: 'Y',
      otzPartiallyFlag: 'Y',
      pensionDepositFlag: '',
      rate: 7.7,
      reserve: '',
      sdboFlag: 'N',
      taxAmnt: 0.0,
      term: '3',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 50.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВИК',
      depositAmnt: 0.0,
      depositCurr: 'BYN',
      depositName: 'Интернет-Вкл@д фикс',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 13,
      interestAmnt: 0.0,
      otzFlag: 'Y',
      otzPartiallyFlag: 'Y',
      pensionDepositFlag: '',
      rate: 8.0,
      reserve: '',
      sdboFlag: 'Y',
      taxAmnt: 0.0,
      term: '3',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 1000.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВФЗ',
      depositAmnt: 0.0,
      depositCurr: 'BYN',
      depositName: 'УНИВЕРСАЛЬНЫЙ',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 14,
      interestAmnt: 0.0,
      otzFlag: 'Y',
      otzPartiallyFlag: 'Y',
      pensionDepositFlag: '',
      rate: 7.7,
      reserve: '',
      sdboFlag: 'N',
      taxAmnt: 0.0,
      term: '6',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 50.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВИК',
      depositAmnt: 0.0,
      depositCurr: 'BYN',
      depositName: 'Интернет-Вкл@д фикс',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 15,
      interestAmnt: 0.0,
      otzFlag: 'Y',
      otzPartiallyFlag: 'Y',
      pensionDepositFlag: '',
      rate: 8.0,
      reserve: '',
      sdboFlag: 'Y',
      taxAmnt: 0.0,
      term: '6',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 1000.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВФЗ',
      depositAmnt: 0.0,
      depositCurr: 'BYN',
      depositName: 'УНИВЕРСАЛЬНЫЙ',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 16,
      interestAmnt: 0.0,
      otzFlag: 'Y',
      otzPartiallyFlag: 'Y',
      pensionDepositFlag: '',
      rate: 7.7,
      reserve: '',
      sdboFlag: 'N',
      taxAmnt: 0.0,
      term: '12',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 50.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВИК',
      depositAmnt: 0.0,
      depositCurr: 'BYN',
      depositName: 'Интернет-Вкл@д фикс',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 17,
      interestAmnt: 0.0,
      otzFlag: 'Y',
      otzPartiallyFlag: 'Y',
      pensionDepositFlag: '',
      rate: 8.0,
      reserve: '',
      sdboFlag: 'Y',
      taxAmnt: 0.0,
      term: '12',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 1000.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВФЗ',
      depositAmnt: 0.0,
      depositCurr: 'BYN',
      depositName: 'УНИВЕРСАЛЬНЫЙ',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 18,
      interestAmnt: 0.0,
      otzFlag: 'Y',
      otzPartiallyFlag: 'Y',
      pensionDepositFlag: '',
      rate: 7.7,
      reserve: '',
      sdboFlag: 'N',
      taxAmnt: 0.0,
      term: '18',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 50.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВИК',
      depositAmnt: 0.0,
      depositCurr: 'BYN',
      depositName: 'Интернет-Вкл@д фикс',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 19,
      interestAmnt: 0.0,
      otzFlag: 'Y',
      otzPartiallyFlag: 'Y',
      pensionDepositFlag: '',
      rate: 8.0,
      reserve: '',
      sdboFlag: 'Y',
      taxAmnt: 0.0,
      term: '18',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 1000.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВФЗ',
      depositAmnt: 0.0,
      depositCurr: 'BYN',
      depositName: 'УНИВЕРСАЛЬНЫЙ',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 20,
      interestAmnt: 0.0,
      otzFlag: 'Y',
      otzPartiallyFlag: 'Y',
      pensionDepositFlag: '',
      rate: 7.7,
      reserve: '',
      sdboFlag: 'N',
      taxAmnt: 0.0,
      term: '24',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 50.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВИК',
      depositAmnt: 0.0,
      depositCurr: 'BYN',
      depositName: 'Интернет-Вкл@д фикс',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 21,
      interestAmnt: 0.0,
      otzFlag: 'Y',
      otzPartiallyFlag: 'Y',
      pensionDepositFlag: '',
      rate: 8.0,
      reserve: '',
      sdboFlag: 'Y',
      taxAmnt: 0.0,
      term: '24',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 1000.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВФФ',
      depositAmnt: 0.0,
      depositCurr: 'USD',
      depositName: 'Универсальный безотзывный',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 22,
      interestAmnt: 0.0,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 4.3,
      reserve: '',
      sdboFlag: 'N',
      taxAmnt: 0.0,
      term: '35',
      termAbbr: 'D'
    }, {
      MaxAma: 999999999999999,
      MinAma: 50.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'N',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВИО',
      depositAmnt: 0.0,
      depositCurr: 'USD',
      depositName: 'Безотзывный онлайн',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 23,
      interestAmnt: 0.0,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 1.6,
      reserve: '',
      sdboFlag: 'Y',
      taxAmnt: 0.0,
      term: '35',
      termAbbr: 'D'
    }, {
      MaxAma: 999999999999999,
      MinAma: 1000.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВФФ',
      depositAmnt: 0.0,
      depositCurr: 'USD',
      depositName: 'Универсальный безотзывный',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 24,
      interestAmnt: 0.0,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 5.0,
      reserve: '',
      sdboFlag: 'N',
      taxAmnt: 0.0,
      term: '3',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 50.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'N',
      capitalizationDflt: false,
      capitalizationFlag: 'N',
      dealType: 'ВИО',
      depositAmnt: 0.0,
      depositCurr: 'USD',
      depositName: 'Безотзывный онлайн',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 25,
      interestAmnt: 0.0,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 1.9,
      reserve: '',
      sdboFlag: 'Y',
      taxAmnt: 0.0,
      term: '3',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 1000.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВФФ',
      depositAmnt: 0.0,
      depositCurr: 'USD',
      depositName: 'Универсальный безотзывный',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 26,
      interestAmnt: 0.0,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 6.0,
      reserve: '',
      sdboFlag: 'N',
      taxAmnt: 0.0,
      term: '7',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 50.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'N',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВИО',
      depositAmnt: 0.0,
      depositCurr: 'USD',
      depositName: 'Безотзывный онлайн',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 27,
      interestAmnt: 0.0,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 2.6,
      reserve: '',
      sdboFlag: 'Y',
      taxAmnt: 0.0,
      term: '7',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 1000.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВФФ',
      depositAmnt: 0.0,
      depositCurr: 'USD',
      depositName: 'Универсальный безотзывный',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 28,
      interestAmnt: 0.0,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 6.6,
      reserve: '',
      sdboFlag: 'N',
      taxAmnt: 0.0,
      term: '13',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 50.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'N',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВИО',
      depositAmnt: 0.0,
      depositCurr: 'USD',
      depositName: 'Безотзывный онлайн',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 29,
      interestAmnt: 0.0,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 4.0,
      reserve: '',
      sdboFlag: 'Y',
      taxAmnt: 0.0,
      term: '13',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 1000.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВФФ',
      depositAmnt: 0.0,
      depositCurr: 'USD',
      depositName: 'Универсальный безотзывный',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 30,
      interestAmnt: 0.0,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 6.4,
      reserve: '',
      sdboFlag: 'N',
      taxAmnt: 0.0,
      term: '24',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 50.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'N',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВИО',
      depositAmnt: 0.0,
      depositCurr: 'USD',
      depositName: 'Безотзывный онлайн',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 31,
      interestAmnt: 0.0,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 5.0,
      reserve: '',
      sdboFlag: 'Y',
      taxAmnt: 0.0,
      term: '24',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 1000.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВФЗ',
      depositAmnt: 0.0,
      depositCurr: 'USD',
      depositName: 'УНИВЕРСАЛЬНЫЙ',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 32,
      interestAmnt: 0.0,
      otzFlag: 'Y',
      otzPartiallyFlag: 'Y',
      pensionDepositFlag: '',
      rate: 2.4,
      reserve: '',
      sdboFlag: 'N',
      taxAmnt: 0.0,
      term: '12',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 1000.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВФЗ',
      depositAmnt: 0.0,
      depositCurr: 'USD',
      depositName: 'УНИВЕРСАЛЬНЫЙ',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 33,
      interestAmnt: 0.0,
      otzFlag: 'Y',
      otzPartiallyFlag: 'Y',
      pensionDepositFlag: '',
      rate: 3.6,
      reserve: '',
      sdboFlag: 'N',
      taxAmnt: 0.0,
      term: '24',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 1000.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВФФ',
      depositAmnt: 0.0,
      depositCurr: 'EUR',
      depositName: 'Универсальный безотзывный',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 34,
      interestAmnt: 0.0,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 4.3,
      reserve: '',
      sdboFlag: 'N',
      taxAmnt: 0.0,
      term: '35',
      termAbbr: 'D'
    }, {
      MaxAma: 999999999999999,
      MinAma: 50.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'N',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВИО',
      depositAmnt: 0.0,
      depositCurr: 'EUR',
      depositName: 'Безотзывный онлайн',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 35,
      interestAmnt: 0.0,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 1.6,
      reserve: '',
      sdboFlag: 'Y',
      taxAmnt: 0.0,
      term: '35',
      termAbbr: 'D'
    }, {
      MaxAma: 999999999999999,
      MinAma: 1000.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВФФ',
      depositAmnt: 0.0,
      depositCurr: 'EUR',
      depositName: 'Универсальный безотзывный',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 36,
      interestAmnt: 0.0,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 5.1,
      reserve: '',
      sdboFlag: 'N',
      taxAmnt: 0.0,
      term: '3',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 50.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'N',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВИО',
      depositAmnt: 0.0,
      depositCurr: 'EUR',
      depositName: 'Безотзывный онлайн',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 37,
      interestAmnt: 0.0,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 2.1,
      reserve: '',
      sdboFlag: 'Y',
      taxAmnt: 0.0,
      term: '3',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 1000.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВФФ',
      depositAmnt: 0.0,
      depositCurr: 'EUR',
      depositName: 'Универсальный безотзывный',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 38,
      interestAmnt: 0.0,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 5.9,
      reserve: '',
      sdboFlag: 'N',
      taxAmnt: 0.0,
      term: '7',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 50.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'N',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВИО',
      depositAmnt: 0.0,
      depositCurr: 'EUR',
      depositName: 'Безотзывный онлайн',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 39,
      interestAmnt: 0.0,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 2.8,
      reserve: '',
      sdboFlag: 'Y',
      taxAmnt: 0.0,
      term: '7',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 1000.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВФФ',
      depositAmnt: 0.0,
      depositCurr: 'EUR',
      depositName: 'Универсальный безотзывный',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 40,
      interestAmnt: 0.0,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 6.6,
      reserve: '',
      sdboFlag: 'N',
      taxAmnt: 0.0,
      term: '13',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 50.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'N',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВИО',
      depositAmnt: 0.0,
      depositCurr: 'EUR',
      depositName: 'Безотзывный онлайн',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 41,
      interestAmnt: 0.0,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 3.5,
      reserve: '',
      sdboFlag: 'Y',
      taxAmnt: 0.0,
      term: '13',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 1000.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВФФ',
      depositAmnt: 0.0,
      depositCurr: 'EUR',
      depositName: 'Универсальный безотзывный',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 42,
      interestAmnt: 0.0,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 6.2,
      reserve: '',
      sdboFlag: 'N',
      taxAmnt: 0.0,
      term: '24',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 50.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'N',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВИО',
      depositAmnt: 0.0,
      depositCurr: 'EUR',
      depositName: 'Безотзывный онлайн',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 43,
      interestAmnt: 0.0,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 3.6,
      reserve: '',
      sdboFlag: 'Y',
      taxAmnt: 0.0,
      term: '24',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 1000.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВФЗ',
      depositAmnt: 0.0,
      depositCurr: 'EUR',
      depositName: 'УНИВЕРСАЛЬНЫЙ',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 44,
      interestAmnt: 0.0,
      otzFlag: 'Y',
      otzPartiallyFlag: 'Y',
      pensionDepositFlag: '',
      rate: 2.4,
      reserve: '',
      sdboFlag: 'N',
      taxAmnt: 0.0,
      term: '12',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 1000.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВФЗ',
      depositAmnt: 0.0,
      depositCurr: 'EUR',
      depositName: 'УНИВЕРСАЛЬНЫЙ',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 45,
      interestAmnt: 0.0,
      otzFlag: 'Y',
      otzPartiallyFlag: 'Y',
      pensionDepositFlag: '',
      rate: 2.8,
      reserve: '',
      sdboFlag: 'N',
      taxAmnt: 0.0,
      term: '24',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 0.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВФФ',
      depositAmnt: 0.0,
      depositCurr: 'RUB',
      depositName: 'Универсальный безотзывный',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 46,
      interestAmnt: 0.0,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 9.3,
      reserve: '',
      sdboFlag: 'N',
      taxAmnt: 0.0,
      term: '35',
      termAbbr: 'D'
    }, {
      MaxAma: 999999999999999,
      MinAma: 0.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВФФ',
      depositAmnt: 0.0,
      depositCurr: 'RUB',
      depositName: 'Универсальный безотзывный',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 47,
      interestAmnt: 0.0,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 11.0,
      reserve: '',
      sdboFlag: 'N',
      taxAmnt: 0.0,
      term: '3',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 0.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВФФ',
      depositAmnt: 0.0,
      depositCurr: 'RUB',
      depositName: 'Универсальный безотзывный',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 48,
      interestAmnt: 0.0,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 12.0,
      reserve: '',
      sdboFlag: 'N',
      taxAmnt: 0.0,
      term: '7',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 0.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВФФ',
      depositAmnt: 0.0,
      depositCurr: 'RUB',
      depositName: 'Универсальный безотзывный',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 49,
      interestAmnt: 0.0,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 12.2,
      reserve: '',
      sdboFlag: 'N',
      taxAmnt: 0.0,
      term: '13',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 0.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВФФ',
      depositAmnt: 0.0,
      depositCurr: 'RUB',
      depositName: 'Универсальный безотзывный',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 50,
      interestAmnt: 0.0,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 12.0,
      reserve: '',
      sdboFlag: 'N',
      taxAmnt: 0.0,
      term: '24',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 0.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВФЗ',
      depositAmnt: 0.0,
      depositCurr: 'RUB',
      depositName: 'УНИВЕРСАЛЬНЫЙ',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 51,
      interestAmnt: 0.0,
      otzFlag: 'Y',
      otzPartiallyFlag: 'Y',
      pensionDepositFlag: '',
      rate: 6.3,
      reserve: '',
      sdboFlag: 'N',
      taxAmnt: 0.0,
      term: '12',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 0.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'Y',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВФЗ',
      depositAmnt: 0.0,
      depositCurr: 'RUB',
      depositName: 'УНИВЕРСАЛЬНЫЙ',
      depositTopUpAllowed: '',
      fixedRateFlag: 'Y',
      id: 52,
      interestAmnt: 0.0,
      otzFlag: 'Y',
      otzPartiallyFlag: 'Y',
      pensionDepositFlag: '',
      rate: 7.3,
      reserve: '',
      sdboFlag: 'N',
      taxAmnt: 0.0,
      term: '24',
      termAbbr: 'M'
    }],
    dataDepositListDflt: [{
      MaxAma: 999999999999999,
      MinAma: 50.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'N',
      capitalizationDflt: true,
      capitalizationFlag: '',
      dealType: 'ВИО',
      depositAmnt: 1000.02,
      depositCurr: 'BYN',
      depositName: 'Безотзывный онлайн',
      depositTopUpAllowed: '',
      fileName: 'Договор-безотзывный-098H0V-BYN-1.pdf',
      fixedRateFlag: 'Y',
      id: 4,
      interestAmnt: 15.66,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 7.1,
      reserve: '',
      sdboFlag: 'Y',
      taxAmnt: 2.33,
      term: '3',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 50.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'N',
      capitalizationDflt: false,
      capitalizationFlag: '',
      dealType: 'ВИО',
      depositAmnt: 1000.02,
      depositCurr: 'BYN',
      depositName: 'Безотзывный онлайн',
      depositTopUpAllowed: '',
      fileName: 'Договор-безотзывный-098H0V-BYN-2.pdf',
      fixedRateFlag: 'Y',
      id: 4,
      interestAmnt: 15.57,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 7.1,
      reserve: '',
      sdboFlag: 'Y',
      taxAmnt: 2.32,
      term: '3',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 50.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'N',
      capitalizationDflt: true,
      capitalizationFlag: 'N',
      dealType: 'ВИО',
      depositAmnt: 200.02,
      depositCurr: 'USD',
      depositName: 'Безотзывный онлайн',
      depositTopUpAllowed: '',
      fileName: 'Договор-безотзывный-098H0V-USD-1.pdf',
      fixedRateFlag: 'Y',
      id: 25,
      interestAmnt: 0.85,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 1.9,
      reserve: '',
      sdboFlag: 'Y',
      taxAmnt: 0.12,
      term: '3',
      termAbbr: 'M'
    }, {
      MaxAma: 999999999999999,
      MinAma: 50.0,
      OpnDateFlag: '',
      automaticPrlFlag: 'N',
      capitalizationDflt: false,
      capitalizationFlag: 'N',
      dealType: 'ВИО',
      depositAmnt: 200.02,
      depositCurr: 'USD',
      depositName: 'Безотзывный онлайн',
      depositTopUpAllowed: '',
      fileName: 'Договор-безотзывный-098H0V-USD-2.pdf',
      fixedRateFlag: 'Y',
      id: 25,
      interestAmnt: 0.83,
      otzFlag: 'N',
      otzPartiallyFlag: 'N',
      pensionDepositFlag: '',
      rate: 1.9,
      reserve: '',
      sdboFlag: 'Y',
      taxAmnt: 0.12,
      term: '3',
      termAbbr: 'M'
    }]
  };
};
export var getDepositCalculatorPutMock = function getDepositCalculatorPutMock() {
  return {
    depositCurr: 'BYN',
    fileName: 'fileName',
    interestAmnt: 20,
    taxAmnt: 1
  };
};
export var getDepositOpenMock = function getDepositOpenMock() {
  return {
    codeOperation: '2410',
    dataOperation: [{
      id: 1,
      opDetails: 'string'
    }],
    idOTP: 7890,
    idTransaction: '123456',
    messageForClient: 'На номер +37529***4580 отправлен код для подтверждения.',
    otp: true,
    pin: false
  };
};