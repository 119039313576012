import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, styled } from '@mui/material';
var StyledButtonWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    marginTop: theme.spacing(2.5)
  };
  return styles;
});
var StyledCheckboxWrapper = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;

  var styles = _defineProperty({
    paddingLeft: theme.spacing(1)
  }, theme.breakpoints.up('desktop'), {
    paddingLeft: 'unset'
  });

  return styles;
});
export { StyledButtonWrapper, StyledCheckboxWrapper };