export var getAccountToAccountMock = function getAccountToAccountMock() {
  return {
    accountCt: 'BY49BELB3014104LOX01I0226000',
    accountCurrCt: 'USD',
    accountCurrDt: 'BYN',
    accountDt: 'BY50BELB3014104LOX0020226000',
    accountList: [{
      accountAmount: 33.04,
      accountCurrency: 'BYN',
      accountName: 'не проходит перевод',
      accountNumber: '3014104LOX002',
      accountPrefix: '3014',
      accountSuffix: '002',
      accountToAccountType: 0,
      bankCode: '226',
      bankDepartment: '99500',
      cards: [],
      defaultAccount: true,
      equivalents: [{
        equivalentAmount: 11.47,
        equivalentCurrency: 'USD'
      }, {
        equivalentAmount: 10.68,
        equivalentCurrency: 'EUR'
      }],
      iban: 'BY50BELB3014104LOX0020226000',
      ibanReadable: 'BY50 BELB 3014 104L OX00 2022 6000',
      id: 1,
      isOverDraft: true,
      rate: 0
    }, {
      accountAmount: 3145.8,
      accountCurrency: 'BYN',
      accountName: 'по умолчанию 1',
      accountNumber: '3014104LOX001',
      accountPrefix: '3014',
      accountSuffix: '001',
      accountToAccountType: null,
      bankCode: '226',
      bankDepartment: '99500',
      cards: [{
        cardCurrency: 'BYN',
        cardName: 'Visa Infinite Multi',
        cardNumber: '****2686',
        cardStatus: null,
        cardTerm: null,
        cardType: 'Visa Infinite Multi',
        pictureID: 'VISA_INFINITE',
        pictureURL: '/remote/cards/VISA_INFINITE.png'
      }, {
        cardCurrency: 'BYN',
        cardName: 'Visa Platinum Multi',
        cardNumber: '****4230',
        cardStatus: null,
        cardTerm: null,
        cardType: 'Visa Platinum Multi',
        pictureID: 'VISA_PLATINUM',
        pictureURL: '/remote/cards/VISA_PLATINUM.png'
      }, {
        cardCurrency: 'BYN',
        cardName: 'Visa Classic Multi',
        cardNumber: '****9584',
        cardStatus: null,
        cardTerm: null,
        cardType: 'Visa Classic Multi',
        pictureID: 'VISA_CLASSIC',
        pictureURL: '/remote/cards/VISA_CLASSIC.png'
      }, {
        cardCurrency: 'BYN',
        cardName: 'БЕЛКАРТ-ПРЕМИУМ МИР',
        cardNumber: '****9989',
        cardStatus: null,
        cardTerm: null,
        cardType: 'БЕЛКАРТ-ПРЕМИУМ МИР',
        pictureID: 'BELCARD-PREMIUM',
        pictureURL: '/remote/cards/BELCARD-PREMIUM.png'
      }, {
        cardCurrency: 'BYN',
        cardName: 'Visa Classic',
        cardNumber: '****7801',
        cardStatus: null,
        cardTerm: null,
        cardType: 'Visa Classic',
        pictureID: 'VC_VIRT_DEB',
        pictureURL: '/remote/cards/VC_VIRT_DEB.png'
      }, {
        cardCurrency: 'BYN',
        cardName: 'Белкарт-Премиум Виртуальная',
        cardNumber: '****3903',
        cardStatus: null,
        cardTerm: null,
        cardType: 'Белкарт-Премиум Виртуальная',
        pictureID: 'BELKART_VIRT',
        pictureURL: '/remote/cards/BELKART_VIRT.png'
      }, {
        cardCurrency: 'BYN',
        cardName: 'Белкарт-Премиум Виртуальная',
        cardNumber: '****1569',
        cardStatus: null,
        cardTerm: null,
        cardType: 'Белкарт-Премиум Виртуальная',
        pictureID: 'BELKART_VIRT',
        pictureURL: '/remote/cards/BELKART_VIRT.png'
      }, {
        cardCurrency: 'BYN',
        cardName: 'Visa Classic Multi',
        cardNumber: '****9679',
        cardStatus: null,
        cardTerm: null,
        cardType: 'Visa Classic Multi',
        pictureID: 'VISA_CLASSIC',
        pictureURL: '/remote/cards/VISA_CLASSIC.png'
      }, {
        cardCurrency: 'BYN',
        cardName: 'Visa Classic Multi',
        cardNumber: '****1447',
        cardStatus: null,
        cardTerm: null,
        cardType: 'Visa Classic Multi',
        pictureID: 'VISA_CLASSIC',
        pictureURL: '/remote/cards/VISA_CLASSIC.png'
      }, {
        cardCurrency: 'BYN',
        cardName: 'Visa Classic Multi',
        cardNumber: '****8815',
        cardStatus: null,
        cardTerm: null,
        cardType: 'Visa Classic Multi',
        pictureID: 'VISA_CLASSIC',
        pictureURL: '/remote/cards/VISA_CLASSIC.png'
      }, {
        cardCurrency: 'BYN',
        cardName: 'Visa Classic Multi',
        cardNumber: '****3460',
        cardStatus: null,
        cardTerm: null,
        cardType: 'Visa Classic Multi',
        pictureID: 'VISA_CLASSIC',
        pictureURL: '/remote/cards/VISA_CLASSIC.png'
      }, {
        cardCurrency: 'BYN',
        cardName: 'Visa Classic Multi',
        cardNumber: '****2767',
        cardStatus: null,
        cardTerm: null,
        cardType: 'Visa Classic Multi',
        pictureID: 'VISA_CLASSIC',
        pictureURL: '/remote/cards/VISA_CLASSIC.png'
      }, {
        cardCurrency: 'BYN',
        cardName: 'Visa Classic Multi',
        cardNumber: '****6666',
        cardStatus: null,
        cardTerm: null,
        cardType: 'Visa Classic Multi',
        pictureID: 'VISA_CLASSIC',
        pictureURL: '/remote/cards/VISA_CLASSIC.png'
      }, {
        cardCurrency: 'BYN',
        cardName: 'Visa Classic Multi',
        cardNumber: '****3990',
        cardStatus: null,
        cardTerm: null,
        cardType: 'Visa Classic Multi',
        pictureID: 'VISA_CLASSIC',
        pictureURL: '/remote/cards/VISA_CLASSIC.png'
      }, {
        cardCurrency: 'BYN',
        cardName: 'Visa Gold',
        cardNumber: '****7277',
        cardStatus: null,
        cardTerm: null,
        cardType: 'Visa Gold',
        pictureID: 'VISA_GOLD_OLD',
        pictureURL: '/remote/cards/VISA_GOLD_OLD.png'
      }, {
        cardCurrency: 'BYN',
        cardName: 'Visa Classic Multi',
        cardNumber: '****5512',
        cardStatus: null,
        cardTerm: null,
        cardType: 'Visa Classic Multi',
        pictureID: 'VISA_CLASSIC',
        pictureURL: '/remote/cards/VISA_CLASSIC.png'
      }],
      defaultAccount: false,
      equivalents: [{
        equivalentAmount: 1096.48,
        equivalentCurrency: 'USD'
      }, {
        equivalentAmount: 1019.71,
        equivalentCurrency: 'EUR'
      }],
      iban: 'BY65BELB3014104LOX0010226000',
      ibanReadable: 'BY65 BELB 3014 104L OX00 1022 6000',
      id: 2,
      isOverDraft: false,
      rate: 0
    }, {
      accountAmount: 0,
      accountCurrency: 'BYN',
      accountName: 'новый',
      accountNumber: '3014104LOX02E',
      accountPrefix: '3014',
      accountSuffix: '02E',
      accountToAccountType: null,
      bankCode: '226',
      bankDepartment: '99500',
      cards: [],
      defaultAccount: false,
      equivalents: [{
        equivalentAmount: 0,
        equivalentCurrency: 'USD'
      }, {
        equivalentAmount: 0,
        equivalentCurrency: 'EUR'
      }],
      iban: 'BY64BELB3014104LOX02E0226000',
      ibanReadable: 'BY64 BELB 3014 104L OX02 E022 6000',
      id: 3,
      isOverDraft: false,
      rate: 0
    }, {
      accountAmount: 0,
      accountCurrency: 'BYN',
      accountName: '1',
      accountNumber: '3014104LOX02F',
      accountPrefix: '3014',
      accountSuffix: '02F',
      accountToAccountType: null,
      bankCode: '226',
      bankDepartment: '99500',
      cards: [],
      defaultAccount: false,
      equivalents: [{
        equivalentAmount: 0,
        equivalentCurrency: 'USD'
      }, {
        equivalentAmount: 0,
        equivalentCurrency: 'EUR'
      }],
      iban: 'BY49BELB3014104LOX02F0226000',
      ibanReadable: 'BY49 BELB 3014 104L OX02 F022 6000',
      id: 4,
      isOverDraft: false,
      rate: 0
    }, {
      accountAmount: 0,
      accountCurrency: 'BYN',
      accountName: 'Текущий счет %s',
      accountNumber: '3014104LOX02G',
      accountPrefix: '3014',
      accountSuffix: '02G',
      accountToAccountType: null,
      bankCode: '226',
      bankDepartment: '99500',
      cards: [{
        cardCurrency: 'BYN',
        cardName: 'Белкарт-Премиум Виртуальная',
        cardNumber: '****4574',
        cardStatus: null,
        cardTerm: null,
        cardType: 'Белкарт-Премиум Виртуальная',
        pictureID: 'BELKART_VIRT',
        pictureURL: '/remote/cards/BELKART_VIRT.png'
      }],
      defaultAccount: false,
      equivalents: [{
        equivalentAmount: 0,
        equivalentCurrency: 'USD'
      }, {
        equivalentAmount: 0,
        equivalentCurrency: 'EUR'
      }],
      iban: 'BY34BELB3014104LOX02G0226000',
      ibanReadable: 'BY34 BELB 3014 104L OX02 G022 6000',
      id: 5,
      isOverDraft: false,
      rate: 0
    }, {
      accountAmount: 0,
      accountCurrency: 'BYN',
      accountName: 'Текущий счет %s',
      accountNumber: '3014104LOX02H',
      accountPrefix: '3014',
      accountSuffix: '02H',
      accountToAccountType: null,
      bankCode: '226',
      bankDepartment: '99500',
      cards: [{
        cardCurrency: 'BYN',
        cardName: 'Белкарт-Премиум Виртуальная',
        cardNumber: '****9222',
        cardStatus: null,
        cardTerm: null,
        cardType: 'Белкарт-Премиум Виртуальная',
        pictureID: 'BELKART_VIRT',
        pictureURL: '/remote/cards/BELKART_VIRT.png'
      }],
      defaultAccount: false,
      equivalents: [{
        equivalentAmount: 0,
        equivalentCurrency: 'USD'
      }, {
        equivalentAmount: 0,
        equivalentCurrency: 'EUR'
      }],
      iban: 'BY19BELB3014104LOX02H0226000',
      ibanReadable: 'BY19 BELB 3014 104L OX02 H022 6000',
      id: 6,
      isOverDraft: false,
      rate: 0
    }, {
      accountAmount: 0,
      accountCurrency: 'BYN',
      accountName: 'Текущий счет BYN',
      accountNumber: '3014104LOX02V',
      accountPrefix: '3014',
      accountSuffix: '02V',
      accountToAccountType: null,
      bankCode: '226',
      bankDepartment: '99500',
      cards: [{
        cardCurrency: 'BYN',
        cardName: 'вторая карта юзд',
        cardNumber: '****3610',
        cardStatus: null,
        cardTerm: null,
        cardType: 'Белкарт-Премиум Виртуальная',
        pictureID: 'BELKART_VIRT',
        pictureURL: '/remote/cards/BELKART_VIRT.png'
      }],
      defaultAccount: false,
      equivalents: [{
        equivalentAmount: 0,
        equivalentCurrency: 'USD'
      }, {
        equivalentAmount: 0,
        equivalentCurrency: 'EUR'
      }],
      iban: 'BY03BELB3014104LOX02V0226000',
      ibanReadable: 'BY03 BELB 3014 104L OX02 V022 6000',
      id: 7,
      isOverDraft: false,
      rate: 0
    }, {
      accountAmount: 0,
      accountCurrency: 'BYN',
      accountName: 'Текущий счет BYN',
      accountNumber: '3014104LOX02W',
      accountPrefix: '3014',
      accountSuffix: '02W',
      accountToAccountType: null,
      bankCode: '226',
      bankDepartment: '99500',
      cards: [{
        cardCurrency: 'BYN',
        cardName: 'Visa Classic Multi',
        cardNumber: '****5232',
        cardStatus: null,
        cardTerm: null,
        cardType: 'Visa Classic Multi',
        pictureID: 'VISA_CLASSIC',
        pictureURL: '/remote/cards/VISA_CLASSIC.png'
      }],
      defaultAccount: false,
      equivalents: [{
        equivalentAmount: 0,
        equivalentCurrency: 'USD'
      }, {
        equivalentAmount: 0,
        equivalentCurrency: 'EUR'
      }],
      iban: 'BY85BELB3014104LOX02W0226000',
      ibanReadable: 'BY85 BELB 3014 104L OX02 W022 6000',
      id: 8,
      isOverDraft: false,
      rate: 0
    }, {
      accountAmount: 0,
      accountCurrency: 'BYN',
      accountName: 'Текущий счет BYN',
      accountNumber: '3014104LOX02Z',
      accountPrefix: '3014',
      accountSuffix: '02Z',
      accountToAccountType: null,
      bankCode: '226',
      bankDepartment: '99500',
      cards: [{
        cardCurrency: 'BYN',
        cardName: 'Первая карта бин1',
        cardNumber: '****5821',
        cardStatus: null,
        cardTerm: null,
        cardType: 'Белкарт-Премиум Виртуальная',
        pictureID: 'BELKART_VIRT',
        pictureURL: '/remote/cards/BELKART_VIRT.png'
      }],
      defaultAccount: false,
      equivalents: [{
        equivalentAmount: 0,
        equivalentCurrency: 'USD'
      }, {
        equivalentAmount: 0,
        equivalentCurrency: 'EUR'
      }],
      iban: 'BY40BELB3014104LOX02Z0226000',
      ibanReadable: 'BY40 BELB 3014 104L OX02 Z022 6000',
      id: 9,
      isOverDraft: false,
      rate: 0
    }, {
      accountAmount: 20.19,
      accountCurrency: 'USD',
      accountName: 'первый диапазон',
      accountNumber: '3014104LOX01I',
      accountPrefix: '3014',
      accountSuffix: '01I',
      accountToAccountType: 1,
      bankCode: '226',
      bankDepartment: '12030',
      cards: [{
        cardCurrency: 'USD',
        cardName: 'Белкарт-Премиум Виртуальная',
        cardNumber: '****3615',
        cardStatus: null,
        cardTerm: null,
        cardType: 'Белкарт-Премиум Виртуальная',
        pictureID: 'BELKART_VIRT',
        pictureURL: '/remote/cards/BELKART_VIRT.png'
      }],
      defaultAccount: false,
      equivalents: [{
        equivalentAmount: 57.34,
        equivalentCurrency: 'BYN'
      }, {
        equivalentAmount: 18.69,
        equivalentCurrency: 'EUR'
      }],
      iban: 'BY49BELB3014104LOX01I0226000',
      ibanReadable: 'BY49 BELB 3014 104L OX01 I022 6000',
      id: 10,
      isOverDraft: false,
      rate: 0
    }, {
      accountAmount: 245.29,
      accountCurrency: 'USD',
      accountName: 'второй диапазон',
      accountNumber: '3014104LOX003',
      accountPrefix: '3014',
      accountSuffix: '003',
      accountToAccountType: null,
      bankCode: '226',
      bankDepartment: '99500',
      cards: [],
      defaultAccount: false,
      equivalents: [{
        equivalentAmount: 699.32,
        equivalentCurrency: 'BYN'
      }, {
        equivalentAmount: 227.12,
        equivalentCurrency: 'EUR'
      }],
      iban: 'BY35BELB3014104LOX0030226000',
      ibanReadable: 'BY35 BELB 3014 104L OX00 3022 6000',
      id: 11,
      isOverDraft: false,
      rate: 0
    }, {
      accountAmount: 0,
      accountCurrency: 'USD',
      accountName: 'комиссия',
      accountNumber: '3014104LOX02D',
      accountPrefix: '3014',
      accountSuffix: '02D',
      accountToAccountType: null,
      bankCode: '226',
      bankDepartment: '99500',
      cards: [],
      defaultAccount: false,
      equivalents: [{
        equivalentAmount: 0,
        equivalentCurrency: 'BYN'
      }, {
        equivalentAmount: 0,
        equivalentCurrency: 'EUR'
      }],
      iban: 'BY79BELB3014104LOX02D0226000',
      ibanReadable: 'BY79 BELB 3014 104L OX02 D022 6000',
      id: 12,
      isOverDraft: false,
      rate: 0
    }, {
      accountAmount: 20,
      accountCurrency: 'EUR',
      accountName: '0707',
      accountNumber: '3014104LOX01R',
      accountPrefix: '3014',
      accountSuffix: '01R',
      accountToAccountType: null,
      bankCode: '226',
      bankDepartment: '12030',
      cards: [],
      defaultAccount: false,
      equivalents: [{
        equivalentAmount: 60.6,
        equivalentCurrency: 'BYN'
      }, {
        equivalentAmount: 21,
        equivalentCurrency: 'USD'
      }],
      iban: 'BY11BELB3014104LOX01R0226000',
      ibanReadable: 'BY11 BELB 3014 104L OX01 R022 6000',
      id: 13,
      isOverDraft: false,
      rate: 0
    }, {
      accountAmount: 7.42,
      accountCurrency: 'EUR',
      accountName: 'евро без комиссии',
      accountNumber: '3014104LOX01J',
      accountPrefix: '3014',
      accountSuffix: '01J',
      accountToAccountType: null,
      bankCode: '226',
      bankDepartment: '12030',
      cards: [],
      defaultAccount: false,
      equivalents: [{
        equivalentAmount: 22.48,
        equivalentCurrency: 'BYN'
      }, {
        equivalentAmount: 7.79,
        equivalentCurrency: 'USD'
      }],
      iban: 'BY34BELB3014104LOX01J0226000',
      ibanReadable: 'BY34 BELB 3014 104L OX01 J022 6000',
      id: 14,
      isOverDraft: false,
      rate: 0
    }, {
      accountAmount: 0,
      accountCurrency: 'EUR',
      accountName: '13',
      accountNumber: '3014104LOX02S',
      accountPrefix: '3014',
      accountSuffix: '02S',
      accountToAccountType: null,
      bankCode: '226',
      bankDepartment: '99500',
      cards: [],
      defaultAccount: false,
      equivalents: [{
        equivalentAmount: 0,
        equivalentCurrency: 'BYN'
      }, {
        equivalentAmount: 0,
        equivalentCurrency: 'USD'
      }],
      iban: 'BY48BELB3014104LOX02S0226000',
      ibanReadable: 'BY48 BELB 3014 104L OX02 S022 6000',
      id: 15,
      isOverDraft: false,
      rate: 0
    }, {
      accountAmount: 297.3,
      accountCurrency: 'RUB',
      accountName: '123',
      accountNumber: '3014104LOX02C',
      accountPrefix: '3014',
      accountSuffix: '02C',
      accountToAccountType: null,
      bankCode: '226',
      bankDepartment: '99500',
      cards: [],
      defaultAccount: false,
      equivalents: [{
        equivalentAmount: 8.03,
        equivalentCurrency: 'BYN'
      }, {
        equivalentAmount: 3.72,
        equivalentCurrency: 'USD'
      }, {
        equivalentAmount: 2.97,
        equivalentCurrency: 'EUR'
      }],
      iban: 'BY94BELB3014104LOX02C0226000',
      ibanReadable: 'BY94 BELB 3014 104L OX02 C022 6000',
      id: 16,
      isOverDraft: false,
      rate: 0
    }, {
      accountAmount: 0,
      accountCurrency: 'RUB',
      accountName: 'рос руб без комиссии',
      accountNumber: '3014104LOX01K',
      accountPrefix: '3014',
      accountSuffix: '01K',
      accountToAccountType: null,
      bankCode: '226',
      bankDepartment: '12030',
      cards: [],
      defaultAccount: false,
      equivalents: [{
        equivalentAmount: 0,
        equivalentCurrency: 'BYN'
      }, {
        equivalentAmount: 0,
        equivalentCurrency: 'USD'
      }, {
        equivalentAmount: 0,
        equivalentCurrency: 'EUR'
      }],
      iban: 'BY19BELB3014104LOX01K0226000',
      ibanReadable: 'BY19 BELB 3014 104L OX01 K022 6000',
      id: 17,
      isOverDraft: false,
      rate: 0
    }, {
      accountAmount: 0,
      accountCurrency: 'RUB',
      accountName: 'по умолч',
      accountNumber: '3014104LOX01L',
      accountPrefix: '3014',
      accountSuffix: '01L',
      accountToAccountType: null,
      bankCode: '226',
      bankDepartment: '12030',
      cards: [],
      defaultAccount: false,
      equivalents: [{
        equivalentAmount: 0,
        equivalentCurrency: 'BYN'
      }, {
        equivalentAmount: 0,
        equivalentCurrency: 'USD'
      }, {
        equivalentAmount: 0,
        equivalentCurrency: 'EUR'
      }],
      iban: 'BY04BELB3014104LOX01L0226000',
      ibanReadable: 'BY04 BELB 3014 104L OX01 L022 6000',
      id: 18,
      isOverDraft: false,
      rate: 0
    }],
    bannerAmount: 100,
    bannerCurrency: 'USD',
    bannerId: 2,
    bannerRate: 2.88,
    bannerRateSuper: 2.869,
    bannerVisible: true,
    maxAmnts: [{
      maxTransferAmnt: null,
      transferCurr: 'BYN'
    }, {
      maxTransferAmnt: null,
      transferCurr: 'USD'
    }, {
      maxTransferAmnt: null,
      transferCurr: 'EUR'
    }, {
      maxTransferAmnt: null,
      transferCurr: 'RUB'
    }],
    minAmnts: [{
      minTransferAmnt: 1,
      transferCurr: 'BYN'
    }, {
      minTransferAmnt: 1,
      transferCurr: 'USD'
    }, {
      minTransferAmnt: 1,
      transferCurr: 'EUR'
    }, {
      minTransferAmnt: 10,
      transferCurr: 'RUB'
    }],
    resultRate: 2.88,
    scale: 1,
    transferAmntCt: 0,
    transferAmntDt: 0,
    transferFee: null
  };
};
export var getAccountToAccountUpdateMock = function getAccountToAccountUpdateMock() {
  return {
    accountCt: 'BY49BELB3014129XG80020226000',
    accountCurrCt: 'USD',
    accountCurrDt: 'BYN',
    accountDt: 'BY64BELB3014129XG80010226000',
    bannerAmount: 99.69,
    bannerCurrency: 'USD',
    bannerId: 2,
    bannerRate: 3.22,
    bannerRateSuper: 3.205,
    bannerVisible: true,
    resultRate: 3.22,
    scale: 1,
    transferAmntCt: 0.31,
    transferAmntDt: 1.0,
    transferFee: 0.0
  };
};
export var getAccountToAccountConfirmationTypeMock = function getAccountToAccountConfirmationTypeMock() {
  return {
    codeOperation: '2543',
    dataOperation: [{
      id: 1,
      opDetails: 'eyJyZWNpcGllbnRzTmFtZSI6ItCk0JjQkNCi0JjQodCi0J7QkiDQktCQ0JvQldCg0JjQmSDQn9CV0KLQoNCe0JLQmNCnIiwicmVzdWx0UmF0ZSI6My4yMiwic2NhbGUiOjEsInRyYW5zZmVyRmVlIjowLjAsImFjY291bnREdCI6IkJZNjRCRUxCMzAxNDEyOVhHODAwMTAyMjYwMDAiLCJhY2NvdW50Q3VyckR0IjoiQllOIiwiYWNjb3VudEN0IjoiQlk0OUJFTEIzMDE0MTI5WEc4MDAyMDIyNjAwMCIsImFjY291bnRDdXJyQ3QiOiJVU0QiLCJ0cmFuc2ZlckFtbnREdCI6MS4wLCJ0cmFuc2ZlckFtbnRDdCI6MC4zMX0='
    }],
    idOtp: null,
    idTransaction: '6c2b34f5-beff-4c8c-b03e-4e18c0087206',
    messageForClient: null,
    otp: false,
    pin: true
  };
};
export var getAccountToAccountPinCorrectnessMock = function getAccountToAccountPinCorrectnessMock() {
  return {
    defaultFavoriteName: 'Между счетами',
    idCheck: 200007391,
    inFavorites: true,
    transactionId: '5a69de71-cbff-4d82-a74a-28fe9b28da1d',
    typeOperation: '2543'
  };
};