import { __read as o, __assign as e } from "../../../_virtual/_tslib.js";
import { jsxs as r, jsx as i } from "react/jsx-runtime";
import { useState as l } from "react";
import { Swiper as t, SwiperSlide as n } from "swiper/react";
import { Box as a } from "@mui/material";
import { Button as c } from "../../button/button.component.js";
import { Icon as d } from "../../icon/icon.component.js";
import { StyledFormControl as u, StyledInputLabel as m, StyledGroupBase as s, StyledRadioLabel as f, StyleFromHelperWrapper as v, StyledFormHelperErrorText as h, StyledFormHelperText as p } from "./radio-buttons-field.styles.js";

var b = function (b) {
  var w = b.name,
      y = b.fullWidth,
      j = b.color,
      F = void 0 === j ? "white" : j,
      x = b.bottomLabel,
      B = b.error,
      k = b.focused,
      z = b.items,
      C = b.onChange,
      g = b.onFocus,
      E = b.onBlur,
      I = b.value,
      L = b.label,
      S = b.spaceBetween,
      W = void 0 === S ? 16 : S,
      $ = b.disableElevation,
      _ = void 0 !== $ && $,
      P = o(l(null), 2),
      T = P[0],
      V = P[1],
      q = w && "".concat(w, "-radio-button-field"),
      A = !!B,
      D = {
    errorId: q && "".concat(q, "-error-text"),
    htmlFor: q,
    id: q
  };

  return r(u, e({
    error: A,
    focused: k,
    fullWidth: y
  }, {
    children: [L && i(m, e({
      $color: F,
      htmlFor: D.htmlFor,
      shrink: !0
    }, {
      children: L
    })), i(a, {
      children: i(s, e({
        "aria-describedby": q,
        autoComplete: "off",
        id: D.id,
        name: w,
        onBlur: function (o) {
          E && E(o);
        },
        onFocus: function (o) {
          g && g(o);
        }
      }, {
        children: i(t, e({
          slidesPerView: "auto",
          spaceBetween: W,
          style: {
            fontSize: 0,
            overflow: "visible"
          },
          onSwiper: V
        }, {
          children: null == z ? void 0 : z.map(function (o, l) {
            return i(n, e({
              style: {
                display: "inline-block",
                width: "auto"
              }
            }, {
              children: i(f, {
                control: r(c, e({
                  color: I === o.value ? "primary" : F,
                  disableElevation: I !== o.value && _,
                  size: o.size || "extraLarge",
                  variant: "contained",
                  view: o.view,
                  onClick: function () {
                    C && (T && T.slideTo(l), C(o.value));
                  }
                }, {
                  children: [o.title, o.icon && i(d, {
                    src: o.icon
                  })]
                })),
                label: "",
                value: o.value
              }, o.value)
            }), o.value);
          })
        }))
      }))
    }), A && i(v, {
      children: i(h, e({
        id: D.errorId
      }, {
        children: B
      }))
    }), x && !A ? i(v, {
      children: i(p, e({
        $color: F
      }, {
        children: x
      }))
    }) : null]
  }));
};

export { b as RadioButtonsWithSliderField };