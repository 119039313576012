import moment from 'moment';
import { CardActiveStatus, CardBlockedStatus, CardPermBlockedStatus } from '@enums';

/**
 * Get Account Index By Id.
 *
 * @author Yury Kuntsou
 * @function getCardIndexByNumber
 * @category helpers
 * @return {string} account index.
 */
var getCardIndexByNumber = function getCardIndexByNumber(number, list, isWithAllCards) {
  if (!number) {
    return undefined;
  }

  var index = list.findIndex(function (card) {
    return card.extraRsTech === number;
  });
  return index !== -1 ? isWithAllCards ? index + 1 : index : undefined;
};
/**
 * Gets the card status.
 *
 * @author Yury Kuntsou
 * @function getCardStatus
 * @category Card Status
 * @returns {Object} The object containing active, tempBlocked and permBlocked card statuses.
 */


var getCardStatus = function getCardStatus() {
  return {
    active: Object.values(CardActiveStatus),
    permBlocked: Object.values(CardPermBlockedStatus),
    tempBlocked: Object.values(CardBlockedStatus)
  };
};
/**
 * Checks if the date on the card is correct.
 *
 * @author Yury Kuntsou
 * @function checkIsCorrectExpiredDateCard
 * @category Utils
 * @param {string} date - The date to be checked format MM/YY.
 * @returns {boolean} Whether the date is correct or not.
 */


var isCorrectExpiredDateCard = function isCorrectExpiredDateCard(date) {
  if (!date) {
    return false;
  }

  var currentDate = moment().startOf('months');
  var targetDate = moment(date, 'MM/YY');
  return targetDate.isSameOrAfter(currentDate);
};
/**
 * Get Card Index By Id.
 *
 * @author Christina Kashevar
 * @function getAccountIndexById
 * @category helpers
 * @return {string} account index.
 */


var getCardIndexById = function getCardIndexById(cards, productCode) {
  if (!productCode) {
    return undefined;
  }

  var index = cards.findIndex(function (card) {
    return card.productCode === productCode;
  });
  return index !== -1 ? index + 1 : undefined;
};

export { getCardIndexById, getCardIndexByNumber, getCardStatus, isCorrectExpiredDateCard };