import { pxToRem } from '@pwa/ui';
import { Box, List, ListItem, styled } from '@mui/material';
var StyledWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    backgroundColor: theme.palette.white.main,
    borderRadius: pxToRem(12),
    padding: theme.spacing(2)
  };
  return styles;
});
var StyledList = styled(List)(function () {
  var styles = {
    padding: '0'
  };
  return styles;
});
var StyledDot = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    flexShrink: 0,
    height: pxToRem(5),
    width: pxToRem(5)
  };
  return styles;
});
var StyledListItem = styled(ListItem)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    padding: theme.spacing(0.375, 1)
  };
  return styles;
});
export { StyledDot, StyledList, StyledListItem, StyledWrapper };