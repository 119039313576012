/**
 * Map deposit history items.
 *
 * @author Christina Kashevar
 * @function mapDepositHistoryItems
 * @category helpers
 * @return {FC} icon component.
 */
var mapDepositHistoryItems = function mapDepositHistoryItems(data) {
  return data.map(function (element) {
    return {
      bal: element.bal,
      curr: element.curr,
      id: element.id,
      operationAmount: element.amount,
      operationCurrency: element.depCurr,
      operationDate: element.date,
      operationDesc: element.desc,
      operationDetails: element.detailsOperation,
      operationRate: element.rate,
      urlIcon: element.urlIcon
    };
  });
};

export { mapDepositHistoryItems };