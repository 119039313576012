import { __assign as o } from "../../_virtual/_tslib.js";
import { jsxs as i, jsx as t } from "react/jsx-runtime";
import { Stack as r, Box as n, Badge as e } from "@mui/material";
import { Icon as c } from "../icon/icon.component.js";
import { IconButton as a } from "../icon-button/icon-button.component.js";
import { Typography as m } from "../typography/typography.component.js";
import p from "../../packages/pwa-ui/assets/images/notification.image.svg.js";

var l = function (l) {
  var s = l.title,
      d = l.count,
      h = l.onClick;
  return i(r, o({
    alignItems: "center",
    direction: "row",
    justifyContent: "space-between",
    spacing: 2
  }, {
    children: [s && t(n, {
      children: i(m, o({
        color: "abandonedSpaceship",
        component: "div",
        fontWeight: 500,
        variant: "body1"
      }, {
        children: [s, !!d && " (".concat(d, ")")]
      }))
    }), t(n, {
      children: t(a, o({
        color: "alpineGoat",
        variant: "contained",
        onClick: h
      }, {
        children: t(e, o({
          color: "error",
          invisible: !d,
          overlap: "circular",
          variant: "dot"
        }, {
          children: t(c, {
            color: "nightWizard",
            height: 20,
            src: p,
            width: 16
          })
        }))
      }))
    })]
  }));
};

export { l as Notifications };