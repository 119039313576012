import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { IconButton } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    backgroundColor: theme.palette.whiteSolid.main,
    paddingTop: theme.spacing(3)
  }, theme.breakpoints.up('desktop'), {
    paddingTop: theme.spacing(4)
  });

  return styles;
});
var StyledIconWrapper = styled(IconButton)(function (_ref2) {
  var theme = _ref2.theme;

  var styles = _defineProperty({
    '& svg': {
      transition: theme.transitions.create(['color'])
    },
    minWidth: 'unset',
    padding: '0'
  }, theme.breakpoints.up('desktop'), {
    backgroundColor: theme.palette.white.main,
    minWidth: theme.typography.pxToRem(40)
  });

  return styles;
});
var StyledLeftContent = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;

  var styles = _defineProperty({}, theme.breakpoints.up('desktop'), {
    flexBasis: theme.typography.pxToRem(452),
    flexGrow: 0,
    flexShrink: 0,
    marginRight: theme.typography.pxToRem(150),
    overflow: 'hidden',
    width: theme.typography.pxToRem(452)
  });

  return styles;
});
export { StyledIconWrapper, StyledLeftContent, StyledWrapper };