import { pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledBox = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    '& .swiper-slide:first-child button': {
      padding: theme.spacing(0.75)
    },
    '& button': {
      minWidth: 'unset'
    },
    '& svg': {
      height: pxToRem(24),
      width: pxToRem(24)
    },
    display: 'inline-block',
    marginBottom: theme.spacing(3),
    maxWidth: '100%'
  };
  return styles;
});
export { StyledBox };