import { __assign as o } from "../../../../../_virtual/_tslib.js";
import { jsx as s } from "react/jsx-runtime";
import { Icon as a } from "../../../../icon/icon.component.js";
import { IconButton as e } from "../../../../icon-button/icon-button.component.js";
import i from "../../../../../packages/pwa-ui/assets/images/icons/eye.image.svg.js";
import t from "../../../../../packages/pwa-ui/assets/images/icons/eye-close.image.svg.js";

var n = function (n) {
  var r = n.type,
      p = n.onChange;
  return s(e, o({
    color: "abandonedSpaceship",
    size: "small",
    sx: {
      mr: .5
    },
    value: r,
    variant: "contained",
    onClick: function (o) {
      o.preventDefault(), o.stopPropagation(), p("password" === r ? "text" : "password");
    }
  }, {
    children: s(a, {
      color: "abandonedSpaceship",
      height: 24,
      src: "password" === r ? t : i,
      width: 24
    })
  }));
};

export { n as PasswordToggleButton };