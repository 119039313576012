import { styled as t, Box as e } from "@mui/material";
import "../../themes/box-shadows/boxShadows.js";
import "../../themes/dark.theme.js";
import { pxToRem as r } from "../../themes/default-theme/default-theme.js";
import "../../themes/gradients/gradients.js";
import "../../themes/light.theme.js";
var o = t(e)(function (t) {
  return t.theme, {
    height: r(40),
    width: r(40)
  };
}),
    i = t(e)(function (t) {
  t.theme;
  return {
    height: "100%",
    position: "relative",
    width: "100%"
  };
}),
    s = t("circle")(function (t) {
  var e = t.theme,
      o = t.$active,
      i = t.$percent,
      s = t.color,
      n = void 0 === s ? "aragonGreen" : s,
      a = {
    fill: e.colors.white,
    stroke: "transparent",
    strokeDasharray: o ? "".concat(.88 * i, " 88") : "0 100",
    strokeLinecap: "round",
    strokeWidth: 0,
    transition: "stroke-dasharray 0.5s ease"
  };
  return o && (a.stroke = e.colors[n], a.strokeWidth = r(4)), a;
}),
    n = t("svg")(function (t) {
  t.theme;
  return {
    transform: "rotate(-90deg)",
    transformOrigin: "50%"
  };
}),
    a = t(e)(function (t) {
  return {
    alignItems: "center",
    color: t.theme.colors.white,
    display: "flex",
    height: "100%",
    justifyContent: "center",
    left: 0,
    position: "absolute",
    top: 0,
    width: "100%"
  };
});
export { o as ContainerBox, s as DonutCircle, a as PercentContainer, n as StyledSvg, i as SvgContainer };