import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { IconButton } from '@pwa/ui';
import { AppBar, Box, Button, styled } from '@mui/material';
var StyledAppBar = styled(AppBar)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    height: theme.HEADER_HEIGHT
  };
  return styles;
});
var StyledContentWrapper = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    paddingBottom: theme.spacing(2.25),
    paddingTop: theme.spacing(2.25),
    position: 'relative',
    textAlign: 'center'
  };
  return styles;
});
var StyledArrowWrapper = styled(Button)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    '& svg': {
      transition: theme.transitions.create(['color'])
    },
    minWidth: 'unset',
    padding: '0'
  };
  return styles;
});
var StyledDesktopTitleWrapper = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    maxWidth: theme.typography.pxToRem(452),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    position: 'relative'
  };
  return styles;
});
var StyledIconButton = styled(IconButton)(function (_ref5) {
  var theme = _ref5.theme;

  var styles = _defineProperty({
    '& svg': {
      transition: theme.transitions.create(['color'])
    },
    // height:                            '100%',
    minWidth: 'unset',
    padding: '0'
  }, theme.breakpoints.up('desktop'), {
    backgroundColor: theme.palette.white.main,
    minWidth: theme.typography.pxToRem(40)
  });

  return styles;
});
var StyledAdaptiveCloseContainer = styled(Box)(function (_ref6) {
  var theme = _ref6.theme;
  var styles = {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    position: 'relative'
  };
  return styles;
});
var StyledAdaptiveCloseButtonWrapper = styled(Box)(function (_ref7) {
  var theme = _ref7.theme;
  var styles = {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative'
  };
  return styles;
});
export { StyledAdaptiveCloseButtonWrapper, StyledAdaptiveCloseContainer, StyledAppBar, StyledArrowWrapper, StyledContentWrapper, StyledDesktopTitleWrapper, StyledIconButton };