import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
import icon from '@images/icons/location.image.png';
var StyledWrapper = styled(Box)(function (_ref) {
  var _theme$palette, _theme$palette2, _theme$palette3, _theme$palette4, _theme$boxShadows;

  var theme = _ref.theme;

  var styles = _defineProperty({
    '& ymaps[class*="_hidden-text"]': {
      borderRadius: pxToRem(10),
      boxShadow: 'none',
      height: pxToRem(44),
      width: pxToRem(44)
    },
    '& ymaps[class*="_icon_geolocation"]': {
      backgroundImage: "url(".concat(icon, ")")
    },
    '& ymaps[class*="button-icon"]': {
      height: pxToRem(32),
      width: pxToRem(32)
    },
    '& ymaps[class*="controls__bottom"]': {
      display: 'none'
    },
    '& ymaps[class*="controls__control"] ymaps[class*="-zoom"]': {
      display: 'none'
    },
    '& ymaps[class*="controls__control_toolbar"]': {
      display: 'none'
    }
  }, theme.breakpoints.up('desktop'), {
    '& .balloon-desktop-flex': {
      '&:hover': {
        cursor: 'pointer'
      },
      alignItems: 'center',
      display: 'flex',
      flexGap: pxToRem(16),
      justifyContent: 'space-between'
    },
    '& .balloon-desktop-img': {
      height: pxToRem(32),
      width: pxToRem(32)
    },
    '& .balloon-desktop-label-secondary': {
      color: theme === null || theme === void 0 ? void 0 : (_theme$palette = theme.palette) === null || _theme$palette === void 0 ? void 0 : _theme$palette.white.main,
      fontSize: pxToRem(10),
      lineHeight: pxToRem(12),
      margin: 0
    },
    '& .balloon-desktop-label-secondary-bold': {
      color: theme === null || theme === void 0 ? void 0 : (_theme$palette2 = theme.palette) === null || _theme$palette2 === void 0 ? void 0 : _theme$palette2.nightWizard.main,
      fontWeight: 500
    },
    '& .balloon-desktop-text-main': {
      color: theme === null || theme === void 0 ? void 0 : (_theme$palette3 = theme.palette) === null || _theme$palette3 === void 0 ? void 0 : _theme$palette3.nightWizard.main,
      fontSize: pxToRem(16),
      fontWeight: 500,
      lineHeight: pxToRem(18),
      margin: 0
    },
    '& .balloon-desktop-text-secondary': {
      color: theme === null || theme === void 0 ? void 0 : (_theme$palette4 = theme.palette) === null || _theme$palette4 === void 0 ? void 0 : _theme$palette4.abandonedSpaceship.main,
      fontSize: pxToRem(13),
      lineHeight: pxToRem(16),
      margin: 0
    },
    '& .balloon-desktop-wrapper': {
      borderRadius: pxToRem(16)
    },
    '& ymaps[class*="balloon__close-button"]': {
      marginRight: theme.spacing(1.25)
    },
    '& ymaps[class*="balloon__content"]': {
      '& >ymaps': {
        height: "unset !important"
      },
      margin: 0,
      padding: 0
    },
    '& ymaps[class*="balloon__tail"]': {
      display: 'none'
    },
    '& ymaps[class*="balloon_layout_normal"]': {
      '& ymaps[class*="balloon__layout"]': {
        borderRadius: pxToRem(16)
      },
      borderRadius: pxToRem(16),
      boxShadow: theme === null || theme === void 0 ? void 0 : (_theme$boxShadows = theme.boxShadows) === null || _theme$boxShadows === void 0 ? void 0 : _theme$boxShadows.independence,
      left: "".concat(pxToRem(-200), " !important"),
      top: "".concat(pxToRem(10), " !important")
    },
    '& ymaps[class*="cluster-content__body"]': {
      margin: 0
    },
    '& ymaps[class*="cluster-tabs"]': {
      height: "unset !important",
      padding: 0
    },
    '& ymaps[class*="cluster-tabs__item-body"]': {
      padding: 0
    },
    '& ymaps[class*="cluster-tabs__item-header"]': {
      display: 'none'
    },
    '& ymaps[class*="cluster-tabs__menu-item"]': {
      padding: 0
    },
    '& ymaps[class*="cluster-tabs__menu-item-text"]': {
      padding: theme.spacing(1.5)
    },
    '& ymaps[class*="cluster-tabs__section_type_content"]': {
      borderLeft: "".concat(pxToRem(1), " solid ").concat(theme.colors.lavenderSyrup),
      height: "unset !important",
      padding: 0
    },
    '& ymaps[class*="cluster-tabs__section_type_nav"]': {
      borderRight: 'none'
    },
    marginTop: theme.spacing(0)
  });

  return styles;
});
export { StyledWrapper };