import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getDrawerOpenStateSelector } from '@store/drawers';
import { DrawersService } from '@services';

/**
 * Custom hook to manage a drawer.
 *
 * @author Yury Kuntsou
 * @function useDrawer
 * @category hook
 * @param {string} drawerId - The unique identifier of the drawer.
 * @returns {useDrawerReturnType} - An object containing functions to interact with the drawer.
 */
export function useDrawer(_ref) {
  var drawerId = _ref.id,
      _ref$keepMounted = _ref.keepMounted,
      keepMounted = _ref$keepMounted === void 0 ? false : _ref$keepMounted;

  if (!drawerId) {
    throw new Error('Drawer id is required');
  }

  var isOpen = useSelector(getDrawerOpenStateSelector(drawerId));
  /**
   * Opens the drawer with optional data.
   *
   * @author Yury Kuntsou
   * @param {object} data - The data to pass to the drawer.
   * @returns {Promise} - A promise that resolves with the result of opening the drawer.
   */

  var openDrawer = useCallback(function (data) {
    return DrawersService.open(drawerId, data);
  }, [drawerId]);
  /**
   * Rejects the current drawer.
   *
   * @author Yury Kuntsou
   * @function rejectDrawer
   * @category Drawer
   * @param {object} data - The data to pass to the drawer.
   * @returns {void}
   */

  var rejectDrawer = useCallback(function (data) {
    DrawersService.closeWithAnswer(drawerId, {
      data: data,
      rejected: true
    });
  }, [drawerId]);
  /**
   * Accepts the current drawer.
   *
   * @author Yury Kuntsou
   * @function acceptDrawer
   * @category Drawer
   * @param {object} data - The data to pass to the drawer.
   * @returns {void}
   */

  var acceptDrawer = useCallback(function (data) {
    DrawersService.closeWithAnswer(drawerId, {
      accepted: true,
      data: data
    });
  }, [drawerId]);
  /**
   * Closes the current drawer.
   *
   * @author Yury Kuntsou
   * @function handleClose
   * @category Drawer
   * @returns {void}
   */

  var closeDrawer = useCallback(function () {
    DrawersService.close(drawerId);
  }, [drawerId]);
  useEffect(function () {
    if (!keepMounted && isOpen) return closeDrawer;
  }, [closeDrawer, isOpen, keepMounted]);
  return {
    acceptDrawer: acceptDrawer,
    closeDrawer: closeDrawer,
    openDrawer: openDrawer,
    rejectDrawer: rejectDrawer
  };
}