import { i18n } from '@localization';
import icon from '@images/icons/filter-history.image.svg';
import { FilterOptions } from './common-credit-deposit.helpers';
export var filterOptions = [{
  icon: icon,
  size: 'small',
  value: FilterOptions.Details
}, {
  size: 'small',
  title: i18n.t('pages.depositHistory.all'),
  value: FilterOptions.All
}, {
  size: 'small',
  title: i18n.t('pages.depositHistory.month'),
  value: FilterOptions.Month
}, {
  size: 'small',
  title: i18n.t('pages.depositHistory.threeMonth'),
  value: FilterOptions.ThreeMonth
}];