import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Button, Icon, pxToRem, Typography } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    paddingTop: theme.spacing(2)
  }, theme.breakpoints.up('desktop'), {
    maxWidth: theme.typography.pxToRem(452)
  });

  return styles;
});
var StyledIconWrapper = styled(Button)(function (_ref2) {
  var theme = _ref2.theme;

  var styles = _defineProperty({
    boxShadow: 'none',
    color: theme.colors.panPurple,
    height: 'unset',
    marginLeft: theme.spacing(-5.5),
    minWidth: pxToRem(65),
    padding: '0'
  }, theme.breakpoints.up('desktop'), {
    marginLeft: 0,
    marginTop: 0
  });

  return styles;
});
var StyledIcon = styled(Icon)(function () {
  var styles = {
    marginRight: pxToRem(8),
    transform: 'rotate(45deg)'
  };
  return styles;
});
var StyledButtonWrapper = styled(Box)(function (_ref3) {
  var theme = _ref3.theme,
      isHidden = _ref3.isHidden;
  var styles = {
    backgroundColor: theme.palette.whiteSolid.main,
    display: isHidden ? 'none' : 'flex',
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(2.5)
  };
  return styles;
});
var StyledContentWrapper = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;

  var styles = _defineProperty({
    flexGrow: 1,
    flexShrink: 1,
    overflowY: 'auto'
  }, theme.breakpoints.up('desktop'), {
    marginTop: theme.spacing(2)
  });

  return styles;
});
var StyledSubtitleWrapper = styled(Box)(function (_ref5) {
  var theme = _ref5.theme;

  var styles = _defineProperty({
    display: 'block',
    marginBottom: theme.spacing(3),
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: pxToRem(300)
  }, theme.breakpoints.up('desktop'), {
    maxWidth: 'unset'
  });

  return styles;
});
var StyledSubtitle = styled(Typography)(function (_ref6) {
  var theme = _ref6.theme;

  var styles = _defineProperty({
    display: 'block',
    textAlign: 'center'
  }, theme.breakpoints.up('desktop'), {
    textAlign: 'left'
  });

  return styles;
});
export { StyledButtonWrapper, StyledContentWrapper, StyledIcon, StyledIconWrapper, StyledSubtitle, StyledSubtitleWrapper, StyledWrapper };