/**
 * Helps to get 4 last digits of a card.
 *
 * @author Ihar Kazlouski
 * @function getLastFourCardDigits
 * @category helpers
 * @param {string} cardNumber number of a card.
 * @return {string} return last 4 digits.
 */
var getLastFourCardDigits = function getLastFourCardDigits() {
  var cardNumber = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return cardNumber.substring(cardNumber.length - 4);
};

export { getLastFourCardDigits };