import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { pxToRem } from '@pwa/ui';
import { Box, Container, Stack, styled } from '@mui/material';
var StyledContainer = styled(Container)(function () {
  var styles = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    overflowX: 'hidden'
  };
  return styles;
});
var StyledWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(2)
  }, theme.breakpoints.up('desktop'), {
    display: 'block',
    maxWidth: theme.typography.pxToRem(452)
  });

  return styles;
});
var StyledInfoBlock = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    backgroundColor: theme.palette.lavenderSyrup.main,
    borderRadius: pxToRem(16),
    marginTop: theme.spacing(5.25),
    padding: theme.spacing(1.75, 2)
  };
  return styles;
});
var StyledBox = styled(Stack)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    '&:first-child span': {
      fontWeight: '500'
    },
    alignItems: 'center',
    justifyContent: 'space-between'
  };
  return styles;
});
export { StyledBox, StyledContainer, StyledInfoBlock, StyledWrapper };