import { LinkType, TemplateWatchedStatus, WidgetStatus } from '@components/view-story/view-story.types';
export var getStoriesListMock = function getStoriesListMock() {
  return {
    stories: [{
      id: 1,
      interactionId: '6696679256203',
      isHighlighted: true,
      status: WidgetStatus.VIEWED,
      templates: [{
        buttons: [{
          action: LinkType.URL,
          link: 'https://www.belveb.by',
          name: 'Оформить'
        }],
        header: '<p style="text-align: center;">Дивный money-back!</p>',
        id: 1,
        image: 'https://www.belveb.by/upload/webp/79/79708a2d67ec29b6b5906d9682623fa9.webp',
        message: '<p>Если очень хочется, то можно! Особенно если это выгодно!</p>\n\n<p>Оформи карту &quot;Дива&quot; и получи повышенный money-back в размере 5% на покупки в категории &laquo;Фастфуд&raquo;.</p>\n\n<p>&nbsp;</p>\n\n<p>гости к Рональду Макдональду на вкусные бургеры или отведать курочки в Texas Chicken,&nbsp;а также в десятки других заведений теперь можно зайти с дополнительной выгодой!</p>',
        templateId: 'Key_1',
        templateStatus: TemplateWatchedStatus.NOT_READ
      }],
      widgetHeader: '<p style="text-align: center;">Дивный money-back!</p>',
      widgetImage: 'https://www.belveb.by/upload/webp/a5/a5ce451ddd1ee49997917bfe784e510b.webp'
    }, {
      id: 2,
      interactionId: '-4732556526929850864',
      isHighlighted: false,
      status: WidgetStatus.ACCEPTED,
      templates: [{
        buttons: [{
          action: LinkType.DEEP_LINK,
          link: '/payments/erip',
          name: 'Erip'
        }, {
          action: LinkType.CLOSE,
          link: null,
          name: 'Close'
        }],
        header: '<p>Story 1</p>',
        id: 1,
        image: 'https://www.belveb.by/upload/webp/a5/a5ce451ddd1ee49997917bfe784e510b.webp',
        message: '<p>Test Page 1 Bady</p>',
        templateId: '1',
        templateStatus: TemplateWatchedStatus.NOT_READ
      }, {
        buttons: null,
        header: '<p>Story 2</p>',
        id: 2,
        image: 'https://www.belveb.by/upload/webp/79/79708a2d67ec29b6b5906d9682623fa9.webp',
        message: '<p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Accusamus debitis reprehenderit illum, aliquam enim iure, quos ratione laudantium minus dolores temporibus non tenetur minima harum? Nobis ullam incidunt doloribus.</p>',
        templateId: '2',
        templateStatus: TemplateWatchedStatus.NOT_READ
      }, {
        buttons: [{
          action: LinkType.CLOSE,
          link: null,
          name: 'Закрыть'
        }],
        header: 'Story 3</p>',
        id: 3,
        image: 'https://www.belveb.by/upload/webp/16/16f29ae4c014dc3e0d7b813b0a35b82a.webp',
        message: '<p>Test Page 2 Bady</p>',
        templateId: '3',
        templateStatus: TemplateWatchedStatus.NOT_READ
      }, {
        buttons: [{
          action: LinkType.URL,
          link: 'https://www.belveb.by',
          name: 'Bank'
        }, {
          action: LinkType.DEEP_LINK,
          link: '/more',
          name: 'More'
        }, {
          action: LinkType.CLOSE,
          link: null,
          name: 'Закрыть'
        }],
        header: 'Story 4',
        id: 4,
        image: 'https://www.belveb.by/upload/webp/16/16f29ae4c014dc3e0d7b813b0a35b82a.webp',
        message: '<p>Test Page 2 Bady</p>',
        templateId: '4',
        templateStatus: TemplateWatchedStatus.NOT_READ
      }],
      widgetHeader: '<p>Test header</p>',
      widgetImage: 'https://www.belveb.by/upload/webp/79/79708a2d67ec29b6b5906d9682623fa9.webp'
    }]
  };
};