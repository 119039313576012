export var Field; // TODO remove

(function (Field) {
  Field["Date"] = "Date";
  Field["List"] = "List";
  Field["Text"] = "Text";
})(Field || (Field = {}));

export var MinAmount;

(function (MinAmount) {
  MinAmount[MinAmount["Number"] = 0.5] = "Number";
  MinAmount["String"] = "0,50";
})(MinAmount || (MinAmount = {}));