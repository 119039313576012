import { jsx as r } from "react/jsx-runtime";
import { Icon as o } from "../icon/icon.component.js";
import { StyledSearchIcon as e } from "./autocomplete-drawer.styles.js";
import t from "../../packages/pwa-ui/assets/images/search.image.svg.js";

var s = function () {
  return r(e, {
    children: r(o, {
      color: "secondary",
      height: 18,
      src: t,
      width: 18
    })
  });
};

export { s as SearchIcon };