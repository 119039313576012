import { IconButton } from '@pwa/ui';
import { alpha, Box, Dialog, styled } from '@mui/material';
import { pxToRem } from '@themes';
var StyledDialog = styled(Dialog)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    overflow: 'hidden'
  };
  return styles;
});
var ClosePopupButton = styled(IconButton)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    background: alpha(theme.colors.white, 0.15),
    height: pxToRem(24),
    position: 'fixed',
    right: pxToRem(29),
    top: pxToRem(31),
    width: pxToRem(24)
  };
  return styles;
});
var NavButton = styled(IconButton)(function (_ref3) {
  var theme = _ref3.theme,
      $right = _ref3.$right,
      $left = _ref3.$left;
  var styles = {
    background: alpha(theme.colors.white, 0.2),
    borderRadius: '50%',
    height: pxToRem(40),
    left: $left ? pxToRem(-60) : 'auto',
    position: 'absolute',
    right: $right ? pxToRem(-60) : 'auto',
    top: '45%',
    transform: $left ? 'rotate(180deg)' : 'rotate(0deg)',
    width: pxToRem(40),
    zIndex: 1000
  };
  return styles;
});
var MobileButton = styled(Box)(function (_ref4) {
  var theme = _ref4.theme,
      $right = _ref4.$right,
      $left = _ref4.$left;
  var styles = {
    bottom: 0,
    left: $left ? 0 : undefined,
    position: 'absolute',
    right: $right ? 0 : undefined,
    top: 0,
    width: '50%',
    zIndex: 1
  };
  return styles;
});
export { ClosePopupButton, MobileButton, NavButton, StyledDialog };