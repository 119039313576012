import * as yup from 'yup';
import { Countries } from '@enums';
import { i18n } from '@localization';

var getPhoneSchema = function getPhoneSchema(country, numberLength) {
  return yup.object().shape({
    phone: yup.string().matches(country === Countries.BELARUS ? /^(25|29|33|44)[0-9]{3}[0-9]{2}[0-9]{2}$/ : new RegExp("^[0-9]{".concat(numberLength, "}$")), i18n.t('forms.validation.mobileNumber')).required(i18n.t('forms.validation.required'))
  });
};

export { getPhoneSchema };