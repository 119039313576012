import r from "../../node_modules/@babel/runtime/helpers/typeof.js";
import { __values as e } from "../../_virtual/_tslib.js";

var t = function t(n, l) {
  var o, a, i, f;

  if ("object" === r(n) && null !== n && null !== l && "object" === r(l)) {
    var u = Object.keys(n);

    try {
      for (var c = e(u), y = c.next(); !y.done; y = c.next()) {
        if (!((d = y.value) in l)) return !1;
        if (!t(n[d], l[d])) return !1;
      }
    } catch (r) {
      o = {
        error: r
      };
    } finally {
      try {
        y && !y.done && (a = c.return) && a.call(c);
      } finally {
        if (o) throw o.error;
      }
    }

    var v = Object.keys(l);

    try {
      for (var b = e(v), s = b.next(); !s.done; s = b.next()) {
        var d;
        if (!((d = s.value) in n)) return !1;
      }
    } catch (r) {
      i = {
        error: r
      };
    } finally {
      try {
        s && !s.done && (f = b.return) && f.call(b);
      } finally {
        if (i) throw i.error;
      }
    }

    return !0;
  }

  return n === l;
};

export { t as deepEqual };