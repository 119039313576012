export var getCurrencyNBRBListMock = function getCurrencyNBRBListMock() {
  return {
    RatesNb: [{
      flagNb: 'https://www.nbrb.by/i/flags/flags/4x3/us.svg',
      id: 1,
      rateNb: 2.8345,
      reqCurrNb: 'USD',
      scaleNb: 1
    }, {
      flagNb: 'https://www.nbrb.by/i/flags/flags/4x3/eu.svg',
      id: 2,
      rateNb: 3.0207,
      reqCurrNb: 'EUR',
      scaleNb: 1
    }, {
      flagNb: 'https://www.nbrb.by/i/flags/flags/4x3/ru.svg',
      id: 3,
      rateNb: 3.7632,
      reqCurrNb: 'RUB',
      scaleNb: 100
    }],
    reqDate: '2023-03-09T11:00+03:00'
  };
};
export var getCurrencyListMock = function getCurrencyListMock() {
  return {
    exchangeRates: [{
      buyRate: 3.03,
      id: 1,
      imageURL: 'https://www.nbrb.by/i/flags/flags/4x3/eu.svg',
      isSuperRate: true,
      reqCurr: 'BYN',
      scale: 1,
      sellRate: 3.095,
      srcCurr: 'EUR',
      superBuyRate: 3.051,
      superSellRate: 3.085
    }, {
      buyRate: 2.84,
      id: 2,
      imageURL: 'https://www.nbrb.by/i/flags/flags/4x3/us.svg',
      isSuperRate: true,
      reqCurr: 'BYN',
      scale: 1,
      sellRate: 2.88,
      srcCurr: 'USD',
      superBuyRate: 2.851,
      superSellRate: 2.869
    }, {
      buyRate: 1.05,
      id: 3,
      imageURL: 'https://www.nbrb.by/i/flags/flags/4x3/eu.svg',
      isSuperRate: false,
      reqCurr: 'USD',
      scale: 1,
      sellRate: 1.08,
      srcCurr: 'EUR',
      superBuyRate: null,
      superSellRate: null
    }, {
      buyRate: 74,
      id: 4,
      imageURL: 'https://www.nbrb.by/i/flags/flags/4x3/us.svg',
      isSuperRate: false,
      reqCurr: 'RUB',
      scale: 1,
      sellRate: 80,
      srcCurr: 'USD',
      superBuyRate: null,
      superSellRate: null
    }],
    startDt: '2023-03-09T11:00+03:00'
  };
};
export var getConverterAmount = function getConverterAmount() {
  return {
    bannerAmount: null,
    bannerCurrency: null,
    bannerId: 3,
    bannerRate: 2.565,
    bannerRateSuper: 2.564,
    bannerVisible: true,
    buyCurr: 'USD',
    maxAmntCurr: 'USD',
    originalBuyAmnt: 100.0,
    rangesMaxAmnt: 10000000,
    reqCurrFlags: [{
      reqCurr: 'BYN',
      reqCurrFlag: 'https://www.nbrb.by/i/flags/flags/4x3/by.svg'
    }, {
      reqCurr: 'USD',
      reqCurrFlag: 'https://www.nbrb.by/i/flags/flags/4x3/us.svg'
    }, {
      reqCurr: 'EUR',
      reqCurrFlag: 'https://www.nbrb.by/i/flags/flags/4x3/eu.svg'
    } // { reqCurr: 'RUB', reqCurrFlag: 'http://bveb.by/remote/flags/RUB.svg' },
    // { reqCurr: 'CNY', reqCurrFlag: 'http://bveb.by/remote/flags/CNY.svg' },
    ],
    resultBuyAmnt: null,
    resultRate: 2.565,
    resultSellAmnt: 256.5,
    scale: 1,
    sellCurr: 'BYN',
    srcCurrFlags: [{
      scrCurrFlag: 'https://www.nbrb.by/i/flags/flags/4x3/by.svg',
      srcCurr: 'BYN'
    }, {
      scrCurrFlag: 'https://www.nbrb.by/i/flags/flags/4x3/us.svg',
      srcCurr: 'USD'
    }, {
      scrCurrFlag: 'https://www.nbrb.by/i/flags/flags/4x3/eu.svg',
      srcCurr: 'EUR'
    } // { srcCurr: 'RUB', scrCurrFlag: 'http://bveb.by/remote/flags/RUB.svg' },
    // { srcCurr: 'CNY', scrCurrFlag: 'http://bveb.by/remote/flags/CNY.svg' },
    ]
  };
};
export var getConverterAmountUpdateMock = function getConverterAmountUpdateMock() {
  return {
    bannerAmount: null,
    bannerCurrency: null,
    bannerId: 3,
    bannerRate: 2.565,
    bannerRateSuper: 2.564,
    bannerVisible: true,
    maxAmntCurr: 'USD',
    rangesMaxAmnt: 100000.0,
    resultBuyAmnt: 38.99,
    resultRate: 2.565,
    resultSellAmnt: null,
    scale: 1
  };
};
export var getOpenAccountCurrencies = function getOpenAccountCurrencies() {
  return {
    listCurr: [{
      dftCurr: false,
      nameCurr: 'EUR'
    }, {
      dftCurr: true,
      nameCurr: 'BYN'
    }, {
      dftCurr: false,
      nameCurr: 'USD'
    }, {
      dftCurr: false,
      nameCurr: 'RUB'
    }]
  };
};