import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import * as yup from 'yup';
import { CreditCardType, CreditFormFieldInputType, CreditFormFieldType, MAX_NAME_FIELD_LENGTH } from '@enums';
import { i18n } from '@localization';

var getCreditApplicationFirstStepSchema = function getCreditApplicationFirstStepSchema(data) {
  var validationObject = {};
  data.forEach(function (field) {
    if (field.type === CreditFormFieldType.Checkbox) {
      if (field.isRequired) {
        validationObject[field.textId] = yup.bool().equals([true]);
      }

      return;
    }

    var message = field.inputType === CreditFormFieldInputType.String ? i18n.t('forms.validation.required') : i18n.t('forms.validation.enterAmount');

    if (field.isRequired) {
      validationObject[field.textId] = yup.string().trim().required(message);
    }
  });
  var validation = yup.object().shape(_objectSpread({}, validationObject));
  return validation;
};

var getCreditApplicationSecondStepSchema = function getCreditApplicationSecondStepSchema() {
  return yup.object().shape({
    cardholderName: yup.string().when('type', {
      is: CreditCardType.Personalized,
      then: yup.string().required(i18n.t('forms.validation.required')).nullable()
    }).nullable(),
    cardholderSurname: yup.string().when('type', {
      is: CreditCardType.Personalized,
      then: yup.string().required(i18n.t('forms.validation.required')).nullable()
    }).test({
      message: i18n.t('pages.cardIssue.nameValidation', {
        count: MAX_NAME_FIELD_LENGTH
      }),
      name: 'cardholderSurname',
      test: function test(value, ctx) {
        var _fields$cardholderNam;

        if (!value) {
          return true;
        }

        var fields = ctx.parent;

        if (value.length + ((_fields$cardholderNam = fields.cardholderName) === null || _fields$cardholderNam === void 0 ? void 0 : _fields$cardholderNam.length) > MAX_NAME_FIELD_LENGTH) {
          return false;
        }

        return true;
      }
    }).nullable(),
    checkIsCorrectData: yup.bool().when('type', {
      is: CreditCardType.Personalized,
      then: yup.bool().equals([true])
    }).nullable(),
    codeWord: yup.string().trim().when('type', {
      is: CreditCardType.Personalized,
      then: yup.string().required(i18n.t('forms.validation.required')).min(4, i18n.t('forms.validation.minLengthCode')).nullable()
    }).nullable(),
    productCode: yup.string().when('type', {
      is: CreditCardType.Personalized,
      then: yup.string().required(i18n.t('forms.validation.required'))
    }).nullable()
  });
};

var getCreditApplicationThirdStepSchema = function getCreditApplicationThirdStepSchema() {
  return yup.object().shape({
    district: yup.object().test({
      message: i18n.t('forms.validation.required'),
      name: 'district',
      test: function test(value, ctx) {
        var fields = ctx.parent;

        if (!fields.regionCenter && !value.value) {
          return false;
        }

        return true;
      }
    }),
    houseNumber: yup.string().trim().required(i18n.t('forms.validation.required')),
    locality: yup.object().test({
      message: i18n.t('forms.validation.required'),
      name: 'locality',
      test: function test(value, ctx) {
        var fields = ctx.parent;
        var regionCenter = fields.regionCenter,
            districtCenter = fields.districtCenter;

        if (!regionCenter && !districtCenter && !value.value) {
          return false;
        }

        return true;
      }
    }),
    localityType: yup.string().trim().required(i18n.t('forms.validation.required')),
    postCode: yup.string().trim().required(i18n.t('forms.validation.required')).length(6, i18n.t('forms.validation.postCode')),
    region: yup.object().test({
      message: i18n.t('forms.validation.required'),
      name: 'region',
      test: function test(value) {
        if (!value.value) {
          return false;
        }

        return true;
      }
    }),
    soato: yup.string().trim().required(i18n.t('forms.validation.required')),
    streetName: yup.string().trim().required(i18n.t('forms.validation.required')),
    streetType: yup.object().test({
      message: i18n.t('forms.validation.required'),
      name: 'streetType',
      test: function test(value) {
        if (!value.value) {
          return false;
        }

        return true;
      }
    })
  });
};

var getCreditApplicationFourthStepSchema = function getCreditApplicationFourthStepSchema() {
  return yup.object();
};

var getCreditApplicationFifthStepSchema = function getCreditApplicationFifthStepSchema() {
  return yup.object().shape({
    checkDocuments: yup.bool().equals([true])
  });
};

export { getCreditApplicationFifthStepSchema, getCreditApplicationFirstStepSchema, getCreditApplicationFourthStepSchema, getCreditApplicationSecondStepSchema, getCreditApplicationThirdStepSchema };