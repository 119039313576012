import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var height = theme.convertToDVH(100);

  var styles = _defineProperty({
    height: "calc(".concat(height, " - ").concat(pxToRem(460), ")"),
    marginTop: theme.spacing(2),
    minHeight: pxToRem(300)
  }, theme.breakpoints.up('desktop'), {
    height: "clamp(".concat(pxToRem(300), ", calc(100dvh - ").concat(pxToRem(448), "), ").concat(pxToRem(497), ")")
  });

  return styles;
});
export { StyledWrapper };