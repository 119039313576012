import { FavoriteOperationType } from '@enums';
export var getFavoritesList = function getFavoritesList() {
  return {
    data: [{
      id: 1,
      paymentFor: '291112233',
      paymentName: 'МТС по № телефона',
      personalAccountName: 'Номер телефона',
      serviceId: 393931,
      shortcutName: 'Мой МТС',
      shortcutUrl: 'http://bveb.by/remote/operationsPicture/table1/MTC.svg',
      typeOperation: '2300'
    }, {
      id: 2,
      paymentFor: '445556677',
      paymentName: 'А1 по № телефона',
      personalAccountName: 'Номер телефона',
      serviceId: 381861,
      shortcutName: 'Мой a1',
      shortcutUrl: 'http://bveb.by/remote/operationsPicture/table1/A1.svg',
      typeOperation: '2300'
    }, {
      id: 3,
      paymentFor: '291112233',
      paymentName: 'МТС по № телефона',
      personalAccountName: 'Номер телефона',
      serviceId: 393931,
      shortcutName: 'Имеется спорная точка зрения, гласящая примерно следующее: сторонники тоталитаризма в науке, которые представляют собой яркий пример континентально-европейского типа политической культуры, будут ассоциативно распределены по отраслям.',
      shortcutUrl: 'http://bveb.by/remote/operationsPicture/table1/MTC.svg',
      typeOperation: '2300'
    }, {
      id: 4,
      paymentFor: '445556677',
      paymentName: 'А1 по № телефона',
      personalAccountName: 'Номер телефона',
      serviceId: 381861,
      shortcutName: 'Мой a1 2',
      shortcutUrl: 'http://bveb.by/remote/operationsPicture/table1/A1.svg',
      typeOperation: '2300'
    }, {
      id: 5,
      paymentFor: '291112233',
      paymentName: 'МТС по № телефона',
      personalAccountName: 'Номер телефона',
      serviceId: 393931,
      shortcutName: 'Мой МТС 3',
      shortcutUrl: 'http://bveb.by/remote/operationsPicture/table1/MTC.svg',
      typeOperation: '2300'
    }, {
      id: 6,
      paymentFor: '445556677',
      paymentName: 'А1 по № телефона',
      personalAccountName: 'Номер телефона',
      serviceId: 381861,
      shortcutName: 'Мой a1 4',
      shortcutUrl: 'http://bveb.by/remote/operationsPicture/table1/A1.svg',
      typeOperation: '2300'
    }],
    filters: [],
    pagination: [{
      pageOffset: 0,
      pageSize: 100,
      totalObject: 6
    }]
  };
};
export var getFavoritesListNew = function getFavoritesListNew() {
  return {
    elements: [{
      childElements: [{
        childElements: null,
        iconUrl: '/remote/operationsPicture/table1/MTC.svg',
        id: 439,
        label: 'МТС экспресс оплата 3',
        paymentData: {
          classType: FavoriteOperationType.ERIP,
          paymentFor: '295444444',
          paymentFrom: null,
          paymentType: FavoriteOperationType.ERIP,
          personalAccountName: 'Номер телефона',
          providerName: null,
          serviceId: 393931
        },
        position: 1,
        type: 'PAYMENT'
      }, {
        childElements: null,
        iconUrl: '/remote/operationsPicture/table1/default.svg',
        id: 429,
        label: 'QA_123456789',
        paymentData: {
          classType: FavoriteOperationType.ERIP,
          paymentFor: '123456789',
          paymentFrom: null,
          paymentType: FavoriteOperationType.ERIP,
          personalAccountName: 'LS',
          providerName: null,
          serviceId: 2644671
        },
        position: 2,
        type: 'PAYMENT'
      }, {
        childElements: null,
        iconUrl: '/remote/operationsPicture/table1/default.svg',
        id: 427,
        label: 'МТС по 295774455',
        paymentData: {
          classType: FavoriteOperationType.ERIP,
          paymentFor: '295774455',
          paymentFrom: null,
          paymentType: FavoriteOperationType.ERIP,
          personalAccountName: 'Номер телефона',
          providerName: null,
          serviceId: 571
        },
        position: 3,
        type: 'PAYMENT'
      }],
      iconUrl: '/remote/operationsPicture/folder.svg',
      id: 42,
      label: 'Группа платежей',
      paymentData: null,
      position: 1,
      type: 'FOLDER'
    }, {
      childElements: [{
        childElements: null,
        iconUrl: '/remote/operationsPicture/table1/MTC.svg',
        id: 430,
        label: 'МТС 297788990',
        paymentData: {
          classType: FavoriteOperationType.ERIP,
          paymentFor: '297788990',
          paymentFrom: null,
          paymentType: FavoriteOperationType.ERIP,
          personalAccountName: 'Номер телефона',
          providerName: null,
          serviceId: 393931
        },
        position: 1,
        type: 'PAYMENT'
      }, {
        childElements: null,
        iconUrl: '/remote/operationsPicture/table3/17.svg',
        id: 453,
        label: 'Свободный платеж Юрлицо',
        paymentData: {
          classType: 'freepay',
          paymentFor: 'BY74ALFA30122007660150270000',
          paymentFrom: null,
          paymentType: FavoriteOperationType.FREEPAYMENT,
          purpose: 'test 123',
          purposeCode: '10501',
          receiverName: 'ООО "Парфюм Трейд"',
          receiverUNP: '190805026'
        },
        position: 2,
        type: 'PAYMENT'
      }, {
        childElements: null,
        iconUrl: '/remote/operationsPicture/table3/17.svg',
        id: 423,
        label: 'Свободный платеж 1 r',
        paymentData: {
          classType: 'freepay',
          paymentFor: 'BY24BELB30145083440060226000',
          paymentFrom: null,
          paymentType: FavoriteOperationType.FREEPAYMENT,
          purpose: 'test',
          purposeCode: '40103',
          receiverName: 'фвыв',
          receiverUNP: null
        },
        position: 3,
        type: 'PAYMENT'
      }],
      iconUrl: '/remote/operationsPicture/folder.svg',
      id: 40,
      label: 'Группа платежей 2m dasjkb asdjah s kjhda',
      paymentData: null,
      position: 2,
      type: 'FOLDER'
    }, {
      childElements: [{
        childElements: null,
        iconUrl: '/remote/operationsPicture/table1/default.svg',
        id: 431,
        label: 'QA 987654321 2',
        paymentData: {
          classType: FavoriteOperationType.ERIP,
          paymentFor: '987654321',
          paymentFrom: null,
          paymentType: FavoriteOperationType.ERIP,
          personalAccountName: 'LS',
          providerName: null,
          serviceId: 2644671
        },
        position: 1,
        type: 'PAYMENT'
      }],
      iconUrl: '/remote/operationsPicture/folder.svg',
      id: 39,
      label: 'Группа платежей',
      paymentData: null,
      position: 3,
      type: 'FOLDER'
    }, {
      childElements: null,
      iconUrl: '/remote/operationsPicture/table3/23.svg',
      id: 432,
      label: 'BYN - BYN',
      paymentData: {
        classType: 'root',
        paymentFor: 'BY09BELB30143103930030226000',
        paymentFrom: 'BY39BELB30143103930010226000',
        paymentType: FavoriteOperationType.ACCOUNT_ACCOUNT_TRANSFER
      },
      position: 4,
      type: 'PAYMENT'
    }, {
      childElements: null,
      iconUrl: '/remote/operationsPicture/table3/23.svg',
      id: 436,
      label: 'BYN - USD',
      paymentData: {
        classType: 'root',
        paymentFor: 'BY24BELB30143103930020226000',
        paymentFrom: 'BY39BELB30143103930010226000',
        paymentType: FavoriteOperationType.ACCOUNT_ACCOUNT_TRANSFER
      },
      position: 5,
      type: 'PAYMENT'
    }, {
      childElements: null,
      iconUrl: '/remote/operationsPicture/table3/23.svg',
      id: 438,
      label: 'Между счетами 2',
      paymentData: {
        classType: 'root',
        paymentFor: 'BY61BELB30143103930060226000',
        paymentFrom: 'BY39BELB30143103930010226000',
        paymentType: FavoriteOperationType.ACCOUNT_ACCOUNT_TRANSFER
      },
      position: 6,
      type: 'PAYMENT'
    }, {
      childElements: null,
      iconUrl: '/remote/operationsPicture/table1/A1.svg',
      id: 428,
      label: 'velcom-по 294455667',
      paymentData: {
        classType: FavoriteOperationType.ERIP,
        paymentFor: '334455667',
        paymentFrom: null,
        paymentType: FavoriteOperationType.ERIP,
        personalAccountName: 'Номер телефона',
        providerName: null,
        serviceId: 381861
      },
      position: 7,
      type: 'PAYMENT'
    }, {
      childElements: null,
      iconUrl: '/remote/operationsPicture/table3/17.svg',
      id: 477,
      label: 'Свободный платеж юр',
      paymentData: {
        classType: 'freepay',
        paymentFor: 'BY74ALFA30122007660150270000',
        paymentFrom: null,
        paymentType: FavoriteOperationType.FREEPAYMENT,
        purpose: 'test 123',
        purposeCode: '10101',
        receiverName: 'ООО "Парфюм Трейд"',
        receiverUNP: '190805026'
      },
      position: 8,
      type: 'PAYMENT'
    }, {
      childElements: null,
      iconUrl: '/remote/operationsPicture/table1/MTC.svg',
      id: 474,
      label: 'МТС 297788899',
      paymentData: {
        classType: FavoriteOperationType.ERIP,
        paymentFor: '297788899',
        paymentFrom: null,
        paymentType: FavoriteOperationType.ERIP,
        personalAccountName: 'Номер телефона',
        providerName: 'Мобильные ТелеСистемы, Совместное общество с ограниченной ответственностью',
        serviceId: 393931
      },
      position: 9,
      type: 'PAYMENT'
    }]
  };
};