import { utc } from 'moment';
import { ModalsService } from '@services';
import { Modals } from '@enums';
export var FilterOptions;

(function (FilterOptions) {
  FilterOptions["All"] = "all";
  FilterOptions["Details"] = "details";
  FilterOptions["Month"] = "month";
  FilterOptions["ThreeMonth"] = "threeMonth";
})(FilterOptions || (FilterOptions = {}));

export var resetFilterFormValues = {
  dateFrom: utc().utcOffset('+03:00').subtract(30, 'days').startOf('day').format(),
  dateTo: utc().utcOffset('+03:00').endOf('day').format(),
  maxAmount: null,
  minAmount: null
};
export var hasMore = function hasMore(pagination) {
  return !!pagination && typeof pagination.pageOffset !== 'undefined' && typeof pagination.pageSize !== 'undefined' && typeof pagination.totalObject !== 'undefined' && pagination.pageOffset + pagination.pageSize < pagination.totalObject;
};
/**
 * Handle open filter history.
 *
 * @author Christina Kashevar
 * @return {void}
 */

export var handleOpenFilterHistoryModal = function handleOpenFilterHistoryModal(props) {
  var filter = props.filter,
      handleLoad = props.handleLoad,
      isFilterLoading = props.isFilterLoading,
      lastFilter = props.lastFilter,
      updateFilterLoading = props.updateFilterLoading;
  var formFilter = lastFilter.current === FilterOptions.Details ? filter || resetFilterFormValues : resetFilterFormValues;
  void ModalsService.open(Modals.FilterCommonCreditDepositHistory, {
    filter: formFilter,
    resetValues: resetFilterFormValues
  }).then(function (data) {
    if ('data' in data) {
      document.documentElement.scrollTo({
        left: 0,
        top: 0
      });
      var _filter = data.data;

      if (!isFilterLoading) {
        updateFilterLoading();
      }

      handleLoad(_filter);
      lastFilter.current = FilterOptions.Details;
    }
  })["catch"](function (data) {// ToDo: error handling
  });
};
export var handleChange = function handleChange(props) {
  var filter = props.filter,
      handleLoad = props.handleLoad,
      isFilterLoading = props.isFilterLoading,
      lastFilter = props.lastFilter,
      updateFilterLoading = props.updateFilterLoading,
      value = props.value;

  switch (value) {
    case FilterOptions.Details:
      {
        handleOpenFilterHistoryModal({
          filter: filter,
          handleLoad: handleLoad,
          isFilterLoading: isFilterLoading,
          lastFilter: lastFilter,
          updateFilterLoading: updateFilterLoading
        });
        break;
      }

    case FilterOptions.All:
      {
        if (lastFilter.current !== FilterOptions.All && !isFilterLoading) {
          updateFilterLoading();
        }

        lastFilter.current = FilterOptions.All;
        handleLoad({});
        break;
      }

    case FilterOptions.Month:
      {
        if (lastFilter.current !== FilterOptions.Month && !isFilterLoading) {
          updateFilterLoading();
        }

        lastFilter.current = FilterOptions.Month;
        handleLoad({
          dateFrom: utc().utcOffset('+03:00').subtract(30, 'days').startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          dateTo: utc().utcOffset('+03:00').endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        });
        break;
      }

    case FilterOptions.ThreeMonth:
      {
        if (lastFilter.current !== FilterOptions.ThreeMonth && !isFilterLoading) {
          updateFilterLoading();
        }

        lastFilter.current = FilterOptions.ThreeMonth;
        handleLoad({
          dateFrom: utc().utcOffset('+03:00').subtract(90, 'days').startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          dateTo: utc().utcOffset('+03:00').endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        });
        break;
      }
  }
};