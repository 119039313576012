import { dispatch } from '@store';
import { actions } from '@store/loader';
/**
 * Loader service.
 *
 * @author Ihar Kazlouski
 * @const LoaderService
 * @category Components
 * @subcategory Loader
 */

var LoaderService = {
  /**
   * Decrease count of the requests.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  decRequestCounter: function decRequestCounter() {
    dispatch(actions.initDecreasingRequestCounter());
  },

  /**
   * Hide loader.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  hideLoader: function hideLoader() {
    dispatch(actions.initLoaderHiding());
  },

  /**
   * Increase count of the requests.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  incRequestCounter: function incRequestCounter() {
    dispatch(actions.initIncreasingRequestCounter());
  },

  /**
   * Show loader.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  showLoader: function showLoader() {
    dispatch(actions.initLoaderShowing());
  }
};
export { LoaderService };