import { __rest as t, __assign as o } from "../../_virtual/_tslib.js";
import { jsx as e, jsxs as r, Fragment as n } from "react/jsx-runtime";
import { useRef as i } from "react";
import { useVirtualizer as l } from "@tanstack/react-virtual";
import { Box as a, Skeleton as m } from "@mui/material";
import { InlineError as p } from "../inline-error/inline-error.component.js";
import { Typography as d } from "../typography/typography.component.js";
import { StyledComponentWrapper as h, StyledParentList as c, StyledListWrapper as s, StyledList as g } from "./list-virtualizer.styles.js";

function u(u) {
  var f,
      v,
      y = u.children,
      C = u.list,
      w = u.ListComponent,
      x = void 0 === w ? "ul" : w,
      S = u.height,
      b = u.ListItemComponent,
      L = void 0 === b ? "li" : b,
      j = u.ParentComponent,
      T = u.disableBottomShadow,
      B = u.disableTopShadow,
      E = u.emptyListComponent,
      I = void 0 === E ? "Not found" : E,
      k = u.loading,
      z = u.error,
      P = u.loadingComponent,
      $ = u.errorComponent,
      _ = t(u, ["children", "list", "ListComponent", "height", "ListItemComponent", "ParentComponent", "disableBottomShadow", "disableTopShadow", "emptyListComponent", "loading", "error", "loadingComponent", "errorComponent"]),
      A = i(null),
      F = C.length,
      G = l(o(o({}, _), {
    count: F,
    getScrollElement: function () {
      return A.current;
    }
  })),
      N = G.getVirtualItems(),
      O = G.getTotalSize(),
      R = G.scrollRect.height,
      V = G.scrollOffset;

  return e(h, o({
    $showBottomShadow: !(B || O > R && V + R >= O),
    $showTopShadow: !T && V > 0
  }, {
    children: e(c, o({
      ref: A,
      component: j,
      height: S
    }, {
      children: k || z ? r(s, o({
        style: {
          height: k || z ? void 0 : O
        }
      }, {
        children: [k && (P || e(a, o({
          px: 2
        }, {
          children: e(m, {
            height: 56,
            variant: "rectangular"
          })
        }))), !k && z && ($ || e(a, o({
          px: 2
        }, {
          children: e(p, {
            color: "alpineGoat",
            title: "Error"
          })
        })))]
      })) : e(s, o({
        style: {
          height: k || z ? void 0 : O
        }
      }, {
        children: e(g, o({
          component: x,
          style: {
            transform: "translateY(".concat(null !== (v = null === (f = N[0]) || void 0 === f ? void 0 : f.start) && void 0 !== v ? v : 0, "px)")
          }
        }, {
          children: e(n, {
            children: 0 === N.length ? e("li", {
              children: "string" == typeof I ? e(d, o({
                color: "bayFog",
                textAlign: "center",
                variant: "body1"
              }, {
                children: I
              })) : I
            }) : N.map(function (t) {
              return e(a, o({
                ref: G.measureElement,
                component: L,
                "data-index": t.index
              }, {
                children: y(t, G)
              }), t.key);
            })
          })
        }))
      }))
    }))
  }));
}

export { u as ListVirtualizer };