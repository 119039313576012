import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Button, pxToRem } from '@pwa/ui';
import { Box, Stack, styled } from '@mui/material';
var StyledBox = styled(Box)(function () {
  var styles = {
    '& .rpv-core__inner-page': {
      display: 'flex',
      justifyContent: 'center'
    },
    '& .rpv-core__text-layer': {
      display: 'none'
    },
    '& .rpv-core__text-layer-text': {
      display: 'none'
    }
  };
  return styles;
});
var StyledWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var height = theme.convertToDVH(100);

  var styles = _defineProperty({
    height: "calc(".concat(height, " - ").concat(pxToRem(332), ")"),
    marginTop: theme.spacing(2),
    minHeight: pxToRem(300)
  }, theme.breakpoints.up('desktop'), {
    height: "clamp(".concat(pxToRem(300), ", calc(100dvh - ").concat(pxToRem(448), "), ").concat(pxToRem(497), ")")
  });

  return styles;
});
var StyledButtonWrapper = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    '&:hover': {
      boxShadow: theme.boxShadows.independence,
      cursor: 'pointer'
    },
    backgroundColor: theme.palette.white.main,
    borderRadius: pxToRem(12),
    padding: theme.spacing(2),
    textAlign: 'center',
    width: '100%'
  };
  return styles;
});
var StyledStack = styled(Stack)(function () {
  var styles = {
    '& > div': {
      width: '100%'
    }
  };
  return styles;
});
var StyledButton = styled(Button)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    height: 'unset',
    padding: theme.spacing(2),
    width: '100%'
  };
  return styles;
});
var StyledSkeletonContent = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;

  var styles = _defineProperty({
    paddingTop: theme.spacing(8),
    position: 'relative'
  }, theme.breakpoints.down('desktop'), {
    marginTop: theme.spacing(-3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(3)
  });

  return styles;
});
export { StyledBox, StyledButton, StyledButtonWrapper, StyledSkeletonContent, StyledStack, StyledWrapper };