import { Button, pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
import { NotificationImage } from '@enums';
var StyledWrapper = styled(Button)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    borderRadius: pxToRem(16),
    height: 'unset',
    padding: theme.spacing(1)
  };
  return styles;
});
var StyledContent = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    padding: theme.spacing(1.5, 1, 1)
  };
  return styles;
});
var StyledLabel = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    backgroundColor: theme.palette.whiteSolid.main,
    borderRadius: pxToRem(5),
    padding: theme.spacing(0.5, 0.75)
  };
  return styles;
});
var StyledBox = styled(Box)(function () {
  var styles = {
    '& div': {
      height: '100%',
      width: '100%'
    },
    '& img': {
      borderRadius: pxToRem(10),
      maxHeight: '100%',
      maxWidth: '100%',
      objectFit: 'cover'
    },
    aspectRatio: "".concat(NotificationImage.RatioWidth, "/").concat(NotificationImage.RatioHeight),
    flex: "".concat(pxToRem(92), " 0 1")
  };
  return styles;
});
export { StyledBox, StyledContent, StyledLabel, StyledWrapper };