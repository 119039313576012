import { __rest as e, __assign as t } from "../../../_virtual/_tslib.js";
import { jsxs as i, jsx as o } from "react/jsx-runtime";
import { Stack as r } from "@mui/material";
import { Icon as a } from "../../icon/icon.component.js";
import { RemoteImage as c } from "../../remote-image/remote-image.view.js";
import { Typography as l } from "../../typography/typography.component.js";
import "../../../themes/box-shadows/boxShadows.js";
import "../../../themes/dark.theme.js";
import { pxToRem as n } from "../../../themes/default-theme/default-theme.js";
import "../../../themes/gradients/gradients.js";
import "../../../themes/light.theme.js";
import { StyledFormControl as s, StyledInputLabel as d, StyledCheckboxLabel as m, StyledButton as h, StyledCheckboxBase as p, StyleFromHelperWrapper as b, StyledFormHelperText as u } from "./checkbox-button-field.styles.js";
import f from "../../../packages/pwa-ui/assets/images/checkbox-checked.image.svg.js";
import g from "../../../packages/pwa-ui/assets/images/checkbox-unchecked.image.svg.js";

var v = function (v) {
  var w = v.name,
      j = v.fullWidth,
      k = v.color,
      y = void 0 === k ? "white" : k,
      x = v.label,
      W = v.title,
      F = v.subtitle,
      I = v.bottomLabel,
      $ = v.error;
  v.deviceSize;
  var R = v.focused;
  v.active, v.inputRef, v.value;

  var S = v.image,
      z = v.disabled,
      B = e(v, ["name", "fullWidth", "color", "label", "title", "subtitle", "bottomLabel", "error", "deviceSize", "focused", "active", "inputRef", "value", "image", "disabled"]),
      L = w && "".concat(w, "-checkbox-button-field"),
      _ = !!$,
      C = {
    errorId: L && "".concat(L, "-error-text"),
    htmlFor: L,
    id: L
  };

  return i(s, t({
    $disabled: Boolean(z),
    error: _,
    focused: R,
    fullWidth: j
  }, {
    children: [x && o(d, t({
      $color: y,
      htmlFor: C.htmlFor,
      shrink: !0
    }, {
      children: x
    })), o(m, {
      control: o(h, t({
        $disabled: Boolean(z),
        color: "white",
        component: "span",
        fullWidth: j,
        variant: "contained"
      }, {
        children: i(r, t({
          direction: "row",
          justifyContent: "space-between",
          spacing: 2,
          width: "100%"
        }, {
          children: [i(r, t({
            alignItems: "center",
            direction: "row",
            spacing: 2
          }, {
            children: [S && o(c, {
              borderRadius: n(10),
              height: 36,
              src: S,
              width: 36
            }), o(l, t({
              fontWeight: 500,
              variant: "body1"
            }, {
              children: W
            }))]
          })), i(r, t({
            alignItems: "center",
            direction: "row",
            spacing: 1.5
          }, {
            children: [o(l, t({
              color: "bayFog",
              fontWeight: 500,
              variant: "body1"
            }, {
              children: F
            })), o(p, t({
              "aria-describedby": L,
              checkedIcon: o(a, {
                height: 20,
                src: f,
                width: 20
              }),
              error: $ || "",
              fullWidth: j,
              icon: o(a, {
                color: _ ? "error" : "estateViolet",
                height: 20,
                src: g,
                width: 20
              }),
              id: C.id,
              inputProps: {
                id: C.id,
                type: "checkbox"
              },
              name: w
            }, B, {
              type: "checkbox"
            }))]
          }))]
        }))
      })),
      label: ""
    }), I && !_ ? o(b, {
      children: o(u, t({
        $color: y
      }, {
        children: I
      }))
    }) : null]
  }));
};

export { v as CheckboxButtonField };