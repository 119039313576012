// type PaymentsCardsFormSchemaProps = {};
import * as yup from 'yup';
import { formatNumber, isCorrectIBANValidator } from '@helpers';
import { PaymentsFreeReceiverType, PaymentsFreeStatus } from '@enums';
import { i18n } from '@localization';
import { RECEIVER_IBAN_LENGTH_FIELD, RECEIVER_UNP_LENGTH_FIELD } from './payments-free-form.enum';

var getPaymentsFreeFormSchema = function getPaymentsFreeFormSchema(_ref) {
  var status = _ref.status;
  return yup.object().shape({
    amount: yup.number().typeError(i18n.t('forms.validation.enterAmount')).test({
      name: 'amount',
      test: function test(value, ctx) {
        var fields = ctx.parent;
        if (status !== PaymentsFreeStatus.CONFIRM) return true;

        if (!value) {
          return ctx.createError({
            message: i18n.t('forms.validation.enterAmount')
          });
        }

        if (fields.minAmount && value < fields.minAmount) {
          return ctx.createError({
            message: i18n.t('forms.validation.minAmount', {
              data: " ".concat(formatNumber(fields.minAmount, 2, true, ',', ' '), " ").concat(fields.currency)
            })
          });
        }

        if (fields.maxAmount && value > fields.maxAmount) {
          return ctx.createError({
            message: i18n.t('forms.validation.maxAmount', {
              data: " ".concat(formatNumber(fields.maxAmount, 2, true, ',', ' '), " ").concat(fields.currency)
            })
          });
        }

        if (fields.senderAccount && value > fields.senderAccount.accountAmount) {
          return ctx.createError({
            message: i18n.t('forms.validation.notEnoughOnAccount')
          });
        }

        return true;
      }
    }).nullable(),
    checkContract: yup.bool().test({
      message: i18n.t('forms.validation.required'),
      name: 'checkContract',
      test: function test(value, ctx) {
        if (status !== PaymentsFreeStatus.CONFIRM) return true;
        return value || false;
      }
    }),
    purpose: yup.string().test({
      name: 'checkPurpose',
      test: function test(value, ctx) {
        if (status !== PaymentsFreeStatus.CONFIRM) return true;

        if (!value || !value.length) {
          return ctx.createError({
            message: i18n.t('forms.validation.required')
          });
        }

        return true;
      }
    }).nullable(),
    purposeCode: yup.string().test({
      name: 'checkPurposeCode',
      test: function test(value, ctx) {
        if (status !== PaymentsFreeStatus.CONFIRM) return true;

        if (!value || !value.length) {
          return ctx.createError({
            message: i18n.t('forms.validation.required')
          });
        }

        return true;
      }
    }).nullable(),
    purposeCodeShortDescription: yup.string().test({
      name: 'checkPurposeCodeShortDescription',
      test: function test(value, ctx) {
        if (status !== PaymentsFreeStatus.CONFIRM) return true;

        if (!value || !value.length) {
          return ctx.createError({
            message: i18n.t('forms.validation.required')
          });
        }

        return true;
      }
    }).nullable(),
    purposeLegal: yup.object().test({
      name: 'checkPurposeLegal',
      test: function test(value, ctx) {
        if (status !== PaymentsFreeStatus.CONFIRM) return true;
        var fields = ctx.parent;

        if (fields.receiverType !== PaymentsFreeReceiverType.LEGAL) {
          return true;
        }

        if (!value) {
          return ctx.createError({
            message: i18n.t('forms.validation.required')
          });
        }

        return true;
      }
    }).nullable(),
    receiverIban: yup.string().required(i18n.t('forms.validation.required')).test({
      name: 'checkIBANLength',
      test: function test(value, ctx) {
        if (!value) return true;
        var isCorrectIBAN = isCorrectIBANValidator()(value);
        return isCorrectIBAN ? true : ctx.createError({
          message: i18n.t('forms.validation.inCorrectValue')
        });
      }
    }).nullable(),
    receiverName: yup.string().test({
      name: 'checkReceiverName',
      test: function test(value, ctx) {
        if (status !== PaymentsFreeStatus.CONFIRM) return true;

        if (!value || !value.length) {
          return ctx.createError({
            message: i18n.t('forms.validation.required')
          });
        }

        var fields = ctx.parent;
        var words = value.split(' ').filter(function (word) {
          return word.trim() !== '';
        });

        if (words.length < 2 && fields.receiverType !== 2) {
          return ctx.createError({
            message: i18n.t('forms.validation.fullName')
          });
        }

        return true;
      }
    }).nullable(),
    receiverUNP: yup.string().when('receiverIban', {
      is: function is(receiverIban) {
        var ibanRegexp = /^([A-Z]{2})(\d{2})([A-Z0-9]{4})3819([A-Z0-9]{16})$/;
        if (status === PaymentsFreeStatus.CONFIRM) return false;

        if (receiverIban && receiverIban.length === RECEIVER_IBAN_LENGTH_FIELD && ibanRegexp.test(receiverIban)) {
          return true;
        }

        return false;
      },
      then: yup.string().required(i18n.t('forms.validation.required'))
    }).test({
      name: 'checkUNPLength',
      test: function test(value, ctx) {
        if (!value) return true;

        if (value.length !== RECEIVER_UNP_LENGTH_FIELD) {
          return ctx.createError({
            message: i18n.t('forms.validation.inCorrectValue')
          });
        }

        return true;
      }
    }).nullable()
  });
};

export { getPaymentsFreeFormSchema };