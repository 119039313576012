import { produce } from 'immer';
import { CLOSE_POPUP, CLOSING_POPUP_WITH_ANSWER, REMOVE_POPUP, SET_POPUP } from './popups.action-types';
var initialState = [];
/**
 * Popups reducer.
 *
 * @author Mihail Siutsou
 * @category Reducers
 * @subcategory Popups
 * @param {PopupsState} state state.
 * @param {ActionsTypes} action action.
 * @return {PopupsState[]} popups state.
 */

var popupsReducer = function popupsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_POPUP:
      {
        return produce(state, function (draft) {
          var idx = state.findIndex(function (popup) {
            return popup.popupId === action.payload.popupId;
          });

          if (idx >= 0) {
            draft[idx] = {
              accepted: false,
              data: action.payload.data,
              isOpen: action.payload.isOpen,
              popupId: action.payload.popupId,
              rejected: false,
              template: action.payload.template
            };
          } else {
            draft.push({
              accepted: false,
              data: action.payload.data,
              isOpen: action.payload.isOpen,
              popupId: action.payload.popupId,
              rejected: false,
              template: action.payload.template
            });
          }
        });
      }

    case CLOSE_POPUP:
      {
        return produce(state, function (draft) {
          var popup = draft.find(function (popup) {
            return popup.popupId === action.payload.popupId;
          });

          if (popup) {
            popup.isOpen = false;
          }
        });
      }

    case CLOSING_POPUP_WITH_ANSWER:
      {
        return produce(state, function (draft) {
          var popup = draft.find(function (popup) {
            return popup.popupId === action.payload.popupId;
          });

          if (popup) {
            popup.accepted = action.payload.accepted;
            popup.rejected = action.payload.rejected;
            popup.isOpen = false;
            popup.data = action.payload.data;
          }
        });
      }

    case REMOVE_POPUP:
      {
        return produce(state, function (draft) {
          var index = draft.findIndex(function (popup) {
            return popup.popupId === action.payload.popupId;
          });
          if (index !== -1) draft.splice(index, 1);
        });
      }

    default:
      {
        return state;
      }
  }
};

export default popupsReducer;