export var Modals;

(function (Modals) {
  Modals["AccountCardOther"] = "AccountCardOther";
  Modals["AccountForCommission"] = "AccountForCommission";
  Modals["BankAtms"] = "BankAtms";
  Modals["BankDepartments"] = "BankDepartments";
  Modals["CardActivation"] = "CardActivation";
  Modals["CardLimitsEdit"] = "CardLimitsEdit";
  Modals["ConfirmOperation"] = "ConfirmOperation";
  Modals["CreditRepay"] = "CreditRepay";
  Modals["DepositClose"] = "DepositClose";
  Modals["DepositReplenish"] = "DepositReplenish";
  Modals["DepositWithdraw"] = "DepositWithdraw";
  Modals["FilterCommonCreditDepositHistory"] = "FilterCommonCreditDepositHistory";
  Modals["FilterHistory"] = "FilterHistory";
  Modals["HistoryOperationDetails"] = "HistoryOperationDetails";
  Modals["Notifications"] = "Notifications";
  Modals["OpenAccounts"] = "OpenAccounts";
  Modals["OperationCheck"] = "OperationCheck";
  Modals["PackageCategories"] = "PackageCategories";
  Modals["PaymentsOneclickDetails"] = "PaymentsOneclickDetails";
  Modals["QRScanner"] = "QRScanner";
  Modals["SMSNotification"] = "SMSNotification";
  Modals["Secure3DS"] = "Secure3DS";
})(Modals || (Modals = {}));