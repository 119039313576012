import { styled as i, IconButton as e, iconButtonClasses as r } from "@mui/material";
import "../../themes/box-shadows/boxShadows.js";
import "../../themes/dark.theme.js";
import { pxToRem as o } from "../../themes/default-theme/default-theme.js";
import "../../themes/gradients/gradients.js";
import "../../themes/light.theme.js";
var t = i(e)(function (i) {
  var e,
      t,
      a,
      d = i.theme,
      n = i.size,
      s = i.color,
      m = i.variant,
      h = ((e = {
    borderRadius: o(12),
    minHeight: o(40),
    minWidth: o(40),
    transition: d.transitions.create(["box-shadow", "background-color"])
  })["&.".concat(r.edgeEnd)] = {
    marginRight: d.spacing(-1)
  }, e);

  switch (n) {
    case "extraLarge":
      h.borderRadius = o(16), h.minHeight = o(60), h.minWidth = o(60);
      break;

    case "large":
      h.borderRadius = o(14), h.minHeight = o(56), h.minWidth = o(56);
      break;

    case "small":
      h.padding = o(5), h.borderRadius = o(6), h.minHeight = o(24), h.minWidth = o(24), h["&.".concat(r.edgeEnd)] = {
        marginRight: d.spacing(-.625)
      };
  }

  if (!0 == ("whisper" === s)) "contained" === m && (h.backgroundColor = null === (t = null == d ? void 0 : d.colors) || void 0 === t ? void 0 : t.whisper, h["&:hover"] = {
    backgroundColor: null === (a = null == d ? void 0 : d.colors) || void 0 === a ? void 0 : a.mischka
  });
  return h;
});
export { t as StyledIconButton };