import { lightTheme as defaultLightThemeUI } from '@pwa/ui';
import { createTheme } from '@mui/material';
import { appTheme } from './app.theme';
import { boxShadows } from './box-shadows';
import { colors } from './colors';
import { convertToDVH, HEADER_HEIGHT, HEADER_HEIGHT_DESKTOP, NAVIGATION_HEIGHT } from './default-theme';
import { gradients } from './gradients';
var lightTheme = createTheme(defaultLightThemeUI, appTheme, {
  HEADER_HEIGHT: HEADER_HEIGHT,
  HEADER_HEIGHT_DESKTOP: HEADER_HEIGHT_DESKTOP,
  NAVIGATION_HEIGHT: NAVIGATION_HEIGHT,
  boxShadows: boxShadows,
  colors: colors,
  convertToDVH: convertToDVH,
  gradients: gradients
});
export { lightTheme };