import { jsx as o } from "react/jsx-runtime";
import { Icon as r } from "../icon/icon.component.js";
import { StyledSearchIcon as t } from "./autocomplete-list.styles.js";
import e from "../../packages/pwa-ui/assets/images/search.image.svg.js";

var s = function () {
  return o(t, {
    children: o(r, {
      color: "secondary",
      height: 18,
      src: e,
      width: 18
    })
  });
};

export { s as SearchIcon };