import { styled as t, Box as o, alpha as e } from "@mui/material";
var i = t(o)(function (t) {
  var o,
      i = t.theme,
      n = t.$showBottomShadow,
      a = t.$showTopShadow,
      r = i.typography.pxToRem;
  return (o = {})["&:before, &:after"] = {
    borderRadius: "10px",
    content: "''",
    height: 1,
    left: "50%",
    opacity: 0,
    pointerEvents: "none",
    position: "absolute",
    transform: "translate3d(-50%,0,0)",
    transition: i.transitions.create(["opacity"]),
    width: "calc(100% - 20px)",
    zIndex: 1
  }, o["&:after"] = {
    bottom: -1,
    boxShadow: "0 ".concat(r(-5), " ").concat(r(20), " ").concat(e(i.palette.nightWizard.main, 1)),
    opacity: n ? 1 : void 0
  }, o["&:before"] = {
    boxShadow: "0 ".concat(r(5), " ").concat(r(20), " ").concat(e(i.palette.nightWizard.main, 1)),
    opacity: a ? 1 : void 0,
    top: -1
  }, o.overflow = "hidden", o.position = "relative", o;
}),
    n = t(o)(function (t) {
  t.theme;
  return {
    contain: "strict",
    overflowY: "auto",
    position: "relative"
  };
}),
    a = t(o)(function (t) {
  t.theme;
  return {
    position: "relative",
    width: "100%"
  };
}),
    r = t(o)(function (t) {
  return {
    left: 0,
    listStyle: "none",
    margin: 0,
    padding: t.theme.spacing(1, 2),
    position: "absolute",
    top: 0,
    width: "100%"
  };
});
export { i as StyledComponentWrapper, r as StyledList, a as StyledListWrapper, n as StyledParentList };