/**
 * Masks util.
 *
 * @author Ihar Kazlouski
 * @const MaskUtil
 * @category Utils
 */
var MaskUtil = {
  currencyAmountMask: {
    definitions: {
      '#': /[\d\\.]/
    },
    lazy: true,
    mask: Number,
    max: 10000000000000000,
    min: 0,
    normalizeZeros: true,
    padFractionalZeros: true,
    radix: ',',
    scale: 2,
    signed: false,
    thousandsSeparator: ' '
  },
  cyrillicMask: {
    lazy: true,
    mask: /^[а-яА-Я]*$/
  },
  dateMask: {
    lazy: true,
    mask: Date,
    max: new Date(),
    min: new Date(1990, 0, 1)
  },
  fullNameMask: {
    lazy: true,
    mask: /^[а-яА-Яa-zA-Z0-9- ]*$/
  },
  latinMask: {
    lazy: true,
    mask: /^[a-z]*$/gi
  },
  latinNumberMask: {
    lazy: true,
    mask: /^[a-zA-Z0-9]*$/
  },
  numberCardMask: {
    definitions: {
      '#': /\d/
    },
    lazy: true,
    mask: '0000 0000 0000 0000',
    placeholderChar: 'X'
  },
  oneDigitMask: {
    definitions: {
      '#': /[0-9]/
    },
    inputMode: 'decimal',
    lazy: true,
    mask: '#'
  },
  onlyLatinNumberSymbols: {
    lazy: true,
    // eslint-disable-next-line no-useless-escape
    mask: /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{}\\<>\/?]*$/
  },
  onlyNumber: {
    lazy: true,
    mask: /^[0-9]*$/
  },
  onlyPositiveDigital: {
    mask: Number,
    normalizeZeros: true,
    scale: 0,
    signed: false
  },
  phoneMask: {
    definitions: {
      '#': /[0-9]/
    },
    lazy: true,
    mask: '+{#} (###) ### ## ## ## ## ## #',
    placeholderChar: 'X'
  },
  phoneShortMask: {
    definitions: {
      '#': /[0-9]/
    },
    inputMode: 'decimal',
    lazy: true,
    mask: '## ### ## ##',
    placeholderChar: 'X'
  },
  timeMask: {
    definitions: {
      '#': /[0-23]/,
      '*': /[0-59]/
    },
    lazy: true,
    mask: '##.**',
    placeholderChar: 'X'
  },
  transferAmountMask: {
    definitions: {
      '#': /[\d\\.]/
    },
    lazy: true,
    mask: Number,
    max: 10000000000000000,
    min: 0,
    normalizeZeros: true,
    padFractionalZeros: true,
    radix: ',',
    scale: 2,
    signed: false,
    thousandsSeparator: ' '
  }
};
export { MaskUtil };