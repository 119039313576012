import * as yup from 'yup';
import { i18n } from '@localization';
export var getReplenishFormSchema = function getReplenishFormSchema(accounts) {
  return yup.object().shape({
    amount: yup.number().required(i18n.t('forms.validation.enterAmount')).test({
      message: i18n.t('forms.validation.maxTransferAmountExceed'),
      name: 'notEnoughOnAccount',
      test: function test(value, ctx) {
        if (!value) return true;
        var fields = ctx.parent;

        if (!fields.accountId) {
          return true;
        }

        var account = accounts === null || accounts === void 0 ? void 0 : accounts.find(function (acc) {
          return acc.id === fields.accountId;
        });
        if (!account) return false;
        return Number(value) <= Number(account.accountAmount);
      }
    }).nullable()
  });
};