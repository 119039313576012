import { styled as o, BottomNavigation as e, alpha as t, BottomNavigationAction as r, bottomNavigationActionClasses as a } from "@mui/material";
var p = o(e)(function (o) {
  var e = o.theme;
  return {
    backdropFilter: "blur(".concat(e.typography.pxToRem(10), ")"),
    backgroundColor: t(e.palette.white.main, .85),
    height: e.typography.pxToRem(68)
  };
}),
    i = o(r)(function (o) {
  var e,
      t = o.theme;
  return (e = {
    color: t.colors.bayFog,
    padding: 0
  })["& .".concat(a.label)] = {
    fontSize: t.typography.pxToRem(10),
    fontWeight: 500,
    padding: "0 ".concat(t.spacing(1)),
    position: "relative",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  }, e["& .".concat(a.selected)] = {
    color: t.colors.panPurple,
    fontSize: t.typography.pxToRem(10)
  }, e;
});
export { p as StyledBottomNavigation, i as StyledBottomNavigationAction };