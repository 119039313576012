import * as yup from 'yup';
import { i18n } from '@localization';
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from './settings-password-form.enum';

var getSettingsPasswordFormSchema = function getSettingsPasswordFormSchema() {
  return yup.object().shape({
    newPassword: yup.string().trim().required(i18n.t('forms.validation.required')).test({
      name: 'checkCorrectNewPassword',
      test: function test(value, ctx) {
        if (!value) return true;

        if (value.length < MIN_PASSWORD_LENGTH) {
          return ctx.createError({
            message: i18n.t('forms.validation.minSymbols', {
              count: MIN_PASSWORD_LENGTH
            })
          });
        }

        if (value.length > MAX_PASSWORD_LENGTH) {
          return ctx.createError({
            message: i18n.t('forms.validation.maxSymbols', {
              count: MAX_PASSWORD_LENGTH
            })
          });
        }

        if (!/^\S+$/.test(value)) {
          return ctx.createError({
            message: i18n.t('forms.validation.password.space')
          });
        }

        if (!/[A-Z]/.test(value)) {
          return ctx.createError({
            message: i18n.t('forms.validation.password.capitalLatin')
          });
        }

        if (!/[0-9]/.test(value)) {
          return ctx.createError({
            message: i18n.t('forms.validation.password.number')
          });
        }

        var field = ctx.parent;

        if (field.password && value === field.password) {
          return ctx.createError({
            message: i18n.t('forms.validation.password.samePasswords')
          });
        }

        return true;
      }
    }),
    newPasswordRepeat: yup.string().trim().required(i18n.t('forms.validation.required')).test({
      name: 'checkCorrectNewPasswordRepeat',
      test: function test(value, ctx) {
        if (!value) return true;

        if (value.length < MIN_PASSWORD_LENGTH) {
          return ctx.createError({
            message: i18n.t('forms.validation.minSymbols', {
              count: MIN_PASSWORD_LENGTH
            })
          });
        }

        if (value.length > MAX_PASSWORD_LENGTH) {
          return ctx.createError({
            message: i18n.t('forms.validation.maxSymbols', {
              count: MAX_PASSWORD_LENGTH
            })
          });
        }

        if (!/^\S+$/.test(value)) {
          return ctx.createError({
            message: i18n.t('forms.validation.password.space')
          });
        }

        if (!/[A-Z]/.test(value)) {
          return ctx.createError({
            message: i18n.t('forms.validation.password.capitalLatin')
          });
        }

        if (!/[0-9]/.test(value)) {
          return ctx.createError({
            message: i18n.t('forms.validation.password.number')
          });
        }

        var field = ctx.parent;

        if (field.newPassword && value !== field.newPassword) {
          return ctx.createError({
            message: i18n.t('forms.validation.password.notSamePassword')
          });
        }

        return true;
      }
    }),
    password: yup.string().trim().required(i18n.t('forms.validation.required')).test({
      name: 'checkCorrectPassword',
      test: function test(value, ctx) {
        if (!value) return true;

        if (value.length > MAX_PASSWORD_LENGTH) {
          return ctx.createError({
            message: i18n.t('forms.validation.maxSymbols', {
              count: MAX_PASSWORD_LENGTH
            })
          });
        }

        return true;
      }
    })
  });
};

export { getSettingsPasswordFormSchema };