export var PaymentInstrument;

(function (PaymentInstrument) {
  PaymentInstrument["Account"] = "A";
  PaymentInstrument["Card"] = "C";
})(PaymentInstrument || (PaymentInstrument = {}));

export var OperationDirection;

(function (OperationDirection) {
  OperationDirection["Income"] = "1";
  OperationDirection["Outcome"] = "2";
})(OperationDirection || (OperationDirection = {}));

export var OperationType;

(function (OperationType) {
  OperationType["Banking"] = "3";
  OperationType["ERIP"] = "1";
  OperationType["Other"] = "4";
  OperationType["Transfer"] = "2";
})(OperationType || (OperationType = {}));

export var SdboChannel;

(function (SdboChannel) {
  SdboChannel["Mobile"] = "2";
  SdboChannel["PWA"] = "1";
})(SdboChannel || (SdboChannel = {}));