import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { pxToRem, Typography } from '@pwa/ui';
import { alpha, Box, styled } from '@mui/material';
var StyledWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme,
      $bgColor = _ref.$bgColor;

  var styles = _defineProperty({
    backgroundColor: $bgColor,
    marginTop: "-".concat(theme.HEADER_HEIGHT),
    paddingBottom: theme.spacing(12),
    paddingTop: theme.HEADER_HEIGHT
  }, theme.breakpoints.up('desktop'), {
    marginTop: "-".concat(theme.HEADER_HEIGHT_DESKTOP),
    paddingTop: theme.HEADER_HEIGHT_DESKTOP
  });

  return styles;
});
var StyledBox = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    backgroundColor: alpha(theme.palette.white.main, 0.1),
    borderRadius: pxToRem(16),
    boxShadow: theme.boxShadows.independence,
    padding: theme.spacing(1.5, 2)
  };
  return styles;
});
var StyledImage = styled(Box)(function () {
  var styles = {
    alignItems: 'center',
    borderRadius: '50%',
    display: 'flex',
    flex: '32px 0 0',
    height: pxToRem(32),
    overflow: 'hidden',
    width: pxToRem(32)
  };
  return styles;
});
var StyledTypography = styled(Typography)(function () {
  var styles = {
    opacity: '0.7'
  };
  return styles;
});
var StyledLargeTypography = styled(Typography)(function () {
  var styles = {
    fontSize: pxToRem(48)
  };
  return styles;
});
export { StyledBox, StyledImage, StyledLargeTypography, StyledTypography, StyledWrapper };