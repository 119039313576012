import { __rest as r, __read as t, __assign as e } from "../../_virtual/_tslib.js";
import { jsx as i, jsxs as o } from "react/jsx-runtime";
import { useState as n, useEffect as c } from "react";
import { Box as l } from "@mui/material";
import { Typography as a } from "../typography/typography.component.js";
import { ContainerBox as h, SvgContainer as m, StyledSvg as p, DonutCircle as f, PercentContainer as u } from "./donut-chart.styles.js";

var d = function (d) {
  var s = d.percent,
      v = d.color;
  r(d, ["percent", "color"]);
  var x = t(n(!1), 2),
      y = x[0],
      g = x[1];
  return c(function () {
    var r = setTimeout(function () {
      s > 0 && g(!0);
    }, 300);
    return function () {
      clearTimeout(r), g(!1);
    };
  }, [s]), i(h, {
    children: o(m, {
      children: [o(p, e({
        viewBox: "0 0 32 32"
      }, {
        children: [i("defs", {
          children: o("mask", e({
            id: "holeDonutChart"
          }, {
            children: [i("rect", {
              fill: "white",
              height: "100%",
              width: "100%"
            }), i("circle", {
              cx: "16",
              cy: "16",
              fill: "black",
              r: "13"
            })]
          }))
        }), i("circle", {
          cx: "16",
          cy: "16",
          fill: "white",
          mask: "url(#holeDonutChart)",
          r: "15.8"
        }), i(f, {
          $active: y,
          $percent: s,
          color: v,
          cx: "16",
          cy: "16",
          mask: "url(#holeDonutChart)",
          r: "14"
        })]
      })), i(u, {
        children: o(l, e({
          alignItems: "baseline",
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap"
        }, {
          children: [i(a, e({
            component: "span",
            fontWeight: "bold",
            variant: "caption2"
          }, {
            children: s
          })), i(a, e({
            fontWeight: "bold",
            variant: "caption2"
          }, {
            children: "%"
          }))]
        }))
      })]
    })
  });
};

export { d as DonutChart };