import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, styled } from '@mui/material';
var StyledIframeWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  };
  return styles;
});
var StyledIframeContainer = styled(Box)(function (_ref2) {
  var theme = _ref2.theme,
      $iframeHeight = _ref2.$iframeHeight;

  // const height = `calc(${theme.convertToDVH(100)} - ${theme.HEADER_HEIGHT})`;
  // const iframeHeight =  $iframeHeight ? theme.typography.pxToRem($iframeHeight): undefined;
  // const minHeight = $iframeHeight && (window.innerHeight - remToPixels(theme.HEADER_HEIGHT)) > $iframeHeight ? height: iframeHeight;
  var styles = _defineProperty({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    // height:          height,
    // maxHeight:       height,
    // minHeight:       minHeight,
    // overflow:        'hidden',
    width: '100%'
  }, theme.breakpoints.up('desktop'), {
    marginTop: theme.spacing(-3),
    minHeight: theme.typography.pxToRem(720)
  });

  return styles;
});
export { StyledIframeContainer, StyledIframeWrapper };