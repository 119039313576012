import { convertMomentDateFormat } from '@pwa/ui';
/**
 * Get Calendar Wheels Props
 *
 * @author Yury Kuntsou
 * @function getCalendarWheelsProps
 * @category Calendar
 * @param {string} format - The date format.
 * @returns {Pick<CalendarFieldProps, 'valueFormat' | 'dateFormat' | 'disableDays'>} The request with the added headers.
 */

var getCalendarWheelsProps = function getCalendarWheelsProps(format) {
  var convertedFormat = convertMomentDateFormat(format);
  var minDate = new Date(2000, 0, 1, 0, 0, 0, 0).toISOString();
  var maxDate = new Date(2099, 11, 31, 23, 59, 59, 999).toISOString();

  switch (format) {
    case 'MMYY':
      return {
        dateFormat: 'MM.20yy',
        disableDays: true,
        max: maxDate,
        min: minDate,
        valueFormat: convertedFormat
      };

    case 'DD.MM.YY':
      return {
        dateFormat: 'dd.MM.20yy',
        max: maxDate,
        min: minDate,
        valueFormat: convertedFormat
      };

    case 'DDMMYY':
      return {
        dateFormat: 'dd.MM.20yy',
        max: maxDate,
        min: minDate,
        valueFormat: convertedFormat
      };

    case 'YY':
      return {
        dateFormat: '20yy',
        disableDays: true,
        disableMonths: true,
        max: maxDate,
        min: minDate,
        valueFormat: convertedFormat
      };

    default:
      return {
        dateFormat: convertedFormat,
        valueFormat: convertedFormat
      };
  }
};

export { getCalendarWheelsProps };