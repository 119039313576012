import { __rest as r, __assign as t } from "../../../../_virtual/_tslib.js";
import { jsx as o, jsxs as e } from "react/jsx-runtime";
import { Stack as i, Box as n } from "@mui/material";
import { DonutChart as c } from "../../../donut-chart/donut-chart.component.js";
import { Typography as a } from "../../../typography/typography.component.js";
import { BannerContainer as l, Banner as m } from "./money-back.styles.js";

var p = function (p) {
  var s = p.currency;
  p.title;
  var u = p.subtitle,
      d = p.amount,
      h = p.percent;
  p.isArrow;
  var y = p.color,
      f = void 0 === y ? "luxuryDark" : y,
      v = r(p, ["currency", "title", "subtitle", "amount", "percent", "isArrow", "color"]);
  return o(l, {
    children: o(m, t({
      $color: f,
      color: "primary",
      variant: "contained"
    }, v, {
      children: o(i, t({
        flexGrow: 1,
        spacing: 1
      }, {
        children: e(i, t({
          alignItems: "center",
          direction: "row",
          justifyContent: "space-between",
          spacing: 1
        }, {
          children: [e(n, t({
            display: "flex",
            flexDirection: "column",
            gap: .25
          }, {
            children: [o(a, t({
              variant: "caption1"
            }, {
              children: u
            })), e(a, t({
              fontWeight: 700,
              variant: "body1"
            }, {
              children: [d, " ", s]
            }))]
          })), o(n, {
            children: o(c, {
              percent: h
            })
          })]
        }))
      }))
    }))
  });
};

export { p as MoneyBack };