import { Button, pxToRem } from '@pwa/ui';
import { styled } from '@mui/material';
var StyledButton = styled(Button)(function () {
  var styles = {
    bottom: pxToRem(40),
    height: 'unset',
    minWidth: 'unset',
    position: 'absolute',
    right: pxToRem(16)
  };
  return styles;
});
export { StyledButton };