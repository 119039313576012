import { styled as t, Box as n } from "@mui/material";
import { Button as o } from "../button/button.component.js";
var e = t(o)(function (t) {
  var n = t.theme;
  return t.color, {
    height: "unset",
    paddingBottom: n.spacing(1.875),
    paddingTop: n.spacing(1.875),
    position: "relative"
  };
}),
    i = t(n)(function (t) {
  t.theme;
  return {
    display: "flex",
    justifyContent: "center"
  };
});
export { i as StyledArrowWrapper, e as StyledButton };