import { pxToRem, Typography } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    backgroundColor: theme.palette.white.main,
    borderRadius: pxToRem(16)
  };
  return styles;
});
var StyledSecureContent = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column'
  };
  return styles;
});
var StyledSecureError = styled(Typography)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    color: theme.palette.error.main,
    fontSize: '12px',
    fontWeight: 500
  };
  return styles;
});
var StyledSecureNotConnected = styled(Typography)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    color: theme.colors.clownNose,
    fontSize: '12px',
    fontWeight: 500
  };
  return styles;
});
export { StyledSecureContent, StyledSecureError, StyledSecureNotConnected, StyledWrapper };