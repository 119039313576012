import { __rest as e, __read as t, __assign as n } from "../../../_virtual/_tslib.js";
import { jsxs as o, jsx as a, Fragment as r } from "react/jsx-runtime";
import { useState as i, useRef as s, useMemo as m } from "react";
import l from "../../../node_modules/date-fns/esm/format/index.js";
import d from "../../../node_modules/date-fns/esm/formatISO/index.js";
import u from "../../../node_modules/date-fns/esm/subMonths/index.js";
import { AdapterDateFns as p } from "../../../packages/pwa-ui/node_modules/@mui/x-date-pickers/AdapterDateFns/index.js";
import "../../../enums/entities/entities.enum.js";
import "../../../enums/keyboard/keyboard.enum.js";
import "../../../enums/theme/theme.enum.js";
import { getISODate as f, convertMomentDateFormat as c } from "../../../helpers/date/date.helpers.js";
import "../../../node_modules/@babel/runtime/helpers/typeof.js";
import { StyledWrapper as x } from "./desktop-calendar-field.styles.js";
import { TextField as D } from "../text-field/text-field.component.js";
import h from "../../../node_modules/date-fns/esm/locale/ru/index.js";
import v from "../../../node_modules/date-fns/esm/locale/en-IN/index.js";
import { LocalizationProvider as j } from "../../../packages/pwa-ui/node_modules/@mui/x-date-pickers/LocalizationProvider/LocalizationProvider.js";
import { DesktopDatePicker as y } from "../../../packages/pwa-ui/node_modules/@mui/x-date-pickers/DesktopDatePicker/DesktopDatePicker.js";

var k = function (k) {
  var b = k.name,
      w = k.min,
      _ = k.max,
      g = k.locale,
      P = void 0 === g ? "en" : g,
      F = k.value,
      S = k.onChange,
      C = k.dateFormat,
      M = void 0 === C ? "yyyy-MM-dd'T'HH:mm:ss.SSSxxx" : C,
      I = k.disableDays,
      O = k.disableMonths,
      A = k.disableYears,
      L = k.valueFormat,
      W = e(k, ["name", "min", "max", "locale", "value", "onChange", "dateFormat", "disableDays", "disableMonths", "disableYears", "valueFormat"]),
      z = t(i(!1), 2),
      B = z[0],
      H = z[1],
      Y = L ? f(F, c(L)) : F,
      $ = t(i(Y || ""), 1)[0],
      E = s(null),
      N = m(function () {
    return "ru" === P ? h : v;
  }, [P]),
      R = function (e) {
    if (S && e && e.toISOString() !== $) {
      var t = L ? L && l(new Date(d(e)), c(L)) : d(e);
      S(t);
    }
  },
      T = m(function () {
    return w ? new Date(w) : u(new Date(), 1);
  }, [w]),
      q = m(function () {
    return _ ? new Date(_) : new Date();
  }, [_]),
      G = O || I || A ? ["day", "month", "year"].filter(function (e) {
    return !(I && "day" === e || O && "month" === e || A && "year" === e);
  }) : null;

  return o(x, n({
    $filled: Boolean(F),
    $fullWidth: Boolean(W.fullWidth)
  }, {
    children: [a(D, n({
      inputRef: E,
      onClick: function (e) {
        e.preventDefault(), H(function (e) {
          return !e;
        });
      }
    }, W, {
      name: b,
      readOnly: !0,
      value: l(Y ? new Date(Y) : new Date(), M, {
        locale: N
      })
    })), a(j, n({
      adapterLocale: N,
      dateAdapter: p
    }, {
      children: a(y, n({
        PopperProps: {
          anchorEl: E.current,
          disablePortal: !0,
          style: {
            left: "initial",
            position: "absolute",
            top: "initial"
          }
        },
        dayOfWeekFormatter: function (e) {
          return e;
        },
        maxDate: q,
        minDate: T,
        open: B,
        reduceAnimations: !1,
        renderInput: function () {
          return a(r, {});
        },
        value: Y ? new Date(Y) : new Date(),
        onChange: function (e) {
          e && R(e);
        },
        onClose: function () {
          var e;
          null === (e = E.current) || void 0 === e || e.focus(), H(!1);
        }
      }, G ? {
        views: G
      } : {}))
    }))]
  }));
};

export { k as DesktopCalendarField };