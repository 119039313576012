import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { pxToRem } from '@pwa/ui';
import { Box, Stack, styled } from '@mui/material';
var StyledInformationWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(2)
  }, theme.breakpoints.up('desktop'), {
    maxWidth: theme.typography.pxToRem(452)
  });

  return styles;
});
var StyledInformation = styled(Stack)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    backgroundColor: '#FFFFFF',
    borderRadius: pxToRem(16),
    height: 'fit-content',
    padding: theme.spacing(2)
  };
  return styles;
});
var StyledPhoneNumberWrapper = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    '& p': {
      marginTop: theme.spacing(1)
    },
    marginBottom: theme.spacing(2)
  };
  return styles;
});
var StyledBodyWrapper = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    '& > span:last-child': {
      borderTop: "1px solid ".concat(theme.colors.lavenderSyrup),
      marginTop: theme.spacing(1.5),
      paddingTop: theme.spacing(1.5)
    },
    '& p': {
      marginBottom: theme.spacing(0.5)
    },
    borderTop: "1px solid ".concat(theme.colors.lavenderSyrup),
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(2, 0),
    paddingTop: theme.spacing(2)
  };
  return styles;
});
export { StyledBodyWrapper, StyledInformation, StyledInformationWrapper, StyledPhoneNumberWrapper };