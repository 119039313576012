import { pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledContent = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    backgroundColor: theme.palette.white.main,
    borderRadius: pxToRem(16),
    padding: theme.spacing(3)
  };
  return styles;
});
export { StyledContent };