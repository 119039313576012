import { styled as t, Button as e, Box as o } from "@mui/material";
import { Icon as r } from "../../../icon/icon.component.js";
import { Typography as n } from "../../../typography/typography.component.js";
import "../../../../themes/box-shadows/boxShadows.js";
import "../../../../themes/dark.theme.js";
import { pxToRem as a } from "../../../../themes/default-theme/default-theme.js";
import "../../../../themes/gradients/gradients.js";
import "../../../../themes/light.theme.js";
var i = t(e)(function (t) {
  var e,
      o = t.theme;
  return {
    alignItems: "start",
    background: null === (e = null == o ? void 0 : o.gradients) || void 0 === e ? void 0 : e.oxfordBlue,
    borderRadius: "".concat(a(28), " 0"),
    height: a(130),
    justifyContent: "start",
    paddingLeft: o.spacing(2.5),
    paddingTop: o.spacing(2.5),
    whiteSpace: "pre-line",
    width: "100%"
  };
}),
    s = t(o)(function (t) {
  var e,
      o = t.theme;
  return (e = {
    bottom: "0",
    height: a(150),
    position: "absolute",
    right: "0",
    width: a(170)
  })[o.breakpoints.down(340)] = {
    display: "none"
  }, e;
}),
    m = t(n)(function () {
  return {
    textDecoration: "line-through"
  };
}),
    h = t(r)(function () {
  return {
    "@keyframes slowShakeCardOut": {
      "0%": {
        transform: "translateY(".concat(a(4), ")")
      },
      "100%": {
        transform: "translateY(".concat(a(4), ")")
      },
      "50%": {
        transform: "translateY(0)"
      }
    },
    animation: "slowShakeCardOut 1.5s infinite ease"
  };
});
export { h as StyledArrowIcon, i as StyledBanner, s as StyledBox, m as StyledTypography };