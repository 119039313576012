import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Button, pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledContentWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(3)
  }, theme.breakpoints.up('desktop'), {
    maxWidth: theme.typography.pxToRem(452),
    paddingTop: theme.spacing(2)
  });

  return styles;
});
var StyledWrapper = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    '& br': {
      content: "''",
      display: 'block',
      marginTop: theme.spacing(1)
    },
    '& p': {
      color: theme.palette.nightWizard.main,
      fontSize: pxToRem(14)
    },
    backgroundColor: theme.palette.white.main,
    borderRadius: pxToRem(16),
    padding: theme.spacing(3)
  };
  return styles;
});
var StyledIconWrapper = styled(Button)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    height: '100%',
    justifyContent: 'flex-end',
    minWidth: 'unset',
    padding: '0',
    width: theme.typography.pxToRem(20)
  };
  return styles;
});
export { StyledContentWrapper, StyledIconWrapper, StyledWrapper };