export var getChangeUserPasswordConfirmationMock = function getChangeUserPasswordConfirmationMock() {
  return {
    codeOperation: '5022',
    dataOperation: [{
      id: 1,
      opDetails: 'eyJkZWFsQ29kZSI6IlZGMDMxMDIzLTAwMDEiLCJkZWFsVHlw'
    }],
    idOtp: null,
    idTransaction: '1234567',
    messageForClient: null,
    otp: false,
    pin: true
  };
};
export var getUser2FASettingsMock = function getUser2FASettingsMock() {
  return {
    '2faSbv': true
  };
};