import { styled as o, FormControl as t, Checkbox as e, InputLabel as r, Box as i, FormHelperText as n, FormControlLabel as a, formControlLabelClasses as s } from "@mui/material";
import { Button as l } from "../../button/button.component.js";
import "../../../themes/box-shadows/boxShadows.js";
import "../../../themes/dark.theme.js";
import { pxToRem as c } from "../../../themes/default-theme/default-theme.js";
import "../../../themes/gradients/gradients.js";
import "../../../themes/light.theme.js";
var m = o(t)(function (o) {
  var t,
      e = o.fullWidth,
      r = o.$disabled;
  return (t = {})["& .MuiFormControlLabel-root"] = {
    margin: 0
  }, t.display = e && "flex", t.maxWidth = "100%", t.pointerEvents = r && "none", t;
}),
    h = o(e, {
  shouldForwardProp: function (o) {
    return "fullWidth" !== o;
  }
})(function (o) {
  var t,
      e = o.theme;
  return (t = {})["& .MuiSvgIcon-root"] = {
    height: c(20)
  }, t["&.Mui-checked .MuiSvgIcon-root"] = {
    color: e.colors.panPurple
  }, t.color = e.colors.estateViolet, t.padding = e.spacing(.5), t;
}),
    p = o(r)(function (o) {
  var t = o.theme,
      e = o.$color,
      r = {
    color: t.colors.abandonedSpaceship,
    display: "block",
    fontSize: c(16),
    fontWeight: 400,
    lineHeight: c(18),
    marginBottom: t.spacing(.5),
    maxWidth: "100%",
    paddingLeft: t.spacing(1),
    position: "relative",
    transform: "unset",
    transformOrigin: "unset"
  };
  return "bayFog" === e && (r.color = t.colors.white), r;
}),
    d = o(i)(function () {
  return {
    fontSize: c(12),
    left: 0,
    lineHeight: c(14),
    marginTop: c(4),
    position: "absolute",
    right: 0,
    top: "100%"
  };
}),
    u = o(n)(function (o) {
  var t = o.theme,
      e = o.$color,
      r = {
    color: t.colors.abandonedSpaceship,
    display: "block",
    margin: "unset",
    maxWidth: "100%",
    padding: t.spacing(0, 1)
  };
  return "bayFog" === e && (r.color = t.colors.white), r;
}),
    g = o(a)(function (o) {
  var t,
      e = o.theme;
  return (t = {})["&.".concat(s.error)] = {
    color: e.palette.error.main
  }, t;
}),
    f = o(l)(function (o) {
  var t = o.theme;
  return {
    height: "unset",
    opacity: o.$disabled && "0.5",
    padding: t.spacing(1.375, 2)
  };
});
export { d as StyleFromHelperWrapper, f as StyledButton, h as StyledCheckboxBase, g as StyledCheckboxLabel, m as StyledFormControl, u as StyledFormHelperText, p as StyledInputLabel };