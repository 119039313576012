import { addEventListener, removeEventListener } from './event';
import { allQueuesAreEmpty, getOrCreateQueue } from './queue';

/**
 * Removes the callback from the queue and removes the event listener if necessary.
 *
 * @author Yury Kuntsou
 * @function removeCallback
 * @category Interceptors
 * @param {Object} params - The parameters object containing the required data.
 * @param {ID} params.key - The key of the queue.
 * @param {Queue} params.queue - The queue.
 * @param {HREF} href - The init locale url.
 * @param {WrappedCallbackRef} params.wrappedCallback - The wrapped callback reference.
 */
var removeCallback = function removeCallback(_ref) {
  var queue = _ref.queue,
      wrappedCallback = _ref.wrappedCallback,
      key = _ref.key,
      href = _ref.href;
  var index = queue.findIndex(function (queueCallback) {
    return queueCallback === wrappedCallback;
  });

  if (index > -1) {
    queue.splice(index, 1);
  }

  if (allQueuesAreEmpty()) {
    removeEventListener(key, href);
  }
};

/**
 * Adds the callback to the queue and adds an event listener if necessary.
 *
 * @author Yury Kuntsou
 * @function addCallback
 * @category Interceptors
 * @param {Object} params - The parameters object containing the required data.
 * @param {ID} params.key - The key of the queue.
 * @param {unknown} params.state - The state of the queue.
 * @param {WrappedCallbackRef} params.wrappedCallback - The wrapped callback reference.
 * @param {HREF} href - The init locale url.
 * @returns {Function} The function which removes the callback from the queue.
 */
export var addCallback = function addCallback(_ref2) {
  var key = _ref2.key,
      wrappedCallback = _ref2.wrappedCallback,
      state = _ref2.state,
      href = _ref2.href;
  var needAddEventListener = allQueuesAreEmpty();
  var queue = getOrCreateQueue(key);
  queue.push(wrappedCallback);

  if (needAddEventListener) {
    addEventListener(key, state);
  }

  return function () {
    return removeCallback({
      href: href,
      key: key,
      queue: queue,
      wrappedCallback: wrappedCallback
    });
  };
};