import { styled as o, FormControl as t, Checkbox as e, InputLabel as r, Box as i, FormHelperText as n, FormControlLabel as a, formControlLabelClasses as s } from "@mui/material";
import "../../../themes/box-shadows/boxShadows.js";
import "../../../themes/dark.theme.js";
import { pxToRem as c } from "../../../themes/default-theme/default-theme.js";
import "../../../themes/gradients/gradients.js";
import "../../../themes/light.theme.js";
var l = o(t)(function (o) {
  return {
    display: o.fullWidth && "flex",
    maxWidth: "100%"
  };
}),
    h = o(e, {
  shouldForwardProp: function (o) {
    return "fullWidth" !== o;
  }
})(function (o) {
  var t,
      e = o.theme;
  return (t = {})["& .MuiSvgIcon-root"] = {
    height: c(20)
  }, t["&.Mui-checked .MuiSvgIcon-root"] = {
    color: e.colors.panPurple
  }, t["&.Mui-checked.Mui-disabled .MuiSvgIcon-root"] = {
    color: e.colors.mischka
  }, t.color = e.colors.estateViolet, t.padding = e.spacing(1.5), t;
}),
    m = o(r)(function (o) {
  var t = o.theme,
      e = o.$color,
      r = {
    color: t.colors.abandonedSpaceship,
    display: "block",
    fontSize: c(16),
    fontWeight: 400,
    lineHeight: c(18),
    marginBottom: t.spacing(.5),
    maxWidth: "100%",
    paddingLeft: t.spacing(1),
    position: "relative",
    transform: "unset",
    transformOrigin: "unset"
  };
  return "bayFog" === e && (r.color = t.colors.white), r;
}),
    d = o(i)(function () {
  return {
    fontSize: c(12),
    left: 0,
    lineHeight: c(14),
    marginTop: c(4),
    position: "absolute",
    right: 0,
    top: "100%"
  };
}),
    u = o(n)(function (o) {
  var t = o.theme,
      e = o.$color,
      r = {
    color: t.colors.abandonedSpaceship,
    display: "block",
    margin: "unset",
    maxWidth: "100%",
    padding: t.spacing(0, 1)
  };
  return "bayFog" === e && (r.color = t.colors.white), r;
}),
    p = o(a)(function (o) {
  var t,
      e = o.theme;
  return (t = {})["&.".concat(s.error)] = {
    color: e.palette.error.main
  }, t;
});
export { d as StyleFromHelperWrapper, h as StyledCheckboxBase, p as StyledCheckboxLabel, l as StyledFormControl, u as StyledFormHelperText, m as StyledInputLabel };