import { pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledBox = styled(Box)(function (_ref) {
  var theme = _ref.theme,
      $isFilled = _ref.$isFilled;
  var styles = {
    backgroundColor: $isFilled ? theme.palette.primary.main : theme.palette.lavenderSyrup.main,
    borderRadius: pxToRem(15),
    height: pxToRem(4)
  };
  return styles;
});
export { StyledBox };