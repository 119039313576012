import { pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledWrapper = styled(Box)(function (_ref) {
  var _theme$palette, _theme$palette$white;

  var theme = _ref.theme;
  var styles = {
    backgroundColor: theme === null || theme === void 0 ? void 0 : (_theme$palette = theme.palette) === null || _theme$palette === void 0 ? void 0 : (_theme$palette$white = _theme$palette.white) === null || _theme$palette$white === void 0 ? void 0 : _theme$palette$white.main,
    borderTopLeftRadius: pxToRem(16),
    borderTopRightRadius: pxToRem(16),
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '60%',
    overflow: 'hidden',
    position: 'fixed',
    width: '100%',
    zIndex: 2
  };
  return styles;
});
var StyledBox = styled(Box)(function (_ref2) {
  var _theme$colors;

  var theme = _ref2.theme;
  var styles = {
    '&:not(:last-child)': {
      borderBottom: "".concat(pxToRem(1), " solid ").concat(theme === null || theme === void 0 ? void 0 : (_theme$colors = theme.colors) === null || _theme$colors === void 0 ? void 0 : _theme$colors.lavenderSyrup)
    },
    padding: theme.spacing(2)
  };
  return styles;
});
var StyledLabel = styled(Box)(function (_ref3) {
  var _theme$palette2, _theme$palette2$alpin;

  var theme = _ref3.theme,
      $bgColor = _ref3.$bgColor;
  var styles = {
    alignItems: 'center',
    backgroundColor: $bgColor || (theme === null || theme === void 0 ? void 0 : (_theme$palette2 = theme.palette) === null || _theme$palette2 === void 0 ? void 0 : (_theme$palette2$alpin = _theme$palette2.alpineGoat) === null || _theme$palette2$alpin === void 0 ? void 0 : _theme$palette2$alpin.main),
    borderRadius: pxToRem(5),
    display: 'flex',
    padding: theme.spacing(0.5, 0.75)
  };
  return styles;
});
var StyledIcon = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    paddingRight: theme.spacing(1.25),
    paddingTop: theme.spacing(0.5),
    textAlign: 'right'
  };
  return styles;
});
var StyledItem = styled(Box)(function (_ref5) {
  var _theme$colors2;

  var theme = _ref5.theme;
  var styles = {
    '&:not(:last-child)': {
      borderBottom: "".concat(pxToRem(2), " solid ").concat(theme === null || theme === void 0 ? void 0 : (_theme$colors2 = theme.colors) === null || _theme$colors2 === void 0 ? void 0 : _theme$colors2.lavenderSyrup)
    }
  };
  return styles;
});
export { StyledBox, StyledIcon, StyledItem, StyledLabel, StyledWrapper };