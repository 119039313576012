import { __assign as i } from "../../_virtual/_tslib.js";
import { styled as e, Typography as r } from "@mui/material";
var t = e(r)(function (e) {
  e.theme;
  var r = e.$maxLine;
  return i({}, r && r && {
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: r,
    display: "-webkit-box",
    overflow: "hidden"
  });
});
export { t as StyledTypography };