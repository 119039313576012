import { pxToRem } from '@pwa/ui';
import { Box, List, ListItem, styled } from '@mui/material';
var StyledList = styled(List)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    padding: '0'
  };
  return styles;
});
var StyledDot = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    flexShrink: 0,
    height: pxToRem(8),
    width: pxToRem(8)
  };
  return styles;
});
var StyledListItem = styled(ListItem)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  };
  return styles;
});
export { StyledDot, StyledList, StyledListItem };