import { SET_MAP_DATA } from './data.action-types';
export var actions = {
  /**
   * set data.
   *
   * @author Christina Kashevar
   * @function mapDataSet
   * @category Actions
   * @subcategory Data
   * @param {DataMapPayload} payload payload.
   */
  mapDataSet: function mapDataSet(payload) {
    return {
      payload: payload,
      type: SET_MAP_DATA
    };
  }
};