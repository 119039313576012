import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { produce } from 'immer';
import { FETCH_USER_FAILURE, FETCH_USER_INFO_FAILURE, FETCH_USER_INFO_REQUEST, FETCH_USER_INFO_SUCCESS, FETCH_USER_REQUEST, FETCH_USER_SUCCESS, LOGOUT_USER_FAILURE, LOGOUT_USER_REQUEST, LOGOUT_USER_SUCCESS } from './auth.action-types';
var initialState = {
  error: null,
  hasAccounts: null,
  hasCurrentAccounts: null,
  hasPackage: null,
  isAllowedNewPackage: null,
  pending: false,
  sessionId: null,
  token: null,
  user: null
};
/**
 * Auth reducer.
 *
 * @author Mikhail Siutsou
 * @category Reducers
 * @subcategory Auth
 * @param {AuthState} state state.
 * @param {ActionsTypes} action action.
 * @return {AuthState} auth state.
 */

export default (function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case FETCH_USER_REQUEST:
      {
        return produce(state, function (draft) {
          draft.pending = true;
        });
      }

    case FETCH_USER_SUCCESS:
      {
        return produce(state, function (draft) {
          draft.pending = false;
          draft.user = action.payload.user;
          draft.error = null;
          draft.token = action.payload.token;
          draft.sessionId = action.payload.sessionId;
          draft.hasAccounts = action.payload.hasAccounts;
          draft.hasPackage = action.payload.hasPackage;
          draft.isAllowedNewPackage = action.payload.isAllowedNewPackage;
          draft.hasCurrentAccounts = action.payload.hasCurrentAccounts;
        });
      }

    case FETCH_USER_FAILURE:
      {
        return produce(state, function (draft) {
          draft.pending = false;
          draft.user = null;
          draft.error = action.payload.error;
          draft.token = null;
          draft.sessionId = null;
          draft.hasAccounts = null;
          draft.hasPackage = null;
          draft.isAllowedNewPackage = null;
          draft.hasCurrentAccounts = null;
        });
      }

    case FETCH_USER_INFO_REQUEST:
      {
        return produce(state, function (draft) {
          draft.pending = true;
        });
      }

    case FETCH_USER_INFO_SUCCESS:
      {
        return produce(state, function (draft) {
          draft.pending = false;
          draft.error = null;
          draft.hasAccounts = action.payload.hasAccounts;
          draft.hasPackage = action.payload.hasPackage;
          draft.isAllowedNewPackage = action.payload.isAllowedNewPackage;
          draft.hasCurrentAccounts = action.payload.hasCurrentAccounts;
        });
      }

    case FETCH_USER_INFO_FAILURE:
      {
        return produce(state, function (draft) {
          draft.pending = false;
          draft.error = action.payload.error;
          draft.hasAccounts = null;
          draft.hasPackage = null;
          draft.isAllowedNewPackage = null;
          draft.hasCurrentAccounts = null;
        });
      }

    case LOGOUT_USER_REQUEST:
      {
        return produce(state, function (draft) {
          draft.pending = true;
        });
      }

    case LOGOUT_USER_SUCCESS:
      {
        return produce(state, function (draft) {
          draft.pending = false;
          draft.user = null;
          draft.error = null;
          draft.token = null;
          draft.sessionId = null;
          draft.hasAccounts = null;
          draft.hasPackage = null;
          draft.isAllowedNewPackage = null;
          draft.hasCurrentAccounts = null;
        });
      }

    case LOGOUT_USER_FAILURE:
      {
        return produce(state, function (draft) {
          draft.pending = false;
        });
      }

    default:
      {
        return _objectSpread({}, state);
      }
  }
});