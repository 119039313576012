import { Currency, PaymentNextStep, PaymentTypeForm, TransactionResults } from '@enums';
import { OneclickPaymentStatuses } from '@types';
import image from '@assets/images/payment.image.png';
export var getOneclickPaymentStartMock = function getOneclickPaymentStartMock() {
  return {
    oneclickTransactionId: '73735689-ac93-448c-8603-1e790654939c'
  };
};
export var getOneclickPaymentListMock = function getOneclickPaymentListMock() {
  return {
    payments: [{
      amount: 10,
      currency: 'BYN',
      description: '291234567',
      formStatus: OneclickPaymentStatuses.INPUT_DONE,
      iconUrl: image,
      summary: [{
        name: 'Фамилия',
        value: 'Пупкин'
      }, {
        name: 'Имя',
        value: 'Серёга'
      }, {
        name: 'Отчество',
        value: 'Серегович'
      }, {
        name: 'Сумма',
        value: '10,00 BYN'
      }],
      title: 'МТС по № телефона 291234567',
      transactionId: '73ebb212-ac93-448c-8603-1e790654939c',
      typeOperation: '2300'
    }, {
      currency: 'BYN',
      description: '2094330099',
      formStatus: OneclickPaymentStatuses.INPUT_REQUIRED,
      iconUrl: image,
      summary: [],
      title: 'Квартплата',
      transactionId: '73ebb212-ac93-448c-8603',
      typeOperation: '2300'
    }, {
      currency: 'BYN',
      description: '291234567',
      errorMessage: 'Данный платеж невозможен',
      formStatus: OneclickPaymentStatuses.ERROR,
      iconUrl: image,
      summary: [],
      title: 'МТС по № телефона 29',
      transactionId: '73ebb212',
      typeOperation: '2300'
    }]
  };
};
export var getOneclickPaymentMock = function getOneclickPaymentMock() {
  return {
    description: ['', 'Введите лицевой счет'],
    iconUrl: '${erip.iconUrlDefault}',
    nextStep: PaymentNextStep.ServiceInfo,
    oneclickTransactionId: '73735689-ac93-448c-8603-1e790654939c',
    parameters: [{
      dataType: 0,
      editable: true,
      id: 1,
      maxLength: 30,
      minLength: 1,
      name: 'Лицевой счет',
      required: false
    }, {
      dataType: 0,
      defaultValue: '24.04.2023',
      editable: false,
      id: 2,
      name: 'Дата выставления',
      required: false
    }, {
      dataType: 3,
      defaultValue: '0523',
      editable: true,
      formatDataType: 'MMYY',
      id: 3,
      maxLength: 4,
      minLength: 4,
      name: 'Период 1 MMYY',
      required: true
    }, {
      dataType: 3,
      defaultValue: '01.05.23',
      editable: true,
      formatDataType: 'DD.MM.YY',
      id: 4,
      maxLength: 8,
      minLength: 8,
      name: 'Период 2 DD.MM.YY',
      required: true
    }, {
      dataType: 3,
      defaultValue: '010523',
      editable: true,
      formatDataType: 'DDMMYY',
      id: 5,
      maxLength: 6,
      minLength: 6,
      name: 'Период 3 DDMMYY',
      required: true
    }, {
      dataType: 3,
      defaultValue: '23',
      editable: true,
      formatDataType: 'YY',
      id: 6,
      maxLength: 2,
      minLength: 2,
      name: 'Период 4 YY',
      required: true
    }, {
      dataType: 3,
      defaultValue: '01.05.2023',
      editable: true,
      formatDataType: 'DD.MM.YYYY',
      id: 7,
      maxLength: 2,
      minLength: 2,
      name: 'Период 5 DD.MM.YYYY',
      required: true
    }, {
      dataType: 0,
      defaultValue: '9273',
      editable: false,
      id: 8,
      name: 'Счётчик 1-предыдущ. знач.',
      required: false
    }, {
      dataType: 2,
      editable: true,
      id: 9,
      maxLength: 11,
      minLength: 0,
      name: 'Счётчик 1-текущее   знач.',
      required: false
    }, {
      dataType: 5,
      defaultValue: 'false',
      editable: true,
      id: 10,
      name: 'Согласие',
      required: false
    }, {
      dataType: 1,
      editable: true,
      id: 11,
      maxLength: 10,
      minLength: 1,
      name: 'Введите количество',
      required: false
    }],
    personalAccount: 'BY13123333',
    serviceName: 'Абонентская плата',
    transactionId: '73ebb212-ac93-448c-8603-1e790654939c'
  };
};
export var getOneclickPaymentPostMock = function getOneclickPaymentPostMock(type) {
  return {
    amount: {
      amount_Budget: 0,
      currency: Currency.BYN,
      defaultValue: 0,
      editable: true,
      fineAmount: 0,
      maxAmount: 200,
      minAmount: 0.5
    },
    description: ['', 'Введите лицевой счет'],
    iconUrl: '${erip.iconUrlDefault}',
    oneclickTransactionId: '73735689-ac93-448c-8603-1e790654939c',
    parameters: [{
      dataType: 0,
      editable: true,
      id: 1,
      maxLength: 30,
      minLength: 1,
      name: 'Лицевой счет',
      required: false
    }],
    personalAccount: 'BY13123333',
    serviceId: 1,
    serviceName: 'Абонентская плата',
    sorCheckRequired: false,
    transactionId: '73ebb212-ac93-448c-8603-1e790654939c',
    typeForm: type || PaymentTypeForm.APPROVE
  };
};
export var getOneclickResponse = function getOneclickResponse() {
  return {
    codeOperation: '8000',
    dataOperation: [{
      id: 1,
      opDetails: 'some details'
    }],
    idTransaction: '1',
    otp: false,
    pin: true
  };
};
export var getOneclickResult = function getOneclickResult() {
  return {
    oneclickTransactionId: '73ebb212-ac93-448c-8603-1e790654939c',
    payments: [{
      amount: '10',
      currency: 'BYN',
      description: '+375(29)806-78-91',
      result: TransactionResults.SUCCESS,
      title: 'Оплата телефона А1 5522'
    }, {
      amount: '10',
      currency: 'BYN',
      description: '+375(29)806-78-91',
      result: TransactionResults.SUCCESS,
      title: 'Оплата телефона А1 552'
    }, {
      amount: '10',
      currency: 'BYN',
      description: '+375(29)806-78-91',
      result: TransactionResults.FAIL,
      title: 'Оплата телефона А1 55'
    }, {
      amount: '10',
      currency: 'BYN',
      description: '+375(29)806-78-91',
      result: TransactionResults.SUCCESS,
      title: 'Оплата телефона А1 5'
    }, {
      amount: '10',
      currency: 'BYN',
      description: '+375(29)806-78-91',
      result: TransactionResults.SUCCESS,
      title: 'Оплата телефона А1'
    }, {
      amount: '10',
      currency: 'BYN',
      description: '+375(29)806-78-91',
      result: TransactionResults.SUCCESS,
      title: 'Оплата телефона А1 55229'
    }, {
      amount: '10',
      currency: 'BYN',
      description: '+375(29)806-78-91',
      result: TransactionResults.SUCCESS,
      title: 'Оплата телефона А1 552299'
    }]
  };
};