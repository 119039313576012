import { pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    backgroundColor: '#fff',
    paddingTop: theme.spacing(3),
    width: '100%'
  };
  return styles;
});
var StyledBox = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    '&:not(:last-child)': {
      borderBottom: "".concat(pxToRem(1), " solid #e7e7f0")
    },
    padding: theme.spacing(2)
  };
  return styles;
});
var StyledLabel = styled(Box)(function (_ref3) {
  var theme = _ref3.theme,
      $bgColor = _ref3.$bgColor;
  var styles = {
    alignItems: 'center',
    backgroundColor: $bgColor || '#f0f0f6',
    borderRadius: pxToRem(5),
    display: 'flex',
    padding: theme.spacing(0.5, 0.75)
  };
  return styles;
});
var StyledIcon = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    paddingRight: theme.spacing(1.25),
    paddingTop: theme.spacing(0.5),
    textAlign: 'right'
  };
  return styles;
});
var StyledLine = styled(Box)(function (_ref5) {
  var theme = _ref5.theme;
  var styles = {
    '&:not(:last-child)': {
      borderBottom: "".concat(pxToRem(1), " solid #e7e7f0")
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    '&:has(li.MuiBox-root)': {
      borderBottom: "".concat(pxToRem(1), " solid #e7e7f0")
    },
    padding: theme.spacing(2)
  };
  return styles;
});
var StyledItem = styled(Box)(function (_ref6) {
  var theme = _ref6.theme;
  var styles = {
    alignItems: 'center',
    border: "".concat(pxToRem(1), " solid #f0f0f6"),
    borderRadius: pxToRem(5),
    display: 'flex',
    padding: theme.spacing(0.3755, 0.75)
  };
  return styles;
});
export { StyledBox, StyledIcon, StyledItem, StyledLabel, StyledLine, StyledWrapper };