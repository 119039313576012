import { __assign as o, __rest as i } from "../../_virtual/_tslib.js";
import { jsx as r, jsxs as t } from "react/jsx-runtime";
import { Stack as n } from "@mui/material";
import { Button as a } from "../button/button.component.js";
import { Icon as l } from "../icon/icon.component.js";
import { StyledWrapper as e, StyledTypography as c } from "./card-action-button.styles.js";

var p = function (p) {
  var d = p.icon,
      s = p.label,
      m = p.onClick,
      b = p.disabled,
      u = p.buttonColor,
      y = void 0 === u ? "primary" : u,
      h = p.iconColor,
      v = void 0 === h ? "abandonedSpaceship" : h,
      C = p.typographyColor,
      f = void 0 === C ? "primary" : C,
      g = p.typographyDisabledColor,
      j = void 0 === g ? "purpleIllusion" : g,
      k = p.disabledOpacity,
      w = void 0 === k ? "1" : k,
      x = i(p, ["icon", "label", "onClick", "disabled", "buttonColor", "iconColor", "typographyColor", "typographyDisabledColor", "disabledOpacity"]);
  return r(e, {
    children: t(n, o({
      alignItems: "center",
      spacing: 1
    }, {
      children: [r(a, o({
        color: y,
        disableElevation: !0,
        disabled: b,
        variant: "contained",
        view: "square",
        onClick: m
      }, x, {
        children: r(l, {
          color: v,
          height: 24,
          src: d,
          width: 24
        })
      })), r(c, o({
        $opacity: b ? w : "1",
        color: b ? j : f,
        fontWeight: 500,
        variant: "caption1"
      }, {
        children: s
      }))]
    }))
  });
},
    d = function (i) {
  return r(p, o({}, i));
};

export { d as CardActionButton };