import { __rest as r, __assign as e, __spreadArray as o, __read as n } from "../../../_virtual/_tslib.js";
import { jsxs as t, jsx as i } from "react/jsx-runtime";
import { useRef as a, useEffect as c } from "react";
import { Box as l, Stack as u } from "@mui/material";
import "../../../enums/entities/entities.enum.js";
import { EventKey as s } from "../../../enums/keyboard/keyboard.enum.js";
import "../../../enums/theme/theme.enum.js";
import { Icon as m } from "../../icon/icon.component.js";
import { colors as p } from "../../../themes/colors/colors.js";
import { StyledInputLabel as f, StyleFromHelperWrapper as d, StyledFormHelperErrorText as h, StyledFormHelperText as v, StyledBox as g, StyledStack as b, StyledButton as w } from "./keyboard-pin-field.styles.js";
import x from "../../../packages/pwa-ui/assets/images/icons/back-keyboard.image.svg.js";

var k = function (k) {
  var y = k.value,
      j = k.name,
      C = k.error,
      F = k.fields,
      S = void 0 === F ? 6 : F,
      A = k.onChange;
  k.onFocus, k.onBlur;
  var B = k.onComplete,
      D = k.bottomLabel,
      E = k.label,
      O = k.isShowError,
      P = void 0 === O || O;
  k.clearPlaceholderOnFocus, k.isSeparate, k.autoFocus;

  var L = r(k, ["value", "name", "error", "fields", "onChange", "onFocus", "onBlur", "onComplete", "bottomLabel", "label", "isShowError", "clearPlaceholderOnFocus", "isSeparate", "autoFocus"]),
      T = Array.from(Array(S), function () {
    return a(null);
  }),
      $ = j || "pinfield",
      _ = k.value || Array.from(Array(S), function () {
    return "";
  }),
      R = P && !!C;

  c(function () {
    B && y && "" !== y[y.length - 1] && B(y);
  }, [y]);

  var V = function (r) {
    return function () {
      if (A) {
        var e = o([], n(_), !1),
            t = e.indexOf("");

        if (window.navigator.vibrate && window.navigator.vibrate(100), t > -1 && (e[t] = r), r === s.Backspace) {
          var i = o([], n(_), !1);
          if (0 === t) return;
          -1 === t ? i[i.length - 1] = "" : i[t - 1] = "", A(i);
        } else A(e);
      }
    };
  };

  return t(l, e({
    mt: 1.5
  }, {
    children: [E && i(f, e({
      $color: L.color,
      htmlFor: $,
      shrink: !0
    }, {
      children: E
    })), t(l, e({
      position: "relative"
    }, {
      children: [i(u, e({
        direction: "row",
        justifyContent: "center",
        spacing: 2
      }, {
        children: T.map(function (r, e) {
          return i(l, {
            bgcolor: _[e] ? p.panPurple : p.estateViolet,
            borderRadius: "100px",
            height: "10px",
            width: "10px"
          }, "".concat($, "-").concat(e));
        })
      })), R && i(d, {
        children: i(h, e({
          id: "".concat($, "-error-text")
        }, {
          children: C
        }))
      }), D && !R ? i(d, {
        children: "string" == typeof D ? i(v, e({
          $color: L.color
        }, {
          children: D
        })) : D
      }) : null]
    })), i(g, {
      children: t(b, e({
        direction: "row",
        flexWrap: "wrap"
      }, {
        children: [Array.from({
          length: 9
        }, function (r, e) {
          return e + 1;
        }).map(function (r, o) {
          return i(w, e({
            variant: "text",
            onClick: function (e) {
              e.preventDefault(), V(r.toString())();
            },
            onTouchEnd: function (e) {
              e.preventDefault(), V(r.toString())();
            }
          }, {
            children: r
          }), r);
        }), i(l, {
          height: "92px",
          width: "92px"
        }), i(w, e({
          variant: "text",
          onClick: function (r) {
            r.preventDefault(), V("0")();
          },
          onTouchEnd: function (r) {
            r.preventDefault(), V("0")();
          }
        }, {
          children: "0"
        })), i(w, e({
          variant: "text",
          onClick: V(s.Backspace)
        }, {
          children: i(m, {
            height: 28,
            src: x,
            width: 40
          })
        }))]
      }))
    })]
  }));
};

export { k as KeyboardPinField };