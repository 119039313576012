import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
var _excluded = ["allPaymentsNumber"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

/**
 * Handle 'check all' button.
 *
 * @author Christina Kashevar
 * @function checkAllFields
 * @category helpers
 * @param {[boolean]} values field.
 * @param {MutableState} MutableState from final form.
 * @param {Tools} Tools from final form.
 * @return {void} return.
 */
var checkAllFields = function checkAllFields(values, MutableState, Tools) {
  var fields = MutableState.fields;

  for (var field in fields) {
    Tools.changeValue(MutableState, field, function () {
      return values[0];
    });
  }
};
/**
 * Handle uncheck 'check all' button when any check is false.
 *
 * @author Christina Kashevar
 * @function uncheckAllFields
 * @category helpers
 * @param {[boolean]} values field.
 * @param {MutableState} MutableState from final form.
 * @param {Tools} Tools from final form.
 * @return {void} return.
 */


var uncheckAllFields = function uncheckAllFields(values, MutableState, Tools) {
  var _values = _slicedToArray(values, 3),
      currentCheckValue = _values[0],
      value = _values[1],
      id = _values[2];

  var allPaymentsNumber = value.allPaymentsNumber,
      allCheckValues = _objectWithoutProperties(value, _excluded);

  if (!currentCheckValue && allCheckValues.all) {
    Tools.changeValue(MutableState, 'all', function () {
      return false;
    });
  }

  var updatedValues = _objectSpread(_objectSpread({}, allCheckValues), {}, _defineProperty({}, "".concat(id, "-checkbox"), currentCheckValue));

  delete updatedValues.all;
  var checkedValuesNumber = Object.values(updatedValues).filter(function (value) {
    return Boolean(value);
  }).length;

  if (allPaymentsNumber === checkedValuesNumber) {
    Tools.changeValue(MutableState, 'all', function () {
      return true;
    });
  }
};

export { checkAllFields, uncheckAllFields };