import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { pxToRem } from '@pwa/ui';
import { Box, Link, styled } from '@mui/material';
var StyledContentWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(2)
  }, theme.breakpoints.up('desktop'), {
    display: 'block',
    maxWidth: theme.typography.pxToRem(452),
    paddingTop: theme.spacing(4)
  });

  return styles;
});
var StyledButtonWrapper = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    backgroundColor: theme.palette.white.main,
    borderRadius: pxToRem(16)
  };
  return styles;
});
var StyledBoxWrapper = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    alignItems: 'center',
    borderBottom: "1px solid ".concat(theme.palette.lavenderSyrup.main),
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    width: '100%'
  };
  return styles;
});
var StyledLink = styled(Link)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    color: theme.colors.panPurple,
    fontSize: pxToRem(14),
    fontWeight: 500,
    lineHeight: pxToRem(16),
    textDecoration: 'none',
    underline: 'none'
  };
  return styles;
});
export { StyledBoxWrapper, StyledButtonWrapper, StyledContentWrapper, StyledLink };