import { __rest as r, __assign as t } from "../../_virtual/_tslib.js";
import { jsx as o } from "react/jsx-runtime";
import * as a from "react";
import { StyledButtonGroup as i } from "./button-group.styles.js";
var p = a.forwardRef(function (a, p) {
  var s = a.spacing,
      m = r(a, ["spacing"]);
  return o(i, t({
    ref: p,
    $spacing: s
  }, m));
});
p.displayName = "ButtonGroup";
export { p as ButtonGroup };