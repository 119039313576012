import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { utc } from 'moment';
import { AuthService, http } from '@services';

/**
 * Adds the Authorization and Session-ID headers to an Axios request.
 *
 * @author Yury Kuntsou
 * @function AuthHeaderRequestInterception
 * @category Interceptors
 * @param {AxiosRequestConfig} request - The request to add the headers to.
 * @returns {AxiosRequestConfig} The request with the added headers.
 */
export var AuthHeaderRequestInterception = function AuthHeaderRequestInterception(request) {
  var token = AuthService.getTokenSessionStorage();
  var sessionId = AuthService.getSessionIdSessionStorage();

  if (request && request.headers) {
    if (!request.headers.Authorization && token) {
      request.headers.Authorization = token ? "Bearer ".concat(token) : false;
    }

    if (!request.headers['Session-ID'] && sessionId) {
      request.headers['Session-ID'] = sessionId;
    }
  }

  return request;
};
/**
 * Sets the base URL of an Axios request based on its prefixURL.
 *
 * @author Yury Kuntsou
 * @function RequestInterceptor
 * @category Interceptors
 * @param {AxiosRequestConfig} request - The request to set the base URL for.
 * @returns {AxiosRequestConfig} The request with the modified base URL.
 */

export var BaseURLRequestInterception = function BaseURLRequestInterception(request) {
  if (request.prefixURL) {
    var baseURL = request.baseURL || '';
    request.baseURL = "".concat(baseURL).concat(request.prefixURL);
  }

  return request;
};
/* eslint-disable @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment */

/**
 * Transforms the params of an Axios request from arrays of objects into strings.
 *
 * @author Yury Kuntsou
 * @function TransformParamsRequestInterception
 * @category Interceptors
 * @param {AxiosRequestConfig} request - The request to transform the params for.
 * @returns {AxiosRequestConfig} The request with the transformed params.
 */

export var TransformParamsRequestInterception = function TransformParamsRequestInterception(request) {
  if (request.params) {
    Object.keys(request.params).forEach(function (key) {
      if (Array.isArray(request.params[key])) {
        var values = request.params[key].map(function (obj) {
          return Object.entries(obj).map(function (_ref) {
            var _ref2 = _slicedToArray(_ref, 2),
                k = _ref2[0],
                v = _ref2[1];

            return v === null || typeof v === 'undefined' || !String(v).length ? '' : "".concat(k, ":").concat(v);
          }).join(' ');
        });
        var filteredValues = values.filter(function (value) {
          return value !== '';
        });

        if (filteredValues.length > 0) {
          request.params[key] = filteredValues.join(';');
        } else {
          delete request.params[key]; // Удаление ключа, если нет значений
        }
      }
    });
  }

  return request;
};
/* eslint-enable @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment */

/**
 * Aborts any existing requests with the same URL as the current request.
 *
 * @author Yury Kuntsou
 * @function StackRequestInterception
 * @category Interceptors
 * @param {AxiosRequestConfig} request - The request to abort existing requests for.
 * @returns {AxiosRequestConfig} The request with the updated stackRequest.
 */

export var StackRequestInterception = function StackRequestInterception(request) {
  var fullUrl = request.url ? http.getUri(request) : 'error';
  var controller = request.abortController || new AbortController();

  if (!request.abortController) {
    request.signal = controller.signal;
  }

  if (request !== null && request !== void 0 && request.stackRequest) {
    if (request.stackRequest.has(fullUrl)) {
      var _request$stackRequest;

      (_request$stackRequest = request.stackRequest.get(fullUrl)) === null || _request$stackRequest === void 0 ? void 0 : _request$stackRequest.abort();
      request.stackRequest["delete"](fullUrl);
    }

    request.stackRequest.set(fullUrl, controller);
  }

  return request;
};
/**
 * Adds the user Local time header to an Axios request.
 *
 * @author Yury Kuntsou
 * @function LocalTimeHeaderRequestInterception
 * @category Interceptors
 * @param {AxiosRequestConfig} request - The request to add the headers to.
 * @returns {AxiosRequestConfig} The request with the added headers.
 */

export var LocalTimeHeaderRequestInterception = function LocalTimeHeaderRequestInterception(request) {
  if (request && request.headers) {
    var localTime = utc().local().format();
    request.headers['Local-Time'] = localTime;
  }

  return request;
};