import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
/**
 * get query params hook.
 *
 * @author Yury Kuntsou
 * @function useQueryParam
 * @category hook
 * @param {T} value query key.
 * @return {[value: T | undefined, (newQuery: T, options?: NavigateOptions) => void]} return query key value and handler change query value.
 */

var useQueryParam = function useQueryParam(key) {
  var _useSearchParams = useSearchParams(),
      _useSearchParams2 = _slicedToArray(_useSearchParams, 2),
      search = _useSearchParams2[0],
      setSearch = _useSearchParams2[1];

  var paramValue = search.get(key);
  var transformedSearch = useMemo(function () {
    var searchAsObject = JSON.stringify(Object.fromEntries(new URLSearchParams(search))[key]);
    return searchAsObject ? JSON.parse(searchAsObject) : undefined; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramValue]);
  var handleSetSearchValue = useCallback(function (newValue, options) {
    var newSearchParams = new URLSearchParams(search);
    newSearchParams.set(key, JSON.stringify(newValue));
    setSearch(newSearchParams, options);
  }, [key, search, setSearch]);
  return [transformedSearch, handleSetSearchValue];
};

export { useQueryParam };