import { pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
import { CardAccordionLimits } from './card-accordion-limits';
var StyledBox = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    '&:first-child': {
      borderTop: "1px solid ".concat(theme.palette.lavenderSyrup.main),
      paddingTop: theme.spacing(2)
    },
    padding: theme.spacing(0, 2)
  };
  return styles;
});
var StyledAccordion = styled(CardAccordionLimits)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    '& .MuiAccordionDetails-root': {
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(0)
    },
    '& > .MuiAccordionSummary-root': {
      padding: theme.spacing(2, 2)
    },
    backgroundColor: theme.palette.white.main,
    borderRadius: pxToRem(16)
  };
  return styles;
});
export { StyledAccordion, StyledBox };