import * as yup from 'yup';
import { i18n } from '@localization';

var getPhoneSchema = function getPhoneSchema(initNumber) {
  return yup.object().shape({
    checkAgreement: yup.bool().equals([true]),
    phone: yup.string().matches(/^(25|29|33|44)[0-9]{3}[0-9]{2}[0-9]{2}$/, i18n.t('forms.validation.smsNotificationPhoneNumber')).test('phone-unique', i18n.t('forms.validation.smsNotificationExistNumber'), function (value) {
      if (initNumber) {
        return value !== initNumber;
      }

      return true;
    }).required(i18n.t('forms.validation.smsNotificationPhoneNumber'))
  });
};

export { getPhoneSchema };