import { styled as o, Button as i, alpha as l } from "@mui/material";
import "../../themes/box-shadows/boxShadows.js";
import "../../themes/dark.theme.js";
import { pxToRem as n } from "../../themes/default-theme/default-theme.js";
import "../../themes/gradients/gradients.js";
import "../../themes/light.theme.js";
var d = o(i)(function (o) {
  var i,
      d,
      e,
      t,
      a,
      c,
      r,
      s,
      u,
      v,
      h,
      b,
      g,
      p,
      m,
      w,
      S,
      k,
      x,
      f,
      W,
      C,
      z,
      y,
      j,
      R,
      $,
      F,
      G,
      H,
      M = o.theme,
      P = o.color,
      V = o.disabled,
      L = o.variant,
      N = void 0 === L ? "text" : L,
      q = o.size,
      B = o.$indent,
      E = o.$center,
      I = o.$view,
      T = o.disableElevation,
      U = o.$disableGutters,
      A = o.$justifyContent,
      D = !!B && (!0 === B ? 4 : B),
      J = "large" === q ? 2 : 1,
      K = ((i = {
    borderRadius: n(10),
    boxShadow: !T && "0 ".concat(n(5), " ").concat(n(15), " ").concat(l(M.colors.independence, .05)),
    display: E && "flex",
    fontSize: n(14),
    fontWeight: 500,
    justifyContent: A,
    letterSpacing: n(-.02),
    lineHeight: n(16),
    marginLeft: E && "auto",
    marginRight: E && "auto",
    maxWidth: D && "calc(100% - ".concat(M.spacing(D), ")"),
    minWidth: n(56),
    padding: U ? "unset" : M.spacing(1.25, 2),
    textTransform: "none"
  })[M.breakpoints.up("desktop")] = {
    maxWidth: D ? "100%" : void 0
  }, i);

  switch (!0) {
    case !q || "medium" === q:
      K.height = n(42), K.minWidth = n(160), K.borderRadius = n(12);
      break;

    case "large" === q:
      K.height = n(56), K.minWidth = n(200), K.fontSize = n(16), K.lineHeight = n(18), K.letterSpacing = n(-.05), K.borderRadius = n(16);
      break;

    case "extraLarge" === q:
      K.height = n(60), K.fontSize = n(16), K.lineHeight = n(18), K.letterSpacing = n(-.05), K.borderRadius = n(16);
      break;

    case "extraSmall" === q:
      K.padding = "".concat(n(2), " ").concat(n(8)), K.fontSize = n(12), K.lineHeight = n(16), K.letterSpacing = "unset", K.minWidth = "unset", K.borderRadius = n(8);
  }

  switch (!0) {
    case !P || "primary" === P:
      V ? ("contained" === N && (K["&.Mui-disabled"] = {
        backgroundColor: null === (d = null == M ? void 0 : M.colors) || void 0 === d ? void 0 : d.purpleIllusion,
        color: null === (e = null == M ? void 0 : M.colors) || void 0 === e ? void 0 : e.white
      }), "text" === N && (K["&.Mui-disabled"] = {
        color: null === (t = null == M ? void 0 : M.colors) || void 0 === t ? void 0 : t.estateViolet
      })) : "contained" !== N || T || (K.boxShadow = "0 ".concat(n(5), " ").concat(n(20), " ").concat(l(null === (a = null == M ? void 0 : M.colors) || void 0 === a ? void 0 : a.panPurple, .24)), K["&:hover"] = {
        boxShadow: "0 ".concat(n(5), " ").concat(n(20), " ").concat(l(null === (c = null == M ? void 0 : M.colors) || void 0 === c ? void 0 : c.panPurple, .24))
      });
      break;

    case "carteBlanche" === P:
      K.color = null === (r = null == M ? void 0 : M.colors) || void 0 === r ? void 0 : r.panPurple, V ? "contained" === N && (K["&.Mui-disabled"] = {
        backgroundColor: null === (s = null == M ? void 0 : M.colors) || void 0 === s ? void 0 : s.alpineGoat,
        color: null === (u = null == M ? void 0 : M.colors) || void 0 === u ? void 0 : u.bayFog
      }) : "contained" === N && (K["&:hover"] = {
        backgroundColor: null === (v = null == M ? void 0 : M.colors) || void 0 === v ? void 0 : v.panPurple,
        color: null === (h = null == M ? void 0 : M.colors) || void 0 === h ? void 0 : h.white
      });
      break;

    case "error" === P:
      V ? "contained" === N && (K["&.Mui-disabled"] = {
        backgroundColor: null === (b = null == M ? void 0 : M.colors) || void 0 === b ? void 0 : b.translucentUnicorn,
        color: null === (g = null == M ? void 0 : M.colors) || void 0 === g ? void 0 : g.white
      }) : "contained" !== N || T || (K.boxShadow = "0 ".concat(n(5), " ").concat(n(20), " ").concat(l(null === (p = null == M ? void 0 : M.colors) || void 0 === p ? void 0 : p.clownNose, .24)), K["&:hover"] = {
        boxShadow: "0 ".concat(n(5), " ").concat(n(20), " ").concat(l(null === (m = null == M ? void 0 : M.colors) || void 0 === m ? void 0 : m.clownNose, .24))
      });
      break;

    case "white" === P:
      K.color = null === (w = null == M ? void 0 : M.colors) || void 0 === w ? void 0 : w.abandonedSpaceship, V && (K.color = null === (S = null == M ? void 0 : M.colors) || void 0 === S ? void 0 : S.estateViolet), "contained" !== N || T || (K.boxShadow = M.boxShadows.independence, K["&:hover"] = {
        boxShadow: M.boxShadows.independence
      }), "outlined" === N && (K.border = "".concat(n(J), " solid ").concat(null === (k = null == M ? void 0 : M.colors) || void 0 === k ? void 0 : k.estateViolet), K["&:hover"] = {
        backgroundColor: null === (x = null == M ? void 0 : M.colors) || void 0 === x ? void 0 : x.ghostWhite,
        border: "".concat(n(J), " solid ").concat(null === (f = null == M ? void 0 : M.colors) || void 0 === f ? void 0 : f.estateViolet)
      });
      break;

    case "bayFog" === P:
      K.color = null === (W = null == M ? void 0 : M.colors) || void 0 === W ? void 0 : W.bayFog, "outlined" === N && (K.border = "".concat(n(J), " solid ").concat(null === (C = null == M ? void 0 : M.colors) || void 0 === C ? void 0 : C.bayFog)), "contained" === N && (K.color = M.colors.white);
      break;

    case "alpineGoat" === P:
      K.color = null === (z = null == M ? void 0 : M.colors) || void 0 === z ? void 0 : z.abandonedSpaceship, "contained" === N && (K.backgroundColor = null === (y = null == M ? void 0 : M.colors) || void 0 === y ? void 0 : y.whiteSolid), "outlined" === N && (K.border = "".concat(n(J), " solid ").concat(null === (j = null == M ? void 0 : M.colors) || void 0 === j ? void 0 : j.alpineGoat));
      break;

    case "ghostWhite" === P:
      K.color = null === (R = null == M ? void 0 : M.colors) || void 0 === R ? void 0 : R.abandonedSpaceship, "contained" === N && (K.backgroundColor = null === ($ = null == M ? void 0 : M.colors) || void 0 === $ ? void 0 : $.ghostWhite), "outlined" === N && (K.border = "".concat(n(J), " solid ").concat(null === (F = null == M ? void 0 : M.colors) || void 0 === F ? void 0 : F.ghostWhite));
      break;

    case "nightWizard" === P:
      "contained" === N && (K.backgroundColor = null === (G = null == M ? void 0 : M.colors) || void 0 === G ? void 0 : G.nightWizard, V && (K.backgroundColor = "".concat(l(M.colors.nightWizard, .35)))), "outlined" === N && (K.border = "".concat(n(J), " solid ").concat(null === (H = null == M ? void 0 : M.colors) || void 0 === H ? void 0 : H.nightWizard));
  }

  if (!0 == ("square" === I)) K.width = K.height, K.minWidth = "unset";
  return K;
});
export { d as StyledButton };