import { SMSInfoState } from '@views/components/card/card-management-view/components/card-controls/card-controls.types';
export var getCardsServicesMock = function getCardsServicesMock() {
  return {
    '3ds': true,
    '3dsPhoneNumber': '291234567',
    smsInfo: true,
    smsInfoPhoneNumber: '291234567',
    smsInfoState: SMSInfoState.connected
  };
};
export var getCardsSMSInfoMock = function getCardsSMSInfoMock() {
  return {
    currency: 'BYN',
    feeAmount: 2.5,
    phoneNumber: '291234567',
    price: 2.5,
    reconnectFee: 1.33
  };
};