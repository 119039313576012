import * as yup from 'yup';

var getCardActivationSchema = function getCardActivationSchema() {
  return yup.object().shape({
    checkAgreement: yup.bool().when('addSMS', {
      is: true,
      then: yup.bool().equals([true])
    })
  });
};

export { getCardActivationSchema };