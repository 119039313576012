import InfiniteScroll from 'react-infinite-scroller';
import { styled } from '@mui/material';
var StyledInfiniteScrollList = styled(InfiniteScroll)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    '& > * + *:last-child': {
      marginTop: theme.spacing(3)
    },
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  };
  return styles;
});
export { StyledInfiniteScrollList };