import { __assign as r } from "../../../_virtual/_tslib.js";
import { jsxs as o, jsx as i } from "react/jsx-runtime";
import { Box as e } from "@mui/material";
import { Button as t } from "../../button/button.component.js";
import { StyledFormControl as l, StyledInputLabel as n, StyledGroupBase as u, StyledRadioLabel as c, StyleFromHelperWrapper as a, StyledFormHelperErrorText as d, StyledFormHelperText as m } from "./radio-buttons-field.styles.js";

function f(f) {
  var h = f.name,
      s = f.fullWidth,
      v = f.color,
      b = void 0 === v ? "white" : v,
      p = f.bottomLabel,
      F = f.error,
      j = f.focused,
      w = f.items,
      x = f.onChange,
      y = f.onFocus,
      C = f.onBlur,
      g = f.value,
      k = f.label,
      z = h && "".concat(h, "-radio-button-field"),
      B = !!F,
      I = {
    errorId: z && "".concat(z, "-error-text"),
    htmlFor: z,
    id: z
  };
  return o(l, r({
    error: B,
    focused: j,
    fullWidth: s
  }, {
    children: [k && i(n, r({
      $color: b,
      htmlFor: I.htmlFor,
      shrink: !0
    }, {
      children: k
    })), i(e, {
      children: i(u, r({
        "aria-describedby": z,
        autoComplete: "off",
        id: I.id,
        name: h,
        onBlur: function (r) {
          C && C(r);
        },
        onFocus: function (r) {
          y && y(r);
        }
      }, {
        children: null == w ? void 0 : w.map(function (o) {
          var e;
          return i(c, {
            control: i(t, r({
              color: g === o.value ? "primary" : b,
              size: o.size || "medium",
              variant: "contained",
              view: o.view,
              onClick: function () {
                x && x(o.value);
              }
            }, {
              children: o.title
            })),
            label: "",
            value: o.value
          }, String(null !== (e = o.value) && void 0 !== e ? e : o.title));
        })
      }))
    }), B && i(a, {
      children: i(d, r({
        id: I.errorId
      }, {
        children: F
      }))
    }), p && !B ? i(a, {
      children: i(m, r({
        $color: b
      }, {
        children: p
      }))
    }) : null]
  }));
}

export { f as RadioButtonsField };