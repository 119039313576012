import { IconButton, pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledIconButton = styled(IconButton)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    backgroundColor: theme.palette.whiteSolid.main,
    borderRadius: pxToRem(5),
    paddingLeft: theme.spacing(2.625),
    paddingRight: theme.spacing(2.625)
  };
  return styles;
});
var StyledBox = styled(Box)(function (_ref2) {
  var _theme$colors, _theme$colors2;

  var theme = _ref2.theme,
      $isAddOpenCardButton = _ref2.$isAddOpenCardButton;
  var styles = {
    borderBottom: "".concat(pxToRem(1), " solid ").concat(theme === null || theme === void 0 ? void 0 : (_theme$colors = theme.colors) === null || _theme$colors === void 0 ? void 0 : _theme$colors.lavenderSyrup),
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    '&:last-child': {
      borderBottom: !$isAddOpenCardButton ? 'none' : "".concat(pxToRem(1), " solid ").concat(theme === null || theme === void 0 ? void 0 : (_theme$colors2 = theme.colors) === null || _theme$colors2 === void 0 ? void 0 : _theme$colors2.lavenderSyrup)
    },
    marginLeft: theme.spacing(2)
  };
  return styles;
});
var StyledBoxWrapper = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    padding: theme.spacing(2, 0)
  };
  return styles;
});
var StyledContent = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    margin: theme.spacing(0, -2)
  };
  return styles;
});
var StyledCardsWrapper = styled(Box)(function (_ref5) {
  var _theme$colors3;

  var theme = _ref5.theme;
  var styles = {
    borderTop: "".concat(pxToRem(6), " solid ").concat(theme === null || theme === void 0 ? void 0 : (_theme$colors3 = theme.colors) === null || _theme$colors3 === void 0 ? void 0 : _theme$colors3.whiteSolid)
  };
  return styles;
});
export { StyledBox, StyledBoxWrapper, StyledCardsWrapper, StyledContent, StyledIconButton };