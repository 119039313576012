import { __rest as o } from "../../../_virtual/_tslib.js";
import { styled as e, Box as t, Stack as r, FormControl as i, InputLabel as n, FormHelperText as a, Select as l, alpha as s, MenuItem as c } from "@mui/material";
import "react";
import { getHexColor as d } from "../../../helpers/colors/colors.helper.js";
import "../../../enums/entities/entities.enum.js";
import "../../../enums/keyboard/keyboard.enum.js";
import "../../../enums/theme/theme.enum.js";
import "../../../node_modules/@babel/runtime/helpers/typeof.js";
import { Button as u } from "../../button/button.component.js";
import { Icon as p } from "../../icon/icon.component.js";
import { Typography as m } from "../../typography/typography.component.js";
import "../../../themes/box-shadows/boxShadows.js";
import "../../../themes/dark.theme.js";
import { pxToRem as h } from "../../../themes/default-theme/default-theme.js";
import "../../../themes/gradients/gradients.js";
import "../../../themes/light.theme.js";

var g = e(t)(function (o) {
  var e = o.theme;
  return {
    backgroundColor: e.colors.carteBlanche,
    borderRadius: h(2),
    height: h(3),
    margin: e.spacing(1, "auto", 0, "auto"),
    opacity: "50%",
    width: h(60)
  };
}),
    f = e(t)(function (o) {
  var e = o.theme;
  return o.$searchListField, {
    display: "flex",
    flexDirection: "column",
    maxHeight: e.convertToDVH(100)
  };
}),
    b = e(r)(function (o) {
  return {
    padding: o.theme.spacing(2),
    width: "100%"
  };
}),
    v = e(t)(function (o) {
  return {
    padding: o.theme.spacing(3, 2, 0),
    textAlign: "center"
  };
}),
    y = e(i)(function (o) {
  return {
    display: o.fullWidth && "flex",
    maxWidth: "100%"
  };
}),
    x = e(n)(function (o) {
  var e = o.theme,
      t = o.$color,
      r = {
    color: e.colors.abandonedSpaceship,
    display: "block",
    fontSize: h(16),
    fontWeight: 400,
    lineHeight: h(18),
    marginBottom: e.spacing(.5),
    maxWidth: "100%",
    paddingLeft: e.spacing(1),
    position: "relative",
    transform: "unset",
    transformOrigin: "unset"
  };
  return "bayFog" === t && (r.color = e.colors.white), r;
}),
    k = e(t)(function (o) {
  return o.theme, {
    fontSize: h(12),
    left: 0,
    lineHeight: h(14),
    marginTop: h(4),
    position: "absolute",
    right: 0,
    top: "100%"
  };
}),
    j = e(a)(function (o) {
  var e = o.theme;
  return {
    color: e.palette.error.main,
    display: "block",
    margin: "unset",
    maxWidth: "100%",
    padding: e.spacing(0, 1)
  };
}),
    w = e(l)(function (o) {
  var e = o.theme,
      t = o.color,
      r = d(e.palette, t),
      i = {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    },
    "& .MuiSvgIcon-root svg": {
      stroke: e.colors.abandonedSpaceship
    },
    "&&& .MuiInputBase-input": {
      alignItems: "center",
      boxSizing: "border-box",
      display: "flex",
      fontSize: h(16),
      fontWeight: 500,
      height: h(56),
      lineHeight: h(18),
      padding: e.spacing(2, 2, 2, 1.5),
      position: "relative"
    },
    "&.Mui-error": {
      borderColor: e.palette.error.main
    },
    "&.Mui-focused": {
      borderColor: e.palette.primary.main
    },
    backgroundColor: r,
    border: r && "".concat(h(1), " solid ").concat(r),
    borderRadius: h(12),
    boxSizing: "border-box",
    color: e.colors.nightWizard,
    fontSize: h(14),
    height: h(56),
    lineHeight: h(24),
    overflow: "hidden",
    padding: 0,
    position: "relative",
    transition: e.transitions.create(["border-color"])
  };

  switch (t) {
    case "secondary":
      i.backgroundColor = e.colors.ghostWhite, i.borderColor = e.colors.ghostWhite;
      break;

    case "bayFog":
      i.backgroundColor = s(e.colors.ghostWhite, .05), i.borderColor = s(e.colors.white, .1), i.color = e.colors.white, i["& .MuiSvgIcon-root svg"] = {
        stroke: e.colors.estateViolet
      };
  }

  return i;
}),
    C = e(p)(function (e) {
  var t = e.theme,
      r = e.height;
  return e.color, o(e, ["theme", "height", "color"]), {
    display: "flex",
    pointerEvents: "none",
    position: "absolute",
    right: h(16),
    top: "calc(50% - ".concat(h(r / 2), ")"),
    transition: t.transitions.create(["transform"]),
    zIndex: 1
  };
}),
    S = function (o, e) {
  return {
    borderRadius: h(12),
    display: o ? "none" : "block",
    transform: "translateY(4px)"
  };
},
    W = function (o) {
  return {
    li: {
      "&.Mui-selected": {
        backgroundColor: null == o ? void 0 : o.colors.alpineGoat
      },
      "&.Mui-selected:hover": {
        backgroundColor: null == o ? void 0 : o.colors.alpineGoat
      },
      ":last-child": {
        marginBottom: null == o ? void 0 : o.spacing(0)
      },
      borderRadius: h(12),
      marginBottom: null == o ? void 0 : o.spacing(.5),
      padding: null == o ? void 0 : o.spacing(1.25, 1.5)
    },
    padding: null == o ? void 0 : o.spacing(2, 1.5)
  };
},
    $ = e(u, {
  shouldForwardProp: function (o) {
    return "active" !== o && "$isDefault" !== o;
  }
})(function (o) {
  var e = o.theme,
      t = o.active,
      r = o.$isDefault;
  return {
    ":hover": {
      backgroundColor: null == e ? void 0 : e.colors.alpineGoat
    },
    backgroundColor: t ? null == e ? void 0 : e.colors.alpineGoat : void 0,
    display: r ? "none" : "inline-flex",
    justifyContent: "flex-start",
    padding: e.spacing(2)
  };
}),
    z = e(c, {
  shouldForwardProp: function (o) {
    return "$isDefault" !== o;
  }
})(function (o) {
  return {
    display: o.$isDefault ? "none" : "block"
  };
}),
    D = e(c, {
  shouldForwardProp: function (o) {
    return "$isDefault" !== o;
  }
})(function (o) {
  return o.theme, {
    alignItems: "center",
    color: "red",
    display: o.$isDefault ? "none" : "flex"
  };
}),
    F = e(p)(function (o) {
  return {
    marginRight: o.theme.spacing(1)
  };
}),
    M = e(a)(function (o) {
  var e = o.theme,
      t = o.$color,
      r = {
    color: e.colors.abandonedSpaceship,
    display: "block",
    margin: "unset",
    maxWidth: "100%",
    padding: e.spacing(0, 1)
  };
  return "bayFog" === t && (r.color = e.colors.white), r;
}),
    I = e(m)(function (o) {
  var e,
      t,
      r = o.theme,
      i = o.$color,
      n = {
    color: s(null === (e = null == r ? void 0 : r.colors) || void 0 === e ? void 0 : e.nightWizard, .4)
  };
  return "bayFog" === i && (n.color = s(null === (t = null == r ? void 0 : r.colors) || void 0 === t ? void 0 : t.white, .4)), n;
});

export { f as DrawerContainer, b as ItemsContainer, g as Puller, W as SelectMenuStyles, S as SelectPaperStyles, k as StyleFromHelperWrapper, $ as StyledButton, y as StyledFormControl, j as StyledFormHelperErrorText, M as StyledFormHelperText, F as StyledIcon, x as StyledInputLabel, z as StyledMenuItem, D as StyledMenuItemWithIcon, I as StyledPlaceholder, w as StyledSelectBase, C as StyledSelectIcon, v as StyledTitle };