import { RemoteImage, Typography } from '@pwa/ui';
import { alpha, Box, Button, styled } from '@mui/material';
import { pxToRem } from '@themes';
var BannerContainer = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    height: '100%',
    position: 'relative'
  };
  return styles;
});
var Banner = styled(Button)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    '&:hover': {
      boxShadow: theme.boxShadows.white
    },
    background: 'linear-gradient(91.89deg, #79CBCA 0.96%, #6177F3 108.44%)',
    borderRadius: pxToRem(16),
    boxShadow: theme.boxShadows.white,
    fontSize: 0,
    height: '100%',
    overflow: 'hidden',
    padding: theme.spacing(2.25, 1.5),
    textAlign: 'left',
    textTransform: 'none',
    width: '100%'
  };
  return styles;
});
var Shadow = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    backgroundColor: alpha(theme.colors.shark, 0.3),
    borderRadius: pxToRem(16),
    bottom: pxToRem(-6),
    filter: "blur(".concat(pxToRem(8), ")"),
    height: pxToRem(56),
    left: pxToRem(16),
    position: 'absolute',
    width: pxToRem(178)
  };
  return styles;
});
var Background = styled(RemoteImage)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {};
  return styles;
});
var BannerTitle = styled(Typography)(function (_ref5) {
  var _theme$colors;

  var theme = _ref5.theme;
  var styles = {
    color: alpha(theme === null || theme === void 0 ? void 0 : (_theme$colors = theme.colors) === null || _theme$colors === void 0 ? void 0 : _theme$colors.white, 0.7)
  };
  return styles;
});
export { Background, Banner, BannerContainer, BannerTitle, Shadow };