import _defineProperty from "@babel/runtime/helpers/defineProperty";
// eslint-disable-next-line import/no-unresolved
import { Swiper } from 'swiper/react';
import { Button } from '@pwa/ui';
import { styled } from '@mui/material';
import { pxToRem } from '@themes';
var StyledSwiper = styled(Swiper)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    '& .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet': {
      margin: theme.spacing(0, 0.25)
    },
    '& .swiper-pagination': {
      bottom: 'unset',
      fontSize: 0,
      lineHeight: pxToRem(8),
      marginTop: pxToRem(8),
      position: 'relative'
    },
    '& .swiper-pagination-bullet': {
      '&.lastBullet': {
        '& svg': {
          '& path': {
            fill: 'black'
          },
          display: 'block',
          height: '100%',
          width: '100%'
        },
        '&.swiper-pagination-bullet-active': {
          '& svg': {
            '& path': {
              fill: theme.palette.primary.main
            }
          }
        },
        backgroundColor: 'transparent',
        borderRadius: 'unset',
        height: pxToRem(8),
        width: pxToRem(12)
      },
      height: pxToRem(4),
      verticalAlign: 'middle',
      width: pxToRem(4)
    },
    '&.swiper': {
      paddingBottom: theme.spacing(3)
    },
    overflow: 'visible'
  }, theme.breakpoints.up('tablet'), {
    overflow: 'hidden'
  });

  return styles;
});
var StyledButton = styled(Button)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    '&.arrow-left': {
      left: 0
    },
    '&.arrow-right': {
      right: 0
    },
    '&:disabled': {
      visibility: 'hidden'
    },
    // left:            pxToRem(16),
    marginTop: theme.spacing(-4.5),
    position: 'absolute',
    top: '50%',
    width: pxToRem(40),
    zIndex: 2
  };
  return styles;
});
export { StyledButton, StyledSwiper };