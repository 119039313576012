import { Box, styled } from '@mui/material';
var StyledPopupContentContainer = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'hidden',
    paddingTop: theme.spacing(3)
  };
  return styles;
});
export { StyledPopupContentContainer };