import { Box, styled } from '@mui/material';
var StyledBox = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(3),
    textAlign: 'center'
  };
  return styles;
});
export { StyledBox };