export var keyToQueueBackButton = {};
/**
 * Check if all queues are empty
 *
 * @function allQueuesAreEmpty
 * @returns {boolean} `true` if all queues are empty, `false` otherwise
 */

export var allQueuesAreEmpty = function allQueuesAreEmpty() {
  return Object.values(keyToQueueBackButton).every(function (queue) {
    return queue.length === 0;
  });
};
/**
 * Get the queue for the given key
 *
 * @function getQueue
 * @param {ID} key - The key to get the queue for
 * @returns {Queue | undefined} The queue or `undefined` if no queue exists for the given key
 */

export var getQueue = function getQueue(key) {
  return keyToQueueBackButton[key];
};
/**
 * Get or create the queue for the given key
 *
 * @function getOrCreateQueue
 * @param {ID} key - The key to get or create the queue for
 * @returns {Queue} The queue
 */

export var getOrCreateQueue = function getOrCreateQueue(key) {
  var queue = getQueue(key);

  if (queue) {
    return queue;
  }

  var newQueue = [];
  keyToQueueBackButton[key] = newQueue;
  return newQueue;
};