import { styled as t, FormControl as o, RadioGroup as e, Radio as i, radioClasses as r, FormControlLabel as n, Box as a, FormHelperText as s, InputLabel as l } from "@mui/material";
import "../../../themes/box-shadows/boxShadows.js";
import "../../../themes/dark.theme.js";
import { pxToRem as m } from "../../../themes/default-theme/default-theme.js";
import "../../../themes/gradients/gradients.js";
import "../../../themes/light.theme.js";
var c = t(o)(function (t) {
  return {
    display: t.fullWidth && "flex",
    maxWidth: "100%"
  };
}),
    h = t(e)(function (t) {
  var o = t.theme;
  return t.color, {
    color: o.colors.nightWizard
  };
}),
    d = t(i)(function (t) {
  var o,
      e = t.theme;
  return t.color, (o = {
    "& .MuiSvgIcon-root": {
      height: m(20)
    },
    padding: e.spacing(1.5)
  })["&.".concat(r.disabled)] = {
    color: "inherit",
    opacity: .1
  }, o;
}),
    p = t(n)(function (t) {
  t.theme, t.color;
  return {};
}),
    g = t(a)(function () {
  return {
    fontSize: m(12),
    left: 0,
    lineHeight: m(14),
    marginTop: m(4),
    position: "absolute",
    right: 0,
    top: "100%"
  };
}),
    u = t(s)(function (t) {
  var o = t.theme;
  return {
    color: o.palette.error.main,
    display: "block",
    margin: "unset",
    maxWidth: "100%",
    padding: o.spacing(0, 2)
  };
}),
    f = t(s)(function (t) {
  var o = t.theme;
  return {
    color: o.colors.abandonedSpaceship,
    display: "block",
    margin: "unset",
    maxWidth: "100%",
    padding: o.spacing(0, 2)
  };
}),
    b = t(l)(function (t) {
  var o = t.theme,
      e = t.$color,
      i = {
    color: o.colors.abandonedSpaceship,
    display: "block",
    fontSize: m(16),
    fontWeight: 400,
    lineHeight: m(18),
    marginBottom: o.spacing(.5),
    maxWidth: "100%",
    paddingLeft: o.spacing(1),
    position: "relative",
    textAlign: "left",
    transform: "unset",
    transformOrigin: "unset"
  };
  return "bayFog" === e && (i.color = o.colors.white), i;
});
export { g as StyleFromHelperWrapper, c as StyledFormControl, u as StyledFormHelperErrorText, f as StyledFormHelperText, h as StyledGroupBase, b as StyledInputLabel, d as StyledRadioBase, p as StyledRadioLabel };