import { __assign as t } from "../../_virtual/_tslib.js";
import { styled as e, List as r, ListItemButton as o, ListItemText as i, Box as n, CircularProgress as a } from "@mui/material";
import "../../themes/box-shadows/boxShadows.js";
import "../../themes/dark.theme.js";
import { pxToRem as m } from "../../themes/default-theme/default-theme.js";
import "../../themes/gradients/gradients.js";
import "../../themes/light.theme.js";
var h = e(r)(function (t) {
  return t.theme, {
    display: "flex",
    flexDirection: "column",
    gap: m(12)
  };
}),
    s = e(o)(function (t) {
  t.theme;
  return {
    "&:hover": {
      backgroundColor: "transparent"
    },
    padding: 0
  };
}),
    p = e(i)(function (e) {
  var r = e.theme;
  return {
    "& .MuiTypography-root": t(t({}, r.typography.title3), {
      fontWeight: 500
    })
  };
}),
    u = e(n)(function (t) {
  var e = t.theme;
  return {
    alignItems: "center",
    backgroundColor: e.colors.lavenderSyrup,
    borderRadius: m(8),
    display: "flex",
    gap: m(8),
    padding: e.spacing(.75, 1.5, .75, 1)
  };
}),
    d = e(a)(function (t) {
  return t.theme, {
    height: "".concat(m(13), " !important"),
    width: "".concat(m(13), " !important")
  };
});
export { u as StyledBox, d as StyledCircularProgress, h as StyledList, s as StyledListItemButton, p as StyledListItemText };