import { Box, styled } from '@mui/material';
var StyledBox = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    margin: theme.spacing(0, 0, 1, 1)
  };
  return styles;
});
var StyledCheckboxWrapper = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    '& label': {
      margin: 0
    },
    '& span.MuiButtonBase-root': {
      marginRight: theme.spacing(1.5),
      padding: 0
    },
    margin: theme.spacing(4, 1)
  };
  return styles;
});
var StyledBoxInfo = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    backgroundColor: theme.palette.lavenderSyrup.main,
    borderRadius: theme.typography.pxToRem(12),
    padding: theme.spacing(1.75, 2)
  };
  return styles;
});
export { StyledBox, StyledBoxInfo, StyledCheckboxWrapper };