import { styled as t, Box as r } from "@mui/material";
import { Typography as o } from "../typography/typography.component.js";
var e = t(r)(function () {
  return {
    textAlign: "center",
    whiteSpace: "pre-line"
  };
}),
    i = t(o)(function (t) {
  var r = t.$opacity;
  return {
    opacity: void 0 === r ? "1" : r
  };
});
export { i as StyledTypography, e as StyledWrapper };