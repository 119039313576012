import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { http } from '@services';

/**
 * Response interceptor, catch all API responses.
 *
 * @author Ihar Kazlouski
 * @function ResponseInterceptor
 * @category Interceptors
 * @param {AxiosResponse} response api response.
 * @param {AxiosError<ApiError>} error api error.
 * @return {AxiosResponse| AxiosError<ApiError>| Promise<AxiosError<ApiError>> | undefined} api response or error.
 */
export var ResponseInterceptor = function ResponseInterceptor(_ref) {
  var response = _ref.response,
      error = _ref.error;

  if (response.config.disableResponseTransform) {
    return response || error && Promise.reject(error);
  }

  var responseObject;
  var respData = Object.keys(response.data).length === 1 && response.data.data || response.data;

  if (Array.isArray(respData)) {
    responseObject = _objectSpread(_objectSpread({}, response), {}, {
      data: _toConsumableArray(respData)
    });
  } else if (typeof respData === 'string') {
    responseObject = _objectSpread(_objectSpread({}, response), {}, {
      data: respData
    });
  } else {
    responseObject = _objectSpread(_objectSpread({}, response), {}, {
      data: respData
    });
  }

  return responseObject || error && Promise.reject(error);
};
/**
 * Intercepts response from Axios
 *
 * @author Yury Kuntsou
 * @function StackResponseInterception
 * @category Interceptors
 * @param {AxiosResponse} response - Response from Axios
 * @returns {AxiosResponse} response - Modified response
 */

export var StackResponseInterception = function StackResponseInterception(response) {
  var _response$config;

  var fullUrl = response.config.url ? http.getUri(response.config) : 'error';

  if ((_response$config = response.config) !== null && _response$config !== void 0 && _response$config.stackRequest) {
    if (response.config.stackRequest.has(fullUrl)) {
      response.config.stackRequest["delete"](fullUrl);
    }
  }

  return response;
};