import { styled as e, Box as o } from "@mui/material";
import { Size as t } from "../../enums/entities/entities.enum.js";
import "../../enums/keyboard/keyboard.enum.js";
import "../../enums/theme/theme.enum.js";
import "../../themes/box-shadows/boxShadows.js";
import "../../themes/dark.theme.js";
import { pxToRem as a } from "../../themes/default-theme/default-theme.js";
import "../../themes/gradients/gradients.js";
import "../../themes/light.theme.js";
var i = e(o)(function (e) {
  var o,
      i,
      n = e.theme,
      s = e.inverse,
      c = e.color,
      r = void 0 === c ? "seaBuckthorn" : c,
      m = e.size,
      d = {
    alignItems: "center",
    backgroundColor: null === (o = null == n ? void 0 : n.colors) || void 0 === o ? void 0 : o[r],
    color: null === (i = null == n ? void 0 : n.colors) || void 0 === i ? void 0 : i.white,
    display: "inline-flex"
  };

  switch (m) {
    case t.Small:
      d.padding = "".concat(n.spacing(.5), " ").concat(n.spacing(1.5)), d.borderRadius = s ? "".concat(a(16), " 0") : "0 ".concat(a(16));
      break;

    case t.Big:
      d.padding = "".concat(n.spacing(1), " ").concat(n.spacing(2.5)), d.borderRadius = s ? "".concat(a(24), " 0") : "0 ".concat(a(24));
      break;

    default:
      d.padding = "".concat(n.spacing(.5), " ").concat(n.spacing(2)), d.borderRadius = s ? "".concat(a(16), " 0") : "0 ".concat(a(16));
  }

  return d;
});
export { i as StyledChip };