/**
 * Trims a string of whitespace and returns the trimmed value.
 *
 * @author Yury Kuntsou
 * @function trimFormat
 * @category Formatters
 * @param {string} value - The string to be trimmed.
 * @returns {string} The trimmed string.
 */
export var trimFormat = function trimFormat(value) {
  return value && value.trim();
};
/**
 * Converts a string to uppercase.
 *
 * @author Yury Kuntsou
 * @function uppercaseFormat
 * @category Formatting
 * @param {string} value - The string to convert.
 * @returns {string} The uppercase string.
 */

export var uppercaseFormat = function uppercaseFormat(value) {
  return value && value.toUpperCase();
};