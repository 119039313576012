import { i18n } from '@localization';
/**
 * Combine validators.
 *
 * @author Yury Kuntsou
 * @function combinedValidator
 * @category helpers
 * @param {FieldValidator<T>[]} validators - Array of validators.
 * @returns {FieldValidator<T>} Combined validator.
 */
// export const combinedValidator =
//   <T = string | number>(
//     ...validators: FieldValidator<T>[]
//   ): FieldValidator<T> =>
//     (value: T) => {
//       const validator: FieldValidator<T> | undefined = validators.reduce<
//       undefined | FieldValidator<T>
//       >(
//         (error: unknown, validator: FieldValidator<T>) =>
//         // eslint-disable-next-line @typescript-eslint/no-unsafe-return
//           error || validator(value),
//         undefined,
//       );
//       return validator;
//     };

export var combinedValidator = function combinedValidator() {
  for (var _len = arguments.length, validators = new Array(_len), _key = 0; _key < _len; _key++) {
    validators[_key] = arguments[_key];
  }

  return function (value) {
    var validator = validators.reduce(function (error, validator) {
      if (error) {
        return error;
      }

      if (typeof validator === 'function') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return validator(value);
      }

      return undefined;
    }, undefined);
    return validator;
  };
};
/**
 * Required validators.
 *
 * @author Yury Kuntsou
 * @function requiredValidator
 * @category helpers
 * @param {string | number} value - Value to validate.
 * @returns {string | undefined} Error message or undefined if validation passed.
 */

export var requiredValidator = function requiredValidator(text) {
  return function (value) {
    return value ? undefined : text || "".concat(i18n.t('forms.validation.required'));
  };
};
/**
 * Max length value validators.
 *
 * @author Yury Kuntsou
 * @function maxLengthValidator
 * @category helpers
 * @param {number} max - Maximum length.
 * @param {string | number} value - Value to validate.
 * @returns {string | undefined} Error message or undefined if validation passed.
 */

export var maxLengthValidator = function maxLengthValidator(max, text) {
  return function (value) {
    return value && value.toString().length > max ? text : undefined;
  };
};
/**
 * Max length value validators.
 *
 * @author Yury Kuntsou
 * @function maxLengthValidator
 * @category helpers
 * @param {number} max - Maximum length.
 * @param {string | number} value - Value to validate.
 * @returns {string | undefined} Error message or undefined if validation passed.
 */

export var minLengthValidator = function minLengthValidator(min, text) {
  return function (value) {
    return min && value && value.toString().length < min ? text : undefined;
  };
};
/**
 * Validates the length of a number with decimal parts.
 *
 * @author Yury Kuntsou
 * @function maxLengthWithDecimalValidator
 * @category Validators
 * @param {number} max - The maximum length of the number.
 * @param {number} decimal - The maximum length of the decimal part.
 * @param {string} text - The error message to return if the number is too long.
 * @param {string|number} value - The value to validate.
 * @returns {string|undefined} The error message if the number is too long, or undefined if it is valid.
 */

export var maxLengthWithDecimalValidator = function maxLengthWithDecimalValidator(max, decimal, text) {
  return function (value) {
    if (!value || !max) {
      return undefined;
    }

    var numberParts = value.toString().split('.');
    var decimalPart = numberParts[1];

    if (value.toString().length > max) {
      return text;
    }

    if (!decimalPart && value.toString().length > max - (decimal + 1)) {
      return text;
    }

    return undefined;
  };
};
/**
 * Checks if the given string is a valid IBAN.
 *
 * @author Yury Kuntsou
 * @function isCorrectIBANValidator
 * @category Validators
 * @param {string} [text] - The text to show when the value is valid.
 * @param {string} value - The value to check.
 * @returns {(string | boolean | undefined)} The text or true if the value is valid, undefined otherwise.
 */

export var isCorrectIBANValidator = function isCorrectIBANValidator(text) {
  return function (value) {
    if (!value || typeof value !== 'string') {
      return undefined;
    }

    var IBANRegExp = /^BY\d{2}[A-Z0-9]{4}\d{4}[A-Z0-9]{16}$/;

    if (IBANRegExp.test(value)) {
      return text || true;
    }

    return undefined;
  };
};