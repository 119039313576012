import { pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    backgroundColor: theme.palette.white.main,
    borderRadius: pxToRem(16),
    boxShadow: theme.boxShadows.independence,
    padding: theme.spacing(2)
  };
  return styles;
});
var StyledBox = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    alignSelf: 'start',
    backgroundColor: theme.palette.alpineGoat.main,
    borderRadius: pxToRem(5),
    fontSize: '0',
    lineHeight: '0',
    padding: theme.spacing(0.5, 1)
  };
  return styles;
});
export { StyledBox, StyledWrapper };