import { pxToRem, Typography } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    backgroundColor: theme.palette.brightGray.main,
    borderRadius: pxToRem(20),
    padding: theme.spacing(4, 2, 2.5)
  };
  return styles;
});
var StyledTypography = styled(Typography)(function () {
  var styles = {
    opacity: '0.7'
  };
  return styles;
});
export { StyledTypography, StyledWrapper };