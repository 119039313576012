import { styled as t, Box as e } from "@mui/material";
import { Button as r } from "../button/button.component.js";
import "../../themes/box-shadows/boxShadows.js";
import "../../themes/dark.theme.js";
import { pxToRem as o } from "../../themes/default-theme/default-theme.js";
import "../../themes/gradients/gradients.js";
import "../../themes/light.theme.js";
var i = t(r)(function (t) {
  var e = t.theme;
  return {
    alignItems: "start",
    borderRadius: o(16),
    flexDirection: "column",
    height: o(100),
    minWidth: o(120),
    padding: e.spacing(1.5),
    textAlign: "start",
    whiteSpace: "pre-wrap",
    width: o(120)
  };
}),
    m = t(e)(function (t) {
  var e = t.theme,
      r = t.$color;
  return {
    alignItems: "center",
    backgroundColor: e.colors[r],
    borderRadius: "50%",
    display: "flex",
    height: o(40),
    justifyContent: "center",
    marginBottom: e.spacing(1),
    width: o(40)
  };
});
export { i as StyledButton, m as StyledIconWrapper };