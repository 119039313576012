import { Box, styled } from '@mui/material';
import { pxToRem } from '@themes';
var StyledPopupContainer = styled(Box)(function (_ref) {
  var theme = _ref.theme,
      $fullScreen = _ref.$fullScreen;
  var styles = {
    borderRadius: !$fullScreen && pxToRem(12),
    button: {
      minWidth: 'fit-content'
    },
    display: 'flex',
    flex: $fullScreen && 1,
    flexDirection: 'column',
    height: $fullScreen && '100%',
    padding: !$fullScreen && theme.spacing(3)
  };
  return styles;
});
export { StyledPopupContainer };