import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";

/**
 * Helps to create an array of objects of a select field items.
 *
 * @author Christina Kashevar
 * @function getTermItemsForSelectField
 * @category helpers
 * @return {array} array of radio field items.
 */
var getDistrictItemsForSelectField = function getDistrictItemsForSelectField(district, districtCenter) {
  var mappedDistricts = district.map(function (_ref) {
    var districtName = _ref.districtName,
        districtType = _ref.districtType;
    return {
      text: "".concat(districtType, " ").concat(districtName),
      value: districtName
    };
  });
  var mappedDistrictCenters = districtCenter.map(function (_ref2) {
    var localityName = _ref2.localityName,
        localityType = _ref2.localityType;
    return {
      text: "".concat(localityType, " ").concat(localityName),
      value: localityName
    };
  });
  return [].concat(_toConsumableArray(mappedDistricts), _toConsumableArray(mappedDistrictCenters));
};

export { getDistrictItemsForSelectField };