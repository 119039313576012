import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, styled } from '@mui/material';
var StyledWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    paddingBottom: theme.spacing(5)
  }, theme.breakpoints.up('desktop'), {
    maxWidth: theme.typography.pxToRem(452),
    paddingTop: theme.spacing(2)
  });

  return styles;
});
export { StyledWrapper };