import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, Container, styled } from '@mui/material';
var StyledContainer = styled(Container)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    display: 'flex',
    flex: '1',
    flexDirection: 'column'
  }, theme.breakpoints.up('desktop'), {
    display: 'block'
  });

  return styles;
});
var StyledWrapper = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;

  var styles = _defineProperty({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(2.5, 0, 5)
  }, theme.breakpoints.up('desktop'), {
    display: 'block',
    maxWidth: theme.typography.pxToRem(452),
    paddingTop: theme.spacing(3)
  });

  return styles;
});
var StyledSubtitle = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;

  var styles = _defineProperty({
    margin: theme.spacing(2.5, 0, 3),
    textAlign: 'center'
  }, theme.breakpoints.up('desktop'), {
    textAlign: 'left'
  });

  return styles;
});
var StyledButtonsWrapper = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;

  var styles = _defineProperty({
    fontSize: '0',
    textAlign: 'center'
  }, theme.breakpoints.up('desktop'), {
    textAlign: 'left'
  });

  return styles;
});
var StyledBox = styled(Box)(function (_ref5) {
  var theme = _ref5.theme;
  var styles = {
    grow: '1',
    paddingTop: theme.spacing(5)
  };
  return styles;
});
var StyledLineWrapper = styled(Box)(function (_ref6) {
  var theme = _ref6.theme;
  var styles = {
    margin: theme.spacing(3.5, 0, 1.5)
  };
  return styles;
});
export { StyledBox, StyledButtonsWrapper, StyledContainer, StyledLineWrapper, StyledSubtitle, StyledWrapper };