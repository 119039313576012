import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, Container, styled } from '@mui/material';
var StyledContentWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(2)
  }, theme.breakpoints.up('desktop'), {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: theme.typography.pxToRem(452),
    paddingTop: theme.spacing(4)
  });

  return styles;
});
var StyledContainer = styled(Container)(function (_ref2) {
  var theme = _ref2.theme;

  var styles = _defineProperty({
    display: 'flex',
    flex: '1',
    flexDirection: 'column'
  }, theme.breakpoints.up('desktop'), {
    display: 'block'
  });

  return styles;
});
var StyledButtonWrapper = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    paddingTop: theme.spacing(2.5)
  };
  return styles;
});
export { StyledButtonWrapper, StyledContainer, StyledContentWrapper };