import { styled as e, Box as t } from "@mui/material";
import "../../../../themes/box-shadows/boxShadows.js";
import "../../../../themes/dark.theme.js";
import { pxToRem as o } from "../../../../themes/default-theme/default-theme.js";
import "../../../../themes/gradients/gradients.js";
import "../../../../themes/light.theme.js";
var r = e(t)(function (e) {
  var t = e.theme,
      r = e.$color,
      i = e.$variant;
  return {
    backgroundColor: "alpineGoat" === r && "contained" === i ? t.colors.white : t.colors.whiteSolid,
    borderRadius: o(5),
    display: "flex",
    padding: t.spacing(.5, .5, .5, .75)
  };
});
export { r as StyledNumberBox };