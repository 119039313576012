import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, styled } from '@mui/material';
var StyledCheckboxWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    marginLeft: theme.spacing(1)
  }, theme.breakpoints.up('desktop'), {
    marginLeft: 'unset'
  });

  return styles;
});
export { StyledCheckboxWrapper };