import { styled as e, Box as t, alpha as o } from "@mui/material";
import "react";
import { getHexColor as r } from "../../helpers/colors/colors.helper.js";
import "../../enums/entities/entities.enum.js";
import "../../enums/keyboard/keyboard.enum.js";
import "../../enums/theme/theme.enum.js";
import "../../node_modules/@babel/runtime/helpers/typeof.js";
import { ActionChip as n } from "../action-chip/action-chip.component.js";
import { Typography as i } from "../typography/typography.component.js";
import "../../themes/box-shadows/boxShadows.js";
import "../../themes/dark.theme.js";
import { pxToRem as a } from "../../themes/default-theme/default-theme.js";
import "../../themes/gradients/gradients.js";
import "../../themes/light.theme.js";
import { Button as m } from "../button/button.component.js";
var p = e(m)(function (e) {
  var t = e.theme.typography.pxToRem;
  return {
    alignItems: "flex-end",
    borderRadius: t(12),
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    height: t(112),
    justifyContent: "space-between",
    position: "relative",
    width: t(176)
  };
}),
    s = e(t)(function (e) {
  var t = e.theme;
  return {
    backgroundColor: o(t.colors.black, .4),
    borderRadius: 16,
    bottom: "-3%",
    filter: "blur(".concat(t.typography.pxToRem(7.5), ")"),
    height: "50%",
    left: "11%",
    pointerEvents: "none",
    position: "absolute",
    right: "11%",
    zIndex: -1
  };
}),
    u = e(t)(function (e) {
  return {
    alignItems: "flex-start",
    bottom: "0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    left: "0",
    padding: e.theme.spacing(2.75, 1.5),
    position: "absolute",
    right: "0",
    top: "0"
  };
}),
    l = e(n)(function (e) {
  var t = e.$color,
      o = e.theme;
  return {
    backgroundColor: t,
    borderRadius: "0 ".concat(a(12)),
    maxWidth: o.typography.pxToRem(146),
    position: "absolute",
    right: 0,
    top: 0
  };
}),
    h = e(i)(function () {
  return {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  };
}),
    c = e(t)(function (e) {
  var t = e.theme,
      o = e.$backgroundImg,
      r = {
    backgroundColor: t.colors.bayFog,
    borderRadius: a(16),
    bottom: 0,
    left: 0,
    position: "absolute",
    right: 0,
    top: 0
  };
  return o && (r.backgroundImage = o && "url(".concat(o, ")"), r.backgroundPosition = "center", r.backgroundRepeat = "no-repeat", r.backgroundSize = "100% 100%"), r;
}),
    d = e(t)(function (e) {
  var t = e.theme,
      o = e.$backgroundColor;
  return {
    backgroundColor: r(t.palette, o) || t.palette.white.main,
    height: t.typography.pxToRem(10),
    marginLeft: t.spacing(1),
    marginRight: t.spacing(1),
    width: t.typography.pxToRem(1)
  };
});
export { l as BannerLabel, u as StyledBox, c as StyledBoxBackground, d as StyledSeparator, s as StyledShadow, h as StyledTypography, p as StyledWrapper };