import { __assign as e } from "../../../../_virtual/_tslib.js";
import { jsx as r, Fragment as t } from "react/jsx-runtime";
import { CardsType as s } from "../../../../enums/entities/entities.enum.js";
import "../../../../enums/keyboard/keyboard.enum.js";
import "../../../../enums/theme/theme.enum.js";
import { Icon as i } from "../../../icon/icon.component.js";
import { RemoteImage as o } from "../../../remote-image/remote-image.view.js";
import { Typography as m } from "../../../typography/typography.component.js";
import { StyledWidget as a } from "./widget.styles.js";
import n from "../../../../packages/pwa-ui/assets/images/add.image.svg.js";
import p from "../../../../packages/pwa-ui/assets/images/money-pig.image.svg.js";
import c from "../../../../packages/pwa-ui/assets/images/money-wallet.image.svg.js";

var g = function (g) {
  var u = g.currency,
      d = g.cardType,
      f = g.image,
      h = function () {
    switch (!0) {
      case d === s.Credit:
        return r(i, {
          src: c
        });

      case d === s.Deposit:
        return r(i, {
          height: 21,
          src: p,
          width: 27
        });

      case d === s.Card:
        return f && "string" == typeof f ? r(o, {
          borderRadius: 5,
          height: 44,
          src: f,
          width: 72
        }) : r(t, {});

      case !d && !u:
        return f ? "string" == typeof f ? r(i, {
          src: f
        }) : f : r(i, {
          src: n
        });

      default:
        return r(m, e({
          color: "white",
          component: "span",
          fontWeight: 500,
          variant: "body2"
        }, {
          children: u
        }));
    }
  }();

  return r(a, e({
    $type: d,
    component: "span"
  }, {
    children: h
  }));
};

export { g as Widget };