import { CREATE_FORM_STATE, CREATE_FORM_STATE_ASYNC, DELETE_FORM_STATE, DELETE_FORM_STATE_ASYNC, UPDATE_FORM_STATE, UPDATE_FORM_STATE_ASYNC } from './forms.action-types';
export var actions = {
  /**
   * create form action.
   *
   * @author Yury Kuntsou
   * @function createForm
   * @category Actions
   */
  createForm: function createForm(payload) {
    return {
      payload: payload,
      type: CREATE_FORM_STATE
    };
  },

  /**
   * create form async action.
   *
   * @author Yury Kuntsou
   * @function createFormAsync
   * @category Actions
   */
  createFormAsync: function createFormAsync(payload) {
    return {
      payload: payload,
      type: CREATE_FORM_STATE_ASYNC
    };
  },

  /**
   * delete form action.
   *
   * @author Yury Kuntsou
   * @function deleteFormState
   * @category Actions
   */
  deleteFormState: function deleteFormState(payload) {
    return {
      payload: payload,
      type: DELETE_FORM_STATE
    };
  },

  /**
   * delete form async action.
   *
   * @author Yury Kuntsou
   * @function deleteFormStateAsync
   * @category Actions
   */
  deleteFormStateAsync: function deleteFormStateAsync(payload) {
    return {
      payload: payload,
      type: DELETE_FORM_STATE_ASYNC
    };
  },

  /**
   * update form action.
   *
   * @author Yury Kuntsou
   * @function updateFormState
   * @category Actions
   */
  updateFormState: function updateFormState(payload) {
    return {
      payload: payload,
      type: UPDATE_FORM_STATE
    };
  },

  /**
   * update form async action.
   *
   * @author Yury Kuntsou
   * @function updateFormStateAsync
   * @category Actions
   */
  updateFormStateAsync: function updateFormStateAsync(payload) {
    return {
      payload: payload,
      type: UPDATE_FORM_STATE_ASYNC
    };
  }
};