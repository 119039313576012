import { Typography } from '@pwa/ui';
import { alpha, Box, Button, styled } from '@mui/material';
import { pxToRem } from '@themes';
var BannerContainer = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    height: '100%',
    position: 'relative'
  };
  return styles;
});
var Banner = styled(Button)(function (_ref2) {
  var _theme$boxShadows, _theme$boxShadows2;

  var theme = _ref2.theme,
      $color = _ref2.$color;
  var styles = {
    '&:hover': {
      boxShadow: theme === null || theme === void 0 ? void 0 : (_theme$boxShadows = theme.boxShadows) === null || _theme$boxShadows === void 0 ? void 0 : _theme$boxShadows.white
    },
    background: $color && (theme === null || theme === void 0 ? void 0 : theme.gradients[$color]),
    borderRadius: pxToRem(16),
    boxShadow: theme === null || theme === void 0 ? void 0 : (_theme$boxShadows2 = theme.boxShadows) === null || _theme$boxShadows2 === void 0 ? void 0 : _theme$boxShadows2.white,
    fontSize: 0,
    height: '100%',
    overflow: 'hidden',
    padding: theme.spacing(2.25, 1.5),
    textAlign: 'left',
    width: '100%'
  };
  return styles;
});
var Shadow = styled(Box)(function (_ref3) {
  var theme = _ref3.theme,
      $color = _ref3.$color;

  var getShadowColor = function getShadowColor(color) {
    switch (color) {
      case 'coolGrey':
        return theme.colors.mensNight;

      case 'panPurple':
        return theme.colors.denim;

      case 'deepAquamarine':
        return theme.colors.blueBobbin;

      default:
        return theme.colors.shark;
    }
  };

  var styles = {
    backgroundColor: $color && alpha(getShadowColor($color), 0.6),
    borderRadius: pxToRem(16),
    bottom: pxToRem(-6),
    filter: "blur(".concat(pxToRem(8), ")"),
    height: pxToRem(56),
    left: pxToRem(16),
    position: 'absolute',
    width: pxToRem(178)
  };
  return styles;
});
var LabelTypography = styled(Typography)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    color: alpha(theme.colors.white, 0.7)
  };
  return styles;
});
var StyledLogoWrapper = styled(Box)(function (_ref5) {
  var theme = _ref5.theme;
  var styles = {
    '& i': {
      color: alpha(theme.colors.white, 0.15)
    },
    fontSize: 0,
    pointerEvents: 'none',
    position: 'absolute',
    right: 14,
    top: 13
  };
  return styles;
});
export { Banner, BannerContainer, LabelTypography, Shadow, StyledLogoWrapper };