import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { Box, styled } from '@mui/material';
var StyledHeaderWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var headerHeight = theme.mixins.header || {};

  var styles = _objectSpread({
    alignItems: 'center',
    backgroundColor: theme.palette.white.main,
    display: 'flex',
    zIndex: theme.zIndex.appBar + 1
  }, headerHeight);

  return styles;
});
var StyledLogoWrapper = styled(Box)(function () {
  var styles = {
    display: 'flex',
    justifyContent: 'center'
  };
  return styles;
});
var StyledContainer = styled(Box)(function () {
  var styles = {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  };
  return styles;
});
var StyledContainerItem = styled(Box)(function () {
  var styles = {
    alignItems: 'center',
    display: 'flex'
  };
  return styles;
});
var StyledDivider = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    backgroundColor: theme.palette.estateViolet.main,
    height: 24,
    width: '1px'
  };
  return styles;
});
export { StyledContainer, StyledContainerItem, StyledDivider, StyledHeaderWrapper, StyledLogoWrapper };