import { styled as o, Select as r, alpha as e, FormControl as t, InputLabel as i, Box as n, FormHelperText as a } from "@mui/material";
import "react";
import { getHexColor as s } from "../../../helpers/colors/colors.helper.js";
import "../../../enums/entities/entities.enum.js";
import "../../../enums/keyboard/keyboard.enum.js";
import "../../../enums/theme/theme.enum.js";
import "../../../node_modules/@babel/runtime/helpers/typeof.js";
import { Icon as l } from "../../icon/icon.component.js";
import { Typography as c } from "../../typography/typography.component.js";
var p = o(r)(function (o) {
  var r = o.theme,
      t = o.color,
      i = s(r.palette, t),
      n = r.typography.pxToRem,
      a = {
    "& .MuiInputBase-input": {
      alignItems: "center",
      boxSizing: "border-box",
      display: "flex",
      fontSize: n(16),
      fontWeight: 500,
      height: n(56),
      lineHeight: n(18),
      padding: r.spacing(2, 2, 2, 1.5),
      position: "relative"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    },
    "& .MuiSvgIcon-root svg": {
      stroke: r.colors.abandonedSpaceship
    },
    "&.Mui-error": {
      borderColor: r.palette.error.main
    },
    "&.Mui-focused": {
      borderColor: r.palette.primary.main
    },
    backgroundColor: i,
    border: i && "".concat(n(1), " solid ").concat(i),
    borderRadius: n(12),
    boxSizing: "border-box",
    color: r.colors.nightWizard,
    fontSize: n(14),
    height: n(56),
    lineHeight: n(24),
    overflow: "hidden",
    padding: 0,
    position: "relative",
    transition: r.transitions.create(["border-color"])
  };

  switch (t) {
    case "secondary":
      a.backgroundColor = r.colors.ghostWhite, a.borderColor = r.colors.ghostWhite;
      break;

    case "bayFog":
      a.backgroundColor = e(r.colors.ghostWhite, .05), a.borderColor = e(r.colors.white, .1), a.color = r.colors.white, a["& .MuiSvgIcon-root svg"] = {
        stroke: r.colors.estateViolet
      };
  }

  return a;
}),
    h = o(l)(function (o) {
  var r = o.theme,
      e = o.height;
  o.color;
  var t = r.typography.pxToRem;
  return {
    display: "flex",
    pointerEvents: "none",
    position: "absolute",
    right: t(16),
    top: "calc(50% - ".concat(t(e / 2), ")"),
    transition: r.transitions.create(["transform"]),
    zIndex: 1
  };
}),
    d = o(c)(function (o) {
  var r = o.theme,
      t = o.$color,
      i = {
    color: e(r.colors.nightWizard, .4)
  };
  return "bayFog" === t && (i.color = e(r.colors.white, .4)), i;
}),
    m = o(t)(function (o) {
  return {
    display: o.fullWidth && "flex",
    maxWidth: "100%"
  };
}),
    g = o(i)(function (o) {
  var r = o.theme,
      e = o.$color,
      t = r.typography.pxToRem,
      i = {
    color: r.colors.abandonedSpaceship,
    display: "block",
    fontSize: t(16),
    fontWeight: 400,
    lineHeight: t(18),
    marginBottom: r.spacing(.5),
    maxWidth: "100%",
    paddingLeft: r.spacing(1),
    position: "relative",
    transform: "unset",
    transformOrigin: "unset"
  };
  return "bayFog" === e && (i.color = r.colors.white), i;
}),
    u = o(n)(function (o) {
  var r = o.theme.typography.pxToRem;
  return {
    fontSize: r(12),
    left: 0,
    lineHeight: r(14),
    marginTop: r(4),
    position: "absolute",
    right: 0,
    top: "100%"
  };
}),
    b = o(a)(function (o) {
  var r = o.theme;
  return {
    color: r.palette.error.main,
    display: "block",
    margin: "unset",
    maxWidth: "100%",
    padding: r.spacing(0, 1)
  };
}),
    f = o(a)(function (o) {
  var r = o.theme,
      e = o.$color,
      t = {
    color: r.colors.abandonedSpaceship,
    display: "block",
    margin: "unset",
    maxWidth: "100%",
    padding: r.spacing(0, 1)
  };
  return "bayFog" === e && (t.color = r.colors.white), t;
});
export { u as StyleFromHelperWrapper, m as StyledFormControl, b as StyledFormHelperErrorText, f as StyledFormHelperText, g as StyledInputLabel, d as StyledPlaceholder, p as StyledSelectBase, h as StyledSelectIcon };