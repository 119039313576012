import { __rest as e, __assign as r } from "../../../_virtual/_tslib.js";
import { jsxs as o, jsx as a } from "react/jsx-runtime";
import { Box as l } from "@mui/material";
import { StyledFormControl as i, StyledInputLabel as t, StyledInputBase as n, StyledInputAdornment as c, StyleFromHelperWrapper as u, StyledFormHelperErrorText as d, StyledFormHelperText as m, StyledAbsoluteInputBase as s } from "./currency-field.styles.js";
import v from "../number-format-input/helper/numeric-format.js";

var h = function (h) {
  var f = h.name,
      p = h.value,
      b = h.label,
      g = h.labelText,
      y = h.color,
      S = void 0 === y ? "white" : y,
      F = h.error,
      C = h.bottomLabel;
  h.deviceSize;

  var x = h.drawerTitle,
      V = h.thousandSeparator,
      w = void 0 === V ? " " : V,
      I = h.fixedDecimalScale,
      N = void 0 === I || I,
      O = h.decimalSeparator,
      P = void 0 === O ? "," : O,
      T = h.decimalScale,
      j = void 0 === T ? 2 : T,
      B = h.inputProps,
      W = h.onChange,
      D = h.onBlur,
      L = h.onFocus,
      z = h.valueIsNumericString,
      _ = void 0 === z || z,
      k = h.allowNegative,
      A = void 0 !== k && k,
      M = h.items,
      $ = void 0 === M ? [] : M,
      q = h.clearPlaceholderOnFocus,
      E = h.placeholder,
      G = h.onChangeValue,
      H = h.onChangeCurrency,
      J = h.onFocusValue,
      K = h.onFocusCurrency,
      Q = h.focused,
      R = h.disabled,
      U = h.readOnly,
      X = h.active,
      Y = e(h, ["name", "value", "label", "labelText", "color", "error", "bottomLabel", "deviceSize", "drawerTitle", "thousandSeparator", "fixedDecimalScale", "decimalSeparator", "decimalScale", "inputProps", "onChange", "onBlur", "onFocus", "valueIsNumericString", "allowNegative", "items", "clearPlaceholderOnFocus", "placeholder", "onChangeValue", "onChangeCurrency", "onFocusValue", "onFocusCurrency", "focused", "disabled", "readOnly", "active"]),
      Z = f && "".concat(f, "-textfield"),
      ee = f && "".concat(f, "-select"),
      re = !!F,
      oe = {
    errorId: Z && "".concat(Z, "-error-text"),
    htmlFor: Z,
    id: Z
  },
      ae = {
    errorId: ee && "".concat(ee, "-error-text"),
    htmlFor: ee,
    id: ee
  };

  return o(l, r({
    position: "relative"
  }, {
    children: [o(i, r({
      error: re,
      focused: Q,
      fullWidth: !0
    }, {
      children: [b && a(t, r({
        htmlFor: oe.htmlFor,
        shrink: !0
      }, {
        children: b
      })), a(v, r({
        "aria-describedby": Z,
        color: S,
        customInput: n,
        error: re,
        fullWidth: !0,
        id: oe.id,
        name: Z,
        placeholder: q && X ? void 0 : E,
        startAdornment: g && a(c, r({
          position: "start"
        }, {
          children: g
        })),
        valueIsNumericString: _
      }, Y, {
        allowNegative: A,
        decimalScale: j,
        decimalSeparator: P,
        disabled: R,
        fixedDecimalScale: N,
        inputProps: r({
          autoComplete: "off",
          inputMode: "decimal",
          maxLength: 20
        }, B),
        readOnly: U,
        thousandSeparator: w,
        value: (null == p ? void 0 : p.value) || "",
        onBlur: D,
        onFocus: function (e) {
          L && L(e), J && J(e);
        },
        onValueChange: function (e, o) {
          var a = _ ? "number" == typeof e.floatValue ? "".concat(e.floatValue) : "." === e.value ? "0" : e.value : e.formattedValue;
          W && W(r(r({}, p), {
            value: a
          }), o), G && G(r(r({}, p), {
            value: a
          }), o);
        }
      })), re && a(u, {
        children: a(d, r({
          id: oe.errorId
        }, {
          children: "string" == typeof F ? F : F.value || F.currency
        }))
      }), C && !re ? a(u, {
        children: a(m, r({
          $color: S
        }, {
          children: C
        }))
      }) : null]
    })), a(i, r({
      error: re,
      fullWidth: !0
    }, {
      children: a(s, {
        "aria-describedby": ee,
        disabled: R,
        drawerTitle: x,
        error: re,
        fullWidth: !0,
        id: ae.id,
        items: $,
        name: ee,
        readOnly: U,
        value: (null == p ? void 0 : p.currency) || "",
        onBlur: D,
        onChange: function (e) {
          W && W(r(r({}, p), {
            currency: e.target.value || ""
          }), e), H && H(r(r({}, p), {
            currency: e.target.value || ""
          }), e);
        },
        onFocus: function (e) {
          L && L(e), K && K(e);
        }
      })
    }))]
  }));
};

export { h as CurrencyField };