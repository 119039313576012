import { produce } from 'immer';
import { CLOSE_DRAWER, CLOSING_DRAWER_WITH_ANSWER, REMOVE_DRAWER, SET_DRAWER } from './drawers.action-types';
var initialState = [];
/**
 * Drawers reducer.
 *
 * @author Mihail Siutsou
 * @category Reducers
 * @subcategory Drawers
 * @param {DrawersState} state state.
 * @param {ActionsTypes} action action.
 * @return {DrawersState[]} drawers state.
 */

var drawersReducer = function drawersReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_DRAWER:
      {
        return produce(state, function (draft) {
          var idx = state.findIndex(function (drawer) {
            return drawer.drawerId === action.payload.drawerId;
          });

          if (idx >= 0) {
            draft[idx] = {
              accepted: false,
              data: action.payload.data,
              drawerId: action.payload.drawerId,
              isOpen: action.payload.isOpen,
              rejected: false
            };
          } else {
            draft.push({
              accepted: false,
              data: action.payload.data,
              drawerId: action.payload.drawerId,
              isOpen: action.payload.isOpen,
              rejected: false
            });
          }
        });
      }

    case CLOSE_DRAWER:
      {
        return produce(state, function (draft) {
          var drawer = draft.find(function (drawer) {
            return drawer.drawerId === action.payload.drawerId;
          });

          if (drawer) {
            drawer.isOpen = false;
          }
        });
      }

    case CLOSING_DRAWER_WITH_ANSWER:
      {
        return produce(state, function (draft) {
          var drawer = draft.find(function (drawer) {
            return drawer.drawerId === action.payload.drawerId;
          });

          if (drawer) {
            drawer.accepted = action.payload.accepted;
            drawer.rejected = action.payload.rejected;
            drawer.isOpen = false;
            drawer.data = action.payload.data;
          }
        });
      }

    case REMOVE_DRAWER:
      {
        return produce(state, function (draft) {
          var index = draft.findIndex(function (drawer) {
            return drawer.drawerId === action.payload.drawerId;
          });
          if (index !== -1) draft.splice(index, 1);
        });
      }

    default:
      {
        return state;
      }
  }
};

export default drawersReducer;