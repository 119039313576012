import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, Stack, styled } from '@mui/material';
var StyledWrapper = styled(Stack)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1'
  }, theme.breakpoints.up('desktop'), {
    display: 'block'
  });

  return styles;
});
var StyledBox = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    margin: theme.spacing(0, 0, 1, 1)
  };
  return styles;
});
export { StyledBox, StyledWrapper };