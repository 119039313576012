import { __rest as r, __assign as t } from "../../../_virtual/_tslib.js";
import { jsx as o } from "react/jsx-runtime";
import { CurrencyTypography as a } from "../../currency-typography/currency-typography.component.js";

var e = function (e) {
  var m = e.value,
      n = r(e, ["value"]);
  return o(a, t({
    amount: m || 0
  }, n));
};

export { e as AmountField };