import { styled as t, Box as e } from "@mui/material";
import "../../themes/box-shadows/boxShadows.js";
import "../../themes/dark.theme.js";
import { pxToRem as i } from "../../themes/default-theme/default-theme.js";
import "../../themes/gradients/gradients.js";
import "../../themes/light.theme.js";
import { Button as o } from "../button/button.component.js";
var r = t(e)(function (t) {
  var e = t.theme.typography.pxToRem;
  return {
    "& img": {
      height: "100%",
      objectFit: "cover",
      width: "100%"
    },
    borderRadius: e(5),
    height: e(40),
    overflow: "hidden",
    width: e(66)
  };
}),
    n = t(o)(function (t) {
  return {
    height: "unset",
    padding: t.theme.spacing(2, 2, 2, 0),
    textAlign: "left",
    width: "100%"
  };
}),
    a = t(e)(function (t) {
  var e = t.$isWithArrow;
  return {
    display: "flex",
    flexDirection: "row",
    flexGrow: "1",
    height: "100%",
    justifyContent: "space-between",
    maxWidth: "calc(100% - ".concat(i(e ? 24 : 0), ")")
  };
}),
    h = t(e)(function (t) {
  var e = t.theme;
  return {
    alignSelf: "start",
    backgroundColor: t.$backgroundColor || e.palette.alpineGoat.main,
    borderRadius: i(5),
    fontSize: "0",
    lineHeight: "0",
    padding: e.spacing(.5, .75)
  };
});
export { h as StyledBox, n as StyledCard, a as StyledCardContent, r as StyledImageWrapper };