import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, styled } from '@mui/material';
import { pxToRem } from '@themes';
var StyledBanner = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    marginTop: theme.spacing(4)
  };
  return styles;
});
var StyledBox = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var height = theme.convertToDVH(100);

  var styles = _defineProperty({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: "calc(".concat(height, " - ").concat(pxToRem(40 + 50 + 68 + 32 + 16 + 16), ")"),
    paddingTop: theme.spacing(4),
    position: 'relative'
  }, theme.breakpoints.up('desktop'), {
    display: 'block',
    minHeight: 'unset'
  });

  return styles;
});
var StyledCurrencyRevertButtonWrapper = styled(Box)(function () {
  var styles = {
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
    zIndex: '2'
  };
  return styles;
});
var StyledButtonWrapper = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    marginTop: theme.spacing(5)
  };
  return styles;
});
export { StyledBanner, StyledBox, StyledButtonWrapper, StyledCurrencyRevertButtonWrapper };