import { __assign as n } from "../../_virtual/_tslib.js";
import { jsxs as t, jsx as r } from "react/jsx-runtime";
import { Typography as o } from "../typography/typography.component.js";
import { StyledPaper as e, StyledTable as i, StyledTableRow as a, StyledTableCell as c, StyledFooterWrapper as l } from "./simple-table.styles.js";

function p(p) {
  var m = p.columns,
      d = p.items,
      s = p.customId,
      u = p.footer,
      h = p.tableProps;
  return t(e, n({
    elevation: 0
  }, {
    children: [t(i, n({
      $tableLayout: null == h ? void 0 : h.tableLayout
    }, {
      children: [r(a, {
        children: m.map(function (t) {
          return r(c, n({}, t.props, {
            children: r(o, n({
              color: "abandonedSpaceship",
              variant: "body3"
            }, {
              children: t.title
            }))
          }), t.name.toString());
        })
      }), d.map(function (t) {
        var e = s ? t[s] : t.id;
        return r(a, {
          children: m.map(function (e) {
            var i = e.name,
                a = e.content ? e.content(t) : t[e.name];
            return r(c, n({}, e.props, {
              children: e.content ? a : r(o, n({
                variant: "body2"
              }, {
                children: a
              }))
            }), i);
          })
        }, e);
      })]
    })), u && r(l, {
      children: r(o, n({
        component: "div",
        variant: "body3"
      }, {
        children: u
      }))
    })]
  }));
}

export { p as SimpleTable };