export var DepositCalculatorFields;

(function (DepositCalculatorFields) {
  DepositCalculatorFields["Amount"] = "amount";
  DepositCalculatorFields["Capitalization"] = "capitalization";
  DepositCalculatorFields["FileName"] = "fileName";
  DepositCalculatorFields["Interest"] = "interest";
  DepositCalculatorFields["IsCapitalizationDisabled"] = "isCapitalizationDisabled";
  DepositCalculatorFields["IsProlongationDisabled"] = "isProlongationDisabled";
  DepositCalculatorFields["Prolongation"] = "prolongation";
  DepositCalculatorFields["Tax"] = "tax";
  DepositCalculatorFields["Term"] = "term";
  DepositCalculatorFields["Type"] = "type";
})(DepositCalculatorFields || (DepositCalculatorFields = {}));