import { Button, pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledBox = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    backgroundColor: theme.palette.white.main,
    borderRadius: pxToRem(16),
    padding: theme.spacing(3, 2, 1)
  };
  return styles;
});
var StyledFormField = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    '& input': {
      textAlign: 'center'
    },
    '& label': {
      textAlign: 'center'
    },
    '& p': {
      fontSize: "".concat(pxToRem(13)),
      textAlign: 'center'
    },
    paddingBottom: theme.spacing(2)
  };
  return styles;
});
var StyledBlock = styled(Box)(function (_ref3) {
  var _theme$colors;

  var theme = _ref3.theme;
  var styles = {
    borderTop: "".concat(pxToRem(1), " solid ").concat(theme === null || theme === void 0 ? void 0 : (_theme$colors = theme.colors) === null || _theme$colors === void 0 ? void 0 : _theme$colors.lavenderSyrup),
    marginTop: theme.spacing(2)
  };
  return styles;
});
var StyledButton = styled(Button)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    fontWeight: '500',
    padding: theme.spacing(2)
  };
  return styles;
});
export { StyledBlock, StyledBox, StyledButton, StyledFormField };