import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { alpha, AppBar, Box, Container, styled } from '@mui/material';
var StyledAppBar = styled(AppBar)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    '&.isShowShadow': {
      boxShadow: "0 ".concat(theme.typography.pxToRem(5), " ").concat(theme.typography.pxToRem(20), " ").concat(alpha(theme.colors.nightWizard, 0.08))
    },
    transition: theme.transitions.create(['background-color', 'box-shadow'])
  };
  return styles;
});
var StyledContentWrapper = styled(Box)(function (_ref2) {
  var theme = _ref2.theme,
      $isAdaptive = _ref2.$isAdaptive;
  var styles = {
    alignItems: 'center',
    display: 'flex',
    height: !$isAdaptive && theme.HEADER_HEIGHT,
    maxWidth: $isAdaptive && theme.typography.pxToRem(452)
  };
  return styles;
});
var StyledBox = styled(Box)(function (_ref3) {
  var theme = _ref3.theme,
      $isAdaptive = _ref3.$isAdaptive;

  var styles = _defineProperty({
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  }, theme.breakpoints.up('desktop'), {
    paddingTop: $isAdaptive ? theme.spacing(5) : undefined
  });

  return styles;
});
var StyledBoxSpacer = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    height: theme.HEADER_HEIGHT
  };
  return styles;
});
var StyledMobileContainer = styled(Container)(function (_ref5) {
  var theme = _ref5.theme,
      $isAdaptive = _ref5.$isAdaptive;
  var styles = {};

  if ($isAdaptive) {
    styles.paddingLeft = theme.spacing(2);
    styles.paddingRight = theme.spacing(2);
  }

  return styles;
});
export { StyledAppBar, StyledBox, StyledBoxSpacer, StyledContentWrapper, StyledMobileContainer };