var P, t, i, c;
!function (P) {
  P.Account = "account", P.Card = "card", P.Credit = "credit", P.Deposit = "deposit";
}(P || (P = {})), function (P) {
  P.BYN = "BYN", P.CNY = "CNY", P.EUR = "EUR", P.GBP = "GBP", P.RUB = "RUB", P.USD = "USD";
}(t || (t = {})), function (P) {
  P.Big = "Big", P.Medium = "Medium", P.Small = "Small";
}(i || (i = {})), function (P) {
  P.D = "d", P.DD = "dd", P.H = "H", P.HH = "HH", P.M = "M", P.MM = "MM", P.PP = "PP", P.PPP = "PP", P.PPPP = "PPPP", P.YY = "yy", P.YYYY = "yyyy", P.m = "m", P.mm = "mm", P.s = "s", P.ss = "ss";
}(c || (c = {}));
var n,
    o = {
  156: t.CNY,
  643: t.RUB,
  826: t.GBP,
  840: t.USD,
  933: t.BYN,
  978: t.EUR
};
!function (P) {
  P.Account = "accounts", P.Credit = "credits", P.Deposit = "deposits";
}(n || (n = {}));
export { P as CardsType, t as Currency, c as MomentToDaterFnsFormat, n as ProductsType, i as Size, o as currencyISOCode };