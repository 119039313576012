import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, buttonGroupClasses, styled } from '@mui/material';
import { pxToRem } from '@themes';
var StyledWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    display: 'flex',
    flexGrow: 1,
    position: 'relative'
  }, "& .".concat(buttonGroupClasses.root), {
    left: pxToRem(16),
    position: 'absolute',
    right: pxToRem(16),
    top: pxToRem(8),
    width: 'unset'
  });

  return styles;
});
export { StyledWrapper };