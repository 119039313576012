import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { pxToRem } from '@pwa/ui';
import { alpha, Box, List, ListItem, styled } from '@mui/material';
var StyledContent = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1'
  };
  return styles;
});
var StyledList = styled(List)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    paddingBottom: 0,
    paddingTop: 0
  };
  return styles;
});
var StyledDot = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    backgroundColor: theme.colors.abandonedSpaceship,
    borderRadius: '50%',
    flexShrink: 0,
    height: pxToRem(4),
    width: pxToRem(4)
  };
  return styles;
});
var StyledListItem = styled(ListItem)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  };
  return styles;
});
var StyledImageWrapper = styled(Box)(function (_ref5) {
  var theme = _ref5.theme;

  var styles = _defineProperty({
    '& img': {
      borderRadius: pxToRem(10),
      height: '100%',
      objectFit: 'cover',
      width: '100%'
    },
    // marginLeft:                  theme.spacing(9.75),
    // marginRight:                 theme.spacing(9.75),
    margin: '0 auto',
    maxWidth: pxToRem(219),
    position: 'relative'
  }, theme.breakpoints.up(456), {
    // margin:   'auto',
    maxWidth: pxToRem(300)
  });

  return styles;
});
var StyledShadow = styled(Box)(function (_ref6) {
  var theme = _ref6.theme;
  var styles = {
    backgroundColor: alpha(theme.colors.black, 0.2),
    borderRadius: 16,
    bottom: '-3%',
    filter: "blur(".concat(theme.typography.pxToRem(12.5), ")"),
    height: '6%',
    left: '-10%',
    pointerEvents: 'none',
    position: 'absolute',
    right: '-10%'
  };
  return styles;
});
var StyledSliderNavigation = styled(Box)(function (_ref7) {
  var _styles2;

  var theme = _ref7.theme;
  var styles = (_styles2 = {}, _defineProperty(_styles2, theme.breakpoints.up(456), {
    '&> button.arrow-left': {
      left: 'unset',
      right: '100%',
      transform: "translateX(-".concat(theme.typography.pxToRem(16), ")")
    },
    '&> button.arrow-right': {
      left: '100%',
      right: 'unset',
      transform: "translateX(".concat(theme.typography.pxToRem(16), ")")
    },
    left: '50%',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: pxToRem(300),
    position: 'absolute',
    top: '50%',
    transform: 'translateX(-50%)',
    width: '100%',
    zIndex: 1
  }), _defineProperty(_styles2, theme.breakpoints.up('tablet'), {
    '&> .arrow-left': {
      transform: "translateX(-".concat(theme.typography.pxToRem(24), ")")
    },
    '&> .arrow-right': {
      transform: "translateX(".concat(theme.typography.pxToRem(24), ")")
    }
  }), _styles2);
  return styles;
});
export { StyledContent, StyledDot, StyledImageWrapper, StyledList, StyledListItem, StyledShadow, StyledSliderNavigation };