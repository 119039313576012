import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, styled } from '@mui/material';
var StyledWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    backgroundColor: theme === null || theme === void 0 ? void 0 : theme.palette.white.main,
    borderBottomLeftRadius: theme.typography.pxToRem(16),
    borderBottomRightRadius: theme.typography.pxToRem(16),
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }, theme.breakpoints.up('desktop'), {
    backgroundColor: 'unset',
    borderRadius: 'unset',
    paddingBottom: 'unset',
    paddingTop: theme.spacing(4)
  });

  return styles;
});
var StyledIconWrapper = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    position: 'absolute',
    right: theme.typography.pxToRem(-40),
    top: "calc(50% - ".concat(theme.typography.pxToRem(12), ")")
  };
  return styles;
});
var StyledBox = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    backgroundColor: theme === null || theme === void 0 ? void 0 : theme.palette.lavenderSyrup.main,
    borderRadius: theme.typography.pxToRem(12),
    padding: theme.spacing(1.5, 2),
    whiteSpace: 'pre-line'
  };
  return styles;
});
var StyledDesktopWrapper = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;

  var styles = _defineProperty({}, theme.breakpoints.up('desktop'), {
    backgroundColor: theme === null || theme === void 0 ? void 0 : theme.palette.white.main,
    borderRadius: theme.typography.pxToRem(16),
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: theme.typography.pxToRem(452),
    padding: theme.spacing(3, 4)
  });

  return styles;
});
export { StyledBox, StyledDesktopWrapper, StyledIconWrapper, StyledWrapper };