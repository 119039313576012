import { styled as o, InputAdornment as t, FormControl as r, InputLabel as e, InputBase as n, inputBaseClasses as i, Box as a, FormHelperText as p } from "@mui/material";
import { SelectField as c } from "../select-field/select-field.component.js";
var l = o(t)(function (o) {
  var t = o.theme;
  return {
    marginLeft: t.spacing(1.875),
    marginRight: t.spacing(1)
  };
}),
    d = o(r)(function (o) {
  return {
    display: o.fullWidth && "flex",
    maxWidth: "100%"
  };
}),
    s = o(e)(function (o) {
  var t = o.theme;
  return {
    color: t.palette.secondary.main,
    display: "block",
    fontSize: t.typography.pxToRem(14),
    lineHeight: t.typography.pxToRem(24),
    marginBottom: t.spacing(.5),
    maxWidth: "100%",
    paddingLeft: t.spacing(2),
    position: "relative",
    transform: "unset",
    transformOrigin: "unset"
  };
}),
    m = o(n)(function (o) {
  var t,
      r = o.theme,
      e = o.color,
      n = o.error,
      a = r.typography.pxToRem,
      p = ((t = {})["& .MuiInputAdornment-root.MuiInputAdornment-positionEnd"] = {
    left: "calc(50% - ".concat(a(10), ")"),
    position: "absolute",
    top: a(20)
  }, t["& .MuiInputAdornment-root.MuiInputAdornment-positionStart"] = {
    fontSize: a(14),
    fontWeight: 400,
    left: 0,
    lineHeight: a(16),
    position: "absolute",
    top: a(20)
  }, t["& .MuiInputAdornment-root.MuiInputAdornment-positionStart > p"] = {
    color: n ? r.palette.error.main : r.colors.bayFog
  }, t.backgroundColor = r.colors.white, t.border = "".concat(a(1), " solid ").concat(r.colors.lavenderSyrup), t.borderRadius = a(12), t.color = r.colors.nightWizard, t.fontSize = a(16), t.fontWeight = 500, t.height = a(64), t.lineHeight = a(18), t["&.".concat(i.error)] = {
    border: "".concat(a(1), " solid ").concat(r.palette.error.main)
  }, t["&.".concat(i.focused)] = {
    border: "".concat(a(1), " solid ").concat(r.palette.primary.main)
  }, t["& .".concat(i.inputAdornedStart)] = {
    padding: 0
  }, t.overflow = "hidden", t.padding = r.spacing(3.75, 2, 1.25, 2), t.position = "relative", t["&.".concat(i.focused, " ").concat(l, " > p")] = {
    color: r.colors.panPurple
  }, t);
  if ("secondary" === e) p.backgroundColor = r.palette.secondary.dark, p.borderColor = r.palette.secondary.dark;
  return p;
}),
    u = o(a)(function (o) {
  var t = o.theme;
  return {
    fontSize: t.typography.pxToRem(12),
    left: 0,
    lineHeight: t.typography.pxToRem(14),
    marginTop: t.typography.pxToRem(4),
    position: "absolute",
    right: 0,
    top: "100%"
  };
}),
    g = o(p)(function (o) {
  var t = o.theme;
  return {
    color: t.palette.error.main,
    display: "block",
    margin: "unset",
    maxWidth: "100%",
    padding: t.spacing(0, 2)
  };
}),
    h = o(p)(function (o) {
  var t = o.theme,
      r = o.$color,
      e = {
    color: t.colors.abandonedSpaceship,
    display: "block",
    margin: "unset",
    maxWidth: "100%",
    padding: t.spacing(0, 1)
  };
  return "bayFog" === r && (e.color = t.colors.white), e;
}),
    y = o(c)(function (o) {
  var t = o.theme;
  return {
    "& .items-icon": {
      svg: {
        stroke: "unset"
      }
    },
    backgroundColor: t.colors.alpineGoat,
    border: "none",
    bottom: t.typography.pxToRem(12),
    height: t.typography.pxToRem(40),
    position: "absolute",
    right: t.typography.pxToRem(16),
    width: t.typography.pxToRem(110)
  };
});
export { u as StyleFromHelperWrapper, y as StyledAbsoluteInputBase, d as StyledFormControl, g as StyledFormHelperErrorText, h as StyledFormHelperText, l as StyledInputAdornment, m as StyledInputBase, s as StyledInputLabel };