import { __rest as i, __assign as e } from "../../../../_virtual/_tslib.js";
import { jsxs as r, jsx as t } from "react/jsx-runtime";
import { Box as a } from "@mui/material";
import { Typography as l } from "../../../typography/typography.component.js";
import { StyledBanner as o, StyledTypography as n, StyledArrowIcon as s, StyledBox as h } from "./super-rate.styles.js";
import m from "../../../../packages/pwa-ui/assets/images/arrow-colored.image.svg.js";

var c = function (c) {
  var d = c.heading,
      g = c.subHeading,
      p = c.oldRate,
      f = c.newRate,
      w = c.isWithArrows,
      u = void 0 !== w && w,
      y = c.image,
      v = i(c, ["heading", "subHeading", "oldRate", "newRate", "isWithArrows", "image"]);
  return r(o, e({}, v, {
    children: [r(a, e({
      textAlign: "left"
    }, {
      children: [d, g, r(a, e({
        alignItems: "baseline",
        display: "flex"
      }, {
        children: [p && t(a, e({
          display: "inline-block",
          mr: 1
        }, {
          children: t(n, e({
            color: "white",
            variant: "title2"
          }, {
            children: p
          }))
        })), t(l, e({
          color: "white",
          variant: "largeTitle1"
        }, {
          children: f
        })), u && r(a, e({
          alignSelf: "center",
          display: "flex",
          flexDirection: "column",
          ml: 1
        }, {
          children: [t(s, {
            height: 7,
            src: m,
            width: 12
          }), t(s, {
            height: 7,
            src: m,
            width: 12
          })]
        }))]
      }))]
    })), t(h, {
      children: t("img", {
        alt: "",
        height: "100%",
        src: y,
        width: "100%"
      })
    })]
  }));
};

export { c as SuperRate };