import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

/**
 * Clear field value.
 *
 * @author Yury Kuntsou
 * @function clearFieldValue
 * @category helpers
 * @param {[string]} name field.
 * @param {MutableState} MutableState from  final form.
 * @param {Tools} Tools from  final form.
 * @return {void} return.
 */
export var clearFieldValue = function clearFieldValue(values, MutableState, Tools) {
  if (!values[0]) {
    return;
  }

  var fieldName = values[0];
  Tools.changeValue(MutableState, "".concat(fieldName), function () {
    return '';
  });
  return;
};
/**
 * Set field value.
 *
 * @author Yury Kuntsou
 * @function setFieldValue
 * @category helpers
 * @param {[string, string]} name and value field.
 * @param {MutableState} MutableState from  final form.
 * @param {Tools} Tools from  final form.
 * @return {void} return.
 */

export var setFieldValue = function setFieldValue(values, MutableState, Tools) {
  if (!values[0]) {
    return;
  }

  var fieldName = values[0];
  var fieldValue = typeof values[1] === 'undefined' ? '' : values[1];
  Tools.changeValue(MutableState, "".concat(fieldName), function () {
    return fieldValue;
  });
  return;
};
/**
 * Sets the fields values of a record
 * @author Yury Kuntsou
 * @function setFieldsValues
 * @category helpers
 * @param {[T]} values  Values to set
 * @param {MutableState} MutableState Current state of mutable record
 * @param {Tools} Tools Object containing helper functions
 * @return {Mutator<T>} Mutator function
 */

export var setFieldsValues = function setFieldsValues() {
  return function (values, MutableState, Tools) {
    if (!values[0]) return;
    Object.entries(values[0]).forEach(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          name = _ref2[0],
          value = _ref2[1];

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      Tools.changeValue(MutableState, name, function () {
        return value;
      });
    });
  };
};
/**
 * Set error field.
 *
 * @author Yury Kuntsou
 * @function setErrorField
 * @category helpers
 * @param {[string, string]} name field, error text.
 * @param {MutableState} MutableState from  final form.
 * @param {Tools} Tools from  final form.
 * @return {Mutator} return.
 */

export var setErrorField = function setErrorField(values, MutableState, Tools) {
  if (typeof values[0] !== 'string') {
    return;
  }

  if (typeof values[1] !== 'string') {
    return;
  }

  var errors = MutableState.formState.errors;
  var newErrors = Tools.setIn(_objectSpread({}, errors), values[0], values[1]);
  MutableState.formState.errors = newErrors;
  return;
};
/**
 * Set submit error field.
 *
 * @author Yury Kuntsou
 * @function setSubmitErrorField
 * @category helpers
 * @param {[string, string]} name and value error field.
 * @param {MutableState} MutableState from  final form.
 * @param {Tools} Tools from  final form.
 * @return {Mutator} return.
 */

export var setSubmitErrorField = function setSubmitErrorField() {
  return function (values, MutableState, Tools) {
    if (typeof values[0] !== 'string') {
      return;
    }

    if (typeof values[1] !== 'string') {
      return;
    }

    var submitErrors = MutableState.formState.submitErrors;
    var newSubmitErrors = Tools.setIn(_objectSpread({}, submitErrors), values[0], values[1]);
    MutableState.formState.submitErrors = newSubmitErrors;
  };
};
/**
 * Clear submit errors field.
 *
 * @author Yury Kuntsou
 * @function clearSubmitErrorsField
 * @category helpers
 * @param {[name]} name field name.
 * @param {MutableState} MutableState from  final form.
 * @param {Tools} Tools from  final form.
 * @return {Mutator} return.
 */

export var clearSubmitErrorsField = function clearSubmitErrorsField() {
  return function (value, MutableState, Tools) {
    var name = value[0];

    if (MutableState.formState.submitErrors && MutableState.formState.submitErrors[name]) {
      var errors = _objectSpread({}, MutableState.formState.submitErrors);

      var newErrors = Tools.setIn(errors, String(name), undefined);
      MutableState.formState.submitErrors = newErrors;
    }
  };
};
/**
 * Clear submit error fields.
 *
 * @author Yury Kuntsou
 * @function clearSubmitErrorsField
 * @category helpers
 * @param {[name]} name field name.
 * @param {MutableState} MutableState from  final form.
 * @param {Tools} Tools from  final form.
 * @return {Mutator} return.
 */

export var clearSubmitErrorsFields = function clearSubmitErrorsFields() {
  return function (values, MutableState, Tools) {
    if (values.length && MutableState.formState.submitErrors) {
      var errors = _objectSpread({}, MutableState.formState.submitErrors);

      for (var index = 0; index < values.length; index++) {
        if (errors[values[index]]) {
          errors = Tools.setIn(errors, String(values[index]), undefined);
        }
      }

      MutableState.formState.submitErrors = errors;
    }
  };
};
/**
 * Reset submit errors all fields.
 *
 * @author Yury Kuntsou
 * @function resetSubmitErrorsFields
 * @category helpers
 * @param {[name]} name field name.
 * @param {MutableState} MutableState from  final form.
 * @param {Tools} Tools from  final form.
 * @return {Mutator} return.
 */

export var resetSubmitErrorsFields = function resetSubmitErrorsFields() {
  return function (_, MutableState) {
    if (MutableState.formState.submitErrors) {
      delete MutableState.formState.submitErrors;
    }
  };
};