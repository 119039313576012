import { __read as i, __assign as t } from "../../_virtual/_tslib.js";
import { jsx as r, Fragment as o, jsxs as n } from "react/jsx-runtime";
import { useState as e, Children as c } from "react";
import { Box as a, Stack as l, Collapse as p } from "@mui/material";
import { Icon as s } from "../icon/icon.component.js";
import { Typography as m } from "../typography/typography.component.js";
import { StyledList as h, StyledListItemButton as d, StyledListItemText as u, StyledCircularProgress as f, StyledBox as g } from "./controlled-list.styles.js";
import y from "../../packages/pwa-ui/assets/images/arrow-down.image.svg.js";
import v from "../../packages/pwa-ui/assets/images/arrow-up.image.svg.js";

var w = function (w) {
  var j = w.title,
      x = w.children,
      C = w.showTitle,
      b = w.hideTitle,
      k = w.itemsCount,
      I = w.defaultItemsCount,
      T = void 0 === I ? 3 : I,
      R = w.isLoading,
      W = w.withoutIcon,
      _ = i(e(!1), 2),
      A = _[0],
      E = _[1],
      L = c.toArray(x),
      O = L.slice(0, T);

  return r(o, {
    children: n(h, {
      children: [n(d, t({
        disableRipple: !0,
        disableTouchRipple: !0,
        onClick: W ? void 0 : function () {
          E(function (i) {
            return !i;
          });
        }
      }, {
        children: [r(u, {
          primary: n(a, t({
            display: "flex",
            gap: 1
          }, {
            children: [r(m, {
              children: "".concat(j, " ").concat(k ? "(".concat(k, ")") : "")
            }), R && r(a, t({
              alignItems: "center",
              display: "flex",
              justifyContent: "center"
            }, {
              children: r(f, {
                color: "primary"
              })
            }))]
          }))
        }), !W && r(o, {
          children: n(g, A ? {
            children: [r(m, t({
              color: "inherit",
              component: "p",
              fontWeight: 500,
              variant: "caption1"
            }, {
              children: b
            })), r(s, {
              height: 10,
              src: v,
              width: 10
            })]
          } : {
            children: [r(m, t({
              color: "inherit",
              component: "p",
              fontWeight: 500,
              variant: "caption1"
            }, {
              children: C
            })), r(s, {
              height: 10,
              src: y,
              width: 10
            })]
          })
        })]
      })), r(l, t({
        spacing: 1.5
      }, {
        children: O.map(function (i) {
          return i;
        })
      })), r(p, t({
        in: A,
        timeout: "auto",
        unmountOnExit: !0
      }, {
        children: r(l, t({
          spacing: 1.5
        }, {
          children: L.slice(T)
        }))
      }))]
    })
  });
};

export { w as ControlledList };