import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Button } from '@pwa/ui';
import { Box, Stack, styled } from '@mui/material';
var StyledWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  // const height = theme.convertToDVH(100);
  var styles = _defineProperty({
    // backgroundColor: theme.colors.white,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden'
  }, theme.breakpoints.up('desktop'), {
    overflow: 'unset'
  });

  return styles;
});
var StyledContentWrapper = styled(Stack)(function (_ref2) {
  var theme = _ref2.theme;

  var styles = _defineProperty({
    flexGrow: 1,
    justifyContent: 'space-between',
    overflow: 'hidden'
  }, theme.breakpoints.up('desktop'), {
    display: 'block',
    overflow: 'unset'
  });

  return styles;
});
var StyledContainer = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;

  var styles = _defineProperty({
    paddingBottom: theme.spacing(2)
  }, theme.breakpoints.up('desktop'), {
    maxWidth: theme.typography.pxToRem(452)
  });

  return styles;
});
var StyledContent = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;

  var styles = _defineProperty({
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    paddingTop: theme.spacing(3)
  }, theme.breakpoints.up('desktop'), {
    overflow: 'unset'
  });

  return styles;
});
var StyledButton = styled(Button)(function () {
  var styles = {
    justifyContent: 'flex-start'
  };
  return styles;
});
var StyledBox = styled(Box)(function (_ref5) {
  var theme = _ref5.theme;
  var styles = {
    margin: theme.spacing(0, 1, 1)
  };
  return styles;
});
var StyledButtonWrapper = styled(Box)(function (_ref6) {
  var theme = _ref6.theme;
  var styles = {
    backgroundColor: theme.palette.whiteSolid.main,
    padding: theme.spacing(2.5, 0, 5)
  };
  return styles;
});
export { StyledBox, StyledButton, StyledButtonWrapper, StyledContainer, StyledContent, StyledContentWrapper, StyledWrapper };