import { styled as e, Box as t } from "@mui/material";
import { CardsType as r } from "../../../../enums/entities/entities.enum.js";
import "../../../../enums/keyboard/keyboard.enum.js";
import "../../../../enums/theme/theme.enum.js";
import "../../../../themes/box-shadows/boxShadows.js";
import "../../../../themes/dark.theme.js";
import { pxToRem as m } from "../../../../themes/default-theme/default-theme.js";
import "../../../../themes/gradients/gradients.js";
import "../../../../themes/light.theme.js";
var o = e(t)(function (e) {
  var t = e.theme,
      o = e.$type,
      i = o !== r.Account && o ? t.colors.nightWizard : t.colors.panPurple,
      s = o === r.Card ? m(72) : m(44);
  return {
    "& img": {
      height: "100%",
      objectFit: "cover",
      width: "100%"
    },
    alignItems: "center",
    backgroundColor: o !== r.Card && i,
    borderRadius: o === r.Card ? m(5) : m(12),
    display: "flex",
    height: m(44),
    justifyContent: "center",
    width: s
  };
});
export { o as StyledWidget };