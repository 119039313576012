import { styled as e, Box as t, Drawer as o, Dialog as r } from "@mui/material";
import "../../themes/box-shadows/boxShadows.js";
import "../../themes/dark.theme.js";
import { pxToRem as n } from "../../themes/default-theme/default-theme.js";
import "../../themes/gradients/gradients.js";
import "../../themes/light.theme.js";
var i = e(t)(function (e) {
  var t = e.theme;
  return {
    backgroundColor: t.colors.carteBlanche,
    borderRadius: n(2),
    height: n(3),
    margin: t.spacing(1, "auto", 0, "auto"),
    opacity: "50%",
    width: n(60)
  };
}),
    m = e(t)(function (e) {
  return e.theme, {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden"
  };
}),
    a = e(o)(function (e) {
  return {
    zIndex: e.theme.zIndex.modal + 1
  };
}),
    d = e(r, {
  shouldForwardProp: function (e) {
    return "ModalProps" !== e && "SlideProps" !== e;
  }
})(function (e) {
  return {
    zIndex: e.theme.zIndex.modal + 1
  };
});
export { m as DrawerContainer, i as Puller, d as StyledDesktopDrawer, a as StyledDrawer };