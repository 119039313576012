import { produce } from 'immer';
import { SET_MAP_DATA } from './data.action-types';
var initialState = {
  point: []
};
/**
 * Data reducer.
 *
 * @author Christina Kashevar
 * @category Reducers
 * @subcategory Data
 * @param {DataState} state state.
 * @param {ActionsTypes} action action.
 * @return {DataState} data state.
 */

var dataReducer = function dataReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_MAP_DATA:
      {
        return produce(state, function (draft) {
          draft.point = action.payload.point;
        });
      }

    default:
      {
        return state;
      }
  }
};

export default dataReducer;