import { produce } from 'immer';
import { CLOSE_MODAL, CLOSING_MODAL_WITH_ANSWER, SET_MODAL } from './modals.action-types';
var initialState = [];
/**
 * Modals reducer.
 *
 * @author Ihar Kazlouski
 * @category Reducers
 * @subcategory Modals
 * @param {ModalsState} state state.
 * @param {ActionsTypes} action action.
 * @return {ModalsState[]} modals state.
 */

var modalsReducer = function modalsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_MODAL:
      {
        return produce(state, function (draft) {
          var idx = state.findIndex(function (modal) {
            return modal.modalId === action.payload.modalId;
          });

          if (idx >= 0) {
            draft[idx] = {
              accepted: false,
              data: action.payload.data,
              isDisabledCloseAccepted: action.payload.isDisabledCloseAccepted,
              isOpen: action.payload.isOpen,
              modalId: action.payload.modalId,
              rejected: false
            };
          } else {
            draft.push({
              accepted: false,
              data: action.payload.data,
              isDisabledCloseAccepted: action.payload.isDisabledCloseAccepted,
              isOpen: action.payload.isOpen,
              modalId: action.payload.modalId,
              rejected: false
            });
          }
        });
      }

    case CLOSE_MODAL:
      {
        return produce(state, function (draft) {
          var modal = draft.find(function (modal) {
            return modal.modalId === action.payload.modalId;
          });

          if (modal) {
            modal.isOpen = false;
            modal.isDisabledCloseAccepted = false;
          }
        });
      }

    case CLOSING_MODAL_WITH_ANSWER:
      {
        return produce(state, function (draft) {
          var modal = draft.find(function (modal) {
            return modal.modalId === action.payload.modalId;
          });

          if (modal) {
            modal.accepted = action.payload.accepted;
            modal.rejected = action.payload.rejected;

            if (!action.payload.isDisabledCloseAccepted) {
              modal.isOpen = false;
            }

            modal.data = action.payload.data;
          }
        });
      }

    default:
      {
        return state;
      }
  }
};

export default modalsReducer;