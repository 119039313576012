import { __assign as o } from "../../../../_virtual/_tslib.js";
import { jsx as e, jsxs as t } from "react/jsx-runtime";
import { useNumericFormat as r } from "react-number-format";
import { CardsType as a } from "../../../../enums/entities/entities.enum.js";
import "../../../../enums/keyboard/keyboard.enum.js";
import "../../../../enums/theme/theme.enum.js";
import { Typography as i } from "../../../typography/typography.component.js";
import { StyledInfo as n } from "./credit-debit-money-info.styles.js";

var c = function (c) {
  var m = c.date,
      p = c.sum,
      d = c.cardType,
      s = c.currency,
      u = c.finishDate,
      l = c.isLoading,
      f = r({
    decimalScale: 2,
    decimalSeparator: ",",
    fixedDecimalScale: !0,
    thousandSeparator: " "
  }).format;
  return e(n, {
    children: d === a.Deposit ? e(i, o({
      color: l ? "bayFog" : "abandonedSpaceship",
      variant: "caption1"
    }, {
      children: void 0 !== m && u
    })) : t(i, o({
      color: l ? "bayFog" : "abandonedSpaceship",
      variant: "caption1"
    }, {
      children: [void 0 !== m && "".concat(m, " ").concat(d === a.Credit ? "•" : "≈", " "), void 0 !== p && f && "".concat(f("".concat(p))).concat(s ? " ".concat(s) : "")]
    }))
  });
};

export { c as CreditDebitMoneyInfo };