export var CreditFormFieldType;

(function (CreditFormFieldType) {
  CreditFormFieldType["Checkbox"] = "CHECKBOX";
  CreditFormFieldType["Input"] = "INPUT";
  CreditFormFieldType["Select"] = "SELECT";
})(CreditFormFieldType || (CreditFormFieldType = {}));

export var CreditFormFieldInputType;

(function (CreditFormFieldInputType) {
  CreditFormFieldInputType["Int"] = "Int";
  CreditFormFieldInputType["String"] = "String";
})(CreditFormFieldInputType || (CreditFormFieldInputType = {}));

export var CreditStatus;

(function (CreditStatus) {
  CreditStatus[CreditStatus["Exist"] = 1] = "Exist";
  CreditStatus[CreditStatus["LessAmount"] = 4] = "LessAmount";
})(CreditStatus || (CreditStatus = {}));

export var CreditCardType;

(function (CreditCardType) {
  CreditCardType["Instant"] = "instant";
  CreditCardType["Personalized"] = "personalized";
})(CreditCardType || (CreditCardType = {}));

export var CreditClientAction;

(function (CreditClientAction) {
  CreditClientAction["Next"] = "NEXT";
  CreditClientAction["Previous"] = "PREVIOUS";
})(CreditClientAction || (CreditClientAction = {}));