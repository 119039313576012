import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, Container, styled } from '@mui/material';
var StyledPageWrapper = styled(Container)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  };
  return styles;
});
var StyledContentWrapper = styled(Box)(function (_ref2) {
  var _styles;

  var theme = _ref2.theme,
      $center = _ref2.$center;
  var styles = (_styles = {}, _defineProperty(_styles, theme.breakpoints.up('desktop'), {
    display: 'block',
    marginLeft: $center ? 'auto' : undefined,
    marginRight: $center ? 'auto' : undefined,
    maxWidth: theme.typography.pxToRem(452),
    paddingTop: theme.spacing(1)
  }), _defineProperty(_styles, "display", 'flex'), _defineProperty(_styles, "flex", 1), _defineProperty(_styles, "flexDirection", 'column'), _defineProperty(_styles, "paddingBottom", theme.spacing(4)), _defineProperty(_styles, "width", '100%'), _styles);
  return styles;
});
export { StyledContentWrapper, StyledPageWrapper };