import { Button, pxToRem, Typography } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledImageLargeWrapper = styled(Box)(function () {
  var styles = {
    '& img': {
      height: '100%',
      objectFit: 'cover',
      width: '100%'
    },
    alignItems: 'center',
    borderRadius: pxToRem(4),
    display: 'flex',
    flex: '32px 0 0',
    height: pxToRem(32),
    overflow: 'hidden',
    width: pxToRem(32)
  };
  return styles;
});
var StyledImageSmallWrapper = styled(Box)(function () {
  var styles = {
    '& img': {
      height: '100%',
      objectFit: 'cover',
      width: '100%'
    },
    alignItems: 'center',
    borderRadius: pxToRem(16),
    display: 'flex',
    height: pxToRem(60),
    overflow: 'hidden',
    width: pxToRem(60)
  };
  return styles;
});
var StyledButton = styled(Button)(function () {
  var styles = {
    padding: 0
  };
  return styles;
});
var StyledTypography = styled(Typography)(function () {
  var styles = {
    wordBreak: 'break-word'
  };
  return styles;
});
var StyledBoxIcon = styled(Box)(function (_ref) {
  var theme = _ref.theme,
      isCanDrop = _ref.isCanDrop;
  var styles = {
    alignItems: 'center',
    backgroundColor: isCanDrop ? theme.palette.aragonGreen.main : theme.palette.bayFog.main,
    borderRadius: '50%',
    color: 'white',
    display: 'flex',
    height: theme.typography.pxToRem(24),
    justifyContent: 'center',
    pointerEvents: 'none',
    position: 'absolute',
    right: -10,
    top: -10,
    width: theme.typography.pxToRem(24),
    zIndex: 1
  };
  return styles;
});
export { StyledBoxIcon, StyledButton, StyledImageLargeWrapper, StyledImageSmallWrapper, StyledTypography };