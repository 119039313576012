import { createSelector } from 'reselect';

/**
 * Get Grids array.
 *
 * @author Ihar Kazlouski
 * @function getGrids
 * @category Selectors
 * @subcategory Grids
 * @param {AppState} state state.
 * @return {GridsState[]} grids state.
 */
var getGrids = function getGrids(state) {
  return state.grids;
};

export var getGridsSelector = createSelector(getGrids, function (grids) {
  return grids;
});
/**
 * Get Grid by id.
 *
 * @author Yury Kuntsou
 * @function getGridSelector
 * @category Selectors
 * @subcategory Grids
 * @param {GridState["gridId"]} gridId gridId.
 * @return {GridsState} grid state.
 */

export var getGridSelector = function getGridSelector(gridId) {
  return createSelector(getGridsSelector, function (grids) {
    return grids.find(function (grid) {
      return grid.gridId === gridId;
    });
  });
};
/**
 * Get Grid data by id.
 *
 * @author Yury Kuntsou
 * @function getGridDataSelector
 * @category Selectors
 * @subcategory Grids
 * @param {GridState["gridId"]} gridId gridId.
 * @return {GridsState["data"]} grid  data.
 */

export var getGridDataSelector = function getGridDataSelector(gridId) {
  return createSelector(getGridSelector(gridId), function (grid) {
    return (grid === null || grid === void 0 ? void 0 : grid.data) || undefined;
  });
};