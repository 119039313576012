import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { SnackbarContent } from 'notistack';
import { pxToRem } from '@pwa/ui';
import { styled } from '@mui/material';
var StyledSnackbar = styled(SnackbarContent)(function (_ref) {
  var theme = _ref.theme,
      $variant = _ref.$variant;
  var backgroundColor = {
    "default": theme.palette.nightWizard.main,
    error: theme.palette.error.main,
    info: theme.palette.info.main,
    success: theme.palette.aragonGreen.main,
    warning: theme.palette.warning.main
  };

  var styles = _defineProperty({
    backgroundColor: backgroundColor[$variant],
    borderRadius: theme.typography.pxToRem(16),
    color: theme.palette.white.main,
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(0, 2),
    padding: theme.spacing(1.5, 0)
  }, theme.breakpoints.up('tablet'), {
    width: pxToRem(400)
  });

  return styles;
});
export { StyledSnackbar };