import { __assign as r } from "../../../../_virtual/_tslib.js";
import { jsx as e, jsxs as t } from "react/jsx-runtime";
import { Box as m } from "@mui/material";
import { getLastFourCardDigits as i } from "../../../../helpers/card-number/card-number.helper.js";
import "react";
import "../../../../enums/entities/entities.enum.js";
import "../../../../enums/keyboard/keyboard.enum.js";
import "../../../../enums/theme/theme.enum.js";
import "../../../../node_modules/@babel/runtime/helpers/typeof.js";
import { Typography as o } from "../../../typography/typography.component.js";
import { StyledNumberBox as n } from "./cards-number.styles.js";

var a = function (a) {
  var p = a.number,
      s = a.variant,
      l = a.color;
  return e(m, r({
    alignItems: "center",
    display: "flex"
  }, {
    children: e(m, r({
      display: "flex",
      ml: 1
    }, {
      children: e(n, r({
        $color: l,
        $variant: s
      }, {
        children: t(o, r({
          color: "nightWizard",
          fontWeight: 500,
          variant: "caption2"
        }, {
          children: ["**** ", i(p)]
        }))
      }))
    }))
  }));
};

export { a as CardsNumber };