import { __rest as e, __assign as r } from "../../../_virtual/_tslib.js";
import { jsxs as t, jsx as o } from "react/jsx-runtime";
import { Box as i } from "@mui/material";
import { Typography as l } from "../../typography/typography.component.js";
import { StyledFormControl as c, StyledInputLabel as d, StyledSwitchLabel as a, StyledSwitch as n, StyleFromHelperWrapper as h, StyledFormHelperErrorText as s, StyledFormHelperText as m } from "./switch-field.styles.js";

var p = function (p) {
  var u = p.error;
  p.deviceSize, p.t;
  var v = p.value,
      b = p.focused,
      f = p.fullWidth,
      y = p.name,
      k = p.color,
      x = p.title,
      g = p.subtitle,
      j = p.bottomLabel,
      W = p.label;
  p.active;
  var $ = p.checked,
      F = p.reverse,
      S = e(p, ["error", "deviceSize", "t", "value", "focused", "fullWidth", "name", "color", "title", "subtitle", "bottomLabel", "label", "active", "checked", "reverse"]),
      w = y && "".concat(y, "-switchfield"),
      z = !!u,
      I = {
    errorId: w && "".concat(w, "-error-text"),
    htmlFor: w,
    id: w
  },
      L = "string" == typeof v ? "true" === v : $;
  return t(c, r({
    $reverse: F,
    error: z,
    focused: b,
    fullWidth: f
  }, {
    children: [W && o(d, r({
      $color: k,
      htmlFor: I.htmlFor,
      shrink: !0
    }, {
      children: W
    })), o(a, {
      $reverse: F,
      control: o(n, r({
        "aria-describedby": w,
        error: u || "",
        id: I.id,
        inputProps: {
          id: I.id,
          type: "checkbox"
        },
        name: y,
        type: "checkbox"
      }, S, {
        checked: L
      })),
      label: t(i, {
        children: [o(l, r({
          fontWeight: 500,
          variant: "body1"
        }, {
          children: x
        })), g && o(l, r({
          color: "abandonedSpaceship",
          variant: "caption1"
        }, {
          children: g
        }))]
      })
    }), z && o(h, {
      children: o(s, r({
        id: I.errorId
      }, {
        children: u
      }))
    }), j && !z ? o(h, {
      children: o(m, r({
        $color: k
      }, {
        children: j
      }))
    }) : null]
  }));
};

export { p as SwitchField };