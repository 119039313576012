import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, Container, styled } from '@mui/material';
var StyledContainer = styled(Container)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  }, theme.breakpoints.up('desktop'), {
    display: 'block'
  });

  return styles;
});
var StyledWrapper = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;

  var styles = _defineProperty({
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  }, theme.breakpoints.up('desktop'), {
    display: 'block',
    marginTop: theme.spacing(4),
    maxWidth: theme.typography.pxToRem(452)
  });

  return styles;
});
export { StyledContainer, StyledWrapper };