import { Currency, PaymentNextStep, PaymentTypeForm } from '@enums';
export var getEripNodeList = function getEripNodeList() {
  return {
    items: [{
      id: 304612,
      isFinalPoint: true,
      title: 'QA_ASSIST'
    }, {
      id: 304194,
      isFinalPoint: false,
      title: 'Банковские, финансовые услуги'
    }, {
      id: 300001,
      isFinalPoint: false,
      title: 'БЕЛТЕЛЕКОМ'
    }, {
      id: 304566,
      isFinalPoint: false,
      title: 'Билеты, лотереи'
    }, {
      id: 300002,
      isFinalPoint: false,
      title: 'Домофонные системы, Видеонаблюдение'
    }, {
      id: 300088,
      isFinalPoint: false,
      title: 'Интернет, Телевидение, Телефония'
    }, {
      id: 300004,
      isFinalPoint: false,
      title: 'Интернет-магазины/сервисы'
    }, {
      id: 304489,
      isFinalPoint: false,
      title: 'Информационные услуги'
    }, {
      id: 300005,
      isFinalPoint: false,
      title: 'Коммунальные платежи'
    }, {
      id: 300006,
      isFinalPoint: false,
      title: 'Мобильная связь'
    }, {
      id: 304526,
      isFinalPoint: false,
      title: 'Налоги'
    }, {
      id: 304551,
      isFinalPoint: false,
      title: 'Образование и развитие'
    }, {
      id: 304615,
      isFinalPoint: false,
      title: 'Прочие платежи'
    }, {
      id: 304536,
      isFinalPoint: false,
      title: 'Соцобслуживание, здравоохранение'
    }, {
      id: 304351,
      isFinalPoint: false,
      title: 'Стоянки, гаражи, парковки'
    }, {
      id: 304168,
      isFinalPoint: false,
      title: 'Суды, Юстиция, Юридические услуги'
    }, {
      id: 304577,
      isFinalPoint: false,
      title: 'Таможенные платежи'
    }, {
      id: 304600,
      isFinalPoint: false,
      title: 'Туризм и Отдых'
    }, {
      id: 202,
      isFinalPoint: false,
      title: 'Билеты, Лотереи'
    }, {
      id: 203,
      isFinalPoint: false,
      title: 'Домофонные системы'
    }, {
      id: 204,
      isFinalPoint: false,
      title: 'Защита корзины платежей плательщика'
    }, {
      id: 205,
      isFinalPoint: false,
      title: 'Интернет, Телевидение'
    }, {
      id: 206,
      isFinalPoint: false,
      title: 'Интернет-магазины/сервисы'
    }, {
      id: 207,
      isFinalPoint: false,
      title: 'Информационные услуги'
    }, {
      id: 208,
      isFinalPoint: false,
      title: 'МВД'
    }, {
      id: 209,
      isFinalPoint: false,
      title: 'Мобильная связь'
    }, {
      id: 210,
      isFinalPoint: false,
      title: 'Образование и развитие'
    }, {
      id: 211,
      isFinalPoint: false,
      title: 'Оплата по коду услуги'
    }, {
      id: 212,
      isFinalPoint: false,
      title: 'Пожертвования, благотворительность'
    }, {
      id: 213,
      isFinalPoint: false,
      title: 'Прочие республиканские услуги'
    }, {
      id: 214,
      isFinalPoint: false,
      title: 'Прочие услуги'
    }, {
      id: 215,
      isFinalPoint: false,
      title: 'СМИ'
    }, {
      id: 216,
      isFinalPoint: false,
      title: 'Социальное обслуживание, Здравоохранение'
    }, {
      id: 217,
      isFinalPoint: false,
      title: 'Стоянки, гаражи, парковки'
    }, {
      id: 218,
      isFinalPoint: false,
      title: 'Таможенные платежи'
    }, {
      id: 219,
      isFinalPoint: false,
      title: 'Туристические услуги'
    }, {
      id: 220,
      isFinalPoint: false,
      title: 'Финансовые услуги'
    }, {
      id: 17,
      isFinalPoint: false,
      title: 'г. Брест'
    }, {
      id: 18,
      isFinalPoint: false,
      title: 'г. Витебск'
    }, {
      id: 19,
      isFinalPoint: false,
      title: 'г. Гомель'
    }, {
      id: 20,
      isFinalPoint: false,
      title: 'г. Гродно'
    }, {
      id: 4,
      isFinalPoint: false,
      title: 'Брестская обл.'
    }, {
      id: 5,
      isFinalPoint: false,
      title: 'Витебская обл.'
    }, {
      id: 1,
      isFinalPoint: false,
      title: 'Гомельская обл.'
    }, {
      id: 2,
      isFinalPoint: false,
      title: 'Гродненская обл.'
    }, {
      id: 3,
      isFinalPoint: false,
      title: 'Минская обл.'
    }, {
      id: 6,
      isFinalPoint: false,
      title: 'Могилевская обл.'
    }],
    tree: [{
      id: null,
      title: 'Платежи'
    }]
  };
};
export var getEripPaymentInitMock = function getEripPaymentInitMock() {
  return {
    description: ['', 'Введите лицевой счет'],
    iconUrl: '${erip.iconUrlDefault}',
    nextStep: PaymentNextStep.ServiceInfo,
    parameters: [{
      dataType: 0,
      editable: true,
      id: 1,
      maxLength: 30,
      minLength: 1,
      name: 'Лицевой счет',
      required: false
    }, {
      dataType: 0,
      defaultValue: '24.04.2023',
      editable: false,
      id: 2,
      name: 'Дата выставления',
      required: false
    }, {
      dataType: 3,
      defaultValue: '0523',
      editable: true,
      formatDataType: 'MMYY',
      id: 3,
      maxLength: 4,
      minLength: 4,
      name: 'Период 1 MMYY',
      required: true
    }, {
      dataType: 3,
      defaultValue: '01.05.23',
      editable: true,
      formatDataType: 'DD.MM.YY',
      id: 4,
      maxLength: 8,
      minLength: 8,
      name: 'Период 2 DD.MM.YY',
      required: true
    }, {
      dataType: 3,
      defaultValue: '010523',
      editable: true,
      formatDataType: 'DDMMYY',
      id: 5,
      maxLength: 6,
      minLength: 6,
      name: 'Период 3 DDMMYY',
      required: true
    }, {
      dataType: 3,
      defaultValue: '23',
      editable: true,
      formatDataType: 'YY',
      id: 6,
      maxLength: 2,
      minLength: 2,
      name: 'Период 4 YY',
      required: true
    }, {
      dataType: 3,
      defaultValue: '01.05.2023',
      editable: true,
      formatDataType: 'DD.MM.YYYY',
      id: 7,
      maxLength: 2,
      minLength: 2,
      name: 'Период 5 DD.MM.YYYY',
      required: true
    }, {
      dataType: 0,
      defaultValue: '9273',
      editable: false,
      id: 8,
      name: 'Счётчик 1-предыдущ. знач.',
      required: false
    }, {
      dataType: 2,
      editable: true,
      id: 9,
      maxLength: 11,
      minLength: 0,
      name: 'Счётчик 1-текущее   знач.',
      required: false
    }, {
      dataType: 5,
      defaultValue: 'false',
      editable: true,
      id: 10,
      name: 'Согласие',
      required: false
    }, {
      dataType: 1,
      editable: true,
      id: 11,
      maxLength: 10,
      minLength: 1,
      name: 'Введите количество',
      required: false
    }],
    personalAccount: 'BY13123333',
    serviceId: 1,
    serviceName: 'Абонентская плата',
    sorCheckRequired: false,
    transactionId: '841c9df9-9bd8-4efc-a9a9-6612d9b5dc27'
  };
};
export var getEripPaymentSubmitMock = function getEripPaymentSubmitMock(type) {
  return {
    amount: {
      amount_Budget: 0,
      currency: Currency.BYN,
      defaultValue: 0,
      editable: true,
      fineAmount: 0,
      maxAmount: 200,
      minAmount: 0.5
    },
    data: [{
      name: 'test 1',
      value: 'test 1 value'
    }, {
      name: 'test 2',
      value: 'test 2 value'
    }],
    description: ['', 'Введите лицевой счет'],
    iconUrl: '${erip.iconUrlDefault}',
    nextStep: PaymentNextStep.TransactionStart,
    personalAccount: 'BY13123333',
    serviceId: 1,
    serviceName: 'Абонентская плата',
    sorCheckRequired: false,
    transactionId: '841c9df9-9bd8-4efc-a9a9-6612d9b5dc27',
    typeForm: type || PaymentTypeForm.INPUT
  };
};
export var getEripPaymentPopularMock = function getEripPaymentPopularMock() {
  return [{
    id: 1,
    isFinalPoint: true,
    serviceId: 393931,
    shortcutName: 'Мобильный МТС',
    shortcutUrl: 'https://dev-frontend-pwa.lab.vebtech.by/remote/operationspicture/table1/mts.svg'
  }, {
    id: 2,
    isFinalPoint: true,
    serviceId: 377801,
    shortcutName: 'Покупка ЖД билетов',
    shortcutUrl: 'https://dev-frontend-pwa.lab.vebtech.by/remote/operationspicture/table1/b4.svg'
  }];
};