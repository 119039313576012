import { pxToRem } from '@pwa/ui';
import { ListItem, styled } from '@mui/material';
var StyledItem = styled(ListItem)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    alignItems: 'center',
    backgroundColor: theme.palette.white.main,
    borderRadius: pxToRem(16),
    cursor: 'grab',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2)
  };
  return styles;
});
export { StyledItem };