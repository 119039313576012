import { styled as e, Box as r, Skeleton as o } from "@mui/material";
import "react";
import { getHexColor as t } from "../../helpers/colors/colors.helper.js";
import "../../enums/entities/entities.enum.js";
import "../../enums/keyboard/keyboard.enum.js";
import "../../enums/theme/theme.enum.js";
import "../../node_modules/@babel/runtime/helpers/typeof.js";
var i = e(r)(function (e) {
  var r;
  e.theme;
  var o = e.$borderRadius,
      t = e.$height,
      i = e.$width;
  return (r = {})["& img"] = {
    aspectRatio: t && "number" == typeof t && i && "number" == typeof i ? "auto ".concat(i, "/").concat(t) : void 0,
    display: "block",
    maxHeight: "100%",
    maxWidth: "100%"
  }, r.borderRadius = o, r.overflow = "hidden", r.position = "relative", r;
}),
    m = e(o)(function (e) {
  var r = e.theme,
      o = e.$backgroundColor,
      i = e.$borderRadius;
  return {
    backgroundColor: t(r.palette, o),
    borderRadius: i,
    flex: 1,
    height: "100%",
    width: "100%"
  };
});
export { i as StyledImageWrapper, m as StyledSkeleton };