import { __rest as e, __assign as i, __spreadArray as n, __read as r } from "../../../_virtual/_tslib.js";
import { jsx as o } from "react/jsx-runtime";
import { createElement as t } from "react";
import { FormControl as a } from "@mui/material";
import { CheckboxField as l } from "../checkbox-field/checkbox-field.component.js";

var c = function (c) {
  c.active;
  var m = c.onChange,
      u = c.items,
      v = c.value;
  c.deviceSize;

  var f = e(c, ["active", "onChange", "items", "value", "deviceSize"]),
      d = function (e, i) {
    var o = e.target.name;

    if (null == v ? void 0 : v.includes(o)) {
      if (!i) {
        var t = null == v ? void 0 : v.indexOf(o),
            a = n([], r(v), !1);
        a.splice(t, 1), null == m || m(a);
      }
    } else v && (null == m || m(n(n([], r(v), !1), [o], !1)));
  };

  return o(a, i({}, f, {
    children: u.map(function (e) {
      return t(l, i({}, e, {
        key: e.name,
        checked: null == v ? void 0 : v.includes(e.name || ""),
        onChange: d
      }));
    })
  }));
};

export { c as CheckboxFieldsGroup };