import { __rest as t, __read as o, __assign as n } from "../../../_virtual/_tslib.js";
import { jsx as r } from "react/jsx-runtime";
import { useState as e, useCallback as i, useMemo as p } from "react";
import { TextField as m } from "../text-field/text-field.component.js";
import { PasswordToggleButton as u } from "./components/password-toggle-button/password-toggle-button.component.js";

var l = function (l) {
  var s = l.type,
      a = void 0 === s ? "password" : s,
      f = t(l, ["type"]),
      d = o(e(a), 2),
      c = d[0],
      v = d[1],
      g = i(function (t) {
    v(function () {
      return t;
    });
  }, []),
      y = p(function () {
    var t = null == f ? void 0 : f.value;
    return "string" == typeof t ? t.length > 0 : !!t;
  }, [null == f ? void 0 : f.value]);
  return r(m, n({}, f, {
    endAdornment: y && r(u, {
      type: c,
      onChange: g
    }),
    type: c
  }));
};

export { l as PasswordField };