import { dateFormatter, getPriorYMDDate } from '@pwa/ui';
import { i18n } from '@localization';

/**
 * Transform history operations by date.
 *
 * @author Christina Kashevar
 * @function transformHistory
 * @category helpers
 * @return {FC} icon component.
 */
var transformHistory = function transformHistory(data) {
  var today = dateFormatter(new Date(), 'dd.MM.yyyy');
  var yesterday = dateFormatter(getPriorYMDDate(1), 'dd.MM.yyyy');
  var transformedHistoryWithDateKeys = data.reduce(function (acc, item) {
    var transactionDate = dateFormatter(item.operationDate, 'dd.MM.yyyy');
    var date = transactionDate === today ? "".concat(i18n.t('common.history.today')) : transactionDate === yesterday ? "".concat(i18n.t('common.history.yesterday')) : transactionDate;
    acc[date] ? acc[date].push(item) : acc[date] = [item];
    return acc;
  }, {});
  return Object.entries(transformedHistoryWithDateKeys);
};

export { transformHistory };