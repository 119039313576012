import { Button, pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledBox = styled(Button)(function (_ref) {
  var _theme$colors, _theme$colors2;

  var theme = _ref.theme;
  var styles = {
    '&:not(:last-child)': {
      borderBottom: "".concat(pxToRem(1), " solid ").concat(theme === null || theme === void 0 ? void 0 : (_theme$colors = theme.colors) === null || _theme$colors === void 0 ? void 0 : _theme$colors.lavenderSyrup)
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    '&:has(li.MuiBox-root)': {
      borderBottom: "".concat(pxToRem(1), " solid ").concat(theme === null || theme === void 0 ? void 0 : (_theme$colors2 = theme.colors) === null || _theme$colors2 === void 0 ? void 0 : _theme$colors2.lavenderSyrup)
    },
    height: 'unset',
    padding: theme.spacing(2)
  };
  return styles;
});
var StyledLabel = styled(Box)(function (_ref2) {
  var _theme$palette, _theme$palette$alpine;

  var theme = _ref2.theme,
      $bgColor = _ref2.$bgColor;
  var styles = {
    alignItems: 'center',
    backgroundColor: $bgColor || (theme === null || theme === void 0 ? void 0 : (_theme$palette = theme.palette) === null || _theme$palette === void 0 ? void 0 : (_theme$palette$alpine = _theme$palette.alpineGoat) === null || _theme$palette$alpine === void 0 ? void 0 : _theme$palette$alpine.main),
    borderRadius: pxToRem(5),
    display: 'flex',
    padding: theme.spacing(0.5, 0.75)
  };
  return styles;
});
var StyledItem = styled(Box)(function (_ref3) {
  var _theme$palette2, _theme$palette2$alpin;

  var theme = _ref3.theme;
  var styles = {
    alignItems: 'center',
    border: "1px solid ".concat(theme === null || theme === void 0 ? void 0 : (_theme$palette2 = theme.palette) === null || _theme$palette2 === void 0 ? void 0 : (_theme$palette2$alpin = _theme$palette2.alpineGoat) === null || _theme$palette2$alpin === void 0 ? void 0 : _theme$palette2$alpin.main),
    borderRadius: pxToRem(5),
    display: 'flex',
    padding: theme.spacing(0.375, 0.75)
  };
  return styles;
});
var StyledButton = styled(Button)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    borderRadius: '50%',
    height: 'unset',
    minWidth: 'unset',
    padding: theme.spacing(0.5)
  };
  return styles;
});
export { StyledBox, StyledButton, StyledItem, StyledLabel };