import { pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    boxShadow: theme.boxShadows.independence
  };
  return styles;
});
var StyledTopBox = styled(Box)(function (_ref2) {
  var _theme$colors;

  var theme = _ref2.theme;
  var styles = {
    backgroundColor: theme.palette.white.main,
    borderBottom: "".concat(pxToRem(1), " solid ").concat(theme === null || theme === void 0 ? void 0 : (_theme$colors = theme.colors) === null || _theme$colors === void 0 ? void 0 : _theme$colors.lavenderSyrup),
    borderRadius: "".concat(pxToRem(16), " ").concat(pxToRem(16), " 0 0"),
    padding: theme.spacing(2)
  };
  return styles;
});
var StyledBottomBox = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    backgroundColor: theme.palette.white.main,
    borderRadius: "0 0 ".concat(pxToRem(16), " ").concat(pxToRem(16)),
    padding: theme.spacing(0, 2, 0)
  };
  return styles;
});
export { StyledBottomBox, StyledTopBox, StyledWrapper };