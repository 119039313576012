import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, styled } from '@mui/material';
var StyledContentWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    backgroundColor: theme.palette.white.main,
    marginBottom: theme.spacing(-2),
    marginTop: theme.spacing(-9.25),
    paddingBottom: theme.spacing(4.5),
    paddingTop: theme.spacing(12.25)
  }, theme.breakpoints.up('desktop'), {
    backgroundColor: 'unset',
    marginBottom: 'unset',
    marginTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    paddingTop: 'unset'
  });

  return styles;
});
var StyledActionsWrapper = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;

  var styles = _defineProperty({}, theme.breakpoints.up('desktop'), {
    backgroundColor: theme.palette.white.main,
    borderRadius: theme.typography.pxToRem(16),
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: theme.typography.pxToRem(452),
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  });

  return styles;
});
export { StyledActionsWrapper, StyledContentWrapper };