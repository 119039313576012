import { styled as e, Box as r } from "@mui/material";
import "react";
import { getHexColor as t } from "../../../../helpers/colors/colors.helper.js";
import "../../../../enums/entities/entities.enum.js";
import "../../../../enums/keyboard/keyboard.enum.js";
import "../../../../enums/theme/theme.enum.js";
import "../../../../node_modules/@babel/runtime/helpers/typeof.js";
var o = e(r)(function () {
  return {
    alignItems: "baseline",
    display: "flex",
    flex: "45% 1 1",
    flexDirection: "row",
    flexWrap: "nowrap"
  };
}),
    i = e(r)(function (e) {
  var r = e.theme,
      o = e.$backgroundColor;
  return {
    backgroundColor: t(r.palette, o) || r.palette.white.main,
    height: r.typography.pxToRem(9),
    marginLeft: r.spacing(1),
    marginRight: r.spacing(1),
    width: r.typography.pxToRem(.5)
  };
});
export { o as StyledBox, i as StyledSeparator };