var keyToQueue = {};
/**
 * Checks if all queues are empty.
 *
 * @author Yury Kuntsou
 * @function allQueuesAreEmpty
 * @category Queues
 * @returns {boolean} Whether all queues are empty or not.
 */

export var allQueuesAreEmpty = function allQueuesAreEmpty() {
  return Object.values(keyToQueue).every(function (queue) {
    return queue.length === 0;
  });
};
/**
 * Gets a queue for the given key.
 *
 * @author Yury Kuntsou
 * @function getQueue
 * @category Queues
 * @param {Key} key - The key to get the queue for.
 * @returns {Queue | undefined} The queue for the given key or undefined if it doesn't exist.
 */

export var getQueue = function getQueue(key) {
  return keyToQueue[key];
};
/**
 * Gets or creates a queue for the given key.
 *
 * @author Yury Kuntsou
 * @function getOrCreateQueue
 * @category Queues
 * @param {Key} key - The key to get or create the queue for.
 * @returns {Queue} The queue for the given key.
 */

export var getOrCreateQueue = function getOrCreateQueue(key) {
  var queue = getQueue(key);

  if (queue) {
    return queue;
  }

  var newQueue = [];
  keyToQueue[key] = newQueue;
  return newQueue;
};