import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, buttonGroupClasses, styled } from '@mui/material';
var StyledList = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    marginTop: theme.spacing(-5)
  }, "& .".concat(buttonGroupClasses.root), {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    width: 'unset'
  });

  return styles;
});
var StyledBox = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;

  var styles = _defineProperty({}, theme.breakpoints.up('desktop'), {
    backgroundColor: theme.palette.white.main,
    flexBasis: '26%',
    maxWidth: theme.typography.pxToRem(496),
    minWidth: theme.typography.pxToRem(408)
  });

  return styles;
});
var StyledHeader = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    padding: theme.spacing(2, 3)
  };
  return styles;
});
var StyledContainer = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    marginTop: theme.spacing(2)
  };
  return styles;
});
export { StyledBox, StyledContainer, StyledHeader, StyledList };