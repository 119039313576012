import urlIcon from '@assets/images/payment.image.png';
export var getDepositsListMock = function getDepositsListMock() {
  return {
    data: [{
      alreadyPaid: 0,
      avlAmount: 1,
      closeOpportunity: true,
      currency: 'BYN',
      currentSum: 1,
      dealCode: 'VF290922-0001',
      dealType: 'ВВФ',
      depositCapitalize: false,
      depositName: 'Зручны анл@йн',
      depositTerm: 0,
      depositTermType: 'до востребования',
      depositType: 'D',
      finishDate: '2099-01-01',
      iban: 'BY91BELB340443006501B0226000',
      ibanInterest: 'BY69BELB90450226000815098005',
      ibanReadable: 'BY91 BELB 3404 4300 6501 B022 6000',
      id: 1,
      interestCurrentForecast: 0,
      interestDate: '2023-05-29',
      interestForecastTotal: 0,
      isEnd: false,
      isHidden: true,
      isOnline: false,
      nextInterestDate: '2023-05-29',
      openDate: '2023-04-28',
      rate: 0,
      replenishOpportunity: true,
      withdrawOpportunity: true
    }, {
      alreadyPaid: 0,
      avlAmount: 1,
      closeOpportunity: false,
      currency: 'BYN',
      currentSum: 1,
      dealCode: 'VF290922-0001',
      dealType: 'ВВФ',
      depositCapitalize: false,
      depositName: 'Зручны анл@йн',
      depositTerm: 0,
      depositTermType: 'до востребования',
      depositType: 'D',
      finishDate: '2099-01-01',
      iban: 'BY76BELB340443006501C0226000',
      ibanInterest: 'BY69BELB90450226000815098005',
      ibanReadable: 'BY91 BELB 3404 4300 6501 B022 6000',
      id: 2,
      interestCurrentForecast: 0,
      interestDate: '2023-05-29',
      interestForecastTotal: 0,
      isEnd: false,
      isHidden: true,
      isOnline: false,
      nextInterestDate: '2023-05-29',
      openDate: '2023-04-28',
      rate: 0,
      replenishOpportunity: true,
      withdrawOpportunity: true
    }, {
      alreadyPaid: 0,
      avlAmount: 1,
      closeOpportunity: false,
      currency: 'BYN',
      currentSum: 1,
      dealCode: 'VF290922-0001',
      dealType: 'ВВФ',
      depositCapitalize: false,
      depositName: 'Зручны анл@йн',
      depositTerm: 0,
      depositTermType: 'до востребования',
      depositType: 'D',
      finishDate: '2099-01-01',
      iban: 'BY53BELB341443006501F0226000',
      ibanInterest: 'BY97BELB90450226000815091002',
      ibanReadable: 'BY91 BELB 3404 4300 6501 B022 6000',
      id: 3,
      interestCurrentForecast: 0,
      interestDate: '2023-05-29',
      interestForecastTotal: 0,
      isEnd: false,
      isHidden: false,
      isOnline: false,
      nextInterestDate: '2023-05-29',
      openDate: '2023-04-28',
      rate: 0,
      replenishOpportunity: true,
      withdrawOpportunity: true
    }, {
      alreadyPaid: 0,
      avlAmount: 1,
      closeOpportunity: false,
      currency: 'BYN',
      currentSum: 1,
      dealCode: 'VF290922-0001',
      dealType: 'ВВФ',
      depositCapitalize: false,
      depositName: 'Зручны анл@йн',
      depositTerm: 0,
      depositTermType: 'до востребования',
      depositType: 'D',
      finishDate: '2099-01-01',
      iban: 'BY98BELB340443006501H0226000',
      ibanInterest: 'BY69BELB90450226000815098005',
      ibanReadable: 'BY91 BELB 3404 4300 6501 B022 6000',
      id: 4,
      interestCurrentForecast: 0,
      interestDate: '2023-05-29',
      interestForecastTotal: 0,
      isEnd: false,
      isHidden: false,
      isOnline: false,
      nextInterestDate: '2023-05-29',
      openDate: '2023-04-28',
      rate: 0,
      replenishOpportunity: true,
      withdrawOpportunity: true
    }, {
      alreadyPaid: 0,
      avlAmount: 1,
      closeOpportunity: true,
      currency: 'BYN',
      currentSum: 1,
      dealCode: 'VF290922-0001',
      dealType: 'ВВФ',
      depositCapitalize: false,
      depositName: 'Зручны анл@йн',
      depositTerm: 0,
      depositTermType: 'до востребования',
      depositType: 'D',
      finishDate: '2099-01-01',
      iban: 'BY83BELB340443006501I0226000',
      ibanInterest: 'BY69BELB90450226000815098005',
      ibanReadable: 'BY91 BELB 3404 4300 6501 B022 6000',
      id: 5,
      interestCurrentForecast: 0,
      interestDate: '2023-05-29',
      interestForecastTotal: 0,
      isEnd: false,
      isHidden: false,
      isOnline: false,
      nextInterestDate: '2023-05-29',
      openDate: '2023-04-28',
      rate: 0,
      replenishOpportunity: true,
      withdrawOpportunity: true
    }],
    filters: [],
    pagination: [{
      pageOffset: 0,
      pageSize: 100,
      totalObject: 97
    }]
  };
};
export var getDepositMock = function getDepositMock() {
  return {
    alreadyPaid: 500,
    avlAmount: 1,
    closeOpportunity: true,
    currency: 'BYN',
    currentSum: 1000.5,
    dealCode: 'VF290922-0001',
    dealType: 'ВВФ',
    depositCapitalize: false,
    depositName: 'Зручны анл@йн',
    depositTerm: 0,
    depositTermType: 'До востребования',
    depositType: 'До востребования',
    finishDate: '2099-01-01',
    iban: 'BY91BELB340443006501B0226000',
    ibanInterest: 'BY69BELB90450226000815098005',
    ibanReadable: 'BY91 BELB 3404 4300 6501 B022 6000',
    id: 1,
    interestCurrentForecast: 0,
    interestDate: '2023-05-29',
    interestForecastTotal: 5,
    isEnd: false,
    isOnline: false,
    nextInterestDate: '2023-05-29',
    openDate: '2023-04-28',
    rate: 3.0,
    replenishOpportunity: true,
    withdrawOpportunity: false
  };
};
export var getDepositAccountTypesMock = function getDepositAccountTypesMock() {
  return {
    accountTypes: 'AB,AP,AS,AT,BC,CB,CE,C3,C4,C7,C8,DX,B0,B1,B2,B3,B4,B5'
  };
};
export var getDepositCloseMock = function getDepositCloseMock() {
  return {
    depositAmntPlanned: 1000.5,
    depositAmntPlannedCurr: 'BYN',
    terminationDate: '2023-05-29'
  };
};
export var getDepositHistoryListMock = function getDepositHistoryListMock() {
  return {
    filters: {
      // dateFrom:          '2019-09-14T00:00:00+03:00',
      // dateTo:            '2019-12-31T23:59:59+03:00',
      dealCode: 'ВФР',
      dealType: 'D2140910-0112',
      depositCapitalize: true
    },
    historyDep: [{
      amount: 180.0,
      bal: null,
      curr: 'BYN',
      date: '2023-09-21',
      depCurr: null,
      desc: 'Открытие депозита Открытие депозита Открытие депозита Открытие депозита',
      detailsOperation: 'Процентная ставка Процентная ставка Процентная ставка Процентная ставка Процентная ставка',
      id: 1,
      narrative: null,
      rate: 3.5,
      type: 'D01',
      urlIcon: urlIcon
    }, {
      amount: 10.0,
      bal: null,
      curr: 'BYN',
      date: '2023-09-21',
      depCurr: null,
      desc: 'Открытие депозита',
      detailsOperation: 'Процентная ставка',
      id: 2,
      narrative: null,
      rate: 3.7,
      type: 'D01',
      urlIcon: urlIcon
    }, {
      amount: 50.0,
      bal: null,
      curr: 'BYN',
      date: '2019-12-31',
      depCurr: null,
      desc: 'Выплата процентов',
      detailsOperation: 'Процентная ставка',
      id: 3,
      narrative: null,
      rate: 3.6,
      type: 'D01',
      urlIcon: urlIcon
    }, {
      amount: 500.0,
      bal: null,
      curr: 'BYN',
      date: '2019-12-31',
      depCurr: null,
      desc: 'Открытие депозита',
      detailsOperation: 'Процентная ставка',
      id: 4,
      narrative: null,
      rate: 3.8,
      type: 'D01',
      urlIcon: urlIcon
    }, {
      amount: 50.0,
      bal: null,
      curr: 'BYN',
      date: '2019-12-31',
      depCurr: null,
      desc: 'Выплата процентов',
      detailsOperation: 'Процентная ставка',
      id: 5,
      narrative: null,
      rate: 3.5555,
      type: 'D01',
      urlIcon: urlIcon
    }, {
      amount: 100.0,
      bal: 600.0,
      curr: 'BYN',
      date: '2019-11-02',
      depCurr: 'BYN',
      desc: 'Пополнение депозита',
      detailsOperation: 'Сумма вклада',
      id: 6,
      narrative: 'Пополнение вклада, сервис СБС.',
      rate: null,
      type: 'D05',
      urlIcon: urlIcon
    }, {
      amount: 100.0,
      bal: 500.0,
      curr: 'BYN',
      date: '2019-11-01',
      depCurr: 'BYN',
      desc: 'Отзыв депозита',
      detailsOperation: 'Сумма вклада',
      id: 7,
      narrative: 'Отзыв вклада, сервис СБС.',
      rate: null,
      type: 'D06',
      urlIcon: urlIcon
    }, {
      amount: null,
      bal: null,
      curr: null,
      date: '2019-10-01',
      depCurr: null,
      desc: 'Изменение процентной ставки',
      detailsOperation: 'Процентная ставка',
      id: 8,
      narrative: null,
      rate: 4.0,
      type: 'D02',
      urlIcon: urlIcon
    }, {
      amount: 1.2,
      bal: null,
      curr: 'BYN',
      date: '2019-09-14',
      depCurr: null,
      desc: 'Выплата процентов',
      detailsOperation: null,
      id: 9,
      narrative: null,
      rate: null,
      type: 'D03',
      urlIcon: urlIcon
    }, {
      amount: 180.0,
      bal: null,
      curr: 'BYN',
      date: '2019-09-14',
      depCurr: null,
      desc: 'Открытие депозита Открытие депозита Открытие депозита Открытие депозита',
      detailsOperation: 'Процентная ставка Процентная ставка Процентная ставка Процентная ставка Процентная ставка',
      id: 10,
      narrative: null,
      rate: 3.5,
      type: 'D01',
      urlIcon: urlIcon
    }, {
      amount: 10.0,
      bal: null,
      curr: 'BYN',
      date: '2019-09-14',
      depCurr: null,
      desc: 'Открытие депозита',
      detailsOperation: 'Процентная ставка',
      id: 11,
      narrative: null,
      rate: 3.7,
      type: 'D01',
      urlIcon: urlIcon
    }, {
      amount: 50.0,
      bal: null,
      curr: 'BYN',
      date: '2019-09-14',
      depCurr: null,
      desc: 'Выплата процентов',
      detailsOperation: 'Процентная ставка',
      id: 12,
      narrative: null,
      rate: 3.6,
      type: 'D01',
      urlIcon: urlIcon
    }, {
      amount: 500.0,
      bal: null,
      curr: 'BYN',
      date: '2019-09-13',
      depCurr: null,
      desc: 'Открытие депозита',
      detailsOperation: 'Процентная ставка',
      id: 13,
      narrative: null,
      rate: 3.8,
      type: 'D01',
      urlIcon: urlIcon
    }, {
      amount: 50.0,
      bal: null,
      curr: 'BYN',
      date: '2019-09-12',
      depCurr: null,
      desc: 'Выплата процентов',
      detailsOperation: 'Процентная ставка',
      id: 14,
      narrative: null,
      rate: 3.5555,
      type: 'D01',
      urlIcon: urlIcon
    }, {
      amount: 100.0,
      bal: 600.0,
      curr: 'BYN',
      date: '2019-09-11',
      depCurr: 'BYN',
      desc: 'Пополнение депозита',
      detailsOperation: 'Сумма вклада',
      id: 15,
      narrative: 'Пополнение вклада, сервис СБС.',
      rate: null,
      type: 'D05',
      urlIcon: urlIcon
    }, {
      amount: 100.0,
      bal: 500.0,
      curr: 'BYN',
      date: '2019-09-10',
      depCurr: 'BYN',
      desc: 'Отзыв депозита',
      detailsOperation: 'Сумма вклада',
      id: 16,
      narrative: 'Отзыв вклада, сервис СБС.',
      rate: null,
      type: 'D06',
      urlIcon: urlIcon
    }, {
      amount: null,
      bal: null,
      curr: null,
      date: '2019-08-15',
      depCurr: null,
      desc: 'Изменение процентной ставки',
      detailsOperation: 'Процентная ставка',
      id: 17,
      narrative: null,
      rate: 4.0,
      type: 'D02',
      urlIcon: urlIcon
    }, {
      amount: 1.2,
      bal: null,
      curr: 'BYN',
      date: '2019-08-14',
      depCurr: null,
      desc: 'Выплата процентов',
      detailsOperation: null,
      id: 18,
      narrative: null,
      rate: null,
      type: 'D03',
      urlIcon: urlIcon
    }, {
      amount: 100.0,
      bal: 500.0,
      curr: 'BYN',
      date: '2019-08-13',
      depCurr: 'BYN',
      desc: 'Отзыв депозита',
      detailsOperation: 'Сумма вклада',
      id: 19,
      narrative: 'Отзыв вклада, сервис СБС.',
      rate: null,
      type: 'D06',
      urlIcon: urlIcon
    }, {
      amount: null,
      bal: null,
      curr: null,
      date: '2019-08-13',
      depCurr: null,
      desc: 'Изменение процентной ставки',
      detailsOperation: 'Процентная ставка',
      id: 20,
      narrative: null,
      rate: 4.0,
      type: 'D02',
      urlIcon: urlIcon
    }, {
      amount: 1.2,
      bal: null,
      curr: 'BYN',
      date: '2019-08-12',
      depCurr: null,
      desc: 'Выплата процентов',
      detailsOperation: null,
      id: 21,
      narrative: null,
      rate: null,
      type: 'D03',
      urlIcon: urlIcon
    }],
    pagination: [{
      pageOffset: 0,
      pageSize: 10,
      totalObject: 21
    }]
  };
};