import { CardStatus } from '@enums';
import card from '@assets/images/credit-card-green.image.png';
export var getCardsListMock = function getCardsListMock() {
  return {
    data: [{
      RBSNumber: 'string',
      accountCurrency: 'BYN',
      accountName: 'Мой зарплатный счет',
      backgroundColorFrom: '#7BA5BC',
      backgroundColorTo: '#4E7992',
      cardBalance: 3213,
      cardCurrency: 'BYN',
      cardExpiry: '11/26',
      cardName: 'Зарплатная - 12',
      cardNumber: '123456789',
      cardTerm: 'string',
      cardType: 'Standard',
      equivalents: [{
        equivalentAmount: 1045,
        equivalentCurrency: 'USD'
      }, {
        equivalentAmount: 1980,
        equivalentCurrency: 'EUR'
      }],
      extraRsRelated: 'string',
      extraRsTech: '1',
      firstName: 'string',
      iban: 'BY48BELB3404102R5505A0226000',
      ibanReadable: 'string',
      id: 1,
      isMulti: true,
      lastName: 'string',
      pictureID: 'string',
      pictureURL: card,
      productCode: 'string',
      status: {
        cardStatus: 'BLOCKED',
        color: 'string',
        isDisplay: true,
        state: CardStatus.ACTIVE,
        statusCode: 'string'
      }
    }, {
      RBSNumber: 'string',
      accountCurrency: 'BYN',
      accountName: 'Мой зарплатный счет',
      backgroundColorFrom: '#3AA5BC',
      backgroundColorTo: '#DEAC12',
      cardBalance: 42132,
      cardCurrency: 'BYN',
      cardExpiry: '11/26',
      cardName: 'Зарплатная - 2',
      cardNumber: '123456329',
      cardTerm: 'string',
      cardType: 'Visa Gold',
      equivalents: [{
        equivalentAmount: 14000,
        equivalentCurrency: 'BYN'
      }, {
        equivalentAmount: 13490,
        equivalentCurrency: 'EUR'
      }],
      extraRsRelated: 'string',
      extraRsTech: '2',
      firstName: 'string',
      iban: 'BY48BELB3404102R5505A0226000',
      ibanReadable: 'string',
      id: 2,
      isMulti: true,
      lastName: 'string',
      pictureID: 'string',
      pictureURL: card,
      productCode: 'string',
      status: {
        cardStatus: 'SUPERRATE',
        color: 'string',
        isDisplay: true,
        state: CardStatus.ACTIVE,
        statusCode: 'string'
      }
    }, {
      RBSNumber: 'string',
      accountCurrency: 'BYN',
      accountName: 'Мой зарплатный счет',
      backgroundColorFrom: '#7BA5BC',
      backgroundColorTo: '#4E7992',
      cardBalance: 3213,
      cardCurrency: 'BYN',
      cardExpiry: '11/26',
      cardName: 'Зарплатная',
      cardNumber: '123456789',
      cardTerm: 'string',
      cardType: 'Standard',
      equivalents: [{
        equivalentAmount: 1045,
        equivalentCurrency: 'USD'
      }, {
        equivalentAmount: 1980,
        equivalentCurrency: 'EUR'
      }],
      extraRsRelated: 'string',
      extraRsTech: '3',
      firstName: 'string',
      iban: 'string',
      ibanReadable: 'string',
      id: 3,
      isMulti: false,
      lastName: 'string',
      pictureID: 'string',
      pictureURL: card,
      productCode: 'string',
      status: {
        cardStatus: 'BLOCKED',
        color: 'string',
        isDisplay: true,
        state: CardStatus.ACTIVE,
        statusCode: 'string'
      }
    }, {
      RBSNumber: 'string',
      accountCurrency: 'BYN',
      accountName: 'Мой зарплатный счет',
      backgroundColorFrom: '#3AA5BC',
      backgroundColorTo: '#DEAC12',
      cardBalance: 121212,
      cardCurrency: 'USD',
      cardExpiry: '11/26',
      cardName: 'Валютная',
      cardNumber: '123456329',
      cardTerm: 'string',
      cardType: 'Visa Gold2',
      equivalents: [{
        equivalentAmount: 100000,
        equivalentCurrency: 'BYN'
      }, {
        equivalentAmount: 111111,
        equivalentCurrency: 'EUR'
      }],
      extraRsRelated: 'string',
      extraRsTech: '4',
      firstName: 'string',
      iban: 'string',
      ibanReadable: 'string',
      id: 4,
      isMulti: false,
      lastName: 'string',
      pictureID: 'string',
      pictureURL: card,
      productCode: 'string',
      status: {
        cardStatus: 'SUPERRATE',
        color: 'string',
        isDisplay: true,
        state: CardStatus.ACTIVE,
        statusCode: 'string'
      }
    }, {
      RBSNumber: '3014104LOU0010226',
      accountCurrency: 'BYN',
      accountName: 'Мой зарплатный счет',
      backgroundColorFrom: '#479085',
      backgroundColorTo: '#494891',
      cardBalance: 0.17,
      cardCurrency: 'BYN',
      cardExpiry: '10/24',
      cardName: 'Белкарт-Премиум Виртуальная',
      cardNumber: '****2717',
      cardType: 'Белкарт-Премиум Виртуальная',
      equivalents: [{
        equivalentAmount: 0.06,
        equivalentCurrency: 'USD'
      }, {
        equivalentAmount: 0.05,
        equivalentCurrency: 'EUR'
      }],
      extraRsRelated: '59856770',
      extraRsTech: '59856750',
      firstName: 'OLEG',
      iban: 'BY57BELB3014104LOU0010226000',
      ibanReadable: 'BY57 BELB 3014 104L OU00 1022 6000',
      id: 5,
      isMulti: false,
      lastName: 'ARBIDOL',
      pictureID: 'BELKART_VIRT',
      pictureURL: card,
      productCode: 'PrvDbAAA027VIRT_TMC',
      status: {
        cardStatus: 'Активна',
        color: '',
        isDisplay: false,
        state: CardStatus.ACTIVE,
        statusCode: '00'
      }
    }, {
      RBSNumber: '3014104LOU0040226',
      accountCurrency: 'BYN',
      accountName: 'Мой зарплатный счет',
      backgroundColorFrom: '#479085',
      backgroundColorTo: '#494891',
      cardBalance: 14.9,
      cardCurrency: 'BYN',
      cardExpiry: '10/24',
      cardName: 'Белкарт-Премиум Виртуальная',
      cardNumber: '****9215',
      cardType: 'Белкарт-Премиум Виртуальная',
      equivalents: [{
        equivalentAmount: 5.17,
        equivalentCurrency: 'USD'
      }, {
        equivalentAmount: 4.81,
        equivalentCurrency: 'EUR'
      }],
      extraRsRelated: '59857150',
      extraRsTech: '59856950',
      firstName: 'OLEG',
      iban: 'BY12BELB3014104LOU0040226000',
      ibanReadable: 'BY12 BELB 3014 104L OU00 4022 6000',
      id: 6,
      isMulti: false,
      lastName: 'ARBIDOL',
      pictureID: 'BELKART_VIRT',
      pictureURL: card,
      productCode: 'PrvDbAAA027VIRT_TMC',
      status: {
        cardStatus: 'Активна',
        color: '',
        isDisplay: false,
        state: CardStatus.ACTIVE,
        statusCode: '00'
      }
    }, {
      RBSNumber: '3014104LOU0010226',
      accountCurrency: 'BYN',
      accountName: 'Мой зарплатный счет',
      backgroundColorFrom: '#479085',
      backgroundColorTo: '#494891',
      cardBalance: 0.17,
      cardCurrency: 'BYN',
      cardExpiry: '10/24',
      cardName: 'Белкарт-Премиум Виртуальная',
      cardNumber: '****7966',
      cardType: 'Белкарт-Премиум Виртуальная',
      equivalents: [{
        equivalentAmount: 0.06,
        equivalentCurrency: 'USD'
      }, {
        equivalentAmount: 0.05,
        equivalentCurrency: 'EUR'
      }],
      extraRsRelated: '59868550',
      extraRsTech: '59856990',
      firstName: 'OLEG',
      iban: 'BY57BELB3014104LOU0010226000',
      ibanReadable: 'BY57 BELB 3014 104L OU00 1022 6000',
      id: 7,
      isMulti: false,
      lastName: 'ARBIDOL',
      pictureID: 'BELKART_VIRT',
      pictureURL: card,
      productCode: 'PrvDbAAA027VIRT_TMC',
      status: {
        cardStatus: 'Активна',
        color: '',
        isDisplay: false,
        state: CardStatus.ACTIVE,
        statusCode: '00'
      }
    }, {
      RBSNumber: '3014104LOU01C0226',
      accountCurrency: 'BYN',
      accountName: 'Мой зарплатный счет',
      backgroundColorFrom: '#021F5D',
      backgroundColorTo: '#03173A',
      cardBalance: 1.7,
      cardCurrency: 'USD',
      cardExpiry: '10/24',
      cardName: 'Visa Classic',
      cardNumber: '****7295',
      cardType: 'Visa Classic',
      equivalents: [{
        equivalentAmount: 4.83,
        equivalentCurrency: 'BYN'
      }, {
        equivalentAmount: 1.57,
        equivalentCurrency: 'EUR'
      }],
      extraRsRelated: '59871770',
      extraRsTech: '59871760',
      firstName: 'OLEG',
      iban: 'BY59BELB3014104LOU01C0226000',
      ibanReadable: 'BY59 BELB 3014 104L OU01 C022 6000',
      id: 8,
      isMulti: true,
      lastName: 'ARBIDOL',
      pictureID: 'VC_VIRT_DEB',
      pictureURL: card,
      productCode: 'PrvDbAAA003VIRT_TMC',
      status: {
        cardStatus: 'Активна',
        color: '',
        isDisplay: false,
        state: CardStatus.ACTIVE,
        statusCode: '00'
      }
    }, {
      RBSNumber: '3014104LOU01D0226',
      accountCurrency: 'BYN',
      accountName: 'Мой зарплатный счет',
      backgroundColorFrom: '#479085',
      backgroundColorTo: '#494891',
      cardBalance: 45.95,
      cardCurrency: 'EUR',
      cardExpiry: '10/24',
      cardName: 'Белкарт-Премиум Виртуальная',
      cardNumber: '****1535',
      cardType: 'Белкарт-Премиум Виртуальная',
      equivalents: [{
        equivalentAmount: 139.23,
        equivalentCurrency: 'BYN'
      }, {
        equivalentAmount: 48.25,
        equivalentCurrency: 'USD'
      }],
      extraRsRelated: '59857200',
      extraRsTech: '59857180',
      firstName: 'OLEG',
      iban: 'BY44BELB3014104LOU01D0226000',
      ibanReadable: 'BY44 BELB 3014 104L OU01 D022 6000',
      id: 9,
      isMulti: true,
      lastName: 'ARBIDOL',
      pictureID: 'BELKART_VIRT',
      pictureURL: card,
      productCode: 'PrvDbAAA027VIRT_TMC',
      status: {
        cardStatus: 'Активна',
        color: '',
        isDisplay: false,
        state: CardStatus.ACTIVE,
        statusCode: '00'
      }
    }, {
      RBSNumber: '3014104LOU01D0226',
      accountCurrency: 'BYN',
      accountName: 'Мой зарплатный счет',
      backgroundColorFrom: '#479085',
      backgroundColorTo: '#494891',
      cardBalance: 45.95,
      cardCurrency: 'EUR',
      cardExpiry: '10/24',
      cardName: 'Белкарт-Премиум Виртуальная',
      cardNumber: '****4225',
      cardType: 'Белкарт-Премиум Виртуальная',
      equivalents: [{
        equivalentAmount: 139.23,
        equivalentCurrency: 'BYN'
      }, {
        equivalentAmount: 48.25,
        equivalentCurrency: 'USD'
      }],
      extraRsRelated: '59872110',
      extraRsTech: '59872100',
      firstName: 'OLEG',
      iban: 'BY44BELB3014104LOU01D0226000',
      ibanReadable: 'BY44 BELB 3014 104L OU01 D022 6000',
      id: 10,
      isMulti: true,
      lastName: 'ARBIDOL',
      pictureID: 'BELKART_VIRT',
      pictureURL: card,
      productCode: 'PrvDbAAA027VIRT_TMC',
      status: {
        cardStatus: 'Активна',
        color: '',
        isDisplay: false,
        state: CardStatus.ACTIVE,
        statusCode: '00'
      }
    }],
    filters: [],
    pagination: [],
    sort: []
  };
};
export var getCardIssueInfoApiMock = function getCardIssueInfoApiMock() {
  return {
    cards: [{
      addInfo: 'Международная карточка Visa Classic.\nБесплатное снятие наличных в банкоматах банка и партнеров.\nНачисление баллов в программе лояльности UP4U.\nСкидка 30% на СМС-оповещение',
      cardType: 'Classic',
      pictureURL: '/remote/cards/VISA_CLASSIC.png',
      productCode: 'PrvDb_TMC'
    }, {
      addInfo: 'Международная карточка Visa Gold. Бесплатное снятие наличных в банкоматах банка и партнеров + 3 раза в любом банкомате на территории РБ. Начисление баллов в программе лояльности UP4U<br> Скидки и бонусы от Visa',
      cardType: 'Visa Gold Multi',
      pictureURL: '/remote/cards/VISA_GOLD.png',
      productCode: 'PrvDbAAA041PSOPTIMAL_TMC'
    }]
  };
};
export var getCardIssueStartApiMock = function getCardIssueStartApiMock() {
  return {
    accounts: [{
      accountABANAS: '0226-450882-012',
      accountAmount: 750.0,
      accountCurrency: 'BYN',
      accountName: 'Мой зарплатный счет',
      cards: [{
        extraRsTech: '2882918',
        pictureURL: 'pictureURL'
      }, {
        extraRsTech: '2882919',
        pictureURL: 'pictureURL'
      }],
      equivalents: [{
        equivalentAmount: 278.0,
        equivalentCurrency: 'USD'
      }, {
        equivalentAmount: 250.0,
        equivalentCurrency: 'EUR'
      }],
      iban: 'BY61BEL'
    }],
    availableCurrency: [{
      dftCurr: true,
      nameCurr: 'BYN'
    }, {
      dftCurr: false,
      nameCurr: 'USD'
    }, {
      dftCurr: false,
      nameCurr: 'EUR'
    }],
    nameLatin: 'ivan',
    surnameLatin: 'Ivanov'
  };
};
export var getOpenCardResponseMock = function getOpenCardResponseMock() {
  return {
    codeOperation: '2575',
    dataOperation: [{
      id: 1,
      opDetails: 'some details'
    }],
    idOtp: 0,
    idTransaction: '1',
    messageForClient: 'Success',
    otp: false,
    pin: true
  };
};
export var getCardAccountsMock = function getCardAccountsMock() {
  return {
    accounts: [{
      accountAmount: 750.0,
      accountCurrency: 'BYN',
      accountName: 'Мой зарплатный счет',
      accountType: 'CE',
      cards: [{
        pictureURL: '/remote/cards/{...}.png'
      }, {
        pictureURL: '/remote/cards/{...}.png'
      }],
      defaultAccount: true,
      equivalents: [{
        equivalentAmount: 278.0,
        equivalentCurrency: 'USD'
      }, {
        equivalentAmount: 250.0,
        equivalentCurrency: 'EUR'
      }],
      iban: 'BY61BELB30144508820120226000',
      id: 1
    }]
  };
};