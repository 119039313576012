import { __assign as t } from "../../../_virtual/_tslib.js";
import { styled as o, Switch as e, alpha as i, FormControl as r, formControlLabelClasses as a, InputLabel as n, FormControlLabel as c, Box as s, FormHelperText as l } from "@mui/material";
import "../../../themes/box-shadows/boxShadows.js";
import "../../../themes/dark.theme.js";
import { pxToRem as h } from "../../../themes/default-theme/default-theme.js";
import "../../../themes/gradients/gradients.js";
import "../../../themes/light.theme.js";
var d = o(e)(function (o) {
  var e = o.theme,
      r = {
    height: h(40),
    padding: e.spacing(1),
    transition: e.transitions.create(["background-color"]),
    width: h(56)
  };
  return r = t(t({}, r), {
    "& .MuiSwitch-switchBase": {
      "& + .MuiSwitch-track": {
        backgroundColor: e.colors.estateViolet,
        borderRadius: h(22),
        opacity: 1
      },
      "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: e.colors.panPurple
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: .5
      },
      "&:hover": {
        backgroundColor: i(e.colors.hawaiianPassion, e.palette.action.hoverOpacity)
      },
      color: e.colors.white,
      left: h(8),
      padding: h(2),
      top: h(8)
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      "&:hover": {
        backgroundColor: i(e.colors.white, e.palette.action.hoverOpacity)
      },
      color: e.colors.white,
      left: h(4),
      top: h(8)
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none"
    }
  });
}),
    p = o(r)(function (t) {
  var o,
      e = t.fullWidth,
      i = t.$reverse;
  return (o = {
    display: e && "flex",
    maxWidth: "100%"
  })["& .".concat(a.root)] = {
    display: e ? "flex" : "flex-inline",
    flexDirection: i ? "row-reverse" : "unset",
    justifyContent: "space-between",
    margin: "unset"
  }, o;
}),
    u = o(n)(function (t) {
  var o = t.theme,
      e = t.$color,
      i = {
    color: o.colors.abandonedSpaceship,
    display: "block",
    fontSize: h(16),
    fontWeight: 400,
    lineHeight: h(18),
    marginBottom: o.spacing(.5),
    maxWidth: "100%",
    paddingLeft: o.spacing(1),
    position: "relative",
    transform: "unset",
    transformOrigin: "unset"
  };
  return "bayFog" === e && (i.color = o.colors.white), i;
}),
    m = o(c)(function (t) {
  var o,
      e,
      i = t.theme,
      r = t.$reverse;
  return (o = {})["&.MuiFormControlLabel-labelPlacementEnd"] = ((e = {})["& .".concat(a.label)] = {
    paddingLeft: !r && i.spacing(.5)
  }, e), o;
}),
    g = o(s)(function () {
  return {
    fontSize: h(12),
    left: 0,
    lineHeight: h(14),
    marginTop: h(4),
    position: "absolute",
    right: 0,
    top: "100%"
  };
}),
    f = o(l)(function (t) {
  var o = t.theme;
  return {
    color: o.palette.error.main,
    display: "block",
    margin: "unset",
    maxWidth: "100%",
    padding: o.spacing(0, 1)
  };
}),
    b = o(l)(function (t) {
  var o = t.theme,
      e = t.$color,
      i = {
    color: o.colors.abandonedSpaceship,
    display: "block",
    margin: "unset",
    maxWidth: "100%",
    padding: o.spacing(0, 1)
  };
  return "bayFog" === e && (i.color = o.colors.white), i;
});
export { g as StyleFromHelperWrapper, p as StyledFormControl, f as StyledFormHelperErrorText, b as StyledFormHelperText, u as StyledInputLabel, d as StyledSwitch, m as StyledSwitchLabel };