import { Box, styled } from '@mui/material';
var StyledDrawerContentContainer = styled(Box)(function () {
  var styles = {
    display: 'flex',
    overflow: 'hidden'
  };
  return styles;
});
var StyledBox = styled(Box)(function (_ref) {
  var theme = _ref.theme,
      isMobile = _ref.isMobile;
  var styles = {
    padding: isMobile ? theme.spacing(2) : theme.spacing(0, 3, 3, 3)
  };
  return styles;
});
export { StyledBox, StyledDrawerContentContainer };