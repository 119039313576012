import * as yup from 'yup';
import { formatNumber } from '@helpers/numbers';
import { Currency } from '@enums';
import { i18n } from '@localization';

var getPaymentsCardsFormSchema = function getPaymentsCardsFormSchema(_ref) {
  var minTransferAmount = _ref.minTransferAmount,
      userCards = _ref.userCards;
  return yup.object().shape({
    amount: yup.number().required(i18n.t('forms.validation.enterAmount')).test({
      message: i18n.t('forms.validation.enterAmount'),
      name: 'isRequired',
      test: function test(value, ctx) {
        return Number(value) !== 0;
      }
    }).test({
      name: 'minTransferAmnt',
      test: function test(value, ctx) {
        if (!value) return true;

        if (minTransferAmount && Number(value) < minTransferAmount) {
          return ctx.createError({
            message: i18n.t('forms.validation.minTransferAmount', {
              data: " ".concat(formatNumber(minTransferAmount, 2, true, ',', ' '), " ").concat(Currency.BYN)
            })
          });
        }

        var fields = ctx.parent;
        var cardFromId = fields.cardFromId;
        var userCard = cardFromId && userCards.find(function (card) {
          return card.extraRsTech === cardFromId;
        });

        if (userCard && userCard.cardCurrency === Currency.BYN) {
          if (Number(value) > userCard.cardBalance) {
            return ctx.createError({
              message: i18n.t('forms.validation.notEnoughOnAccount')
            });
          }
        }

        return true;
      }
    }).nullable(),
    checkContract: yup.bool().test({
      message: i18n.t('forms.validation.required'),
      name: 'min',
      test: function test(value, ctx) {
        var fields = ctx.parent;

        if (typeof fields.commission === 'number' && !value) {
          return false;
        }

        return true;
      }
    })
  });
};

export { getPaymentsCardsFormSchema };