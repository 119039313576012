var t = function (t) {
  if (!t) return "0,00";
  var r = 1;
  Number(t) < 0 && (r = -1, t = -t);

  for (var x = t.toString().includes(".") ? t.toString().split(".")[0] : t.toString(); /(\d+)(\d{3})/.test(x.toString());) x = x.toString().replace(/(\d+)(\d{3})/, "$1 $2");

  if (t.toString().includes(".")) {
    var n = t.toString().split(".")[1];
    x = x + "," + (1 === n.length ? n + "0" : n);
  } else x += ",00";

  return r < 0 ? "-" + x : x;
},
    r = function () {
  var t = window.crypto && window.crypto.getRandomValues ? window.crypto.getRandomValues(new Uint32Array(1))[0] : new Date().getTime();
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (r) {
    var x = (t + 16 * Math.random()) % 16 | 0;
    return t = Math.floor(t / 16), ("x" === r ? x : 3 & x | 8).toString(16);
  });
};

export { t as commaSeparateNumber, r as generateUUID };