import { Box, styled } from '@mui/material';
var StyledWrapperDrawer = styled(Box)(function (_ref) {
  var theme = _ref.theme,
      isMobile = _ref.isMobile;
  var styles = {
    marginLeft: isMobile ? 0 : theme.spacing(-2),
    padding: isMobile ? theme.spacing(1.5, 1.25) : 0
  };
  return styles;
});
export { StyledWrapperDrawer };