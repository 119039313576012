import { Button, pxToRem, Typography } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledButton = styled(Button)(function () {
  var styles = {
    padding: 0
  };
  return styles;
});
var StyledImageWrapper = styled(Box)(function () {
  var styles = {
    alignItems: 'center',
    borderRadius: pxToRem(16),
    display: 'flex',
    height: pxToRem(60),
    overflow: 'hidden',
    width: pxToRem(60)
  };
  return styles;
});
var StyledTypography = styled(Typography)(function () {
  var styles = {
    wordBreak: 'break-word'
  };
  return styles;
});
export { StyledButton, StyledImageWrapper, StyledTypography };