import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { pxToRem } from '@pwa/ui';
import { Box, Container, Stack, styled } from '@mui/material';
var StyledContainer = styled(Container)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    display: 'flex',
    flex: '1',
    flexDirection: 'column'
  }, theme.breakpoints.up('desktop'), {
    display: 'block'
  });

  return styles;
});
var StyledBox = styled(Box)(function (_ref2) {
  var _theme$colors;

  var theme = _ref2.theme;
  var styles = {
    alignItems: 'center',
    backgroundColor: theme === null || theme === void 0 ? void 0 : (_theme$colors = theme.colors) === null || _theme$colors === void 0 ? void 0 : _theme$colors.white,
    borderRadius: pxToRem(16),
    boxShadow: theme.boxShadows.independence,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1.25, 2)
  };
  return styles;
});
var StyledPageWrapper = styled(Stack)(function (_ref3) {
  var theme = _ref3.theme;

  var styles = _defineProperty({
    flexGrow: '1',
    padding: theme.spacing(4, 0, 5)
  }, theme.breakpoints.up('desktop'), {
    display: 'block',
    maxWidth: theme.typography.pxToRem(452)
  });

  return styles;
});
export { StyledBox, StyledContainer, StyledPageWrapper };