import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { pxToRem } from '@pwa/ui';
import { Box, Container, styled } from '@mui/material';
var StyledContainer = styled(Container)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    display: 'flex',
    flex: '1',
    flexDirection: 'column'
  }, theme.breakpoints.up('desktop'), {
    display: 'block'
  });

  return styles;
});
var StyledWrapper = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;

  var styles = _defineProperty({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(2.5, 0, 5)
  }, theme.breakpoints.up('desktop'), {
    display: 'block',
    maxWidth: theme.typography.pxToRem(452),
    paddingTop: theme.spacing(3)
  });

  return styles;
});
var StyledBoxWrapper = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    paddingTop: theme.spacing(4.25),
    position: 'relative'
  };
  return styles;
});
var StyledButtonWrapper = styled(Box)(function () {
  var styles = {
    position: 'absolute',
    right: pxToRem(8),
    top: pxToRem(8)
  };
  return styles;
});
var StyledWarning = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;

  var styles = _defineProperty({
    backgroundColor: theme.colors.lavenderSyrup,
    borderRadius: pxToRem(12),
    margin: theme.spacing(0.5, 2, 2.25),
    padding: theme.spacing(1.75),
    textAlign: 'center'
  }, theme.breakpoints.up('desktop'), {
    marginLeft: 'unset',
    marginRight: 'unset'
  });

  return styles;
});
var StyledInfo = styled(Box)(function (_ref5) {
  var theme = _ref5.theme;

  var styles = _defineProperty({
    marginTop: theme.spacing(1.75),
    textAlign: 'center'
  }, theme.breakpoints.up('desktop'), {
    textAlign: 'left'
  });

  return styles;
});
export { StyledBoxWrapper, StyledButtonWrapper, StyledContainer, StyledInfo, StyledWarning, StyledWrapper };