import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledButtonsWrapper = styled(Box)(function () {
  var styles = {
    fontSize: '0',
    textAlign: 'center'
  };
  return styles;
});
var StyledButtonWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    marginTop: theme.spacing(4)
  };
  return styles;
});
var StyledBox = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(3),
    textAlign: 'center'
  };
  return styles;
});
var StyledContent = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;

  var styles = _defineProperty({
    backgroundColor: theme.palette.white.main,
    borderTopLeftRadius: theme.typography.pxToRem(16),
    borderTopRightRadius: theme.typography.pxToRem(16),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(4)
  }, theme.breakpoints.up('desktop'), {
    backgroundColor: 'unset',
    borderRadius: 'unset',
    paddingTop: 'unset'
  });

  return styles;
});
var StyledDesktopWrapper = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;

  var styles = _defineProperty({}, theme.breakpoints.up('desktop'), {
    backgroundColor: theme.palette.white.main,
    borderRadius: theme.typography.pxToRem(16),
    maxWidth: theme.typography.pxToRem(452),
    padding: theme.spacing(4, 2)
  });

  return styles;
});
var StyledTypographyWrapper = styled(Box)(function (_ref5) {
  var theme = _ref5.theme;
  var styles = {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1)
  };
  return styles;
});
var StyledInfoBox = styled(Box)(function (_ref6) {
  var theme = _ref6.theme;
  var styles = {
    backgroundColor: theme.palette.whiteSolid.main,
    borderRadius: "".concat(pxToRem(30), " 0"),
    marginTop: theme.spacing(2),
    padding: theme.spacing(3.5),
    textAlign: 'center'
  };
  return styles;
});
var StyledHorizontalLine = styled(Box)(function (_ref7) {
  var theme = _ref7.theme;
  var styles = {
    backgroundColor: theme.colors.lavenderSyrup,
    height: pxToRem(32),
    width: pxToRem(1)
  };
  return styles;
});
export { StyledBox, StyledButtonsWrapper, StyledButtonWrapper, StyledContent, StyledDesktopWrapper, StyledHorizontalLine, StyledInfoBox, StyledTypographyWrapper };