import { pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledBox = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    '& > *:first-child': {
      marginTop: 0
    },
    '& > *:last-child': {
      marginBottom: 0
    },
    '& h1': {
      color: theme.colors.nightWizard,
      fontSize: pxToRem(24),
      fontWeight: 700,
      lineHeight: pxToRem(28),
      margin: theme.spacing(2, 0)
    },
    '& h2': {
      color: theme.colors.nightWizard,
      fontSize: pxToRem(16),
      fontWeight: 500,
      lineHeight: pxToRem(18),
      margin: theme.spacing(1.5, 0)
    },
    '& h3': {
      color: theme.colors.abandonedSpaceship,
      fontSize: pxToRem(16),
      fontWeight: 400,
      lineHeight: pxToRem(18),
      margin: theme.spacing(1.5, 0)
    },
    '& li h2': {
      display: 'inline'
    },
    '& li::marker': {
      fontWeight: 500
    },
    '& ol': {
      listStylePosition: 'inside',
      margin: theme.spacing(1, 0),
      padding: 0
    },
    '& p': {
      color: theme.colors.abandonedSpaceship,
      fontSize: pxToRem(14),
      fontWeight: 400,
      lineHeight: pxToRem(16),
      margin: theme.spacing(1.5, 0)
    },
    '& strong': {
      color: theme.colors.abandonedSpaceship,
      fontWeight: 700,
      margin: theme.spacing(1.5, 0)
    },
    '& ul': {
      listStylePosition: 'inside',
      margin: theme.spacing(1, 0),
      padding: 0
    }
  };
  return styles;
});
export { StyledBox };