import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { getHexColor, pxToRem, Typography } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledPageWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    // justifyContent: 'space-between',
    minHeight: theme.convertToDVH(100),
    paddingBottom: '5vh',
    paddingTop: '12vh',
    whiteSpace: 'pre-line'
  }, theme.breakpoints.up('desktop'), {
    display: 'block'
  });

  return styles;
});
var StyledContent = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: theme.typography.pxToRem(352)
  };
  return styles;
});
var StyledIconWrapper = styled(Box)(function () {
  var styles = {
    display: 'block',
    margin: '0 auto',
    maxWidth: pxToRem(330),
    position: 'relative',
    transform: 'translate3d(0,0,0)',
    width: '100%'
  };
  return styles;
});
var StyledIconSpacer = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    height: 0,
    overflow: 'hidden',
    paddingTop: '97%',
    width: '100%'
  };
  return styles;
});
var StyledIconCircle = styled(Box)(function (_ref4) {
  var theme = _ref4.theme,
      $backgroundColor = _ref4.$backgroundColor;
  var color = getHexColor(theme.palette, $backgroundColor);
  var styles = {
    backgroundColor: color,
    borderRadius: '50%',
    height: 0,
    left: '50%',
    marginLeft: 'calc(-41%)',
    marginTop: 'calc(-41%)',
    paddingTop: '82%',
    position: 'absolute',
    top: '50%',
    width: '82%'
  };
  return styles;
});
var StyledIcon = styled('img')(function (_ref5) {
  var theme = _ref5.theme;
  var styles = {
    display: 'block',
    height: '100%',
    inset: 0,
    position: 'absolute',
    width: '100%'
  };
  return styles;
});
var StyledTypography = styled(Typography)(function () {
  var styles = {
    textAlign: 'center',
    wordBreak: 'break-word'
  };
  return styles;
});
export { StyledContent, StyledIcon, StyledIconCircle, StyledIconSpacer, StyledIconWrapper, StyledPageWrapper, StyledTypography };