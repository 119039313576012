import { __assign as r } from "../../_virtual/_tslib.js";
import { jsxs as i, jsx as e } from "react/jsx-runtime";
import { Box as t } from "@mui/material";
import { StyledProgress as a, StyledProgressMain as o } from "./circular-progress.styles.js";

var m = function (m) {
  var s = m.size,
      n = void 0 === s ? 80 : s;
  return i(t, r({
    position: "relative"
  }, {
    children: [e(a, {
      size: n,
      value: 100,
      variant: "determinate"
    }), e(o, {
      disableShrink: !0,
      size: n,
      variant: "indeterminate"
    })]
  }));
};

export { m as CircularProgress };