export var GRID_INIT_REQUEST = 'GRID_INIT_REQUEST';
export var GRID_INIT = 'GRID_INIT';
export var UPDATE_GRID_REQUEST = 'UPDATE_GRID_REQUEST';
export var UPDATE_GRID = 'UPDATE_GRID';
export var CLOSE_GRID_REQUEST = 'CLOSE_GRID_REQUEST';
export var CLOSE_GRID = 'CLOSE_GRID';
export var CLOSE_ALL_GRIDS_REQUEST = 'CLOSE_ALL_GRIDS_REQUEST';
export var CLOSE_ALL_GRIDS = 'CLOSE_ALL_GRIDS';
export var API_GRID_FETCH = 'API_GRID_FETCH';
export var API_GRID_SUCCESS = 'API_GRID_SUCCESS';
export var API_GRID_FAILURE = 'API_GRID_FAILURE';
export var API_GRID_UPDATE_FETCH = 'API_GRID_UPDATE_FETCH';