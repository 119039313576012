import { __rest as o, __assign as i } from "../../_virtual/_tslib.js";
import { jsxs as r, jsx as t } from "react/jsx-runtime";
import { Icon as n } from "../icon/icon.component.js";
import { Typography as c } from "../typography/typography.component.js";
import { StyledButton as l, StyledIconWrapper as e } from "./action-button.styles.js";

var h = function (h) {
  var p = h.icon,
      a = h.label,
      m = h.onClick,
      d = h.iconColor,
      s = void 0 === d ? "panPurple" : d,
      f = h.iconWidth,
      g = void 0 === f ? 20 : f,
      v = h.iconHeight,
      u = void 0 === v ? 20 : v,
      j = o(h, ["icon", "label", "onClick", "iconColor", "iconWidth", "iconHeight"]);
  return r(l, i({
    onClick: m
  }, j, {
    children: [t(e, i({
      $color: s
    }, {
      children: t(n, {
        color: "white",
        height: u,
        src: p,
        width: g
      })
    })), t(c, i({
      color: "nightWizard",
      flex: "1 1",
      fontWeight: 500,
      variant: "caption1"
    }, {
      children: a
    }))]
  }));
};

export { h as ActionButton };