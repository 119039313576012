import { pxToRem } from '@pwa/ui';
import { Box, List, ListItem, styled } from '@mui/material';
var StyledList = styled(List)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    paddingBottom: 0,
    paddingTop: 0
  };
  return styles;
});
var StyledDot = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    backgroundColor: theme.colors.abandonedSpaceship,
    borderRadius: '50%',
    flexShrink: 0,
    height: pxToRem(4),
    marginTop: theme.spacing(0.875),
    width: pxToRem(4)
  };
  return styles;
});
var StyledListItem = styled(ListItem)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  };
  return styles;
});
export { StyledDot, StyledList, StyledListItem };