import * as yup from 'yup';
import { i18n } from '@localization';
import { MIN_CARD_LIMIT_AMOUNT } from './card-limits-edit-form.enum';

var cardLimitsSchema = function cardLimitsSchema(max, currency, maxError) {
  return yup.object().shape({
    currentAmount: yup.number().required("".concat(i18n.t('components.modals.cardLimits.min'), " ").concat(MIN_CARD_LIMIT_AMOUNT, " ").concat(currency)).max(max, "".concat(i18n.t('components.modals.cardLimits.max'), " ").concat(maxError, " ").concat(currency)),
    currentNumber: yup.number().required("".concat(i18n.t('components.modals.cardLimits.enterVal')))
  });
};

export { cardLimitsSchema };