import { Icon } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledIcon = styled(Icon)(function () {
  var styles = {
    transform: 'rotate(180deg)'
  };
  return styles;
});
var StyledButtonWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    marginBottom: theme.spacing(0.75),
    marginTop: theme.spacing(0.25)
  };
  return styles;
});
export { StyledButtonWrapper, StyledIcon };