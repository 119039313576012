import { __rest as r, __assign as t } from "../../_virtual/_tslib.js";
import { jsx as n, Fragment as i } from "react/jsx-runtime";
import { Typography as e } from "../typography/typography.component.js";

var o = function (o) {
  var p = o.text,
      a = o.highlightWords,
      c = r(o, ["text", "highlightWords"]);
  if (!p || !p.length) return n(i, {});
  if (!a || !a.length) return n(i, {
    children: p
  });
  var h = new RegExp("(".concat(a.map(function (r) {
    return r.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }).join("|"), ")"), "gi"),
      l = p.split(h);
  return n(i, {
    children: l.map(function (r, i) {
      return a.includes(r.toLowerCase()) ? n(e, t({
        color: "primary",
        component: "span",
        variant: "inherit"
      }, c, {
        children: r
      }), i) : r;
    })
  });
};

export { o as HighlightedText };