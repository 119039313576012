import { __read as e, __assign as r } from "../_virtual/_tslib.js";
import { useState as t, useEffect as n } from "react";

var o = function (o, a, i) {
  var s = new WeakMap(),
      u = e(t(!1), 2),
      c = u[0],
      v = u[1],
      f = new IntersectionObserver(function (e) {
    e.forEach(function (e) {
      if (s.has(e.target)) {
        var r = s.get(e.target);
        e.isIntersecting && (f.unobserve(e.target), s.delete(e.target), r && r(), v(!0));
      }
    });
  }, r({
    rootMargin: "0px",
    threshold: .5
  }, a || {}));
  return n(function () {
    var e = null == o ? void 0 : o.current;
    return e && (s.set(e, i), f.observe(e)), function () {
      e && (s.delete(e), f.unobserve(e));
    };
  }, [o, i]), c;
};

export { o as useIntersection };