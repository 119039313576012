import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { pxToRem } from '@pwa/ui';
import { Box, Container, styled } from '@mui/material';
var StyledBox = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    backgroundColor: theme.palette.whiteSolid.main,
    borderTopLeftRadius: pxToRem(16),
    borderTopRightRadius: pxToRem(16),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2.5),
    padding: theme.spacing(2, 0, 5)
  }, theme.breakpoints.up('desktop'), {
    borderRadius: 'unset',
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(2.5)
  });

  return styles;
});
var StyledContainer = styled(Container)(function (_ref2) {
  var theme = _ref2.theme;

  var styles = _defineProperty({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1'
  }, theme.breakpoints.up('desktop'), {
    display: 'block'
  });

  return styles;
});
var StyledContentWrapper = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;

  var styles = _defineProperty({
    display: 'flex',
    flex: '1',
    flexDirection: 'column'
  }, theme.breakpoints.up('desktop'), {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: theme.typography.pxToRem(452)
  });

  return styles;
});
export { StyledBox, StyledContainer, StyledContentWrapper };