import { styled as e, Box as t, Stack as r, Divider as o } from "@mui/material";
import "../../themes/box-shadows/boxShadows.js";
import "../../themes/dark.theme.js";
import { pxToRem as i } from "../../themes/default-theme/default-theme.js";
import "../../themes/gradients/gradients.js";
import "../../themes/light.theme.js";
var m = e(t)(function (e) {
  e.theme;
  return {
    "& img": {
      display: "block",
      height: "100%",
      objectFit: "cover",
      width: "100%"
    }
  };
}),
    h = e(r)(function (e) {
  var t = e.theme;
  return {
    backgroundColor: t.colors.alpineGoat,
    borderRadius: i(36),
    padding: t.spacing(.5, 1)
  };
}),
    s = e(o)(function (e) {
  return e.theme, {
    height: i(12)
  };
});
export { m as StyledBox, s as StyledDivider, h as StyledRateContainer };