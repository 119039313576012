import { styled as e, Box as t } from "@mui/material";
import "../../../themes/box-shadows/boxShadows.js";
import "../../../themes/dark.theme.js";
import { pxToRem as o } from "../../../themes/default-theme/default-theme.js";
import "../../../themes/gradients/gradients.js";
import "../../../themes/light.theme.js";
var r = e(t)(function (e) {
  var t = e.theme;
  e.$filled;
  var r = e.$fullWidth;
  return {
    "& .MuiFormControl-root": {
      position: "unset"
    },
    ".MuiDayPicker-weekDayLabel": {
      color: t.colors.nightWizard,
      fontSize: o(14),
      textTransform: "capitalize"
    },
    ".MuiPickersCalendarHeader-label": {
      fontWeight: 700,
      textTransform: "capitalize"
    },
    ".MuiPickersCalendarHeader-root svg": {
      height: o(24),
      width: o(24)
    },
    ".MuiPickersDay-root": {
      color: t.colors.nightWizard,
      fontSize: o(14)
    },
    ".MuiPickersDay-root.Mui-selected": {
      backgroundColor: t.colors.panPurple,
      color: t.colors.white
    },
    position: "relative",
    width: r && "100%"
  };
});
export { r as StyledWrapper };