import { i18n } from '@localization';
/**
 * Get mapped term function.
 *
 * @author Christina Kashevar
 * @function getMappedTerm
 * @category helpers
 * @return {string} term.
 */

export var getMappedTerm = function getMappedTerm(term) {
  if (term % 12 === 0 && term !== 0) {
    var years = term / 12;
    return "".concat(i18n.t('pages.creditsCalculator.termYear', {
      count: years
    }));
  }

  return "".concat(i18n.t('pages.creditsCalculator.termMonth', {
    count: term
  }));
};