import { __assign as r } from "../../../../_virtual/_tslib.js";
import { jsx as i } from "react/jsx-runtime";
import { Box as o } from "@mui/material";
import { Icon as t } from "../../../icon/icon.component.js";
import e from "../../../../packages/pwa-ui/assets/images/arrow-right.image.svg.js";

var m = function () {
  return i(o, r({
    alignItems: "center",
    alignSelf: "center",
    component: "span",
    display: "flex",
    ml: 2.7
  }, {
    children: i(t, {
      color: "bayFog",
      height: 10,
      src: e,
      width: 6
    })
  }));
};

export { m as Arrow };