import { Box, styled } from '@mui/material';
var StyledReceiverTitle = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    margin: theme.spacing(0, 0, 1, 1)
  };
  return styles;
});
var StyledReceiverBox = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    backgroundColor: theme.palette.white.main,
    borderRadius: theme.typography.pxToRem(16),
    padding: theme.spacing(2)
  };
  return styles;
});
export { StyledReceiverBox, StyledReceiverTitle };