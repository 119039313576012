import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { pxToRem } from '@pwa/ui';
import { Box, Container, Stack, styled } from '@mui/material';
var StyledWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    justifyContent: 'space-between'
  }, theme.breakpoints.up('desktop'), {
    justifyContent: 'normal'
  });

  return styles;
});
var StyledContainer = styled(Container)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    marginTop: theme.spacing(3)
  };
  return styles;
});
var StyledBox = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    backgroundColor: theme.palette.white.main,
    borderRadius: pxToRem(12),
    padding: theme.spacing(2)
  };
  return styles;
});
var StyledButtonsWrapper = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(5)
  };
  return styles;
});
var StyledButtons = styled(Stack)(function (_ref5) {
  var theme = _ref5.theme;

  var styles = _defineProperty({}, theme.breakpoints.up('desktop'), {
    alignItems: 'baseline',
    flexDirection: 'row-reverse',
    gap: theme.spacing(2)
  });

  return styles;
});
var StyledSkeleton = styled(Container)(function () {
  var styles = {
    display: 'flex',
    flexGrow: '1'
  };
  return styles;
});
export { StyledBox, StyledButtons, StyledButtonsWrapper, StyledContainer, StyledSkeleton, StyledWrapper };