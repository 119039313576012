import e from "../../node_modules/date-fns/esm/addDays/index.js";
import n from "../../node_modules/date-fns/esm/differenceInDays/index.js";
import t from "../../node_modules/date-fns/esm/format/index.js";
import r from "../../node_modules/date-fns/esm/formatISO/index.js";
import o from "../../node_modules/date-fns/esm/isValid/index.js";
import s from "../../node_modules/date-fns/esm/parse/index.js";
import m from "../../node_modules/date-fns/esm/parseISO/index.js";
import d from "../../node_modules/date-fns/esm/subYears/index.js";
import { MomentToDaterFnsFormat as i } from "../../enums/entities/entities.enum.js";
import "../../enums/keyboard/keyboard.enum.js";
import "../../enums/theme/theme.enum.js";
import a from "../../node_modules/date-fns/esm/locale/ru/index.js";
import u from "../../node_modules/date-fns/esm/locale/en-IN/index.js";

var f = function (e, n) {
  return n ? s(e, n, new Date()) : new Date(e);
},
    l = function (e, n, r) {
  var s = "string" == typeof e ? new Date(e) : e;
  return o(s) ? t(s, n || "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", {
    locale: "ru" === r ? a : u
  }) : t(new Date(), n || "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", {
    locale: "ru" === r ? a : u
  });
},
    p = function (e, n, r, m) {
  var d = "string" == typeof e ? s(e, n, new Date()) : e;
  return o(d) ? t(d, r || "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", {
    locale: m && "ru" !== m ? u : a
  }) : "string" == typeof e ? e : "";
},
    x = function (n) {
  return void 0 === n && (n = 30), r(e(new Date().setHours(0, 0, 0, 0), -n));
},
    S = function (e) {
  return void 0 === e && (e = 1), r(d(new Date().setHours(0, 0, 0, 0), e));
},
    c = function () {
  return r(new Date().setHours(0, 0, 0, 0));
},
    y = function (e, n) {
  if (!e || !m(e)) return new Date().toISOString();

  if (n) {
    var t = s(e, n, new Date());

    if (!o(t)) {
      var r = l(e, n);
      return r && void 0 !== r ? s(r, n, new Date()).toISOString() : new Date().toISOString();
    }

    return t.toISOString();
  }

  return new Date(e).toISOString();
},
    D = function (e) {
  var n = new RegExp("(".concat(Object.keys(i).join("|"), ")"), "g");
  return e.replace(n, function (e) {
    return i[e];
  });
},
    w = function (e, t) {
  var r = new Date(e),
      o = new Date(t);
  return n(o, r);
};

export { D as convertMomentDateFormat, p as dateConvertFormatter, l as dateFormatter, f as dateParser, w as getDifferenceInDays, y as getISODate, x as getPriorYMDDate, S as getPriorYearsYMDDate, c as getYMDToday };