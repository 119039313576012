// type PaymentsCardsFormSchemaProps = {};
import * as yup from 'yup';
import { Currency } from '@enums';
import { i18n } from '@localization';
import { MinAmountByCountry } from './instant-transfer-details-form.enum';

var instantTransferFormSchema = function instantTransferFormSchema() {
  var currency = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : Currency.BYN;
  var amountBYN = arguments.length > 1 ? arguments[1] : undefined;
  var isBYN = currency === Currency.BYN;
  var min = isBYN ? MinAmountByCountry.Belarus : MinAmountByCountry.Russia;
  return yup.object().shape({
    bankCode: yup.string().test({
      message: i18n.t('forms.validation.required'),
      name: 'bankCode',
      test: function test(value) {
        return !!value || !!(value !== null && value !== void 0 && value.length);
      }
    }).nullable(),
    bankName: yup.string().test({
      message: i18n.t('forms.validation.required'),
      name: 'bankName',
      test: function test(value) {
        return !!value || !!(value !== null && value !== void 0 && value.length);
      }
    }).nullable(),
    banks: yup.object().test({
      message: i18n.t('forms.validation.required'),
      name: 'banks',
      test: function test(value) {
        return !!value;
      }
    }).nullable(),
    currentAmount: yup.number().required(i18n.t('forms.validation.required')).min(min, i18n.t('forms.validation.minAmount', {
      data: "".concat(min, ",00 ").concat(currency)
    })).when({
      is: function is() {
        return isBYN && (!!amountBYN || amountBYN === 0);
      },
      then: function then(schema) {
        return schema.max(amountBYN, i18n.t('forms.validation.notEnoughMoney'));
      }
    }),
    paymentPurpose: yup.object().test({
      message: i18n.t('forms.validation.required'),
      name: 'paymentPurpose',
      test: function test(value) {
        return !!value;
      }
    }).nullable(),
    purposeCode: yup.string().test({
      name: 'checkPurposeCode',
      test: function test(value, ctx) {
        if (!value || !value.length) {
          return ctx.createError({
            message: i18n.t('forms.validation.required')
          });
        }

        return true;
      }
    }).nullable(),
    purposeCodeDescription: yup.string().test({
      name: 'checkPurposeCodeShortDescription',
      test: function test(value, ctx) {
        if (!value || !value.length) {
          return ctx.createError({
            message: i18n.t('forms.validation.required')
          });
        }

        return true;
      }
    }).nullable()
  });
};

export { instantTransferFormSchema };