import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';
import { ConstantsUtil } from '@utils';
import { CLOSE_MODAL, CLOSING_MODAL_WITH_ANSWER, INIT_CLOSE_MODAL, INIT_MODAL_CLOSING_WITH_ANSWER, MODAL_INIT, SET_MODAL } from './modals.action-types';
export var actions = {
  /**
   * Close modal with answer.
   *
   * @author Ihar Kazlouski
   * @function closeWithAnswer
   * @category Actions
   * @subcategory Modals
   * @param {ModalCloseWithAnswerPayload} payload payload.
   */
  closeWithAnswer: function closeWithAnswer(payload) {
    return {
      payload: payload,
      type: CLOSING_MODAL_WITH_ANSWER
    };
  },

  /**
   * Close modal.
   *
   * @author Ihar Kazlouski
   * @function modalClose
   * @category Actions
   * @subcategory Modals
   * @param {ModalClosePayload} payload payload.
   */
  modalClose: function modalClose(payload) {
    return {
      payload: payload,
      type: CLOSE_MODAL
    };
  },

  /**
   * Init modal opening.
   *
   * @author Ihar Kazlouski
   * @function modalInit
   * @category Actions
   * @subcategory Modals
   * @param {ModalPayload} payload payload.
   */
  modalInit: function modalInit(payload) {
    var _ref;

    return _ref = {}, _defineProperty(_ref, ERROR_ACTION, "".concat(MODAL_INIT, "/").concat(ConstantsUtil.actions.ASYNC_FAILED)), _defineProperty(_ref, WAIT_FOR_ACTION, "".concat(MODAL_INIT, "/").concat(ConstantsUtil.actions.ASYNC_SUCCESS)), _defineProperty(_ref, "payload", payload), _defineProperty(_ref, "type", MODAL_INIT), _ref;
  },

  /**
   * Init modal closing.
   *
   * @author Ihar Kazlouski
   * @function modalInitClosing
   * @category Actions
   * @subcategory Modals
   * @param {ModalClosePayload} payload payload.
   */
  modalInitClosing: function modalInitClosing(payload) {
    return {
      payload: payload,
      type: INIT_CLOSE_MODAL
    };
  },

  /**
   * Init closing with answer.
   *
   * @author Ihar Kazlouski
   * @function modalInitClosingWithAnswer
   * @category Actions
   * @subcategory Modals
   * @param {ModalCloseWithAnswerPayload} payload payload.
   */
  modalInitClosingWithAnswer: function modalInitClosingWithAnswer(payload) {
    return {
      payload: payload,
      type: INIT_MODAL_CLOSING_WITH_ANSWER
    };
  },

  /**
   * Set necessary modal.
   *
   * @author Ihar Kazlouski
   * @function setModal
   * @category Actions
   * @subcategory Modals
   * @param {ModalPayload} payload payload.
   */
  setModal: function setModal(payload) {
    return {
      payload: payload,
      type: SET_MODAL
    };
  }
};