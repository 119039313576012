import { Box, styled } from '@mui/material';
var StyledSeparate = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    backgroundColor: theme.palette.estateViolet.main,
    borderRadius: theme.typography.pxToRem(4),
    height: theme.typography.pxToRem(20),
    width: theme.typography.pxToRem(2)
  };
  return styles;
});
export { StyledSeparate };