import { Box, Link, styled } from '@mui/material';
import { pxToRem } from '@themes';
var StyledWrapper = styled(Link)(function (_ref) {
  var $unwatched = _ref.$unwatched,
      theme = _ref.theme;
  var styles = {
    borderColor: $unwatched ? theme.colors.panPurple : theme.colors.lavenderSyrup,
    borderRadius: pxToRem(20),
    borderStyle: 'solid',
    borderWidth: pxToRem(2),
    cursor: 'pointer',
    display: 'inline-block',
    lineHeight: 0,
    padding: pxToRem(4),
    position: 'relative',
    textDecoration: 'none'
  };
  return styles;
});
var StyledBox = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    color: theme.colors.white,
    lineHeight: 1,
    margin: 0,
    p: {
      margin: 0
    },
    position: 'absolute',
    textAlign: 'left'
  };
  return styles;
});
export { StyledBox, StyledWrapper };