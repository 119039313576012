import image from '@assets/images/payment.image.png';

var getPaymentsPopupTitle = function getPaymentsPopupTitle() {
  return '<h2>Избранные платежи</h2>';
};

var getPaymentsPopupText = function getPaymentsPopupText() {
  return '<ol><li><h2>На экране “Платежи”</h2><p>На экране “Платежи” нажмите кнопку “Добавить”, вам откроется страница с вашей историей платежей, найдите платеж который хотите добавить, откройте его детальную информацию, и нажмите кнопку “Добавить в избранные”.</p></li><li><h2>На экране успешного платежа</h2><p>После того как вы завершили платеж, на экране который сообщает вам в том что платеж успешно совершен нажмите кнопку “Добавить в избранные”.</p></li><li><h2>Добавление из истории</h2><p>Для добавления платежа в “Избранные” перейдите в историю платежей, найдите платеж который хотите добавить, откройте его детальную информацию и нажмите кнопку “Добавить в избранные”.</p></li></ol>';
};

var fields = [{
  name: 'lastName',
  title: 'Фамилия',
  type: 'text',
  value: 'Стульба'
}, {
  name: 'Дата выставления',
  title: 'Дата',
  type: 'info',
  value: '12.04.2023'
}, {
  name: 'Период',
  title: 'Период',
  type: 'info',
  value: '03.2023'
}, {
  name: 'Name',
  title: 'Имя',
  type: 'text',
  value: 'Сергей'
}, {
  name: 'surname',
  title: 'Отчество',
  type: 'text',
  value: 'Владимирович'
}, {
  name: 'Счётчик 2-предыдущ. знач.',
  title: 'Предыдущий',
  type: 'info',
  value: '30,45'
}, {
  name: 'amount',
  required: true,
  title: 'Сумма',
  type: 'number',
  value: '10 BYN'
}];

var getTempPayments = function getTempPayments() {
  return [{
    details: '+375(29)806-78-91',
    eripTitle: 'МТС по № телефона',
    fields: fields,
    id: 1,
    image: image,
    title: 'Оплата телефона А1 5522'
  }, {
    details: 'BY00ALFA00000C00C0000000000',
    eripTitle: 'Пополнение счета',
    fields: fields,
    id: 2,
    image: image,
    title: 'Пополнение счета'
  }, {
    details: 'BY00ALFA00000C00C0000000000',
    eripTitle: 'Пополнение счета',
    fields: fields,
    id: 3,
    image: image,
    title: 'Онлайн кошелек'
  }, {
    details: '+375(29)806-78-91',
    eripTitle: 'МТС по № телефона',
    fields: fields,
    id: 4,
    image: image,
    title: 'МТС 45679'
  }, {
    details: 'BY00ALFA00000C00C0000000000',
    eripTitle: 'Пополнение счета',
    fields: fields,
    id: 5,
    image: image,
    title: 'Онлайн кошелек2'
  }, {
    details: '+375(29)806-78-91',
    eripTitle: 'МТС по № телефона',
    fields: fields,
    id: 6,
    image: image,
    title: 'МТС 45675'
  }, {
    details: '+375(29)806-78-91',
    eripTitle: 'МТС по № телефона',
    fields: fields,
    id: 7,
    image: image,
    title: 'МТС 4567'
  }, {
    details: 'BY00ALFA00000C00C0000000000',
    eripTitle: 'Пополнение счета',
    fields: fields,
    id: 8,
    image: image,
    title: 'Пополнение счета3'
  }, {
    details: 'BY00ALFA00000C00C0000000000',
    eripTitle: 'Пополнение счета',
    fields: fields,
    id: 9,
    image: image,
    title: 'Онлайн кошелек3'
  }, {
    details: '+375(29)806-78-91',
    eripTitle: 'МТС по № телефона',
    fields: fields,
    id: 10,
    image: image,
    title: 'МТС 456754'
  }, {
    details: 'BY00ALFA00000C00C0000000000',
    eripTitle: 'Пополнение счета',
    fields: fields,
    id: 11,
    image: image,
    title: 'Пополнение счета2'
  }, {
    details: '+375(29)806-78-91',
    eripTitle: 'МТС по № телефона',
    fields: fields,
    id: 12,
    image: image,
    title: 'МТС 4567'
  }, {
    details: 'BY00ALFA00000C00C0000000000',
    eripTitle: 'Пополнение счета',
    fields: fields,
    id: 13,
    image: image,
    title: 'Онлайн кошелек2'
  }];
};

var fieldNames = getTempPayments().map(function (payment) {
  return payment.title;
});
export { fieldNames, getPaymentsPopupText, getPaymentsPopupTitle, getTempPayments };