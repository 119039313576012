import { __assign as e } from "../../_virtual/_tslib.js";
import { jsx as t } from "react/jsx-runtime";
import { ThemeProvider as m } from "@mui/material";
import "../../themes/box-shadows/boxShadows.js";
import "../../themes/dark.theme.js";
import "../../themes/default-theme/default-theme.js";
import "../../themes/gradients/gradients.js";
import { lightTheme as r } from "../../themes/light.theme.js";

function i(i) {
  var o = i.displayName || i.name || "Component",
      s = function (o) {
    return t(m, e({
      theme: r
    }, {
      children: t(i, e({}, o))
    }));
  };

  return s.displayName = "withTheme(".concat(o, ")"), s;
}

export { i as withTheme };