import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";

/**
 * Helps to create an array of objects of a select field items.
 *
 * @author Christina Kashevar
 * @function getTermItemsForSelectField
 * @category helpers
 * @return {array} array of radio field items.
 */
var getRegionItemsForSelectField = function getRegionItemsForSelectField(region, regionCenter) {
  var mappedRegions = region.map(function (_ref) {
    var regionType = _ref.regionType,
        regionName = _ref.regionName;
    return {
      text: "".concat(regionType, " ").concat(regionName),
      value: regionName
    };
  });
  var mappedRegionCenters = regionCenter.map(function (_ref2) {
    var localityName = _ref2.localityName,
        localityType = _ref2.localityType;
    return {
      text: "".concat(localityType, " ").concat(localityName),
      value: localityName
    };
  });
  return [].concat(_toConsumableArray(mappedRegions), _toConsumableArray(mappedRegionCenters));
};

export { getRegionItemsForSelectField };