import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { CardButton } from '@pwa/ui';
import { Box, Grid, styled } from '@mui/material';
var StyledHomeWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(2.5)
  }, theme.breakpoints.up('desktop'), {
    paddingTop: theme.spacing(3)
  });

  return styles;
});
var StyledGridContainer = styled(Grid)(function (_ref2) {
  var theme = _ref2.theme;

  var styles = _defineProperty({}, theme.breakpoints.up('desktop'), {
    flexWrap: 'nowrap'
  });

  return styles;
});
var StyledCardButton = styled(CardButton)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    height: '100%',
    padding: theme.spacing(1.5)
  };
  return styles;
});
export { StyledCardButton, StyledGridContainer, StyledHomeWrapper };