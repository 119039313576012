import { Box, styled } from '@mui/material';
var StyledContent = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1'
  };
  return styles;
});
var StyledContainer = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    '& >div': {
      '& >div.MuiBox-root': {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: '1',
        marginTop: theme.spacing(2)
      },
      display: 'flex',
      flexDirection: 'column',
      flexGrow: '1'
    },
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    marginTop: theme.spacing(2)
  };
  return styles;
});
export { StyledContainer, StyledContent };