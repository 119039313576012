import { styled as o, FormControl as e, InputBase as r, inputBaseClasses as t, alpha as i, InputLabel as n, Box as a, FormHelperText as s } from "@mui/material";
import "react";
import { getHexColor as l } from "../../../helpers/colors/colors.helper.js";
import "../../../enums/entities/entities.enum.js";
import "../../../enums/keyboard/keyboard.enum.js";
import "../../../enums/theme/theme.enum.js";
import "../../../node_modules/@babel/runtime/helpers/typeof.js";
import "../../../themes/box-shadows/boxShadows.js";
import "../../../themes/dark.theme.js";
import { pxToRem as c } from "../../../themes/default-theme/default-theme.js";
import "../../../themes/gradients/gradients.js";
import "../../../themes/light.theme.js";
var d = o(e)(function (o) {
  return {
    display: o.fullWidth && "flex",
    maxWidth: "100%"
  };
}),
    m = o(r)(function (o) {
  var e,
      r = o.theme,
      n = o.color,
      a = o.$bold,
      s = l(r.palette, n),
      d = ((e = {})["& .InputAdornment-clearable"] = {
    visibility: "hidden"
  }, e["&.".concat(t.focused, " .InputAdornment-clearable")] = {
    visibility: "visible"
  }, e.backgroundColor = s, e.border = s && "".concat(c(1), " solid ").concat(s), e.borderRadius = c(12), e.color = r.colors.nightWizard, e.overflow = "hidden", e.transition = r.transitions.create(["border-color"]), e["&.".concat(t.error)] = {
    borderColor: r.palette.error.main
  }, e["&.".concat(t.focused)] = {
    borderColor: r.palette.primary.main
  }, e["& .".concat(t.input)] = {
    boxSizing: "border-box",
    fontSize: c(a ? 24 : 16),
    fontWeight: a ? 700 : 500,
    height: c(56),
    lineHeight: c(a ? 26 : 18),
    padding: r.spacing(2, 2, 2, 1.5),
    position: "relative"
  }, e);

  switch (n) {
    case "secondary":
      d.backgroundColor = r.colors.ghostWhite, d.borderColor = r.colors.ghostWhite, d["&.".concat(t.focused)] = {
        borderColor: r.colors.panPurple
      };
      break;

    case "bayFog":
      d.backgroundColor = i(r.colors.ghostWhite, .05), d.borderColor = i(r.colors.white, .1), d["&.".concat(t.focused)] = {
        borderColor: r.colors.panPurple
      }, d.color = r.colors.white;
  }

  return d;
}),
    p = o(n)(function (o) {
  var e = o.theme,
      r = o.$color,
      t = o.$wrap,
      i = {
    color: e.colors.abandonedSpaceship,
    display: "block",
    fontSize: c(16),
    fontWeight: 400,
    lineHeight: c(18),
    marginBottom: e.spacing(.5),
    maxWidth: "100%",
    paddingLeft: e.spacing(1),
    position: "relative",
    transform: "unset",
    transformOrigin: "unset",
    whiteSpace: t ? "unset" : "nowrap"
  };
  return "bayFog" === r && (i.color = e.colors.white), i;
}),
    h = o(a)(function () {
  return {
    fontSize: c(12),
    left: 0,
    lineHeight: c(14),
    marginTop: c(4),
    position: "absolute",
    right: 0,
    top: "100%"
  };
}),
    u = o(s)(function (o) {
  var e = o.theme;
  return {
    color: e.palette.error.main,
    display: "block",
    margin: "unset",
    maxWidth: "100%",
    padding: e.spacing(0, 1)
  };
}),
    b = o(s)(function (o) {
  var e = o.theme,
      r = o.$color,
      t = {
    color: e.colors.abandonedSpaceship,
    display: "block",
    margin: "unset",
    maxWidth: "100%",
    padding: e.spacing(0, 1)
  };
  return "bayFog" === r && (t.color = e.colors.white), t;
});
export { h as StyleFromHelperWrapper, d as StyledFormControl, u as StyledFormHelperErrorText, b as StyledFormHelperText, m as StyledInputBase, p as StyledInputLabel };