import facebook from '@images/social/facebook.image.svg';
import instagram from '@images/social/instagram.image.svg';
import telegram from '@images/social/telegram.image.svg';
import vk from '@images/social/vk.image.svg';
export var getSocialItems = function getSocialItems() {
  return [{
    href: 'https://t.me/BelVEBby',
    icon: telegram,
    title: 'telegram'
  }, {
    href: 'https://www.instagram.com/bankbelveb',
    icon: instagram,
    title: 'instagram'
  }, {
    href: 'https://www.facebook.com/belveb',
    icon: facebook,
    title: 'facebook'
  }, {
    href: 'https://vk.com/bvebbank',
    icon: vk,
    title: 'vkontakte'
  }];
};