var e = "undefined" != typeof globalThis ? globalThis : "undefined" != typeof window ? window : "undefined" != typeof global ? global : "undefined" != typeof self ? self : {};

function o(e) {
  return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}

function t(e) {
  var o = {
    exports: {}
  };
  return e(o, o.exports), o.exports;
}

export { e as commonjsGlobal, t as createCommonjsModule, o as getDefaultExportFromCjs };