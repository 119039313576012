import { createSelector } from 'reselect';

/**
 * Get forms from state.
 *
 * @author Yury Kuntsou
 * @function getForms
 * @category Selectors
 * @subcategory Forms
 * @param {AppState} state state.
 * @return {FormState[]} modals state.
 */
var getForms = function getForms(state) {
  return state.forms;
};
/**
 * Get forms array.
 *
 * @author Yury Kuntsou
 * @function getFormsSelector
 * @category Selectors
 * @subcategory Forms
 * @param {AppState} state state.
 * @return {FormState[]} modals state.
 */


export var getFormsSelector = createSelector(getForms, function (forms) {
  return forms;
});
/**
 * Get form.
 *
 * @author Yury Kuntsou
 * @function getFormSelector
 * @category Selectors
 * @subcategory Forms
 * @param {AppState} state state.
 * @return {FormState | undefined} modals state.
 */

export var getFormSelector = function getFormSelector(formId) {
  return createSelector(getFormsSelector, function (forms) {
    return forms.find(function (form) {
      return form.formId === formId;
    });
  });
};
/**
 * Get form id.
 *
 * @author Yury Kuntsou
 * @function getFormIdSelector
 * @category Selectors
 * @subcategory Forms
 * @param {AppState} state state.
 * @return {FormState['formId'] | undefined} modals state.
 */

export var getFormIdSelector = function getFormIdSelector(formId) {
  return createSelector([getFormSelector(formId)], function (form) {
    return form === null || form === void 0 ? void 0 : form.formId;
  });
};
/**
 * Get form values.
 *
 * @author Yury Kuntsou
 * @function getFormValuesSelector
 * @category Selectors
 * @subcategory Forms
 * @param {AppState} state state.
 * @return {FormState['values'] | undefined} modals state.
 */

export var getFormValuesSelector = function getFormValuesSelector(formId) {
  return createSelector([getFormSelector(formId)], function (form) {
    return form === null || form === void 0 ? void 0 : form.values;
  });
};