export var getProductsDepositsListMock = function getProductsDepositsListMock() {
  return [{
    alreadyPaid: 0,
    avlAmount: 1,
    closeOpportunity: true,
    currency: 'BYN',
    currentSum: 1,
    dealCode: 'VF290922-0001',
    dealType: 'ВВФ',
    depositCapitalize: false,
    depositName: 'Зручны анл@йн',
    depositTerm: 0,
    depositTermType: 'до востребования',
    depositType: 'D',
    finishDate: '2099-01-01',
    id: 1,
    interestCurrentForecast: 0,
    interestDate: '2023-05-29',
    interestForecastTotal: 0,
    isEnd: false,
    isHidden: true,
    isOnline: false,
    nextInterestDate: '2023-05-29',
    openDate: '2023-04-28',
    rate: 0,
    replenishOpportunity: true,
    withdrawOpportunity: true
  }, {
    alreadyPaid: 0,
    avlAmount: 1,
    closeOpportunity: false,
    currency: 'BYN',
    currentSum: 1,
    dealCode: 'VF290922-0001',
    dealType: 'ВВФ',
    depositCapitalize: false,
    depositName: 'Зручны анл@йн',
    depositTerm: 0,
    depositTermType: 'до востребования',
    depositType: 'D',
    finishDate: '2099-01-01',
    id: 2,
    interestCurrentForecast: 0,
    interestDate: '2023-05-29',
    interestForecastTotal: 0,
    isEnd: false,
    isHidden: true,
    isOnline: false,
    nextInterestDate: '2023-05-29',
    openDate: '2023-04-28',
    rate: 0,
    replenishOpportunity: true,
    withdrawOpportunity: true
  }, {
    alreadyPaid: 0,
    avlAmount: 1,
    closeOpportunity: false,
    currency: 'BYN',
    currentSum: 1,
    dealCode: 'VF290922-0001',
    dealType: 'ВВФ',
    depositCapitalize: false,
    depositName: 'Зручны анл@йн',
    depositTerm: 0,
    depositTermType: 'до востребования',
    depositType: 'D',
    finishDate: '2099-01-01',
    id: 3,
    interestCurrentForecast: 0,
    interestDate: '2023-05-29',
    interestForecastTotal: 0,
    isEnd: false,
    isHidden: false,
    isOnline: false,
    nextInterestDate: '2023-05-29',
    openDate: '2023-04-28',
    rate: 0,
    replenishOpportunity: true,
    withdrawOpportunity: true
  }, {
    alreadyPaid: 0,
    avlAmount: 1,
    closeOpportunity: false,
    currency: 'BYN',
    currentSum: 1,
    dealCode: 'VF290922-0001',
    dealType: 'ВВФ',
    depositCapitalize: false,
    depositName: 'Зручны анл@йн',
    depositTerm: 0,
    depositTermType: 'до востребования',
    depositType: 'D',
    finishDate: '2099-01-01',
    id: 4,
    interestCurrentForecast: 0,
    interestDate: '2023-05-29',
    interestForecastTotal: 0,
    isEnd: false,
    isHidden: false,
    isOnline: false,
    nextInterestDate: '2023-05-29',
    openDate: '2023-04-28',
    rate: 0,
    replenishOpportunity: true,
    withdrawOpportunity: true
  }, {
    alreadyPaid: 0,
    avlAmount: 1,
    closeOpportunity: true,
    currency: 'BYN',
    currentSum: 1,
    dealCode: 'VF290922-0001',
    dealType: 'ВВФ',
    depositCapitalize: false,
    depositName: 'Зручны анл@йн',
    depositTerm: 0,
    depositTermType: 'до востребования',
    depositType: 'D',
    finishDate: '2099-01-01',
    id: 5,
    interestCurrentForecast: 0,
    interestDate: '2023-05-29',
    interestForecastTotal: 0,
    isEnd: false,
    isHidden: false,
    isOnline: false,
    nextInterestDate: '2023-05-29',
    openDate: '2023-04-28',
    rate: 0,
    replenishOpportunity: true,
    withdrawOpportunity: true
  }];
};