/**
 * Helps to create an array of objects of a select field items.
 *
 * @author Christina Kashevar
 * @function getTermItemsForSelectField
 * @category helpers
 * @return {array} array of radio field items.
 */
var getStreetItemsForSelectField = function getStreetItemsForSelectField(streets) {
  return streets.map(function (_ref) {
    var streetTypeFull = _ref.streetTypeFull,
        streetTypeShort = _ref.streetTypeShort;
    return {
      text: streetTypeFull,
      value: streetTypeShort
    };
  });
};

export { getStreetItemsForSelectField };