import { pxToRem, Typography } from '@pwa/ui';
import { alpha, Box, styled } from '@mui/material';
var StyledWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    backgroundColor: alpha(theme.palette.white.main, 0.1),
    borderRadius: pxToRem(12),
    padding: theme.spacing(2.25, 0),
    width: '100%'
  };
  return styles;
});
var StyledTypography = styled(Typography)(function () {
  var styles = {
    opacity: '0.7'
  };
  return styles;
});
export { StyledTypography, StyledWrapper };