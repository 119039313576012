import { Box, styled } from '@mui/material';
var StyledWrapperCenter = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    alignItems: 'center',
    display: 'flex',
    flexGrow: '1',
    justifyContent: 'center',
    textAlign: 'center'
  };
  return styles;
});
export { StyledWrapperCenter };