import { Box, styled } from '@mui/material';
var StyledCardInfo = styled(Box)(function (_ref) {
  var _theme$colors;

  var theme = _ref.theme;
  var styles = {
    alignItems: 'center',
    backgroundColor: theme === null || theme === void 0 ? void 0 : (_theme$colors = theme.colors) === null || _theme$colors === void 0 ? void 0 : _theme$colors.translucentUnicorn,
    borderRadius: theme.typography.pxToRem(6),
    display: 'flex',
    height: theme.typography.pxToRem(20),
    justifyContent: 'center',
    width: theme.typography.pxToRem(20)
  };
  return styles;
});
export { StyledCardInfo };