import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Button } from '@pwa/ui';
import { Box, Container, Stack, styled } from '@mui/material';
var StyledWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    display: 'flex',
    flexGrow: '1',
    margin: theme.spacing(-9, 0, 3)
  }, theme.breakpoints.up('desktop'), {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: theme.typography.pxToRem(452)
  });

  return styles;
});
var StyledContainer = styled(Container)(function (_ref2) {
  var theme = _ref2.theme;

  var styles = _defineProperty({
    display: 'flex',
    flexGrow: '1'
  }, theme.breakpoints.up('desktop'), {
    display: 'block'
  });

  return styles;
});
var StyledButtonWrapper = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    padding: theme.spacing(2.5, 0, 2)
  };
  return styles;
});
var StyledStack = styled(Stack)(function () {
  var styles = {
    flexGrow: '1',
    maxWidth: '100%'
  };
  return styles;
});
var StyledIconWrapper = styled(Button)(function () {
  var styles = {
    height: '100%',
    justifyContent: 'flex-end',
    minWidth: 'unset',
    padding: '0'
  };
  return styles;
});
export { StyledButtonWrapper, StyledContainer, StyledIconWrapper, StyledStack, StyledWrapper };