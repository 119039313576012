import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { pxToRem } from '@pwa/ui';
import { Box, Container, Stack, styled } from '@mui/material';
var StyledWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: theme.spacing(3, 0, 5)
  }, theme.breakpoints.up('desktop'), {
    display: 'block',
    maxWidth: theme.typography.pxToRem(452),
    paddingTop: theme.spacing(2)
  });

  return styles;
});
var StyledContainer = styled(Container)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    display: 'flex',
    flex: '1',
    flexDirection: 'column'
  };
  return styles;
});
var StyledButtonsWrapper = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    marginTop: theme.spacing(4)
  };
  return styles;
});
var StyledRadioWrapper = styled(Stack)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    '& .MuiFormGroup-root': {
      '& label': {
        '& button': {
          flexGrow: '1',
          minWidth: 'unset'
        },
        flexGrow: '1'
      },
      display: 'flex',
      flexDirection: 'row'
    },
    backgroundColor: theme.palette.white.main,
    borderRadius: pxToRem(16),
    padding: theme.spacing(0.5)
  };
  return styles;
});
export { StyledButtonsWrapper, StyledContainer, StyledRadioWrapper, StyledWrapper };