import { __rest as e, __spreadArray as r, __read as o, __assign as n } from "../../../_virtual/_tslib.js";
import { jsxs as t, jsx as a } from "react/jsx-runtime";
import { useRef as i, useEffect as c, useCallback as l } from "react";
import { Box as u, Stack as s } from "@mui/material";
import "../../../enums/entities/entities.enum.js";
import { KeyCode as d, EventKey as f } from "../../../enums/keyboard/keyboard.enum.js";
import "../../../enums/theme/theme.enum.js";
import { StyledInputLabel as m, StyledSeparate as p, StyledTextField as v, StyleFromHelperWrapper as h, StyledFormHelperErrorText as b, StyledFormHelperText as k } from "./pin-field.styles.js";

var y = function (y) {
  y.value;
  var g = y.name,
      w = y.error,
      A = y.inputProps,
      C = void 0 === A ? {} : A,
      F = y.fields,
      x = void 0 === F ? 6 : F,
      L = y.maxLength,
      j = void 0 === L ? 1 : L,
      B = y.onChange,
      D = y.onFocus,
      P = y.onBlur,
      S = y.onComplete,
      E = y.bottomLabel,
      O = y.label,
      R = y.isShowError,
      U = void 0 === R || R,
      N = y.clearPlaceholderOnFocus,
      $ = y.isSeparate,
      _ = y.autoFocus,
      I = e(y, ["value", "name", "error", "inputProps", "fields", "maxLength", "onChange", "onFocus", "onBlur", "onComplete", "bottomLabel", "label", "isShowError", "clearPlaceholderOnFocus", "isSeparate", "autoFocus"]),
      K = Array.from(Array(x), function () {
    return i(null);
  }),
      M = i(null),
      T = g || "pinfield",
      W = y.value || Array.from(Array(x), function () {
    return "";
  }),
      q = U && !!w;
  c(function () {
    var e;
    q && 0 === W.indexOf("") && (null === (e = K[0].current) || void 0 === e || e.focus());
  }, [q]);
  var z = l(function (e, n) {
    var t = "string" == typeof e ? e : e.target.value;

    if (B) {
      var a = r([], o(W), !1);
      a[n] = t.slice(0, j), B && B(a);
    }
  }, [W, j, B]),
      G = l(function (e) {
    var r,
        o,
        n = e.target,
        t = Number(n.dataset.id);

    if (n) {
      var a = null === (r = K[t + 1]) || void 0 === r ? void 0 : r.current,
          i = null === (o = K[t - 1]) || void 0 === o ? void 0 : o.current;

      switch (!0) {
        case e.keyCode === d.Backspace || e.key === f.Backspace:
          "" === n.value && i && (i.focus(), i.select());
          break;

        case e.keyCode === d.LeftArrow || e.key === f.LeftArrow:
          e.preventDefault(), i && (i.focus(), i.select());
          break;

        case e.keyCode === d.RightArrow || e.key === f.RightArrow:
          e.preventDefault(), a && (a.focus(), a.select());
          break;

        case e.keyCode === d.UpArrow || e.key === f.UpArrow:
        case e.keyCode === d.DownArrow || e.key === f.DownArrow:
          e.preventDefault();
          break;

        case e.keyCode === d.E || e.key === f.E:
          if ("number" === n.type) {
            e.preventDefault();
            break;
          }

          break;

        default:
          if (n.value.length >= j) {
            var c = n.selectionStart;
            if (t < x - 1) Number(c) >= j && (null == a || a.focus(), null == a || a.select());else {
              var l = W.find(function (e) {
                return !e || e.length !== j;
              });
              S && void 0 === l && S(W.join(""));
            }
          }

      }
    }
  }, [x, S, W, j, K]);
  return t(u, n({
    position: "relative"
  }, {
    children: [O && a(m, n({
      $color: I.color,
      htmlFor: T,
      shrink: !0
    }, {
      children: O
    })), a(s, n({
      direction: "row",
      spacing: 1
    }, {
      children: K.map(function (e, r) {
        var o,
            i = $ && Math.ceil(x / 2) === r;
        return t(s, n({
          alignItems: "center",
          direction: "row",
          flexWrap: "nowrap",
          id: T,
          spacing: 1.5
        }, {
          children: [i && a(p, {
            children: "-"
          }), a(v, n({
            autoFocus: _ && 0 === r,
            inputRef: e,
            onBlur: function (e) {
              M.current = null, P && P(e);
            },
            onFocus: function (o) {
              setTimeout(function () {
                e.current && (e.current.selectionStart = e.current.value.length);
              }, 0), M.current = r, D && D(o);
            }
          }, I, {
            clearPlaceholderOnFocus: N && M.current === r,
            disableBottomLabel: !0,
            disableError: !0,
            disableLabel: !0,
            error: q && w,
            id: "".concat(T, "-").concat(r),
            inputProps: n(n({
              sx: {
                width: 40
              }
            }, C), (o = {}, o["data-id"] = r, o.maxLength = j + 1, o)),
            name: "".concat(T, "-").concat(r),
            value: W[r],
            onChange: function (e) {
              z(e, r);
            },
            onKeyUp: G
          }))]
        }), "".concat(T, "-").concat(r));
      })
    })), q && a(h, {
      children: a(b, n({
        id: "".concat(T, "-error-text")
      }, {
        children: w
      }))
    }), E && !q ? a(h, {
      children: "string" == typeof E ? a(k, n({
        $color: I.color
      }, {
        children: E
      })) : E
    }) : null]
  }));
};

export { y as PinField };