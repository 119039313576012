import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import * as yup from 'yup';
import { i18n } from '@localization';

var getDepositsCalculatorSchema = function getDepositsCalculatorSchema(currencies, depositsList, accounts) {
  var validationObject = {};
  currencies.forEach(function (currency) {
    validationObject["amount-".concat(currency)] = yup.string().trim().test({
      message: i18n.t('forms.validation.notEnoughOnAccount'),
      name: 'max',
      test: function test(value, ctx) {
        if (!value) return true;
        var fields = ctx.parent;
        var depositId = fields["term-".concat(fields.currency)];
        var deposit = depositsList.find(function (_ref) {
          var id = _ref.id;
          return id === depositId;
        });
        var maxAmount = deposit === null || deposit === void 0 ? void 0 : deposit.MaxAma;

        if (currency !== fields.currency) {
          return true;
        }

        if (maxAmount && Number(value) > maxAmount) {
          return ctx.createError({
            message: i18n.t('forms.validation.maxDepositAmount', {
              data: " ".concat(maxAmount, " ").concat(currency)
            })
          });
        }

        if (!fields.accountId) {
          return true;
        }

        var account = accounts === null || accounts === void 0 ? void 0 : accounts.find(function (acc) {
          return acc.id === fields.accountId;
        });
        if (!account) return false;
        return Number(value) <= Number(account.accountAmount);
      }
    }).test('min', '', function (value, ctx) {
      var fields = ctx.parent;
      var depositId = fields["term-".concat(fields.currency)];
      var deposit = depositsList.find(function (_ref2) {
        var id = _ref2.id;
        return id === depositId;
      });
      var minAmount = deposit === null || deposit === void 0 ? void 0 : deposit.MinAma;

      if (currency !== fields.currency) {
        return true;
      }

      if (value === undefined) {
        return ctx.createError({
          message: i18n.t('forms.validation.enterAmount')
        });
      }

      if (minAmount && Number(value) < minAmount) {
        return ctx.createError({
          message: i18n.t('forms.validation.minDepositAmount', {
            data: " ".concat(minAmount, " ").concat(currency)
          })
        });
      }

      return true;
    });
  });
  var validation = yup.object().shape(_objectSpread(_objectSpread({}, validationObject), {}, {
    checkContract: yup.bool().equals([true], i18n.t('forms.validation.required'))
  }));
  return validation;
};

export { getDepositsCalculatorSchema };