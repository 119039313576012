import cover from '@assets/images/credit-card/cover.image.png';
import green from '@assets/images/credit-card/green.image.png';
import orange from '@assets/images/credit-card/orange.image.png';
import pink from '@assets/images/credit-card/pink.image.png';
import yellow from '@assets/images/credit-card/yellow.image.png';
import { file } from './file';
import { getAccountsApiMock } from './get-accounts-list.mock';
export var getCreditsMock = function getCreditsMock() {
  return {
    credits: [{
      contractNumber: '76-160720-1-2',
      creditProgramName: 'Спраўная+',
      currency: 'BYN',
      currentDebt: 267.61,
      id: 1,
      isHidden: true,
      rate: 13.51,
      repaymentDate: '15.07.2023',
      totalDebt: 267.61
    }, {
      contractNumber: '301409512100200',
      creditProgramName: 'Яркая кредитка',
      currency: 'BYN',
      currentDebt: 306.02,
      id: 2,
      isHidden: false,
      rate: 19.89,
      repaymentDate: '27.11.2023',
      totalDebt: 1114.89
    }]
  };
};
export var getCreditItemMock = function getCreditItemMock() {
  return {
    availabilityPeriod: '30.03.2024',
    availabilityPeriodInfo: 'Автоматически продлевается при соблюдении условий кредитного договора',
    availableBalance: 5795.6,
    buttonName: 'Погасить',
    contractFinalDate: '30.03.2027',
    contractNumber: '280812231451',
    contractStartDate: '30.03.2026',
    creditDetails: [{
      name: 'Основной долг',
      value: '68.64'
    }, {
      name: 'Начисленные проценты Начисленные проценты Начисленные проценты Начисленные проценты',
      value: '2.5'
    } // {
    //   name:   'Начисленные проценты ',
    //   value:  '10',
    // },
    ],
    creditProgramName: 'Кредит',
    currentDebt: 150.6,
    debtInformation: true,
    gracePeriodDuration: '1',
    gracePeriodFinalDate: '30.03.2024',
    gracePeriodRate: '0',
    isAvailableRepayment: true,
    rate: 4.5555,
    repaymentDate: '16.01.2024',
    schedulePayments: true,
    totalCreditLimit: 7000.0,
    totalDebt: 954.4
  };
};
export var getCreditHistoryListMock = function getCreditHistoryListMock() {
  return {
    filters: {
      contractNumber: '57-270220-1-17'
    },
    historyCredit: [{
      id: 1,
      operationAmount: 11.1,
      operationCurrency: 'BYN',
      operationDate: '2023-10-13',
      operationDesc: 'Погашение штрафа',
      operationDetails: 'Процентная ставка Процентная ставка Процентная ставка Процентная ставка Процентная ставка Процентная ставка Процентная ставка ',
      operationRate: 1,
      urlIcon: '/remote/credit/5.svg'
    }, {
      id: 2,
      operationAmount: 1.03,
      operationCurrency: 'BYN',
      operationDate: '2023-04-21',
      operationDesc: 'Погашение пени',
      operationDetails: null,
      operationRate: null,
      urlIcon: '/remote/credit/5.svg'
    }, {
      id: 3,
      operationAmount: 3.39,
      operationCurrency: 'BYN',
      operationDate: '2023-04-21',
      operationDesc: 'Погашение процентов текущего месяца',
      operationDetails: null,
      operationRate: null,
      urlIcon: '/remote/credit/5.svg'
    }, {
      id: 4,
      operationAmount: null,
      operationCurrency: null,
      operationDate: '2022-11-01',
      operationDesc: 'Изменение процентной ставки',
      operationDetails: 'Процентная ставка',
      operationRate: 13.51,
      urlIcon: '/remote/credit/2.svg'
    }],
    pagination: [{
      pageOffset: 0,
      pageSize: 10,
      totalObject: 4
    }]
  };
};
export var getCreditAccountsMock = function getCreditAccountsMock() {
  return {
    accounts: getAccountsApiMock().data,
    minAmount: 0.3
  };
};
export var getCreditCustomerProfileMock = function getCreditCustomerProfileMock() {
  return {
    creditAmount: 10000,
    dossierId: 515,
    hasSavedProfile: true,
    status: 4
  };
};
export var getCreditCalculatorMock = function getCreditCalculatorMock() {
  return {
    messageAntiFraud: 'Не оформляйте кредит по требованию (просьбам) сотрудников правоохранительных органов, работников Ассоциации белорусских банков, Национального банка, иных банковских учреждений, обращающихся по телефону или посредством мессенджеров.\n\nНе устанавливайте на свои устройства программы удаленного доступа, не сообщайте посторонним лицам персональные данные, содержание смс-ообщений, кодов, иную конфиденциальную информацию',
    products: [{
      advantage: [{
        detail: '<b>0%</b> в грейс-периоде',
        orderNum: 1,
        title: ' ',
        url: ' '
      }, {
        detail: '<b>0 BYN</b> снятие наличных',
        orderNum: 2,
        title: ' ',
        url: ' '
      }],
      clientInfo: '<b>Гражданство</b> или <b>вид на жительство</b> Республики Беларусь.<br><br><b>Возраст:</b><br><br>женщины - от 21 года до 63 лет;<br><br>мужчины - от 24 лет до 67 лет.<br><br><b>Официальный источник дохода</b> на территории РБ.<br><br><b>Стаж</b> на текущем месте работы не менее 3 (трех) месяцев.<br><br><b>Кредит не предоставляется:</b><br><br><b>Индивидуальным предпринимателям;</b><br><br><b>неработающим пенсионерам;</b><br><br>физическим лицам, осуществляющим трудовую деятельность <b>без регистрации в качестве индивидуального предпринимателя</b>.',
      creditPeriodInfo: 'Срок кредитования состоит из срока пользования и срока возврата.<br><br>В течение <b>срока пользования</b> можно использовать карту в пределах остатка денежных средств на счете с учетом кредитного лимита.<br>При внесении денежных средств на  текущий счет лимит возобновляется на сумму погашения и может быть использован вновь. При соблюдении условий договора срок пользования автоматически продлевается на 12 месяцев.<br><br><b>Срок возврата</b> наступает по истечении срока пользования и составляет 12 месяцев. В этот период кредит можно только погашать.',
      currency: 'BYN',
      defaultAmount: 5000,
      defaultCreditPeriod: 24,
      gracePeriodDays: 10,
      gracePeriodInfo: ['Дает возможность пользования денежными средствами по льготной ставке.', 'Действует первые 20 дней с даты возникновения задолженности.', 'Возобновляется на следующий день при полном погашении основного долга и процентов.'],
      gracePeriodRate: 0.0,
      id: 1,
      isDefault: true,
      isFixed: true,
      isFixedRate: true,
      maxAmount: 50000.0,
      maxCreditPeriod: 24,
      maxPayment: 51.46,
      minAmount: 500.0,
      minCreditPeriod: 24,
      name: 'Кредитная карта',
      processSettingId: '9ec4d426c6a943e2b1bb150a53118500',
      productType: {
        id: 1,
        isNeedCardIssue: false,
        name: 'Кредитная карта'
      },
      rate: 33.0,
      rateAddInfo: 'СР+23.5',
      rateHelper: 'Ставка плавающая',
      rateInfo: 'Переменная процентная ставка, значение которой зависит от ставки рефинансирования Национального Банка Республики Беларусь.',
      rates: [],
      renewalPeriod: 12,
      renewalPeriodHelper: '«Действует первые 30 дней с первой проведенной операции по кредитной карточке, кроме перечня операций, указанных на сайте https://www.belveb.by/credits/credit-card/\nВозобновляется каждый раз при полном погашении текущей задолженности»',
      versID: 221005708170422
    }]
  };
};
export var getCreditCalculatorPutMock = function getCreditCalculatorPutMock() {
  return {
    maxPayment: 55
  };
};
export var getCreditMsiAuthenticationMock = function getCreditMsiAuthenticationMock() {
  return {
    data: 'https://msi.up.by/msi/authorize?redirect_uri=https://dev-credit-pwa.lab.vebtech.by/credit/callback&client_id=remote_banking_individuals&personal_number=3070574A200PB2&phone=375297578249&authentication=online_otp_face&msi_type=f7f1582f-5661-4a54-aa83-ec3cfade6caa'
  };
};
export var getCreditPersonalDataMock = function getCreditPersonalDataMock() {
  return {
    birthdate: '1975-06-08T00:00:00',
    fullName: 'ДЕМИДЕНКО МАРИНА АЛЕКСАНДРОВНА',
    gettingBy: 'МОСКОВСКИЙ РУВД Г.МИНСКА',
    issueDate: '12.01.2023',
    passportInfo: 'МР3111155',
    personNumber: '3121296A077PB2'
  };
};
export var getCreditFormDataMock = function getCreditFormDataMock() {
  return {
    creditModelData: [{
      dataList: [{
        id: 1,
        value: 'Руководитель организации, владелец бизнеса'
      }, {
        id: 2,
        value: 'Заместитель руководителя организации'
      }, {
        id: 3,
        value: 'Прочие руководящие должности'
      }, {
        id: 4,
        value: 'Специалист'
      }, {
        id: 5,
        value: 'Рабочий'
      }, {
        id: 6,
        value: 'Вспомогательный персонал'
      }],
      id: 1,
      inputType: 'String',
      isRequired: true,
      label: 'Тип занимаемой должности',
      textId: 'jobCategory',
      type: 'SELECT'
    }, {
      dataList: null,
      id: 2,
      inputType: 'String',
      isRequired: true,
      label: 'Название организации',
      pattern: "^[A-Za-zА-Яа-яЁё 0-9 .,№()'\"\\-']+$",
      textId: 'workPlace',
      type: 'INPUT'
    }, {
      dataList: [{
        id: 1,
        value: 'государственное управление'
      }, {
        id: 2,
        value: 'правоохранительные органы'
      }, {
        id: 3,
        value: 'вооруженные силы'
      }, {
        id: 4,
        value: 'здравоохранение'
      }, {
        id: 5,
        value: 'образование'
      }, {
        id: 6,
        value: 'сельское хозяйство'
      }, {
        id: 7,
        value: 'коммунальное хозяйство'
      }, {
        id: 8,
        value: 'промышленность и производство'
      }, {
        id: 9,
        value: 'культура'
      }, {
        id: 10,
        value: 'строительство'
      }, {
        id: 11,
        value: 'банковское дело, финансы'
      }, {
        id: 12,
        value: 'торговля (в т.ч. фарм. препаратами через аптеки)'
      }, {
        id: 13,
        value: 'страхование'
      }, {
        id: 14,
        value: 'услуги'
      }, {
        id: 15,
        value: 'информационные технологии'
      }, {
        id: 16,
        value: 'развлечения'
      }, {
        id: 18,
        value: 'профессиональная, научная и техническая деятельность'
      }],
      id: 3,
      inputType: 'String',
      isRequired: true,
      label: 'Сфера деятельности',
      textId: 'scopeActivity',
      type: 'SELECT'
    }, {
      dataList: [{
        id: 0,
        value: 'до 10 чел.'
      }, {
        id: 1,
        value: 'от 11 до 50 чел.'
      }, {
        id: 2,
        value: 'от 51 до 100 чел.'
      }, {
        id: 3,
        value: 'от 101 до 500 чел.'
      }, {
        id: 4,
        value: 'свыше 500 чел.'
      }],
      id: 4,
      inputType: 'String',
      isRequired: true,
      label: 'Количество сотрудников в организации',
      textId: 'employeeCount',
      type: 'SELECT'
    }, {
      dataList: [{
        id: 1,
        value: 'от 0 до 3 мес.'
      }, {
        id: 2,
        value: 'от 3 до 12 мес.'
      }, {
        id: 3,
        value: 'от 12 до 24 мес.'
      }, {
        id: 4,
        value: 'от 24 до 36 мес.'
      }, {
        id: 5,
        value: 'от 36 до 60 мес.'
      }, {
        id: 6,
        value: '60 и более мес.'
      }],
      id: 5,
      inputType: 'String',
      isRequired: true,
      label: 'Стаж на последнем месте работы',
      textId: 'experienceCode',
      type: 'SELECT'
    }, {
      dataList: null,
      id: 6,
      inputType: 'Int',
      isRequired: true,
      label: 'Среднемесячный доход, BYN за последние 3 месяца',
      textId: 'averageMonthIncome',
      type: 'INPUT'
    }, {
      dataList: [{
        id: 1,
        value: 'Высшее'
      }, {
        id: 2,
        value: 'Среднее'
      }, {
        id: 3,
        value: 'Среднее специальное'
      }, {
        id: 5,
        value: 'Два и более вы'
      }],
      id: 7,
      inputType: 'String',
      isRequired: true,
      label: 'Образование',
      textId: 'education',
      type: 'SELECT'
    }, {
      dataList: [{
        id: 0,
        value: '0'
      }, {
        id: 1,
        value: '1'
      }, {
        id: 2,
        value: '2'
      }, {
        id: 3,
        value: '3'
      }, {
        id: 4,
        value: '4'
      }, {
        id: 5,
        value: '5'
      }, {
        id: 6,
        value: '6'
      }, {
        id: 7,
        value: '7'
      }, {
        id: 8,
        value: '8'
      }, {
        id: 9,
        value: '9'
      }, {
        id: 10,
        value: '10'
      }],
      id: 8,
      inputType: 'Int',
      isRequired: true,
      label: 'Количество детей в возрасте до 18 лет',
      textId: 'childrenCount',
      type: 'SELECT'
    }, {
      dataList: null,
      id: 9,
      inputType: null,
      isRequired: true,
      label: 'Подтверждаю, что оформляю кредит в личных целях',
      textId: 'confirmLoanForPersonalPurposes',
      type: 'CHECKBOX'
    }]
  };
};
export var getCreditFormDataWithValuesMock = function getCreditFormDataWithValuesMock() {
  return {
    creditModelData: [{
      dataList: [{
        id: 1,
        value: 'Руководитель организации, владелец бизнеса'
      }, {
        id: 2,
        value: 'Заместитель руководителя организации'
      }, {
        id: 3,
        value: 'Прочие руководящие должности'
      }, {
        id: 4,
        value: 'Специалист'
      }, {
        id: 5,
        value: 'Рабочий'
      }, {
        id: 6,
        value: 'Вспомогательный персонал'
      }],
      dfltValue: '3',
      id: 1,
      inputType: 'String',
      isRequired: true,
      label: 'Тип занимаемой должности',
      textId: 'jobCategory',
      type: 'SELECT'
    }, {
      dataList: null,
      dfltValue: 'Palitra',
      id: 2,
      inputType: 'String',
      isRequired: true,
      label: 'Название организации',
      textId: 'workPlace',
      type: 'INPUT'
    }, {
      dataList: [{
        id: 1,
        value: 'государственное управление'
      }, {
        id: 2,
        value: 'правоохранительные органы'
      }, {
        id: 3,
        value: 'вооруженные силы'
      }, {
        id: 4,
        value: 'здравоохранение'
      }, {
        id: 5,
        value: 'образование'
      }, {
        id: 6,
        value: 'сельское хозяйство'
      }, {
        id: 7,
        value: 'коммунальное хозяйство'
      }, {
        id: 8,
        value: 'промышленность и производство'
      }, {
        id: 9,
        value: 'культура'
      }, {
        id: 10,
        value: 'строительство'
      }, {
        id: 11,
        value: 'банковское дело, финансы'
      }, {
        id: 12,
        value: 'торговля (в т.ч. фарм. препаратами через аптеки)'
      }, {
        id: 13,
        value: 'страхование'
      }, {
        id: 14,
        value: 'услуги'
      }, {
        id: 15,
        value: 'информационные технологии'
      }, {
        id: 16,
        value: 'развлечения'
      }, {
        id: 18,
        value: 'профессиональная, научная и техническая деятельность'
      }],
      dfltValue: '12',
      id: 3,
      inputType: 'String',
      isRequired: true,
      label: 'Сфера деятельности',
      textId: 'scopeActivity',
      type: 'SELECT'
    }, {
      dataList: [{
        id: 0,
        value: 'до 10 чел.'
      }, {
        id: 1,
        value: 'от 11 до 50 чел.'
      }, {
        id: 2,
        value: 'от 51 до 100 чел.'
      }, {
        id: 3,
        value: 'от 101 до 500 чел.'
      }, {
        id: 4,
        value: 'свыше 500 чел.'
      }],
      dfltValue: '2',
      id: 4,
      inputType: 'String',
      isRequired: true,
      label: 'Количество сотрудников в организации',
      textId: 'employeeCount',
      type: 'SELECT'
    }, {
      dataList: [{
        id: 1,
        value: 'от 0 до 3 мес.'
      }, {
        id: 2,
        value: 'от 3 до 12 мес.'
      }, {
        id: 3,
        value: 'от 12 до 24 мес.'
      }, {
        id: 4,
        value: 'от 24 до 36 мес.'
      }, {
        id: 5,
        value: 'от 36 до 60 мес.'
      }, {
        id: 6,
        value: '60 и более мес.'
      }],
      dfltValue: '5',
      id: 5,
      inputType: 'String',
      isRequired: true,
      label: 'Стаж на последнем месте работы',
      textId: 'experienceCode',
      type: 'SELECT'
    }, {
      dataList: null,
      dfltValue: '5000',
      id: 6,
      inputType: 'Int',
      isRequired: true,
      label: 'Среднемесячный доход, BYN за последние 3 месяца',
      textId: 'averageMonthIncome',
      type: 'INPUT'
    }, {
      dataList: [{
        id: 1,
        value: 'Высшее'
      }, {
        id: 2,
        value: 'Среднее'
      }, {
        id: 3,
        value: 'Среднее специальное'
      }, {
        id: 5,
        value: 'Два и более вы'
      }],
      dfltValue: '1',
      id: 7,
      inputType: 'String',
      isRequired: true,
      label: 'Образование',
      textId: 'education',
      type: 'SELECT'
    }, {
      dataList: [{
        id: 0,
        value: '0'
      }, {
        id: 1,
        value: '1'
      }, {
        id: 2,
        value: '2'
      }, {
        id: 3,
        value: '3'
      }, {
        id: 4,
        value: '4'
      }, {
        id: 5,
        value: '5'
      }, {
        id: 6,
        value: '6'
      }, {
        id: 7,
        value: '7'
      }, {
        id: 8,
        value: '8'
      }, {
        id: 9,
        value: '9'
      }, {
        id: 10,
        value: '10'
      }],
      dfltValue: '2',
      id: 8,
      inputType: 'Int',
      isRequired: true,
      label: 'Количество детей в возрасте до 18 лет',
      textId: 'childrenCount',
      type: 'SELECT'
    }, {
      dataList: null,
      dfltValue: '0',
      id: 9,
      inputType: null,
      isRequired: true,
      label: 'Подтверждаю, что оформляю кредит в личных целях',
      textId: 'confirmLoanForPersonalPurposes',
      type: 'CHECKBOX'
    }]
  };
};
export var getCreditCardInfoMock = function getCreditCardInfoMock() {
  return {
    delivery: {
      apartmentNumber: '5',
      district: 'БАРАНОВИЧСКИЙ',
      houseNumber: '5',
      localityName: 'БАРАНОВИЧИ',
      localityType: 'г.',
      postCode: '220121',
      region: 'БРЕСТСКАЯ',
      soato: '626',
      streetName: 'Притыцкого',
      streetType: 'к/о'
    },
    infoCards: {
      instantCard: {
        info: ['Доступна к получению в офисе Банка в течение 30 дней с даты заключения кредитного договора', 'Выбор цвета карточки из имеющихся в Банке'],
        pictureURL: cover
      },
      personalizedCard: {
        cards: [{
          color: '#E24B31',
          pictureURL: orange,
          productCode: 'PrvDbAAA027ORNGOVERIB_TMC'
        }, {
          color: '#98E501',
          pictureURL: green,
          productCode: 'PrvDbAAA027GRNOVERIB_TMC'
        }, {
          color: '#F2DC09',
          pictureURL: yellow,
          productCode: 'PrvDbAAA027YELOVERIB_TMC'
        }, {
          color: '#D824AE',
          pictureURL: pink,
          productCode: 'PrvDbAAA027PINKOVERIB_TMC'
        }],
        info: ['Доставка 2-5 дней почтой на дом', 'Именная карточка', 'Возможность выбора цвета']
      }
    },
    infoFields: {
      cardholderNameLabel: 'Имя (латиницей)',
      cardholderSurnameLabel: 'Фамилия (латиницей)',
      codeWordLabel: 'Кодовое слово (кириллицей)',
      isCorrectDataLabel: 'Подтверждаю корректность введенных данных. <0>Подробнее</0>',
      isCorrectDataText: 'Подтверждаю, что введенные имя и фамилия латинскими буквами для нанесения на платежную карточку соответствуют написанию в документе, удостоверяющем мою личность. В случае некорректного написания замена карточки осуществляется в соответствии с Тарифами на выполнение операций и оказание услуг ОАО "Банк БелВЭБ"'
    },
    selectedCard: {
      color: '#98E501',
      productCode: 'PrvDbAAA027GRNOVERIB_TMC',
      type: 'personalized'
    }
  };
};
export var getCreditApplicationMock = function getCreditApplicationMock() {
  return {
    dossierId: 515
  };
};
export var getCreditApplicationFileMock = function getCreditApplicationFileMock() {
  return {
    file: file,
    fileName: 'Заявление'
  };
};
export var getCreditAgreementFileMock = function getCreditAgreementFileMock() {
  return {
    file: file,
    fileName: 'Договор'
  };
};
export var getCreditConfirmationTypeMock = function getCreditConfirmationTypeMock() {
  return {
    codeOperation: '',
    dataOperation: [{
      code: '2570',
      id: 1,
      opDetails: 'Код подтверждения: 123456'
    }, {
      code: '2551',
      id: 2,
      opDetails: 'Код подтверждения: 1111111'
    }],
    idOtp: null,
    idTransaction: 'dc2459af-5337-41e1-9992-6340ee6670a6',
    messageForClient: 'На номер 37529***1001 отправлен код для подтверждения.',
    otp: false,
    pin: true
  };
};