import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, Stack, styled } from '@mui/material';
var StyledFormWrapper = styled(Stack)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2)
  }, theme.breakpoints.up('desktop'), {
    paddingBottom: 0
  });

  return styles;
});
var StyledPhoneWrapper = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    '& input.MuiInputBase-input': {
      padding: theme.spacing(2, 0, 2, 0.5)
    },
    '& span.MuiButtonBase-root': {
      padding: theme.spacing(0, 1, 0, 0)
    }
  };
  return styles;
});
var StyledButtonWrapper = styled(Box)(function (_ref3) {
  var theme = _ref3.theme,
      $isExtraSpace = _ref3.$isExtraSpace;
  var styles = {
    marginTop: theme.spacing($isExtraSpace ? 8 : 5)
  };
  return styles;
});
export { StyledButtonWrapper, StyledFormWrapper, StyledPhoneWrapper };