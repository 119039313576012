import { styled as e, Box as t } from "@mui/material";
import "../../themes/box-shadows/boxShadows.js";
import "../../themes/dark.theme.js";
import { pxToRem as i } from "../../themes/default-theme/default-theme.js";
import "../../themes/gradients/gradients.js";
import "../../themes/light.theme.js";
var n = e(t)(function (e) {
  return {
    alignItems: "center",
    display: "flex",
    fontSize: 0,
    marginLeft: e.theme.spacing(2)
  };
}),
    a = e(t)(function (e) {
  var t,
      n,
      a = e.theme;
  return {
    "& > div > div": {
      height: e.$height || "calc(100vh - ".concat(i(134), ")")
    },
    "& li:not(:last-child)": {
      borderBottom: "".concat(i(1), " solid ").concat(null === (n = null == a ? void 0 : a.colors) || void 0 === n ? void 0 : n.lavenderSyrup)
    },
    "& ul": (t = {
      padding: 0
    }, t[a.breakpoints.up("tablet")] = {
      padding: a.spacing(0, 2)
    }, t),
    marginTop: a.spacing(2),
    overflow: "hidden"
  };
}),
    o = e(t)(function (e) {
  return {
    padding: e.theme.spacing(2, 3, 1)
  };
}),
    r = e(t)(function (e) {
  var t,
      i = e.theme;
  return (t = {
    padding: i.spacing(0, 2)
  })[i.breakpoints.up("lg")] = {
    padding: i.spacing(0, 3)
  }, t;
});
export { r as StyledBox, n as StyledSearchIcon, o as StyledSearchNotFoundContainer, a as StyledWrapper };