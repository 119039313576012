import { PaymentParameterDataType } from '@enums';
export var convertedPaymentFormDefaultValue = function convertedPaymentFormDefaultValue(defaultValue, type) {
  if (typeof defaultValue === 'undefined') {
    return '';
  }

  if ((type === PaymentParameterDataType.INTEGER || type === PaymentParameterDataType.FLOAT) && typeof defaultValue === 'string') {
    return Number(defaultValue.replace(',', '.'));
  }

  return defaultValue;
};