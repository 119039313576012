import * as yup from 'yup';
import { CardDeliveryMethod, MAX_NAME_FIELD_LENGTH } from '@enums';
import { i18n } from '@localization';

var getCardIssueFirstStepSchema = function getCardIssueFirstStepSchema() {
  return yup.object();
};

var getCardIssueSecondStepSchema = function getCardIssueSecondStepSchema() {
  return yup.object().shape({
    cardholderName: yup.string().required(i18n.t('forms.validation.required')),
    cardholderSurname: yup.string().required(i18n.t('forms.validation.required')).test({
      message: i18n.t('pages.cardIssue.nameValidation', {
        count: MAX_NAME_FIELD_LENGTH
      }),
      name: 'cardholderSurname',
      test: function test(value, ctx) {
        var _fields$cardholderNam;

        if (!value) {
          return true;
        }

        var fields = ctx.parent;

        if (value.length + ((_fields$cardholderNam = fields.cardholderName) === null || _fields$cardholderNam === void 0 ? void 0 : _fields$cardholderNam.length) > MAX_NAME_FIELD_LENGTH) {
          return false;
        }

        return true;
      }
    }),
    checkAgreement: yup.bool().equals([true]),
    checkContract: yup.bool().equals([true]),
    codeWord: yup.string().trim().min(4, i18n.t('forms.validation.minLengthCode')).required(i18n.t('forms.validation.required'))
  });
};

var getCardIssueThirdStepSchema = function getCardIssueThirdStepSchema() {
  return yup.object().shape({
    district: yup.object().test({
      message: i18n.t('forms.validation.required'),
      name: 'district',
      test: function test(value, ctx) {
        var fields = ctx.parent;
        var delivery = fields.delivery,
            regionCenter = fields.regionCenter;

        if (delivery === CardDeliveryMethod.POST && !regionCenter && !value.value) {
          return false;
        }

        return true;
      }
    }),
    houseNumber: yup.string().when('delivery', {
      is: CardDeliveryMethod.POST,
      then: yup.string().required(i18n.t('forms.validation.required'))
    }),
    locality: yup.object().test({
      message: i18n.t('forms.validation.required'),
      name: 'locality',
      test: function test(value, ctx) {
        var fields = ctx.parent;
        var delivery = fields.delivery,
            regionCenter = fields.regionCenter,
            districtCenter = fields.districtCenter;

        if (delivery === CardDeliveryMethod.POST && !regionCenter && !districtCenter && !value.value) {
          return false;
        }

        return true;
      }
    }),
    localityType: yup.string().when('delivery', {
      is: CardDeliveryMethod.POST,
      then: yup.string().required(i18n.t('forms.validation.required'))
    }),
    officeCode: yup.object().test({
      message: i18n.t('forms.validation.required'),
      name: 'officeCode',
      test: function test(value, ctx) {
        var fields = ctx.parent;

        if (fields.delivery === CardDeliveryMethod.OFFICE && !value.value) {
          return false;
        }

        return true;
      }
    }),
    postCode: yup.string().when('delivery', {
      is: CardDeliveryMethod.POST,
      then: yup.string().required(i18n.t('forms.validation.required')).length(6, i18n.t('forms.validation.postCode'))
    }),
    region: yup.object().test({
      message: i18n.t('forms.validation.required'),
      name: 'region',
      test: function test(value, ctx) {
        var fields = ctx.parent;

        if (fields.delivery === CardDeliveryMethod.POST && !value.value) {
          return false;
        }

        return true;
      }
    }),
    soato: yup.string().when('delivery', {
      is: CardDeliveryMethod.POST,
      then: yup.string().required(i18n.t('forms.validation.required'))
    }),
    streetName: yup.string().when('delivery', {
      is: CardDeliveryMethod.POST,
      then: yup.string().required(i18n.t('forms.validation.required'))
    }),
    streetType: yup.object().test({
      message: i18n.t('forms.validation.required'),
      name: 'streetType',
      test: function test(value, ctx) {
        var fields = ctx.parent;

        if (fields.delivery === CardDeliveryMethod.POST && !value.value) {
          return false;
        }

        return true;
      }
    })
  });
};

export { getCardIssueFirstStepSchema, getCardIssueSecondStepSchema, getCardIssueThirdStepSchema };