import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, styled } from '@mui/material';
var StyledWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingTop: theme.spacing(2)
  };
  return styles;
});
var StyledNotFoundWrapper = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;

  var styles = _defineProperty({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: "-".concat(theme.HEADER_HEIGHT)
  }, theme.breakpoints.up('desktop'), {
    marginTop: "-".concat(theme.HEADER_HEIGHT_DESKTOP)
  });

  return styles;
});
export { StyledNotFoundWrapper, StyledWrapper };