import { useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form'; // eslint-disable-next-line import/no-unresolved

import { useSwiperSlide } from 'swiper/react';
import { NewCardId } from '@enums';
import { ChangeFormEnum } from '../../payments-cards.types';

/**
 * Payment Cards Slide component
 *
 * @author Yury Kuntsou
 * @function PaymentsCardsSlide
 * @category components
 * @return {FC} Payment Cards Slide  Component.
 */
var PaymentsCardsSlide = function PaymentsCardsSlide(_ref) {
  var id = _ref.id,
      children = _ref.children,
      cardField = _ref.cardField,
      cardNewField = _ref.cardNewField,
      handleChange = _ref.handleChange;
  var swiperSlide = useSwiperSlide();
  var form = useForm();

  var _useFormState = useFormState(),
      values = _useFormState.values;

  var handleChangeSlide = function handleChangeSlide() {
    form.batch(function () {
      if (id === NewCardId.NEW) {
        form.change(cardField, undefined);
        form.change(cardNewField, true);
      } else {
        form.change(cardField, id);
        form.change(cardNewField, false);
      }
    });

    if (id === NewCardId.NEW && !values[cardNewField]) {
      void handleChange(form, ChangeFormEnum.SLIDE);
      return;
    }

    if (id !== values[cardField]) {
      void handleChange(form, ChangeFormEnum.SLIDE);
    }
  };

  useEffect(function () {
    if (swiperSlide.isActive) {
      handleChangeSlide();
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [cardField, cardNewField, id, swiperSlide.isActive]);
  return children;
};

export { PaymentsCardsSlide };