import * as yup from 'yup';
import { formatNumber } from '@helpers';
import { Currency, PaymentNextStep } from '@enums';
import { i18n } from '@localization';
export var getPaymentsPayFormSchema = function getPaymentsPayFormSchema(amount) {
  return yup.object().shape({
    amount: yup.number().test({
      message: i18n.t('forms.validation.enterAmount'),
      name: 'enterAmount',
      test: function test(value, ctx) {
        if (amount) {
          return !!value;
        }

        return true;
      }
    }).test({
      name: 'minPaymentAmount',
      test: function test(value, ctx) {
        if (!value) return true;

        if (amount && amount.minAmount) {
          if (Number(value) < amount.minAmount) {
            return ctx.createError({
              message: i18n.t('forms.validation.minPaymentAmount', {
                data: " ".concat(formatNumber(amount.minAmount, 2, true, ',', ' '), " ").concat(Currency.BYN)
              })
            });
          }
        }

        return true;
      }
    }).test({
      name: 'maxPaymentAmount',
      test: function test(value, ctx) {
        if (!value) return true;

        if (amount && amount.maxAmount) {
          if (Number(value) > amount.maxAmount) {
            return ctx.createError({
              message: i18n.t('forms.validation.maxPaymentAmount', {
                data: " ".concat(formatNumber(amount.maxAmount, 2, true, ',', ' '), " ").concat(Currency.BYN)
              })
            });
          }
        }

        return true;
      }
    }).test({
      message: i18n.t('forms.validation.notEnoughOnAccount'),
      name: 'notEnoughOnAccount',
      test: function test(value, ctx) {
        if (!value) return true;
        var allValues = ctx.parent;

        if (allValues.nextStep !== PaymentNextStep.TransactionStart) {
          return true;
        }

        var account = allValues === null || allValues === void 0 ? void 0 : allValues.account;
        if (!account) return true;
        return Number(value) <= account.accountAmount;
      }
    }).nullable()
  });
};