import { styled as e, Box as i } from "@mui/material";
var n = e(i)(function (e) {
  e.theme;
  return {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    height: "100%"
  };
}),
    t = e("iframe")(function (e) {
  e.theme;
  var i = e.$hidden;
  return {
    border: "none",
    flex: 1,
    height: i ? 0 : "100%",
    pointerEvents: i && "none",
    visibility: i && "hidden",
    width: "100%"
  };
});
export { t as StyledIframe, n as StyledIframeContainer };