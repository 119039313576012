import { Paper, styled } from '@mui/material';
import { pxToRem } from '@themes';
var StyledPaper = styled(Paper)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    borderRadius: pxToRem(12),
    margin: theme.spacing(2)
  };
  return styles;
});
export { StyledPaper };