import { useContext } from 'react';
import { InnerPageLayoutSwitcherTitleContext } from '@hocs';
export var usePageTitleSwitcher = function usePageTitleSwitcher() {
  var pageTitleSwitcher = useContext(InnerPageLayoutSwitcherTitleContext);

  if (!pageTitleSwitcher) {
    throw new Error('usePageTitleSwitcher must be used within an InnerPageLayoutSwitcherTitleContext Provider');
  }

  return pageTitleSwitcher;
};