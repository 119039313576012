import * as yup from 'yup';
import { i18n } from '@localization';
export var getWithdrawFormSchema = function getWithdrawFormSchema(avlAmount) {
  return yup.object().shape({
    amount: yup.number().required(i18n.t('forms.validation.enterAmount')).test({
      message: i18n.t('forms.validation.maxWithdrawAmountExceed'),
      name: 'max',
      test: function test(value) {
        if (!value) return true;
        return Number(value) <= Number(avlAmount);
      }
    }).nullable()
  });
};