import { __read as t, __assign as r } from "../../_virtual/_tslib.js";
import { jsx as o, Fragment as a, jsxs as i } from "react/jsx-runtime";
import { useNumericFormat as e } from "react-number-format";
import { Typography as n } from "../typography/typography.component.js";

var c = function (c) {
  var m = c.amount,
      l = c.variantInteger,
      d = void 0 === l ? "title2" : l,
      p = c.variantDecimal,
      v = void 0 === p ? "body2" : p,
      f = c.fontWeightInteger,
      h = void 0 === f ? 400 : f,
      g = c.fontWeightDecimal,
      u = void 0 === g ? 400 : g,
      s = c.color,
      y = void 0 === s ? "nightWizard" : s,
      S = c.currency,
      W = c.decimalScale,
      b = e({
    decimalScale: void 0 === W ? 2 : W,
    decimalSeparator: ",",
    fixedDecimalScale: !0,
    thousandSeparator: " "
  }).format;
  if (!b) return o(a, {});
  var j = t(b("".concat(m)).split(","), 2),
      x = j[0],
      D = j[1];
  return i(n, r({
    color: y,
    fontWeight: h,
    variant: d
  }, {
    children: [x, i(n, r({
      component: "span",
      fontWeight: u,
      variant: v
    }, {
      children: [",".concat(D), S ? " ".concat(S) : ""]
    }))]
  }));
};

export { c as CurrencyTypography };