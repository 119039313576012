import { Box, styled } from '@mui/material';
import { pxToRem } from '@themes';
var StyledRateInfo = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    backgroundColor: theme.colors.lavenderSyrup,
    borderRadius: pxToRem(12),
    paddingBottom: theme.spacing(1.75),
    paddingTop: theme.spacing(1.75),
    textAlign: 'center'
  };
  return styles;
});
export { StyledRateInfo };