import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Button, pxToRem } from '@pwa/ui';
import { Box, Stack, styled } from '@mui/material';
var StyledContentWrapper = styled(Stack)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    backgroundColor: theme.colors.whiteSolid,
    borderTopLeftRadius: pxToRem(16),
    borderTopRightRadius: pxToRem(16),
    flexGrow: 1,
    justifyContent: 'space-between',
    marginTop: theme.spacing(1)
  };
  return styles;
});
var StyledPaymentsWrapper = styled(Box)(function (_ref2) {
  var $isWithButton = _ref2.$isWithButton,
      theme = _ref2.theme;
  var height = theme.convertToDVH(100);

  var styles = _defineProperty({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    // appBar 56, button 116 and margins/paddings, text
    maxHeight: "calc(".concat(height, " - ").concat(pxToRem($isWithButton ? 232 - 16 : 116 - 16), " - ").concat(theme.HEADER_HEIGHT, ")"),
    overflow: 'hidden'
  }, theme.breakpoints.up('desktop'), {
    maxHeight: "calc(".concat(height, " - ").concat(pxToRem($isWithButton ? 232 - 16 : 116 - 16), " - ").concat(theme.HEADER_HEIGHT_DESKTOP, " - ").concat(pxToRem(80), ")")
  });

  return styles;
});
var StyledButton = styled(Button)(function () {
  var styles = {
    '& span.MuiFormControlLabel-label': {
      width: "calc(100% - ".concat(pxToRem(32), ")")
    },
    justifyContent: 'flex-start'
  };
  return styles;
});
var StyledButtonWrapper = styled(Box)(function (_ref3) {
  var theme = _ref3.theme,
      isScroll = _ref3.isScroll;
  var styles = {
    padding: theme.spacing(2.5, 0, 5)
  };
  return styles;
});
var StyledContainer = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'hidden'
  };
  return styles;
});
var StyledImageWrapper = styled(Box)(function () {
  var styles = {
    '& img': {
      height: '100%',
      objectFit: 'cover',
      width: '100%'
    },
    alignItems: 'center',
    borderRadius: pxToRem(8),
    display: 'flex',
    height: pxToRem(32),
    overflow: 'hidden',
    width: pxToRem(32)
  };
  return styles;
});
export { StyledButton, StyledButtonWrapper, StyledContainer, StyledContentWrapper, StyledImageWrapper, StyledPaymentsWrapper };