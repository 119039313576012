import { __rest as e, __read as o, __assign as t } from "../../_virtual/_tslib.js";
import { jsxs as r, Fragment as i, jsx as n } from "react/jsx-runtime";
import { useState as m } from "react";
import { Snackbar as s, Stack as a, Box as c } from "@mui/material";
import { copyToClipboard as p } from "../../helpers/copy-to-clipboard/copy-to-clipboard.helper.js";
import "../../enums/entities/entities.enum.js";
import "../../enums/keyboard/keyboard.enum.js";
import "../../enums/theme/theme.enum.js";
import "../../node_modules/@babel/runtime/helpers/typeof.js";
import l from "../../packages/pwa-ui/assets/images/buttons/copy.image.svg.js";
import { Icon as u } from "../icon/icon.component.js";
import { IconButton as d } from "../icon-button/icon-button.component.js";

var h = function (h) {
  var f = h.message,
      g = h.value,
      b = h.delay,
      j = void 0 === b ? 3e3 : b,
      v = e(h, ["message", "value", "delay"]),
      y = o(m(!1), 2),
      w = y[0],
      k = y[1];
  return r(i, {
    children: [n(d, t({}, v, {
      onClick: function () {
        p(g), k(!0);
      }
    }, {
      children: n(u, {
        height: 24,
        src: l,
        width: 24
      })
    })), n(s, {
      anchorOrigin: {
        horizontal: "center",
        vertical: "bottom"
      },
      autoHideDuration: j,
      message: r(a, t({
        alignItems: "center",
        direction: "row",
        flexWrap: "nowrap",
        spacing: 1.75
      }, {
        children: [n(u, {
          height: 24,
          src: l,
          width: 24
        }), n(c, {
          children: f
        })]
      })),
      open: w,
      onClose: function () {
        k(!1);
      }
    }, g)]
  });
};

export { h as CopyButton };