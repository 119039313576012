import { pxToRem } from '@pwa/ui';
import { Stack, styled } from '@mui/material';
var StyledStack = styled(Stack)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    backgroundColor: theme === null || theme === void 0 ? void 0 : theme.palette.white.main,
    borderRadius: pxToRem(16),
    padding: theme.spacing(2, 2, 0)
  };
  return styles;
});
export { StyledStack };