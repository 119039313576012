import { __assign as t } from "../../../../../_virtual/_tslib.js";
import { Swiper as i } from "swiper/react";
import { styled as e, Box as r } from "@mui/material";
import "swiper/css";
var o = e(i)(function (i) {
  var e,
      r = i.theme;
  return {
    "& .swiper-slide": t(t({}, r.typography.body1), (e = {
      "&.swiper-slide-active": {
        fontSize: r.typography.title3.fontSize,
        fontWeight: 700
      }
    }, e["&:hover"] = {
      cursor: "pointer"
    }, e.alignItems = "center", e.color = r.palette.nightWizard.main, e.display = "flex", e.fontWeight = 500, e.textTransform = "capitalize", e.transform = "translateZ(0)", e.transition = r.transitions.create(["font-size", "font-weight"], {
      duration: 150
    }), e)),
    height: "100%",
    width: "100%"
  };
}),
    a = e(r)(function (t) {
  var i = t.theme;
  return {
    display: "block",
    maxWidth: "100%",
    overflow: "hidden",
    paddingLeft: i.spacing(1.5),
    paddingRight: i.spacing(1.5),
    textAlign: "center",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%"
  };
});
export { a as StyledSwiperSlideContent, o as StyledSwiperSlider };