import { styled as o, Box as e, Stack as t, FormControl as r, InputLabel as n, FormHelperText as i, Select as a, alpha as l, MenuItem as s, SwipeableDrawer as c, Dialog as d } from "@mui/material";
import "react";
import { getHexColor as p } from "../../../helpers/colors/colors.helper.js";
import "../../../enums/entities/entities.enum.js";
import "../../../enums/keyboard/keyboard.enum.js";
import "../../../enums/theme/theme.enum.js";
import "../../../node_modules/@babel/runtime/helpers/typeof.js";
import { Button as u } from "../../button/button.component.js";
import { Icon as m } from "../../icon/icon.component.js";
import { Typography as h } from "../../typography/typography.component.js";
import "../../../themes/box-shadows/boxShadows.js";
import "../../../themes/dark.theme.js";
import { pxToRem as g } from "../../../themes/default-theme/default-theme.js";
import "../../../themes/gradients/gradients.js";
import "../../../themes/light.theme.js";

var f = o(e)(function (o) {
  var e = o.theme;
  return {
    backgroundColor: e.colors.carteBlanche,
    borderRadius: g(2),
    height: g(3),
    margin: e.spacing(1, "auto", 0, "auto"),
    opacity: "50%",
    width: g(60)
  };
}),
    b = o(e)(function (o) {
  var e = o.theme,
      t = o.isMobile;
  return o.$searchListField, {
    display: "flex",
    flexDirection: "column",
    maxHeight: e.convertToDVH(100),
    paddingBottom: t ? e.spacing(6) : 0
  };
}),
    v = o(t)(function (o) {
  return {
    padding: o.theme.spacing(2),
    width: "100%"
  };
}),
    x = o(e)(function (o) {
  var e = o.theme;
  return {
    padding: o.isMobile ? e.spacing(3, 2, 0) : 0,
    textAlign: "center"
  };
}),
    y = o(r)(function (o) {
  return {
    display: o.fullWidth && "flex",
    maxWidth: "100%"
  };
}),
    k = o(n)(function (o) {
  var e = o.theme,
      t = o.$color,
      r = o.$wrap,
      n = {
    color: e.colors.abandonedSpaceship,
    display: "block",
    fontSize: g(16),
    fontWeight: 400,
    lineHeight: g(18),
    marginBottom: e.spacing(.5),
    maxWidth: "100%",
    paddingLeft: e.spacing(1),
    position: "relative",
    transform: "unset",
    transformOrigin: "unset",
    whiteSpace: r ? "unset" : "nowrap"
  };
  return "bayFog" === t && (n.color = e.colors.white), n;
}),
    j = o(e)(function (o) {
  return o.theme, {
    fontSize: g(12),
    left: 0,
    lineHeight: g(14),
    marginTop: g(4),
    position: "absolute",
    right: 0,
    top: "100%"
  };
}),
    w = o(i)(function (o) {
  var e = o.theme;
  return {
    color: e.palette.error.main,
    display: "block",
    margin: "unset",
    maxWidth: "100%",
    padding: e.spacing(0, 1)
  };
}),
    z = o(a)(function (o) {
  var e = o.theme,
      t = o.color,
      r = p(e.palette, t),
      n = {
    "& .MuiInputBase-input": {
      alignItems: "center",
      boxSizing: "border-box",
      display: "flex",
      fontSize: g(16),
      fontWeight: 500,
      height: g(56),
      lineHeight: g(18),
      padding: e.spacing(2, 2, 2, 1.5),
      position: "relative"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    },
    "& .MuiSvgIcon-root svg": {
      stroke: e.colors.abandonedSpaceship
    },
    "&.Mui-error": {
      borderColor: e.palette.error.main
    },
    "&.Mui-focused": {
      borderColor: e.palette.primary.main
    },
    backgroundColor: r,
    border: r && "".concat(g(1), " solid ").concat(r),
    borderRadius: g(12),
    boxSizing: "border-box",
    color: e.colors.nightWizard,
    fontSize: g(14),
    height: g(56),
    lineHeight: g(24),
    overflow: "hidden",
    padding: 0,
    position: "relative",
    transition: e.transitions.create(["border-color"])
  };

  switch (t) {
    case "secondary":
      n.backgroundColor = e.colors.ghostWhite, n.borderColor = e.colors.ghostWhite;
      break;

    case "bayFog":
      n.backgroundColor = l(e.colors.ghostWhite, .05), n.borderColor = l(e.colors.white, .1), n.color = e.colors.white, n["& .MuiSvgIcon-root svg"] = {
        stroke: e.colors.estateViolet
      };
  }

  return n;
}),
    C = o(m)(function (o) {
  var e = o.theme,
      t = o.height;
  return o.color, {
    display: "flex",
    pointerEvents: "none",
    position: "absolute",
    right: g(16),
    top: "calc(50% - ".concat(g(t / 2), ")"),
    transition: e.transitions.create(["transform"]),
    zIndex: 1
  };
}),
    S = function (o, e) {
  return {
    borderRadius: g(12),
    display: o ? "none" : "block",
    transform: "translateY(4px)"
  };
},
    W = function (o) {
  return {
    li: {
      "&.Mui-selected": {
        backgroundColor: null == o ? void 0 : o.colors.alpineGoat
      },
      "&.Mui-selected:hover": {
        backgroundColor: null == o ? void 0 : o.colors.alpineGoat
      },
      ":last-child": {
        marginBottom: null == o ? void 0 : o.spacing(0)
      },
      borderRadius: g(12),
      marginBottom: null == o ? void 0 : o.spacing(.5),
      padding: null == o ? void 0 : o.spacing(1.25, 1.5)
    },
    padding: null == o ? void 0 : o.spacing(2, 1.5)
  };
},
    I = o(u)(function (o) {
  var e = o.theme,
      t = o.$active;
  return {
    ":hover": {
      backgroundColor: e.colors.alpineGoat
    },
    backgroundColor: t ? e.colors.alpineGoat : void 0,
    display: "inline-flex",
    justifyContent: "flex-start",
    padding: e.spacing(2),
    textAlign: "left"
  };
}),
    M = o(s)(function () {
  return {
    alignItems: "center",
    display: "flex"
  };
}),
    B = o(m)(function (o) {
  return {
    marginRight: o.theme.spacing(1)
  };
}),
    H = o(i)(function (o) {
  var e = o.theme,
      t = o.$color,
      r = {
    color: e.colors.abandonedSpaceship,
    display: "block",
    margin: "unset",
    maxWidth: "100%",
    padding: e.spacing(0, 1)
  };
  return "bayFog" === t && (r.color = e.colors.white), r;
}),
    $ = o(h)(function (o) {
  var e = o.theme,
      t = o.$color,
      r = {
    color: l(e.colors.nightWizard, .4)
  };
  return "bayFog" === t && (r.color = l(e.colors.white, .4)), r;
}),
    F = o(c)(function (o) {
  return {
    zIndex: o.theme.zIndex.modal + 1
  };
}),
    R = o(d)(function (o) {
  return {
    zIndex: o.theme.zIndex.modal + 1
  };
});

export { b as DrawerContainer, v as ItemsContainer, f as Puller, W as SelectMenuStyles, S as SelectPaperStyles, j as StyleFromHelperWrapper, I as StyledButton, y as StyledFormControl, w as StyledFormHelperErrorText, H as StyledFormHelperText, B as StyledIcon, k as StyledInputLabel, M as StyledMenuItem, $ as StyledPlaceholder, z as StyledSelectBase, C as StyledSelectIcon, R as StyledSwipeableDesktopDrawer, F as StyledSwipeableDrawer, x as StyledTitle };