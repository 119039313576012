import { styled as e, Box as t, Drawer as r, Dialog as n } from "@mui/material";
import "react";
import "../../enums/entities/entities.enum.js";
import "../../enums/keyboard/keyboard.enum.js";
import "../../enums/theme/theme.enum.js";
import "../../node_modules/@babel/runtime/helpers/typeof.js";
import { convertToDVH as i } from "../../helpers/theme/theme.helper.js";
import "../../themes/box-shadows/boxShadows.js";
import "../../themes/dark.theme.js";
import { pxToRem as o } from "../../themes/default-theme/default-theme.js";
import "../../themes/gradients/gradients.js";
import "../../themes/light.theme.js";
var m = e(t)(function (e) {
  var t = e.theme;
  return {
    backgroundColor: t.colors.carteBlanche,
    borderRadius: o(2),
    height: o(3),
    margin: t.spacing(1, "auto", 0, "auto"),
    opacity: "50%",
    width: o(60)
  };
}),
    a = e(t)(function (e) {
  return e.theme, {
    display: "flex",
    flexDirection: "column"
  };
}),
    s = e(r)(function (e) {
  return {
    zIndex: e.theme.zIndex.modal + 1
  };
}),
    h = e(n)(function (e) {
  return {
    zIndex: e.theme.zIndex.modal + 1
  };
}),
    u = e(t)(function (e) {
  var t = e.theme;
  return {
    padding: e.isMobile ? t.spacing(3, 2, 3) : 0,
    textAlign: "center"
  };
}),
    d = e(t)(function (e) {
  return {
    alignItems: "center",
    display: "flex",
    fontSize: 0,
    marginLeft: e.theme.spacing(2)
  };
}),
    p = e(t)(function (e) {
  return e.theme, {
    height: i(65)
  };
}),
    c = e(t)(function (e) {
  var t = e.theme;
  return {
    borderTopLeftRadius: t.spacing(2),
    borderTopRightRadius: t.spacing(2),
    marginTop: t.spacing(2),
    overflow: "hidden"
  };
}),
    l = e(t)(function (e) {
  return {
    padding: e.theme.spacing(2, 3, 1)
  };
});
export { a as DrawerContainer, m as Puller, h as StyledDesktopDrawer, s as StyledDrawer, c as StyledDrawerListWrapper, p as StyledFilteredListContainer, d as StyledSearchIcon, l as StyledSearchNotFoundContainer, u as StyledTitle };