export var Grids;

(function (Grids) {
  Grids["AccountsList"] = "AccountsList";
  Grids["BankAtms"] = "BankAtms";
  Grids["BankDepartments"] = "BankDepartments";
  Grids["BankOfficeList"] = "BankOfficeList";
  Grids["CreditsList"] = "CreditsList";
  Grids["CurrencyNBRBList"] = "CurrencyNBRBList";
  Grids["CurrencyRateList"] = "CurrencyRateList";
  Grids["CurrencySuperRateList"] = "CurrencySuperRateList";
  Grids["DepositCalculator"] = "DepositCalculator";
  Grids["DepositsList"] = "DepositsList";
  Grids["HistoryList"] = "HistoryList";
  Grids["NotificationsList"] = "NotificationsList";
  Grids["PackageCategories"] = "PackageCategories";
  Grids["PaymentAccountsList"] = "PaymentAccountsList";
  Grids["PaymentPayInitData"] = "PaymentPayInitData";
  Grids["RegionsList"] = "RegionsList";
  Grids["Stories"] = "Stories";
  Grids["TransferAccountList"] = "TransferAccountList";
  Grids["WidgetsList"] = "WidgetsList";
})(Grids || (Grids = {}));