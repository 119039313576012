import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, styled } from '@mui/material';
var StyledReceiverBox = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    backgroundColor: theme.palette.lavenderSyrup.main,
    borderRadius: theme.typography.pxToRem(12),
    display: 'flex',
    padding: theme.spacing(1.75)
  };
  return styles;
});
var StyledCheckboxWrapper = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    '& label': {
      margin: 0
    },
    '& span.MuiButtonBase-root': {
      marginRight: theme.spacing(1.5),
      padding: 0
    }
  };
  return styles;
});
var StyledWrapper = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;

  var styles = _defineProperty({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1'
  }, theme.breakpoints.up('desktop'), {
    display: 'block'
  });

  return styles;
});
export { StyledCheckboxWrapper, StyledReceiverBox, StyledWrapper };