import { Button } from '@pwa/ui';
import { styled } from '@mui/material';
var StyledButton = styled(Button)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    height: 'unset',
    padding: theme.spacing(2.5, 2)
  };
  return styles;
});
export { StyledButton };