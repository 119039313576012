import { history } from '@history';
import { AuthService, http } from '@services';
import { getErrorMessage } from '@helpers';
import { ApiResponseStatusCode, Routes } from '@enums';

/**
 * Error interceptor, catch all API errors
 *
 * @author Mikhail Siutsou
 * @function ErrorInterceptor
 * @category Interceptors
 * @param {AxiosError<ApiError>} error api error.
 * @return {Promise<ApiError>} api error.
 */
export var ErrorInterceptor = function ErrorInterceptor(error) {
  var _error$config;

  var disableInterception = (_error$config = error.config) === null || _error$config === void 0 ? void 0 : _error$config.disableInterception;
  var errorObject = getErrorMessage(error);
  /**
   * Redirect to error page handler.
   *
   * @author Mikhail Siutsou
   * @return {void}
   */

  var redirectToErrorPage = function redirectToErrorPage() {
    if (disableInterception) return;
    history.push(Routes.Error, errorObject);
  };
  /**
   * Redirect to error page handler.
   *
   * @author Mikhail Siutsou
   * @return {void}
   */
  // const redirectToLoginPage = (): void => {
  //   history.replace(Routes.Login);
  // };

  /**
   * error handler.
   *
   * @author Yury Kuntsou
   * @return {void}
   */
  // const handleErrorInception = (): void => {
  //   if (disableInterception) return;
  // };


  if (error.response) {
    // Request made and server responded
    switch (error.response.status) {
      case ApiResponseStatusCode.UNAUTHORIZED:
        {
          AuthService.logoutUser(); // redirectToLoginPage();

          break;
        }

      case ApiResponseStatusCode.INTERNAL_SERVER_ERROR:
        {
          redirectToErrorPage();
          break;
        }

      default:
        {
          redirectToErrorPage();
        }
    }
  } else if (error.request) {
    // The request was made but no response was received
    redirectToErrorPage();
  } else {
    // Something happened in setting up the request that triggered an Error
    redirectToErrorPage();
  }

  return Promise.reject(errorObject);
};
/**
 * Intercepts AxiosError object with ApiError data.
 *
 * @author Yury Kuntsou
 * @function StackErrorInterception
 * @category Interceptors
 * @param {AxiosError<ApiError>} error - The AxiosError object with ApiError data.
 * @returns {void}
 */

export var StackErrorInterception = function StackErrorInterception(error) {
  var _error$config2, _error$config3;

  var fullUrl = error !== null && error !== void 0 && (_error$config2 = error.config) !== null && _error$config2 !== void 0 && _error$config2.url ? http.getUri(error === null || error === void 0 ? void 0 : error.config) : 'error';

  if (error !== null && error !== void 0 && (_error$config3 = error.config) !== null && _error$config3 !== void 0 && _error$config3.stackRequest) {
    if (error !== null && error !== void 0 && error.config.stackRequest.has(fullUrl)) {
      error === null || error === void 0 ? void 0 : error.config.stackRequest["delete"](fullUrl);
    }
  }
};