import { __awaiter as t, __generator as r } from "../../_virtual/_tslib.js";

function n(n) {
  return t(this, void 0, void 0, function () {
    var t, e, i;
    return r(this, function (r) {
      switch (r.label) {
        case 0:
          return t = new TextEncoder().encode(n.toString()), [4, crypto.subtle.digest("SHA-256", t)];

        case 1:
          return e = r.sent(), i = Array.from(new Uint8Array(e)), [2, i.map(function (t) {
            return t.toString(16).padStart(2, "0");
          }).join("")];
      }
    });
  });
}

export { n as sha256 };