import { __rest as e, __assign as t } from "../../_virtual/_tslib.js";
import { jsxs as r, jsx as o, Fragment as i } from "react/jsx-runtime";
import { useNumericFormat as a } from "react-number-format";
import { Stack as m } from "@mui/material";
import { RemoteImage as s } from "../remote-image/remote-image.view.js";
import { Typography as l } from "../typography/typography.component.js";
import "../../themes/box-shadows/boxShadows.js";
import { colors as n } from "../../themes/colors/colors.js";
import "../../themes/dark.theme.js";
import "../../themes/default-theme/default-theme.js";
import "../../themes/gradients/gradients.js";
import "../../themes/light.theme.js";
import { StyledRateContainer as c, StyledBox as h, StyledDivider as d } from "./rate-widget.styles.js";

var p = function (p) {
  var g = p.buyRate,
      f = p.sellRate,
      u = p.imageURL,
      j = e(p, ["buyRate", "sellRate", "imageURL"]),
      y = a({
    decimalScale: 3,
    decimalSeparator: ",",
    fixedDecimalScale: !0,
    thousandSeparator: " "
  }).format;
  return g || f ? r(c, t({}, j, {
    alignItems: "center",
    direction: "row",
    display: "flex",
    gap: 1.25
  }, {
    children: [r(m, t({
      alignItems: "center",
      direction: "row",
      flexWrap: "nowrap",
      spacing: 1
    }, {
      children: [o(h, {
        children: o(s, {
          borderRadius: "50%",
          height: 16,
          src: u,
          width: 16
        })
      }), g && o(l, t({
        color: "nightWizard",
        fontWeight: 500,
        variant: "body1"
      }, {
        children: y("".concat(g))
      }))]
    })), f && r(i, {
      children: [o(d, {
        color: n.estateViolet,
        orientation: "vertical"
      }), o(l, t({
        color: "nightWizard",
        fontWeight: 500,
        variant: "body1"
      }, {
        children: y("".concat(f))
      }))]
    })]
  })) : null;
};

export { p as RateWidget };