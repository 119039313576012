import { __rest as r, __read as e, __assign as t } from "../../_virtual/_tslib.js";
import { jsxs as n, jsx as o } from "react/jsx-runtime";
import { useRef as i, useState as a, useEffect as m, useCallback as u } from "react";
import { Skeleton as c } from "@mui/material";
import { InlineError as l } from "../inline-error/inline-error.component.js";
import { StyledIframeContainer as s, StyledIframe as v } from "./Iframe.styles.js";

var f = function (f) {
  var d = f.url;
  f.enableResize;
  var h = f.errorText,
      p = void 0 === h ? "Error" : h,
      L = f.height,
      E = f.customLoader,
      g = r(f, ["url", "enableResize", "errorText", "height", "customLoader"]),
      j = i(null),
      x = e(a(!0), 2),
      b = x[0],
      z = x[1],
      R = e(a(!1), 2),
      T = R[0],
      _ = R[1];
  m(function () {
    var r = j.current;

    if (r) {
      var e = function e() {
        z(!1), _(!1), r.removeEventListener("load", e);
      },
          t = function e() {
        z(!1), _(!0), r.removeEventListener("error", e);
      };

      return r.addEventListener("load", e), r.addEventListener("error", t), function () {
        r.removeEventListener("load", e), r.removeEventListener("error", t);
      };
    }
  }, []);
  var k = u(function () {
    j.current && (z(!0), _(!1), j.current.src = d);
  }, [d]);
  return n(s, t({
    height: L
  }, {
    children: [b && (E || o(c, {
      height: 104,
      variant: "rectangular"
    })), T && o(l, {
      title: p,
      onClick: k
    }), o(v, t({}, g, {
      ref: j,
      $hidden: b || T,
      src: d
    }))]
  }));
};

export { f as Iframe };