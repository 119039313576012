import { __rest as e, __read as i, __assign as r } from "../../../_virtual/_tslib.js";
import { jsxs as t, jsx as n, Fragment as o } from "react/jsx-runtime";
import { useState as l, useRef as d, useEffect as a, isValidElement as c } from "react";
import { Box as s, Grid as h } from "@mui/material";
import { Icon as p } from "../../icon/icon.component.js";
import { IconButton as m } from "../../icon-button/icon-button.component.js";
import { Typography as u } from "../../typography/typography.component.js";
import "../../../themes/box-shadows/boxShadows.js";
import "../../../themes/dark.theme.js";
import { pxToRem as f } from "../../../themes/default-theme/default-theme.js";
import "../../../themes/gradients/gradients.js";
import "../../../themes/light.theme.js";
import { StyledButton as v, StyledIcon as g, StyledFormControl as b, StyledInputLabel as w, StyledSelectBase as x, SelectMenuStyles as y, SelectPaperStyles as W, StyledPlaceholder as j, StyledMenuItem as S, StyleFromHelperWrapper as z, StyledFormHelperErrorText as C, StyledFormHelperText as M, DrawerContainer as P, Puller as k, StyledTitle as I, ItemsContainer as L, StyledSwipeableDrawer as T, StyledSwipeableDesktopDrawer as $ } from "./select-field.styles.js";
import O from "../../../packages/pwa-ui/assets/images/icons/close.image.svg.js";
import { SelectIcon as F } from "./select-field.helpers.js";

function R(R) {
  var E = R.name,
      N = R.items,
      _ = R.error,
      B = R.fullWidth,
      V = R.label,
      q = R.drawerTitle,
      A = R.color,
      D = void 0 === A ? "white" : A,
      G = R.theme,
      H = R.bottomLabel,
      J = R.placeholder;
  R.deviceSize;
  var K = R.open,
      Q = void 0 !== K && K;
  R.active;
  var U = R.value;
  R.nullable;

  var X = R.isMobile,
      Y = R.isLabelWrapping,
      Z = void 0 !== Y && Y,
      ee = e(R, ["name", "items", "error", "fullWidth", "label", "drawerTitle", "color", "theme", "bottomLabel", "placeholder", "deviceSize", "open", "active", "value", "nullable", "isMobile", "isLabelWrapping"]),
      ie = i(l(Q), 2),
      re = ie[0],
      te = ie[1],
      ne = d(null),
      oe = d(re),
      le = !!_,
      de = E && "".concat(E, "-select-field"),
      ae = X ? T : $,
      ce = {
    errorId: de && "".concat(de, "-error-text"),
    htmlFor: de,
    id: de
  },
      se = function () {
    te(function (e) {
      return !e;
    });
  },
      he = function (e) {
    e && ne.current && ne.current.contains(e.target) || te(!1);
  },
      pe = function (e) {
    return function (i) {
      i.target = {
        name: E,
        value: e
      }, ee && ee.onChange && ee.onChange(i), he({});
    };
  };

  a(function () {
    var e;
    oe.current && re && (null === (e = ne.current) || void 0 === e || e.focus()), oe.current = re;
  }, [re]);
  var me = null == N ? void 0 : N.map(function (e) {
    var i = e.text,
        l = e.value,
        d = e.icon,
        a = e.hidden,
        s = e.disabled,
        h = e.renderText,
        p = e.color,
        m = void 0 === p ? "nightWizard" : p,
        u = e.size,
        f = void 0 === u ? "large" : u,
        b = "string" == typeof d,
        w = c(d);
    return t(v, r({
      $active: String(U) === String(l),
      color: m || D,
      disableElevation: !0,
      disabled: s,
      fullWidth: !0,
      hidden: a,
      size: f,
      onClick: pe(l)
    }, {
      children: [d && n(o, {
        children: w ? d : n(g, {
          height: b ? 24 : d.height,
          src: b ? d : d.src,
          width: b ? 24 : d.width
        })
      }), h ? h({
        color: m,
        disabled: s,
        hidden: a,
        icon: d,
        text: i,
        value: l
      }) : i]
    }), String(null != l ? l : i));
  });
  return t(o, {
    children: [t(b, r({
      error: le,
      fullWidth: B
    }, {
      children: [V && n(w, r({
        $color: D,
        $wrap: Z,
        htmlFor: ce.htmlFor,
        shrink: !0
      }, {
        children: V
      })), n(s, {
        children: n(x, r({
          IconComponent: F,
          MenuProps: {
            MenuListProps: {
              sx: y(G)
            },
            PaperProps: {
              style: W(!0)
            },
            sx: {
              display: "none"
            }
          },
          "aria-describedby": de,
          color: D,
          displayEmpty: !0,
          fullWidth: B,
          id: ce.id,
          name: E,
          open: re,
          renderValue: function (e) {
            if (!e) return n(j, r({
              $color: D
            }, {
              children: J
            }));
            var i = null == N ? void 0 : N.find(function (i) {
              return String(i.value) === String(e);
            });
            if (!i) return "";
            var l = i.icon;

            if (i.valueWithIcon && l) {
              var d = "string" == typeof l;
              return t(s, r({
                alignItems: "center",
                display: "flex",
                flexWrap: "nowrap"
              }, {
                children: [l && n(o, {
                  children: c(l) ? l : n(g, {
                    className: "icon-value items-icon",
                    height: d ? 24 : l.height,
                    src: d ? l : l.src,
                    width: d ? 24 : l.width
                  })
                }), n(u, r({
                  color: "nightWizard",
                  fontWeight: 500,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  variant: "body1"
                }, {
                  children: i.text
                }))]
              }));
            }

            return n(u, r({
              color: "nightWizard",
              fontWeight: 500,
              overflow: "hidden",
              textOverflow: "ellipsis",
              variant: "body1"
            }, {
              children: i.text
            }));
          },
          value: String(U),
          onClose: he,
          onOpen: se
        }, ee, {
          children: null == N ? void 0 : N.map(function (e) {
            var i = e.icon,
                l = e.text,
                d = e.disabled,
                a = e.value,
                s = e.hidden,
                h = e.renderText,
                p = e.color,
                m = "string" == typeof i,
                f = c(i);
            return t(S, r({
              disabled: d,
              hidden: s,
              value: String(a)
            }, {
              children: [i && n(o, {
                children: f ? i : n(g, {
                  className: "items-icon",
                  height: m ? 24 : i.height,
                  src: m ? i : i.src,
                  width: m ? 24 : i.width
                })
              }), h ? h({
                color: p,
                disabled: d,
                hidden: s,
                icon: i,
                text: l,
                value: a
              }) : n(u, r({
                color: p,
                variant: "body1",
                width: "100%"
              }, {
                children: l
              }))]
            }), String(a));
          })
        }))
      }), le && "string" == typeof _ && n(z, {
        children: n(C, r({
          id: ce.errorId
        }, {
          children: _
        }))
      }), H && !le ? n(z, {
        children: n(M, r({
          $color: D
        }, {
          children: H
        }))
      }) : null]
    })), n(ae, r({
      ModalProps: {
        BackdropProps: {
          invisible: !0
        }
      },
      PaperProps: {
        sx: {
          borderTopLeftRadius: f(X ? 24 : 16),
          borderTopRightRadius: f(X ? 24 : 16),
          width: X ? "unset" : f(420)
        }
      },
      anchor: "bottom",
      open: re || !1,
      onClose: he,
      onOpen: se
    }, {
      children: t(P, r({
        isMobile: X
      }, {
        children: [X && n(k, {}), q && n(o, {
          children: X ? n(I, r({
            isMobile: X
          }, {
            children: n(u, r({
              fontWeight: 500,
              variant: "title3"
            }, {
              children: q
            }))
          })) : n(s, r({
            flex: "0 0"
          }, {
            children: t(h, r({
              alignItems: "center",
              container: !0,
              flexWrap: "nowrap",
              justifyContent: "space-between",
              p: 3,
              pb: 1
            }, {
              children: [n(h, r({
                item: !0
              }, {
                children: n(I, r({
                  isMobile: X
                }, {
                  children: n(u, r({
                    fontWeight: 500,
                    variant: "title3"
                  }, {
                    children: q
                  }))
                }))
              })), n(h, r({
                item: !0
              }, {
                children: n(m, r({
                  size: "small",
                  onClick: he
                }, {
                  children: n(p, {
                    color: "abandonedSpaceship",
                    height: 22,
                    src: O,
                    width: 22
                  })
                }))
              }))]
            }))
          }))
        }), n(L, {
          children: me
        })]
      }))
    }))]
  });
}

export { R as SelectField };