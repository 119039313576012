import { Routes } from '@enums';
import { i18n } from '@localization';
import dollarCircle from '@images/dollar-circle.image.svg';
import home from '@images/home.image.svg';
import more from '@images/more-dots.image.svg';
import payments from '@images/payments.image.svg';
/**
 * Get get bottom navigation actions function.
 *
 * @author Christina Kashevar
 * @function getBottomNavigationActions
 * @category helpers
 * @return {BottomNavigationActionProps[]} array of bottom navigation actions.
 */

var getBottomNavigationActions = function getBottomNavigationActions() {
  return [{
    icon: home,
    label: "".concat(i18n.t('common.bottomNavigationActions.main')),
    value: Routes.Home
  }, {
    icon: payments,
    label: "".concat(i18n.t('common.bottomNavigationActions.payment')),
    value: Routes.Payments
  }, {
    icon: dollarCircle,
    label: "".concat(i18n.t('common.bottomNavigationActions.exchange')),
    value: Routes.Exchange
  }, {
    icon: more,
    label: "".concat(i18n.t('common.bottomNavigationActions.more')),
    value: Routes.More
  }];
};

export { getBottomNavigationActions };