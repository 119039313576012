import { HIDE_LOADER, INIT_LOADER_HIDING, INIT_LOADER_SHOWING, INIT_REQUEST_COUNTER_DECREASING, INIT_REQUEST_COUNTER_INCREASING, LOADER_SHOW, REQUEST_COUNTER_DECREASE, REQUEST_COUNTER_INCREASE } from '@store/loader/loader.action-types';
export var actions = {
  /**
   * Decrease request counter.
   *
   * @author Ihar Kazlouski
   * @function decreaseRequestCounter
   * @category Actions
   * @subcategory Loader
   */
  decreaseRequestCounter: function decreaseRequestCounter() {
    return {
      type: REQUEST_COUNTER_DECREASE
    };
  },

  /**
   * Hide loader.
   *
   * @author Ihar Kazlouski
   * @function hideLoader
   * @category Actions
   * @subcategory Loader
   */
  hideLoader: function hideLoader() {
    return {
      type: HIDE_LOADER
    };
  },

  /**
   * Increase request counter.
   *
   * @author Ihar Kazlouski
   * @function increaseRequestCounter
   * @category Actions
   * @subcategory Loader
   */
  increaseRequestCounter: function increaseRequestCounter() {
    return {
      type: REQUEST_COUNTER_INCREASE
    };
  },

  /**
   * Init decreasing of request counter..
   *
   * @author Ihar Kazlouski
   * @function initDecreasingRequestCounter
   * @category Actions
   * @subcategory Loader
   */
  initDecreasingRequestCounter: function initDecreasingRequestCounter() {
    return {
      type: INIT_REQUEST_COUNTER_DECREASING
    };
  },

  /**
   * Init increasing of request counter.
   *
   * @author Ihar Kazlouski
   * @function initIncreasingRequestCounter
   * @category Actions
   * @subcategory Loader
   */
  initIncreasingRequestCounter: function initIncreasingRequestCounter() {
    return {
      type: INIT_REQUEST_COUNTER_INCREASING
    };
  },

  /**
   * Init loader hiding.
   *
   * @author Ihar Kazlouski
   * @function initLoaderHiding
   * @category Actions
   * @subcategory Loader
   */
  initLoaderHiding: function initLoaderHiding() {
    return {
      type: INIT_LOADER_HIDING
    };
  },

  /**
   * Init loader showing.
   *
   * @author Ihar Kazlouski
   * @function initModalShowing
   * @category Actions
   */
  initLoaderShowing: function initLoaderShowing() {
    return {
      type: INIT_LOADER_SHOWING
    };
  },

  /**
   * Show loader.
   *
   * @author Ihar Kazlouski
   * @function showLoader
   * @category Actions
   * @subcategory Loader
   */
  showLoader: function showLoader() {
    return {
      type: LOADER_SHOW
    };
  }
};