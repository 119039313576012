import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';
import { ConstantsUtil } from '@utils';
import { API_GRID_FAILURE, API_GRID_FETCH, API_GRID_SUCCESS, API_GRID_UPDATE_FETCH, CLOSE_ALL_GRIDS, CLOSE_ALL_GRIDS_REQUEST, CLOSE_GRID, CLOSE_GRID_REQUEST, GRID_INIT, GRID_INIT_REQUEST, UPDATE_GRID, UPDATE_GRID_REQUEST } from './grids.action-types';
export var actions = {
  /**
   * Close all grids.
   *
   * @author Ihar Kazlouski
   * @function closeAllGrids
   * @category Actions
   * @subcategory Grids
   */
  closeAllGrids: function closeAllGrids() {
    return {
      type: CLOSE_ALL_GRIDS
    };
  },

  /**
   * Request all grids closing.
   *
   * @author Ihar Kazlouski
   * @function closeAllGridsRequest
   * @category Actions
   * @subcategory Grids
   */
  closeAllGridsRequest: function closeAllGridsRequest() {
    return {
      type: CLOSE_ALL_GRIDS_REQUEST
    };
  },

  /**
   * Grid api failure.
   *
   * @author Mikhail Siutsou
   * @function gridApiFailure
   * @category Actions
   * @subcategory Grids
   * @param {GridPayload<T>} payload payload.
   */
  gridApiFailure: function gridApiFailure(payload) {
    return {
      payload: payload,
      type: API_GRID_FAILURE
    };
  },

  /**
   * Api grid fetch.
   *
   * @author Mikhail Siutsou
   * @function gridApiFetch
   * @category Actions
   * @subcategory Grids
   * @param {GridApiFetchPayload<T, R>} payload payload.
   */
  gridApiFetch: function gridApiFetch(payload) {
    var _ref;

    return _ref = {}, _defineProperty(_ref, ERROR_ACTION, "".concat(API_GRID_FETCH, "/").concat(ConstantsUtil.actions.ASYNC_FAILED)), _defineProperty(_ref, WAIT_FOR_ACTION, "".concat(API_GRID_FETCH, "/").concat(ConstantsUtil.actions.ASYNC_SUCCESS)), _defineProperty(_ref, "payload", payload), _defineProperty(_ref, "type", API_GRID_FETCH), _ref;
  },

  /**
   * Grid api success.
   *
   * @author Mikhail Siutsou
   * @function gridApiSuccess
   * @category Actions
   * @subcategory Grids
   * @param {GridPayload<T>} payload payload.
   */
  gridApiSuccess: function gridApiSuccess(payload) {
    return {
      payload: payload,
      type: API_GRID_SUCCESS
    };
  },

  /**
   * Api update grid fetch.
   *
   * @author Ihar Kazlouski
   * @function gridApiUpdateFetch
   * @category Actions
   * @subcategory Grids
   * @param {GridApiFetchPayload<T, R>} payload payload.
   */
  gridApiUpdateFetch: function gridApiUpdateFetch(payload) {
    var _ref2;

    return _ref2 = {}, _defineProperty(_ref2, ERROR_ACTION, "".concat(API_GRID_UPDATE_FETCH, "/").concat(ConstantsUtil.actions.ASYNC_FAILED)), _defineProperty(_ref2, WAIT_FOR_ACTION, "".concat(API_GRID_UPDATE_FETCH, "/").concat(ConstantsUtil.actions.ASYNC_SUCCESS)), _defineProperty(_ref2, "payload", payload), _defineProperty(_ref2, "type", API_GRID_UPDATE_FETCH), _ref2;
  },

  /**
   * Close grid.
   *
   * @author Ihar Kazlouski
   * @function gridClose
   * @category Actions
   * @subcategory Grids
   * @param {GridClosePayload} payload payload.
   */
  gridClose: function gridClose(payload) {
    return {
      payload: payload,
      type: CLOSE_GRID
    };
  },

  /**
   * Request grid closing.
   *
   * @author Ihar Kazlouski
   * @function gridCloseRequest
   * @category Actions
   * @subcategory Grids
   * @param {GridClosePayload} payload payload.
   */
  gridCloseRequest: function gridCloseRequest(payload) {
    return {
      payload: payload,
      type: CLOSE_GRID_REQUEST
    };
  },

  /**
   * Grid init.
   *
   * @author Ihar Kazlouski
   * @function gridInit
   * @category Actions
   * @subcategory Grids
   * @param {GridPayload} payload payload.
   */
  gridInit: function gridInit(payload) {
    return {
      payload: payload,
      type: GRID_INIT
    };
  },

  /**
   * Request grid initiation.
   *
   * @author Ihar Kazlouski
   * @function gridInitRequest
   * @category Actions
   * @subcategory Grids
   * @param {GridPayload} payload payload.
   */
  gridInitRequest: function gridInitRequest(payload) {
    return {
      payload: payload,
      type: GRID_INIT_REQUEST
    };
  },

  /**
   * Update grid.
   *
   * @author Ihar Kazlouski
   * @function gridUpdate
   * @category Actions
   * @subcategory Grids
   * @param {GridPayload} payload payload.
   */
  gridUpdate: function gridUpdate(payload) {
    return {
      payload: payload,
      type: UPDATE_GRID
    };
  },

  /**
   * Request grid updating.
   *
   * @author Ihar Kazlouski
   * @function gridUpdateRequest
   * @category Actions
   * @subcategory Grids
   * @param {GridApiUpdatePayload} payload payload.
   */
  gridUpdateRequest: function gridUpdateRequest(payload) {
    return {
      payload: payload,
      type: UPDATE_GRID_REQUEST
    };
  }
};