import * as yup from 'yup';
import { ChangePinStatus } from '@enums';
import { i18n } from '@localization';

var getSettingsPinFormSchema = function getSettingsPinFormSchema(status) {
  return yup.object().shape({
    newPin: yup.array().of(yup.string()).test({
      name: 'checkCorrectNewPin',
      test: function test(value, ctx) {
        if (status !== ChangePinStatus.CREATE) return true;
        if (!value) return true;
        var isHasEmptyValue = value.some(function (pin) {
          return !pin;
        });

        if (value[value.length - 1] && isHasEmptyValue) {
          return ctx.createError({
            message: i18n.t('forms.validation.pinIncorrect')
          });
        }

        return true;
      }
    }),
    newPinCorrect: yup.array().of(yup.string()).test({
      name: 'checkNewPin',
      test: function test(value, ctx) {
        if (status !== ChangePinStatus.CREATE) return true;
        var fields = ctx.parent;
        var isHasEmptyValue = fields.newPin.some(function (pin) {
          return !pin;
        });
        return !isHasEmptyValue;
      }
    }),
    newPinRepeat: yup.array().of(yup.string()).test({
      name: 'checkCorrectNewPinRepeat',
      test: function test(value, ctx) {
        if (status !== ChangePinStatus.CONFIRM) return true;
        if (!value) return true;
        var isHasEmptyValue = value.some(function (pin) {
          return !pin;
        });

        if (value[value.length - 1] && isHasEmptyValue) {
          return ctx.createError({
            message: i18n.t('forms.validation.pinIncorrect')
          });
        }

        var fields = ctx.parent;

        if (!isHasEmptyValue && value.join('') !== fields.newPinValue) {
          return ctx.createError({
            message: i18n.t('forms.validation.pinNotSame')
          });
        }

        return true;
      }
    }),
    newPinRepeatCorrect: yup.array().of(yup.string()).test({
      name: 'checkNewPinRepeat',
      test: function test(value, ctx) {
        if (status !== ChangePinStatus.CONFIRM) return true;
        var fields = ctx.parent;
        var isHasEmptyValue = fields.newPinRepeat.some(function (pin) {
          return !pin;
        });
        return !isHasEmptyValue;
      }
    }),
    pin: yup.array().of(yup.string()).test({
      name: 'checkCorrectCurrentPin',
      test: function test(value, ctx) {
        if (status !== ChangePinStatus.INIT) return true;
        if (!value) return true;
        var isHasEmptyValue = value.some(function (pin) {
          return !pin;
        }); // console.log('🚀 ~ file: settings-pin-form.schema.ts:19 ~ yup.object ~ isHasEmptyValue:', isHasEmptyValue);

        if (value[value.length - 1] && isHasEmptyValue) {
          return ctx.createError({
            message: i18n.t('forms.validation.pinIncorrect')
          });
        }

        return true;
      }
    }),
    pinCorrect: yup.array().of(yup.string()).test({
      name: 'checkCurrentPin',
      test: function test(value, ctx) {
        if (status !== ChangePinStatus.INIT) return true;
        var fields = ctx.parent;
        var isHasEmptyValue = fields.pin.some(function (pin) {
          return !pin;
        });
        return !isHasEmptyValue;
      }
    })
  });
};

export { getSettingsPinFormSchema };