import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { pxToRem } from '@pwa/ui';
import { Box, Chip, Container, styled } from '@mui/material';
var StyledContainer = styled(Container)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1'
  };
  return styles;
});
var StyledWrapper = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;

  var styles = _defineProperty({
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    padding: theme.spacing(3, 0, 5)
  }, theme.breakpoints.up('desktop'), {
    display: 'block',
    maxWidth: theme.typography.pxToRem(452)
  });

  return styles;
});
var StyledChip = styled(Chip)(function (_ref3) {
  var theme = _ref3.theme,
      value = _ref3.value;
  var styles = {
    '& > .MuiChip-label': {
      padding: theme.spacing(0)
    },
    '&:active': {
      boxShadow: 'initial'
    },
    '&:hover': {
      backgroundColor: theme.colors.white
    },
    backgroundColor: theme.colors.white,
    border: value ? "1px solid ".concat(theme.colors.panPurple) : 'initial',
    fontSize: pxToRem(13),
    fontWeight: 500,
    marginRight: theme.spacing(1.25),
    padding: theme.spacing(1, 1.5)
  };
  return styles;
});
export { StyledChip, StyledContainer, StyledWrapper };