import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, Stack, styled } from '@mui/material';
var StyledContent = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1'
  };
  return styles;
});
var StyledInfo = styled(Box)(function () {
  var styles = {
    '& .swiper': {
      margin: 'unset'
    },
    overflow: 'hidden'
  };
  return styles;
});
var StyledButtonsWrapper = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;

  var styles = _defineProperty({
    fontSize: '0',
    marginTop: theme.spacing(2),
    textAlign: 'center'
  }, theme.breakpoints.up('desktop'), {
    textAlign: 'left'
  });

  return styles;
});
var StyledBox = styled(Stack)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1)
  };
  return styles;
});
export { StyledBox, StyledButtonsWrapper, StyledContent, StyledInfo };