import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { BottomNavigation as NavigationElement } from '@pwa/ui';
import { bottomNavigationActionClasses, styled } from '@mui/material';
var StyledNavigationWrapper = styled(NavigationElement)(function (_ref) {
  var _styles;

  var theme = _ref.theme;
  var styles = (_styles = {}, _defineProperty(_styles, "& .".concat(bottomNavigationActionClasses.root), {
    '&:hover': {
      '& svg': {
        color: theme.palette.primary.main
      },
      color: theme.palette.primary.main
    },
    padding: theme.spacing(0.5),
    width: theme.typography.pxToRem(100)
  }), _defineProperty(_styles, "& .".concat(bottomNavigationActionClasses.root, " + .").concat(bottomNavigationActionClasses.root), {
    marginLeft: theme.typography.pxToRem(14)
  }), _defineProperty(_styles, "& .".concat(bottomNavigationActionClasses.root, " svg"), {
    height: theme.typography.pxToRem(24),
    transition: theme.transitions.create(['color']),
    width: theme.typography.pxToRem(24)
  }), _defineProperty(_styles, "& .".concat(bottomNavigationActionClasses.label), {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
    marginTop: theme.spacing(0.5)
  }), _defineProperty(_styles, "& .".concat(bottomNavigationActionClasses.selected), {
    fontSize: theme.typography.pxToRem(14)
  }), _styles);
  return styles;
});
export { StyledNavigationWrapper };