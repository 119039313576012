import { Children as r, isValidElement as t } from "react";

function n(t, n) {
  return r.map(t, function (r) {
    var t = r;
    return t.type.displayName === n ? t : void 0;
  });
}

function e(r) {
  return function (n) {
    return !!t(n) && n.type === r;
  };
}

export { n as getListChildren, e as getValidChild };