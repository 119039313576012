import { history } from '@history';
import { ConstantsUtil } from '@utils';
import { ApiResponseStatusCode, ApiResponseStatusText } from '@enums';

/**
 * get Error popup message helper.
 *
 * @author Yury Kuntsou
 * @function getPopupErrorMessage
 * @category helpers
 * @param {AxiosError<ApiError>} error api
 * @return {ApiErrorObject} new error.
 */
var getErrorMessage = function getErrorMessage(error) {
  var _error$response, _error$response$data, _error$response2, _error$response2$data, _error$response3, _error$response3$data, _error$response4, _error$response4$data, _error$response5, _error$response6, _error$response7, _error$response7$data;

  var errorObject = {
    code: error === null || error === void 0 ? void 0 : (_error$response = error.response) === null || _error$response === void 0 ? void 0 : (_error$response$data = _error$response.data) === null || _error$response$data === void 0 ? void 0 : _error$response$data.code,
    header: error === null || error === void 0 ? void 0 : (_error$response2 = error.response) === null || _error$response2 === void 0 ? void 0 : (_error$response2$data = _error$response2.data) === null || _error$response2$data === void 0 ? void 0 : _error$response2$data.header,
    message: error !== null && error !== void 0 && (_error$response3 = error.response) !== null && _error$response3 !== void 0 && (_error$response3$data = _error$response3.data) !== null && _error$response3$data !== void 0 && _error$response3$data.message ? error.response.data.message : error.request ? ConstantsUtil.errors.NETWORK_SETTING_ERROR : ConstantsUtil.errors.NETWORK_ERROR,
    route: "".concat(history.location.pathname).concat(history.location.search).concat(history.location.hash),
    status: (error === null || error === void 0 ? void 0 : (_error$response4 = error.response) === null || _error$response4 === void 0 ? void 0 : (_error$response4$data = _error$response4.data) === null || _error$response4$data === void 0 ? void 0 : _error$response4$data.status) || (error === null || error === void 0 ? void 0 : (_error$response5 = error.response) === null || _error$response5 === void 0 ? void 0 : _error$response5.statusText),
    statusCode: ((_error$response6 = error.response) === null || _error$response6 === void 0 ? void 0 : _error$response6.status) || 0,
    timestamp: (error === null || error === void 0 ? void 0 : (_error$response7 = error.response) === null || _error$response7 === void 0 ? void 0 : (_error$response7$data = _error$response7.data) === null || _error$response7$data === void 0 ? void 0 : _error$response7$data.timestamp) || new Date()
  };

  switch (errorObject.statusCode) {
    case ApiResponseStatusCode.UNAUTHORIZED:
      errorObject.code = errorObject.code || ApiResponseStatusText.Unauthorized;
      errorObject.message = ConstantsUtil.errors.TOKEN_MISSING;
      return errorObject;

    case ApiResponseStatusCode.NOT_FOUND:
      errorObject.code = errorObject.code || ApiResponseStatusText.NotFound;
      return errorObject;

    default:
      return errorObject;
  }
};

export { getErrorMessage };