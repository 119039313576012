import { styled as t, Box as e, alpha as r } from "@mui/material";
var o = t(e)(function (t) {
  var e;
  return t.theme, (e = {})["&:after"] = {
    background: "linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.85) 5%, rgba(255, 255, 255, 0.5) 100%)",
    bottom: 0
  }, e["&:before"] = {
    background: "linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.85) 5%, rgba(255, 255, 255, 0.5) 100%)",
    top: 0
  }, e["&:before, &:after"] = {
    content: "''",
    display: "block",
    height: 55,
    left: 0,
    pointerEvents: "none",
    position: "absolute",
    width: "100%",
    zIndex: 2
  }, e.display = "flex", e.flexDirection = "row", e.position = "relative", e;
}),
    a = t(e)(function (t) {
  return {
    flex: "1 0",
    height: t.theme.typography.pxToRem(144)
  };
}),
    n = t(e)(function (t) {
  var e = t.theme;
  return {
    background: r(e.palette.lavenderSyrup.main, .7),
    borderRadius: e.typography.pxToRem(16),
    height: 34,
    left: 0,
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translate3d(0, -50%, 0)"
  };
});
export { a as StyledCalendarWheelWrapper, o as StyledCalendarWrapper, n as StyledCenterBox };