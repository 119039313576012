import moment from 'moment';
export var getCardLimits = function getCardLimits() {
  return {
    filters: [],
    limits: [{
      code: '123',
      currency: 'BYN',
      currentAmount: 100,
      currentNumber: 10,
      dateFrom: moment().format('DD MMM YYYY'),
      dateTo: moment().add(1, 'day').format('DD MMM YYYY'),
      defaultAmount: 1000,
      defaultNumber: 30,
      fullName: 'Оплата товаров (услуг) на территории Республики Беларусь',
      maxAmount: 1200,
      period: 4,
      shortName: 'Платежи в РБ'
    }, {
      code: '234',
      currency: 'BYN',
      currentAmount: 200,
      currentNumber: 20,
      dateFrom: moment().format('DD MMM YYYY'),
      dateTo: moment().add(1, 'day').format('DD MMM YYYY'),
      defaultAmount: 2000,
      defaultNumber: 30,
      fullName: 'Снятие наличных на территории Республики Беларусь',
      maxAmount: 2200,
      period: 4,
      shortName: 'Снятие наличных в РБ'
    }, {
      code: '345',
      currency: 'BYN',
      currentAmount: 300,
      currentNumber: 30,
      defaultAmount: 3000,
      defaultNumber: 40,
      fullName: 'Оплата товаров (услуг) за пределом Республики Беларусь',
      info: 'Китай, Венесуэла, Тайланд',
      maxAmount: 3200,
      period: 4,
      shortName: 'Платежи за рубежом'
    }, {
      code: '456',
      currency: 'BYN',
      currentAmount: 400,
      currentNumber: 40,
      dateFrom: moment().format('DD MMM YYYY'),
      dateTo: moment().add(1, 'day').format('DD MMM YYYY'),
      defaultAmount: 4000,
      defaultNumber: 50,
      fullName: 'Снятие наличных за пределом Республики Беларусь',
      info: 'Китай, Венесуэла, Тайланд',
      maxAmount: 4200,
      period: 4,
      shortName: 'Снятие наличных за рубежом'
    }]
  };
};