import { __assign as r } from "../../../../_virtual/_tslib.js";
import { jsxs as e, jsx as o, Fragment as t } from "react/jsx-runtime";
import { Box as i } from "@mui/material";
import { getLastFourCardDigits as m } from "../../../../helpers/card-number/card-number.helper.js";
import "react";
import "../../../../enums/entities/entities.enum.js";
import "../../../../enums/keyboard/keyboard.enum.js";
import "../../../../enums/theme/theme.enum.js";
import "../../../../node_modules/@babel/runtime/helpers/typeof.js";
import { Typography as n } from "../../../typography/typography.component.js";
import { StyledBox as a, StyledSeparator as c } from "./card-info.styles.js";

var l = function (l) {
  var p = l.validTo,
      s = l.cardNumber,
      d = l.color;
  return e(a, {
    children: [o(i, {
      children: o(n, r({
        color: d || "white",
        variant: "body3"
      }, {
        children: "**** ".concat(m(s))
      }))
    }), p && e(t, {
      children: [o(i, {
        children: o(c, {
          $backgroundColor: d,
          color: "white"
        })
      }), o(i, {
        children: o(n, r({
          color: d || "white",
          variant: "body3"
        }, {
          children: p
        }))
      })]
    })]
  });
};

export { l as CardInfo };