import { DrawersService } from '@services';
import { Drawers } from '@enums';
import { i18n } from '@localization';
/**
 * Handle open info drawer.
 *
 * @author Christina Kashevar
 * @return {void}
 */

var openDepositsTypesDrawer = function openDepositsTypesDrawer() {
  void DrawersService.open(Drawers.WithHTMLComponent, {
    additionalInfo: {
      body: i18n.t('drawers.depositsTypes.text'),
      buttonAccept: {
        color: 'white',
        disableElevation: true,
        variant: 'outlined'
      },
      title: "<h2>".concat(i18n.t('drawers.depositsTypes.title'), "</h2>")
    }
  });
};
/**
 * Handle open info drawer.
 *
 * @author Christina Kashevar
 * @return {void}
 */


var openOverdraftDrawer = function openOverdraftDrawer() {
  return DrawersService.open(Drawers.WithHTMLComponent, {
    additionalInfo: {
      body: i18n.t('drawers.overdraftInfo.text'),
      buttonAccept: {
        color: 'white',
        disableElevation: true,
        text: "".concat(i18n.t('drawers.overdraftInfo.buttonAccept')),
        variant: 'outlined'
      },
      buttonReject: {
        color: 'primary',
        text: "".concat(i18n.t('drawers.overdraftInfo.buttonReject')),
        variant: 'contained'
      },
      title: "<h2>".concat(i18n.t('drawers.overdraftInfo.title'), "</h2>")
    }
  });
};
/**
 * Handle open info drawer.
 *
 * @author Christina Kashevar
 * @return {void}
 */


var openCapitalizationInfoDrawer = function openCapitalizationInfoDrawer() {
  void DrawersService.open(Drawers.WithHTMLComponent, {
    additionalInfo: {
      body: i18n.t('drawers.capitalizationInfo.text'),
      buttonAccept: {
        color: 'white',
        disableElevation: true,
        text: "".concat(i18n.t('drawers.capitalizationInfo.buttonText')),
        variant: 'outlined'
      },
      title: "<h2>".concat(i18n.t('drawers.capitalizationInfo.title'), "</h2>")
    }
  });
};
/**
 * Handle open info drawer.
 *
 * @author Christina Kashevar
 * @return {void}
 */


var openProlongationInfoDrawer = function openProlongationInfoDrawer() {
  void DrawersService.open(Drawers.WithHTMLComponent, {
    additionalInfo: {
      body: i18n.t('drawers.prolongationInfo.text'),
      buttonAccept: {
        color: 'white',
        disableElevation: true,
        text: "".concat(i18n.t('drawers.prolongationInfo.buttonText')),
        variant: 'outlined'
      },
      title: "<h2>".concat(i18n.t('drawers.prolongationInfo.title'), "</h2>")
    }
  });
};
/**
 * Handle open replenish account drawer.
 *
 * @author Christina Kashevar
 * @return {void}
 */


var openReplenishAccountDrawer = function openReplenishAccountDrawer() {
  void DrawersService.open(Drawers.ReplenishAccount);
};

export { openCapitalizationInfoDrawer, openDepositsTypesDrawer, openOverdraftDrawer, openProlongationInfoDrawer, openReplenishAccountDrawer };