import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, Container, styled } from '@mui/material';
var StyledContainer = styled(Container)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1'
  };
  return styles;
});
var StyledWrapper = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;

  var styles = _defineProperty({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(2)
  }, theme.breakpoints.up('desktop'), {
    display: 'block',
    maxWidth: theme.typography.pxToRem(452)
  });

  return styles;
});
export { StyledContainer, StyledWrapper };