import { __rest as o, __assign as i } from "../../_virtual/_tslib.js";
import { jsx as n, jsxs as t } from "react/jsx-runtime";
import * as r from "react";
import { Stack as e, Box as a } from "@mui/material";
import { Icon as c } from "../icon/icon.component.js";
import { RemoteImage as p } from "../remote-image/remote-image.view.js";
import { Typography as l } from "../typography/typography.component.js";
import { StyledButton as s, StyledArrowWrapper as m } from "./button-link.styles.js";
import d from "../../packages/pwa-ui/assets/images/arrow-right.image.svg.js";

var h = function (r, h) {
  var g = r.title,
      w = r.text,
      f = r.isShowArrow,
      v = r.icon,
      y = r.iconColor,
      b = void 0 === y ? "primary" : y,
      u = r.variant,
      j = void 0 === u ? "contained" : u,
      x = r.color,
      S = void 0 === x ? "white" : x,
      k = r.iconRemote,
      W = r.subtitle,
      C = o(r, ["title", "text", "isShowArrow", "icon", "iconColor", "variant", "color", "iconRemote", "subtitle"]),
      R = "contained" === j && !["white", "carteBlanche", "lavenderSyrup", "alpineGoat", "whiteSolid"].includes(S);
  return n(s, i({}, C, {
    ref: h,
    color: S,
    variant: j
  }, {
    children: t(e, i({
      alignItems: "center",
      component: "span",
      direction: "row",
      flexWrap: "nowrap",
      justifyContent: "space-between",
      spacing: 2.5,
      width: "100%"
    }, {
      children: [n(a, i({
        component: "span"
      }, {
        children: t(e, i({
          alignItems: "center",
          component: "span",
          direction: "row",
          flexWrap: "nowrap",
          spacing: 1.5
        }, {
          children: [v && n(c, {
            color: b,
            height: 24,
            src: v,
            width: 24
          }), k && n(p, {
            height: 44,
            src: k,
            width: 44
          }), (g || w || W) && t(e, i({
            component: "span",
            spacing: .5
          }, {
            children: [(g || W) && t(a, i({
              component: "span",
              display: "block"
            }, {
              children: [g && n(l, i({
                align: "left",
                color: "nightWizard",
                component: "span",
                contrast: R,
                display: "block",
                fontWeight: 500,
                variant: "body1"
              }, {
                children: g
              })), W && n(l, i({
                align: "left",
                color: "nightWizard",
                component: "span",
                contrast: R,
                display: "block",
                variant: "body2"
              }, {
                children: W
              }))]
            })), w && n(l, i({
              align: "left",
              color: "abandonedSpaceship",
              component: "span",
              contrast: R,
              variant: "body2"
            }, {
              children: w
            }))]
          }))]
        }))
      })), f && n(m, i({
        component: "span"
      }, {
        children: n(c, {
          color: R ? "white" : "abandonedSpaceship",
          height: 10,
          src: d,
          width: 6
        })
      }))]
    }))
  }));
},
    g = r.forwardRef(h);

export { g as ButtonLink };