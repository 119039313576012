export var getRegionMock = function getRegionMock() {
  return {
    region: [{
      isNextQuery: true,
      regionName: 'БРЕСТСКАЯ',
      regionType: 'обл'
    }, {
      isNextQuery: true,
      regionName: 'ВИТЕБСКАЯ',
      regionType: 'обл'
    }, {
      isNextQuery: true,
      regionName: 'ГОМЕЛЬСКАЯ',
      regionType: 'обл'
    }, {
      isNextQuery: true,
      regionName: 'ГРОДНЕНСКАЯ',
      regionType: 'обл'
    }, {
      isNextQuery: true,
      regionName: 'МИНСКАЯ',
      regionType: 'обл'
    }, {
      isNextQuery: true,
      regionName: 'МОГИЛЕВСКАЯ',
      regionType: 'обл.'
    }],
    regionCenter: [{
      isNextQuery: false,
      localityName: 'БРЕСТ',
      localityType: 'г.',
      soato: '1401000000'
    }, {
      isNextQuery: false,
      localityName: 'ВИТЕБСК',
      localityType: 'г.',
      soato: '2001000000'
    }, {
      isNextQuery: false,
      localityName: 'ГОМЕЛЬ',
      localityType: 'г.',
      soato: '3001000000'
    }, {
      isNextQuery: false,
      localityName: 'ГРОДНО',
      localityType: 'г.',
      soato: '4001000000'
    }, {
      isNextQuery: false,
      localityName: 'МИНСК',
      localityType: 'г.',
      soato: '50000000000'
    }, {
      isNextQuery: false,
      localityName: 'МОГИЛЕВ',
      localityType: 'г.',
      soato: '6001000000'
    }],
    street: [{
      streetTypeFull: 'Бульвар',
      streetTypeShort: 'б-р'
    }, {
      streetTypeFull: 'Кольцо',
      streetTypeShort: 'к/о'
    }, {
      streetTypeFull: 'Квартал',
      streetTypeShort: 'кв-л'
    }, {
      streetTypeFull: 'Улица',
      streetTypeShort: 'ул.'
    }]
  };
};
export var getDistrictMock = function getDistrictMock() {
  return {
    district: [{
      districtName: 'БАРАНОВИЧСКИЙ',
      districtType: 'р-н',
      isNextQuery: true
    }, {
      districtName: 'БЕРЁЗОВСКИЙ',
      districtType: 'р-н',
      isNextQuery: true
    }, {
      districtName: 'ГАНЦЕВИЧСКИЙ',
      districtType: 'р-н',
      isNextQuery: true
    }],
    districtCenter: [{
      districtName: 'БАРАНОВИЧСКИЙ',
      isNextQuery: false,
      localityName: 'БАРАНОВИЧИ',
      localityType: 'г.',
      soato: '1410000000'
    }, {
      districtName: 'БЕРЁЗОВСКИЙ',
      isNextQuery: false,
      localityName: 'БЕРЁЗА',
      localityType: 'г.',
      soato: '1208501000'
    }, {
      districtName: 'ГАНЦЕВИЧСКИЙ',
      isNextQuery: false,
      localityName: 'ГАНЦЕВИЧИ',
      localityType: 'г.',
      soato: '1216501000'
    }]
  };
};
export var getLocalityMock = function getLocalityMock() {
  return {
    locality: [{
      localityName: 'БАРАНОВИЧИ',
      localityType: 'г.',
      soato: '1410000000'
    }, {
      localityName: 'БЕРЁЗА',
      localityType: 'г.',
      soato: '1208501000'
    }, {
      localityName: 'ГАНЦЕВИЧИ',
      localityType: 'г.',
      soato: '1216501000'
    }, {
      localityName: 'ВЕЛИКИЕ ЛУКИ',
      localityType: 'д.',
      soato: '1204804011'
    }, {
      localityName: 'ГРАБОВЕЦ',
      localityType: 'д.',
      soato: '1204804016'
    }, {
      localityName: 'КАБУШКИНА',
      localityType: 'п.',
      soato: '1204804021'
    }]
  };
};
export var getDepartmentsMock = function getDepartmentsMock() {
  return [{
    buildingNumber: '52',
    departmentNumber: '52',
    localityName: 'Жлобин',
    officeCode: '454454',
    streetName: 'Первомайская'
  }];
};