import { AppBar, Box, Container, styled } from '@mui/material';
var StyledNavigationWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    flexGrow: 0,
    flexShrink: 0,
    height: theme.NAVIGATION_HEIGHT
  };
  return styles;
});
var StyledAppBar = styled(AppBar)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    bottom: 0,
    left: 0,
    top: 'auto'
  };
  return styles;
});
var StyledContainer = styled(Container)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {// [theme.breakpoints.up('tablet')]: {
    //   maxWidth: theme.breakpoints.values.tablet,
    // },
    // [theme.breakpoints.up('desktop')]: {
    //   maxWidth: theme.breakpoints.values.largeDesktop,
    // },
  };
  return styles;
});
export { StyledAppBar, StyledContainer, StyledNavigationWrapper };