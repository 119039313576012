import { pxToRem } from '@pwa/ui';
import { Container, Stack, styled } from '@mui/material';
var StyledBox = styled(Stack)(function (_ref) {
  var _theme$colors;

  var theme = _ref.theme;
  var styles = {
    alignItems: 'center',
    borderBottom: "".concat(pxToRem(1), " solid ").concat(theme === null || theme === void 0 ? void 0 : (_theme$colors = theme.colors) === null || _theme$colors === void 0 ? void 0 : _theme$colors.lavenderSyrup),
    justifyContent: 'space-between',
    padding: theme.spacing(2.25, 0),
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    '&:last-child': {
      borderBottom: 'none'
    }
  };
  return styles;
});
var StyledContainer = styled(Container)(function (_ref2) {
  var theme = _ref2.theme,
      isMobile = _ref2.isMobile;
  var styles = {
    padding: isMobile ? undefined : theme.spacing(0, 2)
  };
  return styles;
});
export { StyledBox, StyledContainer };