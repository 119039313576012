import { pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledBox = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    backgroundColor: theme.palette.whiteSolid.main,
    borderRadius: pxToRem(16)
  };
  return styles;
});
export { StyledBox };