import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { getHexColor, pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledContent = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: theme.typography.pxToRem(352)
  };
  return styles;
});
var StyledPageWrapper = styled(Box)(function (_ref2) {
  var theme = _ref2.theme,
      _ref2$$backgroundColo = _ref2.$backgroundColor,
      $backgroundColor = _ref2$$backgroundColo === void 0 ? 'white' : _ref2$$backgroundColo;

  var styles = _defineProperty({
    backgroundColor: theme.colors[$backgroundColor],
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: theme.spacing(2, 0, 5)
  }, theme.breakpoints.up('tablet'), {
    display: 'flex',
    justifyContent: 'end',
    paddingTop: theme.spacing(0) // paddingTop: `clamp(${theme.spacing(5)}, 4vw, 5vh)`,

  });

  return styles;
});
var StyledWrapper = styled(Box)(function () {
  var styles = {
    textAlign: 'center',
    whiteSpace: 'pre-line'
  };
  return styles;
});
var StyledIconWrapper = styled(Box)(function () {
  var styles = {
    display: 'block',
    margin: '0 auto',
    maxWidth: pxToRem(398),
    position: 'relative',
    transform: 'translate3d(0,0,0)',
    width: '100%'
  };
  return styles;
});
var StyledIconSpacer = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    height: 0,
    overflow: 'hidden',
    paddingTop: '109.5%',
    width: '100%'
  };
  return styles;
});
var StyledIconCircle = styled(Box)(function (_ref4) {
  var theme = _ref4.theme,
      $backgroundColor = _ref4.$backgroundColor;
  var color = getHexColor(theme.palette, $backgroundColor);
  var styles = {
    backgroundColor: color,
    borderRadius: '50%',
    bottom: 0,
    height: 0,
    left: '50%',
    marginLeft: 'calc(-35% + 3px)',
    paddingTop: '70%',
    position: 'absolute',
    width: '70%'
  };
  return styles;
});
var StyledIcon = styled('img')(function (_ref5) {
  var theme = _ref5.theme;
  var styles = {
    display: 'block',
    height: '100%',
    inset: 0,
    position: 'absolute',
    width: '100%'
  };
  return styles;
});
export { StyledContent, StyledIcon, StyledIconCircle, StyledIconSpacer, StyledIconWrapper, StyledPageWrapper, StyledWrapper };