import { pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledWrapper = styled(Box)(function () {
  var styles = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    justifyContent: 'space-between'
  };
  return styles;
});
var StyledBox = styled(Box)(function (_ref) {
  var _theme$colors;

  var theme = _ref.theme;
  var styles = {
    borderBottom: "".concat(pxToRem(1), " solid ").concat(theme === null || theme === void 0 ? void 0 : (_theme$colors = theme.colors) === null || _theme$colors === void 0 ? void 0 : _theme$colors.lavenderSyrup)
  };
  return styles;
});
export { StyledBox, StyledWrapper };