import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { IconButton } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyleContentWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    backgroundColor: theme.colors.whiteSolid,
    borderTopLeftRadius: theme.typography.pxToRem(16),
    borderTopRightRadius: theme.typography.pxToRem(16),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingBottom: theme.spacing(8),
    paddingTop: theme.spacing(3)
  };
  return styles;
});
var StyledIconWrapper = styled(IconButton)(function (_ref2) {
  var theme = _ref2.theme;

  var styles = _defineProperty({
    '& svg': {
      transition: theme.transitions.create(['color'])
    },
    minHeight: 'unset',
    minWidth: 'unset',
    padding: '0'
  }, theme.breakpoints.up('desktop'), {
    backgroundColor: theme.palette.white.main,
    minHeight: theme.typography.pxToRem(40),
    minWidth: theme.typography.pxToRem(40)
  });

  return styles;
});
export { StyleContentWrapper, StyledIconWrapper };