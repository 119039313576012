import { useMediaQuery } from '@mui/material';
/**
 * Device size hook.
 *
 * @author Mikhail Siutsou
 * @function useDeviceSize
 * @category helper
 * @return {{ mobile: boolean; tablet: boolean; desktop: boolean; }} return device sizes.
 */

export var useDeviceSize = function useDeviceSize() {
  return {
    desktop: useMediaQuery(function (theme) {
      return theme.breakpoints.between('desktop', 'largeDesktop');
    }),
    isDesktopBreakpoint: useMediaQuery(function (theme) {
      return theme.breakpoints.up('desktop');
    }),
    largeDesktop: useMediaQuery(function (theme) {
      return theme.breakpoints.up('largeDesktop');
    }),
    mobile: useMediaQuery(function (theme) {
      return theme.breakpoints.down('tablet');
    }),
    tablet: useMediaQuery(function (theme) {
      return theme.breakpoints.between('tablet', 'desktop');
    })
  };
};