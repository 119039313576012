import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, ListItem, Stack, styled } from '@mui/material';
import { pxToRem } from '@themes';
var StyledFormWrapper = styled(Stack)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2)
  }, theme.breakpoints.up('desktop'), {
    paddingBottom: 0
  });

  return styles;
});
var StyledContent = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    '&:before': {
      borderTop: "1px solid ".concat(theme.colors.lavenderSyrup),
      content: "''",
      display: 'block',
      height: '1px',
      position: 'absolute',
      top: '0',
      width: 'calc(100% - 16px)'
    },
    color: theme.colors.abandonedSpaceship,
    fontSize: pxToRem(13),
    margin: theme.spacing(6, 0, 0),
    padding: theme.spacing(2, 2, 4, 1),
    position: 'relative'
  };
  return styles;
});
var StyledListItem = styled(ListItem)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  };
  return styles;
});
var StyledPhoneWrapper = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    '& input.MuiInputBase-input': {
      padding: theme.spacing(2, 0, 2, 0.5)
    },
    '& span.MuiButtonBase-root': {
      padding: theme.spacing(0, 1, 0, 0)
    }
  };
  return styles;
});
var StyledCheckboxWrapper = styled(Box)(function (_ref5) {
  var theme = _ref5.theme;

  var styles = _defineProperty({
    '& label': {
      margin: '0'
    },
    '& span.MuiButtonBase-root': {
      padding: theme.spacing(0, 1.5, 0, 0)
    },
    padding: theme.spacing(0, 0, 4, 1)
  }, theme.breakpoints.up('desktop'), {
    padding: theme.spacing(0, 0, 3, 0)
  });

  return styles;
});
export { StyledCheckboxWrapper, StyledContent, StyledFormWrapper, StyledListItem, StyledPhoneWrapper };