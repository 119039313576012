import * as yup from 'yup';
import { i18n } from '@localization';

var getCreditCalculatorSchema = function getCreditCalculatorSchema(minAmount, minAmountFormatted, maxAmount, maxAmountFormatted, currency) {
  return yup.object().shape({
    amount: yup.number().transform(function (value) {
      return Number.isNaN(value) ? null : value;
    }).required(i18n.t('forms.validation.enterAmount')).min(minAmount, i18n.t('pages.creditsCalculator.amountBottomLabel', {
      from: minAmountFormatted,
      to: "".concat(maxAmountFormatted, " ").concat(currency)
    })).max(maxAmount, i18n.t('pages.creditsCalculator.amountBottomLabel', {
      from: minAmountFormatted,
      to: "".concat(maxAmountFormatted, " ").concat(currency)
    })).nullable(),
    checkAgreement: yup.bool().equals([true])
  });
};

export { getCreditCalculatorSchema };