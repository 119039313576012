/**
 * Helps to get filtered by currency array of accounts.
 *
 * @author Christina Kashevar
 * @function getFilteredAccountsByCurrency
 * @category helpers
 * @return {array} array of accounts.
 */
var getFilteredAccountsByCurrency = function getFilteredAccountsByCurrency(accounts, currency) {
  return accounts.filter(function (account) {
    return account.accountCurrency === currency;
  });
};
/**
 * Helps to create an array of objects of a radio field items.
 *
 * @author Christina Kashevar
 * @function getCurrencyItemsForRadioField
 * @category helpers
 * @return {array} array of radio field items.
 */


var getCurrencyItemsForRadioField = function getCurrencyItemsForRadioField(currencies) {
  return currencies.map(function (currency) {
    return {
      title: currency.nameCurr,
      value: currency.nameCurr,
      view: 'square'
    };
  });
};

export { getCurrencyItemsForRadioField, getFilteredAccountsByCurrency };