import * as yup from 'yup';
import { formatNumber } from '@helpers/numbers';
import { i18n } from '@localization';

var getPaymentAccountSchema = function getPaymentAccountSchema(minAmnts, maxAmnts) {
  return yup.object().shape({
    checkContract: yup.bool().equals([true], i18n.t('forms.validation.required')),
    transfer: yup.object().shape({
      from: yup.string().required(i18n.t('forms.validation.enterAmount')).test({
        message: i18n.t('forms.validation.maxTransferAccountAmount'),
        name: 'maxTransferAccountAmount',
        test: function test(value, ctx) {
          var _ctx$from;

          var allValues = (_ctx$from = ctx.from) === null || _ctx$from === void 0 ? void 0 : _ctx$from[1].value;
          var sellAccount = allValues === null || allValues === void 0 ? void 0 : allValues.sellAccount;
          if (!sellAccount) return true;
          return Number(value) <= sellAccount.accountAmount;
        }
      }).test({
        name: 'minTransferAmnt',
        test: function test(value, ctx) {
          var _ctx$from2, _minAmnts$find;

          if (!value) return true;
          var allValues = (_ctx$from2 = ctx.from) === null || _ctx$from2 === void 0 ? void 0 : _ctx$from2[1].value;
          var currency = allValues === null || allValues === void 0 ? void 0 : allValues.sellAccount.accountCurrency;
          if (!currency) return true;
          var minValue = (_minAmnts$find = minAmnts.find(function (amnt) {
            return amnt.transferCurr === currency;
          })) === null || _minAmnts$find === void 0 ? void 0 : _minAmnts$find.minTransferAmnt;
          if (!minValue) return true;

          if (Number(value) < minValue) {
            return ctx.createError({
              message: i18n.t('forms.validation.minTransferAmount', {
                data: " ".concat(formatNumber(minValue, 2, true, ',', ' '), " ").concat(currency)
              })
            });
          }

          return true;
        }
      }).test({
        name: 'maxTransferAmnt',
        test: function test(value, ctx) {
          var _ctx$from3, _maxAmnts$find;

          if (!value) return true;
          var allValues = (_ctx$from3 = ctx.from) === null || _ctx$from3 === void 0 ? void 0 : _ctx$from3[1].value;
          var currency = allValues === null || allValues === void 0 ? void 0 : allValues.sellAccount.accountCurrency;
          if (!currency) return true;
          var maxValue = (_maxAmnts$find = maxAmnts.find(function (amnt) {
            return amnt.transferCurr === currency;
          })) === null || _maxAmnts$find === void 0 ? void 0 : _maxAmnts$find.maxTransferAmnt;
          if (!maxValue) return true;

          if (Number(value) > maxValue) {
            return ctx.createError({
              message: i18n.t('forms.validation.maxTransferAmount', {
                data: " ".concat(formatNumber(maxValue, 2, true, ',', ' '), " ").concat(currency)
              })
            });
          }

          return true;
        }
      })
    })
  });
};

export { getPaymentAccountSchema };