import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, styled } from '@mui/material';
var StyledLogoWrapper = styled(Box)(function (_ref) {
  var _styles;

  var theme = _ref.theme;
  var height = theme.convertToDVH(12);
  var styles = (_styles = {}, _defineProperty(_styles, '& + div', {
    marginTop: height
  }), _defineProperty(_styles, "display", 'flex'), _defineProperty(_styles, "justifyContent", 'center'), _defineProperty(_styles, 'svg .arrow', {
    fill: theme.palette.primary.main
  }), _styles);
  return styles;
});
export { StyledLogoWrapper };