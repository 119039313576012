import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledContentWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(1)
  }, theme.breakpoints.up('desktop'), {
    maxWidth: theme.typography.pxToRem(452),
    paddingTop: theme.spacing(4)
  });

  return styles;
});
var StyledLogoWrapper = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;

  var styles = _defineProperty({
    marginBottom: theme.spacing(3.5),
    textAlign: 'center'
  }, theme.breakpoints.up('desktop'), {
    display: 'none'
  });

  return styles;
});
var StyledButtonsWrapper = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    backgroundColor: theme.colors.white,
    borderRadius: pxToRem(16)
  };
  return styles;
});
export { StyledButtonsWrapper, StyledContentWrapper, StyledLogoWrapper };