import { styled as r, CircularProgress as t, alpha as e } from "@mui/material";
var o = r(t)(function () {
  return {
    left: 0,
    position: "absolute"
  };
}),
    i = r(t)(function (r) {
  var t = r.theme;
  return {
    color: e(t.palette.primary.main, .2)
  };
});
export { i as StyledProgress, o as StyledProgressMain };