import { __assign as r } from "../../_virtual/_tslib.js";
import { jsxs as e, jsx as o } from "react/jsx-runtime";
import { useNumericFormat as t } from "react-number-format";
import { Box as i } from "@mui/material";
import { Size as m } from "../../enums/entities/entities.enum.js";
import "../../enums/keyboard/keyboard.enum.js";
import "../../enums/theme/theme.enum.js";
import { Typography as a } from "../typography/typography.component.js";
import { StyledBoxWrapper as n, StyledShadow as c, StyledBoxBackground as l, StyledBox as d, BannerLabel as u } from "./detailed-card.styles.js";
import { CardInfo as p } from "./elements/card-info/card-info.component.js";

var s = function (s) {
  var f = s.label,
      h = s.chipColor,
      v = s.currency,
      b = s.amount,
      y = s.validTo,
      g = s.cardNumber,
      j = s.backgroundImg,
      S = s.textColor,
      x = t({
    decimalScale: 2,
    decimalSeparator: ",",
    fixedDecimalScale: !0,
    prefix: " ",
    renderText: function (r) {
      if (void 0 !== r) return r;
    },
    thousandSeparator: " ",
    valueIsNumericString: !0
  }).format,
      k = void 0 !== v && void 0 !== b;
  return e(n, {
    children: [o(c, {}), o(l, {
      $backgroundImg: j,
      $isShowMoneyInfo: k
    }), e(d, {
      children: [f && o(u, {
        $color: h,
        size: m.Small,
        title: f
      }), k && o(i, {
        children: o(a, r({
          color: S || "white",
          fontWeight: 700,
          variant: "title3"
        }, {
          children: x && "".concat(x("".concat(b)), " ").concat(v)
        }))
      }), o(p, {
        cardNumber: g,
        color: S,
        validTo: y
      })]
    })]
  });
};

export { s as DetailedCard };