import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { alpha, AppBar, Box, Button, Container, styled } from '@mui/material';
var StyledBox = styled(Box)(function (_ref) {
  var theme = _ref.theme,
      $isAdaptive = _ref.$isAdaptive;

  var styles = _defineProperty({
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  }, theme.breakpoints.up('desktop'), {
    paddingTop: $isAdaptive ? theme.spacing(5) : undefined
  });

  return styles;
});
var StyledAppBar = styled(AppBar)(function (_ref2) {
  var theme = _ref2.theme;

  var styles = _defineProperty({
    '&.isShowShadow': {
      boxShadow: "0 ".concat(theme.typography.pxToRem(5), " ").concat(theme.typography.pxToRem(20), " ").concat(alpha(theme.colors.nightWizard, 0.08))
    },
    minHeight: theme.HEADER_HEIGHT,
    transition: theme.transitions.create(['background-color', 'box-shadow'])
  }, theme.breakpoints.up('desktop'), {
    minHeight: 'unset'
  });

  return styles;
});
var StyledBoxSpacer = styled(Box)(function (_ref3) {
  var theme = _ref3.theme,
      $headerHeight = _ref3.$headerHeight;
  var styles = {
    flexBasis: $headerHeight || theme.HEADER_HEIGHT,
    flexShrink: 0,
    height: $headerHeight || theme.HEADER_HEIGHT // [theme.breakpoints.up('desktop')]: {
    //   height: theme.HEADER_HEIGHT_DESKTOP,
    // },

  };
  return styles;
});
var StyledContentWrapper = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    paddingBottom: theme.spacing(2.25),
    paddingTop: theme.spacing(2.25),
    position: 'relative',
    textAlign: 'center'
  };
  return styles;
});
var StyledArrowWrapper = styled(Button)(function (_ref5) {
  var theme = _ref5.theme;
  var styles = {
    '& svg': {
      transition: theme.transitions.create(['color'])
    },
    minWidth: 'unset',
    padding: '0'
  };
  return styles;
});
var StyledDesktopContentWrapper = styled(Box)(function (_ref6) {
  var theme = _ref6.theme,
      $center = _ref6.$center;

  var styles = _defineProperty({}, theme.breakpoints.up('desktop'), {
    marginLeft: $center ? 'auto' : undefined,
    marginRight: $center ? 'auto' : undefined,
    maxWidth: theme.typography.pxToRem(452),
    position: 'relative'
  });

  return styles;
});
var StyledMobileContainer = styled(Container)(function (_ref7) {
  var theme = _ref7.theme,
      $isAdaptive = _ref7.$isAdaptive;
  var styles = {};

  if ($isAdaptive) {
    styles.paddingLeft = theme.spacing(2);
    styles.paddingRight = theme.spacing(2);
  }

  return styles;
});
export { StyledAppBar, StyledArrowWrapper, StyledBox, StyledBoxSpacer, StyledContentWrapper, StyledDesktopContentWrapper, StyledMobileContainer };