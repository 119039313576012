import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { convertToDVH, lightTheme as defaultLightThemeUI, pxToRem } from '@pwa/ui';
import { CeraProBoldWoff, CeraProBoldWoff2, CeraProMediumWoff, CeraProMediumWoff2, CeraProRegularWoff, CeraProRegularWoff2 } from '@themes/fonts';
import { colors } from '../colors';
var HEADER_HEIGHT = pxToRem(60);
var HEADER_HEIGHT_DESKTOP = pxToRem(80);
var NAVIGATION_HEIGHT = pxToRem(68);
var breakpoints = defaultLightThemeUI.breakpoints;
var defaultTheme = {
  components: {
    MuiCssBaseline: {
      styleOverrides: "\n    * {\n      font-family: Cera Pro, Helvetica, Arial, sans-serif\n    }\n    *[hidden] {\n      display: none !important;\n      pointer-events: none;\n    }\n    input[type='password'] {\n      font-family: Verdana, monospace, auto, sans-serif, 'Cera Pro';\n      letter-spacing: 0.125em;\n    }\n    @font-face {\n      font-family: 'Cera Pro';\n      font-style: normal;\n      font-display: swap;\n      font-weight: 400;\n      src: local('CeraPro-Regular'), url(".concat(CeraProRegularWoff2, ") format('woff2'), url(").concat(CeraProRegularWoff, ") format('woff');\n    }\n    @font-face {\n      font-family: 'Cera Pro';\n      font-style: normal;\n      font-display: swap;\n      font-weight: 500;\n      src: local('CeraPro-Medium'), url(").concat(CeraProMediumWoff2, ") format('woff2'), url(").concat(CeraProMediumWoff, ") format('woff');\n    }\n    @font-face {\n      font-family: 'Cera Pro';\n      font-style: normal;\n      font-display: swap;\n      font-weight: 700;\n      src: local('CeraPro-Bold'), url(").concat(CeraProBoldWoff2, ") format('woff2'), url(").concat(CeraProBoldWoff, ") format('woff');\n    }\n  ")
    }
  },
  mixins: {
    header: _defineProperty({
      height: HEADER_HEIGHT
    }, breakpoints.up('desktop'), {
      height: HEADER_HEIGHT_DESKTOP
    })
  },
  palette: {
    background: {
      "default": colors.whiteSolid
    }
  }
};
export { convertToDVH, defaultTheme, HEADER_HEIGHT, HEADER_HEIGHT_DESKTOP, NAVIGATION_HEIGHT, pxToRem };