import { __assign as r } from "../../../../_virtual/_tslib.js";
import { jsx as e, jsxs as o } from "react/jsx-runtime";
import { NumericFormat as n } from "react-number-format";
import { Box as a } from "@mui/material";
import { Typography as t } from "../../../typography/typography.component.js";
import { CardsImagesInfo as i } from "../cards-images-info/cards-images-info.component.js";
import { CardsNumber as c } from "../cards-number/cards-number.component.js";

var m = function (m) {
  var l = m.title,
      p = m.cardsImgs,
      s = m.percent,
      d = m.color,
      f = m.variant,
      u = m.cardNumber,
      v = m.titleCustomBlock;
  return e(a, r({
    component: "span",
    display: "block",
    minHeight: 24
  }, {
    children: o(a, r({
      alignItems: "center",
      component: "span",
      display: "flex",
      justifyContent: "space-between"
    }, {
      children: [e(t, r({
        color: "secondary",
        component: "span",
        overflow: "hidden",
        textOverflow: "ellipsis",
        variant: "body2",
        whiteSpace: "nowrap"
      }, {
        children: l
      })), p && 0 !== p.length && e(i, {
        cardsImgs: p,
        color: d,
        variant: f
      }), void 0 !== s && e(a, r({
        component: "span",
        display: "flex",
        ml: .5
      }, {
        children: e(n, {
          decimalScale: void 0,
          decimalSeparator: ",",
          displayType: "text",
          fixedDecimalScale: !0,
          readOnly: !0,
          renderText: function (o) {
            if (void 0 !== o) return e(t, r({
              color: "secondary",
              variant: "caption1"
            }, {
              children: "".concat(o, "%")
            }));
          },
          thousandSeparator: " ",
          value: +s,
          valueIsNumericString: !0
        })
      })), void 0 !== u && e(c, {
        color: d,
        number: u,
        variant: f
      }), v && e(a, r({
        component: "span",
        display: "flex",
        ml: .5
      }, {
        children: v
      }))]
    }))
  }));
};

export { m as CardTitle };