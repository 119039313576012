import { styled as t, InputLabel as e, inputBaseClasses as o, Box as n, FormHelperText as r, Stack as i } from "@mui/material";
import "react";
import "../../../enums/entities/entities.enum.js";
import "../../../enums/keyboard/keyboard.enum.js";
import "../../../enums/theme/theme.enum.js";
import "../../../node_modules/@babel/runtime/helpers/typeof.js";
import { convertToDVH as a } from "../../../helpers/theme/theme.helper.js";
import { Button as m } from "../../button/button.component.js";
import "../../../themes/box-shadows/boxShadows.js";
import "../../../themes/dark.theme.js";
import { pxToRem as p } from "../../../themes/default-theme/default-theme.js";
import "../../../themes/gradients/gradients.js";
import "../../../themes/light.theme.js";
import { TextField as s } from "../text-field/text-field.component.js";
var c = t(e)(function (t) {
  var e = t.theme,
      o = t.$color,
      n = {
    color: e.colors.abandonedSpaceship,
    display: "block",
    fontSize: p(16),
    fontWeight: 400,
    lineHeight: p(18),
    marginBottom: e.spacing(.5),
    maxWidth: "100%",
    paddingLeft: e.spacing(1),
    position: "relative",
    transform: "unset",
    transformOrigin: "unset"
  };
  return "bayFog" === o && (n.color = e.colors.white), n;
}),
    d = t(s)(function (t) {
  var e,
      n,
      r,
      i = t.theme;
  return (e = {})["& .".concat(o.input)] = ((n = {})['&[type="password"]'] = ((r = {})["&::placeholder"] = {
    color: i.palette.nightWizard.main
  }, r.color = i.palette.primary.main, r.fontSize = p(18), r), n.boxSizing = "border-box", n.padding = i.spacing(1), n.textAlign = "center", n), e;
}),
    h = t(n)(function () {
  return {
    fontSize: p(12),
    fontWeight: 500,
    left: "50%",
    lineHeight: p(14),
    marginTop: p(16),
    position: "absolute",
    right: 0,
    top: "100%",
    transform: "translateX(-50%)",
    width: p(178)
  };
}),
    l = t(r)(function (t) {
  var e = t.theme;
  return {
    color: e.palette.error.main,
    display: "block",
    margin: "unset",
    maxWidth: "100%",
    padding: e.spacing(0, 1),
    textAlign: "center",
    wordWrap: "break-word"
  };
}),
    g = t(r)(function (t) {
  var e = t.theme,
      o = t.$color,
      n = {
    color: e.colors.abandonedSpaceship,
    display: "block",
    margin: "unset",
    maxWidth: "100%",
    padding: e.spacing(0, 1),
    textAlign: "center"
  };
  return "bayFog" === o && (n.color = e.colors.white), n;
}),
    u = t(m)(function (t) {
  return {
    backgroundColor: "transparent",
    boxShadow: "none",
    color: t.theme.colors.nightWizard,
    fontSize: p(38),
    fontWeight: 400,
    height: p(92),
    lineHeight: p(54),
    minWidth: "unset",
    padding: 0,
    textAlign: "center",
    width: p(92)
  };
}),
    f = t(n)(function (t) {
  var e,
      o = t.theme;
  return (e = {
    paddingTop: "min(".concat(p(100), ", ").concat(a(8), ")"),
    width: p(292)
  })[o.breakpoints.up("md")] = {
    width: p(340)
  }, e;
}),
    b = t(i)(function (t) {
  var e,
      o = t.theme;
  return (e = {
    columnGap: o.spacing(1),
    rowGap: o.spacing("none")
  })[o.breakpoints.up("md")] = {
    columnGap: o.spacing(4),
    rowGap: o.spacing(4)
  }, e;
});
export { h as StyleFromHelperWrapper, f as StyledBox, u as StyledButton, l as StyledFormHelperErrorText, g as StyledFormHelperText, c as StyledInputLabel, b as StyledStack, d as StyledTextField };