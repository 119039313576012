import { Box, styled } from '@mui/material';
var StyledBoxInfo = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    backgroundColor: theme.palette.lavenderSyrup.main,
    borderRadius: theme.typography.pxToRem(12),
    padding: theme.spacing(1.75, 2)
  };
  return styles;
});
export { StyledBoxInfo };