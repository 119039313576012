import { Box, styled } from '@mui/material';
var StyledWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  };
  return styles;
});
var StyledAccountWrapper = styled(Box)(function (_ref2) {
  var theme = _ref2.theme,
      $isApprove = _ref2.$isApprove;
  var styles = {
    backgroundColor: $isApprove && theme.palette.common.white,
    borderRadius: theme.typography.pxToRem(16),
    boxShadow: $isApprove && theme.boxShadows.independence,
    paddingBottom: $isApprove && theme.spacing(1),
    paddingTop: $isApprove && theme.spacing(1)
  };
  return styles;
});
var StyledAccountSeparatorWrapper = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    '& > div': {
      marginLeft: theme.spacing(1.5),
      marginRight: theme.spacing(1.5)
    },
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5)
  };
  return styles;
});
var StyledSeparator = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    borderTop: "".concat(theme.typography.pxToRem(1), " dashed ").concat(theme.palette.estateViolet.main),
    flex: '1 1'
  };
  return styles;
});
var StyledArrowWrapper = styled(Box)(function (_ref5) {
  var theme = _ref5.theme;
  var styles = {
    alignItems: 'center',
    backgroundColor: theme.palette.ghostWhite.main,
    borderRadius: theme.typography.pxToRem(8),
    display: 'flex',
    flex: "".concat(theme.typography.pxToRem(40), " 0 0"),
    height: theme.typography.pxToRem(40),
    justifyContent: 'center',
    width: theme.typography.pxToRem(40)
  };
  return styles;
});
var StyledInformationWrapper = styled(Box)(function (_ref6) {
  var theme = _ref6.theme;
  var styles = {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.typography.pxToRem(8),
    boxShadow: theme.boxShadows.independence,
    marginTop: theme.spacing(3),
    overflow: 'hidden',
    padding: theme.spacing(2)
  };
  return styles;
});
export { StyledAccountSeparatorWrapper, StyledAccountWrapper, StyledArrowWrapper, StyledInformationWrapper, StyledSeparator, StyledWrapper };