import { __rest as o, __read as t, __assign as r } from "../../_virtual/_tslib.js";
import { jsx as e } from "react/jsx-runtime";
import { useState as n, useRef as i, useCallback as l, useEffect as c } from "react";
import { ScrollContainer as s, StyledContentWrapper as m } from "./scroll-shadow.styles.js";

var u = function (u) {
  var a = u.disableTopShadow,
      d = u.disableBottomShadow,
      h = u.children,
      p = u.scrollContent,
      f = o(u, ["disableTopShadow", "disableBottomShadow", "children", "scrollContent"]),
      b = t(n({
    bottom: !1,
    top: !1
  }), 2),
      w = b[0],
      v = b[1],
      S = i(null),
      T = i(),
      g = l(function () {
    if (S.current) {
      var o = S.current,
          t = o.scrollTop,
          r = 0 === t,
          e = o.scrollHeight - t === o.clientHeight;
      w.top === !r && w.bottom === !e || v({
        bottom: !e,
        top: !r
      });
    }
  }, [w.bottom, w.top]),
      H = function () {
    if (S.current) {
      var o = S.current,
          t = o.scrollHeight,
          r = o.clientHeight,
          e = o.scrollTop,
          n = 0 === e,
          i = t - e === r;
      w.top === !n && w.bottom === !i || v({
        bottom: !i,
        top: !n
      });
    }
  };

  return c(function () {
    if (S.current) {
      var o = S.current,
          t = o.scrollHeight,
          r = o.clientHeight;
      v(t > r ? {
        bottom: !0,
        top: !1
      } : {
        bottom: !1,
        top: !1
      });
    }
  }, [h]), c(function () {
    var o = function () {
      clearTimeout(T.current), T.current = setTimeout(H, 100);
    };

    return window.addEventListener("resize", o), function () {
      window.removeEventListener("resize", o);
    };
  }, []), e(s, r({
    $showBottomShadow: !d && (null == w ? void 0 : w.bottom),
    $showTopShadow: !a && (null == w ? void 0 : w.top)
  }, f, {
    children: e(m, r({
      ref: S,
      onScroll: g
    }, p, {
      children: h
    }))
  }));
};

export { u as ScrollShadow };