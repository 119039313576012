export var ApiResponseStatusCode;

(function (ApiResponseStatusCode) {
  ApiResponseStatusCode[ApiResponseStatusCode["BAD_REQUEST"] = 400] = "BAD_REQUEST";
  ApiResponseStatusCode[ApiResponseStatusCode["UNAUTHORIZED"] = 401] = "UNAUTHORIZED";
  ApiResponseStatusCode[ApiResponseStatusCode["FORBIDDEN"] = 403] = "FORBIDDEN";
  ApiResponseStatusCode[ApiResponseStatusCode["NOT_FOUND"] = 404] = "NOT_FOUND";
  ApiResponseStatusCode[ApiResponseStatusCode["UNPROCESSABLE_ENTITY_422"] = 422] = "UNPROCESSABLE_ENTITY_422";
  ApiResponseStatusCode[ApiResponseStatusCode["TOO_MANY_REQUESTS"] = 429] = "TOO_MANY_REQUESTS";
  ApiResponseStatusCode[ApiResponseStatusCode["UNPROCESSABLE_ENTITY_432"] = 432] = "UNPROCESSABLE_ENTITY_432";
  ApiResponseStatusCode[ApiResponseStatusCode["INTERNAL_SERVER_ERROR"] = 500] = "INTERNAL_SERVER_ERROR";
  ApiResponseStatusCode[ApiResponseStatusCode["RESULT_FAILED"] = 519] = "RESULT_FAILED";
})(ApiResponseStatusCode || (ApiResponseStatusCode = {}));

export var ApiResponseStatusText;

(function (ApiResponseStatusText) {
  ApiResponseStatusText["BadRequest"] = "Bad Request";
  ApiResponseStatusText["Forbidden"] = "Forbidden";
  ApiResponseStatusText["NotFound"] = "Not Found";
  ApiResponseStatusText["ServerError"] = "Server Error";
  ApiResponseStatusText["Unauthorized"] = "Unauthorized";
})(ApiResponseStatusText || (ApiResponseStatusText = {}));

export var ApiFailCode;

(function (ApiFailCode) {
  ApiFailCode["DATA_NOT_FOUND_ERROR"] = "DATA_NOT_FOUND_ERROR";
  ApiFailCode["DATA_PROCESSING_EXCEPTION"] = "DATA_PROCESSING_EXCEPTION";
  ApiFailCode["OPERATION_TERMINATED"] = "OPERATION_TERMINATED";
  ApiFailCode["PERSONAL_DATA_COMPARE_EXCEPTION"] = "PERSONAL_DATA_COMPARE_EXCEPTION";
  ApiFailCode["PIN_ATTEMPTS_EXCEEDED"] = "PIN_ATTEMPTS_EXCEEDED";
  ApiFailCode["REQUEST_EXCEPTION"] = "REQUEST_EXCEPTION";
  ApiFailCode["VALIDATION_EXCEPTION"] = "VALIDATION_EXCEPTION";
})(ApiFailCode || (ApiFailCode = {}));