import { __read as e, __assign as i } from "../../../../../_virtual/_tslib.js";
import { jsx as n } from "react/jsx-runtime";
import { memo as r, useState as t, useEffect as o } from "react";
import { Keyboard as d, Mousewheel as l, FreeMode as c, Virtual as a } from "swiper";
import { SwiperSlide as s } from "swiper/react";
import { StyledSwiperSlider as m, StyledSwiperSlideContent as p } from "./wheel-slider.styles.js";
import "swiper/css";
var u = r(function (r) {
  var u = r.data,
      f = r.onChange,
      x = r.formatData,
      v = r.value,
      h = r.onClick,
      I = e(t(null), 2),
      k = I[0],
      w = I[1];
  return o(function () {
    k && v !== k.activeIndex && k.slideTo(v);
  }, [k, v]), n(m, i({
    centeredSlides: !0,
    direction: "vertical",
    freeMode: {
      enabled: !0,
      sticky: !0
    },
    initialSlide: v,
    keyboard: !0,
    modules: [d, l, c, a],
    mousewheel: {
      forceToAxis: !0
    },
    slidesPerView: 4.23,
    spaceBetween: 0,
    speed: 200,
    virtual: !0,
    onClick: function (e, i) {
      h ? h(e.clickedIndex, e) : "number" == typeof e.clickedIndex && e.clickedIndex !== v && e.slideTo(e.clickedIndex);
    },
    onSlideChange: function (e) {
      "number" == typeof e.activeIndex && v !== e.activeIndex && f(e.activeIndex);
    },
    onSlidesLengthChange: function (e) {
      "number" == typeof e.activeIndex && e.activeIndex !== v && e.slideTo(v);
    },
    onSwiper: w
  }, {
    children: u.map(function (e, r) {
      return n(s, i({
        style: {
          height: 34
        },
        virtualIndex: r
      }, {
        children: n(p, {
          children: x ? x(e) : e
        })
      }), e);
    })
  }));
});
u.displayName = "WheelSlider";
export { u as WheelSlider };