import { Countries } from '@enums';
import belarus from '@images/flags/belarus.svg';
import russia from '@images/flags/russia.svg';
/**
 * Get country flag.
 *
 * @author Ruslan Kolesov
 * @function getCountryFlag
 * @category helpers
 * @param {string} countryCode country code.
 * @return {string} return country flag path.
 */

var getCountryFlag = function getCountryFlag(countryCode) {
  var _country$find;

  var country = [{
    countryCode: Countries.BELARUS,
    flag: belarus
  }, {
    countryCode: Countries.RUSSIA,
    flag: russia
  }];
  return ((_country$find = country.find(function (el) {
    return el.countryCode === countryCode;
  })) === null || _country$find === void 0 ? void 0 : _country$find.flag) || '';
};

export { getCountryFlag };