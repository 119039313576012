import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, Container, styled } from '@mui/material';
var StyledContainer = styled(Container)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  };
  return styles;
});
var StyledContentWrapper = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;

  var styles = _defineProperty({
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(2)
  }, theme.breakpoints.up('desktop'), {
    display: 'block',
    maxWidth: theme.typography.pxToRem(452),
    paddingTop: theme.spacing(4)
  });

  return styles;
}); // const StyledContent = styled(Box)<{
//   theme: DefaultTheme;
// }>(({ theme }) => {
//   const styles: OverridesStyleRules = {
//     overflow:      'hidden',
//     display:       'flex',
//     flexDirection: 'column',
//   };
//   return styles;
// });
// const StyledButton = styled(Button)(() => {
//   const styles: OverridesStyleRules = {
//     justifyContent: 'flex-start',
//   };
//   return styles;
// });
// const StyledBox = styled(Box)<{
//   theme: DefaultTheme;
// }>(({ theme }) => {
//   const styles: OverridesStyleRules = {
//     margin: theme.spacing(0, 1, 1),
//   };
//   return styles;
// });
// const StyledInfoBox = styled(Box)<{
//   theme: DefaultTheme;
// }>(({ theme }) => {
//   const styles: OverridesStyleRules = {
//     margin:    theme.spacing(5, 1),
//     textAlign: 'center',
//   };
//   return styles;
// });
// const StyledButtonWrapper = styled(Box)<{
//   theme: DefaultTheme;
//   $backgroundColor: ThemeColorKeys;
// }>(({ theme, $backgroundColor }) => {
//   const color = getHexColor(theme.palette, $backgroundColor);
//   const styles: OverridesStyleRules = {
//     padding:         theme.spacing(2.5, 0, 5),
//     backgroundColor: color,
//   };
//   return styles;
// });

export { // StyledBox,
// StyledButton,
// StyledButtonWrapper,
StyledContainer, // StyledContent,
// StyledContentWrapper,
// StyledInfoBox,
// StyledWrapper,
StyledContentWrapper };