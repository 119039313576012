import { __assign as r } from "../../../_virtual/_tslib.js";
import { jsx as t } from "react/jsx-runtime";
import { StyledSelectIcon as e } from "./autocomplete-drawer-field.styles.js";
import i from "../../../packages/pwa-ui/assets/images/arrow-down.image.svg.js";

var o = function (o) {
  return t(e, r({
    height: 10,
    src: i,
    width: 10
  }, o));
};

export { o as SelectIcon };