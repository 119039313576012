import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, styled } from '@mui/material';
import { colors } from '@themes';
var StyledAccountInfoWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    backgroundColor: colors.ghostWhite,
    borderTopLeftRadius: theme.typography.pxToRem(16),
    borderTopRightRadius: theme.typography.pxToRem(16),
    flex: 1,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2)
  }, theme.breakpoints.up('desktop'), {
    backgroundColor: 'unset',
    borderTopLeftRadius: 'unset',
    borderTopRightRadius: 'unset'
  });

  return styles;
});
export { StyledAccountInfoWrapper };