import { __rest as t, __assign as r } from "../../_virtual/_tslib.js";
import { jsx as i } from "react/jsx-runtime";
import { Typography as o } from "../typography/typography.component.js";
import { StyledChip as e } from "./action-chip.styles.js";

var p = function (p) {
  var a = p.title,
      n = t(p, ["title"]);
  return i(e, r({}, n, {
    children: i(o, r({
      maxWidth: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      variant: "body3",
      whiteSpace: "nowrap"
    }, {
      children: a
    }))
  }));
};

export { p as ActionChip };