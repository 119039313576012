import { pxToRem } from '@pwa/ui';
import { Box, Stack, styled } from '@mui/material';
var StyledWrapper = styled(Stack)(function (_ref) {
  var _theme$palette;

  var theme = _ref.theme;
  var styles = {
    '&:not(:last-child)::after': {
      backgroundColor: theme === null || theme === void 0 ? void 0 : (_theme$palette = theme.palette) === null || _theme$palette === void 0 ? void 0 : _theme$palette.lavenderSyrup.main,
      bottom: 0,
      content: "''",
      display: 'block',
      height: pxToRem(1),
      left: pxToRem(52),
      position: 'absolute',
      right: pxToRem(0)
    },
    height: 'unset',
    paddingBottom: theme.spacing(2),
    position: 'relative'
  };
  return styles;
});
var StyledImageWrapper = styled(Box)(function () {
  var styles = {
    alignItems: 'center',
    borderRadius: pxToRem(10),
    display: 'flex',
    flexShrink: 0,
    height: pxToRem(36),
    overflow: 'hidden',
    width: pxToRem(36)
  };
  return styles;
});
var StyledStack = styled(Stack)(function () {
  var styles = {
    flexGrow: 1,
    justifyContent: 'center',
    maxWidth: '100%',
    overflow: 'hidden'
  };
  return styles;
});
export { StyledImageWrapper, StyledStack, StyledWrapper };