import { styled as e, Stack as o, Box as r } from "@mui/material";
import "react";
import { getHexColor as t } from "../../helpers/colors/colors.helper.js";
import "../../enums/entities/entities.enum.js";
import "../../enums/keyboard/keyboard.enum.js";
import "../../enums/theme/theme.enum.js";
import "../../node_modules/@babel/runtime/helpers/typeof.js";
var n = e(o)(function (e) {
  var o = e.theme,
      r = e.$bgColor,
      n = e.$size,
      i = o.typography.pxToRem,
      a = {
    backgroundColor: t(o.palette, r),
    borderRadius: i(16),
    boxShadow: o.boxShadows.independence,
    boxSizing: "border-box",
    padding: "".concat(i(16), " ").concat(i(8), " ").concat(i(16), " ").concat(i(24))
  };
  if ("small" === n) a.padding = o.spacing(2, 1, 2, 1);
  return a;
}),
    i = e(r)(function (e) {
  var o = e.theme;
  return {
    alignItems: "center",
    backgroundColor: o.palette.lavenderSyrup.main,
    borderRadius: "50%",
    display: "flex",
    flexShrink: 0,
    height: o.typography.pxToRem(44),
    justifyContent: "center",
    overflow: "hidden",
    position: "relative",
    width: o.typography.pxToRem(44)
  };
});
export { n as StyledContainerBox, i as StyledIconWrapper };