import { __rest as e, __read as r, __spreadArray as o, __assign as n } from "../../../_virtual/_tslib.js";
import { jsxs as t, Fragment as i, jsx as l } from "react/jsx-runtime";
import { useState as a, useMemo as d, useCallback as c } from "react";
import { CircularProgress as u, MenuItem as m } from "@mui/material";
import { Typography as s } from "../../typography/typography.component.js";
import { StyledFormControl as h, StyledInputLabel as p, StyledSelectBase as f, StyledPlaceholder as v, StyleFromHelperWrapper as y, StyledFormHelperErrorText as b, StyledFormHelperText as g } from "./autocomplete-drawer-field.styles.js";
import { AutocompleteDrawer as w } from "../../autocomplete-drawer/autocomplete-drawer.component.js";
import { SelectIcon as C } from "./autocomplete-drawer-field.helpers.js";

function x(x) {
  var S = x.name,
      I = void 0 === S ? "AutocompleteDrawerField" : S,
      k = x.placeholder,
      z = x.color,
      F = void 0 === z ? "white" : z,
      L = x.fullWidth,
      j = x.drawerTitle,
      E = x.error,
      T = x.bottomLabel,
      V = x.onChange,
      W = x.label,
      M = x.items,
      P = x.value,
      D = x.renderValue,
      K = x.keyValue,
      N = x.keyId,
      $ = x.renderDrawerItem,
      A = x.searchNotFoundText,
      O = x.searchPlaceholder,
      _ = x.searchKeys,
      q = x.loading,
      B = x.loadingComponent,
      G = x.isErrorList,
      H = x.errorListComponent;
  x.active, x.deviceSize;
  var J = x.estimateSize,
      Q = x.isSearchEnable,
      R = x.isMobile,
      U = e(x, ["name", "placeholder", "color", "fullWidth", "drawerTitle", "error", "bottomLabel", "onChange", "label", "items", "value", "renderValue", "keyValue", "keyId", "renderDrawerItem", "searchNotFoundText", "searchPlaceholder", "searchKeys", "loading", "loadingComponent", "isErrorList", "errorListComponent", "active", "deviceSize", "estimateSize", "isSearchEnable", "isMobile"]),
      X = r(a(!1), 2),
      Y = X[0],
      Z = X[1],
      ee = d(function () {
    var e;
    return P && P[K] && M.length ? null === (e = o([], r(M), !1)) || void 0 === e ? void 0 : e.sort(function (e) {
      return P && (null == P ? void 0 : P[K]) === (null == e ? void 0 : e[K]) ? -1 : 0;
    }) : M;
  }, [M, K, P]),
      re = d(function () {
    return $;
  }, [$]),
      oe = d(function () {
    return _;
  }, [_]),
      ne = I ? "".concat(I, "-autocomplete-drawer-field") : "autocomplete-drawer-field",
      te = {
    errorId: ne && "".concat(ne, "-error-text"),
    htmlFor: ne,
    id: ne
  },
      ie = !!E,
      le = c(function () {
    Z(function () {
      return !0;
    });
  }, []),
      ae = c(function (e) {
    e && (null == V || V(e)), Z(function () {
      return !1;
    });
  }, [V]);
  return t(i, {
    children: [t(h, n({
      error: ie,
      fullWidth: L
    }, {
      children: [W && l(p, n({
        $color: F,
        htmlFor: te.htmlFor,
        shrink: !0
      }, {
        children: W
      })), l(f, n({
        IconComponent: q ? void 0 : C,
        MenuProps: {
          sx: {
            display: "none"
          }
        },
        "aria-describedby": ne,
        color: F,
        displayEmpty: !0,
        endAdornment: q && l(u, {
          disableShrink: !0,
          size: 20,
          sx: {
            marginLeft: -3.875
          }
        }),
        fullWidth: L,
        id: te.id,
        name: I,
        open: Y,
        renderValue: function (e) {
          if (!e) return l(v, n({
            $color: F
          }, {
            children: k
          }));
          var r = (null == D ? void 0 : D(P)) || (null == P ? void 0 : P[K]) || "";
          return l(s, n({
            color: "nightWizard",
            fontWeight: 500,
            overflow: "hidden",
            textOverflow: "ellipsis",
            variant: "body1"
          }, {
            children: l(i, {
              children: r
            })
          }));
        },
        value: (null == P ? void 0 : P[K]) || ""
      }, U, {
        onOpen: le
      }, {
        children: o([], r(M), !1).map(function (e) {
          return l(m, {
            value: e[K]
          }, N && e[N] || e[K]);
        })
      })), ie && "string" == typeof E && l(y, {
        children: l(b, n({
          id: te.errorId
        }, {
          children: E
        }))
      }), T && !ie ? l(y, {
        children: l(g, n({
          $color: F
        }, {
          children: T
        }))
      }) : null]
    })), l(w, {
      drawerTitle: j,
      error: G,
      errorComponent: H,
      estimateSize: J || 68,
      isMobile: R,
      isSearchEnable: Q,
      items: ee,
      keyValue: K,
      loading: q,
      loadingComponent: B,
      open: Y,
      renderItem: re,
      searchKeys: oe,
      searchNotFoundText: A,
      searchPlaceholder: O,
      value: P,
      onClose: ae
    })]
  });
}

export { x as AutocompleteDrawerField };