export var getInstantTransferAgreement = function getInstantTransferAgreement() {
  return {
    conditionsUrl: 'https://google.com',
    isAgreementReceived: true,
    selectedAccount: 'BY14BELB30140226000102R98004'
  };
};
export var getApiInstantTransferFormGet = function getApiInstantTransferFormGet() {
  return {
    contractUrl: 'https://www.belveb.by/documents/dogovor-okazaniya-uslugi-vnutristranovogo-mgnovennogo-platezha-cherez-smp/',
    countries: [{
      banks: [{
        bankCode: 'BLBBBY2XXXX',
        bankName: "ОАО 'Белинвестбанк'"
      }, {
        bankCode: 'BPSBBY2X',
        bankName: "ОАО 'Сбер Банк'"
      }],
      countryCode: 'BY',
      countryName: 'Беларусь',
      countryPhoneCode: '+375',
      currency: 'BYN',
      phoneNumberLength: 9
    }, {
      banks: [{
        bankCode: '044525974',
        bankName: 'Тинькофф Банк'
      }, {
        bankCode: '047501906',
        bankName: 'УРАЛПРОМБАНК'
      }],
      countryCode: 'RU',
      countryName: 'Россия',
      countryPhoneCode: '+7',
      currency: 'RUB',
      phoneNumberLength: 10
    }],
    minAmounts: [{
      amount: 1,
      currency: 'BYN'
    }, {
      amount: 30,
      currency: 'RUB'
    }],
    paymentPurpose: [{
      code: '40103',
      description: 'Переводы между физическими лицами'
    }, {
      code: '44507',
      description: 'Предоставление займов между физическими лицами'
    }, {
      code: '44508',
      description: 'Возврат займов между физическими лицами'
    }, {
      code: '44509',
      description: 'Проценты по займам между физическими лицами'
    }],
    tariffsUrl: 'https://www.belveb.by/documents/tarify-na-vypolnenie-operatsiy-i-okazanie-uslug-oao-bank-belveb/'
  };
};
export var getInstantTransferStartPost = function getInstantTransferStartPost() {
  return {
    account: 'BY85BELB30140928DW0010226000',
    bankName: 'Тинькофф Банк',
    exchangeRate: '3.51',
    fee: 2.15,
    feeCurrency: 'BYN',
    feeCurrencyOtherBank: 'BYN',
    feeOtherBank: 7.85,
    fio: 'Artem N***v',
    paymentPurposeName: 'Перевод между физическими лицами',
    recipientAmount: 1700,
    recipientCurrency: 'BYN',
    recipientPhone: '+375292557891',
    senderAmount: 59.67,
    senderCurrency: 'BYN',
    totalAmount: 69.67,
    totalCurrency: 'BYN',
    transactionId: 'f9f68a69-15fa-4ee5-93d8-a108f5b922ce'
  };
};
export var getInstantTransferConfirmation = function getInstantTransferConfirmation() {
  return {
    codeOperation: '2588',
    dataOperation: [{
      id: 1,
      opDetails: 'RhSXp3d0ttRjNWMHVFWUd1NUdMZnZxWDRwNitnUFZZRmx6NUVxWFhnSV'
    }],
    idOtp: 241121414,
    idTransaction: '12312313',
    messageForClient: 'На номер +37529***4580 отправлен код для подтверждения.',
    otp: true,
    pin: true
  };
};