import { createSelector } from 'reselect';

/**
 * Get pending.
 *
 * @author Mikhail Siutsou
 * @function getPending
 * @category Selectors
 * @subcategory Auth
 * @param {AppState} state state.
 * @return {boolean} pending.
 */
var getPending = function getPending(state) {
  return state.auth.pending;
};
/**
 * Get user.
 *
 * @author Mikhail Siutsou
 * @function getUser
 * @category Selectors
 * @subcategory Auth
 * @param {AppState} state state.
 * @return {User | null} current user.
 */


var getUser = function getUser(state) {
  return state.auth.user;
};
/**
 * Get userId.
 *
 * @author Yury Kuntsou
 * @function getUser
 * @category Selectors
 * @subcategory Auth
 * @param {AppState} state state.
 * @return {User | null} current user.
 */


var getUserId = function getUserId(state) {
  var _state$auth$user;

  return ((_state$auth$user = state.auth.user) === null || _state$auth$user === void 0 ? void 0 : _state$auth$user.userId) || null;
};
/**
 * Get user has accounts.
 *
 * @author Christina kashevar
 * @function getUserHasAccounts
 * @category Selectors
 * @subcategory Auth
 * @param {AppState} state state.
 * @return {UserAddInfo['hasAccounts'] | null} current user has accounts.
 */


var getUserHasAccounts = function getUserHasAccounts(state) {
  return state.auth.hasAccounts;
};
/**
 * Get user has current accounts.
 *
 * @author Christina kashevar
 * @function getUserHasCurrentAccounts
 * @category Selectors
 * @subcategory Auth
 * @param {AppState} state state.
 * @return {UserAddInfo['hasCurrentAccounts'] | null} current user has current accounts.
 */


var getUserHasCurrentAccounts = function getUserHasCurrentAccounts(state) {
  return state.auth.hasCurrentAccounts;
};
/**
 * Get user has package.
 *
 * @author Christina kashevar
 * @function getUserHasPackage
 * @category Selectors
 * @subcategory Auth
 * @param {AppState} state state.
 * @return {UserAddInfo['hasPackage'] | null} current user has package.
 */


var getUserHasPackage = function getUserHasPackage(state) {
  return state.auth.hasPackage;
};
/**
 * Get user has package.
 *
 * @author Christina kashevar
 * @function getUserIsAllowedNewPackage
 * @category Selectors
 * @subcategory Auth
 * @param {AppState} state state.
 * @return {UserAddInfo['hasPackage'] | null} current user has package.
 */


var getUserIsAllowedNewPackage = function getUserIsAllowedNewPackage(state) {
  return state.auth.isAllowedNewPackage;
};
/**
 * Get error.
 *
 * @author Mikhail Siutsou
 * @function getError
 * @category Selectors
 * @subcategory Auth
 * @param {AppState} state state.
 * @return {string | null} error.
 */


var getError = function getError(state) {
  return state.auth.error;
};

export var getUserSelector = createSelector(getUser, function (user) {
  return user;
});
export var getUserIdSelector = createSelector(getUserId, function (userId) {
  return userId;
});
export var getUserHasAccountsSelector = createSelector(getUserHasAccounts, function (hasAccounts) {
  return hasAccounts;
});
export var getUserHasCurrentAccountsSelector = createSelector(getUserHasCurrentAccounts, function (hasCurrentAccounts) {
  return hasCurrentAccounts;
});
export var getUserHasPackageSelector = createSelector(getUserHasPackage, function (hasPackage) {
  return hasPackage;
});
export var getUserIsAllowedNewPackageSelector = createSelector(getUserIsAllowedNewPackage, function (isAllowedNewPackage) {
  return isAllowedNewPackage;
});
export var getPendingSelector = createSelector(getPending, function (pending) {
  return pending;
});
export var getErrorSelector = createSelector(getError, function (error) {
  return error;
});