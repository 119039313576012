import { __rest as r, __assign as e } from "../../_virtual/_tslib.js";
import { jsx as t, Fragment as a } from "react/jsx-runtime";
import * as l from "react";
import { StyledBox as i } from "./tab.styles.js";
var o = l.forwardRef(function (l, o) {
  var u = l.children,
      f = l.tabValue,
      m = l.tabsValue,
      n = l.fullHeight,
      s = r(l, ["children", "tabValue", "tabsValue", "fullHeight"]);
  return t(i, e({
    hidden: f !== m
  }, s, {
    $fullHeight: n
  }, {
    children: f === m && t(a, {
      children: u
    })
  }));
});
o.displayName = "TabPanel";
export { o as TabPanel };