export var TemplateWatchedStatus;

(function (TemplateWatchedStatus) {
  TemplateWatchedStatus["ACCEPTED"] = "T";
  TemplateWatchedStatus["NOT_READ"] = "N";
  TemplateWatchedStatus["VIEWED"] = "V";
})(TemplateWatchedStatus || (TemplateWatchedStatus = {}));

export var WidgetStatus;

(function (WidgetStatus) {
  WidgetStatus["ACCEPTED"] = "T";
  WidgetStatus["NOT_READ"] = "N";
  WidgetStatus["VIEWED"] = "V";
})(WidgetStatus || (WidgetStatus = {}));

export var LinkType;

(function (LinkType) {
  LinkType["CLOSE"] = "close";
  LinkType["DEEP_LINK"] = "deeplink";
  LinkType["URL"] = "url";
})(LinkType || (LinkType = {}));