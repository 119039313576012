import { Box, Container, styled } from '@mui/material';
var StyledPublicWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme,
      $bgColor = _ref.$bgColor;
  var height = theme.convertToDVH(100);
  var styles = {
    alignItems: 'center',
    backgroundColor: "".concat($bgColor || ''),
    display: 'flex',
    maxWidth: '100%',
    minHeight: height
  };
  return styles;
});
var StyledMain = styled(Box)(function (_ref2) {
  var theme = _ref2.theme,
      $inline = _ref2.$inline;
  var styles = {
    flex: '1',
    paddingBottom: !$inline && theme.spacing(4),
    paddingTop: !$inline && theme.spacing(4)
  };
  return styles;
});
var StyledContainer = styled(Container)(function (_ref3) {
  var theme = _ref3.theme,
      $maxWidth = _ref3.$maxWidth;
  var styles = {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    maxWidth: $maxWidth && theme.typography.pxToRem($maxWidth)
  };
  return styles;
});
export { StyledContainer, StyledMain, StyledPublicWrapper };