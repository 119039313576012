import { createSelector } from 'reselect';

/**
 * Get theme name.
 *
 * @author Yulian Kavaliou
 * @function getTheme
 * @category Selectors
 * @subcategory Theme
 * @param {AppState} state state.
 * @return {Theme} theme.
 */
var getTheme = function getTheme(state) {
  return state.theme;
};

export var getThemeSelector = createSelector(getTheme, function (theme) {
  return theme;
});