import { styled as t, FormControlLabel as o, formControlLabelClasses as i, switchClasses as a, Switch as e, alpha as c } from "@mui/material";
import { Button as r } from "../button/button.component.js";
var n = t(r)(function (t) {
  t.theme;
  return {
    height: "unset",
    padding: "unset"
  };
}),
    l = t(o)(function (t) {
  var o,
      e,
      c = t.theme;
  return (o = {
    flex: 1,
    margin: "unset",
    padding: c.spacing(2.25, 2)
  })["& .".concat(i.label)] = {
    flex: "1 1"
  }, o["&.".concat(i.labelPlacementStart)] = ((e = {})["& .".concat(a.root)] = {
    marginRight: c.spacing(-1)
  }, e["& .".concat(i.label)] = {
    paddingRight: c.spacing(1),
    textAlign: "left"
  }, e), o;
}),
    h = t(e)(function (t) {
  var o = t.theme,
      i = o.typography.pxToRem;
  return {
    "& .MuiSwitch-switchBase": {
      "& + .MuiSwitch-track": {
        backgroundColor: o.colors.estateViolet,
        borderRadius: i(22),
        opacity: 1
      },
      "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: o.colors.panPurple
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: .5
      },
      "&:hover": {
        backgroundColor: c(o.colors.hawaiianPassion, o.palette.action.hoverOpacity)
      },
      color: o.colors.white,
      left: i(8),
      padding: i(2),
      top: i(8)
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      "&:hover": {
        backgroundColor: c(o.colors.white, o.palette.action.hoverOpacity)
      },
      color: o.colors.white,
      left: i(4),
      top: i(8)
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none"
    },
    height: i(40),
    padding: o.spacing(1),
    transition: o.transitions.create(["background-color"]),
    width: i(56)
  };
});
export { n as StyledFieldButton, l as StyledFieldLabel, h as StyledSwitch };