import { pxToRem } from '@pwa/ui';
import { Box, Stack, styled } from '@mui/material';
var StyledWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    padding: theme.spacing(3, 0, 0.5)
  };
  return styles;
});
var StyledSection = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    backgroundColor: theme.palette.white.main,
    borderRadius: pxToRem(16),
    padding: theme.spacing(2)
  };
  return styles;
});
var StyledBox = styled(Stack)(function (_ref3) {
  var _theme$colors;

  var theme = _ref3.theme;
  var styles = {
    borderTop: "".concat(pxToRem(1), " solid ").concat(theme === null || theme === void 0 ? void 0 : (_theme$colors = theme.colors) === null || _theme$colors === void 0 ? void 0 : _theme$colors.lavenderSyrup),
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2)
  };
  return styles;
});
export { StyledBox, StyledSection, StyledWrapper };