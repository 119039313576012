import { __assign as i } from "../../_virtual/_tslib.js";
import { jsxs as r, jsx as e } from "react/jsx-runtime";
import { Stack as o, Box as t } from "@mui/material";
import { Icon as n } from "../icon/icon.component.js";
import { IconButton as c } from "../icon-button/icon-button.component.js";
import { Typography as a } from "../typography/typography.component.js";
import { StyledContainerBox as s, StyledIconWrapper as l } from "./inline-error.styles.js";
import m from "../../packages/pwa-ui/assets/images/icons/attention.image.svg.js";
import p from "../../packages/pwa-ui/assets/images/refresh-circle.image.svg.js";

var h = function (h) {
  var g = h.icon,
      d = h.title,
      f = h.onClick,
      w = h.color,
      u = void 0 === w ? "white" : w,
      y = h.minHeight,
      j = void 0 === y ? 104 : y,
      v = h.size,
      b = !["white", "carteBlanche", "lavenderSyrup", "alpineGoat", "whiteSolid"].includes(u);
  return r(s, i({
    $bgColor: u,
    $size: v,
    alignItems: "center",
    direction: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    minHeight: j,
    spacing: "small" === v ? 1 : 2
  }, {
    children: [r(o, i({
      alignItems: "center",
      direction: "row",
      flexWrap: "nowrap",
      spacing: "small" === v ? 1 : 3.5
    }, {
      children: [e(l, {
        children: g ? "string" == typeof g ? e(n, {
          color: "white",
          height: 20,
          src: g,
          width: 20
        }) : g : e(n, {
          color: "white",
          height: 23,
          src: m,
          width: 6
        })
      }), e(t, {
        children: e(a, i({
          color: "bayFog",
          contrast: b,
          variant: "body2"
        }, {
          children: d
        }))
      })]
    })), f && e(t, {
      children: e(c, i({
        onClick: f
      }, {
        children: e(n, {
          height: 28,
          src: p,
          width: 28
        })
      }))
    })]
  }));
};

export { h as InlineError };