// eslint-disable-next-line import/no-named-as-default
import { initReactI18next } from 'react-i18next'; // eslint-disable-next-line import/no-named-as-default

import i18n from 'i18next';
import en from './en.localization.json';
import ru from './ru.localization.json';

var getTranslateConfig = function getTranslateConfig() {
  return {
    debug: false,
    fallbackLng: 'ru',
    lng: 'ru',
    resources: {
      en: en,
      ru: ru
    }
  };
}; // eslint-disable-next-line import/no-named-as-default-member


void i18n.use(initReactI18next).init(getTranslateConfig());
export { i18n };