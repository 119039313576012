export var TransactionResults;

(function (TransactionResults) {
  TransactionResults["FAIL"] = "FAIL";
  TransactionResults["SUCCESS"] = "SUCCESS";
})(TransactionResults || (TransactionResults = {}));

export var SuccessErrorPageTypes;

(function (SuccessErrorPageTypes) {
  SuccessErrorPageTypes["INFO"] = "info";
})(SuccessErrorPageTypes || (SuccessErrorPageTypes = {}));

export var MAX_NAME_FIELD_LENGTH = 24;
export var MAX_CODE_FIELD_LENGTH = 20;
export var AntifraudAuthStatus;

(function (AntifraudAuthStatus) {
  AntifraudAuthStatus["IS_AUTHORIZED"] = "IS_AUTHORIZED";
  AntifraudAuthStatus["IS_GUEST"] = "IS_GUEST";
})(AntifraudAuthStatus || (AntifraudAuthStatus = {}));