import { __rest as r, __assign as t } from "../../_virtual/_tslib.js";
import { jsxs as e, jsx as i } from "react/jsx-runtime";
import { Stack as o, Box as a } from "@mui/material";
import { Icon as c } from "../icon/icon.component.js";
import { RemoteImage as n } from "../remote-image/remote-image.view.js";
import { Typography as m } from "../typography/typography.component.js";
import "../../themes/box-shadows/boxShadows.js";
import { colors as s } from "../../themes/colors/colors.js";
import "../../themes/dark.theme.js";
import "../../themes/default-theme/default-theme.js";
import "../../themes/gradients/gradients.js";
import "../../themes/light.theme.js";
import { StyledCard as h, StyledCardContent as d, StyledImageWrapper as p, StyledBox as l } from "./bank-medium-card.styles.js";
import g from "../../packages/pwa-ui/assets/images/arrow-right.image.svg.js";

var u = function (u) {
  var f = u.image,
      y = u.cardNumber,
      j = u.cardName,
      w = u.cardExpiry,
      b = u.currency,
      v = u.isWithArrow,
      x = u.cardType,
      W = u.colorStatus,
      S = u.cardStatus,
      k = r(u, ["image", "cardNumber", "cardName", "cardExpiry", "currency", "isWithArrow", "cardType", "colorStatus", "cardStatus"]);
  return e(h, t({}, k, {
    children: [e(d, t({
      $isWithArrow: v,
      component: "span"
    }, {
      children: [i(o, t({
        direction: "row",
        justifyContent: "space-between"
      }, {
        children: e(o, t({
          direction: "row",
          spacing: 1.5
        }, {
          children: [i(p, {
            children: i(n, {
              borderRadius: 5,
              height: 40,
              src: f,
              width: 66
            })
          }), e(o, t({
            spacing: 1
          }, {
            children: [i(m, t({
              color: "nightWizard",
              fontWeight: 500
            }, {
              children: j || x
            })), y && w && i(m, t({
              color: "abandonedSpaceship",
              variant: "caption1"
            }, {
              children: "".concat(y, " | ").concat(w)
            })), S && i(l, t({
              $backgroundColor: W || s.brightGray
            }, {
              children: i(m, t({
                color: "white",
                fontWeight: 500,
                variant: "caption2"
              }, {
                children: S
              }))
            }))]
          }))]
        }))
      })), i(l, {
        children: i(m, t({
          color: "nightWizard",
          fontWeight: 500,
          variant: "caption2"
        }, {
          children: b
        }))
      })]
    })), v && i(a, t({
      alignSelf: "flex-start",
      component: "span",
      display: "flex",
      ml: 2
    }, {
      children: i(c, {
        color: "bayFog",
        height: 40,
        src: g,
        width: 8
      })
    }))]
  }));
};

export { u as BankMediumCard };