import { styled as t, Box as o, alpha as e } from "@mui/material";
import "../../themes/box-shadows/boxShadows.js";
import { colors as i } from "../../themes/colors/colors.js";
import "../../themes/dark.theme.js";
import { pxToRem as r } from "../../themes/default-theme/default-theme.js";
import "../../themes/gradients/gradients.js";
import "../../themes/light.theme.js";
var a = t(o)(function (t) {
  var o,
      a = t.theme,
      n = t.$showTopShadow,
      s = t.$showBottomShadow;
  return (o = {})["&:before, &:after"] = {
    borderRadius: "10px",
    content: "''",
    height: 1,
    left: "50%",
    opacity: 0,
    pointerEvents: "none",
    position: "absolute",
    transform: "translate3d(-50%,0,0)",
    transition: a.transitions.create(["opacity"]),
    width: "calc(100% - 20px)",
    zIndex: 1
  }, o["&:after"] = {
    bottom: -1,
    boxShadow: "0 ".concat(r(-5), " ").concat(r(20), " ").concat(e(i.nightWizard, 1)),
    opacity: s ? 1 : void 0
  }, o["&:before"] = {
    boxShadow: "0 ".concat(r(5), " ").concat(r(20), " ").concat(e(i.nightWizard, 1)),
    opacity: n ? 1 : void 0,
    top: -1
  }, o.display = "flex", o.flex = 1, o.flexDirection = "column", o.maxHeight = "100%", o.overflow = "hidden", o.position = "relative", o.width = "100%", o;
}),
    n = t(o)(function () {
  return {
    height: "100%",
    overflow: "hidden",
    overflowY: "auto",
    position: "relative",
    width: "100%"
  };
});
export { a as ScrollContainer, n as StyledContentWrapper };