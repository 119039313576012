import { Box, Container, styled } from '@mui/material';
var StyledPrivateWrapper = styled(Box)(function (_ref) {
  var $bgColor = _ref.$bgColor,
      theme = _ref.theme;
  var height = theme.convertToDVH(100);
  var styles = {
    backgroundColor: "".concat($bgColor || ''),
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    minHeight: height,
    overflow: 'hidden'
  };
  return styles;
});
var StyledMain = styled(Box)(function (_ref2) {
  var theme = _ref2.theme,
      $inline = _ref2.$inline;
  var styles = {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingBottom: !$inline && theme.spacing(5),
    paddingTop: !$inline && theme.spacing(2.5)
  };
  return styles;
});
var StyledContainer = styled(Container)(function (_ref3) {
  var theme = _ref3.theme,
      $maxWidth = _ref3.$maxWidth;
  var styles = {
    display: 'flex',
    flex: 1,
    flexDirection: 'column' // [theme.breakpoints.up('tablet')]: {
    //   maxWidth: theme.breakpoints.values.tablet,
    // },
    // [theme.breakpoints.up('desktop')]: {
    //   maxWidth: theme.breakpoints.values.largeDesktop,
    // },

  };
  return styles;
});
export { StyledContainer, StyledMain, StyledPrivateWrapper };