import { styled as e, Box as t } from "@mui/material";
import "../../../../themes/box-shadows/boxShadows.js";
import "../../../../themes/dark.theme.js";
import { pxToRem as r } from "../../../../themes/default-theme/default-theme.js";
import "../../../../themes/gradients/gradients.js";
import "../../../../themes/light.theme.js";
var i = e(t)(function (e) {
  var t = e.theme;
  return {
    backgroundColor: t.colors.whiteSolid,
    borderRadius: r(12),
    display: "inline-flex",
    marginTop: t.spacing(.25),
    padding: t.spacing(.25, 1)
  };
});
export { i as StyledInfo };