import { dispatch } from '@store';
import { actions } from '@store/grids';

/**
 * Grids service.
 *
 * @author Ihar Kazlouski
 * @const GridsService
 * @category Components
 * @subcategory Grids
 */
var GridsService = {
  /**
   * Call grid api.
   *
   * @author Mikhail Siutsou
   * @param {string} gridId gridId.
   * @param {() => Promise<AxiosResponse<T | R> | ApiError> | Promise<T>} api grid api.
   * @param {((request: R) => T) | undefined} transformResponse grid transformer.
   * @return {Promise<AxiosResponse<T | R> | ApiError> | Promise<T>} data promise.
   */
  api: function api(gridId, _api, transformResponse, pagination, search) {
    var asyncDispatch = dispatch;
    return asyncDispatch(actions.gridApiFetch({
      api: _api,
      gridId: gridId,
      pagination: pagination,
      search: search,
      transformResponse: transformResponse
    }));
  },

  /**
   * Close grid.
   *
   * @author Ihar Kazlouski
   * @param {string} gridId gridId.
   * @return {void}
   */
  close: function close(gridId) {
    dispatch(actions.gridCloseRequest({
      gridId: gridId
    }));
  },

  /**
   * Close all grids.
   *
   * @author Ihar Kazlouski
   * @param {string} gridId gridId.
   * @return {void}
   */
  closeAll: function closeAll() {
    dispatch(actions.closeAllGridsRequest());
  },

  /**
   * Init grid.
   *
   * @author Ihar Kazlouski
   * @param {string} gridId gridId.
   * @param {array} data grid data.
   * @param {boolean} isLoading is grid loaded.
   * @param {boolean} isError fetch error.
   * @return {void}
   */
  init: function init(gridId, data, isLoading, isError, pagination, search) {
    dispatch(actions.gridInitRequest({
      data: data,
      gridId: gridId,
      isError: isError,
      isLoading: isLoading,
      pagination: pagination,
      search: search
    }));
  },

  /**
   * Update grid.
   *
   * @author Ihar Kazlouski
   * @param {string} gridId gridId.
   * @param {array} data grid data.
   * @param {() => Promise<AxiosResponse<T | R> | ApiError> | Promise<T>} api grid api.
   * @param {((request: R) => T) | undefined} transformResponse grid transformer.
   * @return {Promise<AxiosResponse<T | R> | ApiError> | Promise<T>} data promise.
   */
  update: function update(gridId, api, transformResponse, pagination, search) {
    var asyncDispatch = dispatch;
    return asyncDispatch(actions.gridApiUpdateFetch({
      api: api,
      gridId: gridId,
      pagination: pagination,
      search: search,
      transformResponse: transformResponse
    }));
  }
};
export { GridsService };