import { useCallback, useEffect } from 'react';
import { AccountsService, DepositService } from '@services';
import { useApi } from '../use-api';

/**
 * Account types hook.
 *
 * @author Christina Kashevar
 * @function useAccountTypes
 * @category hook
 * @return {AccountTypes<AccountListResponse>} return account types.
 */
export var useAccountTypes = function useAccountTypes(currency) {
  var _useApi = useApi({
    api: DepositService.apiDepositAccountTypesGet
  }),
      accountTypes = _useApi.data,
      updateAccountTypes = _useApi.update;

  var _useApi2 = useApi({
    api: AccountsService.apiAccountsGet,
    deferred: true,
    params: {
      filter: [{
        accountCurrency: currency
      }]
    }
  }),
      data = _useApi2.data,
      error = _useApi2.error,
      loading = _useApi2.loading,
      updateAccounts = _useApi2.update;

  useEffect(function () {
    if (accountTypes !== null && accountTypes !== void 0 && accountTypes.accountTypes) {
      void updateAccounts({
        params: {
          filter: "accountCurrency:".concat(currency, ";accountType:").concat(accountTypes.accountTypes)
        }
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [accountTypes === null || accountTypes === void 0 ? void 0 : accountTypes.accountTypes]);
  var update = useCallback(function () {
    if (!(accountTypes !== null && accountTypes !== void 0 && accountTypes.accountTypes)) {
      void updateAccountTypes();
    } else {
      void updateAccounts({
        params: {
          filter: "accountCurrency:".concat(currency, ";accountType:").concat(accountTypes.accountTypes)
        }
      });
    }
  }, [accountTypes === null || accountTypes === void 0 ? void 0 : accountTypes.accountTypes, currency, updateAccountTypes, updateAccounts]);
  return {
    data: data,
    error: error,
    loading: loading,
    update: update
  };
};