import { __rest as e, __read as t, __assign as o } from "../../../_virtual/_tslib.js";
import { jsxs as r, jsx as n } from "react/jsx-runtime";
import { useState as a, useRef as i, useMemo as m, useCallback as s, memo as l } from "react";
import d from "../../../node_modules/date-fns/esm/format/index.js";
import u from "../../../node_modules/date-fns/esm/formatISO/index.js";
import { Container as p, Box as f } from "@mui/material";
import "../../../enums/entities/entities.enum.js";
import "../../../enums/keyboard/keyboard.enum.js";
import "../../../enums/theme/theme.enum.js";
import { getISODate as c, convertMomentDateFormat as h } from "../../../helpers/date/date.helpers.js";
import "../../../node_modules/@babel/runtime/helpers/typeof.js";
import { Button as b } from "../../button/button.component.js";
import { Typography as v } from "../../typography/typography.component.js";
import "../../../themes/box-shadows/boxShadows.js";
import "../../../themes/dark.theme.js";
import { pxToRem as j } from "../../../themes/default-theme/default-theme.js";
import "../../../themes/gradients/gradients.js";
import "../../../themes/light.theme.js";
import { StyledWrapper as x, StyledDrawer as y, DrawerContainer as g, Puller as w, StyledTitle as D } from "./calendar-field.styles.js";
import { TextField as S } from "../text-field/text-field.component.js";
import { CalendarWheel as T } from "./components/calendar-wheel/calendar-wheel.component.js";
import _ from "../../../node_modules/date-fns/esm/locale/ru/index.js";
import k from "../../../node_modules/date-fns/esm/locale/en-IN/index.js";

var C = l(T),
    M = function (l) {
  var T = l.name,
      M = l.drawerTitle,
      F = void 0 === M ? "Specify date" : M,
      P = l.buttonText,
      R = void 0 === P ? "Set date" : P,
      W = l.min,
      B = l.max,
      I = l.locale,
      O = void 0 === I ? "en" : I;
  l.active;

  var Y = l.value,
      H = l.onChange,
      $ = l.dateFormat,
      z = void 0 === $ ? "yyyy-MM-dd'T'HH:mm:ss.SSSxxx" : $,
      E = l.disableDays,
      L = l.disableMonths,
      N = l.disableYears,
      q = l.valueFormat,
      A = e(l, ["name", "drawerTitle", "buttonText", "min", "max", "locale", "active", "value", "onChange", "dateFormat", "disableDays", "disableMonths", "disableYears", "valueFormat"]),
      G = t(a(!1), 2),
      J = G[0],
      K = G[1],
      Q = q ? c(Y, h(q)) : Y,
      U = t(a(Q || ""), 2),
      V = U[0],
      X = U[1],
      Z = i(null),
      ee = m(function () {
    return "ru" === O ? _ : k;
  }, [O]),
      te = function (e) {
    var t;
    null === (t = Z.current) || void 0 === t || t.focus(), K(!1);
  },
      oe = s(function () {
    if (H) {
      var e = q ? q && d(new Date(V), h(q)) : V;
      H(e);
    }

    te();
  }, [V, H, q]),
      re = s(function (e) {
    e.toISOString() !== V && X(u(e));
  }, [V]);

  return r(x, o({
    $filled: Boolean(Y),
    $fullWidth: Boolean(A.fullWidth)
  }, {
    children: [n(S, o({
      inputRef: Z,
      onClick: function (e) {
        e.preventDefault(), K(function (e) {
          return !e;
        });
      }
    }, A, {
      name: T,
      readOnly: !0,
      value: d(Q ? new Date(Q) : new Date(), z, {
        locale: ee
      })
    })), n(y, o({
      ModalProps: {
        BackdropProps: {
          invisible: !0
        },
        disableEnforceFocus: !0
      },
      PaperProps: {
        sx: {
          borderTopLeftRadius: j(24),
          borderTopRightRadius: j(24)
        }
      },
      anchor: "bottom",
      open: J || !1,
      onClose: te
    }, {
      children: r(g, {
        children: [n(w, {}), F && n(D, {
          children: n(v, o({
            fontWeight: 500,
            variant: "title3"
          }, {
            children: F
          }))
        }), r(p, {
          children: [n(f, o({
            mt: 2.5
          }, {
            children: n(C, {
              disableDays: E,
              disableMonths: L,
              disableYears: N,
              locale: ee,
              maxDate: B,
              minDate: W,
              value: Q,
              onChange: re
            })
          })), n(f, o({
            mt: 7.5
          }, {
            children: n(b, o({
              center: !0,
              fullWidth: !0,
              indent: !0,
              size: "large",
              variant: "contained",
              onClick: oe
            }, {
              children: R
            }))
          }))]
        })]
      })
    }))]
  }));
};

export { M as CalendarField };