import { __read as e, __spreadArray as t, __assign as o } from "../../_virtual/_tslib.js";
import { jsx as n, jsxs as r, Fragment as i } from "react/jsx-runtime";
import { useState as l, useEffect as a, useMemo as s, useCallback as d } from "react";
import c from "../../node_modules/lodash.debounce/index.js";
import { matchSorter as m } from "match-sorter";
import { Box as u, Grid as h } from "@mui/material";
import { ButtonLink as p } from "../button-link/button-link.component.js";
import { HighlightedText as f } from "../highlighted-text/highlighted-text.component.js";
import { Icon as g } from "../icon/icon.component.js";
import { IconButton as v } from "../icon-button/icon-button.component.js";
import { ListVirtualizer as b } from "../list-virtualizer/list-virtualizer.component.js";
import { Typography as x } from "../typography/typography.component.js";
import "../../themes/box-shadows/boxShadows.js";
import "../../themes/dark.theme.js";
import { pxToRem as j } from "../../themes/default-theme/default-theme.js";
import "../../themes/gradients/gradients.js";
import "../../themes/light.theme.js";
import { DrawerContainer as C, Puller as y, StyledTitle as k, StyledDrawerListWrapper as w, StyledFilteredListContainer as S, StyledSearchNotFoundContainer as W, StyledDrawer as N, StyledDesktopDrawer as T } from "./autocomplete-drawer.styles.js";
import z from "../../packages/pwa-ui/assets/images/icons/close.image.svg.js";
import { TextField as A } from "../form/text-field/text-field.component.js";
import { SearchIcon as I } from "./autocomplete-drawer.helpers.js";

function L(L) {
  var P = L.open,
      R = L.items,
      M = L.keyValue,
      _ = L.drawerTitle,
      E = L.renderItem,
      F = L.onClose,
      G = L.searchNotFoundText,
      O = void 0 === G ? "Not found items" : G,
      K = L.searchPlaceholder,
      V = L.searchKeys,
      q = L.loading,
      B = L.loadingComponent,
      D = L.error,
      H = L.errorComponent,
      J = L.estimateSize,
      Q = L.value,
      U = L.isSearchEnable,
      X = void 0 === U || U,
      Y = L.isMobile,
      Z = e(l(""), 2),
      $ = Z[0],
      ee = Z[1],
      te = e(l(R), 2),
      oe = te[0],
      ne = te[1],
      re = e(l(oe), 2),
      ie = re[0],
      le = re[1],
      ae = Y ? N : T;
  a(function () {
    ne(function () {
      return R;
    });
  }, [R]), a(function () {
    le(function () {
      return oe;
    });
  }, [oe]);

  var se = s(function () {
    return V ? t([], e(V), !1).map(function (e) {
      return {
        key: e,
        threshold: m.rankings.CONTAINS
      };
    }) : void 0;
  }, [V]),
      de = function (e) {
    F(e), ee(""), le(function () {
      return oe;
    });
  },
      ce = d(c(function (e) {
    !function (e) {
      if (e) {
        var t = e.split(" ");
        le(function () {
          return t.reduceRight(function (e, t) {
            return m(e, t, {
              keys: se || [{
                key: M,
                threshold: m.rankings.CONTAINS
              }],
              sorter: function (e) {
                return e;
              }
            });
          }, oe);
        });
      } else le(function () {
        return oe;
      });
    }(e);
  }, 1e3), [oe]),
      me = $ && $.trim().length > 1 ? $.toLocaleLowerCase().split(" ") : void 0;

  return n(ae, o({
    PaperProps: {
      sx: {
        borderTopLeftRadius: j(24),
        borderTopRightRadius: j(24),
        overflow: "hidden",
        width: Y ? "unset" : j(420)
      }
    },
    anchor: "bottom",
    open: P,
    onClose: function () {
      return de();
    }
  }, {
    children: r(C, {
      children: [Y && n(y, {}), _ && n(i, {
        children: Y ? n(k, o({
          isMobile: Y
        }, {
          children: n(x, o({
            fontWeight: 500,
            variant: "title3"
          }, {
            children: _
          }))
        })) : n(u, o({
          flex: "0 0"
        }, {
          children: r(h, o({
            alignItems: "center",
            container: !0,
            flexWrap: "nowrap",
            justifyContent: "space-between",
            p: 3
          }, {
            children: [n(h, o({
              item: !0
            }, {
              children: n(k, o({
                isMobile: Y
              }, {
                children: n(x, o({
                  fontWeight: 500,
                  variant: "title3"
                }, {
                  children: _
                }))
              }))
            })), n(h, o({
              item: !0
            }, {
              children: n(v, o({
                size: "small",
                onClick: function () {
                  return de();
                }
              }, {
                children: n(g, {
                  color: "abandonedSpaceship",
                  height: 22,
                  src: z,
                  width: 22
                })
              }))
            }))]
          }))
        }))
      }), r(u, {
        children: [X && n(u, o({
          px: 2
        }, {
          children: n(A, {
            clearable: !!$,
            color: "alpineGoat",
            disabled: q || D,
            fullWidth: !0,
            placeholder: K,
            startAdornment: n(I, {}),
            value: $,
            onChange: function (e) {
              var t;
              if ("clear" === e.type) return ee(function () {
                return "";
              }), void le(function () {
                return oe;
              });
              ee(function () {
                return e.target.value;
              });
              var o = null === (t = e.target.value) || void 0 === t ? void 0 : t.trim();
              o ? o.length > 1 && ce(o) : ce();
            }
          })
        })), n(w, {
          children: n(b, o({
            ParentComponent: S,
            emptyListComponent: n(W, {
              children: n(x, o({
                color: "bayFog",
                textAlign: "center",
                variant: "body1"
              }, {
                children: O
              }))
            }),
            error: D,
            errorComponent: H,
            estimateSize: function () {
              return J;
            },
            list: ie,
            loading: q,
            loadingComponent: B
          }, {
            children: function (e) {
              var t, o;
              if (E) return E({
                highlightWords: me,
                index: e.index,
                item: ie[e.index],
                onClose: de,
                selected: Q
              });
              var r = String(null === (t = ie[e.index]) || void 0 === t ? void 0 : t[M]) || void 0;
              return n(p, {
                color: (null == Q ? void 0 : Q[M]) === (null === (o = ie[e.index]) || void 0 === o ? void 0 : o[M]) ? "alpineGoat" : void 0,
                disableElevation: !0,
                fullWidth: !0,
                title: me ? n(f, {
                  highlightWords: me,
                  text: r
                }) : r,
                onClick: function () {
                  de(ie[e.index]);
                }
              });
            }
          }))
        })]
      })]
    })
  }));
}

export { L as AutocompleteDrawer };