import { styled as o, Box as t, Button as e, alpha as r } from "@mui/material";
import { Typography as i } from "../../../typography/typography.component.js";
import "../../../../themes/box-shadows/boxShadows.js";
import "../../../../themes/dark.theme.js";
import { pxToRem as n } from "../../../../themes/default-theme/default-theme.js";
import "../../../../themes/gradients/gradients.js";
import "../../../../themes/light.theme.js";
var a = o(t)(function (o) {
  o.theme;
  return {
    height: "100%",
    position: "relative"
  };
}),
    d = o(e)(function (o) {
  var t,
      e,
      r = o.theme,
      i = o.$color;
  return {
    "&:hover": {
      boxShadow: null === (t = null == r ? void 0 : r.boxShadows) || void 0 === t ? void 0 : t.white
    },
    background: i && (null == r ? void 0 : r.gradients[i]),
    borderRadius: n(16),
    boxShadow: null === (e = null == r ? void 0 : r.boxShadows) || void 0 === e ? void 0 : e.white,
    fontSize: 0,
    height: "100%",
    overflow: "hidden",
    padding: r.spacing(2.25, 1.5),
    textAlign: "left",
    width: "100%"
  };
}),
    l = o(t)(function (o) {
  var t = o.theme,
      e = o.$color;
  return {
    backgroundColor: e && r(function (o) {
      switch (o) {
        case "coolGrey":
          return t.colors.mensNight;

        case "panPurple":
          return t.colors.denim;

        default:
          return t.colors.shark;
      }
    }(e), .6),
    borderRadius: n(16),
    bottom: n(-6),
    filter: "blur(".concat(n(8), ")"),
    height: n(56),
    left: n(16),
    position: "absolute",
    width: n(178)
  };
}),
    h = o(i)(function (o) {
  var t = o.theme;
  return {
    color: r(t.colors.white, .7)
  };
}),
    s = o(t)(function (o) {
  var t,
      e = o.theme;
  return {
    backgroundColor: r(null === (t = null == e ? void 0 : e.colors) || void 0 === t ? void 0 : t.white, .2),
    borderRadius: n(8),
    display: "block",
    fontSize: 0,
    padding: "".concat(e.spacing(.25), " ").concat(e.spacing(.75))
  };
});
export { d as Banner, a as BannerContainer, s as InfoChip, h as LabelTypography, l as Shadow };