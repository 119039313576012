import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, styled } from '@mui/material';
import { pxToRem } from '@themes';
var StyledProfileHeader = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    backgroundColor: theme.palette.white.main,
    borderBottomLeftRadius: pxToRem(24),
    borderBottomRightRadius: pxToRem(24),
    padding: theme.spacing(1, 0, 3)
  }, theme.breakpoints.up('desktop'), {
    width: 'fit-content'
  });

  return styles;
});
var StyledDesktopProfileHeader = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    backgroundColor: theme.palette.white.main,
    borderRadius: pxToRem(24),
    marginTop: '30px',
    maxWidth: '452px',
    padding: theme.spacing(1, 0, 3)
  };
  return styles;
});
export { StyledDesktopProfileHeader, StyledProfileHeader };