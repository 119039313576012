import { produce } from 'immer';
import { API_GRID_FAILURE, API_GRID_SUCCESS, CLOSE_ALL_GRIDS, CLOSE_GRID, GRID_INIT, UPDATE_GRID } from './grids.action-types';
var initialState = [];
/**
 * Grids reducer.
 *
 * @author Ihar Kazlouski
 * @category Reducers
 * @subcategory Grids
 * @param {GridState} state state.
 * @param {ActionsTypes} action action.
 * @return {GridState[]} modals state.
 */

var gridsReducer = function gridsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case GRID_INIT:
    case UPDATE_GRID:
    case API_GRID_SUCCESS:
    case API_GRID_FAILURE:
      {
        return produce(state, function (draft) {
          var idx = state.findIndex(function (grid) {
            return grid.gridId === action.payload.gridId;
          });

          if (idx >= 0) {
            draft[idx] = {
              data: typeof action.payload.data !== 'undefined' ? action.payload.data : draft[idx].data,
              gridId: typeof action.payload.gridId !== 'undefined' ? action.payload.gridId : draft[idx].gridId,
              isError: typeof action.payload.isError !== 'undefined' ? action.payload.isError : draft[idx].isError,
              isLoading: typeof action.payload.isLoading !== 'undefined' ? action.payload.isLoading : draft[idx].isLoading,
              pagination: typeof action.payload.pagination !== 'undefined' ? action.payload.pagination : draft[idx].pagination,
              search: typeof action.payload.search !== 'undefined' ? action.payload.search : draft[idx].search
            };
          } else {
            draft.push({
              data: action.payload.data,
              gridId: action.payload.gridId,
              isError: action.payload.isError,
              isLoading: action.payload.isLoading,
              pagination: action.payload.pagination,
              search: action.payload.search
            });
          }
        });
      }

    case CLOSE_GRID:
      {
        return produce(state, function (draft) {
          var idx = state.findIndex(function (grid) {
            return grid.gridId === action.payload.gridId;
          });
          draft.splice(idx, 1);
        });
      }

    case CLOSE_ALL_GRIDS:
      {
        return [];
      }

    default:
      {
        return state;
      }
  }
};

export default gridsReducer;