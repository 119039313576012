/**
 * Helps to create an array of objects of a select field items.
 *
 * @author Christina Kashevar
 * @function getBankOfficesForSelectField
 * @category helpers
 * @return {array} array of radio field items.
 */
var getBankOfficesForSelectField = function getBankOfficesForSelectField(offices) {
  return offices.map(function (_ref) {
    var buildingNumber = _ref.buildingNumber,
        departmentNumber = _ref.departmentNumber,
        localityName = _ref.localityName,
        officeCode = _ref.officeCode,
        streetName = _ref.streetName;
    return {
      drawerItemText: "".concat(streetName || '', " ").concat(buildingNumber || '', " ").concat(departmentNumber || ''),
      drawerItemTitle: localityName,
      text: "".concat(localityName, ", ").concat(streetName || '', " ").concat(buildingNumber || '', " ").concat(departmentNumber || ''),
      value: officeCode
    };
  });
};

export { getBankOfficesForSelectField };