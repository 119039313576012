import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { pxToRem } from '@pwa/ui';
import { AppBar, Box, Container, styled } from '@mui/material';
var StyledNavbarWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var headerHeight = theme.mixins.header || {};

  var styles = _objectSpread({
    flexGrow: 0,
    flexShrink: 0
  }, headerHeight);

  return styles;
});
var StyledAppBar = styled(AppBar)(function (_ref2) {
  var theme = _ref2.theme;
  var headerHeight = theme.mixins.header || {};

  var styles = _objectSpread({
    backgroundColor: theme.palette.white.main,
    justifyContent: 'center'
  }, headerHeight);

  return styles;
});
var StyledContainer = styled(Container)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    alignItems: 'center',
    display: 'flex' // [theme.breakpoints.up('tablet')]: {
    //   maxWidth: theme.breakpoints.values.tablet,
    // },
    // [theme.breakpoints.up('desktop')]: {
    //   maxWidth: theme.breakpoints.values.largeDesktop,
    // },

  };
  return styles;
});
var StyledBox = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    marginLeft: pxToRem(16)
  };
  return styles;
});
var StyledSliderContainer = styled(Box)(function (_ref5) {
  var theme = _ref5.theme;
  var styles = {
    flex: '1 1',
    marginLeft: pxToRem(-16),
    overflowX: 'hidden',
    paddingLeft: pxToRem(16)
  };
  return styles;
});
export { StyledAppBar, StyledBox, StyledContainer, StyledNavbarWrapper, StyledSliderContainer };