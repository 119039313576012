var t = function () {
  return t = Object.assign || function (t) {
    for (var e, r = 1, n = arguments.length; r < n; r++) for (var o in e = arguments[r]) Object.prototype.hasOwnProperty.call(e, o) && (t[o] = e[o]);

    return t;
  }, t.apply(this, arguments);
};

function e(t, e) {
  var r = {};

  for (var n in t) Object.prototype.hasOwnProperty.call(t, n) && e.indexOf(n) < 0 && (r[n] = t[n]);

  if (null != t && "function" == typeof Object.getOwnPropertySymbols) {
    var o = 0;

    for (n = Object.getOwnPropertySymbols(t); o < n.length; o++) e.indexOf(n[o]) < 0 && Object.prototype.propertyIsEnumerable.call(t, n[o]) && (r[n[o]] = t[n[o]]);
  }

  return r;
}

function r(t, e, r, n) {
  return new (r || (r = Promise))(function (o, l) {
    function a(t) {
      try {
        c(n.next(t));
      } catch (t) {
        l(t);
      }
    }

    function i(t) {
      try {
        c(n.throw(t));
      } catch (t) {
        l(t);
      }
    }

    function c(t) {
      var e;
      t.done ? o(t.value) : (e = t.value, e instanceof r ? e : new r(function (t) {
        t(e);
      })).then(a, i);
    }

    c((n = n.apply(t, e || [])).next());
  });
}

function n(t, e) {
  var r,
      n,
      o,
      l,
      a = {
    label: 0,
    sent: function () {
      if (1 & o[0]) throw o[1];
      return o[1];
    },
    trys: [],
    ops: []
  };
  return l = {
    next: i(0),
    throw: i(1),
    return: i(2)
  }, "function" == typeof Symbol && (l[Symbol.iterator] = function () {
    return this;
  }), l;

  function i(l) {
    return function (i) {
      return function (l) {
        if (r) throw new TypeError("Generator is already executing.");

        for (; a;) try {
          if (r = 1, n && (o = 2 & l[0] ? n.return : l[0] ? n.throw || ((o = n.return) && o.call(n), 0) : n.next) && !(o = o.call(n, l[1])).done) return o;

          switch (n = 0, o && (l = [2 & l[0], o.value]), l[0]) {
            case 0:
            case 1:
              o = l;
              break;

            case 4:
              return a.label++, {
                value: l[1],
                done: !1
              };

            case 5:
              a.label++, n = l[1], l = [0];
              continue;

            case 7:
              l = a.ops.pop(), a.trys.pop();
              continue;

            default:
              if (!(o = a.trys, (o = o.length > 0 && o[o.length - 1]) || 6 !== l[0] && 2 !== l[0])) {
                a = 0;
                continue;
              }

              if (3 === l[0] && (!o || l[1] > o[0] && l[1] < o[3])) {
                a.label = l[1];
                break;
              }

              if (6 === l[0] && a.label < o[1]) {
                a.label = o[1], o = l;
                break;
              }

              if (o && a.label < o[2]) {
                a.label = o[2], a.ops.push(l);
                break;
              }

              o[2] && a.ops.pop(), a.trys.pop();
              continue;
          }

          l = e.call(t, a);
        } catch (t) {
          l = [6, t], n = 0;
        } finally {
          r = o = 0;
        }

        if (5 & l[0]) throw l[1];
        return {
          value: l[0] ? l[1] : void 0,
          done: !0
        };
      }([l, i]);
    };
  }
}

function o(t) {
  var e = "function" == typeof Symbol && Symbol.iterator,
      r = e && t[e],
      n = 0;
  if (r) return r.call(t);
  if (t && "number" == typeof t.length) return {
    next: function () {
      return t && n >= t.length && (t = void 0), {
        value: t && t[n++],
        done: !t
      };
    }
  };
  throw new TypeError(e ? "Object is not iterable." : "Symbol.iterator is not defined.");
}

function l(t, e) {
  var r = "function" == typeof Symbol && t[Symbol.iterator];
  if (!r) return t;
  var n,
      o,
      l = r.call(t),
      a = [];

  try {
    for (; (void 0 === e || e-- > 0) && !(n = l.next()).done;) a.push(n.value);
  } catch (t) {
    o = {
      error: t
    };
  } finally {
    try {
      n && !n.done && (r = l.return) && r.call(l);
    } finally {
      if (o) throw o.error;
    }
  }

  return a;
}

function a(t, e, r) {
  if (r || 2 === arguments.length) for (var n, o = 0, l = e.length; o < l; o++) !n && o in e || (n || (n = Array.prototype.slice.call(e, 0, o)), n[o] = e[o]);
  return t.concat(n || Array.prototype.slice.call(e));
}

function i(t, e) {
  return Object.defineProperty ? Object.defineProperty(t, "raw", {
    value: e
  }) : t.raw = e, t;
}

export { t as __assign, r as __awaiter, n as __generator, i as __makeTemplateObject, l as __read, e as __rest, a as __spreadArray, o as __values };