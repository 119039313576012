import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _chipType;

import { i18n } from '@localization';
export var ChipKeys;

(function (ChipKeys) {
  ChipKeys["BLOCKED"] = "BLOCKED";
  ChipKeys["FROZEN"] = "FROZEN";
  ChipKeys["SUPERRATE"] = "SUPERRATE";
})(ChipKeys || (ChipKeys = {}));

export var chipType = (_chipType = {}, _defineProperty(_chipType, ChipKeys.FROZEN, {
  color: 'indigo',
  label: i18n.t('components.chip.frozen')
}), _defineProperty(_chipType, ChipKeys.BLOCKED, {
  color: 'amaranth',
  label: i18n.t('components.chip.blocked')
}), _defineProperty(_chipType, ChipKeys.SUPERRATE, {
  color: 'seaBuckthorn',
  label: i18n.t('components.chip.superRate')
}), _chipType);