import _defineProperty from "@babel/runtime/helpers/defineProperty";
// eslint-disable-next-line import/no-unresolved
import { Swiper } from 'swiper/react';
import { alpha, Box, styled } from '@mui/material';
var StyledNavigationWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    alignItems: 'center',
    display: 'none',
    height: 0,
    justifyContent: 'space-between',
    left: theme.typography.pxToRem(-16),
    position: 'absolute',
    right: theme.typography.pxToRem(-16),
    top: '50%',
    zIndex: 1
  }, theme.breakpoints.up('desktop'), {
    display: 'flex'
  });

  return styles;
});
var StyledButton = styled('button')(function (_ref2) {
  var _styles2;

  var theme = _ref2.theme;
  var styles = (_styles2 = {}, _defineProperty(_styles2, '&.swiper-button-disabled', {
    pointerEvents: 'none',
    visibility: 'hidden'
  }), _defineProperty(_styles2, '&:hover', {
    backgroundColor: alpha(theme.palette.white.main, 1)
  }), _defineProperty(_styles2, "alignItems", 'center'), _defineProperty(_styles2, "backgroundColor", alpha(theme.palette.white.main, 0.9)), _defineProperty(_styles2, "border", 'none'), _defineProperty(_styles2, "borderRadius", '50%'), _defineProperty(_styles2, "boxShadow", '0px 5px 20px 0px rgba(51, 55, 64, 0.18)'), _defineProperty(_styles2, "cursor", 'pointer'), _defineProperty(_styles2, "display", 'flex'), _defineProperty(_styles2, "height", theme.typography.pxToRem(40)), _defineProperty(_styles2, "justifyContent", 'center'), _defineProperty(_styles2, "outline", 'none'), _defineProperty(_styles2, "position", 'relative'), _defineProperty(_styles2, "width", theme.typography.pxToRem(40)), _styles2);
  return styles;
});
var StyledSwiper = styled(Swiper)(function (_ref3) {
  var theme = _ref3.theme;

  var styles = _defineProperty({
    overflow: 'visible'
  }, theme.breakpoints.up('desktop'), {
    overflow: 'hidden'
  });

  return styles;
});
export { StyledButton, StyledNavigationWrapper, StyledSwiper };