import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { FavoriteOperationType, FavoritePaymentType } from '@enums';
import { FAVORITE_ACTIONS } from './favorites-dnd-list.types';
export var pointerWithinForCombinableSortable = function pointerWithinForCombinableSortable(action) {
  return function (_ref) {
    var droppableRects = _ref.droppableRects,
        droppableContainers = _ref.droppableContainers,
        pointerCoordinates = _ref.pointerCoordinates;

    if (!pointerCoordinates) {
      return [];
    }

    var collisions = [];

    for (var i = 0; i < droppableContainers.length; i++) {
      var droppableContainer = droppableContainers[i];
      var id = droppableContainer.id;
      var rect = droppableRects.get(id);

      if (!rect) {
        continue;
      }

      var possibleCollisionsOfTheDroppableContainer = [{
        bottom: rect.bottom,
        height: rect.height,
        left: rect.left,
        right: rect.right,
        top: rect.top,
        width: rect.width
      }];

      for (var _i = 0; _i < possibleCollisionsOfTheDroppableContainer.length; _i++) {
        var rectangle = possibleCollisionsOfTheDroppableContainer[_i];

        if (isPointWithinRect(pointerCoordinates, rectangle)) {
          /* There may be more than a single rectangle intersecting
           * with the pointer coordinates. In order to sort the
           * colliding rectangles, we measure the distance between
           * the pointer and the corners of the intersecting rectangle
           */
          var corners = cornersOfRectangle(rectangle);
          var distances = corners.reduce(function (accumulator, corner) {
            return accumulator + distanceBetween(pointerCoordinates, corner);
          }, 0);
          var effectiveDistance = Number((distances / 4).toFixed(4));
          collisions.push({
            data: {
              action: action,
              droppableContainer: droppableContainer,
              value: effectiveDistance
            },
            id: id
          });
        }
      }
    }

    return collisions.sort(sortCollisionsAsc);
  };
}; // active: Active;
// collisionRect: ClientRect;
// droppableRects: RectMap;
// droppableContainers: DroppableContainer[];
// pointerCoordinates: Coordinates | null;

/**
 * Check if a given point is contained within a bounding rectangle
 */

function isPointWithinRect(point, rect) {
  var top = rect.top,
      left = rect.left,
      bottom = rect.bottom,
      right = rect.right; // console.log({ top, left, bottom, right });

  return top <= point.y && point.y <= bottom && left <= point.x && point.x <= right;
}
/**
 * Returns the coordinates of the corners of a given rectangle:
 * [TopLeft {x, y}, TopRight {x, y}, BottomLeft {x, y}, BottomRight {x, y}]
 */


function cornersOfRectangle(_ref2) {
  var left = _ref2.left,
      top = _ref2.top,
      height = _ref2.height,
      width = _ref2.width;
  return [{
    x: left,
    y: top
  }, {
    x: left + width,
    y: top
  }, {
    x: left,
    y: top + height
  }, {
    x: left + width,
    y: top + height
  }];
}
/**
 * Returns the distance between two points
 */


function distanceBetween(p1, p2) {
  return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
}
/**
 * Sort collisions from smallest to greatest value
 */


function sortCollisionsAsc(_ref3, _ref4) {
  var a = _ref3.data.value;
  var b = _ref4.data.value;
  return a - b;
}

export function collisionsShouldBeCombined(_ref5) {
  var _collisions$, _collisions$$data;

  var collisions = _ref5.collisions;

  if (!collisions) {
    return false;
  }

  return (collisions === null || collisions === void 0 ? void 0 : (_collisions$ = collisions[0]) === null || _collisions$ === void 0 ? void 0 : (_collisions$$data = _collisions$.data) === null || _collisions$$data === void 0 ? void 0 : _collisions$$data.action) === FAVORITE_ACTIONS.DROP;
}
export var restrictToVerticalAxis = function restrictToVerticalAxis(_ref6) {
  var transform = _ref6.transform;
  return _objectSpread(_objectSpread({}, transform), {}, {
    x: 0
  });
};
export var isCanFavoriteMerge = function isCanFavoriteMerge(favorite) {
  return Boolean(favorite && favorite.type === FavoritePaymentType.PAYMENT && [FavoriteOperationType.ERIP, FavoriteOperationType.FREEPAYMENT].some(function (operation) {
    return operation === favorite.paymentData.paymentType;
  }));
};