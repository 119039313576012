import { Box, styled } from '@mui/material';
var StyledBanner = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    margin: theme.spacing(4, 0, 2)
  };
  return styles;
});
var StyledBox = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    margin: theme.spacing(0, 0, 1, 1)
  };
  return styles;
});
var StyledWrapper = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    marginTop: theme.spacing(1)
  };
  return styles;
});
var StyledFormWrapper = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    marginBottom: theme.spacing(5.25)
  };
  return styles;
});
var StyledCheckboxWrapper = styled(Box)(function (_ref5) {
  var theme = _ref5.theme;
  var styles = {
    '& label': {
      margin: '0'
    },
    '& span.MuiButtonBase-root': {
      marginRight: theme.spacing(1.5),
      padding: '0'
    },
    margin: theme.spacing(4, 1)
  };
  return styles;
});
var StyledCurrencyRevertButtonWrapper = styled(Box)(function (_ref6) {
  var theme = _ref6.theme;
  var styles = {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(3)
  };
  return styles;
});
export { StyledBanner, StyledBox, StyledCheckboxWrapper, StyledCurrencyRevertButtonWrapper, StyledFormWrapper, StyledWrapper };