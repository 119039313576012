import { __rest as e, __read as o, __assign as r } from "../../../_virtual/_tslib.js";
import { jsxs as a, jsx as i } from "react/jsx-runtime";
import { useState as l } from "react";
import { Box as t } from "@mui/material";
import { StyledFormControl as d, StyledInputLabel as n, StyledInputBase as c, StyledInputDivider as u, StyledInputAdornment as m, StyledAbsoluteInputBase as f, StyleFromHelperWrapper as s, StyledFormHelperErrorText as h, StyledFormHelperText as p } from "./transfer-field.styles.js";
import v from "../number-format-input/helper/numeric-format.js";

var F = function (F) {
  var b = F.name,
      g = F.label;
  F.onChange;
  var S = F.value,
      x = F.color,
      C = void 0 === x ? "white" : x,
      T = F.error,
      I = F.bottomLabel,
      y = F.labelFrom,
      N = F.labelTo;
  F.deviceSize;

  var V = F.placeholder,
      P = F.thousandSeparator,
      w = void 0 === P ? " " : P,
      $ = F.fixedDecimalScale,
      j = void 0 === $ || $,
      B = F.decimalSeparator,
      D = void 0 === B ? "," : B,
      L = F.decimalScale,
      W = void 0 === L ? 2 : L,
      A = F.inputProps,
      k = F.onChange,
      z = F.onBlur,
      M = F.onFocus,
      O = F.valueIsNumericString,
      _ = void 0 === O || O,
      q = F.allowNegative,
      E = void 0 !== q && q,
      G = F.onChangeFrom,
      H = F.onChangeTo,
      J = F.clearPlaceholderOnFocus;

  F.active;
  var K = F.hiddenFromField,
      Q = F.hiddenToField,
      R = F.disabled,
      U = F.disabledFrom,
      X = F.disabledTo,
      Y = F.suffixFrom,
      Z = F.suffixTo,
      ee = e(F, ["name", "label", "onChange", "value", "color", "error", "bottomLabel", "labelFrom", "labelTo", "deviceSize", "placeholder", "thousandSeparator", "fixedDecimalScale", "decimalSeparator", "decimalScale", "inputProps", "onChange", "onBlur", "onFocus", "valueIsNumericString", "allowNegative", "onChangeFrom", "onChangeTo", "clearPlaceholderOnFocus", "active", "hiddenFromField", "hiddenToField", "disabled", "disabledFrom", "disabledTo", "suffixFrom", "suffixTo"]),
      oe = o(l(!1), 2),
      re = oe[0],
      ae = oe[1],
      ie = o(l(!1), 2),
      le = ie[0],
      te = ie[1],
      de = b && "".concat(b, "-from-textfield"),
      ne = b && "".concat(b, "-to-textfield"),
      ce = !!T,
      ue = {
    errorId: de && "".concat(de, "-error-text"),
    htmlFor: de,
    id: de
  },
      me = {
    errorId: ne && "".concat(ne, "-error-text"),
    htmlFor: ne,
    id: ne
  };
  return a(t, r({
    position: "relative"
  }, {
    children: [a(d, r({
      error: ce,
      fullWidth: !0,
      hidden: K
    }, {
      children: [g && i(n, r({
        focused: re || le,
        shrink: !0
      }, {
        children: g
      })), i(v, r({
        "aria-describedby": de,
        color: C,
        customInput: c,
        disabled: R || U,
        endAdornment: i(u, {
          hidden: Q,
          position: "end"
        }),
        error: ce && "string" != typeof T && !!T.from,
        fullWidth: !0,
        id: ue.id,
        name: de,
        placeholder: J && re ? void 0 : V,
        startAdornment: y && i(m, r({
          position: "start"
        }, {
          children: y
        }))
      }, ee, {
        $forcedFocus: re || le,
        $hiddenToField: Q,
        allowNegative: E,
        decimalScale: W,
        decimalSeparator: D,
        fixedDecimalScale: j,
        inputProps: r({
          autoComplete: "off",
          inputMode: "decimal",
          maxLength: 20
        }, A),
        suffix: Y,
        thousandSeparator: w,
        value: (null == S ? void 0 : S.from) || "",
        valueIsNumericString: _,
        onBlur: function (e) {
          ae(!1), z && z(e);
        },
        onFocus: function (e) {
          ae(!0), M && M(e);
        },
        onValueChange: function (e, o) {
          var a = _ ? "number" == typeof e.floatValue ? "".concat(e.floatValue) : "." === e.value ? "0" : e.value : e.formattedValue;
          k && k(r(r({}, S), {
            from: a
          }), o), G && G(a, o);
        }
      }))]
    })), a(d, r({
      error: ce,
      fullWidth: !0,
      hidden: Q
    }, {
      children: [g && K && i(n, r({
        focused: re || le,
        shrink: !0
      }, {
        children: g
      })), i(v, r({
        "aria-describedby": ne,
        color: C,
        customInput: f,
        disabled: R || X,
        error: ce && "string" != typeof T && !!T.to,
        fullWidth: !0,
        id: me.id,
        name: ne,
        placeholder: J && le ? void 0 : V,
        startAdornment: N && i(m, r({
          position: "start"
        }, {
          children: N
        }))
      }, ee, {
        $forcedFocus: K && le,
        $hiddenFromField: K,
        allowNegative: E,
        decimalScale: W,
        decimalSeparator: D,
        fixedDecimalScale: j,
        inputProps: r({
          inputMode: "decimal",
          maxLength: 20
        }, A),
        suffix: Z,
        thousandSeparator: w,
        value: (null == S ? void 0 : S.to) || "",
        valueIsNumericString: _,
        onBlur: function (e) {
          te(!1), z && z(e);
        },
        onFocus: function (e) {
          te(!0), M && M(e);
        },
        onValueChange: function (e, o) {
          var a = _ ? "number" == typeof e.floatValue ? "".concat(e.floatValue) : "." === e.value ? "0" : e.value : e.formattedValue;
          k && k(r(r({}, S), {
            to: a
          }), o), H && H(a, o);
        }
      }))]
    })), ce && i(s, {
      children: i(h, r({
        id: ue.errorId
      }, {
        children: "string" == typeof T ? T : T.from || T.to
      }))
    }), I && !ce ? i(s, {
      children: i(p, r({
        $color: C
      }, {
        children: I
      }))
    }) : null]
  }));
};

export { F as TransferField };