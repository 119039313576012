import { __rest as r, __read as t, __assign as o } from "../../_virtual/_tslib.js";
import { jsxs as i, jsx as e } from "react/jsx-runtime";
import { useState as n, useRef as a, useEffect as c } from "react";
import { Box as d } from "@mui/material";
import { useIntersection as u } from "../../hooks/use-intersection.hook.js";
import { StyledSkeleton as m, StyledImageWrapper as h } from "./remote-image.styles.js";

var l = function (l) {
  var s = l.src,
      f = l.alt,
      g = l.backgroundColor,
      p = l.borderRadius,
      b = l.height,
      j = l.width,
      k = r(l, ["src", "alt", "backgroundColor", "borderRadius", "height", "width"]),
      v = t(n(!0), 2),
      w = v[0],
      x = v[1],
      $ = t(n(!1), 2),
      y = $[0],
      C = $[1],
      R = a(!1),
      _ = a(),
      D = u(_);

  return c(function () {
    return function () {
      R.current = !0;
    };
  }, []), i(d, o({
    ref: _
  }, {
    children: [(w || y) && e(d, o({
      display: "flex",
      flexDirection: "column",
      height: b,
      width: j
    }, k, {
      children: e(m, {
        $backgroundColor: g,
        $borderRadius: p,
        animation: !!w && void 0,
        component: d,
        variant: "rectangular"
      })
    })), D && e(h, o({
      $borderRadius: p,
      $height: b,
      $width: j,
      alignItems: "center",
      display: "flex",
      height: b,
      hidden: w || y,
      justifyContent: "center",
      width: j
    }, k, {
      children: e(d, {
        alt: f,
        component: "img",
        src: s,
        onError: function () {
          R.current || (C(!0), x(!1));
        },
        onLoad: function () {
          R.current || x(!1);
        }
      })
    }))]
  }));
};

export { l as RemoteImage };