import { styled as o, Box as t, Button as i, alpha as e } from "@mui/material";
import { ActionChip as r } from "../../../action-chip/action-chip.component.js";
import { Typography as n } from "../../../typography/typography.component.js";
import "../../../../themes/box-shadows/boxShadows.js";
import "../../../../themes/dark.theme.js";
import { pxToRem as a } from "../../../../themes/default-theme/default-theme.js";
import "../../../../themes/gradients/gradients.js";
import "../../../../themes/light.theme.js";
var d = o(t)(function () {
  return {
    position: "relative"
  };
}),
    h = o(i)(function (o) {
  var t,
      i,
      e,
      r = o.theme;
  return {
    "&:hover": {
      boxShadow: null === (t = null == r ? void 0 : r.boxShadows) || void 0 === t ? void 0 : t.white
    },
    background: null === (i = null == r ? void 0 : r.gradients) || void 0 === i ? void 0 : i.luxuryDark,
    borderRadius: a(16),
    boxShadow: null === (e = null == r ? void 0 : r.boxShadows) || void 0 === e ? void 0 : e.white,
    fontSize: 0,
    height: a(120),
    overflow: "hidden",
    padding: "".concat(r.spacing(4.75), " ").concat(r.spacing(2), " ").concat(r.spacing(2)),
    textAlign: "left",
    width: a(210)
  };
}),
    l = o(t)(function (o) {
  var t,
      i = o.theme;
  return {
    backgroundColor: e(null === (t = null == i ? void 0 : i.colors) || void 0 === t ? void 0 : t.shark, .6),
    borderRadius: a(16),
    bottom: a(-6),
    filter: "blur(".concat(a(8), ")"),
    height: a(56),
    left: a(16),
    position: "absolute",
    width: a(178)
  };
}),
    s = o(r)(function (o) {
  var t,
      i = o.theme;
  return {
    boxShadow: null === (t = null == i ? void 0 : i.boxShadows) || void 0 === t ? void 0 : t.white,
    maxWidth: i.typography.pxToRem(130),
    position: "absolute",
    right: 0,
    top: 0
  };
}),
    m = o(n)(function (o) {
  var t = o.theme;
  return {
    color: e(t.colors.white, .6)
  };
});
export { h as Banner, d as BannerContainer, s as BannerLabel, m as LabelTypography, l as Shadow };