import { Box, styled } from '@mui/material';
var StyledDrawerContent = styled(Box)(function (_ref) {
  var theme = _ref.theme,
      isMobile = _ref.isMobile;
  var styles = {
    marginTop: isMobile ? theme.spacing(-2) : 0,
    paddingBottom: theme.spacing(3)
  };
  return styles;
});
export { StyledDrawerContent };