import { pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledBox = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    backgroundColor: theme === null || theme === void 0 ? void 0 : theme.palette.white.main,
    borderRadius: pxToRem(16)
  };
  return styles;
});
export { StyledBox };