import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { IconButton, pxToRem, Typography } from '@pwa/ui';
import { Box, Container, styled } from '@mui/material';
var StyledContentWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(4)
  }, theme.breakpoints.up('desktop'), {
    display: 'block',
    maxWidth: theme.typography.pxToRem(452),
    paddingTop: theme.spacing(4)
  });

  return styles;
});
var StyledContainer = styled(Container)(function (_ref2) {
  var theme = _ref2.theme;

  var styles = _defineProperty({
    display: 'flex',
    flex: '1',
    flexDirection: 'column'
  }, theme.breakpoints.up('desktop'), {
    display: 'block'
  });

  return styles;
});
var StyledBox = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;

  var styles = _defineProperty({
    textAlign: 'center'
  }, theme.breakpoints.up('desktop'), {
    textAlign: 'left'
  });

  return styles;
});
var StyledWrapper = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    backgroundColor: theme.palette.white.main,
    borderRadius: pxToRem(16),
    boxShadow: theme.boxShadows.independence,
    padding: theme.spacing(3, 3.5)
  };
  return styles;
});
var StyledButtonWrapper = styled(Box)(function (_ref5) {
  var theme = _ref5.theme;
  var styles = {
    padding: theme.spacing(2.5, 2, 5)
  };
  return styles;
});
var StyledIconWrapper = styled(IconButton)(function (_ref6) {
  var theme = _ref6.theme;
  var styles = {
    '& svg': {
      transition: theme.transitions.create(['color'])
    },
    minWidth: 'unset',
    padding: '0'
  };
  return styles;
});
var StyledTypography = styled(Typography)(function () {
  var styles = {
    wordWrap: 'break-word'
  };
  return styles;
});
export { StyledBox, StyledButtonWrapper, StyledContainer, StyledContentWrapper, StyledIconWrapper, StyledTypography, StyledWrapper };