import { dispatch } from '@store';
import { actions } from '@store/forms';

/**
 * Forms controller.
 *
 * @author Yury Kuntsou
 * @const FormsService
 * @category Components
 * @subcategory Forms
 */
var FormsService = {
  /**
   * create form.
   *
   * @author Yury Kuntsou
   * @param {FormStateInitPayload} form formId.
   * @return {void}
   */
  createForm: function createForm(form) {
    dispatch(actions.createFormAsync(form));
  },

  /**
   * delete form.
   *
   * @author Yury Kuntsou
   * @param {FormStateDeletePayload} form formId.
   * @return {void}
   */
  deleteForm: function deleteForm(form) {
    dispatch(actions.deleteFormStateAsync(form));
  },

  /**
   * update form.
   *
   * @author Yury Kuntsou
   * @param {FormState} form formId.
   * @return {void}
   */
  updateForm: function updateForm(form) {
    dispatch(actions.updateFormStateAsync(form));
  }
};
export { FormsService };