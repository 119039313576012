import * as yup from 'yup';
import { i18n } from '@localization';
import { MAX_FIELD_LENGTH, MIN_FIELD_LENGTH } from './help-form.enum';

var getHelpFormSchema = function getHelpFormSchema() {
  return yup.object().shape({
    persNumber: yup.string().trim().required(i18n.t('forms.validation.required')).test({
      name: 'checkCorrectField',
      test: function test(value, ctx) {
        if (!value) return true;

        if (value.length < MIN_FIELD_LENGTH) {
          return ctx.createError({
            message: i18n.t('forms.validation.minSymbols', {
              count: MIN_FIELD_LENGTH
            })
          });
        }

        if (value.length > MAX_FIELD_LENGTH) {
          return ctx.createError({
            message: i18n.t('forms.validation.maxSymbols', {
              count: MAX_FIELD_LENGTH
            })
          });
        }

        return true;
      }
    })
  });
};

export { getHelpFormSchema };