import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Icon, pxToRem } from '@pwa/ui';
import { Accordion as MuiAccordion, AccordionDetails as MuiAccordionDetails, AccordionSummary as MuiAccordionSummary, accordionSummaryClasses, Box, collapseClasses, styled } from '@mui/material';
var StyledAccordion = styled(MuiAccordion)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    '&:before': {
      display: 'none'
    },
    border: 'none',
    borderRadius: 16
  }, "& .".concat(collapseClasses.root), {//   margin:  theme.spacing(0, -2),
    //   marginTop: theme.spacing(0.5625),
    //   padding: theme.spacing(0, 2),
  });

  return styles;
});
var StyledAccordionSummary = styled(MuiAccordionSummary)(function (_ref2) {
  var _styles2;

  var theme = _ref2.theme,
      $isSort = _ref2.$isSort;
  var styles = (_styles2 = {
    minHeight: 'auto',
    padding: theme.spacing(1.25, 2)
  }, _defineProperty(_styles2, "& .".concat(accordionSummaryClasses.content), {
    margin: 0,
    maxWidth: $isSort ? '100%' : "calc(100% - ".concat(theme.typography.pxToRem(24), ")")
  }), _defineProperty(_styles2, "& .".concat(accordionSummaryClasses.expandIconWrapper), {
    color: 'inherit'
  }), _styles2);
  return styles;
});
var StyledImageWrapper = styled(Box)(function () {
  var styles = {
    '& img': {
      height: '100%',
      objectFit: 'cover',
      width: '100%'
    },
    alignItems: 'center',
    borderRadius: pxToRem(4),
    display: 'flex',
    flex: '32px 0 0',
    height: pxToRem(32),
    overflow: 'hidden',
    width: pxToRem(32)
  };
  return styles;
});
var StyledAccordionDetails = styled(MuiAccordionDetails)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    borderTop: "1px solid ".concat(theme.palette.lavenderSyrup.main),
    padding: theme.spacing(0) // margin:     theme.spacing(0, -2),
    //   paddingTop: theme.spacing(1.75),

  };
  return styles;
});
var StyledSeparate = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    backgroundColor: theme.palette.lavenderSyrup.main,
    height: 16,
    marginRight: theme.spacing(1.5),
    width: 1
  };
  return styles;
});
var StyleArrowIcon = styled(Icon)(function (_ref5) {
  var theme = _ref5.theme,
      $expanded = _ref5.$expanded;
  var styles = {
    transform: "rotate(".concat($expanded ? 180 : 0, "deg)"),
    transition: 'transform 0.3s ease'
  };
  return styles;
});
export { StyleArrowIcon, StyledAccordion, StyledAccordionDetails, StyledAccordionSummary, StyledImageWrapper, StyledSeparate };