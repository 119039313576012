import { createSelector } from 'reselect';

/**
 * Get drawers array.
 *
 * @author Mikhail Siutsou
 * @function getDrawers
 * @category Selectors
 * @subcategory Drawers
 * @param {AppState} state state.
 * @return {DrawersState[]} drawers state.
 */
var getDrawers = function getDrawers(state) {
  return state.drawers;
};

export var getDrawersSelector = createSelector(getDrawers, function (drawers) {
  return drawers;
});
export var getIsOpenDrawerExistSelector = createSelector(getDrawers, function (drawers) {
  return drawers.filter(function (drawer) {
    return drawer.isOpen;
  }).length !== 0;
});
export var getDrawerSelector = function getDrawerSelector(drawerId) {
  return createSelector(getDrawers, function (drawers) {
    return drawers.find(function (drawer) {
      return drawer.drawerId === drawerId;
    });
  });
};
export var getDrawerOpenStateSelector = function getDrawerOpenStateSelector(drawerId) {
  return createSelector([getDrawerSelector(drawerId)], function (drawer) {
    return (drawer === null || drawer === void 0 ? void 0 : drawer.isOpen) || false;
  });
};