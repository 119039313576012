import { Button } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    paddingTop: theme.spacing(1)
  };
  return styles;
});
var StyledIconWrapper = styled(Button)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    '& svg': {
      transition: theme.transitions.create(['color'])
    },
    height: '100%',
    minWidth: 'unset',
    padding: '0'
  };
  return styles;
});
export { StyledIconWrapper, StyledWrapper };