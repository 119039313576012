import { __assign as e } from "../../../../_virtual/_tslib.js";
import { jsx as t } from "react/jsx-runtime";
import { ProductsType as r, CardsType as o } from "../../../../enums/entities/entities.enum.js";
import "../../../../enums/keyboard/keyboard.enum.js";
import "../../../../enums/theme/theme.enum.js";
import { Icon as i } from "../../../icon/icon.component.js";
import { IconButton as n } from "../../../icon-button/icon-button.component.js";
import s from "../../../../packages/pwa-ui/assets/images/three-dots.image.svg.js";

var m = function (m) {
  var c = m.handleOpenDrawer,
      u = m.isHidden,
      p = m.dealType,
      a = m.productNumber,
      d = m.productType;
  return t(n, e({
    sx: {
      marginRight: "-12px"
    },
    onClick: c ? c({
      dealType: p,
      isHidden: u,
      productNumber: a,
      productType: function (e) {
        switch (e) {
          case o.Credit:
            return r.Credit;

          case o.Deposit:
            return r.Deposit;

          case o.Account:
          default:
            return r.Account;
        }
      }(d)
    }) : void 0
  }, {
    children: t(i, {
      color: "bayFog",
      height: 22,
      src: s,
      width: 8
    })
  }));
};

export { m as DotsMenu };