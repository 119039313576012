/**
 * Helps to create an array of objects of a select field items.
 *
 * @author Christina Kashevar
 * @function getTermItemsForSelectField
 * @category helpers
 * @return {array} array of radio field items.
 */
var getLocalityItemsForSelectField = function getLocalityItemsForSelectField(locality) {
  return locality.map(function (_ref) {
    var localityName = _ref.localityName,
        localityType = _ref.localityType;
    return {
      text: "".concat(localityType, " ").concat(localityName),
      value: localityName
    };
  });
};

export { getLocalityItemsForSelectField };