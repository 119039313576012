import debounce from 'lodash.debounce';
/* eslint-disable @typescript-eslint/no-explicit-any */

export function debounceAsync(func, debounceDelay, onCanceled // Add a new optional callback parameter
) {
  var promiseResolverRef = {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    current: function current() {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    reject: function reject(e) {}
  };
  var debouncedFunc = debounce(function (a) {
    var promiseResolverSnapshot = promiseResolverRef.current;
    var promiseRejectSnapshot = promiseResolverRef.reject; // eslint-disable-next-line @typescript-eslint/no-unsafe-argument

    for (var _len = arguments.length, sec = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      sec[_key - 1] = arguments[_key];
    }

    void func.apply(void 0, [a].concat(sec)).then(function (b) {
      if (promiseResolverSnapshot === promiseResolverRef.current) {
        promiseResolverRef.current(b);
      }
    })["catch"](function (e) {
      if (promiseRejectSnapshot === promiseResolverRef.reject) {
        promiseResolverRef.reject(e);
      }
    });
  }, debounceDelay);

  var debouncedFetchData = function debouncedFetchData(a) {
    for (var _len2 = arguments.length, sec = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
      sec[_key2 - 1] = arguments[_key2];
    }

    return new Promise(function (resolve, reject) {
      promiseResolverRef.current('skipped');
      promiseResolverRef.current = resolve;
      promiseResolverRef.reject = reject; // eslint-disable-next-line @typescript-eslint/no-unsafe-argument

      debouncedFunc.apply(void 0, [a].concat(sec));
    });
  };

  debouncedFetchData.cancel = function () {
    debouncedFunc.cancel();

    if (onCanceled) {
      onCanceled();
    }

    promiseResolverRef.current('skipped');
  };

  return debouncedFetchData;
}
/* eslint-enable @typescript-eslint/no-explicit-any */