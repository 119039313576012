import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getGridSelector } from '@store/grids';
import { GridsService } from '@services';

/**
 * init redux grid hook.
 *
 * @author Yury Kuntsou
 * @function useApiGrid
 * @category helper
 * @return {GridApiState} return grid state.
 */
function useApiGrid(_ref) {
  var id = _ref.id,
      api = _ref.api,
      transformResponse = _ref.transformResponse,
      fakeGenerator = _ref.fakeGenerator,
      params = _ref.params;
  var grid = useSelector(getGridSelector(id));
  var gridState = useMemo(function () {
    var handleUpdateGrid = function handleUpdateGrid(props) {
      var pagination = props === null || props === void 0 ? void 0 : props.pagination;
      var search = props === null || props === void 0 ? void 0 : props.search;
      GridsService.update(grid.gridId, api, transformResponse, pagination, search)["catch"](function () {// Handle error
      });
    };

    var state = _objectSpread(_objectSpread({}, grid), {}, {
      fake: [],
      updateGrid: handleUpdateGrid
    });

    if (fakeGenerator) {
      if (typeof fakeGenerator.model === 'function') {
        var res = [];

        for (var i = 0; i < fakeGenerator.count; i += 1) {
          res.push(fakeGenerator.model(i));
        }

        state.fake = res;
      } else {
        state.fake = new Array(fakeGenerator.count).fill(fakeGenerator.model);
      }
    }

    return state;
  }, [grid, fakeGenerator, api, transformResponse]);
  useEffect(function () {
    GridsService.api(id, api, transformResponse, params === null || params === void 0 ? void 0 : params.pagination, params === null || params === void 0 ? void 0 : params.search)["catch"](function () {// Handle error
    });
    return function () {
      GridsService.close(id);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return gridState;
}

export { useApiGrid };