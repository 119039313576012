import { __read as t, __spreadArray as e, __assign as r } from "../../_virtual/_tslib.js";
import { jsxs as o, jsx as n } from "react/jsx-runtime";
import { useState as i, useEffect as l, useMemo as u, useCallback as a } from "react";
import m from "../../node_modules/lodash.debounce/index.js";
import { matchSorter as d } from "match-sorter";
import { Box as c } from "@mui/material";
import { ButtonLink as s } from "../button-link/button-link.component.js";
import { HighlightedText as h } from "../highlighted-text/highlighted-text.component.js";
import { ListVirtualizer as f } from "../list-virtualizer/list-virtualizer.component.js";
import { Typography as p } from "../typography/typography.component.js";
import { StyledBox as g, StyledWrapper as v, StyledSearchNotFoundContainer as x } from "./autocomplete-list.styles.js";
import { TextField as y } from "../form/text-field/text-field.component.js";
import { SearchIcon as b } from "./autocomplete-list.helpers.js";

function j(j) {
  var C = j.items,
      k = j.keyValue,
      N = j.renderItem,
      S = j.searchNotFoundText,
      W = void 0 === S ? "Not found items" : S,
      z = j.searchPlaceholder,
      A = j.searchKeys,
      I = j.loading,
      L = j.loadingComponent,
      T = j.error,
      _ = j.errorComponent,
      E = j.estimateSize,
      F = j.isSearchEnable,
      O = void 0 === F || F,
      P = j.listHeight,
      w = t(i(""), 2),
      H = w[0],
      K = w[1],
      R = t(i(C), 2),
      V = R[0],
      $ = R[1],
      q = t(i(V), 2),
      B = q[0],
      D = q[1];
  l(function () {
    $(function () {
      return C;
    });
  }, [C]), l(function () {
    D(function () {
      return V;
    });
  }, [V]);
  var G = u(function () {
    return A ? e([], t(A), !1).map(function (t) {
      return {
        key: t,
        threshold: d.rankings.CONTAINS
      };
    }) : void 0;
  }, [A]),
      J = a(m(function (t) {
    !function (t) {
      if (t) {
        var e = t.split(" ");
        D(function () {
          return e.reduceRight(function (t, e) {
            return d(t, e, {
              keys: G || [{
                key: k,
                threshold: d.rankings.CONTAINS
              }],
              sorter: function (t) {
                return t;
              }
            });
          }, V);
        });
      } else D(function () {
        return V;
      });
    }(t);
  }, 1e3), [V]),
      M = H && H.trim().length > 1 ? H.toLocaleLowerCase().split(" ") : void 0;
  return o(c, {
    children: [O && n(g, {
      children: n(y, {
        clearable: !!H,
        color: "ghostWhite",
        disabled: I || T,
        fullWidth: !0,
        placeholder: z,
        startAdornment: n(b, {}),
        value: H,
        onChange: function (t) {
          var e;
          if ("clear" === t.type) return K(function () {
            return "";
          }), void D(function () {
            return V;
          });
          K(function () {
            return t.target.value;
          });
          var r = null === (e = t.target.value) || void 0 === e ? void 0 : e.trim();
          r ? r.length > 1 && J(r) : J();
        }
      })
    }), n(v, r({
      $height: P
    }, {
      children: n(f, r({
        ParentComponent: c,
        emptyListComponent: n(x, {
          children: n(p, r({
            color: "bayFog",
            textAlign: "center",
            variant: "body1"
          }, {
            children: W
          }))
        }),
        error: T,
        errorComponent: _,
        estimateSize: function () {
          return E;
        },
        list: B,
        loading: I,
        loadingComponent: L
      }, {
        children: function (t) {
          var e;
          if (N) return N({
            highlightWords: M,
            index: t.index,
            item: B[t.index]
          });
          var r = String(null === (e = B[t.index]) || void 0 === e ? void 0 : e[k]) || void 0;
          return n(s, {
            disableElevation: !0,
            fullWidth: !0,
            title: M ? n(h, {
              highlightWords: M,
              text: r
            }) : r
          });
        }
      }))
    }))]
  });
}

export { j as AutocompleteList };