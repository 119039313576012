export var DepositTerm;

(function (DepositTerm) {
  DepositTerm["Day"] = "D";
  DepositTerm["Month"] = "M";
  DepositTerm["OnDemand"] = "O";
  DepositTerm["Week"] = "W";
  DepositTerm["Year"] = "Y";
})(DepositTerm || (DepositTerm = {}));

export var DepositChoose;

(function (DepositChoose) {
  DepositChoose["Any"] = "";
  DepositChoose["No"] = "N";
  DepositChoose["Yes"] = "Y";
})(DepositChoose || (DepositChoose = {}));

export var SellChannels;

(function (SellChannels) {
  SellChannels["APIPortal"] = "3";
  SellChannels["MobileApp"] = "2";
  SellChannels["SDBO"] = "1";
})(SellChannels || (SellChannels = {}));

export var DepositType;

(function (DepositType) {
  DepositType["Demand"] = "demand";
  DepositType["Irrevocable"] = "irrevocable";
  DepositType["Revocable"] = "revocable";
})(DepositType || (DepositType = {}));