var t = function () {
  return window.CSS && CSS.supports && CSS.supports("height: 100dvh");
},
    n = function (n) {
  return t() ? "".concat(n, "dvh") : "".concat(n, "vh");
},
    e = function (t) {
  var n = parseFloat(getComputedStyle(document.documentElement).fontSize);
  return ("string" == typeof t ? parseFloat(t) : t) * n;
};

export { t as checkIsDVHSupported, n as convertToDVH, e as remToPixels };