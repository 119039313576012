import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { IconButton } from '@pwa/ui';
import { Box, Container, styled } from '@mui/material';
var StyledContentWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(3)
  }, theme.breakpoints.up('desktop'), {
    display: 'block',
    maxWidth: theme.typography.pxToRem(452),
    paddingTop: theme.spacing(4)
  });

  return styles;
});
var StyledDesktopContentWrapper = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;

  var styles = _defineProperty({}, theme.breakpoints.up('desktop'), {
    maxWidth: theme.typography.pxToRem(452)
  });

  return styles;
});
var StyledContainer = styled(Container)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    display: 'flex',
    flex: '1',
    flexDirection: 'column'
  };
  return styles;
});
var StyledWrapperCenter = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    alignItems: 'center',
    display: 'flex',
    flexGrow: '1',
    justifyContent: 'center',
    textAlign: 'center'
  };
  return styles;
});
var StyledIconWrapper = styled(IconButton)(function (_ref5) {
  var theme = _ref5.theme;

  var styles = _defineProperty({
    '& svg': {
      transition: theme.transitions.create(['color'])
    },
    // height:                            '100%',
    minWidth: 'unset',
    padding: '0'
  }, theme.breakpoints.up('desktop'), {
    backgroundColor: theme.palette.white.main,
    minWidth: theme.typography.pxToRem(40)
  });

  return styles;
});
export { StyledContainer, StyledContentWrapper, StyledDesktopContentWrapper, StyledIconWrapper, StyledWrapperCenter };