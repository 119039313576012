import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, styled } from '@mui/material';
var StyledButtonsWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    fontSize: '0',
    textAlign: 'center'
  }, theme.breakpoints.up('desktop'), {
    textAlign: 'left'
  });

  return styles;
});
export { StyledButtonsWrapper };