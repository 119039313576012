import { styled as t, FormControl as e, RadioGroup as o, FormControlLabel as i, InputLabel as r, Box as n, FormHelperText as a } from "@mui/material";
import "../../../themes/box-shadows/boxShadows.js";
import "../../../themes/dark.theme.js";
import { pxToRem as s } from "../../../themes/default-theme/default-theme.js";
import "../../../themes/gradients/gradients.js";
import "../../../themes/light.theme.js";
var l = t(e)(function (t) {
  return {
    display: t.fullWidth && "flex",
    maxWidth: "100%"
  };
}),
    m = t(o)(function () {
  return {
    display: "block"
  };
}),
    c = t(i)(function () {
  return {
    margin: "0"
  };
}),
    h = t(r)(function (t) {
  var e = t.theme,
      o = t.$color,
      i = {
    color: e.colors.abandonedSpaceship,
    display: "block",
    fontSize: s(16),
    fontWeight: 400,
    lineHeight: s(18),
    marginBottom: e.spacing(.5),
    maxWidth: "100%",
    paddingLeft: e.spacing(1),
    position: "relative",
    textAlign: "left",
    transform: "unset",
    transformOrigin: "unset"
  };
  return "bayFog" === o && (i.color = e.colors.white), i;
}),
    p = t(n)(function () {
  return {
    fontSize: s(12),
    left: 0,
    lineHeight: s(14),
    marginTop: s(4),
    position: "absolute",
    right: 0,
    top: "100%"
  };
}),
    d = t(a)(function (t) {
  var e = t.theme;
  return {
    color: e.palette.error.main,
    display: "block",
    margin: "unset",
    maxWidth: "100%",
    padding: e.spacing(0, 1)
  };
}),
    g = t(a)(function (t) {
  var e = t.theme,
      o = t.$color,
      i = {
    color: e.colors.abandonedSpaceship,
    display: "block",
    margin: "unset",
    maxWidth: "100%",
    padding: e.spacing(0, 1)
  };
  return "bayFog" === o && (i.color = e.colors.white), i;
});
export { p as StyleFromHelperWrapper, l as StyledFormControl, d as StyledFormHelperErrorText, g as StyledFormHelperText, m as StyledGroupBase, h as StyledInputLabel, c as StyledRadioLabel };