export var Environment;

(function (Environment) {
  Environment["Dev"] = "dev";
  Environment["Preprod"] = "preprod";
  Environment["Prod"] = "prod";
  Environment["Test"] = "test";
})(Environment || (Environment = {}));

export var EnvironmentMode;

(function (EnvironmentMode) {
  EnvironmentMode["Dev"] = "dev";
  EnvironmentMode["Prod"] = "prod";
})(EnvironmentMode || (EnvironmentMode = {}));

export var Feature;

(function (Feature) {
  Feature["MockAPI"] = "mockAPI";
  Feature["ServiceWorker"] = "serviceWorker";
})(Feature || (Feature = {}));

export var Device;

(function (Device) {
  Device["Desktop"] = "desktop";
  Device["Mobile"] = "mobile";
})(Device || (Device = {}));