import { Box, styled } from '@mui/material';
var StyledModalContentContainer = styled(Box)(function (_ref) {
  var theme = _ref.theme,
      $enableScrollShadow = _ref.$enableScrollShadow;
  var styles = {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: $enableScrollShadow && 'hidden',
    position: 'relative'
  };
  return styles;
});
export { StyledModalContentContainer };