import { styled as t, Accordion as e, collapseClasses as n, AccordionSummary as r, accordionSummaryClasses as o, AccordionDetails as a, CircularProgress as i } from "@mui/material";
import "../../themes/box-shadows/boxShadows.js";
import "../../themes/dark.theme.js";
import { pxToRem as m } from "../../themes/default-theme/default-theme.js";
import "../../themes/gradients/gradients.js";
import "../../themes/light.theme.js";
var p = t(e)(function (t) {
  var e,
      r = t.theme;
  return (e = {
    "&:before": {
      display: "none"
    },
    backgroundColor: "transparent",
    border: "none"
  })["& .".concat(n.root)] = {
    margin: r.spacing(0, -2),
    padding: r.spacing(0, 2)
  }, e;
}),
    c = t(r)(function (t) {
  var e;
  return t.theme, (e = {
    minHeight: "auto",
    padding: 0
  })["& .".concat(o.content)] = {
    margin: 0
  }, e["& .".concat(o.expandIconWrapper)] = {
    color: "inherit"
  }, e;
}),
    h = t(a)(function (t) {
  var e = t.theme;
  return {
    padding: e.spacing(0),
    paddingTop: e.spacing(1.75)
  };
}),
    s = t(i)(function (t) {
  return t.theme, {
    height: "".concat(m(13), " !important"),
    width: "".concat(m(13), " !important")
  };
});
export { p as StyledAccordion, h as StyledAccordionDetails, c as StyledAccordionSummary, s as StyledCircularProgress };