import { createSelector } from 'reselect';

/**
 * Get loader state.
 *
 * @author Ihar Kazlouski
 * @function getLoaderState
 * @category Selectors
 * @subcategory Loader
 * @param {AppState} state state.
 * @return {LoaderState} loader state.
 */
var getLoaderState = function getLoaderState(state) {
  return state.loader;
};

export var getLoaderSelector = createSelector(getLoaderState, function (loader) {
  return loader;
});
/**
 * Get loader show status.
 *
 * @author Yury Kuntsou
 * @function getIsShowLoaderSelector
 * @category Selectors
 * @subcategory Loader
 * @return {LoaderState['isShowLoader']} loader  status.
 */

export var getIsShowLoaderSelector = function getIsShowLoaderSelector() {
  return createSelector(getLoaderSelector, function (loader) {
    return loader.isShowLoader;
  });
};