import { styled as o, Box as t, alpha as e } from "@mui/material";
import { ActionChip as r } from "../action-chip/action-chip.component.js";
import "../../themes/box-shadows/boxShadows.js";
import "../../themes/dark.theme.js";
import { pxToRem as i } from "../../themes/default-theme/default-theme.js";
import "../../themes/gradients/gradients.js";
import "../../themes/light.theme.js";
var n = o(t)(function (o) {
  return {
    bottom: "0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    left: "0",
    padding: o.theme.spacing(3, 2),
    position: "absolute",
    right: "0",
    top: "0"
  };
}),
    a = o(t)(function (o) {
  var t = o.theme;
  return {
    backgroundColor: e(t.colors.black, .2),
    borderRadius: 16,
    bottom: "-3%",
    filter: "blur(".concat(t.typography.pxToRem(12.5), ")"),
    height: "28%",
    left: "9%",
    pointerEvents: "none",
    position: "absolute",
    right: "9%"
  };
}),
    p = o(t)(function () {
  return {
    borderRadius: i(24),
    paddingTop: "62.99%",
    position: "relative",
    zIndex: 0
  };
}),
    m = o(t)(function (o) {
  var t = o.theme,
      e = o.$backgroundImg,
      r = o.$isShowMoneyInfo,
      n = {
    backgroundColor: t.colors.bayFog,
    borderRadius: r ? i(16) : "5% 5% 5% 5% / 7.8% 7.8% 7.8% 7.8%",
    bottom: 0,
    left: 0,
    position: "absolute",
    right: 0,
    top: 0
  };
  return e && (n.backgroundImage = e && "url(".concat(e, ")"), n.backgroundPosition = "center", n.backgroundRepeat = "no-repeat", n.backgroundSize = "100% 100%"), n;
}),
    s = o(r)(function (o) {
  var t = o.$color,
      e = o.theme;
  return {
    backgroundColor: t,
    borderRadius: "".concat(i(12)),
    marginRight: i(-16),
    maxWidth: e.typography.pxToRem(140),
    position: "absolute",
    right: e.typography.pxToRem(26),
    top: e.typography.pxToRem(-12)
  };
});
export { s as BannerLabel, n as StyledBox, m as StyledBoxBackground, p as StyledBoxWrapper, a as StyledShadow };