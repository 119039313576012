import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';
import { ConstantsUtil } from '@utils';
import { CLOSE_POPUP, CLOSING_POPUP_WITH_ANSWER, INIT_CLOSE_POPUP, INIT_POPUP_CLOSING_WITH_ANSWER, POPUP_INIT, REMOVE_POPUP, SET_POPUP } from './popups.action-types';
export var actions = {
  /**
   * Close popup with answer.
   *
   * @author Mikhail Siutsou
   * @function closeWithAnswer
   * @category Actions
   * @subcategory Popups
   * @param {PopupCloseWithAnswerPayload} payload payload.
   */
  closeWithAnswer: function closeWithAnswer(payload) {
    return {
      payload: payload,
      type: CLOSING_POPUP_WITH_ANSWER
    };
  },

  /**
   * Close popup.
   *
   * @author Mikhail Siutsou
   * @function popupClose
   * @category Actions
   * @subcategory Popups
   * @param {PopupClosePayload} payload payload.
   */
  popupClose: function popupClose(payload) {
    return {
      payload: payload,
      type: CLOSE_POPUP
    };
  },

  /**
   * Init popup opening.
   *
   * @author Mikhail Siutsou
   * @function popupInit
   * @category Actions
   * @subcategory Popups
   * @param {PopupPayload} payload payload.
   */
  popupInit: function popupInit(payload) {
    var _ref;

    return _ref = {}, _defineProperty(_ref, ERROR_ACTION, "".concat(POPUP_INIT, "/").concat(ConstantsUtil.actions.ASYNC_FAILED)), _defineProperty(_ref, WAIT_FOR_ACTION, "".concat(POPUP_INIT, "/").concat(ConstantsUtil.actions.ASYNC_SUCCESS)), _defineProperty(_ref, "payload", payload), _defineProperty(_ref, "type", POPUP_INIT), _ref;
  },

  /**
   * Init popup closing.
   *
   * @author Mikhail Siutsou
   * @function popupInitClosing
   * @category Actions
   * @subcategory Popups
   * @param {PopupClosePayload} payload payload.
   */
  popupInitClosing: function popupInitClosing(payload) {
    return {
      payload: payload,
      type: INIT_CLOSE_POPUP
    };
  },

  /**
   * Init closing with answer.
   *
   * @author Mikhail Siutsou
   * @function popupInitClosingWithAnswer
   * @category Actions
   * @subcategory Popups
   * @param {PopupCloseWithAnswerPayload} payload payload.
   */
  popupInitClosingWithAnswer: function popupInitClosingWithAnswer(payload) {
    return {
      payload: payload,
      type: INIT_POPUP_CLOSING_WITH_ANSWER
    };
  },

  /**
   * Remove popup with answer.
   *
   * @author Mikhail Siutsou
   * @function removePopup
   * @category Actions
   * @subcategory Popups
   * @param {PopupCloseWithAnswerPayload} payload payload.
   */
  removePopup: function removePopup(payload) {
    return {
      payload: payload,
      type: REMOVE_POPUP
    };
  },

  /**
   * Set necessary popup.
   *
   * @author Mikhail Siutsou
   * @function setPopup
   * @category Actions
   * @subcategory Popups
   * @param {PopupPayload} payload payload.
   */
  setPopup: function setPopup(payload) {
    return {
      payload: payload,
      type: SET_POPUP
    };
  }
};