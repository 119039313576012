import * as yup from 'yup';
import { i18n } from '@localization';

var getConfirmFormSchema = function getConfirmFormSchema(_ref) {
  var fields = _ref.fields,
      maxLength = _ref.maxLength,
      pin = _ref.pin,
      otp = _ref.otp,
      form = _ref.form;
  return yup.object().shape({
    otp: yup.array().min(fields, i18n.t('forms.validation.otpIncorrect')).test({
      message: i18n.t('forms.validation.otpIncorrect'),
      name: 'each-otp-correct',
      test: function test(value) {
        if (!otp) return true;

        if (value) {
          var isHasError = value === null || value === void 0 ? void 0 : value.find(function (val) {
            return !val || val.length !== maxLength;
          });
          return typeof isHasError === 'undefined';
        }

        return true;
      }
    }),
    pin: yup.array().min(fields, i18n.t('forms.validation.pinIncorrect')).test({
      message: i18n.t('forms.validation.pinIncorrect'),
      name: 'each-pin-correct',
      test: function test(value, ctx) {
        if (!pin) return true;

        if (value) {
          if (form) {
            var _formState$submitErro, _formState$submitErro3;

            var formState = form.getState();

            if (formState !== null && formState !== void 0 && formState.modifiedSinceLastSubmit && (value === null || value === void 0 ? void 0 : value.join('').length) === 0 && formState !== null && formState !== void 0 && (_formState$submitErro = formState.submitErrors) !== null && _formState$submitErro !== void 0 && _formState$submitErro.pin) {
              var _formState$submitErro2;

              var errorText = formState === null || formState === void 0 ? void 0 : (_formState$submitErro2 = formState.submitErrors) === null || _formState$submitErro2 === void 0 ? void 0 : _formState$submitErro2.pin;
              return ctx.createError({
                message: errorText
              });
            }

            if (formState !== null && formState !== void 0 && (_formState$submitErro3 = formState.submitErrors) !== null && _formState$submitErro3 !== void 0 && _formState$submitErro3.pin && (value === null || value === void 0 ? void 0 : value.join('').length) === 6) {
              form.mutators.resetSubmitErrors();
            }
          }

          var isHasError = value === null || value === void 0 ? void 0 : value.find(function (val) {
            return !val || val.length !== maxLength;
          });
          return typeof isHasError === 'undefined';
        }

        return true;
      }
    })
  });
};

export { getConfirmFormSchema };