import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, Container, Stack, styled } from '@mui/material';
import { pxToRem } from '@themes';
var StyledBoxWrapper = styled(Stack)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    backgroundColor: theme.colors.white,
    borderRadius: pxToRem(16),
    padding: theme.spacing(2)
  };
  return styles;
});
var StyledBox = styled(Box)(function (_ref2) {
  var theme = _ref2.theme,
      _ref2$$mt = _ref2.$mt,
      $mt = _ref2$$mt === void 0 ? 0 : _ref2$$mt;
  var styles = {
    margin: theme.spacing($mt, 0, 1, 1)
  };
  return styles;
});
var StyledCheckboxWrapper = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;

  var styles = _defineProperty({
    '& label': {
      margin: '0'
    },
    '& span.MuiButtonBase-root': {
      marginRight: theme.spacing(1.5),
      padding: '0'
    },
    margin: theme.spacing(4, 1, 7)
  }, theme.breakpoints.up('desktop'), {
    marginBottom: theme.spacing(4)
  });

  return styles;
});
var StyledContentWrapper = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;

  var styles = _defineProperty({
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(3)
  }, theme.breakpoints.up('desktop'), {
    display: 'block',
    maxWidth: theme.typography.pxToRem(452),
    paddingTop: theme.spacing(4)
  });

  return styles;
});
var StyledContainer = styled(Container)(function (_ref5) {
  var theme = _ref5.theme;

  var styles = _defineProperty({
    display: 'flex',
    flex: '1',
    flexDirection: 'column'
  }, theme.breakpoints.up('desktop'), {
    display: 'block'
  });

  return styles;
});
export { StyledBox, StyledBoxWrapper, StyledCheckboxWrapper, StyledContainer, StyledContentWrapper };