import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Box, styled } from '@mui/material';
var StyledStoriesWrapper = styled(Box)(function (_ref) {
  var _styles;

  var theme = _ref.theme;
  var styles = (_styles = {}, _defineProperty(_styles, theme.breakpoints.up('desktop'), {
    maxWidth: 688
  }), _defineProperty(_styles, theme.breakpoints.up('largeDesktop'), {
    maxWidth: 752
  }), _styles);
  return styles;
});
export { StyledStoriesWrapper };