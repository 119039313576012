import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Container, styled } from '@mui/material';
var StyledContainer = styled(Container)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(2)
  }, theme.breakpoints.up('desktop'), {
    marginTop: theme.spacing(4)
  });

  return styles;
});
export { StyledContainer };