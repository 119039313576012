import { __rest as e, __assign as a } from "../../../_virtual/_tslib.js";
import { jsx as l, Fragment as r } from "react/jsx-runtime";
import { forwardRef as o } from "react";
import { TextField as t } from "../text-field/text-field.component.js";
import i from "./helper/numeric-format.js";
var c = o(function (o, c) {
  var u = o.onChange;
  o.focused;
  var m = o.placeholder,
      d = o.clearPlaceholderOnFocus,
      n = o.valueIsNumericString,
      v = o.thousandSeparator,
      p = void 0 === v ? " " : v,
      f = o.fixedDecimalScale,
      s = void 0 === f || f,
      S = o.decimalSeparator,
      h = void 0 === S ? "," : S,
      g = o.decimalScale,
      N = void 0 === g ? 2 : g,
      x = o.allowNegative,
      b = void 0 !== x && x,
      I = o.active,
      j = o.value,
      F = o.nullable,
      V = e(o, ["onChange", "focused", "placeholder", "clearPlaceholderOnFocus", "valueIsNumericString", "thousandSeparator", "fixedDecimalScale", "decimalSeparator", "decimalScale", "allowNegative", "active", "value", "nullable"]);
  return l(r, {
    children: l(i, a({}, V, {
      allowNegative: b,
      customInput: t,
      decimalScale: N,
      decimalSeparator: h,
      fixedDecimalScale: s,
      getInputRef: c,
      placeholder: d && I ? void 0 : m,
      thousandSeparator: p,
      value: F ? j || "" : j,
      onValueChange: function (e) {
        if (u && V.name) {
          var a = e.floatValue && Number(e.floatValue.toFixed(N)) || e.value;
          0 === Number(e.value) && (a = "");
          var l = {
            target: {
              name: V.name,
              value: n ? a : e.formattedValue
            }
          };
          u(l);
        }
      }
    }))
  });
});
c.displayName = "NumberFormatInput";
export { c as NumberFormatInput };