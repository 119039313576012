import { __rest as e, __assign as m } from "../../../_virtual/_tslib.js";
import { jsx as s } from "react/jsx-runtime";
import { forwardRef as o } from "react";
import "../../../node_modules/imask/esm/index.js";
import "../../../node_modules/imask/esm/controls/input.js";
import "../../../node_modules/imask/esm/masked/date.js";
import "../../../node_modules/imask/esm/masked/pattern.js";
import "../../../node_modules/imask/esm/masked/base.js";
import "../../../node_modules/imask/esm/masked/factory.js";
import "../../../node_modules/imask/esm/masked/regexp.js";
import "../../../node_modules/imask/esm/masked/range.js";
import "../../../node_modules/imask/esm/controls/mask-element.js";
import "../../../node_modules/imask/esm/controls/html-mask-element.js";
import "../../../node_modules/imask/esm/controls/html-contenteditable-mask-element.js";
import "../../../node_modules/react-imask/esm/mixin.js";
import t from "../../../node_modules/react-imask/esm/input.js";
var a = o(function (o, a) {
  var i = o.onChange,
      n = e(o, ["onChange"]);
  return s(t, m({
    onAccept: function (e, m) {
      i(m.unmaskedValue);
    }
  }, n, {
    inputRef: a
  }));
});
a.displayName = "TextMask";
export { a as TextMask };