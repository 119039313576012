import { pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledInfoBox = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    backgroundColor: theme.palette.whiteSolid.main,
    borderRadius: "".concat(pxToRem(30), " 0"),
    marginTop: theme.spacing(2),
    padding: theme.spacing(3.5),
    textAlign: 'center'
  };
  return styles;
});
var StyledHorizontalLine = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    backgroundColor: theme.colors.lavenderSyrup,
    height: pxToRem(32),
    width: pxToRem(1)
  };
  return styles;
});
export { StyledHorizontalLine, StyledInfoBox };