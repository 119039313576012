import * as yup from 'yup';

var getOpenAccountDetailsSchema = function getOpenAccountDetailsSchema(accounts, currency, commission) {
  return yup.object().shape({
    accountCurrency: yup.string().test('currency', '', function (value) {
      return value === currency;
    }),
    accountId: yup.string().trim().test({
      exclusive: true,
      message: '',
      name: 'min',
      params: {},
      test: function test(value) {
        if (!value) return true;
        var account = accounts.find(function (account) {
          return account.id === +value;
        });
        if (!account) return false;
        return commission <= Number(account.accountAmount);
      }
    })
  });
};

export { getOpenAccountDetailsSchema };