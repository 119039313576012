import { numericFormatter } from 'react-number-format';
/**
 * Slice number to 2 decimal without round.
 *
 * @author Yury Kuntsou
 * @function truncated
 * @category helpers
 * @return {string} sliced number.
 */

var truncated = function truncated(num) {
  var decimalPlaces = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  return Math.trunc(num * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
};
/**
 * Format number.
 *
 * @author Christina Kashevar
 * @function formatNumber
 * @category helpers
 * @return {string} formatted number.
 */


var formatNumber = function formatNumber(num) {
  var decimalScale = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  var fixedDecimalScale = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var decimalSeparator = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : ',';
  var thousandSeparator = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : ' ';
  return numericFormatter(num.toString(), {
    decimalScale: decimalScale,
    decimalSeparator: decimalSeparator,
    fixedDecimalScale: fixedDecimalScale,
    thousandSeparator: thousandSeparator
  });
};
/**
 * checkDecimalScale.
 *
 * @author Christina Kashevar
 * @function checkDecimalScale
 * @category helpers
 * @return {string} decimal scale.
 */


var checkDecimalScale = function checkDecimalScale(rate) {
  var _rateString$split$;

  if (rate.toString().includes('e')) {
    return;
  }

  var rateString = rate === null || rate === void 0 ? void 0 : rate.toString();
  var decimalLength = rateString === null || rateString === void 0 ? void 0 : (_rateString$split$ = rateString.split('.')[1]) === null || _rateString$split$ === void 0 ? void 0 : _rateString$split$.length;

  if (!decimalLength || decimalLength <= 2) {
    return 2;
  }

  return;
};
/**
 * Format rate.
 *
 * @author Christina Kashevar
 * @function formatNumber
 * @category helpers
 * @return {string} formatted rate with at least 2 decimals.
 */


var formatRate = function formatRate(rate) {
  var rateString = rate === null || rate === void 0 ? void 0 : rate.toString();
  var decimalScale = 2;

  if (rateString) {
    var _rateString$split$2;

    var decimalLength = (_rateString$split$2 = rateString.split('.')[1]) === null || _rateString$split$2 === void 0 ? void 0 : _rateString$split$2.length;

    if (decimalLength && decimalLength > 2) {
      decimalScale = decimalLength;
    }
  }

  return numericFormatter(rate.toString(), {
    decimalScale: decimalScale,
    decimalSeparator: ',',
    fixedDecimalScale: true,
    thousandSeparator: true
  });
};

export { checkDecimalScale, formatNumber, formatRate, truncated };