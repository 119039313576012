import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Button, pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledButtonWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    position: 'absolute',
    right: pxToRem(16),
    top: pxToRem(224),
    zIndex: '2'
  }, theme.breakpoints.up('desktop'), {
    right: pxToRem(24),
    top: "calc(50% - ".concat(pxToRem(44), ")") // 88px - height of zoom controls

  });

  return styles;
});
var StyledButtonPlus = styled(Button)(function (_ref2) {
  var _theme$colors;

  var theme = _ref2.theme;
  var styles = {
    borderBottom: "".concat(pxToRem(1), " solid ").concat(theme === null || theme === void 0 ? void 0 : (_theme$colors = theme.colors) === null || _theme$colors === void 0 ? void 0 : _theme$colors.lavenderSyrup),
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    height: pxToRem(44),
    minWidth: 'unset',
    width: pxToRem(44)
  };
  return styles;
});
var StyledButtonMinus = styled(Button)(function () {
  var styles = {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    height: pxToRem(44),
    minWidth: 'unset',
    width: pxToRem(44)
  };
  return styles;
});
export { StyledButtonMinus, StyledButtonPlus, StyledButtonWrapper };