import { __rest as t, __assign as e } from "../../_virtual/_tslib.js";
import { jsx as r } from "react/jsx-runtime";
import * as i from "react";
import { StyledButton as n } from "./button.styles.js";

var s = function (i, s) {
  var o = i.indent,
      f = i.center,
      u = i.view,
      a = i.disableGutters,
      m = i.justifyContent,
      d = t(i, ["indent", "center", "view", "disableGutters", "justifyContent"]);
  return r(n, e({
    ref: s,
    $center: f,
    $disableGutters: a,
    $indent: o,
    $justifyContent: m,
    $view: u
  }, d));
},
    o = i.forwardRef(s);

export { o as Button };