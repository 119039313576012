export var getAccountToNumberMock = function getAccountToNumberMock() {
  return {
    accountCt: 'BY74BELB30144300920010226000',
    accountCurrCt: 'BYN',
    accountCurrDt: 'BYN',
    accountDt: 'BY02BELB30144300650010226000',
    accountList: [{
      accountAmount: 9977,
      accountCurrency: 'BYN',
      accountName: 'BY02BELB30144300650010226000',
      accountNumber: '3014430065001',
      accountPrefix: '3014',
      accountSuffix: '001',
      accountToAccountType: 0,
      bankCode: '226',
      bankDepartment: '50030',
      cards: [],
      defaultAccount: true,
      equivalents: [{
        equivalentAmount: 3464.24,
        equivalentCurrency: 'USD'
      }, {
        equivalentAmount: 3223.59,
        equivalentCurrency: 'EUR'
      }],
      iban: 'BY02BELB30144300650010226000',
      ibanReadable: 'BY02 BELB 3014 4300 6500 1022 6000',
      id: 1,
      isOverDraft: false,
      rate: 0
    }, {
      accountAmount: 0,
      accountCurrency: 'BYN',
      accountName: 'Текущий счет BYN.',
      accountNumber: '3014430065002',
      accountPrefix: '3014',
      accountSuffix: '002',
      accountToAccountType: null,
      bankCode: '226',
      bankDepartment: '50030',
      cards: [],
      defaultAccount: false,
      equivalents: [{
        equivalentAmount: 0,
        equivalentCurrency: 'USD'
      }, {
        equivalentAmount: 0,
        equivalentCurrency: 'EUR'
      }],
      iban: 'BY84BELB30144300650020226000',
      ibanReadable: 'BY84 BELB 3014 4300 6500 2022 6000',
      id: 2,
      isOverDraft: false,
      rate: 0
    }, {
      accountAmount: 0,
      accountCurrency: 'EUR',
      accountName: 'Текущий счет EUR.',
      accountNumber: '3014430065003',
      accountPrefix: '3014',
      accountSuffix: '003',
      accountToAccountType: null,
      bankCode: '226',
      bankDepartment: '50030',
      cards: [],
      defaultAccount: false,
      equivalents: [{
        equivalentAmount: 0,
        equivalentCurrency: 'BYN'
      }, {
        equivalentAmount: 0,
        equivalentCurrency: 'USD'
      }],
      iban: 'BY69BELB30144300650030226000',
      ibanReadable: 'BY69 BELB 3014 4300 6500 3022 6000',
      id: 3,
      isOverDraft: false,
      rate: 0
    }],
    maxAmnts: [{
      maxTransferAmnt: null,
      transferCurr: 'BYN'
    }, {
      maxTransferAmnt: null,
      transferCurr: 'USD'
    }, {
      maxTransferAmnt: null,
      transferCurr: 'EUR'
    }, {
      maxTransferAmnt: null,
      transferCurr: 'RUB'
    }],
    minAmnts: [{
      minTransferAmnt: 1,
      transferCurr: 'BYN'
    }, {
      minTransferAmnt: 1,
      transferCurr: 'USD'
    }, {
      minTransferAmnt: 1,
      transferCurr: 'EUR'
    }, {
      minTransferAmnt: 10,
      transferCurr: 'RUB'
    }],
    paymentsPurposes: {
      'BYN/EUR': ['Перевод в пользу близкого родственника.', 'Дарение.', 'Отмена дарения.'],
      'BYN/RUB': ['Перевод в пользу близкого родственника.', 'Дарение.', 'Отмена дарения.'],
      'BYN/USD': ['Перевод в пользу близкого родственника.', 'Дарение.', 'Отмена дарения.'],
      'EUR/EUR': ['Перевод в пользу близкого родственника.', 'Дарение.', 'Отмена дарения.'],
      'EUR/RUB': ['Перевод в пользу близкого родственника.', 'Дарение.', 'Отмена дарения.'],
      'EUR/USD': ['Перевод в пользу близкого родственника.', 'Дарение.', 'Отмена дарения.'],
      'RUB/EUR': ['Перевод в пользу близкого родственника.', 'Дарение.', 'Отмена дарения.'],
      'RUB/RUB': ['Перевод в пользу близкого родственника.', 'Дарение.', 'Отмена дарения.'],
      'RUB/USD': ['Перевод в пользу близкого родственника.', 'Дарение.', 'Отмена дарения.'],
      'USD/EUR': ['Перевод в пользу близкого родственника.', 'Дарение.', 'Отмена дарения.'],
      'USD/RUB': ['Перевод в пользу близкого родственника.', 'Дарение.', 'Отмена дарения.'],
      'USD/USD': ['Перевод в пользу близкого родственника.', 'Дарение.', 'Отмена дарения.']
    },
    recipientsName: 'ПОЛНОЕ НАИМЕНОВАНИЕ КЛИЕНТА  430092',
    resultRate: null,
    scale: null,
    transferAmntDt: 0,
    transferFee: null
  };
};