import { styled as o, Box as e } from "@mui/material";
import "../../../../themes/box-shadows/boxShadows.js";
import "../../../../themes/dark.theme.js";
import { pxToRem as t } from "../../../../themes/default-theme/default-theme.js";
import "../../../../themes/gradients/gradients.js";
import "../../../../themes/light.theme.js";
import { SHIFT_CARD as i } from "./card-images-info.enums.js";
var r = o(e)(function (o) {
  var e = o.theme,
      i = o.$color,
      r = o.$variant;
  return {
    backgroundColor: "alpineGoat" === i && "contained" === r ? e.colors.white : e.colors.whiteSolid,
    borderRadius: t(5),
    display: "flex",
    padding: e.spacing(.5, .5, .5, .75)
  };
}),
    a = o(e)(function (o) {
  var e = o.theme,
      r = o.$color,
      a = o.$variant,
      s = "alpineGoat" === r && "contained" === a ? e.colors.whiteSolid : e.colors.white;
  return {
    "& img": {
      display: "block",
      height: "100%",
      objectFit: "cover",
      width: "100%"
    },
    "&:not(:last-child)": {
      marginRight: e.spacing(i)
    },
    border: "".concat(t(2), " solid ").concat(s),
    borderRadius: t(5),
    height: t(24),
    overflow: "hidden",
    position: "relative",
    width: t(36)
  };
});
export { a as StyledBox, r as StyledNumberBox };