import { __rest as e, __read as t, __assign as r } from "../../_virtual/_tslib.js";
import { jsx as o, jsxs as i } from "react/jsx-runtime";
import { useState as m, useEffect as s } from "react";
import { withTheme as h } from "../../hocs/with-theme/withTheme.js";
import "../../enums/entities/entities.enum.js";
import "../../enums/keyboard/keyboard.enum.js";
import "../../enums/theme/theme.enum.js";
import "../../node_modules/@babel/runtime/helpers/typeof.js";
import { convertToDVH as n } from "../../helpers/theme/theme.helper.js";
import "../../themes/box-shadows/boxShadows.js";
import "../../themes/dark.theme.js";
import { pxToRem as d } from "../../themes/default-theme/default-theme.js";
import "../../themes/gradients/gradients.js";
import "../../themes/light.theme.js";
import { DrawerContainer as p, Puller as a, StyledDrawer as l, StyledDesktopDrawer as u } from "./lazy-drawer.styles.js";
var c = h(function (h) {
  var c = h.children,
      b = h.hideBackdrop,
      f = h.open,
      j = h.minHeight,
      g = h.maxHeight,
      x = h.isMobile,
      w = h.width,
      v = void 0 === w ? "420px" : w,
      k = e(h, ["children", "hideBackdrop", "open", "minHeight", "maxHeight", "isMobile", "width"]),
      H = x ? l : u,
      y = t(m(!1), 2),
      B = y[0],
      P = y[1];
  return s(function () {
    P(function () {
      return !!f;
    });
  }, [f]), o(H, r({
    ModalProps: {
      BackdropProps: {
        invisible: !1,
        sx: {
          backgroundColor: b ? "transparent" : "#0E0E22B2"
        }
      }
    },
    PaperProps: {
      style: {
        maxHeight: g && g.replace(/(?!d)vh/g, "dvh"),
        width: !x && v ? v : "unset"
      },
      sx: {
        borderTopLeftRadius: d(x ? 30 : 16),
        borderTopRightRadius: d(x ? 30 : 16),
        boxShadow: "none",
        maxHeight: g || n(85),
        minHeight: j,
        overflow: "hidden"
      }
    },
    anchor: "bottom",
    open: B
  }, k, {
    children: i(p, {
      children: [x && o(a, {}), c]
    })
  }));
});
export { c as LazyDrawer };