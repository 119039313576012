import { styled as t, Box as e } from "@mui/material";
import "../../themes/box-shadows/boxShadows.js";
import "../../themes/dark.theme.js";
import { pxToRem as i } from "../../themes/default-theme/default-theme.js";
import "../../themes/gradients/gradients.js";
import "../../themes/light.theme.js";
import { Button as o } from "../button/button.component.js";
var r = t(o)(function (t) {
  var e = t.theme,
      o = t.$height,
      r = void 0 === o ? 104 : o,
      n = t.$variant;
  return {
    borderRadius: i(16),
    fontSize: 0,
    height: "string" == typeof r ? r : i(r),
    padding: e.spacing("contained" === n ? 2 : 1.875),
    textAlign: "left",
    width: "100%"
  };
}),
    n = t(e)(function (t) {
  t.theme;
  var e = t.$isWithArrow,
      i = t.$isThreeDotsMenuVisible;
  return {
    display: "flex",
    flexDirection: "column",
    flexGrow: "1",
    height: "100%",
    justifyContent: "center",
    maxWidth: "calc(100% - ".concat(e ? "28px" : i ? "36px" : "0px", ")")
  };
});
export { r as StyledCard, n as StyledCardContent };