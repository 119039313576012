import { createSelector } from 'reselect';

/**
 * Get data point.
 *
 * @author Christina Kashevar
 * @function getData
 * @category Selectors
 * @subcategory Data
 * @param {AppState} state state.
 * @return {DataState} data state.
 */
var getData = function getData(state) {
  return state.data;
};

export var getDataMapSelector = createSelector(getData, function (data) {
  return data.point;
});