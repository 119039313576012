import { history } from '@history';
import { getQueue } from './queue';
/**
 * Neutralize back.
 *
 * @author Yury Kuntsou
 * @function neutralizeBack
 * @category Interceptors
 * @param {ID} key - The key to get the queue from.
 * @param {unknown} state - The state that will be pushed to the history.
 */

var neutralizeBack = function neutralizeBack(key, state) {
  var queue = getQueue(key);

  if (!queue || queue.length === 0) {
    return;
  }

  history.push(window.location.href, state);

  window.onpopstate = function (event) {
    var _wrappedCallback$curr;

    history.push(window.location.href, state);
    var wrappedCallback = queue[queue.length - 1];
    wrappedCallback === null || wrappedCallback === void 0 ? void 0 : (_wrappedCallback$curr = wrappedCallback.current) === null || _wrappedCallback$curr === void 0 ? void 0 : _wrappedCallback$curr.callback(event);
  };
};
/**
 * Revival back.
 *
 * @author Yury Kuntsou
 * @function revivalBack
 * @category Interceptors
 * @param {ID} key - The key to get the queue from.
 * @param {HREF} href - The init locale url.
 */


var revivalBack = function revivalBack(key, href) {
  var queue = getQueue(key);

  if (!queue || queue.length === 0) {
    window.onpopstate = null;

    if (href === window.location.href) {
      history.back();
    }

    return;
  }
};
/**
 * Adds the event listener with a given key and state.
 *
 * @author Yury Kuntsou
 * @function addEventListener
 * @category Interceptors
 * @param {ID} key - The key of the event listener.
 * @param {unknown} state - The state to be associated with the event listener.
 */


export var addEventListener = function addEventListener(key, state) {
  neutralizeBack(key, state);
};
/**
 * Removes the event listener with a given key.
 *
 * @author Yury Kuntsou
 * @function removeEventListener
 * @category Interceptors
 * @param {ID} key - The key of the event listener.
 * @param {HREF} href - The init locale url.
 *
 */

export var removeEventListener = function removeEventListener(key, href) {
  revivalBack(key, href);
};