var getBankFilterString = function getBankFilterString(data) {
  var access24Hours = data.access24hours ? 'access_24hours=true' : null;
  var currentStatus = data.currentStatus ? "current_status=On" : null;
  var currencies = data.currencies ? data.currencies.map(function (currency) {
    return "currencies=".concat(currency);
  }).join('&') : null;
  var services = data.serviceATM_types ? data.serviceATM_types.map(function (service) {
    return "serviceATM_types=".concat(service);
  }).join('&') : null;
  var filter = [access24Hours, currentStatus, currencies, services].filter(function (item) {
    return !!item;
  }).join('&');
  return filter;
};

export { getBankFilterString };