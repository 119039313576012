import { OperationDirection, OperationType, PaymentInstrument } from '@types';
import { i18n } from '@localization';
var PAYMENT_INSTRUMENT_ITEMS = [{
  title: "".concat(i18n.t('components.modals.filterHistory.paymentInstrumentAll')),
  value: null
}, {
  title: "".concat(i18n.t('components.modals.filterHistory.paymentInstrumentAccounts')),
  value: PaymentInstrument.Account
}, {
  title: "".concat(i18n.t('components.modals.filterHistory.paymentInstrumentCards')),
  value: PaymentInstrument.Card
}];
var DIRECTION_ITEMS = [{
  color: 'nightWizard',
  text: "".concat(i18n.t('components.modals.filterHistory.directionAll')),
  value: null
}, {
  color: 'nightWizard',
  text: "".concat(i18n.t('components.modals.filterHistory.directionIncome')),
  value: OperationDirection.Income
}, {
  color: 'nightWizard',
  text: "".concat(i18n.t('components.modals.filterHistory.directionExpense')),
  value: OperationDirection.Outcome
}];
var OPERATIONS_ITEMS = [{
  color: 'nightWizard',
  text: "".concat(i18n.t('components.modals.filterHistory.operationsAll')),
  value: null
}, {
  color: 'nightWizard',
  text: "".concat(i18n.t('components.modals.filterHistory.operationsErip')),
  value: OperationType.ERIP
}, {
  color: 'nightWizard',
  text: "".concat(i18n.t('components.modals.filterHistory.operationsTransfer')),
  value: OperationType.Transfer
}, {
  color: 'nightWizard',
  text: "".concat(i18n.t('components.modals.filterHistory.operationsBanking')),
  value: OperationType.Banking
}, {
  color: 'nightWizard',
  text: "".concat(i18n.t('components.modals.filterHistory.operationsOther')),
  value: OperationType.Other
}];
export { DIRECTION_ITEMS, OPERATIONS_ITEMS, PAYMENT_INSTRUMENT_ITEMS };