import { useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { addCallback } from './helper';

/**
 * Adds a callback to the window.history stack that is executed when the back button is clicked.
 *
 * @author Yury Kuntsou
 * @function useBackButton
 * @category Hooks
 * @param {useBackButtonProps} props - The props for the hook.
 * @returns {void}
 */
export var useBackButton = function useBackButton(_ref) {
  var _ref$id = _ref.id,
      id = _ref$id === void 0 ? 'BACK_BUTTON' : _ref$id,
      callback = _ref.callback,
      _ref$disabled = _ref.disabled,
      disabled = _ref$disabled === void 0 ? false : _ref$disabled,
      enableState = _ref.enableState;

  if (!id || !callback) {
    throw new Error('Missing id or callback');
  }

  var _useLocation = useLocation(),
      state = _useLocation.state;

  var refState = useRef(state);
  var hrefRef = useRef(window.location.href);
  var wrappedCallback = useRef(null);
  wrappedCallback.current = {
    callback: callback
  };
  var removeCallback = useMemo(function () {
    if (disabled) {
      return null;
    }

    return addCallback({
      href: hrefRef.current,
      key: id,
      state: enableState && refState.current,
      wrappedCallback: wrappedCallback
    });
  }, [disabled, enableState, id]);
  useEffect(function () {
    if (removeCallback) return removeCallback;
  }, [removeCallback]);
};