import { Box, styled } from '@mui/material';
var StyledInfiniteScrollListPagination = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  };
  return styles;
});
export { StyledInfiniteScrollListPagination };