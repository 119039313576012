import * as yup from 'yup';
import { i18n } from '@localization';

var getOpenAccountSchema = function getOpenAccountSchema() {
  return yup.object().shape({
    accountTitle: yup.string().trim().required(i18n.t('forms.validation.required')),
    checkContract: yup.bool().equals([true])
  });
};

export { getOpenAccountSchema };