import { Button, pxToRem } from '@pwa/ui';
import { styled } from '@mui/material';
var StyledBox = styled(Button)(function (_ref) {
  var _theme$colors;

  var theme = _ref.theme;
  var styles = {
    '&:has(li.MuiBox-root)': {
      borderBottom: "".concat(pxToRem(1), " solid ").concat(theme === null || theme === void 0 ? void 0 : (_theme$colors = theme.colors) === null || _theme$colors === void 0 ? void 0 : _theme$colors.lavenderSyrup)
    },
    height: 'unset',
    padding: theme.spacing(2)
  };
  return styles;
});
export { StyledBox };